function DownArrow({ height = 20, width = 20, color = '#6E40C5' }: any) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99997 15.0494C9.71322 15.0494 9.4265 14.9408 9.20788 14.724L2.32823 7.89784C1.89059 7.46362 1.89059 6.75959 2.32823 6.32554C2.76569 5.89149 3.4751 5.89149 3.91277 6.32554L9.99997 12.3657L16.0872 6.32575C16.5249 5.8917 17.2342 5.8917 17.6716 6.32575C18.1095 6.7598 18.1095 7.46383 17.6716 7.89805L10.7921 14.7242C10.5733 14.941 10.2866 15.0494 9.99997 15.0494Z" fill={color}/>
        </svg>
    );
  }
  
  export default DownArrow;

    
