import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const ThankYouSection = () => {
  return (
    <section>
      <div
        className="mx-auto px-[20px] sm:py-20 py-10 pb-0 text-center container sm:px-[70px] rounded-[10px] z-10 relative sm:mt-[104px]"
      >
        <h2 className="sm:text-[40px] text-[24px] font-extrabold font-poppins py-1">
          Thank You for <span className="text-primary_color">choosing evyAI</span>
        </h2>
        <p className="sm:px-2 sm:text-[22px] text-[16px] leading-[30px] line-height-mobile text-weight-400 font-poppins py-4">
          We are thrilled to have you on board! By registering with evyAI.com, you have taken a powerful step towards
          unlocking your LinkedIn potential and growing your business with the intelligence of AI.
        </p>
        <p className="sm:px-2 sm:text-[22px] text-[16px] leading-[30px] line-height-mobile font-poppins pt-3  pb-8">
          As a valued evyAI user, you now have the freedom to harness evyAI’s features to enhance your LinkedIn presence
          as often as you like. Whether it’s crafting the perfect post or building strong connections, evyAI is here to
          help every step of the way.
        </p>
      </div>
      <Swiper
      spaceBetween={30}
      slidesPerView={1.3}
      loop={true}
      roundLengths={true}
      centeredSlides={true}
      navigation
      modules={[Navigation]}
      className="sm:mb-12 mb-[-140px]"
    >
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] leading-8 font-bold font-poppins text-center text-[#34317D]">This is the best AI tool I have found for use in my coaching business. I can easily personalize connections and comments, which saves me so much time and triples my productivity on LinkedIn. I always make the comments "my own" so I never feel like I am not connecting authentically, just saving precious time and team resources. Thanks for creating this.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Julia Pimsleur</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“evyAI seamlessly integrates with my workflow, significantly cutting down the time I spend on LinkedIn. Its standout feature is the "command" function for customization of prompt, a unique offering unmatched in the market (I tested all other tools). Plus, the 'save profile' capability truly elevates my networking strategy.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Ivana Todorovic</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“I admit I have avoided AI like the plague because of privacy issues and because the few I tried were a waste of time. I have found one that's smart enough not to actually need a lot of rewriting, hallelujah! Also, the privacy issues are nonexistent--your information is well protected. Just wrote a post on LI that took under a minute. Highly recommend it if you're looking for reliable AI.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Marcy McDonald</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“I absolutely love this tool. I had been eyeing another similar product and I am glad I waited and found this one because the functionality is incredible. I love the persona feature, the profile saving, profile summary, and the post writer. What I really love is that it is a tool that helps me, not something I resign myself to. The team is also awesome and was very prompt to help me with an issue I was having. I highly recommend this tool.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Marjorie Prat</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“Amazing tool to quickly and easily stay connected with prospects and profiles I care about. Highly recommend it!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Eli Natoli</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This tool makes keeping updated with my prospects almost effortless and, more importantly, allows me to connect with folks 3x faster. Highly recommend it to those who feel that LinkedIn is essential.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">David Sokolitz</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>

      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“Makes everything effortless on LinkedIn. For me, even more compelling is evyAI’s CRM capabilities which LinkedIn has desperately needed. The free version alone is a steal in terms of value.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Phillip Lanos</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“evAI is a phenomenal tool. I've learned more about AI from Joe than from any other source. Joe created evyAI, and it's fantastic. You can train it to sound like you! The more you use it, the better it gets.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Jane Lehman</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This is an awesome tool for anybody who wants to leverage LinkedIn. I just started using this tool today and received a very positive feedback from a comment I left on an influencer post, who I never hear from. Keep pushing your boundaries further forward!!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Yosef Domnitz</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“evyAI is a game changer! It removes the main hurdle to LinkedIn engagement. It helps create content from scratch and then all you have to do is edit - and even that is optional!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Yitzi Brusowankin</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“I don't have writer's block and was resistant on getting evyAI. I was using the free version and wasn't impressed. After 3 months, got the upgraded version and everything changed. I created a few Personas and with GPT 4, the quality of the comments to post just exploded and I get responses much more than before. This tool saves me so much time in commenting on far more posts than before.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">James Pereira</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“In the past 7 days, I've seen a more than 1800% increase in post impressions and a 30% increase in followers. This is a great tool that will save you time and help you get more eyeballs on your business.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">John Lamberti</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This AI tool is one of the coolest chrome extensions I have ever seen. It frees up my time and allows me to stay top of mind with my LinkedIn connections all in one. Highly recommend!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Tina Larsson</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“evyAI is a great AI tool to help you be productive in your LinkedIn content strategy, while saving you a lot of time. And it is easy to use with very little learning curve. Just ad extension and start using. Highly Recommend.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Jeff Johnson</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“I always knew that I needed to get much more engaged on LinkedIn. Frankly, I struggled with coming up with more than a few meaningful words when commenting. I also struggled with creating meaningful and engaging posts. evyAI has solved those struggles of mine. I would HIGHLY recommend this platform. You CAN do it and evyAI makes it easy.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Paul Yahnke</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“evyAI is saving me so much time and energy! Thanks for making a really smart tool to help us solopreneurs get more stuff done and do it so well. My account continues to grow and my following has hit over 4K (that's a 30% increase!)”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Evan Mestman</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“Saw a demo of this tool and grabbed it before he was done talking! It installs easily and works smoothly and it's going to save lots of time on my LinkedIn networking.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Tom Collins</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This is a gamechanger and saves me so much time! Highly recommend!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Sarah Sears</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“Graceful and quick! Nicely designed AI tool to make your LinkedIn presence MUCH more efficient and effective. Thanks for helping us all to up our game!!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Dr. Scott Dell, CPA</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“Wow! I didn't realize that this app could be so amazing. But it's ability to generate thoughtful comments is mind-blowing. Plus, the ability to give it prompts makes it a fantastic AND flexible tool.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Brandon Dauphinais</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
        {/* <div className="bg-[#F5F1FB] w-full sm:h-[500px] h-[900px] flex justify-center items-center rounded-[50px]  py-8"> */}
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This app is a game changer! It allows me to write meaningful and engaging comments even when I don't have time to fully consume the post. My comments are getting more replies and likes! Love it. Boom!”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Kira Swanson</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
      <SwiperSlide>
        {({isActive}) => (
          <div className="bg-[#F5F1FB] w-full sm:h-[500px] flex justify-center items-center rounded-[50px] py-8" 
                style={isActive ? { height: "650px", transitionDuration: "500ms" } : {height: "550px", transitionDuration: "500ms"}}
            >
        {/* <div className="bg-[#F5F1FB] w-full sm:sm:h-[500px] h-[700px] flex justify-center items-center rounded-[50px]  py-8"> */}
          <div className="flex w-[80%] flex-col justify-center items-center gap-4">
            <h1 className="text-[16px] sm:text-[24px] sm:leading-10 leading-8 font-bold font-poppins text-center text-[#34317D]">“This is an amazing extension! Works so easily and provides quality results. So many options from optimizing profiles, to asking questions, sending connection requests and more! So so good! I use it for myself and for my clients...and you can hand it over to you VA and other employees.”</h1>
            <div className="flex justify-center items-center mt-4 flex-col">
              <p className="sm:text !text-[18px] !text-[#333] font-bold mt-3">Andrea Rickett</p>
            </div>
          </div>
        </div>)}
      </SwiperSlide>
    </Swiper>
    </section>
  );
};

export default ThankYouSection;
