import FreeTrailEnd from "../../components/FreeTrailEnd";
import ActionButtons from "./components/ActionButtons";
import { Timestamp, collection, getDocs, orderBy, query } from "firebase/firestore";
import { useState, useContext, useEffect } from "react";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { COLLECTIONS, SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import NoDataFound from "../../components/NoDataFound";
import EmptyTableTemplates from "./components/EmptyTableTemplates";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toastAlertSuccess } from "../../utils";
import { useNavigate } from "react-router";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import PlusSvg from "../../assets/Images/Plus.svg"
import TemplatesTable from "./components/TemplatesTable";
import { Tags } from "../AddTemplate/AddTemplate";
import { db } from "../../services/firebase";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
// import './../../CustomStyling/CustomReusablestyling.css';
import {TEMPLATE_LIMIT} from "../../constant";
import { sentryClient } from "@/Sentry/sentry";
export interface ITemplate {
  id: string;
  templateName: string;
  userId: string;
  templateTags: string[] | Tags[];
  templateContent: string;
  timestamp: Timestamp;
  modifiedDate: Timestamp;
}

const Templates = () => {
  const { getTemplates, getSubscriptionStatus, deleteMultipleTemplates, getTagById, getCurrentUser, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, subscriptionStatus, user } = useContext(
    AuthContext
  ) as AuthContext_type;
  const [loading, setLoading] = useState<boolean>(true);
  const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false);
  const [isAddButtonDisable, setIsAddButtonDisable] = useState<boolean>(true);
  const [allTemplatesPresent, setAllTemplatesPresent] = useState<ITemplate[]>([]);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [templatesForSearch, setTemplatesForSearch] = useState<ITemplate[]>([]);
  const [limitConfig, setLimitConfig] = useState<any>({
    [SubscriptionStatus.FREE]: TEMPLATE_LIMIT.FREE,
    [SubscriptionStatus.PRO]: TEMPLATE_LIMIT.PRO,
  })
  const [currentSubscription, setCurrentSubscription] = useState<any>()
  const [deleteTemplateIds, setDeleteTemplateIds] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const [userAllTags, setUserAllTags] =
    useState<Tags[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tags[]>([]);

  const navigate = useNavigate();

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (
      (user)?.limits
    ) {
      setLimitConfig({
        [SubscriptionStatus.FREE]: user?.limits.templates_free_limit,
        [SubscriptionStatus.PRO]: user?.limits.templates_pro_limit,
      });
    }
    console.log("user >>", user);
  }, [currentUser]);


  const handleMultipleDelete = () => {
    deleteMultipleTemplates(deleteTemplateIds);
    toastAlertSuccess("Deleted Succefully")
    setTemplates((prevLists: ITemplate[]) => prevLists.filter(template => !deleteTemplateIds.includes(template.id)));
    setTemplatesForSearch((prevLists: ITemplate[]) => prevLists.filter(template => !deleteTemplateIds.includes(template.id)));
    setAllTemplatesPresent((prevLists: ITemplate[]) => prevLists.filter(template => !deleteTemplateIds.includes(template.id)));
  };

  const confirmMultipleDelete = () => {
    if (deleteTemplateIds.length > 0) {
      handleMultipleDelete();
      setDeleteTemplateIds([])
    }
    setShowConfirmation(false);
  };

  const handleAddTemplate = () => {
    if (
      allTemplatesPresent.length >= 10 &&
      subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE
    ) {
      setShowUpdatePaymentDialog(true);
      return;
    } else {
      navigate(`/${PROTECTED_ROUTES.addTemplate}`)
    }
  }

  async function getAllTemplates(sortBy?: string, order?: any) {
    try {
      let allTemplates
      let allTotalTemplates

      if (sortBy && order) {
        allTemplates = await getTemplates(sortBy, order);
        allTotalTemplates = await getTemplates();
      } else {
        allTemplates = await getTemplates();
        allTotalTemplates = await getTemplates();
      }
      // console.log("ALLTemplates", allTemplates);
      // for (const iterator of allTemplates) {
      //   await deleteTemplate(iterator.id)
      // }

      const allTemplatesWithTags: ITemplate[] = await Promise.all(allTemplates.map(async (template: ITemplate) => {
        const allTagsDetails = await Promise.all(template.templateTags.map(async (tag: any) => {
          const tagDetails: Tags = await getTagById(tag);
          return tagDetails;
        }));
        return { ...template, templateTags: allTagsDetails };
      }));
      console.log("ALLTemplates2", allTemplatesWithTags);
      setAllTemplatesPresent(allTotalTemplates)

      const filteredTemplates = await setFilteredTemplates(allTemplatesWithTags);
      if (filteredTemplates) {
        setTemplates(filteredTemplates)
        setTemplatesForSearch(filteredTemplates)
        setLoading(false);
        return filteredTemplates;
      } else {
        setTemplates(allTemplatesWithTags)
        setTemplatesForSearch(allTemplatesWithTags)
        setLoading(false);
        return allTemplatesWithTags
      }

    } catch (error) {
      console.error("Failed to fetch templates:", error);
      sentryClient.captureException(error)
    }
  }

  const handleGetTags = async (
    orderByField: string = "modifiedDate",
    orderDirection: "asc" | "desc" = "desc"
  ): Promise<any> => {
    try {
      console.log("hitting", orderByField, orderDirection);
      if (currentUser) {
        const allTagsRef = collection(
          db,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );
        const allTagsSnapshot = await getDocs(
          query(allTagsRef, orderBy(orderByField, orderDirection))
        );
        const allTagsMap: Tags[] = [];
        allTagsSnapshot.forEach((tagDoc) => {
          allTagsMap.push({
            ...tagDoc.data(),
            id: tagDoc.id,
          } as Tags);
        });
        console.log(allTagsMap, "TagLists");
        setUserAllTags(allTagsMap);
        // setUserTags(allTagsMap);
      }

      // setLoading(false);
    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const subscriptionStatus = await getSubscriptionStatus();
      setCurrentSubscription(subscriptionStatus)

      const maxLimit = limitConfig[subscriptionStatus.status]
      if (
        allTemplatesPresent.length >= maxLimit &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        setShowLimitMessage(true);
        setIsAddButtonDisable(true);
      } else if (
        allTemplatesPresent.length >= maxLimit &&
        subscriptionStatus.status === SubscriptionStatus.PRO
      ) {
        setShowLimitMessage(true);
        setIsAddButtonDisable(true)
      } else {
        setShowLimitMessage(false);
        setIsAddButtonDisable(false);
      }
    };
    fetchSubscriptionStatus();
    // setTemplatesForSearch(templates);
  }, [templates]);

  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    handleGetTags();
  }, []);

  async function setFilteredTemplates(allTemplates: ITemplate[]) {
    if (selectedTags.length > 0) {
      const filteredProfiles = allTemplates.filter(
        (profile) =>
          profile.templateTags &&
          selectedTags.some((selectedTag) =>
            profile.templateTags!.some((tag) => {
              if (typeof tag !== 'string') {
                return tag.name === selectedTag.name
              }
            })
          )
      );
      return filteredProfiles;
    }
  }

  useEffect(() => {
    getAllTemplates()
  }, [selectedTags]);

  return (
    <section>
      <div className="flex justify-between md:items-end items-center">
        <h3 className="custom-h3-style">
          Templates
        </h3>
        <button
          className={`md:hidden primary_button_custom ${isAddButtonDisable ? 'bg-disable_button_state border-disable_button_state' : 'bg-secondry_color border-secondry_color'}`}
          onClick={handleAddTemplate}
          disabled={isAddButtonDisable}
        >
          <img src={PlusSvg} style={{ marginRight: "5px" }} /> New Template
        </button>
      </div>

      <p className="custom-primary-p-style">
        Save templates to save time when using evyAI
      </p>
      <div className="mt-5 md:mt-8">
        {!loading && showLimitMessage && (
          <FreeTrailEnd
            title={currentSubscription.status === 1 ? "You’ve reached the max limit of templates" : "Your free trial has finished!"}
            content={currentSubscription.status === 1 ? "Please contact hello@evyai.com to ask for more templates." : `Your free trial includes creating ${limitConfig[0]} templates. Upgrade in order to use more templates.`}
            buttonName="Upgrade Subscription"
            feature="Templates"
            subStatus={currentSubscription.status}
          />
        )}
      </div>
      <ActionButtons
        isAddButtonDisable={isAddButtonDisable}
        getAllTemplates={getAllTemplates}
        deleteTemplateIds={deleteTemplateIds}
        setShowConfirmation={setShowConfirmation}
        setTemplates={setTemplates}
        templates={templates}
        templatesForSearch={templatesForSearch}
        userAllTags={userAllTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        checkPaymentPastDue={handleAddTemplate}
      />
      {!loading && templates.length <= 0 && (
        <EmptyTableTemplates />
      )}
      {loading && <NoDataFound isLoading={true} text="No data found" />}
      {!loading && templates.length > 0 && (
        <TemplatesTable
          isAddButtonDisable={isAddButtonDisable}
          getAllTemplates={getAllTemplates}
          templates={templates}
          setTemplates={setTemplates}
          allTemplatesPresent={allTemplatesPresent}
          setAllTemplatesPresent={setAllTemplatesPresent}
          setDeleteTemplateIds={setDeleteTemplateIds}
          deleteTemplateIds={deleteTemplateIds}
          userAllTags={userAllTags}
          setSelectedTags={setSelectedTags}
          setShowUpdatePaymentDialog={setShowUpdatePaymentDialog}
        />
      )}
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmMultipleDelete}
        header={`Delete Template${deleteTemplateIds.length > 1 ? "s" : ""}`}
        content={`Are you sure you want to delete these Template${deleteTemplateIds.length > 1 ? "(s)" : ""}?`}
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </section>
  );
};

export default Templates;
