import { Firestore, getFirestore, collection, getDocs, query, where, QuerySnapshot, DocumentData, addDoc, doc, serverTimestamp, updateDoc, getDoc, limit } from "firebase/firestore";
import { COLLECTIONS } from "../../../config/firebase";
import { TEAM_MODEL_FIELDS, TeamModel } from "../models/TeamsModel";
import { TeamMemberModel } from "../models/TeamMemberModel";
// import { TeamsMemberDbService } from "./TeamMembersDbService";
import { IUser } from "../../../context/Auth/auth.types";
import { firebaseAuth, functionInstance } from "../../../context/Auth";
import { httpsCallable } from "firebase/functions";
import { API_URL } from "../../../config/appConfig";
import { sentryClient } from "@/Sentry/sentry";

// get my team
// get my team members (Pagination)
// get all teams in which I am a member
// get all teams in which I am a member and I am the owner (Pagination)
// accept team invitation
// delete team member
// update team name

export class TeamsDbService {
    private static instance: TeamsDbService;
    private db: Firestore;
    private teamsCollection;
    // private userCollection;
    // private teamMembersDbService: TeamsMemberDbService;

    constructor() {
        this.db = getFirestore();
        // this.userCollection = collection(this.db, COLLECTIONS.CUSTOMERS);
        this.teamsCollection = collection(this.db, COLLECTIONS.TEAMS);
        // this.teamMembersDbService = TeamsMemberDbService.getInstance();
    }

    public static getInstance(): TeamsDbService {
        if (!TeamsDbService.instance) {
            TeamsDbService.instance = new TeamsDbService();
        }

        return TeamsDbService.instance;
    }

    /*
    * Get the team where the user is the admin
    * @param userId The user ID
    * @returns The team where the user is the admin
    */
    async getUserDataByEmail(email: string): Promise<IUser | null> {
        const currentUser = firebaseAuth.currentUser;
        if (currentUser) {
            const firebaseIdToken = currentUser?.getIdToken();
            const functionRef = httpsCallable(
                functionInstance,
                "getUserByEmail_onCall"
            );
            const response: any = await functionRef({data: {email}, auth: firebaseIdToken});
            if (response && response.data.success) {
                const userId = response.data.userId;
                // const q = query(this.teamsCollection, where(TEAM_MODEL_FIELDS.ADMIN_ID, "==", userId));
                const q = doc(this.db, `${COLLECTIONS.CUSTOMERS}/${userId}`);

                // Execute the query
                const querySnapshot = await getDoc(q);

                // Return the first document from the query snapshot
                if (querySnapshot.exists()) {
                    const userData: IUser = (querySnapshot.data() as IUser);
                    return userData as IUser;
                } else {
                    return null;
                }
            } else if (response.data.success === false) {
                return null;
            } else {
                return null;
            }
        } else {
            console.log("user not found");
            return null;
        }
    }
    
    async getUserDataById(userId: string): Promise<IUser | null> {

        const q = doc(this.db, `${COLLECTIONS.CUSTOMERS}/${userId}`);

        // Execute the query
        const querySnapshot = await getDoc(q);

        // Return the first document from the query snapshot
        if (querySnapshot.exists()) {
            const userData: IUser = (querySnapshot.data() as IUser);
            return userData as IUser;
        } else {
            return null;
        }
    }

    async getMyTeam(userId: string): Promise<TeamModel | null> {
        // TODO get current user ID from global auth service instead of parameter

        // Construct a query to find the team where the user is the admin
        const q = query(this.teamsCollection, where(TEAM_MODEL_FIELDS.ADMIN_ID, "==", userId), limit(1));

        // Execute the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q);

        // Return the first document from the query snapshot
        if (!querySnapshot.empty) {
            const teamDoc = querySnapshot.docs[0];
            const teamDocData = teamDoc.data();
            const teamId = teamDoc.id;
            const adminData = await this.getUserDataById(teamDocData.adminId);
            const teamData = {...teamDoc.data(), adminId: adminData, id: teamId};
            return TeamModel.fromJSON(teamData);
        } else {
            // No team found where the user is the owner
            return null;
        }
    }
    
    async getMemberTeams(teamIds: string[]): Promise<TeamModel[] | null> {
        if (teamIds.length === 0) {
            return null;
        }
        let memberTeams: TeamModel[] = [];
        const mapPromises = teamIds.map(async (teamId: string) => {
            const q = doc(this.db, `${COLLECTIONS.TEAMS}/${teamId}`);
            const querySnapshot = await getDoc(q);
            if (querySnapshot.exists()) {
                const teamDocData = querySnapshot.data();
                const teamId = querySnapshot.id;
                const adminData = await this.getUserDataById(teamDocData.adminId);
                const teamData = {...teamDocData, adminId: adminData, id: teamId};
                memberTeams.push(TeamModel.fromJSON(teamData))
            }
        })
        await Promise.all(mapPromises);
        if (memberTeams.length > 0) {
            return memberTeams;
        } else {
            return null;
        }
    }

    async createMyTeam(userId: string, teamName: string) {
        // TODO get current user ID

        // only one team can be created by a user

        // check if the user already has a team
        const myTeam = await this.getMyTeam(userId);

        if (myTeam) {
            // user already has a team
            throw new Error('User already has a team');
        }

        // create a new team
        const newTeam = new TeamModel({
            adminId: userId,
            name: teamName
        });

        // add the team to the database
        return addDoc(this.teamsCollection, TeamModel.toJSON(newTeam));
    }

    async updateTeamName(teamId: string, name: string): Promise<void> {
        // Construct a query to find the team where the user is the admin
        const teamDocRef = doc(this.teamsCollection, teamId);

        // Update the team name
        await updateDoc(teamDocRef, {
            [TEAM_MODEL_FIELDS.NAME]: name,
            [TEAM_MODEL_FIELDS.MODIFIED_DATE]: serverTimestamp(),
        });
    }

    async getMembersOfATeam(teamId: string): Promise<TeamMemberModel[]> {
        
        const q = collection(this.db, `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`);

        // Execute the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q);

        // Return the first document from the query snapshot
        if (!querySnapshot.empty) {
            let membersArr: TeamMemberModel[] = [];
            const mapPromises = querySnapshot.docs.map(async (doc: DocumentData) => {
                let memberDocData = doc.data();
                if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                    const memberDataDB = await this.getUserDataByEmail(memberDocData.email);
                    if (memberDataDB) {
                        const memberUserId = memberDataDB.userId && memberDataDB.userId || memberDataDB.uid && memberDataDB.uid;
                        const memberName = memberDataDB.first_name + (memberDataDB.last_name ? " " + memberDataDB.last_name : "")
                        const photoUrl = memberDataDB.profile_picture_url && memberDocData.profile_picture_url || memberDataDB.photoURL && memberDataDB.photoURL;
                        memberDocData = {...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId}
                    }
                }
                // const memberDataDB = await fetchUserDataByEmail(memberDocData.email)
                // const memberDataDB = await this.getUserDataByEmail(memberDocData.email);
                const memberData = {...memberDocData, id: doc.id};
                membersArr.push(TeamMemberModel.fromJSON(memberData));
            })
            await Promise.all(mapPromises);
            return membersArr;
        } else {
            return [];
        }
    }

    async addMembersToTeam(teamId: string, memberEmails: string[], teamOwnerUid: string): Promise<{ error?: any, success: boolean, status: number, message: string }> {
        try {
            const currentUser = firebaseAuth.currentUser;
            if (currentUser) {
                const firebaseIdToken = await currentUser?.getIdToken();
                const response = await fetch(API_URL.INVITE_USERS_WITH_EMAILS, {
                    method: "POST",
                    // mode: 'no-cors',
                    headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Accept: "application/json; charset=utf-8",
                    pragma: "no-cache",
                    "cache-control": "no-cache",
                    authorization: `Bearer ${firebaseIdToken}`,
                    },
                    cache: "no-cache",
                    redirect: "follow",
                    body: JSON.stringify( { teamId, emails: memberEmails, teamOwnerUid } ),
                });
                const jsonResponse = await response.json();
                if (response.status === 200 && response.ok) {
                    return { success: true, status: response.status, message: jsonResponse.message };
                } else {
                    return { error: "Something occured while inviting!", success: false, status: 400, message: jsonResponse.error };
                }
            } else {
                return { error: "User not Loggedin.", success: false, status: 400, message: "Couldn't invite users" };
            }
        } catch (error) {
            sentryClient.captureException(error)
            return { error: error, success: false, status: 400, message: "Couldn't invite users" };
        }
    }

    // async acceptTeamInvitation(userId: string, teamId: string) {
    //     // accept the team invitation
    // }

    // async deleteTeamMember(userId: string, teamId: string, memberId: string) {
    //     // delete the team member
    // }

}