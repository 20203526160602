import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { toastAlertSuccess } from "../../utils";
import NoDataFound from "../../components/NoDataFound";
import { Link } from "react-router-dom";
import { COLLECTIONS, SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import {
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import PlusSvg from "../../assets/Images/Plus.svg";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { Navigate_to } from "../../router/routes.constants";
import arrowLeft from "../../assets/Images/LeftArrow.svg";
import SortActionButton from "./components/SortActionButton";
import { db } from "../../services/firebase";
import SearchImg from "../../assets/Images/listSearch.svg";
import CreateTag from "../AddTemplate/components/CreateTag";
import TagsTable from "./components/TagsTable";
import { Tags } from "../AddTemplate/AddTemplate";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import {MANAGETAGS_LIMIT} from "../../constant";
import { sentryClient } from "@/Sentry/sentry";

const ManageTags = () => {
  const { getCurrentUser, deleteMultipleTags, getSubscriptionStatus, subscriptionStatus, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, user } =
    useContext(AuthContext) as AuthContext_type;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [userTags, setUserTags] = useState<Tags[]>([]);
  const [deleteTagsIds, setDeleteTagsIds] = useState<string[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFreeTrail, setShowFreeTrail] = useState<boolean>(false);
  const [allUserTags, setAllUsersTags] = useState<Tags[]>([])
  const [limitConfig, setLimitConfig] = useState<any>({
    [SubscriptionStatus.FREE]: MANAGETAGS_LIMIT.FREE,
    [SubscriptionStatus.PRO]: MANAGETAGS_LIMIT.PRO,
  });
  const [currentSubscription, setCurrentSubscription] = useState<any>()
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (
      (user)?.limits
    ) {
      setLimitConfig({
        [SubscriptionStatus.FREE]: user?.limits.user_tags_free,
        [SubscriptionStatus.PRO]: user?.limits.user_tags_pro,
      });
    }
  }, [currentUser]);
  
  async function canAddNewTag(tags_count: any) {
    const subscriptionStatus = await getSubscriptionStatus();
    setCurrentSubscription(subscriptionStatus)
    if (
      tags_count >= limitConfig[subscriptionStatus.status] &&
      subscriptionStatus.status === SubscriptionStatus.FREE
    ) {
      setShowFreeTrail(true);
    } else if (
      tags_count >= limitConfig[subscriptionStatus.status] &&
      subscriptionStatus.status !== SubscriptionStatus.FREE
    ) {
      setShowFreeTrail(true);
    } else {
      setShowFreeTrail(false);
    }
  }

  const confirmDelete = () => {
    if (deleteTagsIds) {
      deleteUserTagIds();
      setDeleteTagsIds([]);
    }
    setShowConfirmation(false);
  };

  const initiateDelete = () => {
    setShowConfirmation(true);
  };

  const toggleDataChanged = () => {
    setDataChanged((prev) => !prev);
  };

  function deleteUserTagIds() {
    deleteMultipleTags(deleteTagsIds);
    setUserTags((prevTags) =>
      prevTags.filter((tag) => !deleteTagsIds.includes(tag.id))
    );
    canAddNewTag(userTags.length - deleteTagsIds.length);
    toastAlertSuccess("Tags deleted successfully");
  }

  const handleGetUserProfile = async (
    orderByField: string = "modifiedDate",
    orderDirection: "asc" | "desc" = "desc"
  ): Promise<any> => {
    try {
      console.log("hitting", orderByField, orderDirection);
      if (currentUser) {
        const allTagsRef = collection(
          db,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );
        const allTagsSnapshot = await getDocs(
          query(allTagsRef, orderBy(orderByField, orderDirection))
        );
        const allTagsMap: Tags[] = [];
        allTagsSnapshot.forEach((tagDoc) => {
          allTagsMap.push({
            ...tagDoc.data(),
            id: tagDoc.id,
          } as Tags);
        });
        console.log(allTagsMap, "UserTags");
        setAllUsersTags(allTagsMap);
        setUserTags(allTagsMap);
        canAddNewTag(allTagsMap.length);
      }

      setLoading(false);
    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
    }
  };

  const handleAddTag = () => {
    if (
      allUserTags.length >= 10 &&
      subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE
    ) {
      setShowUpdatePaymentDialog(true);
      return;
    } else {
      setShowAlert(true)
    }
  }

  const searchTags = (value: string) => {
    const regex = new RegExp(value, 'i');
    const filteredTags = allUserTags.filter(tag => regex.test(tag.name));
    setUserTags(filteredTags);
  }

  useEffect(() => {
    handleGetUserProfile();
  }, [dataChanged]);

  return (
    <>
      <section className="md:px-0 px-1">
        <div className="flex">
          <Link
            to={Navigate_to.templates}
            className="flex justify-start items-center cursor-pointer"
          >
            <h2 className="xsm:text-[14px] font-poppins md:text-[18px] text-[12px]  font-normal leading-normal text-[#444553] ml-2">
              Templates
            </h2>
            <img
              className="ml-2"
              src={arrowLeft}
              alt=""
              width="16px"
              height="17px"
            />
          </Link>
          <h3 className="xsm:text-[14px] font-poppins md:text-[18px] text-[12px] leading-normal text-[#444553] ml-2 font-bold">
            Manage Tags
          </h3>
        </div>

        <div className="mt-5">
          {!loading && showFreeTrail && (
            <FreeTrailEnd
              title={currentSubscription.status === 1 ? "You’ve reached the max limit of tags" : "Your free trial has finished!"}
              content={currentSubscription.status === 1 ? "Please contact hello@evyai.com to ask for more tags." : `Your free trial includes creating ${limitConfig[0]} tags. Upgrade in order to use more tags.`}
              buttonName="Upgrade Subscription"
              feature="Tags"
              subStatus={currentSubscription.status}
            />
          )}
        </div>
        <div className="mt-6 flex justify-between items-center">
          <h3 className="xsm:font-black xsm:text-[24px] font-poppins text-[32px] font-extrabold text-[#292929] capitalize">
            Manage Tags
          </h3>
          {deleteTagsIds.length > 0 ? (
            <button
              className="md:hidden bg-transparent xsm:text-[14px] xsm:py-3 xsm:px-4 text-secondry_color font-bold border border-secondry_color px-3 py-2 rounded-lg ml-3"
              onClick={() => initiateDelete()}
            >
              Delete Tags
            </button>
          ) : (
            !loading && (
              <button
                className="md:hidden bg-secondry_color flex font-poppins xsm:text-[14px] xsm:py-3 xsm:px-4 text-white align-middle text-base font-bold border-secondry_color px-3 py-2 rounded-lg ml-3 items-center disabled:bg-[#C0C0C0]"
                disabled={showFreeTrail}
                onClick={handleAddTag}
              >
                <img src={PlusSvg} style={{ marginRight: "5px" }} /> New Tag
              </button>
            )
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="xsm:hidden flex gap-x-3 mt-5 mb-3">
            <SortActionButton handleGetUserProfile={handleGetUserProfile} />

            <div className="xsm:hidden relative flex items-center border border-border_color p-1.5 rounded-[10px]">
              <img
                src={SearchImg}
                alt="Search"
                className="absolute left-3"
                style={{ height: "16px", width: "16px" }}
              />
              <input
                className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)] outline-none bg-transparent"
                onChange={(e) => searchTags(e.target.value)}
                style={{ fontSize: "14px", width: "100%" }}
                placeholder="Search a tag"
              />
            </div>
          </div>
          <div className="xsm:hidden">

            {deleteTagsIds.length > 0 ? (
              <button
                className="secondary_button_custom"
                onClick={() => initiateDelete()}
              >
                Delete Tags
              </button>
            ) : (
              !loading && (
                <button
                  className="primary_button_custom"
                  disabled={showFreeTrail}
                  onClick={handleAddTag}
                >
                  <img src={PlusSvg} style={{ marginRight: "5px" }} /> New Tag
                </button>
              )
            )}
          </div>

        </div>
        {loading ? (
          <NoDataFound text="No Data Found" isLoading={true} />
        ) : userTags.length <= 0 ? (
          <NoDataFound text="No Data Found" isLoading={false} />
        ) : (
          <TagsTable
            userTags={userTags}
            setUserTags={setUserTags}
            onDataChanged={toggleDataChanged}
            setDeleteTagsIds={setDeleteTagsIds}
            deleteTagsIds={deleteTagsIds}
            canAddNewTag={canAddNewTag}
          />
        )}
      </section>
      {showAlert && (
        <CreateTag
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          onDataChanged={toggleDataChanged}
        />
      )}
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmDelete}
        header="Delete Tag"
        content="Are you sure you want to delete these Tag(s)?"
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </>
  );
};
export default ManageTags;
