import { User } from "firebase/auth";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { Navigate_to } from "../../router/routes.constants";
import Logo from "../../assets/Images/evyAI Logo_Purple.svg";

import DashboardHeader from "../../components/DashboardHeader";
import Sidebar from "./Sidebar";
import { sidebarnavProps } from "./constants";
import { WelcomeDialogue } from "../../components/WelcomeDialogue";
import WelcomeTourMainDialog from "../../components/WelcomeTourMainDialog";
import chromeSvg from "../../assets/Images/chrome.svg";
import { EXTENSION_URL, STORAGE_KEYS } from "../../config/appConfig";
import LoginDialog from "../../components/LoginDialog";
import MobileDrawer from "../../components/MobileDrawer";
import useScreenSize from "../../hooks/useScreenSize";
import SubscriptionBadgeNew from "../../components/SubscriptionBadgeNew";
import CloseIcon from "../../assets/svgs/CloseIcon";
import GlobalTeamInviteBanner from "@/components/GlobalTeamInviteBanner";

function ProtectedRoutes({ children }: { children?: ReactNode }) {
  const navigate = useNavigate();
  const [welcomeDialog, setWelcomeDialog] = useState(false);
  const [introDialog, setIntroDialog] = useState(false);
  const [showExtButton, setShowExtButton] = useState(false);
  const [welcomeSteps, setWelcomeSteps] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [rightMarginToSet, setRightMarginToSet] = useState<number | null>(null);

  const { getCurrentUser, emailVerified, setIsNavigateToSub, user } =
    useContext(AuthContext) as AuthContext_type;
  const currentUser = getCurrentUser();

  const screen = useScreenSize();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const isNavigateToSub = queryParams.get("isNavigateToSubscription-pricing");
    if (!emailVerified && currentUser) {
      navigate(Navigate_to.emailVerificationPending);
    }
    if (isNavigateToSub) {
      setIsNavigateToSub(true);
    }
  }, []);

  useEffect(() => {
    const showExtensionLogin = localStorage.getItem(
      STORAGE_KEYS.SHOW_EXTENSION_BUTTON
    );
    if (showExtensionLogin === "false") {
      setShowExtButton(false);
    } else {
      setShowExtButton(true);
    }
  }, []);

  const handleExtensionButton = async () => {
    await localStorage.setItem(STORAGE_KEYS.SHOW_EXTENSION_BUTTON, "false");
    setShowExtButton(false);
  };

  useEffect(() => {
    if (
      (currentUser as User & any)?.accessToken &&
      user?.first_name &&
      !user?.welcome_tour
    ) {
      setWelcomeDialog(true);
    }
  }, [currentUser, user?.first_name, user?.welcome_tour]);

  if ((currentUser as User & any)?.accessToken && !user?.first_name) {
    return <Navigate to={Navigate_to.createProfile} />;
  }

  const logoutBanner = useMemo(() => {
    return (
      <div className="flex mt-4 ">
        <div className="w-full">
          <div className="flex flex-col justify-between items-center gap-4 w-full">
            <SubscriptionBadgeNew setOpenSideBar={setIsOpen} />
            {showExtButton && (
              <>
                <hr color="#DFDFDF" className="w-full xsm:hidden" />
                <div className="px-4 w-full xsm:hidden">
                  <div className="mt- w-full bg-[#E8EDF1] text-[#292929] rounded-[32px] p-2 flex justify-center items-center">
                    <button
                      className="w-full flex gap-1 justify-center items-center"
                      onClick={() => {
                        window.open(EXTENSION_URL, "_blank");
                      }}
                    >
                      <img src={chromeSvg} alt="Chrome" className="rounded-full w-[20px] h-[20px]" />
                      <span className="font-bold text-[14px]">
                        Add evyAI to Chrome
                      </span>
                    </button>
                    <div
                      className="cursor-pointer mr-2"
                      onClick={handleExtensionButton}
                    >
                      <CloseIcon color="#292929" width={10} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <button
            onClick={() => signOut()}
            className={`flex items-center justify-start w-full ${showExtButton ? "mt-3" : "mt-16"
              } py-3 px-3`}
          >
            <img className="mr-2" src={Logout} alt="Home" />
            <span className="font-poppins lg:text-[18px] md:text-[15px] font-medium text-text_color leading-normal">
              Log Out
            </span>
          </button> */}
        </div>

      </div>
    );
  }, [showExtButton]);

  return (
    <div className="flex flex-row relative min-h-screen">
      <div className="hidden md:flex flex-col justify-between fixed z-50 top-0 h-screen left-0 w-64 bg-white f  py-6 border-r-[1px] overflow-auto border-[#DFDFDF]">
        <div className="flex flex-col gap-y-8 px-4">
          <img className="w-[52%]" src={Logo} alt="Logo" />
          <Sidebar
            introDialog={introDialog}
            welcomeSteps={welcomeSteps}
            {...sidebarnavProps}
          />
        </div>

        {logoutBanner}
      </div>
      <div className={`w-full ${((welcomeSteps !== null && welcomeSteps !== 13) || welcomeDialog) ? "md:max-h-screen" : ""} ${isOpen && "xsm:max-h-screen"} min-h-[100vh] md:inline-block !bg-bg_white_color md:ml-64 ${(isOpen || ((welcomeSteps !== null && welcomeSteps !== 13) || welcomeDialog)) ? "overflow-y-hidden" : "overflow-y-auto"}`}>
        <DashboardHeader isOpen={isOpen} setIsOpen={setIsOpen} isMyAccountWelcomeStep={welcomeSteps === 12} setRightMarginToSet={setRightMarginToSet} />
        {/* <div className="z-[10000]">
        </div> */}
        <div className="w-full h-full xsm:pt-[96px] xsm:pb-4 xsm:px-4 md:pt-6 md:px-[32px] md:pb-8">
          <MobileDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col justify-between h-full">
              <Sidebar
                setIsOpen={setIsOpen}
                introDialog={introDialog}
                welcomeSteps={welcomeSteps}
                {...sidebarnavProps}
              />
              {logoutBanner}
            </div>
          </MobileDrawer>
          <div className="h-auto w-full md:hidden">
            <GlobalTeamInviteBanner />
          </div>
          {/* <GlobalTeamInviteBanner /> */}
          <div className="md:pt-[96px] md:min-w-full h-full max-h-full ">
            {(!(currentUser as User & any)?.accessToken ? (
              <LoginDialog />
            ) : (
              children
            )) || <Outlet />}

            {screen !== "small" &&
              <>
                <WelcomeDialogue
                  openDialog={welcomeDialog}
                  setOpenDialog={setWelcomeDialog}
                  onClose={() => setWelcomeDialog(false)}
                  onStarted={() => setIntroDialog(true)}
                  setWelcomeSteps={setWelcomeSteps}
                  welcomeSteps={welcomeSteps}
                />
                <WelcomeTourMainDialog
                  setWelcomeSteps={setWelcomeSteps}
                  welcomeSteps={welcomeSteps}
                  openDialog={introDialog}
                  onClose={() => setIntroDialog(false)}
                  rightMarginToSet={rightMarginToSet}
                />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtectedRoutes;