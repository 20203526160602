import { useContext, useEffect, useState } from "react";
// import CloseIcon from "../../../assets/svgs/CloseIcon";
import DailogBox from "../../../components/DailogBox";
import Loader from "../../../components/Loader";
import Warning from "../../../assets/Images/warningsvg.svg"
import DownArrow from "../../../assets/svgs/DownArrow";
import UpArrow from "../../../assets/svgs/UpArrow";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { SUBSCRIPTIONS_INTERVALS, SUBSCRIPTIONS_NAMES, SubscriptionStatusDB } from "../../../config/firebase";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>> ;
  onConfirm: (seats: number, intervalType:string) => Promise<void>;
  show: boolean;
  header?: string;
  content?: string;
  isLoading?: boolean;
  totalUsers: number;
  navigateTo?: Function;
  title?:string
  description?:string
  allowUserIntervalChange?:boolean;
  icon?: React.ReactNode
}

const SeatLimitReachedDialogue = ({ setShow, show, onConfirm, isLoading, totalUsers, title, description, allowUserIntervalChange, icon }: IProps) => {

    const { 
      subscriptionStatus,
    } = useContext(AuthContext) as AuthContext_type;

    const [users, setUsers] = useState(1)
    const [intervalToShow, setIntervalToShow] = useState<"MONTH" | "YEAR" | string>("YEAR");
    
    const  handleClose = () => {
        setShow(false);
    }

    const handleSubmit = async () => {
        // if (subscriptionStatus && subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE) {
        //   navigateTo("2")
        // } else {
          await onConfirm(users,intervalToShow);
        // }
        // setShow(false)
    }

    // const handleUpdateSeats = async () => {
    //   setLoading(true)
    //   await updateTeamSeats(users)
    //   setTotalUsersAllowed(users)
    //   if (users !== totalUsersAllowed) {
    //     setWillShowDialogue(true);
    //   } else {
    //     setWillShowDialogue(false);
    //   }
    //   setLoading(false)
    // }

    useEffect(() => {
      if (!show) {
        if (totalUsers) {
          if (totalUsers >= 2) {
            setUsers(totalUsers)
          } else {
            setUsers(2)
          }
        }
      }
    }, [show])

    useEffect(() => {
      if (totalUsers) {
        if (totalUsers >= 2) {
          setUsers(totalUsers)
        } else {
          setUsers(2)
        }
      }
    }, [totalUsers])

    useEffect(() => {
      if (subscriptionStatus) {
        console.log(subscriptionStatus)
        if (
            (subscriptionStatus.hasTeamsPlan === true && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED) ||
            (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.PREMIUM && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED)
            ) {
          subscriptionStatus.interval && setIntervalToShow(subscriptionStatus.interval);
        } else {
          setIntervalToShow(SUBSCRIPTIONS_INTERVALS.YEAR);
        }
      }
    }, [subscriptionStatus])

    const onIntervalChange = () => {
      if(intervalToShow === SUBSCRIPTIONS_INTERVALS.MONTH) {
        setIntervalToShow(SUBSCRIPTIONS_INTERVALS.YEAR);
      }else {
        setIntervalToShow(SUBSCRIPTIONS_INTERVALS.MONTH);
      }
    }
    
  return (
    <DailogBox
      dialogClass="!z-[100]"
      className={`flex flex-col items-center justify-center !gap-0 ${allowUserIntervalChange ? "!max-w-[500px]" : "!max-w-[450px]"}`}
      open={show}
      onClose={handleClose}
      >
        <div className="flex flex-col items-center gap-2">
           { icon || <img src={Warning} style={{ width: "54px", height: "60px" }} />}
        </div>
        <div className="mt-6 flex flex-col justify-between items-center">
            <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
              {title || "Subscription Seat Limit Reached"}
            </h2>
            <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
              {description || "Please add more seats below."}
            </p>
        </div>
        <div className="w-full bg-[#F5F1FB] rounded-2xl p-4 mt-8 flex justify-center items-center gap-1">
          <div className="flex justify-center items-center gap-4 w-full">
            <h4 className="flex justify-center items-center  text-center font-poppins xsm:text-[16px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
              Total Users
            </h4>
            <div className=" w-[25%] xsm:w-[50%] py-1 px-4 bg-white rounded-[10px] border-[0.5px] border-[#A1A2A980] flex justify-between items-center gap-4">
              <h4 className=" w-full text-center font-poppins xsm:text-[20px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                {users}
              </h4>
              <div className="flex flex-col justify-around items-center ">
                <button onClick={()=>setUsers(users + 1)} className="cursor-pointer">
                  <UpArrow width={17} />
                </button>
                <button onClick={()=> (!(users <= 2)) && setUsers(users - 1)} className="cursor-pointer" disabled={(users <= 2)}>
                  <DownArrow width={17} color={((users <= 2)) ? "#DFDFDF" : "#6E40C5"} />
                </button>
              </div>
            </div>
            {allowUserIntervalChange && <>
              <h4 className="flex justify-center items-center  text-center font-poppins xsm:text-[16px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                /
              </h4>
              <div className=" w-[34%] xsm:w-[50%] py-1 px-4 bg-white rounded-[10px] border-[0.5px] border-[#A1A2A980] flex justify-between items-center gap-4">
                <h4 className=" w-full text-center font-poppins xsm:text-[20px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                  {intervalToShow}
                </h4>
                <div className="flex flex-col justify-around items-center ">
                  <button onClick={onIntervalChange} className="cursor-pointer">
                    <UpArrow width={17} />
                  </button>
                  <button onClick={onIntervalChange} className="cursor-pointer">
                    <DownArrow width={17} />
                  </button>
                </div>
              </div>
            </>}
          </div>
        </div>
        
        <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
          <button
            type="button"
            className="w-full  md:max-w-[140px] px-6 py-4 flex-1 text-center text-[#34317D] font-bold rounded-[10px] border border-[#34317D]"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
          <button 
            type="button"
            className=" w-full md:max-w-[400px] xsm:w-full xsm:text-center px-6 py-4 text-center disabled:!bg-[#C0C0C0] !text-white !bg-[#34317D] outline-none border ring-offset-2 flex justify-center rounded-[10px] font-bold "
            disabled={(users === totalUsers)}
            // disabled={users <= 2 || users === totalUsers}
            onClick={handleSubmit}
            >
            <span>{isLoading ? <Loader size={5} /> : ("Update users " +  ((users <= 2 || users !== totalUsers) ? `($${intervalToShow === SUBSCRIPTIONS_INTERVALS.MONTH ? "29" : "180"} per user/${intervalToShow === SUBSCRIPTIONS_INTERVALS.MONTH ? "month" : "year"})` : ""))}</span>
          </button>
        </div>
    </DailogBox>
  );
};
export default SeatLimitReachedDialogue;
