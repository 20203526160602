import * as yup from "yup";

export const personaSchema = yup.object({
  profilePictureUrl: yup.string().label("Profile Picture"),
  personaName: yup
    .string()
    .required("Persona Name is required")
    .max(200, "Must be within 200 characters")
    .label("Persona Name"),
  industry: yup.string().label("Industry"),
  Tag: yup.string().label("Tags"),
  keywordsPhrases: yup
    .string()
    .label("Tag"),
  backgroundHistory: yup
    .string()
    .required("Background and History is required")
    .min(100, "Must be at least 100 characters")
    .max(2000, "Must be within 2000 characters")
    .label("Background and History"),
  tone: yup
    .string()
    .required("Tone is required")
    .label("Tone and Personality"),
});

