import { Link } from "react-router-dom";
import EmptyTemplatesProfileImage from "../../../assets/Images/Templates with YT icon.png"

export default function EmptyTableTemplates() {
  return (
    <>
      <div className="bg-white rounded-[10px] xsm:border-none h-[65vh] xsm:bg-white md:my-6 my-3 flex flex-col justify-center items-center">
        <Link to={"https://www.skool.com/evyai/classroom/a1594949?md=9698e249437b4dc4b429856d987f9dcf"} target="_blank">
          <img
            className="cursor-pointer w-[500px] xsm:w-[90%] mx-auto mt-6"
            src={EmptyTemplatesProfileImage}
          />
        </Link>
        <div className="flex flex-col gap-2 justify-center items-center">
          <h2 className="custom-h3-style md:text-[24px] text-[18px] leading-[36px] mt-8">
            Save Templates
          </h2>
          <p className="custom-seconday-p-style md:text-[16px] text-[14px] text-center">
            We recommend saving templates of the snippets of text that you use most often.
          </p>
        </div>
        {/* <div className="flex flex-col gap-3 justify-center items-center mt-2">
          <h2 className="custom-h3-style">
            Save Templates
          </h2>
          <p className="custom-seconday-p-style">
            We recommend saving templates of the snippets of text that you use most often.
          </p>
        </div> */}
        {/* <div className="flex flex-col gap-3 justify-center items-center">
            <div className="mx-auto mt-12">
              <div className="sm:w-1/2 w-full">
                  <iframe
                      className="rounded-[8px] xl:w-[550px] xl:h-[304px] md:h-[250px] w-full h-[160px] bg-white sm:w-[450px]"
                      src="https://www.youtube.com/embed/vOBNPzRkIro"
                      title="Use Templates with evyAI to supplement your AI on LinkedIn"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                  ></iframe>
              </div>
            </div>
          </div> */}
      </div>
    </>
  );
}
