import { useEffect, useState } from "react";
import CloseIcon from "../../../assets/svgs/CloseIcon";
import DailogBox from "../../../components/DailogBox";
import Loader from "../../../components/Loader";
import * as Yup from "yup"
import WarningIcon from '../../../assets/Images/Warning.svg'
import { sentryClient } from "@/Sentry/sentry";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
   })

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (emails:string[]) => void;
  show: boolean;
  header?: string;
  content?: string;
  isLoading?: boolean;
  totalUsers: number | null;
  seatLimitReached: React.Dispatch<React.SetStateAction<boolean>>;
  existingMembers: any[] | null;
  willClearField: boolean;
}

const InviteUserDialogue = ({ setShow, show, onConfirm, isLoading, totalUsers, seatLimitReached, existingMembers, willClearField }: IProps) => {

    const [emailsToInvite, setEmailsToInvite] = useState<string[]>([])
    const [currentEmail, setCurrentEmail] = useState("")
    const [showSeatLimitWarning, setShowSeatLimitWarning] = useState(false)
    const [error, setError] = useState("")

    const handleClose = () => {
      if (willClearField) {
        setCurrentEmail("");
        setEmailsToInvite([]);
      }
      setError("")
      setShow(false);
      setShowSeatLimitWarning(false);
    }

    const handleKeyPress = async (event:any) => {

        if (event.key === 'Enter') {
            try {
                // setCurrentEmail(currentEmail.trim())
                const existingEmailsToInvite = emailsToInvite.map((email) => email)
                const newEmailsArrOrg = currentEmail.split(",")
                const newEmailsArr = newEmailsArrOrg.map((email: string) => email.trim())
                console.log(newEmailsArr);
                const mapPromises = newEmailsArr.map(async (email: string) => {
                  if (email === "") {
                    return;
                  }
                  await validationSchema.validate({email})
                  setError('');
  
                  if (totalUsers && existingEmailsToInvite.length >= totalUsers) {
                    setShowSeatLimitWarning(true);
                    return;
                  }
                  
                  if (emailsToInvite.includes(email.toLowerCase()) || emailsToInvite.includes(email)) {
                      setError("Email already there")
                      setEmailsToInvite([...emailsToInvite])
                      return
                  } else if (existingMembers && existingMembers.some((member: any) => (member.email.toLowerCase() === email.toLowerCase()) || (member.email === email))) {
                      const memberAlreadyThere = existingMembers.find((member: any) => (member.email === email));
                      if (memberAlreadyThere.status === "joined") {
                        setError("User is already a member")
                      } else if (memberAlreadyThere.status === "invite_sent"){
                        setError("User is already invited")
                      }
                      setEmailsToInvite([...emailsToInvite])
                      return
                  }
                  existingEmailsToInvite.push(email)
                })
                await Promise.all(mapPromises);
                
                if (existingEmailsToInvite.length - emailsToInvite.length === newEmailsArrOrg.length) {
                  setEmailsToInvite(existingEmailsToInvite)
                  setCurrentEmail("")
                } else if (existingEmailsToInvite.length - emailsToInvite.length < newEmailsArrOrg.length) {
                  const newValues = newEmailsArrOrg.filter((email: string) => !existingEmailsToInvite.includes(email.trim())).join(",");
                  setEmailsToInvite(existingEmailsToInvite)
                  setCurrentEmail(newValues);
                }
                
                
                // await validationSchema.validate({ email: currentEmail });
                // setError('');

                // if (totalUsers && emailsToInvite.length >= totalUsers) {
                //   setShowSeatLimitWarning(true);
                //   return;
                // }
                
                // if (emailsToInvite.includes(currentEmail.toLowerCase()) || emailsToInvite.includes(currentEmail)) {
                //     setError("Email already there")
                //     setEmailsToInvite([...emailsToInvite])
                //     return
                // } else if (existingMembers && existingMembers.some((member: any) => (member.email.toLowerCase() === currentEmail.toLowerCase()) || (member.email === currentEmail))) {
                //     const memberAlreadyThere = existingMembers.find((member: any) => (member.email === currentEmail));
                //     if (memberAlreadyThere.status === "joined") {
                //       setError("User is already a member")
                //     } else if (memberAlreadyThere.status === "invite_sent"){
                //       setError("User is already invited")
                //     }
                //     setEmailsToInvite([...emailsToInvite])
                //     return
                // }
                
                // setEmailsToInvite([...emailsToInvite, currentEmail])
                // setCurrentEmail("")

            } catch (validationError: any) {
                setError(validationError.message);
                sentryClient.captureException(error)
                return
            }
        }
    };

    const removeEmailToInvite = (email:string) => {
        const newEmails = emailsToInvite.filter(ele => ele !== email)
        setEmailsToInvite(newEmails)
    }
    
    const handleSubmit = () => {
      if (totalUsers && emailsToInvite.length > totalUsers ) {
        seatLimitReached(true);
        return
      }
      onConfirm(emailsToInvite)
      // setEmailsToInvite([])
      // setCurrentEmail("")
      // setError("")
      // setShow(false)
    }

    // useEffect(() => {
    //   if (totalUsers && emailsToInvite.length >= totalUsers) {
    //     setShowSeatLimitWarning(true);
    //   } else {
    //     setShowSeatLimitWarning(false);
    //   }
    // }, [totalUsers, emailsToInvite])

    useEffect(() => {
      if (willClearField && show) {
        setCurrentEmail("");
        setEmailsToInvite([]);
      }
    }, [willClearField, show])
    
  return (
    <DailogBox
      dialogClass="!z-[100]"
      className="flex flex-col items-center justify-center !gap-0 !max-w-[450px]"
      open={show}
      onClose={handleClose}
    >
        {/* <div className="flex flex-col items-center gap-2">
            <img src={Warning} style={{ width: "54px", height: "60px" }} />
        </div> */}
        <div className="mt-6 flex flex-col justify-between items-center">
            <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
            Invite Member
            </h2>
            <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
                Add one or more emails and evyAI will send an invitation to join your team.
            </p>
            <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
              Hit enter after each email
            </p>
        </div>
        <div className="flex flex-col justify-start items-start w-full gap-4">
            <label className="mt-[5px] font-semibold text-[16px] xsm:text-[14px]">Email</label>
            <div className="input !p-0 -mt-3 flex justify-between flex-col items-center relative">
                {/* <div className=" flex justify-start p-1 items-center overflow-auto" style={{scrollbarWidth: "none"}}> */}
                <div className={`${emailsToInvite.length === 0 ? "hidden" : "flex"} justify-start p-3 w-full items-center flex-wrap gap-y-2 gap-x-2`}>
                    {emailsToInvite.map((email:string) => {
                        return (
                        <span className="mr-2 flex justify-center items-center gap-2 py-[2px] px-3 font-poppins font-semibold text-[16px] text-[#6E40C5] bg-[#F5F1FB] border border-[#6E40C5] rounded-[8px]">
                            <span style={{wordBreak: "break-all"}} className="xsm:text-left">
                              {email}
                            </span>
                            <span className="cursor-pointer" 
                            onClick={() => removeEmailToInvite(email)}
                            >
                            <CloseIcon width={10} color={"#6E40C5"}/>
                            </span>
                        </span>
                        )
                    })}
                </div>
                <input
                    type="email"
                    value={currentEmail}
                    placeholder={"Add emails here"}
                    onKeyDown={handleKeyPress}
                    onChange={(e:any) => setCurrentEmail(e.target.value)}
                    className="input !min-w-[300px] !-mt-[0.2px] !border-none xsm:text-[16px] xsm:px-4"
                />
            </div>
            {error && <p className="text-[red] ml-1">{error}</p>}
            {showSeatLimitWarning &&
              <div className="bg-[#FDEEEE] w-full rounded-[12px] xsm:py-3 py-2 px-3 flex justify-between items-center font-poppins ">
                <div className="flex gap-2 justify-start items-center xsm:w-full">
                  <img src={WarningIcon} className="xsm:w-[12%]" />
                  <p className="font-bold text-[16px] xsm:text-[12px]">Seat limit reached</p>
                </div>
                <div className="flex gap-4 justify-end items-center xsm:w-full">
                  <span 
                    onClick={() => seatLimitReached(true)}
                    className="font-bold text-[16px] xsm:text-[12px] underline text-[#34317D] cursor-pointer"
                    >
                      Add more seats
                  </span>
                  <span className="mb-4 xsm:hidden cursor-pointer" onClick={() => setShowSeatLimitWarning(false)}>
                    <CloseIcon width={10} />
                  </span>
                </div>
              </div>
            }
        </div>
      <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
        <button
          type="button"
          className="w-full  md:max-w-[140px] px-6 py-4 flex-1 text-center text-[#34317D] font-bold rounded-[10px] border border-[#34317D]"
          onClick={() => handleClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="w-full md:max-w-[140px] px-6 py-4 text-center  !text-white !bg-[#34317D] outline-none border ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
          onClick={handleSubmit}
          disabled={emailsToInvite.length === 0}
        >
          {isLoading ? <Loader size={6} /> : "Send Invite"}
        </button>
      </div>
    </DailogBox>
  );
};
export default InviteUserDialogue;
