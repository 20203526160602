import { Timestamp } from "firebase/firestore";
import { IUser } from "../../../context/Auth/auth.types";

interface Team {
    id: string;
    activeMembersIds: string[];
    adminId: string | IUser;
    createdDate: Timestamp | string;
    membersEmails: string[];
    modifiedDate: Timestamp | string;
    name: string;
}

export const TEAM_MODEL_FIELDS = {
    ACTIVE_MEMBERS_IDS: 'activeMembersIds',
    ADMIN_ID: 'adminId',
    CREATED_DATE: 'createdDate',
    MEMBERS_EMAILS: 'membersEmails',
    MODIFIED_DATE: 'modifiedDate',
    NAME: 'name'
};

export class TeamModel implements Team {
    id: string;
    activeMembersIds: string[];
    adminId: string | IUser;
    createdDate: Timestamp | string;
    membersEmails: string[];
    modifiedDate: Timestamp | string;
    name: string;

    constructor(data: Partial<Team>) {
        this.id = data.id || "";
        this.activeMembersIds = data.activeMembersIds || [];
        this.adminId = data.adminId || '';
        this.membersEmails = data.membersEmails || [];
        this.name = data.name || '';
        this.modifiedDate = data.modifiedDate || "";
        this.createdDate = data.createdDate || "";
    }

    static toJSON(data: TeamModel): Team {
        return {
            id: data.id,
            activeMembersIds: data.activeMembersIds,
            adminId: data.adminId,
            createdDate: data.createdDate,
            membersEmails: data.membersEmails,
            modifiedDate: data.modifiedDate,
            name: data.name,
        };
    }

    static fromJSON(data: any): TeamModel {
        return new TeamModel({
            id: data.id,
            activeMembersIds: data.activeMembersIds,
            adminId: data.adminId,
            createdDate: data.createdDate,
            membersEmails: data.membersEmails,
            modifiedDate: data.modifiedDate,
            name: data.name,
        });
    }
}