import { Link } from "react-router-dom";
import EmptyPersonaImage from "../../../assets/Images/EmptyPersonaImage.png"
export default function EmptyTablePersonas() {
  // const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <div className="bg-white rounded-[10px] xsm:border-none h-[65vh] xsm:bg-white md:my-6 my-3 flex flex-col justify-center items-center">
        <Link to={"https://www.skool.com/evyai/classroom/a1594949?md=a0298b7a2357452b9576c9824bc72cf3"} target="_blank">
          <img
            className="cursor-pointer w-[500px] xsm:w-[90%] mx-auto mt-6"
            src={EmptyPersonaImage}
          />
        </Link>
        {/* <div className="ml-10 mt-16 mr-10 mb-3"> */}
        {/* <div className="w-full"> */}
        {/* <iframe width="659" height="370" src="https://www.youtube.com/embed/OevY5A-VQfI" title="How to use personas to train AI and help you craft unique messages on LinkedIn" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
        {/* <iframe
                    className="rounded-[8px] xl:w-[550px] xl:h-[304px] md:h-[250px] w-full h-[160px] bg-white sm:w-[450px]"
                    src="https://www.youtube.com/embed/OevY5A-VQfI"
                    title="How to save and organize profiles using evyAI - Use evyAI"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe> */}
        {/* </div> */}
        {/* <img src={noDataSvg} alt="No Data" /> */}
        {/* </div> */}
        {/* <div className="flex flex-col gap-3 justify-center items-center">
          <h2 className="custom-h3-style">
            No Personas added yet!
          </h2>
          <p className="custom-seconday-p-style">
            Add a persona to teach the AI how to speak in you voice.
          </p>
        </div> */}
        <div className="flex flex-col gap-2 justify-center items-center">
          <h2 className="custom-h3-style md:text-[24px] text-[18px] leading-[36px] mt-8">
            Add <span className="lowercase">a</span> Persona
          </h2>
          <p className="custom-seconday-p-style md:text-[16px] text-[14px] text-center">
            Add a persona to teach the AI how to speak in your voice.
          </p>
        </div>
        {/* <p className="text-text_color font-poppins leading-7 ml-9">
            Save your favorite LinkedIn Profiles here
          </p>
          <div className="mb-16">
            {isUserFree && (
              <button
                onClick={() => setOpenDialog(true)}
                className="bg-secondry_color text-white font-bold border border-secondry_color px-6 py-2.5 rounded-lg flex mt-3"
              >
                <img src={plusSvg} alt="No Data" width={16} height={16} />
                <span className="ml-3">Upgrade Subscription</span>
              </button>
            )}
          </div> */}
      </div>
      {/* <UpgradeToPremiumDialogue open={openDialog} setOpen={setOpenDialog} /> */}
    </>
  );
}
