import { Link } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";

import WarningSvg from "../assets/Images/Warning.svg";
import ThunderSvg from "../assets/Images/Thunder.svg"
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { useContext } from "react";
import { ThreeDots } from "react-loader-spinner";

interface IProps {
  // isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopBarUsageComponent = ({ setIsOpen }: IProps) => {
  const { subscriptionStatus } = useContext(AuthContext) as AuthContext_type;

  if (!subscriptionStatus) {
    return (
      <div className="w-full bg-[#FFFBF3] border border-[#FEBF2B] rounded-[10px] p-2">
        <ThreeDots color="#6E40C5" />
      </div>
    );
  }

  return subscriptionStatus.usagePercentage &&
    subscriptionStatus.usagePercentage >= 100 &&
    subscriptionStatus.status != 1 ? (
    <div className="md:w-[30%] xsm:p-0 xsm:border-none bg-white px-1 rounded-[29px] flex justify-between items-center gap-4 border border-[#DDDDDD]">
      <div className=" xsm:hidden w-[80%] p-2 flex flex-col">
        <div className="flex items-center gap-1 w-full">
          <img className="w-[13px] h-[13px]" src={WarningSvg} alt="Waring" />
          <h4 className="font-poppins font-bold text-[#444553] text-sm md:text-[12px] leading-normal">
            FREE trial finished!
          </h4>
        </div>
        <div className="w-full bg-[#DFDFDF] mt-[2px] rounded-full flex justify-between items-center">
          <div
            className="bg-[#F77049] text-xs font-medium text-blue-100 text-right p-[5px]  leading-none rounded-full"
            style={{ width: "97.5%" }}
          >
          </div>
        </div>
      </div>
      <div className="md:w-full xsm:p-0 md:p-0 p-2">
        {/* Shown on md screens and larger */}
        <Link
          to={Navigate_to.subscriptionPricing}
          className="hidden md:flex font-poppins font-bold text-[#34317D] text-[14px] py-2 px-3 bg-[#FFD572] rounded-[32px] leading-normal justify-center items-center gap-1"
        >
          <img src={ThunderSvg} alt="Thunder" />
          Upgrade Subscription
        </Link>

        {/* Shown on smaller than md screens */}
        <Link
          onClick={() => setIsOpen && setIsOpen(false)}
          to={Navigate_to.subscriptionPricing}
          className="flex md:hidden font-poppins font-bold text-[#34317D] text-[12px] py-1 px-2 bg-[#FFD572] rounded-[32px] leading-normal justify-center items-center gap-1"
        >
          <img src={ThunderSvg} alt="Thunder" />
          Upgrade Now
        </Link>
      </div>
    </div>
  ) : (
    <div
      className={`md:w-[30%] md:flex-[1] bg-white xsm:p-0 xsm:border-none px-1 rounded-[29px] gap-4 flex justify-between items-center border border-[#DDDDDD]`}
    >
      <div className="xsm:hidden p-2 w-[80%]">
        {subscriptionStatus.status === 0 && (
          <h4 className="font-poppins whitespace-nowrap font-bold text-[#444553] text-sm md:text-xs leading-normal flex justify-between items-center w-full">
            Your Usage <span className="font-medium mr-1"> {subscriptionStatus.usagePercentage}% </span>
          </h4>
        )}
        {subscriptionStatus.status === 0 && (
          <>
            <div className="w-full bg-[#DFDFDF] rounded-full mt-[2px] flex justify-between items-center">
              <div
                className="bg-[#696A6F] text-xs font-medium text-blue-100 text-right p-[6px] leading-none rounded-full"
                style={{ width: subscriptionStatus.usagePercentage + "%" }}
              >
              </div>
            </div>
          </>
        )}
      </div>
      {subscriptionStatus.status === 0 &&
        <div className="md:w-full xsm:p-0 md:px-0 md:p-0 p-2">
          <Link
            // onClick={() => setOpenSideBar(false)}
            to={Navigate_to.subscriptionPricing}
            className="xsm:hidden font-poppins font-bold text-[#34317D] text-[14px] py-2 px-3 bg-[#FFD572] rounded-[32px] leading-normal flex justify-center whitespace-nowrap items-center gap-1"
          >
            <img src={ThunderSvg} alt="Thunder" />
            Upgrade Subscription
          </Link>
          <Link
            // onClick={() => setOpenSideBar(false)}
            onClick={() => setIsOpen && setIsOpen(false)}
            to={Navigate_to.subscriptionPricing}
            className="md:hidden font-poppins font-bold text-[#34317D] text-[12px] py-1 px-2 bg-[#FFD572] rounded-[32px] leading-normal flex justify-center items-center gap-1"
          >
            <img src={ThunderSvg} alt="Thunder" />
            Upgrade Now
          </Link>
        </div>
      }
    </div>
  );
};

export default TopBarUsageComponent;
