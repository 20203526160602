import { ReactNode } from "react";

import Google from "../../../assets/Images/google.png";
import { Navigate_to } from "../../../router/routes.constants";
import { Link } from "react-router-dom";
import useLogin from "../hooks/useLogin";
import { ThreeDots } from "react-loader-spinner";
import TextInput from "../../../components/TextInput";
import { Controller } from "react-hook-form";
import { TERMS_AND_CONDITIONS_PAGE } from "../../../constant";

function SignInDialogForm(): ReactNode {
  const {
    onSignInWithCredentials,
    handleSubmit,
    onLoginWithGoogle,
    formControl,
    formErrors,
    formIsValid,
    isLoading,
    errorText,
  } = useLogin();

  return (
    <div className="py-5 px-4 md:py-0 md:px-0 w-full md:w-[660px]">
      <h2 className="md:text-[42px] md:font-extrabold text-[32px] mt-10 font-black font-poppins text-text_color py-1 md:mt-[62px]">
        Sign in to <span className="text-primary_color ">evyAI.com</span>
      </h2>
      {/* <p className="md:text-[20px] md:font-normal text-[16px] font-normal md:leading-8 leading-normal font-poppins text-text_color mt-2 md:mt-6">
        Please sign in with the same username and password that you use for the
        evyAI chrome extension.
      </p> */}
      <form
        onSubmit={handleSubmit(onSignInWithCredentials)}
        className="flex flex-col mt-8"
      >
        <div>
          <label htmlFor="email" className="labal text-start">
            Email
          </label>
          <Controller
            name="email"
            render={({ field }) => (
              <TextInput
                error={formErrors.email?.message}
                {...field}
                id="email"
                type="email"
                placeholder="example@example.com"
                className="input"
              />
            )}
            control={formControl}
          />
        </div>
        <div className="mt-6">
          <label htmlFor="password" className="labal text-start">
            Password
          </label>
          <Controller
            name="password"
            render={({ field }) => (
              <TextInput
                error={formErrors.password?.message}
                {...field}
                id="password"
                type="password"
                placeholder="• • • • • • • • • • • •"
                className="input"
              />
            )}
            control={formControl}
          />
        </div>
        <div className="text-right mt-1">
          <Link to="/forgot-password">
            <button
              type="button"
              className="text-secondry_color underline font-semibold font-poppins md:text-[18px] text-[14px]"
            >
              Forgot Password?
            </button>
          </Link>
        </div>
        {errorText && (
          <div className="my-2 text-red-500 text-center w-full">
            {errorText}
          </div>
        )}
        <button
          disabled={!formIsValid}
          type="submit"
          className={`${
            formIsValid
              ? "bg-secondry_color text-white"
              : "!bg-gray-200 text-black_color !cursor-not-allowed"
          } rounded-[10px] w-full px-6 py-[12px] md:py-[12px] font-poppins md:text-base mt-8 text-sm font-bold h-11 flex items-center justify-center`}
        >
          {!isLoading ? (
            "Sign In"
          ) : (
            <ThreeDots
              height={16}
              width={30}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass="justify-center"
              visible={true}
              ariaLabel="three-dots-loading"
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => onLoginWithGoogle()}
          className="w-full text-[#73747E] text-center border border-[#34317D] rounded-[10px] px-6 py-[10px] flex items-center justify-center md:mt-6 mt-4"
        >
          <img
            src={Google}
            alt="Google"
            className="md:w-[23px] md:h-6 w-[17px] h-[18]"
          />
          <span className="pl-[10px] text-[#34317D] font-poppins md:text-base text-sm font-bold">
            Sign In
          </span>
        </button>
      </form>

      <p className="md:text-[18px] text-[16px] font-normal md:leading-8 leading-normal font-poppins text-text_color mt-8 text-center">
        Don't have an account?{" "}
        <b className="underline">
          <Link to={Navigate_to.register}>Register</Link>
        </b>
      </p>

      <p className="text-sm leading-none font-normal font-poppins text-text_color mt-8 text-center">
        By signing in you agree to our
        <b>
          <a href={TERMS_AND_CONDITIONS_PAGE}>&nbsp;Terms & Conditions </a>
        </b>
      </p>
    </div>
  );
}

export default SignInDialogForm;
