import * as yup from 'yup';
import {
  isLinkedinProfileUrl,
  isTwitterProfileUrl,
  isValidPhoneNumber,
} from '../../../utils/validators';

export const loginFormSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required')
    .label('Email'),
  password: yup
    .string()
    .required('Password is required')
    .label('Password')
    .min(8),
});

export const registerFormSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required')
    .max(200, 'Must be within 200 characters')
    .label('First Name'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(200, 'Must be within 200 characters')
    .label('Last Name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required')
    .label('Email'),
  phoneNumber: yup
    .string()
    .max(500, 'Must be within 50 characters')
    .test('is-valid', 'Phone number is not valid', async (value) => {
      if (value?.length === 0) return true;
      const isPhoneNumberValid = isValidPhoneNumber(value ?? '');
      return isPhoneNumberValid;
    })
    .label('Phone Number'),
  linkedInAccount: yup
    .string()
    .test('is-valid', 'Not a LinkedIn Profile URL', async (value) => {
      if (value?.length === 0) return true;
      const isValidLinkedInProfileURL = isLinkedinProfileUrl(value ?? '');
      return isValidLinkedInProfileURL;
    })
    .label('LinkedIn Account'),
  password: yup.string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/\d/, "Password must contain at least one number")
  .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
  .label("Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .label('Repeat Password')
    .min(8),
});

export const accountSettingSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required')
    .max(200, 'Must be within 200 characters')
    .label('First Name'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(200, 'Must be within 200 characters')
    .label('Last Name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .label('Email'),
  phoneNumber: yup
    .string()
    .max(500, 'Must be within 50 characters')
    .test('is-valid', 'Phone number is not valid', async (value) => {
      if (value?.length === 0) return true;
      const isPhoneNumberValid = isValidPhoneNumber(value ?? '');
      return isPhoneNumberValid;
    })
    .label('Phone Number'),
  linkedInAccount: yup
    .string()
    .test('is-valid', 'Not a LinkedIn Profile URL', async (value) => {
      if (value?.length === 0) return true;
      const isValidLinkedInProfileURL = isLinkedinProfileUrl(value ?? '');
      return isValidLinkedInProfileURL;
    })
    .label('LinkedIn Account'),
  twitterAccount: yup
    .string()
    .test('is-valid', 'Not a Twitter Profile URL', async (value) => {
      if (value?.length === 0) return true;
      const isValidTwitterProfileURL = isTwitterProfileUrl(value ?? '');
      return isValidTwitterProfileURL;
    })
    .label('LinkedIn Account'),
    profilePictureUrl: yup
    .string()
});



export const forgetPasswordSchema = yup.object({
  password:  yup.string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/\d/, "Password must contain at least one number")
  .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
  .label("Password"),
  confirmPassword: yup
    .string()
    .min(8, 'Must be atleast 8 characters long')
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Email is required')
    .label('confirmPassword'),
});