import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";

import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import CopyIcon from "../../assets/Images/Copy-white.svg";
import DownArrow from "../../assets/Images/downArrow.svg";
import ArrowRight from "../../assets/Images/arrowRight.svg";
import FillStarIcon from "../../assets/Images/blue-star-fill.svg";
import TrashIcon from "../../assets/Images/Trash.svg";
import { IActivity } from "../../types/recentActivities.types";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import { Navigate_to } from "../../router/routes.constants";
import { dateFormater, toastAlertError, toastAlertSuccess } from "../../utils";
import {
  PARAMETER_KEY,
  RECENT_ACTIVITY_TYPE_TITLE,
  STORAGE_KEYS,
  TextGenerationInfo,
} from "../../config/appConfig";
import { commandImages } from "../../components/ActivityCard";
import Loader from "../../components/Loader";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { API_REQUEST_TYPE } from "../../config/appConfig";
import { COLLECTIONS } from "../../config/firebase";
import Button from "../../components/Button";
import { IPersona } from "../Persona";
import { sentryClient } from "@/Sentry/sentry";

const PostComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search);
  const isSaved = searchParams.get("isSaved");
  const {
    fetchSingleUserActivity,
    deleteActivity,
    deleteCommandData,
    fetchSingleData,
    fetchSuggestedComments,
    getPersonas,
    User,
  } = useContext(AuthContext) as AuthContext_type;

  const [command, setCommand] = useState<IActivity | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [clickedBtnIndex, setClickedBtnIndex] = useState<number | null>(null);
  const [persona,setPersona] = useState<IPersona | null>(null)

  const handleGetCommandData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        let data : IActivity;
        if (isSaved) {
          data = await fetchSingleData(
            id,
            `${COLLECTIONS.USER_ACTIVITIES}/${User?.uid}/${COLLECTIONS.COMMANDS}`
          );
        } else {
          data = await fetchSingleUserActivity(id);
        }
        
        setCommand(data);
        const personas = await getPersonas() as IPersona[];

        // because there is a typo in returned json
        // for post_ideas its personId, for posts its personaId
        const { personId, personaId } = data.activityData
        const pId = personId ?? personaId
        const persona = personas.find(persona => persona.id === pId)
        setPersona(persona || null);
      }
    } catch (error) {
      console.error(error);
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  };

  const copyTextToClipBoard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
      })
      .catch((error) => {
        console.error({ error });
        sentryClient.captureException(error)
      });
  };

  const handleDelete = async () => {
    try {
      if (id) {
        if (isSaved) {
          setIsDeleting(true);
          await deleteCommandData(id);
          toastAlertSuccess("Deleted successfully");
          navigate(`/${PROTECTED_ROUTES.home}`);
        } else {
          await deleteActivity(id);
          setIsDeleting(true);
          toastAlertSuccess("Deleted successfully");
          navigate(`/${PROTECTED_ROUTES.home}`);
        }
      }
    } catch (err) {
      console.error(err);
      sentryClient.captureException(err)
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCopy = () => {
    if (
      command?.activityType === API_REQUEST_TYPE.PROFILE_SUMMARY &&
      command?.timestamp &&
      command?.activityData &&
      command?.activityData?.linked_url &&
      command.timestamp.seconds
    ) {
      copyTextToClipBoard(
        (!isSaved
          ? command?.activityData?.generatedText
          : command?.generatedText) +
        "\n" +
        (command?.activityData?.linked_url || "") +
        "\n" +
        (dateFormater(command?.timestamp.seconds) || "")
      );
    } else if (command?.activityType === API_REQUEST_TYPE.POST_IDEAS) {
      let text = "";
      {
        JSON.parse(command.activityData.postIdeas).map(
          (item: { idea: string }) => {
            text += item.idea + "\n";
          }
        );
      }
      copyTextToClipBoard(text);
    } else {
      copyTextToClipBoard(
        !isSaved ? command?.activityData?.generatedText : command?.generatedText
      );
    }
  };

  const urlBtnText = useMemo(() => {
    if (
      API_REQUEST_TYPE.INVITATION_NOTE === command?.activityType ||
      API_REQUEST_TYPE.PROFILE_ABOUT === command?.activityType ||
      API_REQUEST_TYPE.RECOMMENDATION_TEXT === command?.activityType ||
      API_REQUEST_TYPE.PROFILE_HEADLINE === command?.activityType
    ) {
      return "Profile";
    } else if (
      API_REQUEST_TYPE.ARTICLE_COMMENT === command?.activityType ||
      API_REQUEST_TYPE.ARTICLE_COMMENT_REPLY === command?.activityType
    ) {
      return "Article";
    }
  }, [command?.activityType]);

  useEffect(() => {
    if (id) {
      handleGetCommandData();
    }
  }, [id]);

  const fetchComments = useCallback(
    async (selectedIdea: string) => {
      setIsGenerating(true);
      const data: any = {};
      data[PARAMETER_KEY.AUTHOR_NAME] = "";
      data[PARAMETER_KEY.TYPE] = API_REQUEST_TYPE.CREATE_POST;
      data[PARAMETER_KEY.POST_CREATION_TEXT] = selectedIdea;

      const response = await fetchSuggestedComments(data);
      if (response?.success) {
        const generatedText = response?.data?.[0]?.["reply"] ?? "";

        const generatedTextInfo: TextGenerationInfo = {
          activityData: data,
          generatedText,
          activityType: API_REQUEST_TYPE.CREATE_POST,
        };

        if (generatedTextInfo) {
          localStorage.setItem(
            STORAGE_KEYS.LAST_PERSONA_USED,
            command?.activityData?.personId
          );
          navigate(`/${PROTECTED_ROUTES.createPost}`, {
            state: {
              generatedTextInfo,
              selectedPersona: command?.activityData?.personId,
              selectedIdea,
            },
          });
        }

        setIsGenerating(false);
      } else {
        setIsGenerating(false);

        if (response?.responseCode === 429) {
          toastAlertError(
            response?.error?.reason ??
            "Some error occurred. Please try again later."
          );
          return;
        }
        toastAlertError(
          response?.error?.reason ??
          "Some error occurred. Please try again later."
        );
      }
    },
    [command?.activityData]
  );

  if (isLoading) {
    return (
      <div className="flex items-start justify-center">
        <Loader size={10} />
      </div>
    );
  }

  return (
    <section>
      <div className="flex">
        <Link
          to={Navigate_to.home}
          className="flex justify-start items-center cursor-pointer"
        >
          <h2 className="font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] ml-2 opacity-60">
            Home
          </h2>
          <img
            className="ml-2 md:w-[14px] w-3 opacity-60"
            src={ArrowRight}
            alt=""
          />
        </Link>

        <Link
          to={isSaved ? Navigate_to.home : Navigate_to.recentActivities}
          className="flex justify-start items-center cursor-pointer"
        >
          <h2 className="font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] ml-2 opacity-60">
            {isSaved ? "Saved Items" : "Recent Activities"}
          </h2>
        </Link>
        <img
          className="ml-2 md:w-[14px] w-3 opacity-60"
          src={ArrowRight}
          alt=""
        />
        <h2 className="font-poppins md:text-[16px] text-[14px] font-bold leading-[24px] text-[#444553] ml-2">
          {command && RECENT_ACTIVITY_TYPE_TITLE[command.activityType]}
        </h2>
      </div>
      <div className="bg-white rounded-[16px] md:p-8 p-4 w-full mt-6 md:mt-9">
        <div className="flex justify-between items-center">
          <div className="flex items-center p-0 ">
            <div className="p-[7.5px] rounded-md bg-chip_bg">
              <img
                className="md:h-[17.8px] h-[15.8px] md:w-[17.8px] w-[15.8px]"
                src={command ? commandImages[command.activityType] : ""}
                alt="Post"
              />
            </div>
            <span className="font-poppins font-black text-[24px] text-[#333] ml-2 ">
              {command && RECENT_ACTIVITY_TYPE_TITLE[command.activityType]}
            </span>
          </div>
          {isSaved && (
            <img
              className="ml-4 cursor-pointer md:w-5 w-4"
              src={FillStarIcon}
              onClick={(event) => {
                event.stopPropagation();
              }}
              alt="Copy"
            />
          )}
        </div>
        <div className="mt-8">
          {API_REQUEST_TYPE.PROFILE_SUMMARY !== command?.activityType && (
            <>
              <div className="md:mt-8 mt-6">
                <label
                  htmlFor="name"
                  className="text-sm font-poppins font-bold md:text-[20px] md:font-semibold leading-5 md:leading-8  md:text-[#444553] text-[#333] mb-1"
                >
                  Command to Evy
                </label>

                <div className="border border-gray-300 rounded-[10px] w-full  py-3 px-4 md:text-[16px] text-[14px] font-poppins text-[#444553B3]  bg-transparent">
                  {
                    command?.activityData?.postCreationText || command?.activityData?.summaryText ||
                    "Enter a prompt with instructions for the type of post you would like evyAI to create."
                  }
                </div>
              </div>
              {
                command?.activityType != "post_ideas" &&
                  <div className="md:mt-6 md:flex md:gap-10 md:justify-center md:items-center mt-4 mb-1">
                <label
                  htmlFor="name"
                  className=" md:text-lg md:font-semibold text-sm font-bold text-[#333] font-poppins"
                >
                  Goal
                </label>
                <div className="relative md:inline-flex self-center w-full">
                  <img
                    className="text-primary_color absolute top-0 right-0 mr-2 mt-1 cursor-pointer pointer-events-none p-2 rounded"
                    src={DownArrow}
                    alt=""
                  />

                  <div className="border-[0.5px] border-[#cbccd0] rounded-[10px] md:h-[48px] h-[45px] pl-5 pr-10 bg-white  md:text-[16px] text-[14px] font-poppins text-[#444553B3] w-full leading-normal flex items-center">
                    {command?.activityData?.goal}
                  </div>
                </div>
                  </div>
              }
              <div className="md:flex md:mt-6 mt-4">
                {
                  command?.activityType != "post_ideas" &&
                    <div className=" md:flex md:gap-10 md:justify-center md:items-center md:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className=" md:text-lg md:font-semibold text-sm font-bold text-[#333] font-poppins"
                  >
                    Tone
                  </label>
                  <div className="relative md:inline-flex self-center  w-full">
                    <img
                      className="text-primary_color cursor-pointer absolute top-0 right-0 mr-2 mt-1 pointer-events-none p-2 rounded"
                      src={DownArrow}
                      alt=""
                    />
                    <div className="border-[0.5px] border-[#cbccd0] rounded-[10px] md:h-[48px] h-[45px] pl-5 pr-10 bg-white appearance-none md:text-[16px] text-[14px] font-poppins text-[#444553B3] focus:outline-none w-full leading-normal flex items-center">
                      {command?.activityData?.tone}
                    </div>
                  </div>
                    </div>
                }
                <div className="md:flex gap-10 md:justify-center md:items-center md:w-1/2 w-full md:ml-6 mt-4 md:mt-0">
                  <label
                    htmlFor="name"
                    className=" md:text-lg md:font-semibold text-sm font-bold text-[#333] font-poppins"
                  >
                    Persona
                  </label>
                  <div className="relative md:inline-flex self-center w-full">
                    <img
                      className="text-primary_color absolute top-0 right-0 mr-2 mt-1 pointer-events-none p-2 rounded cursor-pointer"
                      src={DownArrow}
                      alt=""
                    />
                    <div className="border-[0.5px] border-[#cbccd0] rounded-[10px] md:h-[48px] h-[45px] pl-5 pr-10 bg-white appearance-none md:text-[16px] text-[14px] font-poppins text-[#444553B3] focus:outline-none w-full leading-normal flex items-center">
                      {persona?.personaName}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" block w-full py-8">
                <hr className="border-t border-primary_color" />
              </div>
            </>
          )}
          {command?.activityType === API_REQUEST_TYPE.POST_IDEAS ? (
            <div className="flex flex-col gap-3">
              {JSON.parse(command.activityData.postIdeas).map(
                (item: { idea: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#F5F1FB] p-6 rounded-[16px]"
                    >
                      <p className="font-poppins text-[#121212] md:text-[18px] text-sm leading-normal ext-read-only read-write-plaintext-only">
                        {item.idea}
                      </p>
                      {command?.activityType ===
                        API_REQUEST_TYPE.PROFILE_SUMMARY &&
                        command?.timestamp &&
                        command?.activityData &&
                        command?.activityData?.linked_url &&
                        command.timestamp.seconds && (
                          <>
                            <p
                              className="text-base font-semibold leading-tight underline mt-2"
                              style={{ color: "rgb(52, 49, 125)" }}
                            >
                              {command.activityData.linked_url}
                            </p>
                            <p className="mt-3">
                              {dateFormater(command.timestamp.seconds)}
                            </p>
                          </>
                        )}
                      <div className="flex justify-between mt-3">
                        <p className="pt-6 font-poppins text-[#12121280] text-[14px] leading-normal">
                          {item.idea.split(" ")?.length}/268 Characters
                        </p>{" "}
                        <button
                          className="px-3 md:px-6 md:py-[10px] rounded-[10px] border border-secondry_color text-sm md:text-lg font-bold leading-normal bg-secondry_color text-white flex items-center justify-center w-[125px] md:w-fit disabled:opacity-50"
                          onClick={() => {
                            setClickedBtnIndex(index);
                            fetchComments(item.idea);
                          }}
                          disabled={clickedBtnIndex === index && isGenerating}
                        >
                          {clickedBtnIndex === index && isGenerating ? (
                            <Loader size={4} />
                          ) : (
                            "Generate Post"
                          )}
                        </button>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className="bg-[#F5F1FB] p-6 rounded-[16px]">
              <p className="font-poppins text-[#121212] text-sm md:text-lg pt-2 leading-normal ext-read-only read-write-plaintext-only">
                {!isSaved
                  ? command?.activityData?.generatedText
                  : command?.generatedText}
              </p>
              {command?.activityType === API_REQUEST_TYPE.PROFILE_SUMMARY &&
                command?.timestamp &&
                command?.activityData &&
                command?.activityData?.linked_url &&
                command.timestamp.seconds && (
                  <>
                    <p
                      className="text-base font-semibold leading-tight underline mt-2"
                      style={{ color: "rgb(52, 49, 125)" }}
                    >
                      {command.activityData.linked_url}
                    </p>
                    <p className="mt-3">
                      {dateFormater(command.timestamp.seconds)}
                    </p>
                  </>
                )}
              <p className="pt-6 font-poppins text-[#12121280] text-[14px] leading-normal">
                {!isSaved
                  ? command?.activityData?.generatedText?.split(" ")?.length
                  : command?.generatedText?.split(" ")?.length}
                /268 Characters
              </p>
              {command?.activityType === API_REQUEST_TYPE.POST_IDEA && (
                <div className="flex justify-end mt-3">
                  {" "}
                  <Button
                    className="px-6 py-[10px] items-center rounded-[10px] border border-secondry_color text-sm md:text-lg font-bold leading-normal bg-secondry_color text-white min-w-[125px] md:w-fit disabled:opacity-50"
                    onClick={() =>
                      fetchComments(
                        !isSaved
                          ? command?.activityData?.generatedText?.split(" ")
                            ?.length
                          : command?.generatedText?.split(" ")?.length
                      )
                    }
                    disabled={isGenerating}
                  >
                    <span>{isGenerating ? <Loader size={4} /> :  <span>Generate Post</span>}</span>
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className="mt-6 flex flex-col-reverse md:flex-row gap-4 justify-end flex-wrap">
            {command?.activityType === API_REQUEST_TYPE.PERSPECTIVE_COMMENT &&
              command?.activityData?.article_url && (
                <button
                  onClick={() =>
                    window.open(command?.activityData?.article_url, "_blank")
                  }
                  type="button"
                  className="text-[#34317D] text-center border border-[#34317D] rounded-[10px] px-6 py-[10px] flex items-center justify-center mt-4 disabled:opacity-50 mr-6"
                >
                  {/* <img src={SendIcon} alt="Google" /> */}
                  <span className="pl-1 font-poppins text-[18px] font-bold">
                    View Article
                  </span>
                </button>
              )}
            {command?.activityData?.url && (
              <button
                onClick={() =>
                  window.open(command?.activityData?.url, "_blank")
                }
                type="button"
                className="text-[#34317D] text-center md:border border-[#34317D] md:w-fit rounded-[10px] px-6 py-[10px] w-full flex items-center justify-center disabled:opacity-50"
              >
                <AiOutlineLink
                  size={24}
                  color="#34317D"
                  className="cursor-pointer mr-1"
                />
                <span className="pl-1 font-poppins md:text-[16px] text-sm font-bold underline md:no-underline">
                  View {urlBtnText}
                </span>
              </button>
            )}

            <button
              disabled={isDeleting}
              onClick={() => setShowDeleteDialog(true)}
              type="button"
              className="text-[#34317D] text-center border border-[#34317D] md:w-fit rounded-[10px] px-6 py-[10px] w-full flex items-center justify-center disabled:opacity-50"
            >
              <img src={TrashIcon} alt="trash" />
              <span className="pl-1 font-poppins md:text-[16px] text-sm font-bold">
                Delete
              </span>
            </button>
            <button
              onClick={() => handleCopy()}
              className="bg-secondry_color text-white rounded-[10px] px-6 py-[10px] font-poppins md:text-[18px] text-[10px]  md:w-fit w-full font-bold flex items-center justify-center"
            >
              <img src={CopyIcon} alt="trash" />
              <span className="pl-1 font-poppins md:text-[16px] text-sm font-bold">
                Copy
              </span>
            </button>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        setShow={setShowDeleteDialog}
        isLoading={isDeleting}
        show={showDeleteDialog}
        onConfirm={handleDelete}
        header="Delete Activity"
        content="Are you sure you want to delete this activity?"
      />
    </section>
  );
};

export default PostComponent;
