import Loader from "./Loader";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: () => void;
  isLoading?: boolean;
  show: boolean;
  content?: string;
}
const WarningDialogue = ({
  setShow,
  show,
  isLoading,
  onConfirm,
  content,
}: IProps) => {
  return (
    <>
      {show ? (
        <>
          <div className="fixed inset-0 zindex overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-[#34317D] opacity-[0.8]"
              onClick={() => setShow(false)}
            ></div>
            <div className="flex items-center min-h-screen">
              <div className="relative w-[338px]  mx-auto bg-white rounded-[16px] shadow-lg p-6">
                <button
                  type="button"
                  className="absolute top-[16px] right-[16px]"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    onClick={() => setShow(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <div className="text-center sm:text-left">
                  <p className="text-[24px]  text-[#6E40C5] font-poppins leading-relaxed text-center text-lg font-[900]">
                    {content
                      ? content
                      : "Are you sure you want to delete this activity?"}
                  </p>
                  <div className="items-center flex md:flex-row gap-2 xsm:flex xsm:flex-col">
                    <button
                      className="w-[140px] mt-2 px-6 py-2.5 flex-1 text-center text-blue-800  font-bold rounded-[10px] outline-none border ring-offset-2"
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      className="w-[140px] mt-2 px-6 py-2.5 text-center text-white bg-blue-900  outline-none ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
                      onClick={onConfirm}
                    >
                      {isLoading ? <Loader size={6} /> : "Delete"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default WarningDialogue;
