import DailogBox from "./DailogBox";
import Warning from "../assets/Images/warningsvg.svg";
import Loader from "./Loader";
interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: () => void;
  show: boolean;
  header?: string;
  content?: string;
  isLoading?: boolean;
}
const ConfirmationDialog = ({
  setShow,
  show,
  onConfirm,
  header,
  content,
  isLoading,
}: IProps) => {
  function handleClose() {
    setShow(false);
  }
  return (
    <DailogBox
      dialogClass="!z-[100]"
      className="flex flex-col items-center justify-center !gap-0 !max-w-[450px]"
      open={show}
      onClose={handleClose}
    >
      <div className="flex flex-col items-center gap-2">
        <img src={Warning} style={{ width: "54px", height: "60px" }} />
      </div>
      <div className="mt-6 flex flex-col justify-between items-center">
        <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
          {header}
        </h2>
        <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
          {content}
        </p>
      </div>
      <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
        <button
          type="button"
          className="w-full  md:max-w-[140px] px-6 py-4 flex-1 text-center text-[#34317D] font-bold rounded-[10px] border border-[#34317D]"
          onClick={() => handleClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="w-full md:max-w-[140px] px-6 py-4 text-center  !text-white !bg-blue-900 outline-none border ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
          // className="w-full bg-button_darkBlue_color md:max-w-[140px] disabled:opacity-50"
          onClick={onConfirm}
        >
          {isLoading ? <Loader size={6} /> : "Delete"}
        </button>
      </div>
    </DailogBox>
  );
};
export default ConfirmationDialog;
