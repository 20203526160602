import { useContext, useEffect, useState } from "react";
import PersonaPicUpload from "../../../assets/Images/PersonaPicUpload.svg";
import Camera from "../../../assets/Images/Camera.svg"
import CustomizeSelect from "../../../components/CustomizeSelect";
import FileUploadInput from "../../../components/FileUploadInput";
import TextInput from "../../../components/TextInput";
import { TONES } from "../../../config/appConfig";
import { Controller } from "react-hook-form";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ActionCard from "./ActionCard";
import deleteIcon from "../../../assets/Images/Trash.png";
import { toastAlertSuccess } from "../../../utils";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { sentryClient } from "@/Sentry/sentry";

const AddPersonaForm = (props: any) => {
  const {
    formControl,
    profilePictureUrl,
    setProfilePictureUrl,
    formErrors,
    setSelectedTags,
    selectedTags,
    isSubmitting,
    isLoading,
  } = props;
  const { handleUploadPersonaImage } = useContext(
    AuthContext
  ) as AuthContext_type;
  const { personaId } = useParams();

  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showTagError, setTagError] = useState(false);

  const navigate = useNavigate();
  const { deletePersona } = useContext(AuthContext) as AuthContext_type;

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDeletePersona = async () => {
    try {
      setIsDeleting(true)
      if (personaId) {
        await deletePersona(personaId);
        setShowConfirmation(false);
        toastAlertSuccess("Deleted Successfully!");
        navigate(`/${PROTECTED_ROUTES.Personas}`);
      }
    } catch (error) {
      sentryClient.captureException(error)
    } finally {
      setIsDeleting(false)
    }
  };

  const handleUploadImage = async (event: any) => {
    try {
      setIsUploading(true);
      let file = URL.createObjectURL(event.target.files[0]);
      const response = await handleUploadPersonaImage(file);
      setProfilePictureUrl(response);
    } catch (error) {
      console.log(error, "error");
      sentryClient.captureException(error)
    } finally {
      setIsUploading(false);
    }
  };

  const handleSelectTag = (item: string) => {
    setSelectedTags(item);
  };

  // const handleDeleteImage = async () => {
  //   try {
  //     if (profilePictureUrl) {
  //       setIsDeleting(true);
  //       await deleteImageFromStorage(profilePictureUrl);
  //       setProfilePictureUrl("");
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   } finally {
  //     setIsDeleting(false);
  //   }
  // };

  useEffect(() => {
    if (isSubmitting && !selectedTags) {
      setTagError(true);
    }
  }, [isSubmitting, selectedTags]);

  return (
    <div className="flex justify-between my-6 gap-6">
      <div className="w-full max-w-[1080px] p-8 bg-white rounded-lg flex flex-col gap-y-6">
        <div>
          <h2 className="font-poppins font-bold text-[22px]">
            {personaId ? "Edit Persona" : "Add persona"}
          </h2>

          <p className="font-poppins text-[18px] text-[#333333] mt-4">
            <a style={
              {
                color: "#6D40C4",
                fontWeight: "600",
              }
            } className="" href="https://evyai.com/personalize-to-shine-linkedin-evyai/" target="_blank" >Click here</a> to learn how to fill in your Background and History
          </p>
        </div>
        <div>
          <div className="flex justify-between items-center xsm:flex-col xsm:items-start xsm:gap-8 gap-x-4 mt-2">
            <div className="img-wrap w-[170px] h-[140px] flex justify-center items-center overflow-hidden rounded-[10px]">
              {isUploading ? <Loader size={10} /> :
                <img className="rounded-lg w-full h-full object-contain" src={profilePictureUrl ? profilePictureUrl : PersonaPicUpload} alt="" />}
              <p className="img-description flex justify-center items-center">
                <FileUploadInput
                  onChange={(event) => handleUploadImage(event)}
                  name="uploadImage"
                  className="flex justify-center items-center"
                >
                  <button
                    type="button"
                    disabled={isUploading}
                    className="disabled:opacity-50 flex flex-col justify-center items-center gap-2"
                  >
                    <img src={Camera} alt="" />
                    {/* {profilePictureUrl ? "Update Picture" : "Add Picture"} */}
                  </button>
                </FileUploadInput>
              </p>
            </div>
            <div className="flex flex-col justify-between w-full gap-3">
              <div className="w-full">
                <label htmlFor="personaName" className="form-label ">
                  <p className="text-[18px] font-semibold">
                    Persona Name <span className="text-[#EB5757]">*</span>
                  </p>
                </label>
                <Controller
                  name="personaName"
                  render={({ field }) => (
                    <TextInput
                      error={formErrors.personaName?.message}
                      {...field}
                      id="personaName"
                      name="personaName"
                      type="text"
                      placeholder="Ex: Ajax Union"
                      className="input xsm:text-[16px] xsm:px-4"
                    />
                  )}
                  control={formControl}
                />
              </div>
              <div className="flex justify-start items-center xsm:flex-col xsm:items-start xsm:gap-2 gap-6 ">
                <label htmlFor="personaName" className="form-label">
                  <p className="text-[18px] font-semibold">
                    Tags <span className="text-[#EB5757]">*</span>
                  </p>
                </label>
                <div className="flex items-center gap-x-2 mt-2">
                  {["Person", "Company"].map((item) => {
                    return (
                      <div
                        onClick={() => handleSelectTag(item)}
                        className={`py-1 pl-2 pr-4 rounded-[30px] cursor-pointer ${selectedTags === item
                          ? "bg-[#6E40C5] text-white"
                          : "text-[#6D40C4] border bg-[#F5F1FB] border-[#6E40C5] bg-primary-color-lighter-purple"
                          }  w-fit  font-poppins flex items-center gap-x-2`}
                      >
                        <span>
                          {selectedTags !== item ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <circle cx="8" cy="8" r="7.5" stroke="#6E40C5" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <circle cx="8" cy="8" r="8" fill="white" />
                              <path
                                d="M10.8613 5.09131C10.5895 5.09131 10.334 5.19722 10.1417 5.38953L6.37646 9.16095L5.3729 8.15748C5.18077 7.96531 4.9253 7.85944 4.65356 7.85944C4.38179 7.85944 4.12632 7.96531 3.93414 8.15744C3.74201 8.34957 3.63623 8.60504 3.63623 8.87677C3.63623 9.14851 3.74201 9.40397 3.93414 9.59606L5.65726 11.3192C5.84939 11.5113 6.10486 11.6172 6.37663 11.6172C6.64828 11.6172 6.90383 11.5113 7.09619 11.319L11.5807 6.82806C11.9773 6.43135 11.9772 5.78593 11.5807 5.38922C11.3885 5.19713 11.133 5.09131 10.8613 5.09131Z"
                                fill="#6E40C5"
                              />
                            </svg>
                          )}
                        </span>
                        <span className="text-16 font-semibold leading-normal ">
                          {item}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {showTagError && !selectedTags && (
                  <p className="text-sm font-medium text-rose-600 mt-1 px-1">
                    Tag is required
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='my-3 md:block hidden xsm:block'>
          <hr className='border-t-1 w-full xsm:border-[#6E40C5] border-border_color' />
        </div>

        <div className="flex justify-between items-center gap-6 xsm:flex-col">
          <div className="w-full">
            <label htmlFor="personaName" className="form-label">
              <p className="text-[18px] font-semibold">
                Industry
              </p>
            </label>
            <Controller
              name="industry"
              render={({ field }) => (
                <TextInput
                  {...field}
                  name="industry"
                  type="text"
                  placeholder="Industry"
                  className="input xsm:text-[16px] xsm:px-4"
                  error={formErrors.industry?.message}
                />
              )}
              control={formControl}
            />
          </div>
          <div className="w-full">
            <label htmlFor="personaName" className="form-label">
              <p className="text-[18px] font-semibold">
                Tone and Personality
              </p>
            </label>
            <Controller
              name="tone"
              render={({ field }) => (
                <CustomizeSelect
                  options={TONES}
                  label=""
                  {...field}
                  name="tone"
                  type="text"
                  className="input xsm:text-[16px] xsm:px-4"
                  placeholder="Add tone"
                  error={formErrors.tone?.message}
                />
              )}
              control={formControl}
            />
          </div>
        </div>

        <div>
          <label className="form-label">
            <p className="text-[18px] font-semibold">
              Keywords and Phrases
            </p>
          </label>

          <Controller
            name="keywordsPhrases"
            render={({ field }) => (
              <TextInput
                error={formErrors.keywordsPhrases?.message}
                {...field}
                id="keywordsPhrases"
                name="keywordsPhrases"
                type="text"
                placeholder="Ex: Fast-growing"
                className="input xsm:text-[16px] xsm:px-4"
              />
            )}
            control={formControl}
          />
        </div>

        <div className='my-3 md:block xsm:block hidden'>
          <hr className='border-t-1 xsm:border-[#6E40C5] md:border-[#6E40C5] border-border_color' />
        </div>

        <div>
          <label className="form-label">
            <p className="text-[18px] font-semibold">
              Background and History <span className="text-[#EB5757]">*</span>
            </p>
          </label>
          <Controller
            name="backgroundHistory"
            render={({ field }) => (
              <div>
                <textarea
                  {...field}
                  id="backgroundHistory"
                  name="backgroundHistory"
                  placeholder="Enter in bullet points or a short sumary about this persona, up to 2,000 characters."
                  className="input mt-1 xsm:text-[16px] xsm:px-4 xsm:leading-[21px]"
                  rows={15}
                  maxLength={2000}
                />
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium text-rose-600 mt-1 px-1">
                    {formErrors.backgroundHistory?.message}
                  </p>
                  <p className="text-sm font-medium mt-1 px-1">
                    {" "}
                    {field.value.length} / 2000
                  </p>
                </div>
              </div>
            )}
            control={formControl}
          />
        </div>
        <div className="flex justify-between items-center xsm:flex-col xsm:items-start">
          <h4 className="form-label !text-red-600 xsm:hidden">*Required</h4>
          <div className="md:hidden flex flex-col items-center justify-center w-full gap-4">
            <button
              disabled={isLoading}
              type="submit"
              className="btn-primary mt-4 disabled:opacity-50 w-full"
            >
              {isLoading && <Loader color="fill-white" size={5} />}
              {personaId ? "Update Persona" : "Save Persona"}
            </button>
            <button
              onClick={() => setShowConfirmation(true)}
              disabled={!personaId}
              type="button"
              className="btn-secondary disabled:opacity-50 w-full"
            >
              <img src={deleteIcon} className="mr-[10px]" />
              Delete Persona
            </button>
          </div>
        </div>
      </div>
      <ActionCard isLoading={isLoading} />
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={handleDeletePersona}
        isLoading={isDeleting}
        header="Delete Persona"
        content="Are you sure you want to delete this Persona?"
      />
    </div>
  );
};

export default AddPersonaForm;
