import { useState, useContext, useEffect } from "react";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import TextInput from "../../../components/TextInput";
import DailogBox from "../../../components/DailogBox";
import Loader from "../../../components/Loader";
import { sentryClient } from "@/Sentry/sentry";

export interface CreateTeamProps {
    setShowAlert: (status: boolean) => void;
    showAlert: boolean;
    team?: any | null;
    onDataChanged: () => void;
}

export default function CreateTeam(props: CreateTeamProps) {
    const { setShowAlert, showAlert, team, onDataChanged } = props;
    const { createTeam, editTeamName } = useContext(AuthContext) as AuthContext_type;
    const [teamName, setTeamName] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (team) {
            setTeamName(team.name.trim());
        }
    }, [team]);

    function handleCloseDialog() {
        setShowAlert(false);
    }

    function handleListNameChange(value: string) {    
        if (value.trim().length > 0) {
            setError("");
            setTeamName(value);
        } else {
            setError("Team Name is required**");
            setTeamName("");
        }
    }

    async function handleSubmit() {
        try {
            setLoading(true);
            if (team) {
                console.log("Team", teamName)
                await editTeamName(team.id, teamName.trim());
                toastAlertSuccess("Team name updated successfully");
            } else {
                console.log("else hit")
                await createTeam(teamName.trim());
                toastAlertSuccess("Team created successfully");
            }
            setLoading(false);
            handleCloseDialog();
            onDataChanged()
        } catch (error:any) {
            setLoading(false);
            toastAlertError(error.message)
            console.log(error, "error");
            sentryClient.captureException(error)
        }
    }

    useEffect(() => {
        if (teamName.trim().length > 0) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [teamName])
    

    return (
        <DailogBox
            className='flex flex-col items-center justify-center !max-w-[644px]'
            open={showAlert}
            onClose={handleCloseDialog}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <div className='flex flex-col items-center gap-2'>
                    <h2 className='font-bold xsm:font-black xsm:text-[28px] text-[15px] text-[#333333] text-[32px] font-poppins'>
                        {team ? "Edit Team Name" : "Team Name"}
                    </h2>
                    <p className='text-base xsm:text-[14px] md:w-[55%] xsm:leading-[21px] font-poppins text-[#333333]'>
                        The team name will be shown to all team members.
                    </p>
                </div>
                <div className="items start mt-3">
                    <TextInput
                        sx={{ width: "600px"}}
                        className="w-full xsm:text-[14px]"
                        labelClassName="!font-medium text-base xsm:text-[14px] xsm:font-semibold flex w-[600px] xsm:w-full"
                        placeholder="Ex: Marketing Team"
                        error={error}
                        value={teamName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleListNameChange(e.target.value);
                        }}
                        type="text"
                        label="Team Name"
                    />
                </div>
                <div className="flex gap-4 mt-4 justify-center xsm:w-full xsm:flex-col-reverse">
                    <button
                        type='button'
                        onClick={() => handleCloseDialog()}
                        className='font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-transparent text-secondry_color disabled:opacity-50'
                    >
                        Cancel
                    </button>
                    <button
                        type='submit'
                        disabled={(team && teamName === team.name) || !isDisabled}
                        className='font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50'
                    >
                        {loading ? <Loader size={5} /> : team ? "Edit Team Name" : "Add Team"}
                    </button>
                </div>
            </form>
        </DailogBox>
    );
}
