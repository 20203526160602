import { useContext, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
// import { toastAlertError, toastAlertSuccess } from "../../../utils";
// import { firebaseAuth } from "../../../context/Auth";
import { templateSchema } from "../utils/schema/template";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { useNavigate, useParams } from "react-router-dom";
import { COLLECTIONS, SubscriptionStatusDB } from "../config/firebase";
import { ITemplate } from "../pages/templates";
import { toastAlertError, toastAlertSuccess } from "../utils";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import { Tags } from "../pages/AddTemplate/AddTemplate";
import { SubscriptionStatus } from "../config/appConfig";
import { sentryClient } from "@/Sentry/sentry";

function useValidateTemplate() {
  const { addTemplate, editTemplate, User, fetchSingleData, getTagById, subscriptionStatus } = useContext(
    AuthContext
  ) as AuthContext_type;

  const {
    handleSubmit,
    control: formControl,
    getValues,
    reset,
    formState: { errors: formErrors, isValid: formIsValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      templateName: "",
      templateTags: "",
      templateContent: "", 
    },
    resolver: yupResolver(templateSchema),
  });
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplate, setIsloadingTemplate] = useState(false);
  const [selectedTagsDoc, setSelectedTagsDoc] = useState<Tags[] | null>([]);
  const [selectedTags, setSelectedTags] = useState<string[] | Tags[] | null>([]);
  const [selectedTagsNames, setSelectedTagsNames] = useState<string[] | null>([]);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const [allExistingTemplates, setAllExistingTemplates] = useState<ITemplate[]>([])

  const addTags = (tags:Tags[]) => {
    const tagIds = tags.map(tag => tag.id);
    setSelectedTags(tagIds)
  }

  function canAddNewTemplate(templates_count: any) {
    
    if (subscriptionStatus) {
      if (
        templates_count >= 10 &&
        subscriptionStatus.status === SubscriptionStatus.PRO &&
        subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE
      ) {
        setShowUpdatePaymentDialog(true);
        return false
      } else if (
        templates_count >= 10 &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        return false;
      } else if (
        templates_count >= 1000 &&
        subscriptionStatus.status !== SubscriptionStatus.FREE
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const onSubmit = async () => {
      if (!templateId && (subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE)) {
        const canAddTemplate = canAddNewTemplate(allExistingTemplates.length)
        if (!canAddTemplate) {
          setShowUpdatePaymentDialog(true);
          return;
        }
      }
      const formValues = getValues();
      // console.log("FORM VALUES", formValues);
      // console.log("DATA TO SEND", {
      //   templateName: formValues.templateName,
      //   templateTags: selectedTags,
      //   templateDescription: formValues.templateDescription,
      //   templateContent: formValues.templateContent,
      // });
      
      try {
        setIsLoading(true);
        if (User?.uid && templateId) {
          await editTemplate(templateId, {
            templateName: formValues.templateName,
            templateTags: selectedTags,
            templateContent: formValues.templateContent,
          });
          toastAlertSuccess("Updated Successfully!");
          navigate(`/${PROTECTED_ROUTES.templates}`);
        } else if (User?.uid) {
          await addTemplate({
            templateName: formValues.templateName,
            templateTags: selectedTags,
            templateContent: formValues.templateContent,
          });
          toastAlertSuccess("Added Successfully!");
          navigate(`/${PROTECTED_ROUTES.templates}`);
        }
      } catch (error:any) {
        toastAlertError(error.message);
        console.log(error, "error!");
        sentryClient.captureException(error)
      } finally {
        setIsLoading(false);
      }
  };

  const handleFetchData = async () => {
    try {
      if (templateId) {
        setIsloadingTemplate(true);

        const data: ITemplate = await fetchSingleData(
          templateId,
          `/${COLLECTIONS.TEMPLATES}/${User?.uid}/${COLLECTIONS.TEMPLATES}`
        );

        const tags = await Promise.all((data.templateTags as string[]).map(async (id) => {
          return await getTagById(id)
        }));

        const tagsIds = (tags as any).map((tag: Tags) => tag.id)
        const tagsNames = (tags as any).map((tag: Tags) => tag.name)
        
        setSelectedTagsDoc(tags);
        setSelectedTags(tagsIds);
        setSelectedTagsNames(tagsNames);
        const formValues = getValues();
        const newValue = {
          ...formValues,
          templateName: data.templateName,
          templateTags: "",
          templateContent: data.templateContent,
        };
        reset(newValue);
      }
    } catch (error) {
      console.log(error, "err!");
      sentryClient.captureException(error)
    } finally {
      setIsloadingTemplate(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      handleFetchData();
    }
  }, [User, templateId]);

  return {
    onSubmit,
    handleSubmit,
    formControl,
    formErrors,
    isLoading,
    formIsValid,
    isLoadingTemplate,
    isSubmitting,
    getValues,
    reset,
    selectedTags,
    selectedTagsDoc,
    selectedTagsNames,
    addTags,
    showUpdatePaymentDialog,
    setShowUpdatePaymentDialog,
    setAllExistingTemplates,
  };
}

export default useValidateTemplate;
