import { GiCheckMark } from 'react-icons/gi';
import LinkBtn from '../components/LinkBtn';
import vector from '../assets/vector.svg';
import Header from '../components/Header';
import { LOGIN_PAGE } from '../constant';

const PasswordSuccess = () => {
  return (
    <div className='h-screen container mx-auto'>
      <Header />
      <hr className="border-t border-[#DFDFDF] md:hidden" />
      <div className='h-[calc(100vh-93px)] py-12 md:items-center justify-center flex max-w-lg mx-auto'>
        <div className='w-full max-sm:px-4 max-md:pt-32 md:pb-40'>
          <div className='relative flex justify-center py-8'>
            <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
              <GiCheckMark className='text-5xl text-white' />
            </div>
            <img src={vector} className='logo text-slate-500' alt='Vite logo' />
          </div>
          <h2 className="text-[32px] md:text-5xl text-text_color text-center font-black font-poppins leading-[40px]">
            Password Changed
          </h2>
          <h4 className="font-poppins md:text-xl text-center md:mt-6 text-base mt-4">
            You can now sign in with your new password
          </h4>
          <div className='mt-16 justify-center flex'>
            <LinkBtn
            className="bg-secondry_color text-white py-[10px] px-6 rounded-[10px] w-full font-poppins md:text-base  text-sm font-bold flex items-center justify-center"
              link={LOGIN_PAGE}
              title='Go to the Homepage'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordSuccess;
