import "./style.css";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Navigate_to } from "../../../router/routes.constants";
import backArrow2 from "../../../assets/Images/back-arrow2.svg";
import useRegister from "../hooks/useRegistration";
import TextInput from "../../../components/TextInput";
import { TERMS_AND_CONDITIONS_PAGE } from "../../../constant";
import TickIcon from "../../../assets/Icons/CheckIcon.svg";
import CheckIconGrey from "../../../assets/Icons/CheckIconGrey.svg";
import { useContext } from "react";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";


function Register() {
  const { emailToChange } = useContext(AuthContext) as AuthContext_type;
  const {
    setTND,
    handleSubmit,
    onSubmit,
    isLoading,
    formControl,
    signUpError,
    formIsValid,
    TND,
    formErrors,
  } = useRegister(emailToChange);

  const Requirement = ({
    satisfied,
    text,
  }: {
    satisfied?: boolean;
    text: string;
  }) => (
    <p
      className={`text-base font-normal flex items-center gap-x-2 font-poppins  mt-1 px-1 ${
        !satisfied ? "text-[#696A6F]" : "text-[#333]"
      }`}
    >
      <img
        src={satisfied ? TickIcon : CheckIconGrey}
        className="cursor-pointer"
        alt="Trash"
      />
      {text}
    </p>
  );

  return (
    <div className="mx-auto md:mb-20 md:mt-[71px]">
      <div>
        <Link
          to={Navigate_to.login}
          className="items-center justify-start  text-text_color md:mt-[62] mt-10 flex cursor-pointer"
        >
          <img
            className="md:w-6 md:h-6 w-[18px] h-[18px]"
            src={backArrow2}
            alt="Back Arrow"
          />
          <span className="ml-3 font-poppins md:text-[18px] text-[16px] font-bold">
            Back to Login
          </span>
        </Link>
      </div>

      <div className="md:py-0 md:px-0">
        <h2 className="md:text-[42px] md:font-extrabold text-[32px] md:mt-6 mt-8 font-poppins font-black text-text_color">
          {" "}
          Register
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-8">
          <div>
            <label htmlFor="firstName" className="labal">
              First Name{" "}
              <span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="firstName"
              render={({ field }) => (
                <TextInput
                  error={formErrors.firstName?.message}
                  {...field}
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Helen"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-6">
            <label htmlFor="lastName" className="labal">
              Last Name{" "}
              <span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="lastName"
              render={({ field }) => (
                <TextInput
                  error={formErrors.lastName?.message}
                  {...field}
                  id="last-name"
                  name="last-name"
                  type="text"
                  placeholder="Anderson"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-6">
            <label htmlFor="email" className="labal">
              Email Address{" "}
              <span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="email"
              render={({ field }) => (
                <TextInput
                  error={formErrors.email?.message}
                  {...field}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="example@example.com"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-6">
            <label htmlFor="phoneNumber" className="labal">
              Phone Number
            </label>
            <Controller
              name="phoneNumber"
              render={({ field }) => (
                <TextInput
                  error={formErrors.phoneNumber?.message}
                  {...field}
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="(111) 1111 -1111"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-6">
            <label htmlFor="linkedInAccount" className="labal">
              LinkedIn Account
            </label>
            <Controller
              name="linkedInAccount"
              render={({ field }) => (
                <TextInput
                  error={formErrors.linkedInAccount?.message}
                  {...field}
                  id="linkedIn-account"
                  name="linkedIn-account"
                  type="text"
                  placeholder="linkedin.com/in/Helen_Anderson"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="mt-8 block md:hidden">
            <hr className="border-t border-[#6E40C5]" />
          </div>
          <h2 className="md:text-[22px] md:font-semibold text-[18px] font-extrabold pt-8 font-poppins  text-[#000]">
            Create Password
          </h2>
          <div className="md:mt-8 mt-6">
            <label htmlFor="password" className="labal">
              Password <span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="password"
              render={({ field }) => (
                <div>
                  <TextInput
                    {...field}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="• • • • • • • • • • • •"
                    className="input"
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2  gap-1 mt-4">
                    <Requirement
                      satisfied={field.value.length >= 8}
                      text="8 characters minimum"
                    />
                    <Requirement
                      satisfied={!!field.value.match(/\d/)}
                      text="One number"
                    />
                    <Requirement
                      satisfied={!!field.value.match(/[A-Z]/)}
                      text="One upper case letter"
                    />
                    <Requirement
                      satisfied={!!field.value.match(/[!@#$%^&*(),.?":{}|<>]/)}
                      text="One special character"
                    />
                    <Requirement
                      satisfied={!!field.value.match(/[a-z]/)}
                      text="One lower case letter"
                    />
                  </div>
                </div>
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-6">
            <label htmlFor="confirmPassword" className="labal">
              Repeat Password{" "}
              <span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="confirmPassword"
              render={({ field }) => (
                <TextInput
                  error={formErrors.confirmPassword?.message}
                  {...field}
                  id="repeat-password"
                  name="repeat-password"
                  type="password"
                  placeholder="• • • • • • • • • • • •"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <label className="inline-flex items-start md:my-10 my-8">
            <div className="round min-w-[14px] h-[14px] md:w-6 md:h-6 mt-1">
              <input
                checked={TND}
                onChange={() => setTND(!TND)}
                type="checkbox"
                id="checkbox"
                className="checkbox text-secondry_color"
                // className="form-checkbox h-5 w-5 rounded-full text-secondry_color appearance-none"
              />
              <label htmlFor="checkbox" ></label>
            </div>

            <p className="md:text-[18px] text-[14px] font-normal md:leading-8 leading-normal font-poppins text-text_color ml-2">
              By signing up you agree to our &nbsp;
              <b>
                <a href={TERMS_AND_CONDITIONS_PAGE}>Terms & Conditions *</a>
              </b>
            </p>
          </label>

          <div className="round">
            <input type="checkbox" id="checkbox" />
          </div>
          <button
            disabled={!formIsValid || !TND}
            type="submit"
            className={`${
              formIsValid && TND
                ? "bg-secondry_color cursor-pointer text-white"
                : "!bg-border_color !cursor-not-allowed text-black_color"
            }  rounded-[10px] w-full px-6 font-poppins md:text-[18px] text-[10px] font-bold h-10 flex items-center justify-center`}
          >
            {!isLoading ? (
              "Sign Up"
            ) : (
              <ThreeDots
                height={16}
                width={30}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass="justify-center"
                visible={true}
                ariaLabel="three-dots-loading"
              />
            )}
          </button>

          <p className="text-[16px] font-poppins text-text_color mt-4">
            * Required
          </p>

          {signUpError.length > 0 && (
            <p className="text-sm font-medium text-rose-600 mt-1 px-1 text-center">
              {signUpError}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default Register;
