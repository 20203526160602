import { GiCheckMark } from "react-icons/gi";
import Header from "../components/Header";
import LinkBtn from "../components/LinkBtn";
import { LOGIN_PAGE } from "../constant";
import vector from "../assets/vector.svg";


const EmailVerified = () => {
  return (
    <div className="h-screen container mx-auto">
      <Header />
      <div className="h-[calc(100vh-93px)] py-12 md:items-center justify-center flex mx-auto">
        <div className="w-full max-sm:px-4 max-md:pt-20 md:pb-40">
          <div className="relative flex justify-center py-8">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <GiCheckMark className="text-5xl text-white" />
            </div>
            <img src={vector} className="logo text-slate-500" alt="Vite logo" />
          </div>
          <h2 className="text-text_color text-5xl max-sm:text-2xl text-center font-semibold font-lora">
            Your email has been verified
          </h2>
          <h4 className="text-xl text-center text-text_color mt-5">
            You can now sign in with your new account
          </h4>
          <div className="mt-12 justify-center flex">
            <LinkBtn className="btn-custom" link={LOGIN_PAGE} title="Login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
