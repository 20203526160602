import { useState, useContext, useEffect } from "react";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import TextInput from "../../../components/TextInput";
import DailogBox from "../../../components/DailogBox";
import { UserLists } from ".."
import { sentryClient } from "@/Sentry/sentry";

export interface CreateListProps {
    setShowAlert: (status: boolean) => void;
    showAlert: boolean;
    list?: UserLists | null;
    onDataChanged: () => void;
}

export default function CreateList(props: CreateListProps) {
    const { setShowAlert, showAlert, list, onDataChanged } = props;
    const { createList, updateList } = useContext(AuthContext) as AuthContext_type;
    const [listName, setListName] = useState<string>(list?.name || "");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (list) {
            setListName(list.name);
        }
    }, [list]);

    function handleCloseDialog() {
        setShowAlert(false);
    }

    function handleListNameChange(value: string) {
        if (value) {
            setError("");
            setListName(value);
        } else {
            setError("List Name is required**");
            setListName("");
        }
    }



    async function handleSubmit() {
        try {
            if (list) {
                console.log("list", list)
                await updateList(list.id, listName);
                toastAlertSuccess("List updated successfully");
            } else {
                console.log("else hit")
                await createList(listName);
                toastAlertSuccess("List created successfully");
            }
            handleCloseDialog();
            onDataChanged()
        } catch (error: any) {
            toastAlertError(error.message)
            console.log(error, "error");
            sentryClient.captureException(error)
        }
    }

    return (
        <DailogBox
            className='flex flex-col items-center justify-center !max-w-[644px]'
            open={showAlert}
            onClose={handleCloseDialog}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <div className='flex flex-col items-center gap-2'>
                    <h2 className='font-bold xsm:font-black xsm:text-[28px] text-[15px] text-[#333333] text-[32px] font-poppins'>
                        {list ? "Edit List" : "Add New List"}
                    </h2>
                    <p className='text-base xsm:text-[14px] xsm:leading-[21px] font-poppins text-[#333333]'>
                        e.g. Potential prospects, Referral partners,<br /> Clients, Employees, Team Members
                    </p>
                </div>
                <div className="items start mt-3">
                    <TextInput
                        sx={{ width: "600px" }}
                        className="w-full xsm:text-[14px]"
                        labelClassName="!font-medium text-base xsm:text-[14px] xsm:font-semibold flex w-[600px] xsm:w-full"
                        placeholder="Ex: Fast-growing"
                        error={error}
                        value={listName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleListNameChange(e.target.value);
                        }}
                        type="text"
                        label="List Name"
                    />
                </div>
                <div className="flex gap-4 mt-4 justify-center xsm:w-full xsm:flex-col-reverse">
                    <button
                        type='button'
                        onClick={() => handleCloseDialog()}
                        className='all_dialogue_sType_buttons'
                    >
                        Close
                    </button>
                    <button
                        type='submit'
                        className='all_dialogue_pType_buttons bg-secondry_color text-white'
                    >
                        {list ? "Update" : "Save"}
                    </button>
                </div>
            </form>
        </DailogBox>
    );
}
