export const HOME_PAGE = "https://app.evyai.com/";
export const LOGIN_PAGE = "https://app.evyai.com/login";
export const SIGNUP_PAGE = "https://app.evyai.com/signup";
export const DASHBOARD_PAGE = "https://app.evyai.com/dashboard";
export const TERMS_AND_CONDITIONS_PAGE = "https://evyai.com/terms-of-use/";
export const PRIVACY_POLICY_PAGE = "https://evyai.com/privacy-policy";
export const CONTACT_US = "https://evyai.com/contact-us/"

//Limits
export const TEMPLATE_LIMIT = {
    FREE: 10,
    PRO: 1000
}

export const MANAGELIST_LIMIT = {
    FREE: 3,
    PRO: 50
}

export const MANAGETAGS_LIMIT = {
    FREE: 10,
    PRO: 1000
}

export const PERSONA_LIMIT = {
    FREE: 1,
    PRO: 10
}


export const SAVEDPROFILES_LIMIT = {
    FREE: 3,
    PRO: 30000
}


export const META_DATA = {
    '/welcome': {
        title: 'Welcome to evyAI',
        description: 'Welcome to evyAI Your LinkedIn™ AI Assistant',
        keywords: 'evyAI, Welcome Page, LinkedIn Assistant',
    },
    '/upgrade': {
        title: 'Upgrade Your evyAI Experience',
        description: 'Enhance your LinkedIn™ AI experience with evyAI Pro features.',
        keywords: 'evyAI, Upgrade, LinkedIn Pro',
        robots: 'index, follow',
    },
    '/password-success': {
        title: 'Password Successfully Updated',
        description: 'You successfully updated your evyAI password.',
        keywords: 'evyAI, Password Success, LinkedIn Assistant',
    },
    '/not-found': {
        title: 'Page Not Found',
        description: 'The page you are looking for cannot be found.',
        keywords: 'evyAI, 404, Page Not Found',
    },
    '/email-verification': {
        title: 'Email Verification Required',
        description: 'Please verify your email address to activate your evyAI account.',
        keywords: 'evyAI, Email Verification, LinkedIn Assistant',
    },
    '/email-verification-pending': {
        title: 'Email Verification Pending',
        description: 'Your email verification is pending. Please check your inbox.',
        keywords: 'evyAI, Email Pending, LinkedIn Assistant',
    },
    '/email-verified': {
        title: 'Email Successfully Verified',
        description: 'Your email address has been successfully verified.',
        keywords: 'evyAI, Email Verified, LinkedIn Assistant',
    },
    '/change-email': {
        title: 'Change Your Email Address',
        description: 'Update your email address linked to your evyAI account.',
        keywords: 'evyAI, Change Email, LinkedIn Assistant',
    },
    '/reset-password': {
        title: 'Reset Your Password',
        description: 'Reset your evyAI password to regain access to your account.',
        keywords: 'evyAI, Reset Password, LinkedIn Assistant',
    },
    '/forgot-password': {
        title: 'Forgot Your Password?',
        description: 'Recover your evyAI account by resetting your password.',
        keywords: 'evyAI, Forgot Password, LinkedIn Assistant',
        robots: 'index, follow',
    },
    '/email-verification-failed': {
        title: 'Email Verification Failed',
        description: 'Your email verification failed. Please try again.',
        keywords: 'evyAI, Email Failed, LinkedIn Assistant',
    },
    '/create-profile': {
        title: 'Create Your evyAI Profile',
        description: 'Set up your evyAI profile to start using LinkedIn™ AI features.',
        keywords: 'evyAI, Create Profile, LinkedIn Assistant',
    },
    '/register': {
        title: 'Register for evyAI',
        description: 'Sign up for evyAI and enhance your LinkedIn™ experience with AI tools.',
        keywords: 'evyAI, Register, LinkedIn Assistant',
        robots: 'index, follow',
    },
    '/login': {
        title: 'Login to evyAI',
        description: 'Access your evyAI account and start using LinkedIn™ AI features.',
        keywords: 'evyAI, Login, LinkedIn Assistant',
        robots: 'index, follow',
    },
    '/dashboard': {
        title: 'evyAI Dashboard',
        description: 'View your LinkedIn™ insights and manage your evyAI activities.',
        keywords: 'evyAI, Dashboard, LinkedIn Assistant',
    },
    '/my-account': {
        title: 'evyAI Account',
        description: 'Manage your evyAI account details and preferences.',
        keywords: 'evyAI, Account, LinkedIn Assistant',
    },
    '/settings': {
        title: 'evyAI Settings',
        description: 'Customize your evyAI settings to tailor your LinkedIn™ AI experience.',
        keywords: 'evyAI, Settings, LinkedIn Assistant',
    },
    '/recent-activities': {
        title: 'evyAI Recent Activities',
        description: 'Track and review your recent activities using evyAI on LinkedIn™.',
        keywords: 'evyAI, Recent Activities, LinkedIn Assistant',
    },
    '/subscription-pricing': {
        title: 'evyAI Subscription Pricing',
        description: 'Explore evyAI subscription plans and choose the right one for your needs.',
        keywords: 'evyAI, Subscription, LinkedIn Pricing',
    },
    '/saved-profile': {
        title: 'evyAI Saved Profiles',
        description: 'Access and manage your saved LinkedIn™ profiles with evyAI.',
        keywords: 'evyAI, Saved Profiles, LinkedIn Assistant',
    },
    '/lists': {
        title: 'evyAI Lists',
        description: 'Organize and manage your LinkedIn™ connections and activities.',
        keywords: 'evyAI, Lists, LinkedIn Organization',
    },
    '/post-create': {
        title: 'Create a LinkedIn™ Post with evyAI',
        description: 'Use evyAI to generate and publish engaging LinkedIn™ posts.',
        keywords: 'evyAI, Post Creation, LinkedIn Assistant',
    },
    '/idea-generator': {
        title: 'evyAI Idea Generator',
        description: 'Brainstorm LinkedIn™ post ideas with evyAI’s AI-powered generator.',
        keywords: 'evyAI, Idea Generator, LinkedIn Content',
    },
    '/templates': {
        title: 'evyAI Templates',
        description: 'Access and manage your LinkedIn™ templates with evyAI.',
        keywords: 'evyAI, Templates, LinkedIn Assistant',
    },
    '/template-add': {
        title: 'Add New Template to evyAI',
        description: 'Create and save new templates for LinkedIn™ posts and messages.',
        keywords: 'evyAI, Add Template, LinkedIn Assistant',
    },
    '/tags': {
        title: 'evyAI Tags',
        description: 'Manage and organize your LinkedIn™ tags with evyAI.',
        keywords: 'evyAI, Tags, LinkedIn Organization',
    },
    '/profile-optimizer': {
        title: 'evyAI Profile Optimizer',
        description: 'Optimize your LinkedIn™ profile with evyAI’s AI-driven suggestions.',
        keywords: 'evyAI, Profile Optimization, LinkedIn Assistant',
    },
    '/personas': {
        title: 'evyAI Personas',
        description: 'Manage and customize your LinkedIn™ personas using evyAI.',
        keywords: 'evyAI, Personas, LinkedIn Assistant',
    },
    '/persona-add': {
        title: 'Add New Persona in evyAI',
        description: 'Create and add a new LinkedIn™ persona with evyAI.',
        keywords: 'evyAI, Add Persona, LinkedIn Assistant',
    },
    '/learning-hub': {
        title: 'evyAI Learning Hub',
        description: 'Explore tutorials, videos, and resources to master LinkedIn™ with evyAI.',
        keywords: 'evyAI, Learning Hub, LinkedIn Training',
    },
    '/community': {
        title: 'evyAI Community',
        description: 'Connect with other LinkedIn™ professionals and share insights in the evyAI community.',
        keywords: 'evyAI, Community, LinkedIn Networking',
    },
    '/demo': {
        title: 'evyAI Demo',
        description: 'Watch the evyAI demo to learn how to enhance your LinkedIn™ experience.',
        keywords: 'evyAI, Demo, LinkedIn Assistant',
    },
    '/teams': {
        title: 'evyAI Teams',
        description: 'Manage and collaborate with your team using evyAI\'s LinkedIn™ tools.',
        keywords: 'evyAI, Teams, LinkedIn Collaboration',
    },
    '/paypal-subscription': {
        title: 'evyAI Paypal Subscription',
    },
    '/force-login': {
        title: 'evyAI Force Login',
    },
};