import { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { User } from "firebase/auth";
import { Navigate_to } from "../../router/routes.constants";

import EvyAiLogo from '../../assets/Images/evyAI Logo_Purple.svg';
import LoginRight from '../../assets/Images/login-right.png';

function AuthLayout() {
  const { getCurrentUser,user, handleExtensionMessages } = useContext(AuthContext) as AuthContext_type;
  const currentUser = getCurrentUser();

  useEffect(()=>{
    if (currentUser){
      handleExtensionMessages()
    }
  }, [currentUser])

  if ((currentUser as User & any)?.accessToken && user?.first_name) {
    return <Navigate to={Navigate_to.afterLogin} />;
  }
  return (
    <>
      <section className="flex bg-bg_white_color">
        {/* {isLoading && 'loading...'} */}
        <div className='w-full h-screen overflow-y-auto'>
          <div className='mx-auto md:max-w-[588px] w-full px-4 mt-10 md:mt-[71px]'>

            <img className='flex w-28 h-14 md:w-32 md:h-16'  src={EvyAiLogo} alt='' /> 
            {user && (!user.first_name && <Navigate to={Navigate_to.createProfile} />)}   

            <Outlet />
          </div>
        </div>
        <div className='flex-grow w-full max-w-[606px] md:flex hidden h-screen'>
          <img className='w-full' src={LoginRight} alt='' />
        </div>
      </section>
    </>
  );
}

export default AuthLayout;
