import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillLike } from "react-icons/ai";
import { BiSolidDislike } from "react-icons/bi";
import { BiDislike, BiLike } from "react-icons/bi";
import { ImStarEmpty } from "react-icons/im";
import { FaStar } from "react-icons/fa";

import CopyIcon from "../assets/svgs/CopyIcon";
import EditIcon from "../assets/Icons/Edit.svg";
import ideaGeneratorIcon from "../assets/Icons/Idea-Generator.svg";

import Button from "./Button";
import DailogBox from "./DailogBox";
import { IGeneratedIdea } from "../pages/IdeaGenerator";

import {
  API_REQUEST_TYPE,
  PARAMETER_KEY,
  SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE,
  STORAGE_KEYS,
  SubscriptionStatus,
  TextGenerationInfo,
} from "../config/appConfig";

import { toastAlertError, toastAlertSuccess } from "../utils";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import { useIncrementingTimer } from "../hooks/useIncrementingTimer";
import { SubscriptionStatusDB } from "../config/firebase";
import { sentryClient } from "@/Sentry/sentry";

interface IProps {
  selectedIdea: string;
  selectedPersona: string;
  activityData: any;
  generateIdeaCommand: string;
  id: string;
  generatedIdeas: IGeneratedIdea[];
  setGeneratedIdeas: React.Dispatch<React.SetStateAction<IGeneratedIdea[]>>;
  isIdeaSaved: boolean;
  open:boolean;
  handleClose:()=>void
}

const EditIdeaDialogue: React.FC<IProps> = ({
  selectedIdea,
  selectedPersona,
  activityData,
  generateIdeaCommand,
  id: ideaId,
  generatedIdeas,
  setGeneratedIdeas,
  isIdeaSaved,
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const {
    fetchSuggestedComments,
    deleteCommandData,
    addCommandData,
    upvoteGeneratedIdea,
    downvoteGeneratedIdea,
    subscriptionStatus,
  } = useContext(AuthContext) as AuthContext_type;

  const [isEditable, setIsEditable] = useState(false);
  const [postCreationText, setPostCreationText] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);


  const seconds = useIncrementingTimer(isLoading);

  const handleToggleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    setIsSaved(isIdeaSaved || false);
  }, [isIdeaSaved]);

  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    const data: any = {};
    data[PARAMETER_KEY.AUTHOR_NAME] = "";
    data[PARAMETER_KEY.TYPE] = API_REQUEST_TYPE.CREATE_POST;
    data[PARAMETER_KEY.POST_CREATION_TEXT] = postCreationText;

    if (subscriptionStatus) {
      if (subscriptionStatus.status === null && subscriptionStatus.status === SubscriptionStatus.PRO) {
        data[PARAMETER_KEY.STATUS] = SubscriptionStatusDB.ACTIVE;
      } else {
        data[PARAMETER_KEY.STATUS] = subscriptionStatus.statusDB;
      }
      if (data[PARAMETER_KEY.STATUS] === SubscriptionStatusDB.PAST_DUE) {
        data[PARAMETER_KEY.IS_PRO_USER] = false;
      } else {
        data[PARAMETER_KEY.IS_PRO_USER] = subscriptionStatus.status === SubscriptionStatus.PRO;
      }
    }

    const response = await fetchSuggestedComments(data);
    if (response?.success) {
      const generatedText = response?.data?.[0]?.["reply"] ?? "";

      const generatedTextInfo: TextGenerationInfo = {
        activityData: data,
        generatedText,
        activityType: API_REQUEST_TYPE.CREATE_POST,
      };

      localStorage.setItem(STORAGE_KEYS.LAST_PERSONA_USED, selectedPersona);

      if (generatedTextInfo) {
        navigate(`/${PROTECTED_ROUTES.createPost}`, {
          state: { generatedTextInfo, selectedPersona, selectedIdea },
        });
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (response?.responseCode === 429) {
        toastAlertError(
          response?.error?.reason ??
            "Some error occurred. Please try again later."
        );
        return;
      }
      toastAlertError(
        response?.error?.reason ??
          "Some error occurred. Please try again later."
      );
    }
  }, [postCreationText]);

  const copyTextToClipBoard = () => {
    console.log("### text will be copied to clipboard");
    navigator.clipboard
      .writeText(selectedIdea)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
        setTimeout(() => {}, SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE * 1000);
      })
      .catch((error) => {
        console.log("### Error while copying content to clipboard");
        console.log({ error });
      });
  };

  const handleSaveUnsave = (id: string) => {
    if (isSaved) {
      unsaveCommand(id);
    } else {
      saveCommand(id);
    }
  };

  const unsaveCommand = async (commandDocId: string) => {
    try {
      const { docId, error } = await deleteCommandData(commandDocId);

      if (error === null && docId?.length > 0) {
        toastAlertSuccess("Unsaved Successfully");
        setIsSaved(false);

        const tempSavedCommands = [...generatedIdeas];
        const indexToRemove = tempSavedCommands.findIndex(
          (command: any) => command.id === docId
        );
        if (indexToRemove > -1) {
          tempSavedCommands[indexToRemove].isSaved = false;
          setGeneratedIdeas([...tempSavedCommands]);
        }
      }
    } catch (error) {
      toastAlertError("Something went wrong");
      sentryClient.captureException(error)
    }
  };

  const saveCommand = async (id: string) => {
    try {
      if (!postCreationText) {
        return;
      }
      await addCommandData(
        activityData.activityData || "",
        API_REQUEST_TYPE.POST_IDEA,
        postCreationText,
        id
      );
      toastAlertSuccess("Saved Successfully");
      setIsSaved(true);
      setIsEditable(false);
      const tempSavedCommands = [...generatedIdeas];
      const indexToRemove = tempSavedCommands.findIndex(
        (command: any) => command.id === id
      );
      if (indexToRemove > -1) {
        tempSavedCommands[indexToRemove].isSaved = true;
        setGeneratedIdeas([...tempSavedCommands]);
      }
    } catch (error: any) {
      toastAlertError(error?.message);
      sentryClient.captureException(error)
    } finally {
    }
  };

  const handleLike = async (e: any) => {
    try {
      e.stopPropagation();
       upvoteGeneratedIdea({
        userPrompt: generateIdeaCommand,
        idea: selectedIdea,
        persona: selectedPersona,
      });
      toastAlertSuccess("liked Successfully");
      setIsLiked(true);
      setIsDisliked(false);
    } catch (error) {
      console.log(error, "error");
      toastAlertError("Something went wrong please try again");
      sentryClient.captureException(error)
    }
  };

  const handleDislike = async (e: any) => {
    try {
      e.stopPropagation();
      downvoteGeneratedIdea({
        userPrompt: generateIdeaCommand,
        idea: selectedIdea,
        persona: selectedPersona,
      });
      setIsLiked(false);
      setIsDisliked(true);
    } catch (error) {
      console.log(error, "error");
      toastAlertError("Something went wrong please try again");
      sentryClient.captureException(error)
    }
  };

  const handleSave = () => {
    saveCommand(ideaId);
  };

  useEffect(() => {
    if (selectedIdea) {
      setPostCreationText(selectedIdea);
    }
  }, [selectedIdea]);
  return (
    <DailogBox
      open={open}
      dialogClass="!z-[100] "
      dialogBoxClass="!p-4 md:!p-10 !bg-[#F5F7F9] max-w-[500px] w-full"
      onClose={handleClose}
    >
      <div className="mt-4">
        <div className="rounded-md flex justify-between items-center">
          <div className="bg-[#F5F1FB] h-8 W-8 p-[7px] rounded-md flex justify-center items-center">
            <img
              className="w-[17px] h-[17px] cursor-pointer"
              src={ideaGeneratorIcon}
              alt=""
            />
          </div>
        </div>
        <div className="relative mt-5">
          <textarea
            id="message"
            onChange={(e) => setPostCreationText(e.target.value)}
            value={postCreationText}
            rows={20}
            className={`bg-[#F6F6F6] text-[#444553] font-poppins text-sm rounded-xl w-full mt-4 h-[152px] outline-none  ${
              isEditable ? "overflow-y-auto" : ""
            }`}
            placeholder="Write message here....."
            readOnly={!isEditable}
          />
        </div>
        <hr className="bg-[#6E40C5] h-[1px] mt-7" />
        <div className="flex justify-between items-center my-6 ">
          <div className="flex gap-2 justify-center items-center">
            <span
              className="cursor-pointer"
              onClick={() => copyTextToClipBoard()}
            >
              <CopyIcon className="cursor-pointer" />
            </span>

            {isSaved ? (
              <FaStar
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleSaveUnsave(ideaId);
                }}
                size={24}
                color={"#34317D"}
                className="cursor-pointer "
              />
            ) : (
              <ImStarEmpty
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleSaveUnsave(ideaId);
                }}
                size={24}
                color={"#34317D"}
                className="cursor-pointer "
              />
            )}
          </div>
          <div className="flex gap-2">
            {isLiked ? (
              <AiFillLike
                onClick={handleLike}
                size={24}
                color="#34317D"
                className="cursor-pointer "
              />
            ) : (
              <BiLike
                onClick={handleLike}
                size={24}
                color={"#34317D"}
                className="cursor-pointer "
              />
            )}

            {isDisliked ? (
              <BiSolidDislike
                onClick={handleDislike}
                size={24}
                color={"#34317D"}
                className="cursor-pointer "
              />
            ) : (
              <BiDislike
                onClick={handleDislike}
                size={24}
                color={"#34317D"}
                className="cursor-pointer "
              />
            )}
          </div>
        </div>
        <div>
          {!isEditable ? (
            <>
              <Button
                className="px-6 py-[10px] w-full items-center rounded-[10px] border border-secondry_color !text-sm font-bold leading-normal bg-secondry_color text-white gap-2 disabled:opacity-50 "
                onClick={fetchComments}
                disabled={isLoading}
              >
              {isLoading ? <span>Generating Post</span> : <span>Generate Post</span>}
              <span> {isLoading && seconds}</span>
              </Button>

              <button
                className="flex gap-2 bg-transparent w-full mt-2 text-secondry_color !text-sm  font-bold border border-secondry_color rounded-lg justify-center items-center px-4 py-[10px]"
                onClick={isEditable ? handleSave : handleToggleEdit}
              >
                {!isEditable && <img src={EditIcon} className="h-[14px] w-[14px]" alt="Edit Icon" />}
                {!isEditable ? "Edit Idea" : "Save"}
              </button>
            </>
          ) : (
            <Button
              className="px-6 py-[10px] w-full items-center rounded-[10px] border border-secondry_color !text-sm font-bold leading-normal bg-secondry_color text-white gap-2 disabled:opacity-50 "
              onClick={handleSave}
            >
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </DailogBox>
  );
};

export default EditIdeaDialogue;
