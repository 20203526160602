import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileDrawer: React.FC<IProps> = ({ children,setIsOpen,isOpen }) => {
if(!isOpen){
    return
}

  return (
    <main
      className={
        "fixed w-full h-[calc(100vh-77px)] left-0 top-[77px] z-[99] md:hidden bg-gray-900 bg-opacity-25 transform ease-in-out "
      }
    >
      <section className="w-full max-w-sm left-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in transition-all transform">
        <article className="relative w-screen max-w-sm pb-10 flex flex-col space-y-6 overflow-y-scroll h-full p-4">
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
};

export default MobileDrawer;
