import { useEffect } from "react";
import Header from "../components/LandingPageHeader";
import AboutSection from "../components/AboutSection";
import SubscriptionSection from "../components/SubscriptionSection";
import FeaturesSection from "../components/FeatureSection";
import UpgradeSection from "../components/UpgradeSection";
import AssistanceSection from "../components/AssistanceSection";
import TutorialSection from "../components/TutorialSectionold";
import FooterSection from "../components/FooterSection";
import ConnectToLInkedInSection from "../components/ConnectToLinkedInSection";


const UpgradePage = () => {
  useEffect(() => {
    window.location.replace("https://evyai.com/upgrade/")
  }, [])
  return;
  return (
    <div className="overflow-x-hidden">
      <Header />
      <AboutSection />
      <SubscriptionSection />
      <FeaturesSection />
      <UpgradeSection />
      <AssistanceSection />
      <TutorialSection />
      <ConnectToLInkedInSection/>
      <FooterSection />
    </div>
  );
};

export default UpgradePage;
