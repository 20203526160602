import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { toastAlertSuccess } from "../../utils";
import NoDataFound from "../../components/NoDataFound";
import { Link } from "react-router-dom";
import CreateList from "./components/CreateList";
import { COLLECTIONS, SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import {
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import ListsTable from "./components/ListsTable";
import PlusSvg from "../../assets/Images/Plus.svg";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { Navigate_to } from "../../router/routes.constants";
import arrowLeft from "../../assets/Images/LeftArrow.svg";
import SortActionButton from "./components/SortActionButton";
import { db } from "../../services/firebase";
import SearchImg from "../../assets/Images/listSearch.svg";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import {MANAGELIST_LIMIT} from "../../constant";
import { sentryClient } from "@/Sentry/sentry";

export interface UserLists {
  id: string;
  name: string;
  date: any;
  modifiedDate?: Timestamp;
}

const ManageLists = () => {
  const { getCurrentUser, deleteMultipleLists, subscriptionStatus, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, user } =
    useContext(AuthContext) as AuthContext_type;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [userLists, setUserLists] = useState<UserLists[]>([]);
  const [deleteListsIds, setDeleteListsIds] = useState<string[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFreeTrail, setShowFreeTrail] = useState<boolean>(false);
  const [allUserList, setAllUsersList] = useState<UserLists[]>([])
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const currentUser = getCurrentUser();
  const [limitConfig, setLimitConfig] = useState<any>({
    [SubscriptionStatus.FREE]: MANAGELIST_LIMIT.FREE,
    [SubscriptionStatus.PRO]: MANAGELIST_LIMIT.PRO,
  });
  
  useEffect(() => {
    if (
      (user)?.limits
    ) {
      setLimitConfig({
        [SubscriptionStatus.FREE]: user?.limits.lists_count_free,
        [SubscriptionStatus.PRO]: user?.limits.lists_count_pro,
      });
    }
    console.log("user >>", user);
  }, [currentUser]);

  function canAddNewList(lists_count: any, willReturn: boolean = false) {
    if (subscriptionStatus) {
      if (
        lists_count >= limitConfig[subscriptionStatus.status] &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        !willReturn && setShowFreeTrail(true);
        if (willReturn) {
          return true;
        }
      } else if (
        lists_count >= limitConfig[subscriptionStatus.status] &&
        subscriptionStatus.status !== SubscriptionStatus.FREE
      ) {
        !willReturn && setShowFreeTrail(true);
        if (willReturn) {
          return true;
        }
      } else {
        !willReturn && setShowFreeTrail(false);
        if (willReturn) {
          return false;
        }
      }
    }
  }

  // function canAddNewListForPaymentPastDue(lists_count: any) {
  //   if (lists_count >= 3) {
  //       setShowFreeTrail(true);
  //     } else if (lists_count >= 50) {
  //       setShowFreeTrail(true);
  //     } else {
  //       setShowFreeTrail(false);
  //   }
  // }

  const confirmDelete = () => {
    if (deleteListsIds) {
      deleteUserListIds();
      setDeleteListsIds([]);
    }
    setShowConfirmation(false);
  };

  const initiateDelete = () => {
    setShowConfirmation(true);
  };

  const toggleDataChanged = () => {
    setDataChanged((prev) => !prev);
  };

  function deleteUserListIds() {
    deleteMultipleLists(deleteListsIds);
    setUserLists((prevLists) =>
      prevLists.filter((list) => !deleteListsIds.includes(list.id))
    );
    canAddNewList(userLists.length - deleteListsIds.length);
    toastAlertSuccess("List deleted successfully");
  }

  const handleGetUserProfile = async (
    orderByField: string = "modifiedDate",
    orderDirection: "asc" | "desc" = "desc"
  ): Promise<any> => {
    try {
      console.log("hitting", orderByField, orderDirection);
      if (currentUser) {
        const allListsRef = collection(
          db,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`
        );
        const allListsSnapshot = await getDocs(
          query(allListsRef, orderBy(orderByField, orderDirection))
        );
        const allListsMap: UserLists[] = [];
        allListsSnapshot.forEach((listDoc) => {
          allListsMap.push({
            ...listDoc.data(),
            id: listDoc.id,
          } as UserLists);
        });
        console.log(allListsMap, "UserLists");
        setAllUsersList(allListsMap);
        setUserLists(allListsMap);
        canAddNewList(allListsMap.length);
      }

      setLoading(false);
    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
    }
  };

  const searchLists = (value: string) => {
    const regex = new RegExp(value, 'i');
    const filteredLists = allUserList.filter(list => regex.test(list.name));
    setUserLists(filteredLists);
  }

  const handleClickAddList = () => {
    if (subscriptionStatus) {
      if (subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
        if (allUserList.length >= 3) {
          setShowUpdatePaymentDialog(true);
          return;
        } else {
          setShowAlert(true);
        }
      }
      setShowAlert(true);
    }
  }

  useEffect(() => {
    handleGetUserProfile();
  }, [dataChanged]);

  useEffect(() => {
    canAddNewList(userLists.length)
  }, [subscriptionStatus, userLists])


  return (
    <>
      <section className="md:px-0 px-1">
        <div className="flex">
          <Link
            to={Navigate_to.savedProfiles}
            className="flex justify-start items-center cursor-pointer"
          >
            <h2 className="xsm:text-[14px] font-poppins md:text-[18px] text-[12px]  font-normal leading-normal text-[#444553]">
              Saved Profiles
            </h2>
            <img
              className="ml-2"
              src={arrowLeft}
              alt=""
              width="16px"
              height="17px"
            />
          </Link>
          <h3 className="xsm:text-[14px] font-poppins md:text-[18px] text-[12px] leading-normal text-[#444553] ml-2 font-bold">
            Manage Lists
          </h3>
        </div>

        <div className="mt-5">
          {!loading && showFreeTrail && (
            <FreeTrailEnd
              title="You’ve reached the max limit of Lists."
              content={`You have already saved ${userLists.length} Lists. Upgrade in order to continue organizing your Saved Profiles.`}
              buttonName="Upgrade Subscription"
              feature="Lists"
              subStatus={subscriptionStatus?.status}
            />
          )}
        </div>
        <div className="mt-6 flex justify-between items-center">
          <h3 className="xsm:font-black xsm:text-[24px] font-poppins text-[32px] font-extrabold text-[#292929] capitalize">
            Manage Lists
          </h3>
          {deleteListsIds.length > 0 ? (
            <button
              className="md:hidden bg-transparent xsm:text-[14px] xsm:py-3 xsm:px-4 text-secondry_color font-bold border border-secondry_color px-3 py-2 rounded-lg ml-3"
              onClick={() => initiateDelete()}
            >
              Delete Lists
            </button>
          ) : (
            !loading && (
              <button
                className="md:hidden primary_button_custom"
                disabled={showFreeTrail}
                onClick={handleClickAddList}
              >
                <img src={PlusSvg} style={{ marginRight: "5px" }} /> New List
              </button>
            )
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="xsm:hidden flex gap-x-3 mt-5 mb-3">
            <SortActionButton handleGetUserProfile={handleGetUserProfile} />

            <div className="xsm:hidden relative flex items-center border border-border_color p-1.5 rounded-[10px]">
              <img
                src={SearchImg}
                alt="Search"
                className="absolute left-3"
                style={{ height: "16px", width: "16px" }}
              />
              <input
                className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)] outline-none bg-transparent"
                onChange={(e) => searchLists(e.target.value)}
                style={{ fontSize: "14px", width: "100%" }}
                placeholder="Search a list"
              />
            </div>
          </div>
          <div className="xsm:hidden">

            {deleteListsIds.length > 0 ? (
              <button
                className="bg-transparent text-secondry_color font-bold border border-secondry_color px-3 py-2 rounded-lg ml-3"
                onClick={() => initiateDelete()}
              >
                Delete Lists
              </button>
            ) : (
              !loading && (
                <button
                  className="primary_button_custom"
                  disabled={showFreeTrail}
                  onClick={handleClickAddList}
                >
                  <img src={PlusSvg} style={{ marginRight: "5px" }} /> New List
                </button>
              )
            )}
          </div>

        </div>
        {loading ? (
          <NoDataFound text="No Data Found" isLoading={true} />
        ) : userLists.length <= 0 ? (
          <NoDataFound text="No Data Found" isLoading={false} />
        ) : (
          <ListsTable
            userLists={userLists}
            setUserLists={setUserLists}
            onDataChanged={toggleDataChanged}
            setDeleteListsIds={setDeleteListsIds}
            deleteListsIds={deleteListsIds}
            canAddNewList={canAddNewList}
          />
        )}
      </section>
      {showAlert && (
        <CreateList
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          onDataChanged={toggleDataChanged}
        />
      )}
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmDelete}
        header="Delete List"
        content="Are you sure you want to delete these list(s)?"
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </>
  );
};
export default ManageLists;