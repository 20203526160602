export const FIREBASECONFIG = {
  apiKey: "AIzaSyC0kOy4juGg4iMqSkDmh4vF9HKRVsPXvOA",
  authDomain: "gptchromeextension.firebaseapp.com",
  projectId: "gptchromeextension",
  storageBucket: "gptchromeextension.appspot.com",
  messagingSenderId: "776551117048",
  appId: "1:776551117048:web:b07c9ec9bf7fe7faa6cb0d",
  measurementId: "G-HPJLZFL679",
};

export const COLLECTIONS = {
  USER_ACTIVITIES: "user_activities",
  USER_ACCOUNT: "user_account",
  ACTIVITIES: "activities",
  ACCOUNT: "account",
  COMMANDS: "commands",
  PRODUCTS: "products",
  CUSTOMERS: "customers",
  STATS: "stats",
  ALL_STATS: "all_stats",
  CHECKOUT_SESSIONS: "checkout_sessions",
  SUBSCRIPTIONS: "subscriptions",
  MANUAL_REVIEWS: "manual_reviews",
  PERSONAS:"personas",
  TEMPLATES:"templates",
  TAGS:"tags",
  SAVED_LINKEDIN_PROFILES: "saved_linkedin_profiles",
  PROFILES:"profiles",
  LISTS: "lists",
  QUESTIONS: "questions",
  USER_QUERIES: "user_queries",
  TEAMS: "teams",
  MEMBERS: "members",
  TEAM_INVITES: "team_invites",
};

export const FIELDS = {
  TIMESTAMP: "timestamp",
  ACTIVITY_DATA: "activityData",
  GENERATED_TEXT: "generatedText",
  ACTIVITY_TYPE: "activityType",
  TOTAL_USAGE: "total_usage",
  TOTAL_TOKENS_COUNT: "total_tokens_count",
  CURRENT_MONTH_STATS: "current_month_stats",
};

export const SUBSCRIPTIONS_FIELDS = {
  TEAMS_SUBSCRIPTION_PRODUCT_ID: "prod_QDEMhGnqTkwTr0",
  TEAMS_MONTHLY_PRICE_ID: "price_1PMntuKLRkrcwJ5STo8EpaH0",
  TEAMS_ANNUAL_PRICE_ID: "price_1PQpT5KLRkrcwJ5SIVkFtfTQ",
  TEAMS_ANNUAL_PRICE_ID_2: "price_1PMntuKLRkrcwJ5SSWXxSbNp",
  PREMIUM_SUBSCRIPTION_FREE_TRIAL_PRODUCT_ID: "prod_P59Hor7qvSygSb",
  OLD_PREMIUM_SUBSCRIPTION_PRODUCT_ID: "prod_NogKER9puwkEqH",
  NEW_PREMIUM_SUBSCRIPTION_PRODUCT_ID: "prod_QDE5S8v39sUWcA",
};

export const SUBSCRIPTIONS_NAMES = {
  FREE: "FREE",
  PREMIUM: "PREMIUM",
  TEAMS: "TEAMS_PREMIUM",
  TEAMS_MEMBER: "TEAMS_MEMBER",
};

export const SUBSCRIPTIONS_INTERVALS = {
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export enum SubscriptionStatus {
  FREE,
  PRO,
}

export const SubscriptionStatusDB = {
  ACTIVE: "active",
  PAST_DUE: "past_due",
  CANCELLED: "canceled",
  TRIALING: "trialing",
}

export const FREE_USER_MAXIMUM_TOKENS = 20000;
export const PRO_USER_MAXIMUM_MONTHLY_USAGE = 1000000;

export const SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE = 10;

export const SHARE_EVY_AI_URL = "https://evyai.com/";
