import { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import TeamInviteBanner from "../components/TeamInviteBanner";
import { toastAlertError } from '@/utils';
import { sentryClient } from '@/Sentry/sentry';

const GlobalTeamInviteBanner = () => {

    const {
        teamInvites,
        fetchTeamById,
        fetchUserDataById,
        acceptTeamInvite,
        declineTeamInvite,
        user,
    } = useContext(AuthContext) as AuthContext_type;

    const [teamInvitesArr, setTeamInvitesArr] = useState<any[]>([]);
    const [teamInvitesArrTeams, setTeamInvitesArrTeams] = useState<any[]>([]);
    const [acceptLoading, setAcceptLoading] = useState<string[]>([]);
    const [declineLoading, setDeclineLoading] = useState<string[]>([]);

    useEffect(() => {
        const fetchInvites = async () => {
            let teams: any[] = [];
            const teamInvitesPromises = teamInvites.map(async (item: any) => {
                const data = await fetchTeamById(item.teamId);
                const admin = await fetchUserDataById(data.adminId);
                teams.push({ ...data, adminId: admin });
            });
            await Promise.all(teamInvitesPromises);
            setTeamInvitesArrTeams(teams);
            setTeamInvitesArr(teamInvites);
        };

        fetchInvites();
    }, [teamInvites, fetchTeamById, fetchUserDataById]);

    const handleAcceptTeamInvite = async (teamId: string, inviteId: string) => {
        try {
            let isAlreadyThere = false;
            if (user && user.memberInTeams) {
                const memberInTeams = user.memberInTeams;
                isAlreadyThere = memberInTeams.includes(teamId); 
            }
            setAcceptLoading(prev => [...prev, teamId]);
            if (!isAlreadyThere) {
                await acceptTeamInvite(teamId, inviteId);
            }
            setTeamInvitesArr(prev => prev.filter(team => team.teamId !== teamId));
            setTeamInvitesArrTeams(prev => prev.filter(team => team.id !== teamId));
        } catch (error: any) {
            console.error(error);
            if (error.inviteDeleted) {
                toastAlertError(error.message)
                setTeamInvitesArr(prev => prev.filter(team => team.teamId !== teamId));
                setTeamInvitesArrTeams(prev => prev.filter(team => team.id !== teamId));
            }
            sentryClient.captureException(error)
        } finally {
            setAcceptLoading(prev => prev.filter(id => id !== teamId));
        }
    };

    const handleDeclineTeamInvite = async (teamId: string, inviteId: string) => {
        try {
            setDeclineLoading(prev => [...prev, teamId]);
            await declineTeamInvite(teamId, inviteId);
            setTeamInvitesArr(prev => prev.filter(team => team.id !== inviteId && team.teamId !== teamId));
            setTeamInvitesArrTeams(prev => prev.filter(team => team.id !== teamId));
        } catch (error: any) {
            console.error(error);
            if (error.inviteDeleted) {
                toastAlertError(error.message)
                setTeamInvitesArr(prev => prev.filter(team => team.teamId !== teamId));
                setTeamInvitesArrTeams(prev => prev.filter(team => team.id !== teamId));
            }
            sentryClient.captureException(error)
        } finally {
            setDeclineLoading(prev => prev.filter(id => id !== teamId));
        }
    };

    return (
        <div className="w-full sticky top-0 z-50 mb-6 md:mb-0">
            {teamInvitesArrTeams.map((team) => (
                <div key={team.id} className="max-w-5xl mx-auto sm:px-6 lg:px-6">
                    <TeamInviteBanner
                        team={team}
                        teamInvitesArr={teamInvitesArr}
                        acceptLoading={acceptLoading}
                        declineLoading={declineLoading}
                        handleAcceptTeamInvite={handleAcceptTeamInvite}
                        handleDeclineTeamInvite={handleDeclineTeamInvite}
                    />
                </div>
            ))}
        </div>
    );
};

export default GlobalTeamInviteBanner;