import PersonasTable from "./components/PersonasTable";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import ActionButtons from "./components/ActionButtons";
import { Timestamp } from "firebase/firestore";
import { useState, useContext, useEffect } from "react";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import NoDataFound from "../../components/NoDataFound";
import EmptyTablePersonas from "./components/EmptyTablePersonas";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toastAlertSuccess } from "../../utils";
import { useNavigate } from "react-router";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import PlusSvg from "../../assets/Images/Plus.svg"
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
// import './../../CustomStyling/CustomReusablestyling.css';
import { PERSONA_LIMIT } from "../../constant";
import { sentryClient } from "@/Sentry/sentry";
export interface IPersona {
  id: string;
  tone: string;
  Tag: string;
  personaName: string;
  userId: string;
  industry: string;
  profilePictureUrl: string;
  backgroundHistory: string;
  timestamp: Timestamp;
  keywordsPhrases: string;
}


const headers = [
  {
    width: "50%",
    name: "Name",
    classes: "pl-5",
  },
  {
    width: "35%",
    name: "Persona Type",
    classes: "",
  },
  {
    width: "15%",
    name: "Actions",
    classes: "pl-3",
  },
];

const Personas = () => {
  const { getCurrentUser, getPersonas, subscriptionStatus, deleteMultiplePersonas, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, user } = useContext(
    AuthContext
  ) as AuthContext_type;
  const [loading, setLoading] = useState<boolean>(true);
  const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false);
  const [isAddButtonDisable, setIsAddButtonDisable] = useState<boolean>(true);
  const [personas, setPersonas] = useState<IPersona[]>([]);
  const currentUser = getCurrentUser();
  const [limitConfig, setLimitConfig] = useState<any>({
    [SubscriptionStatus.FREE]: PERSONA_LIMIT.FREE,
    [SubscriptionStatus.PRO]: PERSONA_LIMIT.PRO,
  });

  const [currentSubscription, setCurrentSubscription] = useState<any>()
  const [deletePersonaIds, setDeletePersonaIds] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)


  const navigate = useNavigate();


  useEffect(() => {
    if (
      (user)?.limits
    ) {
      setLimitConfig({
        [SubscriptionStatus.FREE]: user?.limits.persona_free_limit,
        [SubscriptionStatus.PRO]: user?.limits.persona_pro_limit
      });
    }
  }, [currentUser]);

  const handleMultipleDelete = () => {
    deleteMultiplePersonas(deletePersonaIds);
    toastAlertSuccess("Deleted Succefully")
    setPersonas((prevLists: IPersona[]) => prevLists.filter(persona => !deletePersonaIds.includes(persona.id)));
  };

  const confirmMultipleDelete = () => {
    if (deletePersonaIds.length > 0) {
      handleMultipleDelete();
      setDeletePersonaIds([]);
    }
    setShowConfirmation(false);
  };

  async function getAllPersonas(sortBy?: string, order?: any) {
    try {
      let allPersonas;
      if (sortBy && order) {
        allPersonas = await getPersonas(sortBy, order);
      } else {
        allPersonas = await getPersonas();
      }
      setPersonas(allPersonas);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch personas:", error);
      sentryClient.captureException(error)
    }
  }

  const handleClickAddPersona = () => {
    if (subscriptionStatus) {
      if (subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
        const maxLimit = limitConfig[0];
        if (personas.length >= maxLimit) {
          setShowUpdatePaymentDialog(true);
          return;
        } else {
          navigate(`/${PROTECTED_ROUTES.addPersona}`)
        }
      } else {
        navigate(`/${PROTECTED_ROUTES.addPersona}`)
      }
    }
  }

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (subscriptionStatus) {
        setCurrentSubscription(subscriptionStatus)
        const maxLimit = limitConfig[subscriptionStatus.status]
        if (
          personas.length >= maxLimit &&
          subscriptionStatus.status === SubscriptionStatus.FREE
        ) {
          setShowLimitMessage(true);
          setIsAddButtonDisable(true);
        } else if (
          personas.length >= maxLimit &&
          subscriptionStatus.status === SubscriptionStatus.PRO
        ) {
          setShowLimitMessage(true);
          setIsAddButtonDisable(true)
        } else {
          setShowLimitMessage(false);
          setIsAddButtonDisable(false);
        }
      };
    }
    fetchSubscriptionStatus();
  }, [personas, subscriptionStatus]);

  useEffect(() => {
    getAllPersonas();
  }, []);

  return (
    <section>
      <div className="flex justify-between md:items-end items-center">
        <h3 className="custom-h3-style">
          Personas
        </h3>
        <button
          className={`md:hidden primary_button_custom ${isAddButtonDisable ? 'bg-disable_button_state border-disable_button_state' : 'bg-secondry_color border-secondry_color'}`}
          onClick={handleClickAddPersona}
          disabled={isAddButtonDisable}
        >
          <img src={PlusSvg} style={{ marginRight: "5px" }} /> Add Persona
        </button>
      </div>

      <p className="custom-primary-p-style ">
        Use Personas to help evyAI know who you are, who they are speaking as
        when they generate posts, comments, and more.
      </p>
      <div className="mt-5 md:mt-8">
        {!loading && showLimitMessage && (
          <FreeTrailEnd
            title="You’ve reached the max limit of Personas."
            content={`You have already saved 1 Persona. ${currentSubscription.status !== SubscriptionStatus.PRO ? "Upgrade in order to continue adding Personas, and using evyAI to speak in your voice." : ""}`}
            buttonName="Upgrade Subscription"
            feature="Personas"
            subStatus={currentSubscription.status}
          />
        )}
      </div>
      <ActionButtons
        isAddButtonDisable={isAddButtonDisable}
        getAllPersonas={getAllPersonas}
        deletePersonaIds={deletePersonaIds}
        setShowConfirmation={setShowConfirmation}
        handleClickAddPersona={handleClickAddPersona}
      />
      {!loading && personas.length <= 0 && (
        <EmptyTablePersonas />
      )}
      {loading && <NoDataFound isLoading={true} text="No data found" />}
      {!loading && personas.length > 0 && (
        <PersonasTable
          personas={personas}
          setPersonas={setPersonas}
          headers={headers}
          setDeletePersonaIds={setDeletePersonaIds}
          deletePersonaIds={deletePersonaIds}
        />
      )}
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmMultipleDelete}
        header={`Delete Persona${deletePersonaIds.length > 1 ? "s" : ""}`}
        content={`Are you sure you want to delete these Persona${deletePersonaIds.length > 1 ? "(s)" : ""}?`}
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </section>
  );
};

export default Personas;
