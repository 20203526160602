import InfoSvg from "../assets/Images/Info.svg";
import useScreenSize from "../hooks/useScreenSize";

type PropsType = {
  title: string;
  content: string;
};

export default function UsageToCompleteBanner(props: PropsType) {
  const { title, content } = props;
  const screen = useScreenSize();

  return (
    <div className="border-[2px] border-[#6D40C4] rounded-2xl xsm:p-3 p-6 bg-[#F5F1FB] w-full font-poppins flex justify-start items-center  ">
        <div className="flex gap-4 xsm:gap-3 w-full justify-start items-start">
            <img src={InfoSvg} width={screen === "small" ? 20 : 25} />
            <div className="flex flex-col md:gap-1">
                <h1 className="font-bold text-[20px] xsm:text-[16px] text-[#333333]">{title}</h1>
                <p className="text-[#333333] text-[18px] xsm:text-[14px]  ">{content}</p>
            </div>
        </div>
    </div>
  );
}
