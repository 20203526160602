import { IoMdClose } from "react-icons/io";
import { BsLinkedin, BsTwitter, BsFacebook } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const ShareDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <div className="fixed w-screen z-[100] h-screen inset-0 top-0 overflow-y-auto">
      <div
        className="fixed inset-0 -top-[77px] md:top-0 w-screen h-screen md:w-full md:h-full bg-[#34317D]  opacity-[0.8]"
        onClick={() => onClose()}
      ></div>
      <div className="flex items-center min-h-screen mx-4 -mt-9 md:mt-0">
        <div className="relative w-full md:w-fit mx-auto bg-white rounded-[16px] shadow-lg p-8 md:p-16">
          <div
            className="absolute flex items-center justify-center md:h-10 md:w-10 bg-none rounded-full top-[16px] right-[16px]"
            data-te-modal-dismiss
            aria-label="Close"
          >
            <IoMdClose
              onClick={() => onClose()}
              size={20}
              className="font-bold text-[#34317D] rounded-full cursor-pointer"
            />
          </div>
          <div className="flex justify-center text-center flex-col gap-4 mt-16 font-poppins">
            <div className="flex justify-between flex-col gap-4">
              <h1 className="text-[#333] md:text-[#6D40C4] text-[28px] md:text-[32px] leading-[34px] font-black">
                Spread The Word!
              </h1>
              <p className="text-sm md:text-base text-[#292929] font-normal">
                Share evyAI with others
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 md:mt-6 md:mb-10">
              <LinkedinShareButton
                title="evyAI"
                source="https://app.evyai.com"
                summary="Check out this extension I’m using, evyAI. It helps me generate comments and posts on LinkedIn using AI. www.evyAI.com"
                url="https://evyai.com"
              >
                <div className="flex items-center md:w-[314px] bg-[#F5F1FB] text-[#444553] gap-3 transition font-bold border-2 hover:border-[#34317D] p-3 rounded-lg">
                  <BsLinkedin size={25} />
                  <p>Share on Linkedin</p>
                </div>
              </LinkedinShareButton>

              <TwitterShareButton
                url="https://evyai.com"
                title="Check out this extension I’m using, evyAI. It helps me generate comments and posts on LinkedIn using AI."
                related={["evyAI"]}
                hashtags={["evyAI", "AI", "LinkedIn"]}
              >
                <div className="flex items-center md:w-[314px] bg-[#F5F1FB] text-[#444553] gap-3 transition font-bold border-2 hover:border-[#34317D] p-3 rounded-lg">
                  <BsTwitter size={25} />
                  <p>Share on Twitter</p>
                </div>
              </TwitterShareButton>
              <FacebookShareButton
                url="https://evyai.com"
                quote={"Share evyAI with others"}
                hashtag="#evyAI #AI #LinkedIn"
              >
                <div className="flex items-center md:w-[314px] gap-3 bg-[#F5F1FB] text-[#444553] transition font-bold border-2 hover:border-[#34317D] p-3 rounded-lg">
                  <BsFacebook size={25} />
                  <p>Share on Facebook</p>
                </div>
              </FacebookShareButton>
              <EmailShareButton
                title="evyAI"
                url="Check out this extension I’m using, evyAI. It helps me generate comments and posts on LinkedIn using AI. www.evyAI.com. Google Chrome Extension: https://chromewebstore.google.com/detail/evyai-ai-assistant-for-so/imemcjjdfiaphipleieldpoiaoejdofp"
                subject="Check out this extension I’m using, evyAI"
              >
                <div className="flex items-center md:w-[314px] gap-3 bg-[#F5F1FB] text-[#444553] transition font-bold border-2 hover:border-[#34317D] p-3 rounded-lg">
                  <MdEmail size={25} />
                  <p>Share via Email</p>
                </div>
              </EmailShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareDialog;
