import Header from "../components/Header";
import LinkBtn from "../components/LinkBtn";
import { HOME_PAGE } from "../constant";
import { BsExclamation } from "react-icons/bs";
import vector from "../assets/vector.svg";



const NotFound = () => {
  return (
    <div className="h-screen container mx-auto">
      <Header />
      <div className="h-[calc(100vh-93px)] py-12 md:items-center justify-center flex max-w-lg mx-auto">
        <div className="w-full max-sm:px-4 max-md:pt-20 md:pb-40">
          <div className="relative flex justify-center py-8">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <BsExclamation className="text-7xl text-white" />
            </div>
            <img src={vector} className="logo text-slate-500" alt="Vite logo" />
          </div>
          <h2 className="text-text_color text-5xl max-sm:text-2xl text-center font-semibold font-lora">
            Page not found
          </h2>
          <p className="text-xl text-center text-text_color mt-5">
            We can't find what you're looking for.
          </p>

          <p className="text-xl text-center text-text_color">
            Let's bring you back to a place we know
          </p>
          <div className="mt-12 justify-center flex">
            <LinkBtn className="btn-custom" link={HOME_PAGE} title="Go to the Homepage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
