import DailogBox from "./DailogBox";
import { Link } from "react-router-dom";
import useLogin from "../pages/Auth/hooks/useLogin";
import { ThreeDots } from "react-loader-spinner";
import { Controller } from "react-hook-form";
import TextInput from "./TextInput";
import Google from '../assets/Images/google.png';
import { GoogleAuthProvider, OAuthCredential, signInWithPopup, User } from "firebase/auth";
import { firebaseAuth } from "../context/Auth";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (credentialsRequired: boolean, isGoogle: boolean, credentials: {password: string} | {user: User, credential: OAuthCredential}) => void;
  show: boolean;
  invalidPassError: boolean;
}
const ReauthenticateUserDialogue = ({
  setShow,
  show,
  onConfirm,
  invalidPassError,
}: IProps) => {
    
    const {
        formControl,
        formErrors,
        isLoading,
        errorText,
        getValues,
    } = useLogin();
    
    const handleClose = async () => {
        setShow(false);
    }
    
    const sumbitHandler = async (e: any) => {
        e.preventDefault();
        const values = getValues();
        onConfirm(true, false, {password: values.password});
    }
    
    const sumbitHandlerGoogle = async (e: any) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(firebaseAuth, provider);
        const user = result.user;
        // const idToken = await user.getIdToken();
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const credential = GoogleAuthProvider.credential(idToken);
        if (credential) {
            onConfirm(true, true, {user, credential});
        }
    }

  return (
    <DailogBox
        open={show}
        onClose={handleClose}
        dialogClass="w- !z-[99999999999999] flex justify-center items-center"
        dialogBoxClass="flex justify-center items-center"
        >
            <div className="md:py-0 md:px-0 !pb-[78px]">
                <h2 className="md:text-[28px] md:max-w-[75vh] md:font-extrabold text-[20px] mt-10 font-black font-poppins text-text_color py-1 md:mt-[62px]">
                    Please enter your password or sign in again in order to delete your evyAI Account.
                </h2>
                <p className="md:text-[20px] md:font-normal text-[16px] font-normal md:leading-8 leading-normal font-poppins text-text_color mt-2 md:mt-6">
                </p>
                <form
                    onSubmit={sumbitHandler}
                    className="flex flex-col md:mt-6 mt-6"
                >
                    <div className="md:mt-8 ">
                    <label htmlFor="password" className="labal w-full text-left">
                        Password
                    </label>
                    <Controller
                        name="password"
                        render={({ field }) => (
                        <TextInput
                            error={formErrors.password?.message}
                            {...field}
                            id="password"
                            type="password"
                            placeholder="• • • • • • • • • • • •"
                            className="input"
                        />
                        )}
                        control={formControl}
                    />
                    </div>
                    <div className="text-right md:mt-4 mt-2">
                    <Link to="/forgot-password">
                        <button
                        type="button"
                        className="text-secondry_color underline font-semibold font-poppins md:text-[18px] text-[14px]"
                        >
                        Forgot Password?
                        </button>
                    </Link>
                    </div>
                    {invalidPassError &&
                        <div className="my-2 text-red-500 text-center w-full">
                            The Password is incorrect!
                        </div>
                    }
                    {errorText && (
                    <div className="my-2 text-red-500 text-center w-full">
                        {errorText}
                    </div>
                    )}
                    <button
                    disabled={formErrors.password ? true : false}
                    type="submit"
                    className={`${!formErrors.password
                        ? "bg-secondry_color text-white"
                        : "!bg-gray-200 text-black_color !cursor-not-allowed"
                        } rounded-[10px] w-full px-6 py-[12px] md:py-[12px] font-poppins md:text-base md:mt-4 mt-4 text-sm font-bold h-11 flex items-center justify-center`}
                    >
                    {!isLoading ? (
                        "Confirm"
                    ) : (
                        <ThreeDots
                        height={16}
                        width={30}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass="justify-center"
                        visible={true}
                        ariaLabel="three-dots-loading"
                        />
                    )}
                    </button>
                    <button
                    type='button'
                    onClick={sumbitHandlerGoogle}
                    className='w-full text-[#73747E] text-center border border-[#34317D] rounded-[10px] px-6 py-[10px] flex items-center justify-center md:mt-6 mt-4'
                    >
                    <img src={Google} alt="Google" className="md:w-[23px] md:h-6 w-[17px] h-[18]" />
                    <span className="pl-[10px] text-[#34317D] font-poppins md:text-base text-sm font-bold">
                        Use Google to Sign in
                    </span>
                    </button>
                </form>

            </div>
    </DailogBox>
  );
};
export default ReauthenticateUserDialogue;
