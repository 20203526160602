function LeftArrow({ height = 20, width = 20, color = '#6E40C5' }: any) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.4652 6.00037C2.4652 5.79965 2.54124 5.59894 2.69301 5.44591L7.47131 0.630149C7.77527 0.323804 8.26809 0.323804 8.57193 0.630149C8.87576 0.936371 8.87576 1.43296 8.57193 1.73933L4.34382 6.00037L8.57178 10.2614C8.87562 10.5678 8.87562 11.0643 8.57178 11.3705C8.26794 11.677 7.77512 11.677 7.47117 11.3705L2.69287 6.55484C2.54107 6.40173 2.4652 6.20102 2.4652 6.00037Z" fill={color}/>
        </svg>
    );
  }
  
  export default LeftArrow;

