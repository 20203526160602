import HistoryImg from "../../../assets/Images/History.svg";
import StarIcon from "../../../assets/Images/stars.svg";
import ArrowRight from "../../../assets/Images/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import ActionButtonsHome from "./ActionButtonsHome";

interface IProps {
  title?: string;
  totalCount?: number;
  showSeeAll?: boolean;
  setSavedCommands?: Function;
}

const RecentActivities = ({ title, totalCount, showSeeAll, setSavedCommands }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className="md:mt-8 mt-5 flex justify-between items-center md:pr-0 pr-3 flex-wrap">
      <div className="flex items-center flex-wrap gap-2">
        <img
          className="w-4"
          src={!title ? HistoryImg : StarIcon}
          alt="History"
        />
        <span className="font-poppins text-black md:text-black_color md:text-[20px] md:font-semibold text-lg font-bold md:leading-[30px]">
          {!title ? "Recent Activities" : title}{" "}
          {totalCount && `(${totalCount})`}
        </span>
      </div>
      {showSeeAll && (
        <button
          onClick={() => navigate(`/${PROTECTED_ROUTES.recentActivities}`)}
          className="flex items-center justify-between flex-wrap "
        >
          <span className="font-poppins text-base font-bold text-secondry_color underline">
            See All
          </span>
          <img src={ArrowRight} alt="Arrow" className="w-3 ml-[10px]" />
        </button>
      )}
      {title && <ActionButtonsHome setActivities={setSavedCommands} />}
    </div>
  );
};

export default RecentActivities;
