import React, { useState, useEffect } from 'react';
import Button from "./Button";

interface NextPrevPaginationProps {
  itemsPerPage: number;
  paginate: (isNext: boolean) => void;
  setItemsPerPage: (count: number) => void;
  totalRecords: number;
  accessPagination: (value: boolean) => void;
}

const NextPrevPagination: React.FC<NextPrevPaginationProps> = ({ itemsPerPage, paginate, setItemsPerPage, totalRecords, accessPagination }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalRecords]);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      accessPagination(false);
      paginate(false);
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      paginate(true);
      accessPagination(true);
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div className="flex items-center justify-between py-2 px-3 border-t border-gray-300">
      <div className="flex gap-3 items-center">
        <Button
          className={`border px-1 bg-gray-100 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          Prev
        </Button>
        <Button
          className={`border px-1 bg-gray-100 ${currentPage >= totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleNextClick}
          disabled={currentPage >= totalPages}
        >
          Next
        </Button>
      </div>
      <div className="flex items-center gap-x-4">
        <label htmlFor="rowsPerPage" style={{ width: "100px" }} className="text-gray_dark whitespace-nowrap">Rows per page:</label>
        <select
          id="rowsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="border rounded p-1"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
};

export default NextPrevPagination;
