import { useState, useContext } from "react";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import TextInput from "../../../components/TextInput";
import DailogBox from "../../../components/DailogBox";
import WarningSvg from "../../../assets/Images/Warning.svg";
import saved_profile from "../../../assets/Images/saved_profile.svg";
import Loader from "../../../components/Loader";
import { sentryClient } from "@/Sentry/sentry";

export interface AddProfileProps {
  setShowAlert: (status: boolean) => void;
  showAlert: boolean;
  toggleDataChanged: () => void;
}

export default function AddProfileDialog(props: AddProfileProps) {
  const { setShowAlert, showAlert, toggleDataChanged } = props;
  const { addLinkedInProfileWithURL } = useContext(AuthContext) as AuthContext_type;

  const [profileUrl, setProfileUrl] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    profileUrl: false,
    fName: false,
    lName: false,
  });
  const [errorFields, setErrorFields] = useState({
    profileUrl: true,
    fName: true,
    lName: true,
  });
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  function handleCloseDialog() {
    setShowAlert(false);
  }

  const handleURLChange = (e: any) => {
    const inputValue = e.target.value;
    setProfileUrl(inputValue);
    checkFields(inputValue, fName, lName, 'profileUrl');
    setTouchedFields({
      ...touchedFields,
      profileUrl: true,
    });
  }

  const handleFNameChange = (e: any) => {
    const inputValue = e.target.value;
    setFName(inputValue);
    checkFields(profileUrl, inputValue, lName, 'fName');
    setTouchedFields({
      ...touchedFields,
      fName: true,
    });
  }

  const handleLNameChange = (e: any) => {
    const inputValue = e.target.value;
    setLName(inputValue);
    checkFields(profileUrl, fName, inputValue, 'lName');
    setTouchedFields({
      ...touchedFields,
      lName: true,
    });
  }

  const checkFields = (url: String, fName: String, lName: String, field: any) => {
    const urlError = field === 'profileUrl' && (url.trim() === '' || !url.includes("linkedin.com/in/"));
    const fNameError = field === 'fName' && fName.trim() === '';
    const lNameError = field === 'lName' && lName.trim() === '';

    // setTouchedFields({
    //   ...touchedFields,
    //   [field]: true,
    // });
    setErrorFields({
      ...errorFields,
      [field]: urlError || fNameError || lNameError,
    });
  }


  async function saveProfileWithURL(params: any) {
    try {
      checkFields(profileUrl, fName, lName, 'profileUrl');
      checkFields(profileUrl, fName, lName, 'fName');
      checkFields(profileUrl, fName, lName, 'lName');

      const isAnyErrorPresent = Object.values(errorFields).some((value) => value);

      if (isAnyErrorPresent) {
        setIsError(true);
        return
      }
      if (params) {
        setIsError(false);
        setErrorMsg("")
        setIsSaving(true);
        let modifiedProfileUrl = params.profileUrl.trim().replace(/\/?$/, "/").replace("http://","https://")
        if (!modifiedProfileUrl.startsWith("https://")) {
          modifiedProfileUrl = `https://${modifiedProfileUrl}`;
        }

        //  const modifiedProfileUrl = `https://${params.profileUrl.trim().replace(/\/?$/, "/")}`;

        const avatar = saved_profile
        await addLinkedInProfileWithURL({ profileUrl: modifiedProfileUrl, linkedInProfileName: params.fullName, profilePictureUrl: avatar })
        toastAlertSuccess("Profile added Sucessfully");
        handleCloseDialog();
        toggleDataChanged()
        setProfileUrl("")
        setFName("")
        setLName("")
        setTouchedFields({
          profileUrl: false,
          fName: false,
          lName: false,
        })
        setErrorFields({
          profileUrl: true,
          fName: true,
          lName: true,
        })
      }
    } catch (error: any) {
      toastAlertError(error.message);
      console.log(error.message);

      setIsError(true);
      setErrorMsg(error.message);
      sentryClient.captureException(error)
    } finally {
      setIsSaving(false);
    }


  }

  return (
    <DailogBox
      className="flex flex-col items-center justify-center !max-h-[80vh] !max-w-[644px]"
      open={showAlert}
      onClose={handleCloseDialog}
    >
      <form
        className="max-h-[60vh] overflow-auto"
        onSubmit={(e) => {
          e.preventDefault();
          saveProfileWithURL({
            profileUrl: profileUrl.trim(),
            fullName: fName.trim() + " " + lName.trim(),
          })
        }}
      >
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-weight-800 xsm:font-black xsm:text-[28px] text-[32px] text-[#333333] font-poppins leading-[48px] ">
            Add New Profile
          </h2>
          <p className="text-weight-400 xsm:text-[14px] xsm:leading-[21px] text-[18px] font-poppins text-[#333333] leading-[27px] mb-2 w-[90%]">
            Please paste the LinkedIn profile URL you want to save in the
            <br /> 'Save Profile' section here.
          </p>
        </div>
        <div className="items start mt-3">
          <TextInput
            // sx={{ width: "600px" }}
            className="w-full font-poppins px-4 xsm:text-[14px]"
            labelClassName="font-poppins text-[18px] xsm:font-semibold xsm:text-[14px] text-weight-500 flex w-[600px] xsm:w-full mb-2"
            placeholder="https://www.linkedin.com/in/"
            onChange={handleURLChange}
            value={profileUrl}
            type="text"
            label="LinkedIn Profile URL"
          />
        </div>
        {(errorFields.profileUrl && isError) && (
          <div className="bg-[#FDEEEE] flex items-center xsm:items-start gap-x-3 mt-3 p-4 border border-[#EB5757] rounded-xl font-poppins xsm:text-[14px] text-base text-[#292929] font-normal">
            <img src={WarningSvg} alt="" />
            <p className="xsm:text-left">
              <span className="md:font-bold">Alert!</span> The entered URL is not
              correct. Please try again.
            </p>
          </div>
        )}
        <div className="items start mt-3">
          <TextInput
            // sx={{ width: "600px" }}
            className="w-full font-poppins px-4 xsm:text-[14px]"
            labelClassName="font-poppins text-[18px] xsm:font-semibold xsm:text-[14px] text-weight-500 flex w-[600px] xsm:w-full mb-2"
            placeholder="e.g. Joe"
            onChange={handleFNameChange}
            value={fName}
            type="text"
            label="First Name"
          />
        </div>
        {(errorFields.fName && isError) && (
          <div className="bg-[#FDEEEE] flex items-center xsm:items-start gap-x-3 mt-3 p-4 border border-[#EB5757] rounded-xl font-poppins xsm:text-[14px] text-base text-[#292929] font-normal">
            <img src={WarningSvg} alt="" />
            <p className="xsm:text-left">
              <span className="md:font-bold">Alert!</span> First Name is required
            </p>
          </div>
        )}
        <div className="items start mt-3">
          <TextInput
            // sx={{ width: "600px" }}
            className="w-full font-poppins px-4 xsm:text-[14px]"
            labelClassName="font-poppins text-[18px] xsm:font-semibold xsm:text-[14px] text-weight-500 flex w-[600px] xsm:w-full mb-2"
            placeholder="e.g. Rogan"
            onChange={handleLNameChange}
            value={lName}
            type="text"
            label="Last Name"
          />
        </div>
        {(errorFields.lName && isError) && (
          <div className="bg-[#FDEEEE] flex items-center xsm:items-start gap-x-3 mt-3 p-4 border border-[#EB5757] rounded-xl font-poppins xsm:text-[14px] text-base text-[#292929] font-normal">
            <img src={WarningSvg} alt="" />
            <p className="xsm:text-left">
              <span className="md:font-bold">Alert!</span> Last Name is required
            </p>
          </div>
        )}
        {(isError && errorMsg) && (
          <div className="bg-[#FDEEEE] flex items-center xsm:items-start gap-x-3 mt-3 p-4 border border-[#EB5757] rounded-xl font-poppins xsm:text-[14px] text-base text-[#292929] font-normal">
            <img src={WarningSvg} alt="" />
            <p className="xsm:text-left">
              <span className="md:font-bold">Alert!</span> {errorMsg}
            </p>
          </div>
        )}

        <div className="flex xsm:flex-col-reverse xsm:w-full gap-4 mt-4 justify-center">
          <button
            type="button"
            onClick={() => handleCloseDialog()}
            className="all_dialogue_sType_buttons"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSaving}
            className="all_dialogue_pType_buttons bg-secondry_color disabled:text-white disabled:bg-[#C0C0C0] disabled:border-none  "
          >
            Save Profile {isSaving && <Loader size={4} />}
          </button>
        </div>
      </form>
    </DailogBox>
  );
}
