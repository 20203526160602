import { useContext, useState, useEffect } from "react";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { yupResolver } from "@hookform/resolvers/yup";
import { accountSettingSchema } from "../schema";
import { useForm } from "react-hook-form";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { firebaseAuth } from "../../../context/Auth";
import { sentryClient } from "@/Sentry/sentry";

function useSettings() {
  const {
    updateUserProfile,
    User,
    setUser,
    fetchCurrentUserData,
    sendEmailToResetPassword,
    user,
  } = useContext(AuthContext) as AuthContext_type;

  const {
    handleSubmit,
    control: formControl,
    getValues,
    setValue,
    reset,

    formState: { errors: formErrors, isValid: formIsValid, },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      linkedInAccount: "",
      twitterAccount: "",
      email: "",
      profilePictureUrl:""
    },
    resolver: yupResolver(accountSettingSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showDailogBox, setShowDailogBox] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState("");
  const [TND, setTND] = useState(false);

  const onSubmit = async () => {
    const formValues = getValues();
    setIsLoading(true);
    try {
      if (User?.uid) {
        await updateUserProfile(
          User?.uid,
          formValues.firstName,
          formValues.lastName,
          formValues.phoneNumber || "",
          formValues.linkedInAccount || "",
          formValues.twitterAccount || "",
          formValues.profilePictureUrl || ""
        )
          .then(async () => {
            await fetchCurrentUserData(User?.uid).then(() => {
              setUser({
                ...user,
                first_name: formValues.firstName,
                last_name: formValues.lastName,
                phone_number: formValues.phoneNumber,
                linkedin_url: formValues.linkedInAccount,
                twitter_url: formValues.twitterAccount,
                profile_picture_url: formValues.profilePictureUrl
              });
            });
            setIsLoading(false);
            toastAlertSuccess("Details Updated Successfully!");
          })
          .catch((err) => {
            toastAlertError(err.code);
            setIsLoading(false);
            setSignUpError(err);
            sentryClient.captureException(err)
          });
      }
    } catch (error) {
      console.log(error, "error!");
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  };

  const sendPasswordResetEmail = async () => {
    setIsSendingEmail(true);
    await sendEmailToResetPassword(firebaseAuth.currentUser?.email as string);
    setIsSendingEmail(false);
    setShowDailogBox(true);
  };
  const onDailogBoxClose = async () => {
    setShowDailogBox(false);
  };

  useEffect(() => {
    if (user !== null) {
      const formValues = getValues();
      const newValue = {
        ...formValues,
        email: user.email,
        firstName: user.first_name,
        lastName: user?.last_name,
        phoneNumber: user?.phone_number || "",
        linkedInAccount: user.linkedin_url,
        twitterAccount: user.twitter_url || "",
        profilePictureUrl:user.profile_picture_url || ""
      };
      reset(newValue);
    }
  }, [user]);

  return {
    onSubmit,
    handleSubmit,
    sendPasswordResetEmail,
    onDailogBoxClose,
    showDailogBox,
    isSendingEmail,
    signUpError,
    formControl,
    formErrors,
    isLoading,
    formIsValid,
    TND,
    setTND,
    setValue
  };
}

export default useSettings;
