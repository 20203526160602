import React from "react";

interface IProps {
  options: string[];
  value: string;
  label: string;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  [key: string]: any;
}

const CustomizeSelect = ({
  options,
  value,
  label,
  onChange,
  error,
  ...rest
}: IProps) => {
  return (
    <div>
      <label className="form-label">{label}</label>
      <div className="custom-select mt-1">
        <select
          {...rest}
          className="block w-full text-base font-poppins outline-none text-gray_light border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3"
          value={value}
          onChange={onChange}
        >
          {options.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <div className="custom-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
          >
            <path
              d="M7.99997 9.04944C7.71322 9.04944 7.4265 8.9408 7.20788 8.72399L0.328227 1.89784C-0.109409 1.46362 -0.109409 0.759591 0.328227 0.325539C0.765686 -0.108513 1.4751 -0.108513 1.91277 0.325539L7.99997 6.36569L14.0872 0.32575C14.5249 -0.108302 15.2342 -0.108302 15.6716 0.32575C16.1095 0.759802 16.1095 1.46383 15.6716 1.89805L8.79207 8.7242C8.57334 8.94105 8.28662 9.04944 7.99997 9.04944Z"
              fill="#6E40C5"
            />
          </svg>
        </div>
      </div>
      {error && (
        <p className="text-sm font-medium text-rose-600 mt-1 px-1">{error}</p>
      )}
    </div>
  );
};

export default CustomizeSelect;
