import { useNavigate, useParams } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import LeftArrow from "../../assets/svgs/LeftArrow";
import Loader from "../../components/Loader";
import AddTemplateForm from "./components/AddTemplateForm";
import useValidateTemplate from "../../hooks/useValidateTemplate";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { useContext, useEffect, useState } from "react";
import { Timestamp, collection, getDocs, orderBy, query } from "firebase/firestore";
import { COLLECTIONS } from "../../config/firebase";
import { db } from "../../services/firebase";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import { ITemplate } from "../templates";
import { sentryClient } from "@/Sentry/sentry";

export interface Tags {
  id: string;
  name: string;
  modifiedDate?: Timestamp;
}

const AddTemplate = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { getCurrentUser, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, getTemplates, getTagById } = useContext(AuthContext) as AuthContext_type;
  const currentUser = getCurrentUser();

  const [allTags, setAllTags] = useState<Tags[]>([]);
  const [userTags, setUserTags] = useState<Tags[]>([]);
  const [dataChanged, setDataChanged] = useState(false);

  const {
    handleSubmit,
    onSubmit,
    formControl,
    formIsValid,
    isLoading,
    formErrors,
    isLoadingTemplate,
    isSubmitting,
    getValues,
    reset,
    selectedTagsDoc,
    selectedTags,
    selectedTagsNames,
    addTags,
    showUpdatePaymentDialog,
    setShowUpdatePaymentDialog,
    setAllExistingTemplates,
  } = useValidateTemplate();

  const handleGetTags = async (
    orderByField: string = "modifiedDate",
    orderDirection: "asc" | "desc" = "desc"
  ): Promise<any> => {
    try {
      console.log("hitting", orderByField, orderDirection);
      if (currentUser) {
        const allTagsRef = collection(
          db,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );
        const allTagsSnapshot = await getDocs(
          query(allTagsRef, orderBy(orderByField, orderDirection))
        );
        const allTagsMap: Tags[] = [];
        allTagsSnapshot.forEach((tagDoc) => {
          allTagsMap.push({
            ...tagDoc.data(),
            id: tagDoc.id,
          } as Tags);
        });
        console.log(allTagsMap, "TagLists");
        setAllTags(allTagsMap);
        setUserTags(allTagsMap);
      }

      // setLoading(false);
    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
    }
  };

  async function getAllTemplates(sortBy?: string, order?: any) {
    try {
      let allTemplates

      if (sortBy && order) {
        allTemplates = await getTemplates(sortBy, order);
      } else {
        allTemplates = await getTemplates();
      }

      const allTemplatesWithTags: ITemplate[] = await Promise.all(allTemplates.map(async (template: ITemplate) => {
        const allTagsDetails = await Promise.all(template.templateTags.map(async (tag: any) => {
          const tagDetails: Tags = await getTagById(tag);
          return tagDetails;
        }));
        return { ...template, templateTags: allTagsDetails };
      }));
      setAllExistingTemplates(allTemplatesWithTags)
      return allTemplatesWithTags
    } catch (error) {
      console.error("Failed to fetch templates:", error);
      sentryClient.captureException(error)
    }
  }

  const toggleDataChanged = () => {
    setDataChanged((prev) => !prev);
  };

  useEffect(() => {
    handleGetTags();
    getAllTemplates();
  }, [dataChanged]);

  if (isLoadingTemplate) {
    return (
      <div className="flex justify-center padding-[50px]">
        <Loader size={10} />
      </div>
    );
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex justify-start items-center gap-3">
          <p className="cursor-pointer font-poppins text-[#8B8C95] text-[16px] font-normal"
             onClick={() => navigate(`/${PROTECTED_ROUTES.templates}`)}
            >
            Templates
          </p>
          <LeftArrow width={18} height={18} color={"#8B8C95"} />
          <p className="-ml-1 font-poppins text-[16px] font-bold">
            {templateId ? "Edit" : "Add"} Template
          </p>
        </div>
        <AddTemplateForm
              formControl={formControl}
              formIsValid={formIsValid}
              isLoading={isLoading}
              formErrors={formErrors}
              isSubmitting={isSubmitting}
              userTags={userTags}
              allTags={allTags}
              setAllTags={setAllTags}
              toggleDataChanged={toggleDataChanged}
              getValues={getValues}
              reset={reset}
              selectedTagsDoc={selectedTagsDoc}
              selectedTags={selectedTags}
              selectedTagsNames={selectedTagsNames}
              addTags={addTags}
              setShowUpdatePaymentDialog={setShowUpdatePaymentDialog}
            />
      </form>
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </div>
  );
};

export default AddTemplate;
