import { useState, useContext } from "react";

import ChatIcon from "../assets/Images/chat.svg";
import CloseIcon from "../assets/Images/close.svg";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { HiStar } from "react-icons/hi";

import { toastAlertSuccess } from "../utils";
import Loader from "./Loader";
import { sentryClient } from "@/Sentry/sentry";

const RateUsDialog = () => {
  const { addUserReview } = useContext(AuthContext) as AuthContext_type;

  const [review, setReview] = useState("");
  const [showReview, setShowReview] = useState(false);
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [rating, setRating] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showRateUs, setShowRateUs] = useState(true);

  const handleAddReview = async () => {
    try {
      if (rating !== null) {
        setIsAddingReview(true);
        await addUserReview(review, rating);
        setIsOpen(false);
        setShowRateUs(false);
        toastAlertSuccess("Thank you for your feedback!", "top-right");
        setShowReview(false);
      }
    } catch (error: any) {
      console.log(error, "err");
      sentryClient.captureException(error)
    } finally {
      setIsAddingReview(false);
    }
  };

  const handleCloseRateUs = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowRateUs(false);
  };

  return (
    <div>
      <div className="fixed z-49 bottom-8 right-8  flex flex-col items-end max-w-[324px] ">
        {(isOpen && showRateUs) && (
          <div className="bg-[#6D40C4] text-white p-6  rounded-lg flex flex-col items-center mb-4">
            <p className="text-lg font-bold mb-4">
              Share your experience with us!
            </p>

            {!showReview ? (
              <div className="flex items-center">
                {Array(4)
                  .fill("")
                  .map((_, index) => (
                    <HiStar
                      key={index}
                      onClick={() => {
                        setRating(index + 1);
                        setShowReview(true);
                        setReview("");
                      }}
                      className="text-white text-3xl cursor-pointer mx-1"
                    />
                  ))}

                <a
                  href="https://chromewebstore.google.com/detail/evyai-ai-assistant-for-so/imemcjjdfiaphipleieldpoiaoejdofp"
                  onClick={()=>{
                    setIsOpen(false);
                    setShowRateUs(false)
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  <HiStar className="text-white text-3xl" />
                </a>
              </div>
            ) : (
              <>
                <div className="rounded mb-4 w-full">
                  <textarea
                    value={review}
                    rows={5}
                    placeholder="Write your review"
                    onChange={(e) => setReview(e.target.value)}
                    className="border-0 outline-none rounded w-full px-4 py-3 text-black"
                  />
                </div>
                <div className="flex gap-x-3">
                  <button
                    className="px-6 py-1 rounded-[10px] border  text-base font-bold  text-white"
                    onClick={() => setShowReview(false)}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={review.length === 0 || isAddingReview}
                    className="px-6 py-1 flex items-center gap-x-2 rounded-[10px] text-base border border-[#8966D0]  font-bold  bg-[#8966D0] text-white disabled:opacity-50"
                    onClick={handleAddReview}
                  >
                    {isAddingReview ? <Loader size={5} /> : "Submit"}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {showRateUs && (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-1 rounded-[45px] bg-[#6D40C4] "
          >
            <div className="flex items-center text-white text-lg font-bold gap-x-2 p-1 pl-4 first-letter:rounded-[45px]">
              <img src={ChatIcon} alt="" />
              Rate Us
              <img
                src={CloseIcon}
                alt=""
                onClick={handleCloseRateUs}
                className="ml-2"
              />
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default RateUsDialog;
