import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActivityCard from "../../components/ActivityCard";
import RecentActivitiesTitle from "./components/RecentActivitiesTitle";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { IActivity } from "../../types/recentActivities.types";
import NoDataFound from "../../components/NoDataFound";
import { COLLECTIONS, FIELDS, SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../services/firebase";
import { UsageCompleteDisabledWrapper } from "../../utils/UsageCompleteDisabledWrapper";
import RateUsDialog from "../../components/RateUsDialog";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PlusIcon from "../../assets/Images/Plus.svg";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { toastAlertSuccess } from "../../utils";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
// import TeamInviteBanner from "../../components/TeamInviteBanner";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import ShowTrialWarningDialogue from "../../components/ShowTrialWarningDialogue";
import SomethingWentWrongDialogue from "../../components/SomethingWentWrongDialogue";
import SubscriptionBanner from "../../components/SubscriptionBanner";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { STORAGE_KEYS } from "@/config/appConfig";
import { sentryClient } from "@/Sentry/sentry";

const Dashboard = () => {
 const {
  getRecentActivities,
  getCurrentUser,
  subscriptionStatus,
  deleteCommandData,
  deleteActivity,
  getCustomerSubscriptionPortalLink,
  isMSubcribeLinkGenerating,
  getTrialWarning,
  disableTrialWarning,
 } = useContext(AuthContext) as AuthContext_type;
 const navigate = useNavigate();

 const [recentActivities, setRecentActivities] = useState<IActivity[]>([]);
 const [isLoadinRecentActivities, setIsLoadinRecentActivities] = useState(false);
 const [showDialog, setShowDeleteDialog] = useState(false);
 const [savedCommands, setSavedCommands] = useState<any>(null);
 const [isLoadingSavedCommands, setIsLoadingSavedCommands] = useState(true);
 const [showBanner, setShowBanner] = useState(false);
 const [id, setDocumentId] = useState("");
 const [isSaved, setIsSaved] = useState(false);
 const [isDeleting, setIsDeleting] = useState(false);
 const [showTrialWarning, setShowTrialWarning] = useState(false);
 const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);
 const [showSubscriptionBanner, setShowSubscriptionBanner] = useState(false);

 useEffect(() => {
  if (subscriptionStatus) {
   if (subscriptionStatus.usagePercentage && subscriptionStatus.usagePercentage >= 100 && subscriptionStatus.status === SubscriptionStatus.FREE) {
    setShowBanner(true);
   } else if (subscriptionStatus.status === SubscriptionStatus.PRO) {
    setShowBanner(false);
   }
   if (subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
    setShowUpdatePaymentDialog(true);
   } else {
    setShowUpdatePaymentDialog(false);
   }
   const subscriptionBannerShown = localStorage.getItem(STORAGE_KEYS.SUBSCRIPTION_BANNER_SHOWN);
   if (subscriptionStatus.usagePercentage < 100 && ["FREE", "PREMIUM", "TEAMS_PREMIUM"].includes(subscriptionStatus.subscription!) && !subscriptionBannerShown) {
    setShowSubscriptionBanner(true);
   }
  }
 }, [subscriptionStatus]);

 const hanldleGetSaveCommands = async () => {
  try {
   const user = await getCurrentUser();
   if (user?.uid) {
    const q = query(collection(db, `${COLLECTIONS.USER_ACTIVITIES}/${user?.uid}/${COLLECTIONS.COMMANDS}`), orderBy(FIELDS.TIMESTAMP, "desc"));

    onSnapshot(q, (querySnapshot) => {
     setIsLoadingSavedCommands(false);
     const cmds: any[] = [];
     querySnapshot.forEach((doc) => {
      cmds.push({ id: doc.id, ...doc.data() });
     });
     setSavedCommands(cmds);
    });
   }
  } catch (error) {
   setIsLoadingSavedCommands(false);
   sentryClient.captureException(error);
   return error;
  }
 };

 useEffect(() => {
  hanldleGetSaveCommands();
 }, []);

 const fetchRecentActivities = async (willStartAgain: boolean = true) => {
  try {
   setIsLoadinRecentActivities(true);
   if (willStartAgain) {
    setRecentActivities([]);
   }
   const _recentActivities = await getRecentActivities(4);
   setRecentActivities(_recentActivities as any);
  } catch (error) {
   sentryClient.captureException(error);
   return error;
  } finally {
   setIsLoadinRecentActivities(false);
  }
 };

 useEffect(() => {
  fetchRecentActivities(true);
 }, []);

 useEffect(() => {
  const fetchData = async () => {
   const trialWarning = await getTrialWarning();
   if (trialWarning) {
    setShowTrialWarning(trialWarning);
   }
  };

  fetchData();
 }, []);

 const handleDelete = async () => {
  try {
   if (id) {
    setIsDeleting(true);
    if (isSaved) {
     const { error, docId } = await deleteCommandData(id);

     if (error === null && docId?.length > 0) {
      const tempSavedCommands = [...recentActivities];
      const indexToRemove = tempSavedCommands.findIndex((command: any) => command.id === docId);
      if (indexToRemove > -1) {
       tempSavedCommands[indexToRemove].isSaved = false;
       setRecentActivities([...tempSavedCommands]);
      }
     }
     toastAlertSuccess("Deleted successfully");
    } else {
     await deleteActivity(id);
     fetchRecentActivities(true);

     toastAlertSuccess("Deleted successfully");
     navigate(`/${PROTECTED_ROUTES.home}`);
    }
   }
   setShowDeleteDialog(false);
  } catch (err) {
   console.error(err);
   sentryClient.captureException(err);
  } finally {
   setIsDeleting(false);
  }
 };

 return (
  <section>
   <div className='flex md:items-end items-center justify-between flex-wrap'>
    <h3 className='custom-h3-style'> Home</h3>
    <button
     onClick={() => navigate(`/${PROTECTED_ROUTES.createPost}`)}
     className='primary_button_custom'
    >
     <img
      src={PlusIcon}
      alt='Plus Icon'
     />
     New Post
    </button>
   </div>
   <p className='custom-primary-p-style'>Browse your recent activities as well as any items you have saved to view or use later.</p>

   {showSubscriptionBanner && <SubscriptionBanner />}

   {showBanner && (
    <FreeTrailEnd
     title='Your free trial has finished!'
     content='Upgrade your plan to get access to our Premium Features'
     subStatus={subscriptionStatus?.status}
    />
   )}
   {/* {showBanner && <WelcomeBanner setShowBanner={setShowBanner} />} */}
   {/* {showInviteNotification && teamInvitesArrTeams.map((team) => {
        return (
          <div className="mt-4">
            <TeamInviteBanner
              team={team}
              teamInvitesArr={teamInvitesArr}
              acceptLoading={acceptLoading}
              declineLoading={declineLoading}
              handleAcceptTeamInvite={handleAcceptTeamInvite}
              handleDeclineTeamInvite={handleDeclineTeamInvite}
            />
          </div>
        )
      })} */}
   <RecentActivitiesTitle showSeeAll={true} />
   {recentActivities.length <= 0 || isLoadinRecentActivities ? (
    <NoDataFound
     text='No activities yet'
     isLoading={isLoadinRecentActivities}
    />
   ) : (
    <UsageCompleteDisabledWrapper
     isDisabled={!!(subscriptionStatus?.usagePercentage && subscriptionStatus.usagePercentage >= 100) && subscriptionStatus.status === SubscriptionStatus.FREE}
    >
     <Swiper
      slidesPerView={"auto"}
      spaceBetween={16}
      pagination={{
       clickable: true,
      }}
      className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-6 mb-10 !z-0'
     >
      {recentActivities?.map((item, index) => {
       return (
        <SwiperSlide
         key={index}
         className='!w-[80%] sm:!w-[60%] md:!w-[40%] lg:!w-[calc(25%-16px)] !z-0'
        >
         <ActivityCard
          onDeleteClicked={() => {
           setDocumentId(item.id);
           setShowDeleteDialog(true);
           setIsSaved(false);
          }}
          generatedText={item.activityType === "post_ideas" ? item.activityData.summaryText : item?.activityData?.generatedText}
          key={index}
          activity={item}
          isRecent={true}
          currentIndex={index}
          setSavedCommands={setRecentActivities}
          savedCommands={recentActivities}
         />
        </SwiperSlide>
       );
      })}
     </Swiper>
    </UsageCompleteDisabledWrapper>
   )}

   <RecentActivitiesTitle
    title='Saved Items'
    totalCount={savedCommands?.length}
    setSavedCommands={setSavedCommands}
   />
   {savedCommands <= 0 || isLoadingSavedCommands ? (
    <NoDataFound
     isLoading={isLoadingSavedCommands}
     text='Start saving your items'
    />
   ) : (
    <UsageCompleteDisabledWrapper
     isDisabled={!!(subscriptionStatus?.usagePercentage && subscriptionStatus.usagePercentage >= 100) && subscriptionStatus.status === SubscriptionStatus.FREE}
    >
     <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-6 mb-10'>
      {savedCommands?.map((item: any, index: number) => {
       return (
        <ActivityCard
         key={index}
         generatedText={item?.generatedText}
         activity={item}
         setSavedCommands={setRecentActivities}
         savedCommands={recentActivities}
         currentIndex={index}
         onDeleteClicked={() => {
          setDocumentId(item.id);
          setShowDeleteDialog(true);
          setIsSaved(true);
         }}
        />
       );
      })}
     </div>
    </UsageCompleteDisabledWrapper>
   )}
   <RateUsDialog />
   <ConfirmationDialog
    setShow={setShowDeleteDialog}
    show={showDialog}
    onConfirm={handleDelete}
    isLoading={isDeleting}
    header='Delete Activity'
    content='Are you sure you want to delete this activity?'
   />
   <ShowTrialWarningDialogue
    show={showTrialWarning}
    setShow={setShowTrialWarning}
    onConfirm={disableTrialWarning}
   />
   <PaymentMethodPastDueDialogue
    show={showUpdatePaymentDialog}
    setShow={setShowUpdatePaymentDialog}
    onConfirm={() => getCustomerSubscriptionPortalLink()}
    isLoading={isMSubcribeLinkGenerating}
   />
   <SomethingWentWrongDialogue
    show={false}
    setShow={() => {}}
    content=''
   />
  </section>
 );
};

export default Dashboard;
