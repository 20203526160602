import { createContext } from "react";
import { SubscriptionStatus } from "../../config/firebase";
import { DocumentData, Timestamp } from "firebase/firestore";
import { OAuthCredential,  User } from "firebase/auth";
import { SubscriptionType } from "./types";
import { IUser } from "./auth.types";
import { HttpsCallableResult } from "firebase/functions";
import { IGeneratedIdea } from "../../pages/IdeaGenerator";

export interface AuthContext_type {
 signUpWithEmail: (email: string, password: string, firstName: string, lastName: string, phoneNumber: string, linkedInAccount: string) => Promise<unknown>;
 updateUserProfile: (
  userId: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  linkedInAccount: string,
  twitterAccount: string,
  profilePictureUrl: string
 ) => Promise<unknown>;
 signInWithGoogle: () => Promise<any>;
 getCurrentUser: () => any;
 fetchUserDataById: (id: string) => any;
 fetchUserDataByEmail: (email: string) => any;
 fetchSingleUserActivity: (id: string) => any;
 fetchCurrentUserData: (id: string) => any;
 addMembersToTeam: (teamId: string, memberEmails: string[]) => any;
 fetchInviteStatus: (teamId: string) => any;
 fetchTeamInvites: (userId: string) => any;
 fetchMemberById: (teamId: string, memberId: string) => any;
 fetchTeamById: (teamId: string) => any;
 acceptTeamInvite: (teamId: string, inviteId: string) => any;
 declineTeamInvite: (teamId: string, inviteId: string) => any;
 deleteUserFromTeam: (teamId: string, userId: string) => any;
 sendEmailToResetPassword: (email: string) => Promise<unknown>;
 sendVerificationEmail: () => Promise<void>;
 getCurrentUserIdToken: () => Promise<string> | undefined;
 reloadCurrentUser: () => Promise<void | undefined>;
 deleteImageFromStorage: (url: string) => Promise<void | undefined>;
 getRecentActivities: (
  limitOfRecords?: number,
  willFetchFromBeginning?: boolean
 ) => Promise<
  {
   id: string;
   isSaved: boolean;
  }[]
 >;
 getSavedCommands: (limitOfRecords?: number, willFetchFromBeginning?: boolean) => Promise<any>;
 getSubscriptionPlans: () => Promise<
  {
   id: string;
  }[]
 >;
 getUserSubscriptionData: () => Promise<any>;
 addUserReview: (
  reviewText: string,
  rating: number
 ) => Promise<
  | {
     isSuccess: boolean;
    }
  | undefined
 >;
 fetchSingleData: (id: string, collection: string) => Promise<any>;
 updateWelcomeTourStatus: (id: string) => Promise<any>;
 updateTeamAcceptedNotificationStatus: () => Promise<any>;
 updateTeamSize: (id: string) => Promise<any>;
 updateTeamSeats: (seats: number) => Promise<boolean>;
 getCustomerSubscriptionPortalLink: (willEnableLoading?: boolean) => Promise<
  | {
     error: null;
     url: any;
    }
  | {
     error: any;
     url: null;
    }
 >;
 fetchSubscriptionStatusByUserId: (userId: string) => Promise<{
  error: any;
  status: SubscriptionStatus;
  usagePercentage: number;
  hasTeamsPlan?: boolean;
  allowedTeamMembers?: number;
  subscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
  interval?: "MONTH" | "YEAR" | string;
  isAdmin?: boolean;
  userPersonalSubscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
  subscriptionData?: SubscriptionType | null;
  statusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
  userPersonalStatusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
  willGetTrial?: boolean;
 }>;
 getSubscriptionStatus: () => Promise<{
  error: any;
  status: SubscriptionStatus;
  usagePercentage: number;
  hasTeamsPlan?: boolean;
  allowedTeamMembers?: number;
  subscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
  interval?: "MONTH" | "YEAR" | string;
  isAdmin?: boolean;
  userPersonalSubscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
  subscriptionData?: SubscriptionType | null;
  statusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
  userPersonalStatusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
  willGetTrial?: boolean;
  // statusDb?: string | null;
 }>;
 goToSubscriptionPlanPaymentURL: (priceId: string, quantity?: number, trial_from_plan?: boolean) => Promise<unknown>;
 deleteCommandData: (documentId: string) => Promise<{
  docId: string;
  error: any;
 }>;
 deleteActivity: (documentId: string) => Promise<{
  docId: string;
  error: any;
 }>;
 deleteAccount: (credentialsRequired: boolean, isGoogle: boolean, credentials: { password: string } | { user: User; credential: OAuthCredential } | any) => Promise<unknown>;
 setIsNavigateToSub: React.Dispatch<React.SetStateAction<boolean>>;
 setIsTeamInviteUrl: React.Dispatch<React.SetStateAction<boolean>>;
 verificationTime: Timestamp | null;
 setVerificationTime: React.Dispatch<React.SetStateAction<Timestamp | null>>;
 showModalType: Record<string, boolean>;
 setShowModalType: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
 fetchSuggestedComments: (data: any) => Promise<any>;
 getPersonasList: () => Promise<any>;
 handleExtensionMessages: () => void;
 addCommandData: (
  activityData: any,
  activityType: string,
  generatedText: string,
  id: string
 ) => Promise<{
  docId: string;
  error: any;
 }>;
 signOut: (isNavigate?: boolean) => Promise<unknown>;
 signInWithEmail: (
  email: string,
  password: string
 ) => Promise<
  | {
     success: boolean;
     message?: undefined;
    }
  | {
     success: boolean;
     message: any;
    }
 >;
 isSendingVerificationEmail: boolean;
 isMSubcribeLinkGenerating: boolean;
 isStripeLinkGenerating: string | null;
 user: IUser | null;
 teamInvites: any[];
 // newTeamInvite: any;
 setUser: (values: any) => void;
 deleteLinkedinProfile: (profileId: string) => Promise<void>;
 User: User | null;
 userSnapShot: DocumentData | null;
 isTeamInviteUrl: boolean;
 teamInviteAcceptParams: { team_id: string; member_id: string } | undefined;
 isNavigateToSub: boolean;
 emailVerified: boolean;
 setEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
 emailToChange: string;
 setEmailToChange: React.Dispatch<React.SetStateAction<string>>;
 subscriptionData: SubscriptionType | undefined;
 isLoadingUser: boolean;
 createTeam: (data: any) => Promise<any>;
 editTeamName: (teamId: string, newTeamName: string) => Promise<any>;
 createTag: (data: any) => Promise<any>;
 getTagById: (id: string) => Promise<any>;
 deleteTag: (tagId: string) => Promise<any>;
 createList: (data: any) => Promise<any>;
 updateList: (listId: string, listName: string) => Promise<any>;
 updateTag: (tagId: string, tagName: string) => Promise<any>;
 handleGetUserLists: () => Promise<any>;
 getTemplates: (sortBy?: string, order?: any) => Promise<any>;
 addTemplate: (data: any) => Promise<any>;
 editTemplate: (templateId: string, data: any) => Promise<any>;
 addPersona: (data: any) => Promise<any>;
 deleteList: (listId: string) => Promise<any>;
 deleteMultipleProfiles: (profileIds: string[]) => Promise<any>;
 updateProfileToLists: (ProfileId: string, listIds: string[]) => Promise<any>;
 deleteMultipleTags: (tagsId: string[]) => Promise<any>;
 deleteMultipleLists: (listId: string[]) => Promise<any>;
 addProfilesToLists: (profileIds: string[], listIds: string[]) => Promise<any>;
 editPersona: (data: any) => Promise<any>;
 deletePersona: (data: any) => Promise<any>;
 deleteTemplate: (data: any) => Promise<any>;
 duplicateTemplate: (data: any) => Promise<any>;
 deleteMultiplePersonas: (data: any) => Promise<any>;
 deleteMultipleTemplates: (data: any) => Promise<any>;
 handleUploadPersonaImage: (file: any) => Promise<any>;
 handleUploadProfileImage: (file: any) => Promise<any>;
 getPersonas: (sortBy?: string, order?: any) => Promise<any>;
 getEnableTrail: () => Promise<any>;
 getTrialWarning: () => Promise<boolean>;
 disableTrialWarning: () => Promise<boolean>;
 generateProfileOptimizationResponse: ({
  goal,
  tone,
  personaId,
  user_input,
  gpt_version,
 }: {
  goal: string;
  tone: string;
  personaId: string;
  user_input: string;
  gpt_version: string;
  user_name: string;
  last_generated_optimizer: string;
  command: string;
 }) => Promise<HttpsCallableResult<unknown>>;

  generatePostIdeas: (data: {
    prompt: string;
    personaId: string;
    existing_ideas: IGeneratedIdea[];
  } & Record<string,any>
  ) => Promise<HttpsCallableResult<unknown>>;

  handleUpdateTeamSize: (subscriptionId: string, planId: string, quantity: number, subscriptionItemId: string) => Promise<{
    success: boolean;
    error: null;
    response: HttpsCallableResult<unknown>;
} | {
    success: boolean;
    error: any;
    response: null;
}>;
  subscriptionStatus:
    | {
        error: any;
        status: SubscriptionStatus;
        usagePercentage: number;
        hasTeamsPlan?: boolean;
        allowedTeamMembers?: number;
        subscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
        interval?: "MONTH" | "YEAR" | string;
        isAdmin?: boolean;
        userPersonalSubscription?: "FREE" | "PREMIUM" | "TEAMS_PREMIUM" | "TEAMS_MEMBER" | string;
        subscriptionData?: SubscriptionType | null;
        statusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
        userPersonalStatusDB?: "active" | "past_due" | "canceled" | "trialing" | string | null;
        willGetTrial?: boolean;
        // statusDb?: string | null;
      }
    | undefined;
  downvoteGeneratedIdea: ({
    userPrompt,
    idea,
    persona,
  }: {
    userPrompt: string;
    idea: string;
    persona: string;
  }) => Promise<HttpsCallableResult<unknown>>;
  upvoteGeneratedIdea: ({
    userPrompt,
    idea,
    persona,
  }: {
    userPrompt: string;
    idea: string;
    persona: string;
  }) => Promise<HttpsCallableResult<unknown>>;
  addLinkedInProfile: (profileUrlserPrompt: string) => void;
  addLinkedInProfileWithURL: (profileData: any) => void;
  addQuestionOrIssues: (question: string) => Promise<void>;
  updateSubscriptionToTeamsDirectly: (period: "monthly" | "yearly", quantity: number) => Promise<{
    error: string;
    success: boolean;
}>

  
}

export const AuthContext = createContext<AuthContext_type | null>(null);

export const AuthContextProvider = AuthContext.Provider;
