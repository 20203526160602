import { useContext, useState } from "react";
import _ from "lodash";
// import { auth } from "../../services/firebase";
import Header from "../../components/Header";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
// import { User, updateEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../../router/routes.constants";
// import { toast } from "react-toastify";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";

const ChangeEmail = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setEmailToChange } = useContext(AuthContext) as AuthContext_type;
  const navigate = useNavigate();

  // oob code extract from url
  const handleChangeEmail = (e: any) => {
    e.preventDefault();
    if (handleValidation()) {
      setIsLoading(true);
      navigate(Navigate_to.register)

      // updateEmail(auth.currentUser as User, email)
      //   .then(() => {
      //     toast.success(`Email changed to ${email}`, {
      //       position: "top-center",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "light",
      //     });

      //     setIsLoading(false);
      //     setEmail("");
      //     navigate(UNPROTECTED_ROUTES.emailVerificationPending);
      //   })
      //   .catch((err: any) => {
      //     if (err.code === "auth/operation-not-allowed") {
      //       setError("Please verify the new email before changing email");
      //     }else{
      //       setError("Something went wrong please try again");
      //     }
      //     setIsLoading(false);
      //   });
    }
  };

  const handleValidation = () => {
    if (!email) {
      setError("Email is required**");
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setError("Invalid email address*");
      return false;
    }

    setError("");
    return true;
  };

  return (
    <>
      <div className="h-screen container mx-auto">
        <Header />
        <hr className="border-t border-[#DFDFDF] md:hidden" />
        <div className="h-[calc(100vh-93px)] flex items-center justify-center  max-w-lg mx-auto">
          <div className="w-full -mt-10 max-sm:px-4 md:pb-40">
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-[32px] md:text-5xl text-text_color text-center font-black font-poppins leading-[40px]">
                Change Email
              </h2>
              <p className="font-poppins md:text-xl text-center md:mt-6 text-base mt-4">
                Please enter your new email
              </p>
            </div>
            <div className="mt-12">
              <form onSubmit={handleChangeEmail}>
                <TextInput
                  error={error}
                  className="input"
                  labelClassName="labal"
                  placeholder={"example@example.com"}
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                    setEmailToChange(e.target.value);
                  }}
                  type="text"
                  label="Enter your new email"
                />

                <div className="mt-6 justify-center flex">
                  <Button
                    isLoading={isLoading}
                    className="bg-secondry_color text-white py-[10px] px-6 rounded-[10px] w-full font-poppins md:text-base  text-sm font-bold flex items-center justify-center"
                    type="submit"
                    // onClick={handleChangeEmail}
                    disabled={isLoading ? true : false}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmail;
