import { useState, useContext } from "react";
import { toastAlertSuccess } from "../../../utils";
import { FetchData, SavedProfiles } from "..";
import DailogBox from "../../../components/DailogBox";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";
import { UserLists } from "../../ManageLists";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import TextInput from "../../../components/TextInput";
import useScreenSize from "../../../hooks/useScreenSize";

export interface AddToListProps {
  setShowAlert: (status: boolean) => void;
  showAlert: boolean;
  selectedProfiles: string[];
  allLists: Array<UserLists>;
  setSavedProfiles: React.Dispatch<React.SetStateAction<SavedProfiles[]>>;
  savedProfiles: Array<SavedProfiles>;
  setSelectedProfiles: (profileIds: string[]) => void;
  onDataChanged: () => void;
  rowsPerPage: number;
  handleGetUserProfile: (fetchData?: FetchData) => Promise<any>
  accessPagination: boolean;
  createList : (name: string)=> Promise<any>;
  handleGetUserLists: () => Promise<any>;
  setRefreshListFlag: React.Dispatch<React.SetStateAction<boolean>>;
  refershListsFlag: boolean;
}

export default function AddToList(props: AddToListProps) {
  const { addProfilesToLists } = useContext(AuthContext) as AuthContext_type;
  const {
    setShowAlert,
    showAlert,
    selectedProfiles,
    allLists,
    setSavedProfiles,
    savedProfiles,
    setSelectedProfiles,
    rowsPerPage,
    handleGetUserProfile,
    accessPagination,
    createList,
    handleGetUserLists,
    setRefreshListFlag,
  } = props;

  const [selectedLists, setSelectedLists] = useState<UserLists[]>([]);
  const [searchLists, setSearchLists] = useState<UserLists[]>(allLists);
  const [search, setSearch] = useState("");

  const screen = useScreenSize();

  function handleOnChange(value: string) {
    setSearch(value);
    const regex = new RegExp(value, "i");
    const filteredLists = allLists.filter((list) => regex.test(list.name));
    setSearchLists(filteredLists);
  }

  function handleCloseDialog() {
    setSelectedProfiles([]);
    setShowAlert(false);
    setRefreshListFlag(false);
  }

  const addOrRemoveList = (list: UserLists) => {

    const isSelected = selectedLists.some(
      (userSelectedList) => userSelectedList.id === list.id
    );
    if (isSelected) {
      const updatedLists = selectedLists.filter(
        (userSelectedList) => userSelectedList.id !== list.id
      );
      setSelectedLists(updatedLists);
    } else {
      setSelectedLists([...selectedLists, list]);
    }
  };


  async function handleSubmit() {
    let userAllLists:any = {};
    if(search && selectedLists.length <= 0 ){
      await createList(search);
      userAllLists = await handleGetUserLists();
      setSelectedLists([...selectedLists, userAllLists[0]]);
      setRefreshListFlag(true);
    }

    const selectedListIdsSet = new Set(
      userAllLists[0] && typeof userAllLists[0] === 'object' && userAllLists[0].id
        ? [...selectedLists, userAllLists[0].id]
        : selectedLists.map((list) => list.id)
    );
    const selectedProfilesSet = new Set(selectedProfiles);
    const finalSavedProfiles = savedProfiles.map((profile) => {
      if (selectedProfilesSet.has(profile.id)) {
        const currentListIdsSet = new Set(
          profile.lists?.map((list) => list.id)
        );
        const newLists = selectedLists.filter(
          (list) => !currentListIdsSet.has(list.id)
        );
        return {
          ...profile,
          lists: [...(profile.lists || []), ...newLists],
        };
      }
      return profile;
    });

    setSavedProfiles(finalSavedProfiles);
    await addProfilesToLists(selectedProfiles, Array.from(selectedListIdsSet));
    setSelectedProfiles([]);
    // onDataChanged();
    await handleGetUserProfile({
      orderByField: "timestamp",
      orderDirection: "desc",
      init: (accessPagination) ? false : true,
      query: "",
      isNext : accessPagination,
      startAfterFristDoc : (accessPagination) ? true : false,
      itemsPerPage: rowsPerPage,
    });    
    toastAlertSuccess("Lists added successfully");
    handleCloseDialog();
  }

  return (
    <>
      <DailogBox
        className="flex flex-col items-center justify-center !max-w-[644px]"
        open={showAlert}
        onClose={handleCloseDialog}
      >
        <div className="flex flex-col items-center gap-2">
          <h2 className="font-bold xsm:font-black xsm:text-[28px] text-[#333333] text-[32px] font-poppins">
            Add to Profile
          </h2>
          <p className="text-base font-poppins xsm:hidden text-[#333333]">
            Add profiles to lists
          </p>
        </div>
        <div className="items start xsm:w-full">
          <TextInput
            // sx={{ width: "600px" }}
            className="w-full font-poppins xsm:text-[14px]"
            labelClassName="!font-medium text-base flex w-[600px] xsm:w-full xsm:hidden"
            placeholder={`${screen === "small" ? "Search or type to create list" : "Ex: Fast-growing"}`}
            // placeholder="Ex: Fast-growing"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange(e.target.value);
            }}
            type="text"
            label="List Name"
          />
        </div>
        <div className="flex mt-3 mb-4 xsm:w-full">
          <div className="flex md:flex-wrap xsm:flex-col w-[600px] xsm:w-full" >
            {searchLists.map((list) => {
              const isSelected = selectedLists.some(
                (selectedList) => selectedList.id === list.id
              );
              return (
                <div key={list.id} className="w-1/2">
                  <span
                    className="flex mt-1 cursor-pointer"
                    onClick={() => addOrRemoveList(list)}
                  >
                    <img
                      src={isSelected ? checkImage : unCheckImage}
                      alt="check status"
                    />
                    <span className="ml-2 font-poppins xsm:text-[14px]">{list.name}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex gap-4 justify-center xsm:w-[32vh] xsm:flex-col-reverse">
          <button
            type="button"
            onClick={() => handleCloseDialog()}
            className="font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-transparent text-secondry_color disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
          >
            Save To List
          </button>
        </div>
      </DailogBox>
    </>
  );
}
