import { ReactNode, useContext, useEffect, useState } from "react";
import { dateFormat, openInSameTab } from "../../utils";
import ActiveIcon from "../../assets/Images/active.svg";
import WarningIcon from "../../assets/Images/Warning.svg";
import { SubScriptionStaus_Type, SubscriptionType } from "../../context/Auth/types";
import _ from "lodash";
import Button from "../../components/Button";
import UpArrow from "../../assets/svgs/UpArrow";
import DownArrow from "../../assets/svgs/DownArrow";
import TeamSizeUpdatedDialogue from "../Teams/components/TeamSizeUpdatedDialogue";
// import Loader from "../../components/Loader";
import UsageToCompleteBanner from "../../components/UsageToCompleteBanner";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { SubscriptionStatus } from "../../config/appConfig";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { COLLECTIONS, SUBSCRIPTIONS_INTERVALS, SUBSCRIPTIONS_NAMES, SubscriptionStatusDB } from "../../config/firebase";
import { Timestamp, collection, getDocs, limit, query, where } from "firebase/firestore";
import { firebaseDb } from "../../context/Auth";
import Loader from "../../components/Loader";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import { ThreeDots } from "react-loader-spinner";
import SomethingWentWrongDialogue from "../../components/SomethingWentWrongDialogue";
import { IUser } from "@/context/Auth/auth.types";
import { sentryClient } from "@/Sentry/sentry";
// import UpgradeToPremiumDialogue from "../../components/UpgradeToPremiumDialogue";
// import TeamsDescription from "../../components/TeamsDescription";

// const defualtTeamsPriceId = "price_1PMntuKLRkrcwJ5STo8EpaH0";
const yearlyTeamsPriceId = "price_1PQpT5KLRkrcwJ5SIVkFtfTQ";

export interface TeamMemberSubscription {
  teamData: any;
  adminEmail: string;
  adminSubscriptionStatus: SubScriptionStaus_Type;
  memberSince: Timestamp;
  subscriptionData?: SubscriptionType | null;
}


export const SubscriptionData = ({ children, isEnableTrail }: { children: ReactNode, isEnableTrail?: boolean }) => {

  const {
    subscriptionStatus
  } = useContext(AuthContext) as AuthContext_type;

  const [showUsageCompleteBanner, setShowUsageCompleteBanner] = useState(false)
  const [showUsageToCompleteBanner, setShowUsageToCompleteBanner] = useState(false)

  useEffect(() => {
    if (
      !!(
        subscriptionStatus?.usagePercentage &&
        subscriptionStatus.usagePercentage >= 100
      ) &&
      subscriptionStatus.status === SubscriptionStatus.FREE
    ) {
      setShowUsageCompleteBanner(true);
      setShowUsageToCompleteBanner(false);
    } else if (
      (
        subscriptionStatus?.usagePercentage &&
        subscriptionStatus.usagePercentage >= 80 && subscriptionStatus.usagePercentage <= 100
      )
    ) {
      setShowUsageToCompleteBanner(true);
      setShowUsageCompleteBanner(false);
    }
  }, [subscriptionStatus?.usagePercentage]);

  return (
    <>
      {(showUsageCompleteBanner || showUsageToCompleteBanner) &&
        // {subscriptionStatus?.usagePercentage && subscriptionStatus?.usagePercentage >= 80 && 
        <div className="xsm:mt-6 mt-8">
          {showUsageCompleteBanner &&
            <FreeTrailEnd
              title="Your free trial has finished!"
              content="Upgrade your plan to get access to our Premium Features"
            />
          }
          {showUsageToCompleteBanner &&
            <UsageToCompleteBanner
              title="Your FREE usage is about to finish!"
              content="Upgrade your plan to get access to our Premium Features"
            />
          }
        </div>
      }
      <div className='flex flex-col gap-6 items-stretch justify-center mb-14 bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px] sm:mt-[24px] mt-[16px]'>
        <div className="md:block">
          {
            isEnableTrail && (
              <Button
                className="bg-[#FFD572] text-[#292929] mb-7 mt-6 mx-auto font-poppins text-[18px] font-semibold !py-[12px] !px-2 rounded-[10px] "
              >
                Limited Time Offer
              </Button>
            )
          }
          {
            isEnableTrail ? (
              <>
                <h2 className="xsm:hidden font-poppins xsm:font-black xl:text-[32px] text-[25px] font-extrabold text-center text-[#292929] ">
                  Enjoy 3-Day FREE Premium Trial{" "}
                </h2>
                <h2 className="md:hidden font-poppins xsm:font-black xl:text-[32px] text-[25px] font-extrabold text-center text-[#292929] ">
                  Ready for More?
                </h2>
              </>
            ) : (
              <>
                <h2 className="xsm:hidden font-poppins xsm:font-black xl:text-[32px] text-[25px] font-extrabold text-center text-[#292929] ">
                  Ready for More? Consider Upgrading to Premium{" "}
                </h2>
                <h2 className="md:hidden font-poppins xsm:font-black xl:text-[32px] text-[25px] font-extrabold text-center text-[#292929] ">
                  Ready for More?
                </h2>
              </>
            )
          }

          {
            isEnableTrail ? (
              <>
                <p className="md:hidden font-poppins text-[18px] text-center  text-[#333] mt-2 mb-2 leading-normal max-w-[818px] mx-auto">
                  Try evyAI Premium for FREE for three days with this limited time offer.
                </p>
                <p className="xsm:hidden font-poppins text-[18px] text-center  text-[#333] mt-6 mb-2 leading-normal max-w-[818px] mx-auto">
                  Try evyAI Premium for FREE for three days with this limited time offer.
                  With evyAI Premium, you enjoy unlimited access to GPT4. Sign up today and
                  you won’t be charged for 3 days.
                </p>
              </>
            ) : (
              <>
                <p className="md:hidden font-poppins text-[18px] text-center text-[#333] mt-2 mb-2 leading-normal  mx-auto max-w-[1054px] w-full">
                  Unlock evyAI's full potential with premium!
                </p>
                <p className="xsm:hidden font-poppins text-[18px] text-center text-[#333] mt-6 mb-2 leading-normal  mx-auto max-w-[1054px] w-full">
                  Unlock evyAI's full potential with premium! Enjoy unlimited access to
                  innovative features. Leverage AI to comment, post, and message on
                  LinkedIn with GPT4. Upgrading is quick, simple, and worth every penny.
                  Sign up today!
                </p>
              </>
            )
          }
        </div>
        {children}
        <p className="mt-8 font-poppins text-[16px] font-medium italic leading-normal text-[#696A6F] text-center md:block">
          Billed Annually or Monthly and can be canceled at anytime
        </p>
      </div>
    </>
  );
};

export function SubscribedUser({
  subscriptionStatus,
  isLoading,
  getCustomerSubscriptionPortalLink,
  // updateTeamSeats,
  totalUsersAllowed,
  // setTotalUsersAllowed,
  navigateTo,
  setCustomerSubPortalURL,
  setShowSubscribedStripeLinkDialogue,
}: {
  subscriptionData?: SubscriptionType;
  subscriptionStatus?: SubScriptionStaus_Type;
  isLoading: boolean;
  getCustomerSubscriptionPortalLink: (willEnableLoading?: boolean) => Promise<
    | {
      error: null;
      url: any;
    }
    | {
      error: any;
      url: null;
    }
  >;
  setCustomerSubPortalURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowSubscribedStripeLinkDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  updateTeamSeats?: (seats: number) => Promise<any>;
  totalUsersAllowed?: number;
  setTotalUsersAllowed?: React.Dispatch<React.SetStateAction<number>>;
  navigateTo?: Function;
}) {

  const [pricePaid, setPricePaid] = useState<Number | null>(null)
  const [users, setUsers] = useState(2)
  const [willShowDialogue, setWillShowDialogue] = useState(false)
  const [memberSubscriptions, setMemberSubscriptions] = useState<TeamMemberSubscription[]>([])
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [userCreationDate, setUserCreationDate] = useState<any>();
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const [teamsFetched, setTeamsFetched] = useState(false)
  // const [billingPortalLink, setBillingPortalLink] = useState<string | null>(null);
  const [billingPortalLinkLoading, setBillingPortalLinkLoading] = useState(false);
  const [modifySubscriptionLoading, setModifySubscriptionLoading] = useState(false)
  const [isUpdatingTeamSize, setIsUpdatingTeamSize] = useState(false);
  const [showSomethingWWD, setshowSomethingWWD] = useState(false);
  const [showDeleteUsersToUpdateDialogue, setShowDeleteUsersToUpdateDialogue] = useState(false);
  const [usersToDeleteToUpdate, setusersToDeleteToUpdate] = useState(0)
  const [hasCreatedTeam, setHasCreatedTeam] = useState(false);
  const [createdTeam, setCreatedTeam] = useState<any>();
  const [createdTeamExistingMembers, setcreatedTeamExistingMembers] = useState<number | null>(null)
  const [showUpgradeToTeamsDialogue, setShowUpgradeToTeamsDialogue] = useState(false);
  const [intervalToShow, setIntervalToShow] = useState<"MONTH" | "YEAR" | string>("YEAR");
  const [isLoadingUserTeam, setIsLoadingUserTeam] = useState(true);
  // const [showUpgradeToPremiumDialogue, setShowUpgradeToPremiumDialogue] = useState(false);
  // const [updatingUsersLoading, setUpdatingUsersLoading] = useState(false);
  const {
    user,
    fetchTeamById,
    fetchSubscriptionStatusByUserId,
    fetchUserDataById,
    // updateTeamSize,
    isMSubcribeLinkGenerating,
    handleUpdateTeamSize,
    goToSubscriptionPlanPaymentURL,
  } = useContext(AuthContext) as AuthContext_type;
  // const [loading, setLoading] = useState(false)

  // const handleUpdateSeats = async () => {
  //   setLoading(true)
  //   await updateTeamSeats(users)
  //   setTotalUsersAllowed(users)
  //   if (users !== totalUsersAllowed) {
  //     setWillShowDialogue(true);
  //   } else {
  //     setWillShowDialogue(false);
  //   }
  //   setLoading(false)
  // }

  // useEffect(() => {
  //   const willShowTeamSizeUpdateDialogue = async () => {
  //     if (user && subscriptionStatus) {
  //       console.log(user);

  //       if (subscriptionStatus.hasTeamsPlan === true) {
  //         if (user.team_size !== undefined) {
  //           if (subscriptionStatus.allowedTeamMembers && (user.team_size === subscriptionStatus.allowedTeamMembers + 1)) {
  //             // console.log("teams size", user.team_size);
  //             // console.log("allowed members", subscriptionStatus.allowedTeamMembers + 1);
  //             // console.log("teams size is same");
  //             setWillShowDialogue(false);
  //             } else {
  //             await updateTeamSize(user.uid || user.userId);
  //             // console.log("teams size", user.team_size);
  //             // console.log("allowed members", subscriptionStatus.allowedTeamMembers + 1);
  //             // console.log("teams size updated");
  //             setWillShowDialogue(true);
  //           }
  //         } else {
  //           await updateTeamSize(user.uid || user.userId);
  //           setWillShowDialogue(true);
  //           // console.log("team_size value not found in user collection");
  //         }
  //       }
  //     }
  //   }

  //   willShowTeamSizeUpdateDialogue();

  // }, [user])

  const handleClickUpdateTeamSize = async () => {
    // if (subscriptionData && subscriptionStatus?.allowedTeamMembers) {
    if (subscriptionStatus && (subscriptionStatus.hasTeamsPlan === true && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED)) {
      if (subscriptionStatus.subscriptionData && subscriptionStatus?.allowedTeamMembers) {
        setIsUpdatingTeamSize(true);
        setShowUpgradeToTeamsDialogue(false);
        // let existingMembersQuantity = await handleGetAdminTeam();
        // // console.log(team.id);
        // if (existingMembersQuantity) {
        //   existingMembersQuantity = existingMembersQuantity + 1;
        // } else {
        //   existingMembersQuantity = 1;
        // }
        const subscriptionId = subscriptionStatus.subscriptionData.id;
        const planId = subscriptionStatus.subscriptionData.items[0].plan.id;
        const quantity = users;
        const subItemId = subscriptionStatus.subscriptionData.items[0].id;
        // console.log(createdTeamExistingMembers);
        // console.log(quantity);

        if (createdTeamExistingMembers && (quantity < createdTeamExistingMembers + 1)) {
          const usersExceeding = (createdTeamExistingMembers + 1) - quantity;
          // console.log("created TeamExistingMembers", createdTeamExistingMembers);
          // console.log("quantity", quantity);
          // console.log("users Exceeding", usersExceeding);
          setusersToDeleteToUpdate(usersExceeding);
          setShowDeleteUsersToUpdateDialogue(true);
          setIsUpdatingTeamSize(false);
          return;
        } else {
          const res = await handleUpdateTeamSize(subscriptionId, planId, quantity, subItemId);
          setIsUpdatingTeamSize(false);
          if (!res.success && res.error) {
            setshowSomethingWWD(true);
          } else if (res.success && !res.error) {
            // await getSubscriptionStatus();
            setshowSomethingWWD(false);
            setWillShowDialogue(true);
          }
        }
      }
    } else if (subscriptionStatus && (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE || subscriptionStatus.userPersonalStatusDB === SubscriptionStatusDB.CANCELLED)) {
      setIsUpdatingTeamSize(true);
      const checkOutLink = await goToSubscriptionPlanPaymentURL(yearlyTeamsPriceId, users)
      setCustomerSubPortalURL(checkOutLink as string);
      setShowSubscribedStripeLinkDialogue(true)
      // console.log("checkout link",checkOutLink);
      openInSameTab(checkOutLink as string);
      setIsUpdatingTeamSize(true);
    } else {
      setIsUpdatingTeamSize(true);
      const res = await getCustomerSubscriptionPortalLink()
      const checkOutLink: string = (res.url as any).url;
      // const checkOutLink = await goToSubscriptionPlanPaymentURL(yearlyTeamsPriceId, users)
      setCustomerSubPortalURL(checkOutLink as string);
      setShowSubscribedStripeLinkDialogue(true)
      // console.log("checkout link",checkOutLink);
      openInSameTab(checkOutLink as string);
      setIsUpdatingTeamSize(true);
    }
  }


  // const handleGetBillingPortalLink = async () => {
  //   const res = await  getCustomerSubscriptionPortalLink(false);
  //   const url: string = (res.url as any).url;
  //   if (url.length > 0) {
  //     // setBillingPortalLink(url)
  //   }
  //   setBillingPortalLinkLoading(false);
  // }

  // useEffect(() => {
  //   handleGetBillingPortalLink();
  // }, [])


  const handleModifySubscription = async () => {
    // console.log("modifiaction run");
    setModifySubscriptionLoading(true);
    const res = await getCustomerSubscriptionPortalLink()
    if (!(res.error) && setCustomerSubPortalURL && setShowSubscribedStripeLinkDialogue) {
      setCustomerSubPortalURL(res.url.url as string);
      setShowSubscribedStripeLinkDialogue(true)
      // console.log("RESPONSE BILLLINGGGG PORTALLLLL", res.url.url);
      openInSameTab(res.url.url as string);
    }
    setModifySubscriptionLoading(false);
  }

  const handleOpenBillingPortal = async () => {
    setBillingPortalLinkLoading(true);
    const res = await getCustomerSubscriptionPortalLink()
    if (!(res.error)) {
      openInSameTab(res.url.url as string);
    }
    setBillingPortalLinkLoading(false);
  }

  // const handleUpdateUsers = async () => {
  //   console.log("update user run");
  //   try {
  //     if (subscriptionData && subscriptionData.quantity) {
  //       setUpdatingUsersLoading(true);
  //       const priceId = subscriptionData.items[0].plan.id;
  //       const newQuantity = users - subscriptionData.quantity;
  //       const checkOutLink = await goToSubscriptionPlanPaymentURL(priceId, newQuantity)
  //       setCustomerSubPortalURL(checkOutLink as string);
  //       setShowSubscribedStripeLinkDialogue(true)
  //       // console.log("checkout link",checkOutLink);
  //       openInSameTab(checkOutLink as string);
  //     }
  //     setUpdatingUsersLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setUpdatingUsersLoading(false);
  //   }
  // }

  const handleGetAdminTeam = async () => {
    try {
      if (user) {
        setIsLoadingUserTeam(true);
        const currentUserId = user.uid || user.userId;
        const allTeamsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEAMS}/`
        );
        const allTeamsSnapshot = await getDocs(query(allTeamsRef, where("adminId", "==", currentUserId)));
        const allTeamsMap: any[] = [];
        for (const teamDoc of allTeamsSnapshot.docs) {
          const teamData = teamDoc.data();
          const teamId = teamDoc.id;
          const newTeam = {
            ...teamData,
            id: teamId,
          }
          allTeamsMap.push(newTeam);
        };
        if (allTeamsMap.length > 0) {
          setCreatedTeam(allTeamsMap[0]);
          setHasCreatedTeam(true);
          const memberRef = query(
            collection(
              firebaseDb,
              `${COLLECTIONS.TEAMS}/${allTeamsMap[0].id}/${COLLECTIONS.MEMBERS}`
            ),
            // limit(1)
          );
          const querySnapshot = await getDocs(memberRef);
          const totalMembersLength = querySnapshot.docs.length;
          setcreatedTeamExistingMembers(totalMembersLength);
          setIsLoadingUserTeam(false);
          return totalMembersLength;
        } else {
          setHasCreatedTeam(false);
          setIsLoadingUserTeam(false);
        }
        // console.log(allTeamsMap, "Admin Teams");
      }

    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
    }
  };

  useEffect(() => {
    handleGetAdminTeam();
  }, [user])

  useEffect(() => {
    if (subscriptionStatus) {
      if (
        (subscriptionStatus.hasTeamsPlan === true && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED) ||
        (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.PREMIUM && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED)
      ) {
        subscriptionStatus.interval && setIntervalToShow(subscriptionStatus.interval);
      } else {
        setIntervalToShow(SUBSCRIPTIONS_INTERVALS.YEAR);
      }
    }
  }, [subscriptionStatus])

  const fetchAllMembers = async (teamId: string): Promise<any> => {
    try {
      if (user) {
        // setLoadingSubscriptions(true)
        const memberRef = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`
          ),
          where("email", "==", user.email),
          limit(1)
        );
        const querySnapshot = await getDocs(memberRef);
        const memberData = querySnapshot.docs[0].data();
        const memberSince = memberData.joinedDate;
        return memberSince;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err, "err");
      sentryClient.captureException(err)
      return null;
    }
  };

  const handleFetchTeams = async (teamIds: string[] | undefined) => {
    let teamIdsToMap = teamIds;
    if (user) {
      const userId = user.userId || user.uid;
      const userLatestData = await fetchUserDataById(userId)
      teamIdsToMap = (userLatestData as IUser).memberInTeams;
    }
    if (teamIdsToMap !== undefined && teamIdsToMap.length > 0) {
      // setLoadingSubscriptions(true);
      let memberSubscriptionArr: TeamMemberSubscription[] = [];
      const teamFetchMapPromises = teamIdsToMap.map(async (id: string) => {
        const team = await fetchTeamById(id);
        const adminData = await fetchUserDataById(team.adminId);
        const adminSubStatus = await fetchSubscriptionStatusByUserId(team.adminId);
        const memberSince = await fetchAllMembers(team.id);
        const subData = adminSubStatus.subscriptionData;
        const memberSubscription = { teamData: team, adminEmail: adminData.email, adminSubscriptionStatus: adminSubStatus, memberSince: memberSince, subscriptionData: subData }
        memberSubscriptionArr.push(memberSubscription)
      })
      await Promise.all(teamFetchMapPromises);
      const filteredMemeberSubscriptionArr = memberSubscriptionArr.filter((memberSubscription: TeamMemberSubscription) => memberSubscription.memberSince !== null)
      setMemberSubscriptions(filteredMemeberSubscriptionArr);
      setTeamsFetched(true);
      setLoadingSubscriptions(false);
    } else {
      setMemberSubscriptions([]);
      setTeamsFetched(true);
      setLoadingSubscriptions(false);
    }
  }

  useEffect(() => {
    if (user) {
      setTeamsFetched(false);
      setLoadingSubscriptions(true)
      // console.log("user changed in component", user);
      // setLoadingSubscriptions(true);
      if ((subscriptionStatus && subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE) && user.creationTime) {
        const timestamp = Date.parse(user.creationTime) / 1000;
        if (!(isNaN(timestamp))) {
          const formattedDate = dateFormat(timestamp)
          setUserCreationDate(formattedDate)
        }
      }
      const teamIds = user.memberInTeams;
      // console.log(teamIds);
      handleFetchTeams(teamIds);


      // if (teamIds !== undefined) {
      //   if (teamIds.length > 0) {
      //     // if (!teamsFetched) {
      //     //   }
      //       // !teamsFetched && 
      //       // setTeamsFetched(true);
      //       console.log("teams fetched????????", teamsFetched);
      //   } else {
      //     setMemberSubscriptions([])
      //     setLoadingSubscriptions(false);
      //     setTeamsFetched(true);
      //     }
      // } else {
      //   setMemberSubscriptions([])
      //   setLoadingSubscriptions(false);
      //   setTeamsFetched(true);
      // }
      // if (!teamsFetched) {
      // }
    }
    // setLoadingSubscriptions(false);
  }, [user])

  // useEffect(() => {
  //   if (user && subscriptionStatus) {
  //     setTeamsFetched(false);
  //     setLoadingSubscriptions(true)
  //     console.log("user changed in component", user);
  //   }
  // }, [user, subscriptionStatus])

  // useEffect(() => {
  //   setTeamsFetched(false);
  //   setLoadingSubscriptions(true)
  //   // console.log("user changed in component", user);
  // }, [subscriptionStatus])

  useEffect(() => {
    if (subscriptionStatus && subscriptionStatus.subscriptionData) {
      const price: any = subscriptionStatus.subscriptionData.items[subscriptionStatus.subscriptionData.items.length - 1].price.unit_amount
      setPricePaid(price / 100)
    } else {
      setPricePaid(0);
    }
  }, [subscriptionStatus])

  useEffect(() => {
    // console.log("subbb", subscriptionStatus);
    if (subscriptionStatus && subscriptionStatus.allowedTeamMembers) {
      setUsers(subscriptionStatus.allowedTeamMembers + 1)
    }
    if (subscriptionStatus && subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
      setShowUpdatePaymentDialog(true);
    } else {
      setShowUpdatePaymentDialog(false);
    }
  }, [subscriptionStatus])

  // useEffect(() => {
  //   if (subscriptionData) {
  //     if (subscriptionData.status === SubscriptionStatusDB.PAST_DUE) {
  //       setShowUpdatePaymentDialog(true);
  //     } else {
  //       setShowUpdatePaymentDialog(false);
  //     }
  //   }
  // }, [subscriptionData])


  useEffect(() => {
    // const updateUser = async () => {
    //   if (teamsFetched) {
    //     return;
    //   }
    //   const currentUser = await getCurrentUser();
    //   if (currentUser) {
    //     const updatedUser = await fetchUserDataById(currentUser.uid);
    //     // console.log("updated user", updatedUser);
    //     setLoggedInUser(updatedUser);
    //   }
    // }
    // updateUser();
    // console.log("teams fetched", teamsFetched);
    if (teamsFetched) {
      setLoadingSubscriptions(false);
    }
  }, [teamsFetched])

  // useEffect(() => {
  //   console.log("loading subscriptions", loadingSubscriptions);
  // }, [loadingSubscriptions])

  // useEffect(() => {
  //   console.log("member subscriptions", memberSubscriptions);
  // }, [memberSubscriptions])

  return (
    <>
      <div className={`${(!loadingSubscriptions && teamsFetched) ? "hidden" : "flex"} w-full py-48 justify-center items-center`}>
        <Loader size={16} />
      </div>
      <div className={`${(loadingSubscriptions && !teamsFetched) ? "hidden" : "flex"} flex-col gap-6 sm:[16px] mt-[24px]`}>
        {
          subscriptionStatus && (
            subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE
            // subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED
          ) &&
          <div className='bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px]'>
            <div className="w-full flex justify-between ">
              <h4 className="font-poppins xsm:text-[18px] xsm:font-bold md:text-[22px] text-[10px] font-bold leading-normal text-text_color">
                Your Subscription
              </h4>
              {(subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) &&
                <Button
                  type="button"
                  isLoading={isLoading && modifySubscriptionLoading}
                  disabled={isLoading && modifySubscriptionLoading}
                  onClick={handleModifySubscription}
                  // className="text-[#34317D] xsm:hidden bg-white text-center border-2 border-[#34317D] rounded-[10px] xsm:py-4 xsm:px-6 px-2 py-3 "
                  className="all_sType_buttons xsm:hidden"
                >
                  <span className="font-poppins text-[14px] font-bold">
                    Modify Subscription
                  </span>
                </Button>
              }
            </div>
            <div className="w-[57%] xsm:w-full mt-4 grid grid-cols-2 grid-rows-2 gap-y-4 xsm:flex flex-col xsm:gap-y-0">
              <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                Current Plan
              </h4>
              <p className="font-poppins xsm:mb-3 xsm:text-[14px] font-normal md:text-[20px] text-[10px] leading-[30px] text-[#444553] mt-1">
                {subscriptionStatus &&
                  (
                    subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE
                      ? "Free"
                      : subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.PREMIUM
                        ? `Premium ${subscriptionStatus.interval === SUBSCRIPTIONS_INTERVALS.MONTH ? "Monthly" : "Yearly"}`
                        : subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.TEAMS
                          ? `Teams ${subscriptionStatus.interval === SUBSCRIPTIONS_INTERVALS.MONTH ? "Monthly" : "Yearly"}`
                          : ""
                  )
                }
              </p>
              <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                Current Price
              </h4>
              <p className="font-poppins xsm:text-[14px] font-bold md:text-[20px] text-[10px] leading-[30px] text-[#444553] mt-1">
                {subscriptionStatus ? `$${subscriptionStatus.userPersonalStatusDB === SubscriptionStatusDB.CANCELLED ? 0 : pricePaid}${subscriptionStatus.interval === SUBSCRIPTIONS_INTERVALS.MONTH ? "/month" : "/year"}${subscriptionStatus.subscription === SUBSCRIPTIONS_NAMES.TEAMS ? "/person" : ""}` : ""}
              </p>
            </div>
            {(((subscriptionStatus && subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE) && userCreationDate) ||
              (subscriptionStatus && subscriptionStatus.subscriptionData)) &&
              <div className="md:mt-6 md:mb-6 mt-2 mb-4">
                <hr className="border-t-1 border-[#6E40C5]" />
              </div>
            }
            <div className={`w-[57%] xsm:w-full grid grid-cols-2 ${(subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) ? "grid-rows-3" : "grid-rows-1"} gap-y-4 xsm:flex xsm:flex-col xsm:gap-y-0`}>
              {(subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) &&
                <>
                  <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                    Status
                  </h4>
                  <div className="flex justify-start items-center mt-1 xsm:mt-0 mb-3">
                    {subscriptionStatus.subscriptionData &&
                      (
                        (
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.PAST_DUE ||
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.CANCELLED
                        ) ?
                          <img src={WarningIcon} alt="Warning" className="xsm:w-[6%]" />
                          :
                          <img src={ActiveIcon} alt="Active" className="xsm:w-[6%]" />
                      )
                    }
                    {/* <img src={ActiveIcon} alt="Active" className="xsm:w-[6%]" /> */}
                    <p className="font-poppins xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] ml-1">
                      {subscriptionStatus.subscriptionData &&
                        (
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.ACTIVE && "Active" ||
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.PAST_DUE && "Past Due" ||
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.CANCELLED && "Cancelled" ||
                          subscriptionStatus.subscriptionData.status === SubscriptionStatusDB.TRIALING && "Trial"
                        )
                      }
                    </p>
                  </div>
                </>
              }
              {((subscriptionStatus && subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE) && userCreationDate) &&
                <>
                  <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                    Member Since
                  </h4>
                  <p className="font-poppins xsm:mb-3 xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] mt-1">
                    {userCreationDate}
                  </p>
                </>
              }
              {((subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) && subscriptionStatus.subscriptionData) &&
                <>
                  <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                    Member Since
                  </h4>
                  <p className="font-poppins xsm:mb-3 xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] mt-1">
                    {dateFormat(subscriptionStatus.subscriptionData.created?.seconds)}
                  </p>
                </>
              }
              {/* <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                  Member Since
                </h4>
                <p className="font-poppins xsm:mb-3 xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] mt-1">
                  {(subscriptionStatus && subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE) ? userCreationDate : 
                    subscriptionStatus && subscriptionStatus.subscriptionData && dateFormat(subscriptionStatus.subscriptionData.created?.seconds)
                  }
                </p> */}
              {(subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) &&
                <>
                  <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                    Renew Subscription by
                  </h4>
                  <p className="font-poppins xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] mt-1">
                    {subscriptionStatus.subscriptionData && dateFormat(subscriptionStatus.subscriptionData.current_period_end?.seconds)}
                  </p>
                </>
              }
            </div>
            {(subscriptionStatus && subscriptionStatus.userPersonalSubscription !== SUBSCRIPTIONS_NAMES.FREE) &&
              <Button
                type="button"
                isLoading={isLoading && modifySubscriptionLoading}
                disabled={isLoading && modifySubscriptionLoading}
                onClick={handleModifySubscription}
                // className="text-[#34317D] md:hidden w-full mt-6 bg-white text-center border-2 border-[#34317D] rounded-[10px] xsm:py-4 xsm:px-6 px-2 py-3 "
                className="all_sType_buttons mt-6 md:hidden w-full"
              >
                <span className="font-poppins text-[14px] font-bold">
                  Modify Subscription
                </span>
              </Button>
            }
          </div>
        }
        {memberSubscriptions.length > 0 && memberSubscriptions.map((memberSubscription: TeamMemberSubscription) => {
          const teamName = memberSubscription.teamData.name;
          const memberSince = memberSubscription.memberSince;
          const adminEmail = memberSubscription.adminEmail;
          const adminSubStatus = memberSubscription.adminSubscriptionStatus;
          const subData = memberSubscription.subscriptionData;
          return (
            <div className='bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px]'>
              <div className="w-full flex justify-between pb-3 ">
                <h4 className="font-poppins xsm:text-[18px] xsm:font-bold md:text-[22px] text-[10px] font-bold leading-normal text-text_color">
                  {teamName}
                </h4>
              </div>
              <div className="w-[57%] xsm:w-full mt-4 grid grid-cols-2 grid-rows-1 gap-y-4 xsm:flex xsm:flex-col xsm:gap-y-0 ">
                <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                  Current Plan
                </h4>
                <p className="font-poppins xsm:text-[14px] font-normal md:text-[20px] text-[10px] leading-[30px] text-[#444553] mt-1">
                  {adminSubStatus &&
                    (
                      adminSubStatus.subscription === SUBSCRIPTIONS_NAMES.FREE
                        ? "Free"
                        : adminSubStatus.subscription === SUBSCRIPTIONS_NAMES.PREMIUM
                          ? `Premium ${adminSubStatus.interval === SUBSCRIPTIONS_INTERVALS.MONTH ? "Monthly" : "Yearly"}`
                          : adminSubStatus.subscription === SUBSCRIPTIONS_NAMES.TEAMS
                            ? `Teams ${adminSubStatus.interval === SUBSCRIPTIONS_INTERVALS.MONTH ? "Monthly" : "Yearly"}`
                            : ""
                    )
                  }
                </p>
              </div>
              <div className="md:mt-6 md:mb-6 mt-2 mb-4">
                <hr className="border-t-1 border-[#6E40C5]" />
              </div>
              <div className="w-[57%] xsm:w-full grid grid-cols-2 grid-rows-2 gap-y-4 xsm:flex xsm:flex-col xsm:gap-y-0">
                <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                  Status
                </h4>
                <div className="flex justify-start items-center mt-1 xsm:mt-0 xsm:mb-2">
                  {subData ?
                    (
                      (
                        subData.status === SubscriptionStatusDB.PAST_DUE ||
                        subData.status === SubscriptionStatusDB.CANCELLED
                      ) ?
                        <img src={WarningIcon} alt="Warning" className="xsm:w-[6%]" />
                        :
                        <img src={ActiveIcon} alt="Active" className="xsm:w-[6%]" />
                    ) : (
                      <img src={WarningIcon} alt="Warning" className="xsm:w-[6%]" />
                    )
                  }
                  {/* <img src={ActiveIcon} alt="Active" className="xsm:w-[6%]" /> */}
                  <p className="font-poppins xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] ml-1">
                    {/* {subData === null && "Cancelled"} */}
                    {subData !== null ? subData &&
                      (
                        subData.status === SubscriptionStatusDB.ACTIVE && "Active" ||
                        subData.status === SubscriptionStatusDB.PAST_DUE && "Past Due" ||
                        subData.status === SubscriptionStatusDB.CANCELLED && "Cancelled"
                      ) : "Cancelled"

                      //   :
                      // "Active"
                    }
                    {/* {subscriptionData?.status === "active" && "Active"}
                    {subscriptionData?.status === "past_due" && "Past Due"} */}
                  </p>
                </div>
                <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                  Member Since
                </h4>
                <p className="font-poppins xsm:text-[14px] xsm:font-normal xsm:mb-3  md:text-[20px] text-[10px]  leading-normal text-[#444553] mt-1">
                  {dateFormat(memberSince.seconds)}
                </p>
              </div>
              <div className="md:mt-6 md:mb-6 mt-2 mb-4">
                <hr className="border-t-1 border-[#6E40C5]" />
              </div>
              <div className="w-[57%] xsm:w-full grid grid-cols-2 grid-rows-1 gap-y-4 xsm:flex xsm:flex-col xsm:gap-y-0">
                <h4 className="font-poppins xsm:text-[16px] xsm:font-semibold md:text-[20px] text-[10px] font-semibold leading-normal text-text_color">
                  Account Administrator
                </h4>
                <div className="flex justify-start items-center">
                  <p className="font-poppins xsm:text-[14px] xsm:font-normal  md:text-[20px] text-[10px]  leading-normal text-[#444553] ml-1 xsm:ml-0">
                    {adminEmail}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
        {isLoadingUserTeam ? 
          <div className='bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px] flex flex-col gap-6 justify-center items-center'>
            <Loader size={10} />
          </div>
          :
          (subscriptionStatus && (hasCreatedTeam)) &&
            <>
              <div className='bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px] flex flex-col gap-6'>
                <div className="w-full xsm:flex xsm:flex-col xsm:gap-2 ">
                  <h4 className="font-poppins xsm:text-[18px] xsm:font-bold md:text-[22px] text-[10px] font-bold leading-normal text-text_color">
                    Total Users - {createdTeam && createdTeam.name} ({totalUsersAllowed})
                  </h4>
                  <ul className=" font-poppins text-[18px] xsm:text-[14px] xsm:leading-5 leading-7 md:list-inside xsm:ml-6 ml-2 mt-2 list-disc">
                    <li>Modify your team size effortlessly by adding or removing user seats.</li>
                    <li>Inviting new members prorates the annual fee to accommodate changes.</li>
                    <li>Removing user seats results in a credit applied to your upcoming invoice.</li>
                    {/* <li>Pay only for the seats you use, ensuring a fair and transparent billing process.</li> */}
                  </ul>
                </div>
                <div className="w-full bg-[#F5F1FB] rounded-2xl p-6 flex xsm:flex-col xsm:gap-6 justify-start items-center gap-1">
                  <div className="grid grid-cols-2 w-[23%] xsm:flex xsm:w-full">
                    <h4 className="flex justify-center items-center w-full xsm:w-[60%] text-center font-poppins xsm:text-[18px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                      Total Users
                    </h4>
                    <div className=" w-[75%] py-1 px-4 bg-white rounded-[10px] border-[0.5px] border-[#A1A2A980] flex justify-between items-center gap-4">
                      <h4 className=" w-full text-center font-poppins xsm:text-[20px] xsm:font-bold md:text-[20px] text-[10px] font-semibold leading-normal text-[#444553]">
                        {users}
                      </h4>
                      <div className="flex flex-col justify-around items-center ">
                        <button onClick={() => setUsers(users + 1)} className="cursor-pointer">
                          <UpArrow width={17} />
                        </button>
                        <button onClick={() => !(users <= 2) && setUsers(users - 1)} className="cursor-pointer" disabled={users <= 2}>
                          <DownArrow width={17} color={(users <= 2) ? "#DFDFDF" : "#6E40C5"} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="px-6 py-3 xsm:w-full xsm:text-center xsm:flex xsm:justify-center xsm:items-center disabled:bg-[#C0C0C0] bg-[#34317D] text-white rounded-[10px] font-poppins text-[16px] font-bold"
                    disabled={users === totalUsersAllowed}
                    // onClick={handleUpdateSeats}
                    onClick={handleClickUpdateTeamSize}
                  // onClick={handleModifySubscription}
                  >
                    {isUpdatingTeamSize &&
                      <ThreeDots height={25} color="white" />
                    }
                    {!isUpdatingTeamSize &&
                      <span className="font-poppins text-[14px] font-bold">
                        Update total users {!(users === totalUsersAllowed) && `($${intervalToShow === SUBSCRIPTIONS_INTERVALS.YEAR
                          ? "180 per user/year"
                          : "29 per user/month"})`}
                      </span>
                    }
                  </button>
                </div>
                {/* {subscriptionStatus && (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE &&
                  <button
                    onClick={() => setShowUpgradeToPremiumDialogue(true)}
                    className="px-6 py-3 w-[30%] bg-[#34317D] text-white text-center rounded-[10px] font-poppins text-[16px] font-bold"
                    >
                      Show Pricing
                  </button>
                  )
                } */}
              </div>
              <div className='bg-white xsm:px-4 xsm:py-6 md:p-8 rounded-[16px] flex flex-col gap-6 mb-6'>
                <div className="w-full xsm:flex xsm:flex-col gap-2 ">
                  <div className="w-full flex justify-between">
                    <h4 className="font-poppins xsm:text-[18px] xsm:font-bold md:text-[22px] text-[10px] font-bold leading-normal text-text_color">
                      Billing Portal
                    </h4>
                    <button
                      disabled={billingPortalLinkLoading}
                      onClick={handleOpenBillingPortal}
                      // className="px-6 py-3 xsm:hidden disabled:bg-[#C0C0C0] bg-[#34317D] text-white rounded-[10px] font-poppins text-[16px] font-bold"
                      className="all_pType_buttons md:block hidden px-6"
                    >
                      <span className="font-poppins text-[14px] font-bold">
                        {billingPortalLinkLoading &&
                          <ThreeDots height={25} color="white" />
                        }
                        {!billingPortalLinkLoading &&
                          "Open Billing Portal"
                        }
                      </span>
                    </button>
                  </div>
                  <ul className=" font-poppins text-[18px] xsm:leading-5 xsm:text-[14px] leading-7 md:list-inside xsm:ml-6 ml-2 list-disc">
                    <li>Manage your billing details in the Billing Portal</li>
                    <li>See your complete invoice history</li>
                    <li>Update your credit card or invoicing information (email, billing addess)</li>
                    <li>Contact <span className="underline  text-[#6E40C5]">
                      <a href="mailto:hello@evyAI.com">hello@evyAI.com</a>
                    </span> for help with your subscription</li>
                  </ul>
                </div>
                <button
                  disabled={billingPortalLinkLoading}
                  onClick={handleOpenBillingPortal}
                  // className="px-6 py-3 md:hidden bg-[#34317D] text-white text-center rounded-[10px] font-poppins text-[16px] font-bold"
                  className="all_pType_buttons md:hidden"
                >
                  {isMSubcribeLinkGenerating &&
                    <ThreeDots height={20} color="white" />
                  }
                  {!isMSubcribeLinkGenerating &&
                    "Open Billing Portal"
                  }
                </button>
              </div>
            </>
        }
        {/* <UpgradeToPremiumDialogue open={showUpgradeToPremiumDialogue} setOpen={setShowUpgradeToPremiumDialogue} onlyShowPaywall={true} /> */}
        <TeamSizeUpdatedDialogue
          setShow={setWillShowDialogue}
          show={willShowDialogue}
          onConfirm={() => navigateTo && navigateTo("3")}
        />
        <PaymentMethodPastDueDialogue
          show={showUpdatePaymentDialog}
          setShow={setShowUpdatePaymentDialog}
          onConfirm={() => getCustomerSubscriptionPortalLink()}
          isLoading={isMSubcribeLinkGenerating}
        />
        <SomethingWentWrongDialogue
          show={showSomethingWWD}
          setShow={setshowSomethingWWD}
          content="Couldn't update users, please try again!"
        />
        <SomethingWentWrongDialogue
          show={showDeleteUsersToUpdateDialogue}
          setShow={setShowDeleteUsersToUpdateDialogue}
          header="Delete Users"
          content={`Please delete ${usersToDeleteToUpdate === 1 ? `${usersToDeleteToUpdate} user` : `${usersToDeleteToUpdate} users`} from your team in order to change your team size.`}
        />
        <SomethingWentWrongDialogue
          show={showUpgradeToTeamsDialogue}
          setShow={setShowUpgradeToTeamsDialogue}
          header="Upgrade To Teams!"
          content={`Please upgrade to Teams Subscription in order to change your team size.`}
        />
      </div>
    </>
  );
}
