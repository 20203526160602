import { Link } from "react-router-dom";
import communityEmptyState from "../../../assets/Images/CommunityEmptyState.svg"
import MagicIcon from "../../../assets/Images/MagicIcon.svg"

export default function EmptyTableCommunity() {
  return (
    <>
      <div className="xsm:h-[60vh] sm:h-[70vh] bg-white rounded-[10px] font-poppins md:my-6 my-3 flex flex-col justify-center items-center">
        <Link to={"https://www.skool.com/evyai/about"} target="_blank">
          <img
            className="cursor-pointer w-[500px] xsm:w-[90%] mx-auto mt-6"
            src={communityEmptyState}
          />
        </Link>
        <div className="flex flex-col gap-2 justify-center items-center">
          <h2 className="custom-h3-style text-center md:text-[24px] text-[18px] leading-[36px] mt-8">
            Welcome to the AI for LinkedIn community!
          </h2>
          <p className="custom-seconday-p-style md:text-[16px] text-[14px] text-center">
            Our goal is to bring people together who want to leverage AI and LinkedIn.
            Click on the button below to join our AI for LinkedIn community
          </p>
        </div>
        <Link to={"https://www.skool.com/evyai/about"} target="_blank" style={{ background: "linear-gradient(110.88deg, #2F3178 4.78%, #542E9C 83.75%)" }} className=" xsm:w-[90%] px-6 py-[10px] flex justify-center items-center gap-2 font-bold text-[16px] text-white rounded-[12px]">
          <img src={MagicIcon} />
          Discover our Community
        </Link>
      </div>
    </>
  );
}
