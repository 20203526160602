import deleteIcon from "../../../assets/Images/Trash.png";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useContext, useState } from "react";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { toastAlertSuccess } from "../../../utils";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { sentryClient } from "@/Sentry/sentry";

const ActionCard = ({ isLoading }: { isLoading: boolean }) => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { deleteTemplate } = useContext(AuthContext) as AuthContext_type;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteTemplate = async () => {
    try {
      setIsDeleting(true)
      if (templateId) {
        await deleteTemplate(templateId);
        setShowConfirmation(false);
        toastAlertSuccess("Deleted Successfully!");
        navigate(`/${PROTECTED_ROUTES.templates}`);
      }
    } catch (error) {
      sentryClient.captureException(error)

    } finally {
      setIsDeleting(false)
    }
  };

  return (
    <div className="xsm:hidden p-6 w-[40%] h-[270px] bg-white rounded-lg flex flex-col gap-y-4">
      <h3 className="font-poppins text-xl font-semibold text-[#292929]">
        Actions
      </h3>
      <button
        disabled={isLoading}
        type="submit"
        className="btn-primary mt-4 disabled:opacity-50"
      >
        {isLoading && <Loader color="fill-white" size={5} />}
        {templateId ? "Update Template" : "Save Template"}
      </button>
      {!templateId ? 
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="btn-secondary disabled:opacity-50 w-full"
        >
          Cancel
        </button>
        :
        <button
          onClick={() => setShowConfirmation(true)}
          disabled={!templateId}
          type="button"
          className="btn-secondary disabled:opacity-50 w-full"
        >
          <img src={deleteIcon} className="mr-[10px]" />
          Delete Template
        </button>
      }
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={handleDeleteTemplate}
        header="Delete Template"
        content="Are you sure you want to delete these Template?"
        isLoading={isDeleting}
      />
    </div>
  );
};

export default ActionCard;
