import { useState } from "react";

import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

import IdeaGeneratorIcon from "../../../assets/Images/IdeaGenerator.svg";
import resetimg from "../../../assets/Images/Reset.png";
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";

import { IGeneratedIdea } from "..";

type PropsType = {
  setSelectedPersona: React.Dispatch<React.SetStateAction<string>>;
  selectedPersona: string;
  fetchGenerateIdea: () => void;
  generateIdeaCommand: string;
  setGenerateIdeaCommand: React.Dispatch<React.SetStateAction<string>>;
  isGenerating: boolean;
  generatedIdeas: IGeneratedIdea[];
};

export default function IdeaGeneratorMobile(props: PropsType) {
  const {
    fetchGenerateIdea,
    setGenerateIdeaCommand,
    generateIdeaCommand,
    isGenerating,
    generatedIdeas,
  } = props;

  const [isExpand, setIsExpand] = useState(true);

  return (
    <div className="fixed bottom-0 w-full left-0 px-4 pb-4 z-10">
      <div className="bg-white border border-[#A1A2A9] relative z-10 rounded-xl">
        {isExpand && (
          <textarea
            rows={1}
            className="font-poppins text-sm text-gray_light  outline-none px-4 py-4 w-full rounded-tl-2xl rounded-tr-2xl"
            placeholder="Enter command to regenerate post ideas"
            value={generateIdeaCommand}
            onChange={(e) => setGenerateIdeaCommand(e.target.value)}
          />
        )}

        <div
          className={`relative border-r-0.5 border-b-0.5 border-l-0.5 border-[#A1A2A9] gap-4 rounded-bl-2xl rounded-br-2xl ${isExpand && " rounded-tl-2xl rounded-tr-2xl "
            }bg-[#F5F7F9] p-4 !pt-5 `}
        >
          <span className="absolute inset-x-0 mx-auto -top-2 h-5 w-5 rounded-full bg-[#F5F7F9] border-2 border-white">
            <img
              onClick={() => setIsExpand(!isExpand)}
              src={isExpand ? UpArrow : DownArrow}
            />
          </span>
          {generatedIdeas && generatedIdeas.length > 0 ? (
            <Button
              className="px-6 py-[10px] w-full bg-white rounded-[10px] border border-secondry_color  font-bold leading-normal text-secondry_color disabled:opacity-50"
              onClick={fetchGenerateIdea}
              disabled={isGenerating}
            >
              {isGenerating ? (
                <Loader size={4} />
              ) : (
                <img src={resetimg} alt="reset" className="mr-2" />
              )}
              <span>Regenerate</span>
            </Button>
          ) : (
            <Button
              onClick={fetchGenerateIdea}
              // className="px-6 py-[10px] flex items-center rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50 gap-[10px] hover:opacity-90 transition"

              disabled={generateIdeaCommand.length < 1 || isGenerating}
            >
              <img src={IdeaGeneratorIcon} alt="img" width={16} height={16} />
              <span>Generate Ideas</span> {isGenerating && <Loader size={4} />}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
