import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import LeftArrow from "../../assets/svgs/LeftArrow";
import AddPersonaForm from "./components/AddPersonaForm";
import useValidatePersona from "../../hooks/useValidatePersonas";
import Loader from "../../components/Loader";

const AddPersona = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    onSubmit,
    formControl,
    formIsValid,
    isLoading,
    formErrors,
    setProfilePictureUrl,
    profilePictureUrl,
    setSelectedTags,
    selectedTags,
    isLoadingPersona,
    isSubmitting,
  } = useValidatePersona();

  if (isLoadingPersona) {
    return (
      <div className="flex justify-center padding-[50px]">
        <Loader size={10} />{" "}
      </div>
    );
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex justify-start items-center gap-3">
          <p className="cursor-pointer font-poppins text-[#8B8C95] text-[16px] font-normal"
            onClick={() => navigate(`/${PROTECTED_ROUTES.Personas}`)}
          >
            Personas
          </p>
          <LeftArrow width={18} height={18} color={"#8B8C95"} />
          <p className="-ml-1 font-poppins text-[16px] font-bold">
            Add Persona
          </p>
        </div>
        {/* <button
          onClick={() => navigate(`/${PROTECTED_ROUTES.Personas}`)}
          className="items-center justify-start text-text_color  md:flex hidden"
        >
          <img className="-ml-6" src={BackArrow} alt="Back Arrow" />
          <span className="-ml-1 font-poppins text-[#444553] text-[16px] font-normal">
            Persona
          </span>
        </button> */}
        <AddPersonaForm
          formControl={formControl}
          formIsValid={formIsValid}
          isLoading={isLoading}
          formErrors={formErrors}
          setProfilePictureUrl={setProfilePictureUrl}
          profilePictureUrl={profilePictureUrl}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          isSubmitting={isSubmitting}
        />
      </form>
    </div>
  );
};

export default AddPersona;
