import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgetPasswordSchema } from '../schema';
import { confirmPasswordReset } from "firebase/auth";
import { useForm } from 'react-hook-form';
import {auth} from "../../../services/firebase"
import { useNavigate } from 'react-router-dom';
import { UNPROTECTED_ROUTES } from '../../../router/routes.constants';

function useResetPassword() {

  const navigate = useNavigate()
  const [oobCode, setOobCode] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    handleGetQueryParams();
  }, []);

  // oob code extract from url
  const handleGetQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);

    setOobCode(queryParams.get("oobCode"));
  };


  const {
    handleSubmit,
    control: formControl,
    getValues,
    formState: { errors: formErrors, isValid: formIsValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(forgetPasswordSchema),
  });


  const onSubmit = async () => {
    const formValues = getValues();
    if (!oobCode) {
      return;
    }
    setIsLoading(true);
    confirmPasswordReset(auth, oobCode, formValues.password)
      .then(() => {
        setIsLoading(false);
        return navigate(UNPROTECTED_ROUTES.passwordSuccess);
      })
      .catch(() => {
        setIsLoading(false);
      });

  };

  return {
    onSubmit,
    handleSubmit,
    formControl,
    formErrors,
    formIsValid,
    isLoading
  };
}

export default useResetPassword;