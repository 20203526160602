import WarningSvg from "../assets/Images/Warning.svg";
// import { useState } from "react";
// import UpgradeToPremiumDialogue from "./UpgradeToPremiumDialogue";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";

type PropsType = {
  title?: string;
  content?: string;
  buttonName?: string;
  subStatus?: number;
  feature?: string;
};

export default function FreeTrailEnd(props: PropsType) {
  const { title, content, subStatus, feature } = props;
  // const [showDialog, setShowDialog] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-[#FFF5F5] flex xsm:flex-col justify-between items-center gap-4 border-[2px] border-secondry_border_color xsm:p-4 p-6 rounded-xl xsm:rounded-2xl">
        {/* <div className="bg-[#FFF5F5] grid grid-cols-12 gap-4 border-[2px] border-secondry_border_color xsm:p-4 p-6 rounded-xl xsm:rounded-2xl"> */}
        <div className="col-span-12 md:col-span-8 lg:col-span-9 flex items-start gap-2 xsm:gap-3 font-poppins ">
          <img className="xsm:mb-2 xsm:w-5" src={WarningSvg} alt="Warning" width={26} height={26} />
          <div>
            <span className="xsm:font-bold xsm:text-[16px] text-[18px] leading-[27px] font-bold text-text_color">{title}</span>
            {/* <h1 className="xsm:flex xsm:flex-col xsm:items-start text-5xl font-poppins text-base font-bold text-text_color flex items-center">
            </h1> */}
            {subStatus === 1 && feature ?
              <p className="text-text_color text-[16px] xsm:text-[14px] xsm:leading-[21px] leading-[27px]">
                Please contact <span className="font-bold underline">hello@evyai.com</span> to ask for more {feature}.
              </p>
              :
              <p className="text-text_color text-[16px] xsm:text-[14px] xsm:leading-[21px] leading-[27px]">
                {content}
              </p>
            }

          </div>
        </div>
        {subStatus === 0 &&
          <div className="col-span-12 md:col-span-4 lg:col-span-3 xsm:w-full flex items-center justify-center xsm:justify-start">
            <button
              onClick={() => navigate(Navigate_to.subscriptionPricing)}
              className="bg-[#34317D] text-white xsm:w-full xsm:text-[14px] text-[15px] font-bold border border-secondry_color px-6 py-2 xsm:py-[10px] rounded-lg hover:opacity-90 transition"
            >
              Upgrade Subscription
            </button>
          </div>
        }
      </div>
      {/* <UpgradeToPremiumDialogue open={showDialog} setOpen={setShowDialog} /> */}
    </>
  );
}
