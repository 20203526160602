import CheckIcon from "../../../assets/svgs/CheckIcon";
import DailogBox from "../../../components/DailogBox";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  show: boolean;
  header?: string;
  content?: string;
  isLoading?: boolean;
}

const TeamSizeUpdatedDialogue = ({ 
    setShow, 
    show, 
    onConfirm, 
    }: IProps) => {

    const  handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        onConfirm()
        setShow(false)
    }

  return (
    <DailogBox
      dialogClass="!z-[100]"
      className="flex flex-col items-center justify-center !gap-0 !max-w-[450px]"
      open={show}
      onClose={handleClose}
      >
        <div className="md:w-[500px]">
            <div className="flex flex-col items-center gap-2">
                <CheckIcon />
            </div>
            <div className="mt-6 flex flex-col justify-between items-center">
                <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
                Team Size Updated
                </h2>
                <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
                Invite users to join your team on evyAI.
                </p>
            </div>
            <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
            <button
                type="button"
                className="w-full  md:max-w-[140px] px-6 py-3 flex-1 text-center text-[#34317D] font-bold rounded-[10px] border border-[#34317D]"
                onClick={() => handleClose()}
            >
                Cancel
            </button>
            <button 
                type="button"
                className=" md:max-w-[400px] xsm:w-full px-6 py-3 text-center disabled:!bg-[#C0C0C0] !text-white !bg-[#34317D] outline-none border ring-offset-2 flex justify-center rounded-[10px] font-bold "
                onClick={handleSubmit}
                >
                    Add Team Members
            </button>
            </div>
        </div>
    </DailogBox>
  );
};
export default TeamSizeUpdatedDialogue;
