import { useContext, useEffect, useState } from "react";
import EmptyStarIcon from "../assets/Images/blue-empty-star.svg";
import FillStarIcon from "../assets/Images/blue-star-fill.svg";
import TrashIcon from "../assets/Images/Trash.svg";
import CopyIcon from "../assets/Images/Copy.svg";
import { IActivity } from "../types/recentActivities.types";
import moment from "moment";
import { dateFormater, toastAlertError, toastAlertSuccess } from "../utils";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import {
  API_REQUEST_TYPE,
  RECENT_ACTIVITY_TYPE_TITLE,
} from "../config/appConfig";
import { AiOutlineLink } from "react-icons/ai";

import article_comment_reply from "../assets/Images/recent_article_comment_reply.png";
import article_comment from "../assets/Images/recent_article_comment.png";
import comment_reply from "../assets/Images/recent_comment_reply.png";
import create_post from "../assets/Images/recent_create_post.png";
import profile_optimizer from "../assets/Images/recent_create_post.png";
import invitation_note from "../assets/Images/recent_invitation_note.png";
import message_reply from "../assets/Images/recent_message_reply.png";
import post_comment from "../assets/Images/recent_post_comment.png";
import contributed_article_comment from "../assets/Images/recent_post_comment.png";
import profile_about from "../assets/Images/recent_profile_about.png";
import profile_headline from "../assets/Images/recent_profile_headline.png";
import profile_summary from "../assets/Images/recent_profile_about.png";
import recommendation_text from "../assets/Images/recent_recommendation_text.png";
import post_ideas from "../assets/Images/IdeaGenerator.png";
import post_idea from "../assets/Images/IdeaGenerator.png";
import { sentryClient } from "@/Sentry/sentry";


export const commandImages = {
  article_comment_reply,
  article_comment,
  comment_reply,
  create_post,
  invitation_note,
  message_reply,
  post_comment,
  profile_about,
  profile_headline,
  recommendation_text,
  profile_summary,
  profile_optimizer,
  contributed_article_comment,
  post_ideas,
  post_idea,
};

interface IProps {
  activity: IActivity;
  savedCommands: any;
  setSavedCommands: any;
  currentIndex: number;
  isRecent?: boolean;
  generatedText?: string;
  onDeleteClicked: () => void;
}

const ActivityCard = ({
  activity,
  savedCommands,
  setSavedCommands,
  isRecent,
  generatedText,
  onDeleteClicked,
}: IProps) => {
  const navigate = useNavigate();
  const { deleteCommandData, addCommandData } = useContext(
    AuthContext
  ) as AuthContext_type;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const javascriptDate = activity?.timestamp?.toDate() || new Date();

  const formattedDate = moment(javascriptDate).format("MM/DD/YYYY");

  useEffect(() => {
    setIsSaved(activity?.isSaved || false);
  }, [activity?.isSaved]);

  const copyTextToClipBoard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
      })
      .catch(() => { });
  };

  const unsaveCommand = async (commandDocId: string) => {
    try {
      setIsDeleting(true);
      const { docId, error } = await deleteCommandData(commandDocId);
      if (error === null && docId?.length > 0) {
        const tempSavedCommands = [...savedCommands];
        const indexToRemove = tempSavedCommands.findIndex(
          (command: any) => command.id === docId
        );
        if (indexToRemove > -1 && !isRecent) {
          tempSavedCommands[indexToRemove].isSaved = false;
          setSavedCommands([...tempSavedCommands]);
        }
        if (indexToRemove > -1) {
          toastAlertSuccess("Unsaved Successfully");
          setIsSaved(false);

        }
      }
      toastAlertSuccess("Unsaved Successfully");

    } catch (error) {
      toastAlertError("Something went wrong");
      sentryClient.captureException(error)
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSaveUnsave = (id: string) => {
    if (isSaved) {
      unsaveCommand(id);
    } else {
      saveCommand(id);
    }
  };

  const saveCommand = async (id: string) => {
    try {
      if (!generatedText) {
        return;
      }
      await addCommandData(
        activity.activityData || "",
        activity.activityType,
        generatedText,
        id
      );
      toastAlertSuccess("Saved Successfully");
      setIsSaved(true);

      const tempSavedCommands = [...savedCommands];
      const indexToRemove = tempSavedCommands.findIndex(
        (command: any) => command.id === id
      );
      if (indexToRemove > -1) {
        tempSavedCommands[indexToRemove].isSaved = true;
        setSavedCommands([...tempSavedCommands]);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <div
        onClick={() => {
          isRecent
            ? navigate(`/${PROTECTED_ROUTES.commandDetails}/${activity.id}`)
            : navigate(
              `/${PROTECTED_ROUTES.commandDetails}/${activity.id}?isSaved=true`
            );
        }}
        className="bg-white p-4 h-[250px] rounded-[16px] flex flex-col justify-between cursor-pointer"
      >
        <div className="overflow-hidden">
          <div className="flex justify-between items-center pb-2">
            {activity.activityType && (
              <div className="flex items-center">
                <div className="p-[5.5px] md:p-[6.4px] rounded-md bg-chip_bg ">
                  <img
                    className="h-[13px] w-[13px] md:h-[15px] md:w-[15px]"
                    src={commandImages[activity.activityType]}
                    alt=""
                  />
                </div>
                <h5 className="font-poppins text-[16px] text-sm text-[#444553] font-bold leading-normal ml-2">
                  {RECENT_ACTIVITY_TYPE_TITLE[activity.activityType]}
                </h5>
              </div>
            )}

            <img
              className="ml-4 cursor-pointer md:w-5 w-4"
              src={isSaved || !isRecent ? FillStarIcon : EmptyStarIcon}
              onClick={(event) => {
                event.stopPropagation();
                handleSaveUnsave(activity.id);
              }}
              alt="Copy"
            />
          </div>
          <p className="font-poppins md:text-[16px] text-sm text-[#444553] leading-normal cursor-pointer multiline-ellipsis">
            {generatedText}
          </p>
        </div>
        <div className="flex justify-between items-center pt-4">
          <p className="font-poppins md:text-[14px] text-xs font-bold  text-light_gray leading-normal">
            {formattedDate}
          </p>
          <div className="flex">
            {activity.activityData?.url && (
              <AiOutlineLink
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(activity.activityData?.url, "_blank");
                }}
                size={24}
                color="#34317D"
                className="cursor-pointer mr-4"
              />
            )}

            <div className="flex">
              {isDeleting ? (
                <Loader size={5} />
              ) : (
                <img
                  src={TrashIcon}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteClicked();
                  }}
                  className="cursor-pointer md:w-5 w-4"
                  alt="Trash"
                />
              )}
            </div>
            <img
              className="ml-4 cursor-pointer  md:w-5 w-4"
              src={CopyIcon}
              onClick={(event) => {
                event.stopPropagation();
                if (
                  activity.activityType === API_REQUEST_TYPE.PROFILE_SUMMARY
                ) {
                  copyTextToClipBoard(
                    (generatedText || "") +
                    "\n" +
                    (activity?.activityData?.linked_url || "") +
                    "\n" +
                    (dateFormater(activity?.timestamp.seconds) || "")
                  );
                } else {
                  copyTextToClipBoard(generatedText || "");
                }
              }}
              alt="Copy"
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default ActivityCard;
