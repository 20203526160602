import { useEffect, useState } from "react";
// import arrowRight from "../assets/Images/arrow-right.svg";
// import helperCenter from "../assets/Images/helpcenter.svg";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { Navigate_to } from "../router/routes.constants";
// import WarningDialogue from "../components/WarningDialogue";
// import { useContext } from "react";
// import { AuthContext, AuthContext_type } from "../context/Auth/context";
// import { toastAlertSuccess } from "../utils";
// import UpgradeToPremiumDialogue from "../components/UpgradeToPremiumDialogue";
// import AffiliatePoligon from "../components/AffiliatePoligon";
import Setting from "./Setting";
import Teams from "./Teams";
import SubscriptionPricing from "./subscription/Pricing";

interface MyAccountProps {
  page: string;
}

const MyAccount: React.FC<MyAccountProps> = (props) => {

  let { page } = props;
  const currentURL = window.location.href;

  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('team_id');
  const memberId = searchParams.get('member_id');

  // const [showDialog, setShowDeleteDialog] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [activePage, setActivePage] = useState(page);
  const [isSettingsPage, setIsSettingsPage] = useState(true);
  const [isSubscriptionPage, setIsSubscriptionPage] = useState(false);
  const [isTeamsPage, setIsTeamsPage] = useState(false);
  // const { deleteAccount } = useContext(AuthContext) as AuthContext_type;

  const navigate = useNavigate();

  useEffect(() => {
    if (currentURL.includes(`${PROTECTED_ROUTES.myAccount}`) || currentURL.includes(`${PROTECTED_ROUTES.settings}`)) {
      handleChangePage("1")
    } else if (currentURL.includes(`${PROTECTED_ROUTES.subscriptionPricing}`)) {
      handleChangePage("2")
    } else if (currentURL.includes(`${PROTECTED_ROUTES.teams}`)) {
      handleChangePage("3")
    }

  }, [currentURL])


  const handleChangePage = (page: string) => {
    switch (page) {
      case "1":
        setActivePage("1");
        setIsSettingsPage(true);
        navigate(`/${PROTECTED_ROUTES.settings}`)
        setIsSubscriptionPage(false);
        setIsTeamsPage(false);
        break;
      case "2":
        setActivePage("2");
        navigate(`/${PROTECTED_ROUTES.subscriptionPricing}`)
        setIsSettingsPage(false);
        setIsSubscriptionPage(true);
        setIsTeamsPage(false);
        break;
      case "3":
        setActivePage("3");
        if (!teamId || !memberId) {
          navigate(`/${PROTECTED_ROUTES.teams}`)
        }
        setIsSettingsPage(false);
        setIsSubscriptionPage(false);
        setIsTeamsPage(true);
        break;
      default:
        break;
    }
  }

  // const handleDelete = async () => {
  //   try {
  //     setIsDeleting(true);
  //     await deleteAccount();
  //     toastAlertSuccess("Deleted successfully");
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setIsDeleting(false);
  //   }
  // };

  useEffect(() => {
    handleChangePage(activePage);
  }, [])

  return (
    <>
      <div>
        <h2 className="font-poppins xsm:font-black xsm:text-[24px] md:text-[32px] text-20 font-bold md:font-extrabold leading-normal text-black_color">
          My Account
        </h2>
      </div>

      <div className="innerShadow font-poppins w-full box-border text-[#333333] mt-8 flex justify-start items-center gap-8 font-medium text-[18px]">
        <div onClick={() => handleChangePage("1")} className={`${isSettingsPage ? "roundedLine text-[#34317D] font-semibold" : "pb-4"} cursor-pointer hover:text-[#34317D] h-full`}>Settings</div>
        <div onClick={() => handleChangePage("2")} className={`${isSubscriptionPage ? "roundedLine text-[#34317D] font-semibold" : "pb-4"} cursor-pointer hover:text-[#34317D] h-full`}>Subscription</div>
        <div onClick={() => handleChangePage("3")} className={`${isTeamsPage ? "roundedLine text-[#34317D] font-semibold" : "pb-4"} cursor-pointer hover:text-[#34317D] h-full`}>Team</div>
      </div>

      {isSettingsPage && <Setting />}
      {isSubscriptionPage && <SubscriptionPricing navigateTo={handleChangePage} />}
      {isTeamsPage && <Teams navigateTo={handleChangePage} />}

      {/* <p className="xsm:hidden font-poppins text-[#333333] mt-4">
        Update your personal information, password, billing information, and
        manage your subscription.
      </p> */}

      {/* <div className="mt-3 mb-3 block md:hidden pr-3">
        <hr className="border-t border-[#ECECEE]" />
      </div>
      <div
        onClick={() => navigate(`/${PROTECTED_ROUTES.settings}`)}
        className="xsm:p-5 md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-8 flex justify-between items-center cursor-pointer"
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-black">
          Settings
        </h3>
        <a href="#">
          <img
            className="md:h-4 h-3 md:w-4 w-3"
            src={arrowRight}
            alt="Arrows"
          />
        </a>
      </div>
      <div className="xsm:hidden mt-4 mb-4 block md:hidden pr-3">
        <hr className="border-t border-[#ECECEE]" />
      </div>
      <Link
        to={Navigate_to.subscriptionPricing}
        className='xsm:p-5 md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-4 flex justify-between items-center cursor-pointer'
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-black">
          Manage Subscription
        </h3>

        <img className="md:h-4 h-3 md:w-4 w-3" src={arrowRight} alt="Arrows" />
      </Link>
      <Link
        to={Navigate_to.teams}
        className='xsm:p-5 md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-4 flex justify-between items-center cursor-pointer'
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-black">
          Teams
        </h3>

        <img className="md:h-4 h-3 md:w-4 w-3" src={arrowRight} alt="Arrows" />
      </Link>
      <div
        onClick={() => window.location.href = "https://evyai.com/contact-us/"}
        className="xsm:p-5 md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-4 flex justify-between items-center cursor-pointer"
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-black">
          Contact Us
        </h3>
        <img className="md:h-4 h-3 md:w-4 w-3" src={arrowRight} alt="Arrows" />
      </div> */}
      {/* <div className="xsm:hidden md:hidden block w-full bg-bg_white_color border border-border_color rounded-[10px] p-2 mt-5 cursor-pointer">
        <div className="flex items-center justify-between">
          <span className="font-poppins text-10 font-bold leading-normal text-text_color ml-2">
            Usage
          </span>
          <span className="font-poppins text-10 leading-normal text-text_color ml-2">
            80%
          </span>
        </div>
        <div className="w-full bg-[#DFDFDF] rounded-full mt-2 flex justify-between items-center">
          <div
            className="bg-[#FFD572] text-xs font-medium text-blue-100 text-right md:p-[6px] p-[5px] leading-none rounded-full cursor-pointer"
            style={{ width: "80%" }}
          ></div>
          
        </div>
        <p className="font-poppins text-text_color text-10 pt-2 pb-3 leading-normal">
          Your monthly usage plan is about to finish. Upgrade your plan to get
          access to our Premium Features
        </p>
      </div> */}
      {/* <a
        target="_blank"
        href="https://evyai.com/help"
        className="md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-4 flex justify-between items-center cursor-pointer"
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-black">
          Help Center
        </h3>
        <img
          className="md:h-4 h-3 md:w-4 w-3"
          src={helperCenter}
          alt="Arrows"
        />
      </a> */}


      {/* <div className="xsm:hidden mt-4 mb-4 block md:hidden pr-3">
        <hr className="border-t border-[#ECECEE]" />
      </div>
      <div
        onClick={() => {
          setShowDeleteDialog(true);
        }}
        className="xsm:p-5 md:py-6 py-2 md:px-8 rounded-[16px] bg-white mt-4 xsm:flex flex justify-between items-center cursor-pointer"
      >
        <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-[#EB5757]">
          Delete Account
        </h3>
        <a href="#">
          <img
            className="md:h-4 h-3 md:w-4 w-3"
            src={arrowRight}
            alt="Arrows"
          />
        </a>
      </div> */}


      {/* <div className="overflow-hidden mb-4 rounded-[16px] bg-white mt-8 xsm:py-4 xsm:flex xsm:flex-col xsm:items-start xsm:gap-1 md:flex md:flex-row md:justify-between md:items-center cursor-pointer bg-gradient-to-br from-[#6d40c4] to-[#6d40c4]">
        <div className="z-10 md:py-8 py-2 px-4 xsm:px-4 flex flex-col text-white gap-3">
          <div className="text-[25px] font-poppins font-bold">Become an evyAI Affiliate!</div>
          <div className="text-[18px] font-poppins font-thin leading-6">
            Want to spread the word about evyAI and earn commission while doing it?
            <p className="xsm:my-1 font-poppins font-bold">
              Register to become an affiliate and start earning today!
            </p>
          </div>

        </div>
        <div data-itemrole="button" className={`md:py-8 py-2 md:px-8 relative`}>
          <AffiliatePoligon className="xsm:right-[-230px] xsm:top-[-790px] xsm:w-[200px] absolute md:right-0 md:top-[-650px] md:w-[408px]" />
          <button onClick={
            // take the user to an external link via a tag so that it does not get blocked by the popup.
            () => window.open("https://evyai.com/affiliate", "_blank")


          } className="xsm:mx-5 xsm:px-10 xsm:py-4 md:py-4 md:px-[4rem] bg-[#FFD572] rounded-[10px] text-[#572fb3] font-poppins font-bold relative xsm:z-0">
            Start Now!
          </button>
        </div>
      </div>

      <div>
        <WarningDialogue
          setShow={setShowDeleteDialog}
          onConfirm={() => {
            handleDelete();
          }}
          isLoading={isDeleting}
          show={showDialog}
          content={"Are you sure you want to delete your account?"}
        />
        <UpgradeToPremiumDialogue
          open={openUpgradeDialog}
          setOpen={setOpenUpgradeDialog}
        />
      </div> */}
    </>
  );
};

export default MyAccount;
