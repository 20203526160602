export const isRegexExactMatch = (value: string, regexp: string) => {
  const res = value.match(regexp);
  return res && res[0] && res[0] === res.input;
};

export const isLinkedinProfileUrl = (value: string) => {
  const linkedInProfileURLRegExp =
    '(((https?:\\/\\/)?(www.)?)?linkedin.com\\/in\\/[a-zA-Z0-9_.-]+\\/?)';
  return !!isRegexExactMatch(value.trim(), linkedInProfileURLRegExp);

  // for now marking the linkedin profile url as valid
  return true;
};


export const isTwitterProfileUrl = (value: string) => {
  const linkedInProfileURLRegExp =
    '(((https?:\\/\\/)?(www.)?)?twitter.com\\/(?!home|messages|notification|explore)[a-zA-Z0-9_.-]+\\/?)';
  return !!isRegexExactMatch(value.trim(), linkedInProfileURLRegExp);
};
export const isValidPhoneNumber = (value: string) => {
  const validPhoneNumberRegExp = /^([+]\d{2})?\d{10}/;
  if (value.match(validPhoneNumberRegExp)) {
    // return true;
  }
  // return false;

  // for now marking the phone number as valid
  return true;
};
