import { useEffect, useState } from "react";

import DailogBox from "../../../components/DailogBox";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";
import Loader from "../../../components/Loader";
import PlusIcon from "../../../assets/Images/PlusIconBlue.svg"
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";

interface IProps {
  openDialog: boolean;
  title: string;
  items: any[];
  handleClose: () => void;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  selectedItem: string;
  isLoading?: boolean;
}

export const SelectDialogue = ({
  openDialog,
  title,
  items,
  selectedItem,
  setSelectedItem,
  handleClose,
  isLoading,
}: IProps) => {
  const [selItem, setSelItem] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setSelItem(selectedItem);
  }, [selectedItem]);

  return (
    <DailogBox
      className="!gap-6 font-poppins"
      dialogClass="!z-[100]"
      dialogBoxClass="!max-w-[464px] w-full !p-6"
      open={openDialog}
      onClose={handleClose}
    >
      <h2 className="text-[28px] font-black leading-8 text-text_secondary mt-4">
        {title === "Template" && "Select "}{title}
      </h2>
      {isLoading ? 
        <div className=" w-full flex justify-center items-center py-8">
          <Loader size={10} /> 
        </div>
        :
        <div className="text-black_color text-sm font-normal font-poppins max-h-[65vh] overflow-y-auto">
          <span
            className="flex  cursor-pointer"
            onClick={() => {
              setSelItem("");
            }}
          >
            <img src={selItem === "" ? checkImage : unCheckImage} />
            <span className="ml-2">None</span>
          </span>
          {title === "Template" &&
            <hr className="h-[1px] my-3 mx-auto bg-[#DFDFDF] w-[95%]" />
          }
          {items.map((item, index) => (
            <>
              <span
                key={index}
                className="flex items-start mt-3 cursor-pointer"
                onClick={() => {
                  if (title === "Persona" || title === "Template") {
                    setSelItem(item.id);
                  } else {
                    setSelItem(item);
                  }
                }}
                >
                {title === "Persona" || title === "Template" ?
                  <div className="flex items-start overflow-x-hidden">
                    <img src={selItem === item.id ? checkImage : unCheckImage} />
                    <div className="flex flex-col gap-2">
                      <span className={`ml-2 text-left ${title === "Template" && "text-[14px] font-bold"}`}>{item?.personaName || item?.templateName}</span>
                      {title === "Template" && 
                        <p 
                          className="w-full text-left ml-2 text-[12px]" 
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            overflow: "hidden"
                          }}
                          >
                            {item?.templateContent}
                        </p>
                        // <span className="ml-2 text-left">{item?.templateContent}</span>
                      }
                    </div>

                  </div> :
                  <>
                    <img src={selItem === item ? checkImage : unCheckImage} />
                    <span className="ml-2">{item}</span>
                  </>}
              </span>
              {title === "Template" &&
                index !== items.length - 1 &&
                <hr className="h-[1px] my-3 mx-auto bg-[#DFDFDF] w-[95%]" />
              }
            </>
          ))}
        </div>
      }
      <span onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}`)} className="cursor-pointer font-poppins w-full flex justify-start items-center gap-2">
        <img src={PlusIcon} width={14} />
        <h2 className="font-bold underline text-[#34317D]">Add Template</h2>
      </span>
      <div className="flex justify-end mt-2">
        <button
          className="font-poppins font-bold px-6 py-[10px] text-sm text-secondry_color rounded-[10px] border border-purple-900 leading-normal"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-secondry_color text-white text-sm font-bold border px-6 py-[10px] rounded-[10px] leading-normal ml-2"
          onClick={() => {
            setSelectedItem(selItem);
            handleClose();
          }}
        >
          {title === "Template" ? "Use Template" : "Apply"}
        </button>
      </div>
    </DailogBox>
  );
};
