import Header from "../components/Header";
import LinkBtn from "../components/LinkBtn";
import { BsExclamation } from "react-icons/bs";
import vectorError from "../assets/vectorError.svg";
import { HOME_PAGE } from "../constant";

const EmailVerificationFail = () => {
  // const { state } = useLocation();
  // const [email, setEmail] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  //  useEffect(() => {
  // handleEmailVerification();
  //}, []);

  // const handleEmailVerification = async () => {
  //   const oobCode = state?.oobCode;

  //   if (!oobCode) {
  //     return;
  //   }

  //   try {
  //     let abc = await applyActionCode(auth, oobCode);
  //     console.log({
  //       abc,
  //     });

  //     // Extract user email from the actionCodeInfo object
  //     // const actionCodeInfo = await checkActionCode(auth, oobCode);
  //     // const userEmail = actionCodeInfo?.data?.email;

  //     console.log({ sdfasfas: userEmail });

  //     setEmail(userEmail);
  //     console.log("User email:", email);

  //     // Continue with email verification process
  //   } catch (error) {
  //     console.error("Error verifying email", error);
  //   }
  // };

  // const handleTryAgain = () => {
  //   if (!state?.oobCode) {
  //     return;
  //   }

  //   setIsLoading(true);
  // };

  return (
    <div className="h-screen container mx-auto">
      <Header />
      <div className="h-[calc(100vh-93px)] py-12 md:items-center justify-center flex mx-auto">
        <div className="w-full max-sm:px-4 max-md:pt-20 md:pb-40">
          <div className="relative flex justify-center py-8">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <BsExclamation className="text-7xl text-white" />
            </div>
            <img
              src={vectorError}
              className="logo text-slate-500"
              alt="Vite logo"
            />
          </div>
          <h2 className='text-text_color text-5xl max-sm:text-2xl text-center font-semibold font-lora'>
            Try verifying your email again
          </h2>
          <h4 className="text-xl text-center text-text_color mt-5">
            You request to verify your email has expired or the link has already
            been used
          </h4>
          <div className="mt-12 justify-center flex">
            <LinkBtn className="btn-custom" link={HOME_PAGE} title="Go to the Homepage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationFail;
