import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";
import ThunderSvg from "../assets/Images/Thunder.svg";
import SubscriptionBadgeImage from "../assets/Images/subscriptionbadge.png";
import SubscriptionTeamsBadgeImage from "../assets/Images/subscription_teams_badge.svg";
import SubscriptionAffilateBadgeImage from "../assets/Images/subscription_affilate_banner-badge.svg";
import CancelIcon from "../assets/svgs/Cancel";
import { AuthContext_type, AuthContext } from "@/context/Auth/context";
import { STORAGE_KEYS } from "@/config/appConfig";

interface ISUBSCRIPTION_TYPE {
 title: string;
 description: ReactNode;
 icon: string;
 buttonLabel: string;
 showButtonIcon: boolean;
 to: string;
 target: string;
 onClick?: Function;
}

const SubscriptionBanner = () => {
 const [isVisible, setIsVisible] = useState(true);
 const { subscriptionStatus, setShowModalType } = useContext(AuthContext) as AuthContext_type;
 const [subscriptionData, setSubscriptionData] = useState<ISUBSCRIPTION_TYPE | undefined>(undefined);
 const [step, setStep] = useState(0);

 const affiliateBanner = {
  title: "Become an evyAI Affiliate!",
  description: (
   <span>
    Want to spread the word about evyAI and earn a commission while doing it?
    <br />
    <strong>Register to become an affiliate and start earning today!</strong>
   </span>
  ),
  icon: SubscriptionAffilateBadgeImage,
  buttonLabel: "Start now",
  showButtonIcon: false,
  to: Navigate_to.subscriptionAffilate,
  target: "_blank",
 };
 const SUBSCRIPTION_TYPES = useRef<Record<string, ISUBSCRIPTION_TYPE[]>>({
  FREE: [
   {
    title: "Discover the evyAI advantage!",
    description: (
     <span>
      Upgrade to Premium for GPT-4 access, save unlimited LinkedIn profiles, <br /> infinite content creation, and exclusive Learning Hub benefits!"
     </span>
    ),
    icon: SubscriptionBadgeImage,
    buttonLabel: "Upgrade Subscription",
    showButtonIcon: true,
    to: Navigate_to.subscriptionPricing,
    target: "",
   },
  ],
  PREMIUM: [
   {
    title: "Upgrade to Teams!",
    description: <span>Upgrade to Teams with evyAI to give your entire team access to advanced features while saving on subscription costs.</span>,
    icon: SubscriptionTeamsBadgeImage,
    buttonLabel: "Upgrade  to Teams",
    showButtonIcon: false,
    to: Navigate_to.subscriptionPricing,
    target: "",
    onClick: () =>
     setShowModalType((prev) => ({
      ...prev,
      directUpgradeToTeams: true,
     })),
   },
   affiliateBanner,
  ],
  TEAMS_PREMIUM: [affiliateBanner],
 });

 console.log(subscriptionStatus);

 useEffect(() => {
  const bannerShown = localStorage.getItem(STORAGE_KEYS.SUBSCRIPTION_BANNER_SHOWN);
  if (bannerShown) {
   setIsVisible(false);
  }
 }, []);

 // useEffect(() => {
 //   if (!isVisible) {
 //     localStorage.setItem(STORAGE_KEYS.SUBSCRIPTION_BANNER_SHOWN, "true");
 //   }
 // }, [isVisible]);

 function getNewBanner(newStep: number) {
  if (subscriptionStatus && subscriptionStatus.subscription) {
   const subArray = SUBSCRIPTION_TYPES.current[subscriptionStatus.subscription!];
   const newStepArr = subArray[newStep]

   if (subArray && newStepArr) {
    return subArray[newStep];
   }
   localStorage.setItem(STORAGE_KEYS.SUBSCRIPTION_BANNER_SHOWN, "true");
   return null;
  }
  return null;
 }

 useEffect(() => {
  if (subscriptionStatus) {
   const newBanner = getNewBanner(step);
   if (newBanner) {
    setSubscriptionData(newBanner);
   } else if (subscriptionStatus.subscription) {
    setIsVisible(false);
   }
  }
 }, [subscriptionStatus, step]);

 function handleCancel() {
  setStep(step + 1);
 }

 if (!isVisible || !subscriptionData) return null;

 return (
  <section className='subscription-box'>
   <div
    style={{ backgroundColor: "#6D40C4" }}
    className='flex items-center px-4 py-4 md:py-6 md:px-8 rounded-[16px] bg-cover bg-center mt-6 md:mt-8 relative'
   >
    <button
     className='absolute top-4 right-4 md:top-6 md:right-6'
     onClick={handleCancel}
    >
     <CancelIcon />
    </button>
    <img
     src={subscriptionData.icon}
     alt='Subscription Badge'
     className='mr-4'
    />
    <div className=' flex flex-col justify-center flex-grow'>
     <h3 className='font-poppins text-base md:text-[20px] font-bold leading-normal text-white'>{subscriptionData.title}</h3>
     <p className='font-poppins text-sm md:text-base mt-2 leading-normal text-white'>{subscriptionData.description}</p>
    </div>
    <Link
     to={subscriptionData.to}
     target={subscriptionData.target}
     onClick={() => {
      if (subscriptionData.onClick) subscriptionData.onClick();
     }}
     className='xsm:hidden font-poppins font-bold text-[#34317D] text-[14px] py-2 px-3 bg-[#FFD572] rounded-[32px] leading-normal flex justify-center items-center gap-1 whitespace-nowrap'
    >
     {subscriptionData.showButtonIcon && (
      <img
       src={ThunderSvg}
       alt='Thunder'
       className='mr-2'
      />
     )}
     <h3 className='font-bold text-base'>{subscriptionData.buttonLabel}</h3>
    </Link>
   </div>
  </section>
 );
};

export default SubscriptionBanner;
