import { ReactNode } from "react";
// import { GrFormClose } from "react-icons/gr";
import CloseIcon from "../assets/svgs/CloseIcon";
interface DialogProps {
  open: boolean;
  className?: string;
  onClose: () => void;
  children: ReactNode;
  dialogClass?: string;
  dialogBoxClass?: string;
}
function DailogBox({
  open,
  onClose,
  className,
  children,
  dialogClass,
  dialogBoxClass,
}: DialogProps) {
  if (!open) return null;

  return (
    <div className={`xsm:mx-4 fixed inset-0 z-[100] overflow-y-auto ${dialogClass ? dialogClass : ""}`}>
      <div
        className="fixed inset-0 w-full h-full bg-[#34317D] opacity-[0.8]"
        onClick={() => onClose()}
      ></div>
      <div className="flex items-center min-h-screen">
        <div
          className={`relative mx-auto bg-white rounded-[16px] shadow-lg p-10 ${dialogBoxClass ? dialogBoxClass : ""
            }`}
        >
          <button
            type="button"
            className="absolute flex items-center justify-center rounded-full top-2 right-2 md:top-[16px] md:right-[16px]"
            data-te-modal-dismiss
            aria-label="Close"
          >
            <span onClick={() => onClose()} className="rounded-full p-2 cursor-pointer">
              <CloseIcon 
                width={15} 
                color="#34317D" 
              />
            </span>
            {/* <GrFormClose
              onClick={() => onClose()}
              size={10}
              className="w-10 h-10 font-bold text-red-600 rounded-full p-1 cursor-pointer"
            /> */}
          </button>
          <div
            className={`flex justify-center text-center flex-col gap-4 ${className ? className : ""
              }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailogBox;
