import { Navigate, createBrowserRouter } from 'react-router-dom';

import {
  AUTH_ROUTES,
  PROTECTED_ROUTES,
  UNPROTECTED_ROUTES,
} from './routes.constants';
import AuthLayout from '../layouts/Auth';
import Login from '../pages/Auth/Login';
import CreateProfile from '../pages/Auth/CreateProfile';
import Register from '../pages/Auth/Register';
import LayoutMain from '../layouts';
import ProtectedRoutes from '../layouts/Protected';
import NotFound from '../pages/NotFound';
import WelcomePage from '../pages/WelcomePage';
import ResetPassword from '../pages/ResetPassword';
import EmailVerified from '../pages/EmailVerified';
import ForgetPassword from '../pages/ForgetPassword';
import PasswordSuccess from '../pages/PasswordSuccess';
import EmailVerification from '../pages/EmailVerification';
import EmailVerificationFail from '../pages/EmailVerificationFail';
import LearningHub from '../pages/LearningHub/index.tsx';
// import Setting from '../pages/Setting';
import UpgradePage from '../pages/UpgradePage';
// import SubscriptionPricing from '../pages/subscription/Pricing';
import Dashboard from '../pages/Dashboard';
import CommandDetails from '../pages/CommandDetails';
import RecentActivities from '../pages/RecentActivities';
import MyAccount from '../pages/MyAccount';
import EmailVerificationPending from '../pages/EmailVerificationPending';
import ChangeEmail from '../pages/ChangeEmail';
import SavedProfile from '../pages/SavedProfile/index.tsx';
import PostCreate from '../pages/CreatePost';
import ProfileOptimizer from '../pages/ProfileOptimizer';
import Personas from '../pages/Persona';
import AddPersona from '../pages/AddPersona/AddPerson';
import ManageLists from '../pages/ManageLists/index.tsx';
import Logout from '../pages/Auth/Logout/index.tsx';
import IdeaGenerator from '../pages/IdeaGenerator/index.tsx';
import Templates from '../pages/templates/index.tsx';
import AddTemplate from '../pages/AddTemplate/AddTemplate.tsx';
import ManageTags from '../pages/ManageTags/index.tsx';
import Community from '../pages/Community/index.tsx';
import Demo from '../pages/demo/index.tsx';
import ForceLogin from '../pages/ForceLogin/index.tsx';
import MetaDataComponent from '@/utils/DynamicMetaData.tsx';
import ErrorView from '@/components/ErrorView.tsx';

// import Teams from '../pages/Teams/index.tsx';

const RouterMain = createBrowserRouter([
  {
    element:
      <>
        <MetaDataComponent />
        <LayoutMain />
      </>
    ,
    errorElement:<ErrorView />,
    children: [
      {
        path: '/',
        element: <Navigate to={AUTH_ROUTES.login} replace />,
      },
      {
        path: UNPROTECTED_ROUTES.welcome,
        element: <WelcomePage />,
      },
      {
        path: UNPROTECTED_ROUTES.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: UNPROTECTED_ROUTES.emailVerified,
        element: <EmailVerified />,
      },
      {
        path: UNPROTECTED_ROUTES.forgetPassword,
        element: <ForgetPassword />,
      },
      {
        path: UNPROTECTED_ROUTES.passwordSuccess,
        element: <PasswordSuccess />,
      },
      {
        path: UNPROTECTED_ROUTES.emailVerificationPending,
        element: <EmailVerificationPending />,
      },
      {
        path: UNPROTECTED_ROUTES.emailVerification,
        element: <EmailVerification />,
      },
      {
        path: UNPROTECTED_ROUTES.emailVerificationFail,
        element: <EmailVerificationFail />,
      },
      {
        path: UNPROTECTED_ROUTES.upgrade,
        element: <UpgradePage />,
      },
      {
        path: UNPROTECTED_ROUTES.changeEmail,
        element: <ChangeEmail />,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: AUTH_ROUTES.login,
            element: <Login />,
          },
          {
            path: AUTH_ROUTES.register,
            element: <Register />,
          },
          {
            path: AUTH_ROUTES.createProfile,
            element: <CreateProfile />,
          },
        ],
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: PROTECTED_ROUTES.home,
            element: <Dashboard />,
          },
          {
            path: PROTECTED_ROUTES.settings,
            element: <MyAccount page="1" />,
          },
          {
            path: `${PROTECTED_ROUTES.commandDetails}/:id`,
            element: <CommandDetails />,
          },
          {
            path: PROTECTED_ROUTES.savedProfile,
            element: <SavedProfile />,
          },
          {
            path: PROTECTED_ROUTES.ideaGenerator,
            element: <IdeaGenerator />,
          },
          {
            path: PROTECTED_ROUTES.manageLists,
            element: <ManageLists />,
          },
          {
            path: PROTECTED_ROUTES.manageTags,
            element: <ManageTags />,
          },
          {
            path: PROTECTED_ROUTES.createPost,
            element: <PostCreate />,
          },
          {
            path: PROTECTED_ROUTES.profileOptimizer,
            element: <ProfileOptimizer />,
          },

          {
            path: PROTECTED_ROUTES.templates,
            element: <Templates />,
          },
          {
            path: PROTECTED_ROUTES.Personas,
            element: <Personas />,
          },
          {
            path: PROTECTED_ROUTES.learningHub,
            element: <LearningHub />,
          },
          {
            path: PROTECTED_ROUTES.community,
            element: <Community />,
          },
          {
            path: PROTECTED_ROUTES.demo,
            element: <Demo />,
          },
          {
            path: PROTECTED_ROUTES.recentActivities,
            element: <RecentActivities />,
          },
          {
            path: PROTECTED_ROUTES.myAccount,
            element: <MyAccount page="1" />,
          },
          {
            path: PROTECTED_ROUTES.subscriptionPricing,
            element: <MyAccount page="2" />,
          },
          {
            path: PROTECTED_ROUTES.teams,
            element: <MyAccount page="3" />,
          },
          {
            path: `${PROTECTED_ROUTES.acceptInvite}/:team_id?/:member_id?`,
            element: <MyAccount page="3" />,
          },
          {
            path: PROTECTED_ROUTES.addTemplate,
            element: <AddTemplate />,
          },
          {
            path: `${PROTECTED_ROUTES.addTemplate}/:templateId`,
            element: <AddTemplate />,
          },
          {
            path: PROTECTED_ROUTES.addPersona,
            element: <AddPersona />,
          },
          {
            path: `${PROTECTED_ROUTES.addPersona}/:personaId`,
            element: <AddPersona />,
          },
          {
            path: PROTECTED_ROUTES.forceLogin,
            element: <ForceLogin />,
          },
        ],
      },
      {
        path: AUTH_ROUTES.logout,
        element: <Logout />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to={AUTH_ROUTES.login} replace />,
  },
]);

export default RouterMain;
