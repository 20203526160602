import DailogBox from "../components/DailogBox";
import CheckIconCopy from "../assets/svgs/CheckIconCopy";
import { useContext } from "react";
import { AuthContext, AuthContext_type } from "../context/Auth/context";

interface IProps {
  openDialog: boolean;
  welcomeSteps: any;
  setWelcomeSteps: React.Dispatch<React.SetStateAction<any>>;
  onClose: () => void;
  onStarted: () => void;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WelcomeDialogue = ({
  openDialog,
  setOpenDialog,
  onClose,
  setWelcomeSteps,
  onStarted,
}: IProps) => {
  const { user, updateWelcomeTourStatus, User } = useContext(
    AuthContext
  ) as AuthContext_type;

  const fName = user?.first_name;
  const lName = user?.last_name || null;

  return (
    <DailogBox
      className="flex flex-col items-center justify-center !max-w-[464px]"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <CheckIconCopy />
      <div className="mt-6 mb-8 flex flex-col items-center gap-2">
        <h2 className="font-bold text-[#333333] text-[32px] font-poppins">
          {fName + `${lName ? ` ${lName}` : ""}`}
        </h2>
        <p className="text-base font-poppins">
          I'm Evy, your virtual guide on this exciting journey of endless
          possibilities. Let's embark on a creative adventure together!
        </p>
      </div>
      <div className="md:flex justify-end items-center hidden">
        <button
          onClick={() => {
            onClose();
            if (User?.uid) {
              updateWelcomeTourStatus(User.uid);
            }
          }}
          className="px-6 py-[10px] rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal text-secondry_color"
        >
          Skip Tour
        </button>
        <button
          onClick={() => {
            setWelcomeSteps(0);
            onStarted();
            onClose();
          }}
          className="px-6 py-[10px] flex items-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white ml-6 disabled:opacity-50"
        >
          Let’s get started
        </button>
      </div>
    </DailogBox>
  );
};
