import whiteLogo from '../assets/Images/logo-white.png'
import step1 from '../assets/Images/landing_step1.png'
import { BsX } from "react-icons/bs";
import { MdOutlineExtension } from "react-icons/md";
import { useEffect, useState } from 'react';

export default function InstallationPopup({showPopup}:{showPopup:boolean}) {

  const [show,setShow] = useState<boolean>(false)

  useEffect(()=>{
    setShow(showPopup)
  }, [showPopup])

  const handleClose = () => {
    setShow(false)
  }

  return (
    show &&
    <div className="absolute top-4 right-4 bg-white w-[265px] height-[200px] shadow-lg rounded-[10px]">
      <div className="relative flex flex-col justify-center items-center rounded-[10px] gap-[15px] mb-4">
        <div className="cursor-pointer absolute top-[-10px] right-0 w-[25px] h-[25px] bg-[#34317D] rounded-[50%] text-white flex justify-center items-center" onClick={handleClose}><span className="bg-[#FFFFFF1A] m-2 w-[15px] h-[15px]"><BsX /></span></div>
        <div className="px-4 py-3 bg-primary_color w-full rounded-t-[10px]">
          <img src={whiteLogo} alt="envyAI chrome extenion instalation logo" className="w-[60px]" />
        </div>
        <div className="px-6 text-center flex flex-col justify-between items-center gap-[15px]">
          <div className="text text-center font-bold">
            <span className="text-primary_color" >Pin evyAI</span>
            <br />
            to access later
          </div>
          <p className="text-slate-600">To quickly access evyAI, click
  the  <span className="inline-block"><MdOutlineExtension /></span> icon above to pin evyAI</p>
        <img src={step1} alt="landing page step 1" />
        <button className='btn ghost border-[2px] border-[#34317D] rounded-[5px] w-full !py-2 text-[18px]' onClick={handleClose}>Dismiss</button>
        </div>
      </div>
    </div>
  );
}
