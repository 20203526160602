function Community({ height = 20, width = 20, color = "#6D40C4" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 20 20'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M6.9697 3.33332C5.24032 3.33332 3.83838 4.73526 3.83838 6.46463C3.83838 8.19401 5.24032 9.59594 6.9697 9.59594C8.69907 9.59594 10.101 8.19401 10.101 6.46463C10.101 4.73526 8.69907 3.33332 6.9697 3.33332ZM2.0202 6.46463C2.0202 3.7311 4.23617 1.51514 6.9697 1.51514C9.70323 1.51514 11.9192 3.7311 11.9192 6.46463C11.9192 9.19816 9.70323 11.4141 6.9697 11.4141C4.23617 11.4141 2.0202 9.19816 2.0202 6.46463ZM12.1212 2.42423C12.1212 1.92215 12.5282 1.51514 13.0303 1.51514C15.7638 1.51514 17.9798 3.7311 17.9798 6.46463C17.9798 9.19816 15.7638 11.4141 13.0303 11.4141C12.5282 11.4141 12.1212 11.0071 12.1212 10.505C12.1212 10.003 12.5282 9.59594 13.0303 9.59594C14.7597 9.59594 16.1616 8.19401 16.1616 6.46463C16.1616 4.73526 14.7597 3.33332 13.0303 3.33332C12.5282 3.33332 12.1212 2.9263 12.1212 2.42423ZM2.17993 13.9623C3.44557 13.1186 5.14166 12.6262 6.9697 12.6262C8.79773 12.6262 10.4938 13.1186 11.7595 13.9623C13.0188 14.8019 13.9394 16.0615 13.9394 17.5757C13.9394 18.0778 13.5324 18.4848 13.0303 18.4848C12.5282 18.4848 12.1212 18.0778 12.1212 17.5757C12.1212 16.8585 11.6851 16.098 10.7509 15.4752C9.82305 14.8566 8.48884 14.4444 6.9697 14.4444C5.45055 14.4444 4.11634 14.8566 3.18847 15.4752C2.25425 16.098 1.81818 16.8585 1.81818 17.5757C1.81818 18.0778 1.41117 18.4848 0.909091 18.4848C0.407014 18.4848 0 18.0778 0 17.5757C0 16.0615 0.920644 14.8019 2.17993 13.9623ZM14.1656 13.5567C14.2808 13.068 14.7703 12.7652 15.259 12.8803C17.7706 13.4721 20 15.181 20 17.5757C20 18.0777 19.593 18.4848 19.0909 18.4848C18.5888 18.4848 18.1818 18.0777 18.1818 17.5757C18.1818 16.4519 17.0383 15.1675 14.842 14.65C14.3533 14.5349 14.0505 14.0454 14.1656 13.5567Z'
    fill={color}
   />
  </svg>
 );
}
export default Community;
