import _ from 'lodash';
import { ComponentPropsWithoutRef } from 'react';
import { ThreeDots } from 'react-loader-spinner';

type PropsType = {
  isLoading?: boolean;
} & ComponentPropsWithoutRef<'button'>;

export default function Button(props: PropsType) {
  const { children, isLoading, className, ...rest } = props;

  return (
    <button
      className={`rounded-lg bg-[#ffd572] text-center flex justify-center items-center text-xs ${className
        } py-2 px-5 font-bold`}
      {...rest}
    >
      {!isLoading ? (
        children
      ) : (
        <ThreeDots
          height={16}
          width={30}
          color='#ababab'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='three-dots-loading'
        />
      )}
    </button>
  );
}
