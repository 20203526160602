import { sentryClient } from '@/Sentry/sentry';
import { ToastPosition, toast } from 'react-toastify';

export const toastAlertError = (title: string) => {
  return toast.error(title, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};

export const toastAlertSuccess = (title: string, position?:ToastPosition) => {
  return toast.success(title, {
    position: position ? position : 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};

export const copyTextToClipBoard = (url: string) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastAlertSuccess("Text copied to clipboard");
    })
    .catch((error) => {
      console.error({ error });
      sentryClient.captureException(error)
    });
};

export function dateFormater(timestamp: number): string {
  // june 31, 2023
  if (!timestamp) return '';

  const date = new Date(timestamp * 1000);

  const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

export const dateFormat = (timestamp: number): string => {
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
};

export const openInNewTab = (url: string) => {
  let newTab:any = window.open();
 newTab.location.href = url;
};

export const openInSameTab = (url: string) => {
  window.location.href = url;
};

export function formatLinkedInUrl(url: string) {
  const parsedUrl = new URL(url);
  return `${parsedUrl.origin}${parsedUrl.pathname}`;
}


export function updateQueryParams(newListIds: string[], currentLocation:any){
  let queryParams = new URLSearchParams(currentLocation.search);

  if (newListIds.length <= 0) {
    queryParams.delete('list');
  } else {
    const updatedListIds = Array.from(new Set(newListIds));

    if (updatedListIds.length > 0) {
      queryParams.set('list', updatedListIds.join(','));
    } else {
      queryParams.delete('list');
    }
  }
  const queryString = Array.from(queryParams.entries()).map(([key, value]) => `${key}=${value}`).join('&');
  const newUrl = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
  window.history.pushState({}, '', newUrl);
};
