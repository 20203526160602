import { ReactNode } from "react";
import { GrFormClose } from "react-icons/gr";

interface DialogProps {
  open: boolean;
  className?: string;
  onClose: () => void;
  children: ReactNode;
  welcomeSteps: number | null;
  rightMarginToSet: number | null;
}

function WelcomeTourDialogBox({
  open,
  onClose,
  className,
  children,
  welcomeSteps,
  rightMarginToSet,
}: DialogProps) {
  if (!open) return null;

  let topValue = "top-28";
  let zIndex
  let leftIn = "left-in"

  switch (welcomeSteps) {
    case 0:
      topValue = "top-5 !left-[unset] !right-10";
      zIndex = "z-10"
      break;
    case 1:
      topValue = "top-24";
      zIndex = "z-10"
      break;
    case 2:
      topValue = "top-[9rem]";
      zIndex = "z-10"
      break;
    case 3:
      topValue = "top-[15rem]";
      zIndex = "z-10"
      break;
    case 4:
      topValue = "top-[18.3rem]";
      zIndex = "z-10"
      break;
    case 5:
      topValue = "top-[24.5rem]";
      zIndex = "z-10"
      break;
    case 6:
      topValue = "top-[27.7rem]";
      zIndex = "z-10"
      break;
    case 7:
      topValue = "top-[30.8rem]";
      zIndex = "z-10"
      break;
    case 8:
      topValue = "top-[33.5rem]";
      zIndex = "z-10"
      break;
    case 9:
      topValue = "top-[35.8rem]";
      zIndex = "z-10"
      leftIn = "left-in2"
      break;
    case 10:
      topValue = "top-[39rem]";
      zIndex = "z-10"
      leftIn = "left-in2"
      break;
    case 11:
      topValue = "top-[42.1rem]";
      zIndex = "z-10"
      leftIn = "left-in2"
      break;
    case 12:
      // topValue = "top-[35rem]";
      // zIndex = "z-10"
      // leftIn = "left-in2"
      topValue = `top-[5rem] !left-[unset]`;
      zIndex = "z-[10]"
      break;
    case 13:
      topValue = "top-5 !left-[unset] !right-10";
      zIndex = "z-10"
      break;
    default:
      break;
  }

  return (
    <div className={`fixed inset-0 overflow-y-auto ${zIndex}`}>
      <div
        className="fixed inset-0 w-full h-full bg-[#34317D] opacity-[0.8] "
        onClick={() => onClose()}
      ></div>
      <div className="flex min-h-screen !z-[10000]">
        <div
          style={{ right: rightMarginToSet ? rightMarginToSet + "px" : "auto"}}
          className={`!absolute w-fit left-[300px] h-fit bg-white rounded-[16px] shadow-lg p-4 talk-bubble ${
            (welcomeSteps === 0 || welcomeSteps === 12 || welcomeSteps === 13) ? (welcomeSteps === 12 ? "tri-left" : "tri-top") : "tri-right"
          }  ${leftIn} ${topValue}`}
        >
          <GrFormClose
            onClick={() => onClose()}
            size={10}
            className="absolute  h-4 w-4 top-[16px] right-[14px] cursor-pointer text-[#34317D]"
          />

          <div
            className={`flex justify-center text-center flex-col gap-4 talktext ${
              className ? className : ""
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeTourDialogBox;