import TeamsIcon from "../assets/Images/TeamsIcon.svg"
import Loader from './Loader';

interface AdminTeamsTableProps {
    team: any;
    teamInvitesArr: any[];
    acceptLoading: string[];
    declineLoading: string[];
    handleAcceptTeamInvite: (teamId: string, inviteId: string) => Promise<void>;
    handleDeclineTeamInvite: (teamId: string, teamInviteId: string) => Promise<void>;
}


export default function TeamInviteBanner(props: AdminTeamsTableProps) {
    const {
        team,
        teamInvitesArr,
        acceptLoading,
        declineLoading,
        handleAcceptTeamInvite,
        handleDeclineTeamInvite,
    } = props;

    return (
        <div
            key={team.id}
            className="bg-[#FFFBF3] w-full border-[1.6px] border-[#FFD572] rounded-[16px] p-4 xsm:p-6 flex flex-col sm:flex-row justify-between gap-2 items-center font-poppins"
            style={{ minHeight: "65px" }}
        >
            <div className="flex justify-start items-center gap-4 w-full sm:w-auto mb-4 sm:mb-0">
                <img src={TeamsIcon} className="w-[20%] sm:w-auto" alt="Team Icon" />
                <p className="text-[14px] sm:text-[16px]">
                    <span className="font-bold">
                        {(team.adminId.first_name ? team.adminId.first_name : "") +
                            `${team.adminId.last_name ? ` ${team.adminId.last_name}` : ""}`}{" "}
                    </span>
                    invited you to the team
                    <span className="font-bold"> {team.name} </span>
                </p>
            </div>
            <div className="flex flex-col sm:flex-row justify-end items-center gap-2 w-full sm:w-auto">
                <button
                    onClick={() => {
                        const teamInviteIndex = teamInvitesArr.findIndex(
                            (invite) => invite.teamId === team.id
                        );
                        handleDeclineTeamInvite(team.id, teamInvitesArr[teamInviteIndex].id);
                    }}
                    className={`flex w-full sm:w-auto text-[14px] sm:text-[16px] justify-center items-center border-[1.5px] border-[#34317D] font-bold py-[10px] px-6 text-[#34317D] rounded-[10px] ${declineLoading && "w-[110px]"
                        }`}
                >
                    {declineLoading.includes(team.id) ? (
                        <Loader size={6} />
                    ) : (
                        "Decline"
                    )}
                </button>
                <button
                    onClick={() => {
                        const teamInviteIndex = teamInvitesArr.findIndex(
                            (invite) => invite.teamId === team.id
                        );
                        handleAcceptTeamInvite(team.id, teamInvitesArr[teamInviteIndex].id)
                    }}
                    disabled={acceptLoading.includes(team.id)}
                    className={`flex w-full sm:w-auto text-[14px] sm:text-[16px] justify-center items-center border-[1.5px] bg-[#34317D] border-[#34317D] font-bold py-[10px] px-6 sm:px-9 text-white rounded-[10px]`}
                >
                    {acceptLoading.includes(team.id) ? (
                        <Loader size={6} color={"white"} />
                    ) : (
                        "Accept"
                    )}
                </button>
            </div>
        </div>
    );
}