import { useContext, useEffect, useMemo, useState } from "react";

import { AuthContext, AuthContext_type } from "../context/Auth/context";
import Button from "./Button";
import { IPersona } from "../pages/Persona";
import Loader from "./Loader";
import { IGeneratedIdea } from "../pages/IdeaGenerator";

import IdeaGeneratorIcon from "../assets/Images/IdeaGenerator.svg";
import resetimg from "../assets/Images/Reset.png";
import DownArrow from "../assets/Images/downArrow.svg";
import checkImage from "../assets/Images/listCheck.svg";
import useScreenSize from "../hooks/useScreenSize";
import { SelectPersonaDialog } from "../pages/IdeaGenerator/components/SelectPersonaDialog";
import { UsageCompleteDisabledWrapper } from "../utils/UsageCompleteDisabledWrapper";
import FlagSelector, {type LanguageKey} from './FlagSelector';
import { STORAGE_KEYS } from "@/config/appConfig";
import { toastAlertError } from "@/utils";
import { sentryClient } from "@/Sentry/sentry";

type PropsType = {
  setSelectedPersona: React.Dispatch<React.SetStateAction<string>>;
  selectedPersona: string;
  fetchGenerateIdea: () => void;
  generateIdeaCommand: string;
  setGenerateIdeaCommand: React.Dispatch<React.SetStateAction<string>>;
  isGenerating: boolean;
  generatedIdeas: IGeneratedIdea[];
  isTrailFinished: boolean;
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<LanguageKey>>;

};

export default function IdeaGeneratorBanner(props: PropsType) {
  const {
    setSelectedPersona,
    selectedPersona,
    fetchGenerateIdea,
    setGenerateIdeaCommand,
    generateIdeaCommand,
    isGenerating,
    generatedIdeas,
    isTrailFinished,
    selectedLanguage, 
    setSelectedLanguage
  } = props;
  const { getPersonasList } = useContext(AuthContext) as AuthContext_type;
  const screenSize = useScreenSize();

  const [personas, setPersona] = useState<IPersona[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [  ] = useState<LanguageKey>("ENG")

  const handleFetchPersonas = async () => {
    try {
      const res = await getPersonasList();
      setPersona(res);
    } catch (error) {
      console.log(error);
      sentryClient.captureException(error)
    }
  };

  useEffect(() => {
    handleFetchPersonas();
  }, []);

  const isSmallScreen = useMemo(() => {
    if (screenSize === "small" || screenSize === "medium") {
      return true;
    }
    return false;
  }, [screenSize]);

  //close dialog
  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    try {
      const savedLanguage = localStorage.getItem(STORAGE_KEYS.LAST_LANGUAGE_USED);
      if (savedLanguage) {
        setSelectedLanguage(savedLanguage as LanguageKey);
      }
    } catch (error) {
      toastAlertError("Error while loading last used language!");
      sentryClient.captureException(error)
    }
  }, [setSelectedLanguage])

  return (
    <UsageCompleteDisabledWrapper isDisabled={isTrailFinished}>
      <div className="bg-white p-4 md:p-6 rounded-2xl font-poppins">
        <h3 className="text-lg md:text-xl md:font-semibold font-bold  text-gray_light w-full">
          What type of post ideas would you like evyAI to generate?
        </h3>
        <div className="bg-white border border-[#A1A2A9] rounded-xl mt-2">
          <textarea
            className="font-poppins md:text-lg text-sm text-gray_light leading-7 rounded-tl-2xl rounded-tr-2xl outline-none p-4 md:p-6 w-full"
            placeholder="Enter the topics or keywords that you would like evyAI to generate post ideas about"
            value={generateIdeaCommand}
            rows={isSmallScreen ? 10 : 1}
            onChange={(e) => setGenerateIdeaCommand(e.target.value)}
          />
          <div className="flex justify-center items-center flex-col md:flex-row md:items-end md:justify-end  border-r-0.5 border-b-0.5 border-l-0.5 border-[#A1A2A9] gap-4 rounded-bl-2xl rounded-br-2xl bg-[#F5F7F9] p-4">
            <div className="mr-auto">
            <FlagSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            </div>
            {isSmallScreen ? (
              <div
                onClick={() => setOpenDialog(true)}
                className={`relative md:inline-flex  ${!!selectedPersona ? "self-end w-full" : "max-w-[full] w-full self-center"}`}
              >
                <img
                  className="text-primary_color absolute top-1 right-2  pointer-events-none rounded cursor-pointer"
                  src={!!selectedPersona ? checkImage : DownArrow}
                  alt=""
                />
                <div className={`py-1 px-2 !pr-8 rounded-md text-sm font-poppins text-text_primary font-medium ${!!selectedPersona ? "border-purple-800 bg-purple-100 border-[0.8px]" : "bg-white border-[0.5px]"}`}>
                  {!!selectedPersona ? personas?.find((personas) => personas.id === selectedPersona)?.personaName : "Persona"}
                </div>
              </div>
            ) : (
              <div className="px-[16px] py-[10px] font-poppins cursor-pointer md:text-[16px] text-[14px] xsm:py-2 md:h-[44px] h-[38px] leading-[24px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color font-bold bg-transparent text-secondry_color disabled:bg-disable_button_state disabled:text-white disabled:border-disable_button_state">
                  <select
                    value={selectedPersona}
                    onChange={(e) => setSelectedPersona(e.target.value)}
                    className="outline-none border-none border border-transparent bg-transparent w-full max-w-sm px-4"
                    style={{ border: "none", outline: "none" }}
                  >
                    <option value="" className="">
                      Select the Persona
                    </option>
                    {personas?.map((persona, index) => (
                      <option key={index} value={persona.id}>
                        {persona?.personaName}
                      </option>
                    ))}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M7.00008 11.0395C6.77067 11.0395 6.5413 10.9526 6.3664 10.7791L0.862679 5.3182C0.51257 4.97082 0.51257 4.4076 0.862679 4.06036C1.21265 3.71312 1.78017 3.71312 2.13031 4.06036L7.00008 8.89248L11.8699 4.06053C12.22 3.71329 12.7874 3.71329 13.1374 4.06053C13.4877 4.40777 13.4877 4.97099 13.1374 5.31837L7.63375 10.7793C7.45877 10.9528 7.22939 11.0395 7.00008 11.0395Z"
                        fill="url(#paint0_linear_3946_289)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3946_289"
                          x1="1.03121"
                          y1="4.41461"
                          x2="10.6283"
                          y2="10.8889"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#2F3178" />
                          <stop offset="1" stopColor="#542E9C" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
              </div>
            )}

            {generatedIdeas && generatedIdeas.length > 0 ? (
              <Button
                className="px-6 py-[10px] bg-white rounded-[10px] border border-secondry_color !text-base font-bold  text-secondry_color disabled:opacity-50"
                onClick={fetchGenerateIdea}
                disabled={isGenerating}
              >
                {isGenerating ? (
                  <Loader size={4} />
                ) : (
                  <img src={resetimg} alt="reset" className="mr-2" />
                )}
                <span>Regenerate</span>
              </Button>
            ) : (
              <Button
                onClick={fetchGenerateIdea}
                className="all_sType_buttons md:rounded-[12px] rounded-[10px] md:text-[16px] text-[14px]  w-full md:w-fit leading-normal bg-secondry_color text-white  gap-[10px]"
                disabled={generateIdeaCommand.length < 1 || isGenerating}
              >
                <img src={IdeaGeneratorIcon} alt="img" width={16} height={16} />
                <span>Generate Ideas</span> {isGenerating && <Loader size={4} />}
              </Button>
            )}
          </div>
        </div>
        <SelectPersonaDialog
          openDialog={openDialog}
          handleClose={handleClose}
          personas={personas}
          selectedPersona={selectedPersona}
          setSelectedPersona={setSelectedPersona}
        />
      </div>
    </UsageCompleteDisabledWrapper >
  );
}
