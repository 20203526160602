function CheckIconCopy({
  height = 79,
  width = 71,
  backgroundColor = '#6E40C5',
  tickColor = '#F5F1FB',
}: {
  height?: number;
  width?: number;
  backgroundColor?: string;
  tickColor?: string;
}) {
  return (
    <svg
      className=''
      width={width}
      height={height}
      viewBox='0 0 54 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.33 0.725342L3.00593 13.0386C1.33193 14.0052 0.300781 15.7911 0.300781 17.7238V42.2714C0.300781 44.2067 1.33467 45.9946 3.01221 46.9602L24.4107 59.2783C26.0891 60.2445 28.1561 60.2403 29.8306 59.2673L51.0059 46.9638C52.6728 45.9953 53.6985 44.2134 53.6985 42.286V17.7189C53.6985 15.7889 52.67 14.0049 50.9996 13.0374L29.7499 0.728983C28.0741 -0.241692 26.0072 -0.24308 24.33 0.725342Z'
        fill={backgroundColor}
      />
      <path
        d='M22.8392 34.9518C19.3817 29.8564 16.4585 23.0332 9.7207 29.8564C14.5075 33.4975 17.1667 38.7745 19.2046 44.5968C19.2946 44.9603 19.7389 45.1419 20.0928 45.0526C21.5996 44.6891 23.1063 44.3256 24.5259 43.9592C24.7899 43.8698 24.967 43.7773 25.057 43.5063C30.1081 32.9524 36.3148 23.7602 43.3169 15.572C43.938 14.9344 43.0526 13.9332 42.3415 14.389C34.8081 19.3025 28.2474 26.126 22.8392 34.9518Z'
        fill={tickColor}
      />
    </svg>
  );
}

export default CheckIconCopy;
