import "./Sentry/sentry.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.css";
import "./index.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import RouterMain from "./router/index.routes.tsx";
import * as Sentry from "@sentry/react";
import ErrorView from "./components/ErrorView.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
 <Sentry.ErrorBoundary fallback={<ErrorView />}>
  <React.StrictMode>
   <RouterProvider router={RouterMain} />
   <ToastContainer />
  </React.StrictMode>
 </Sentry.ErrorBoundary>
);
