import React, { useEffect, useState, useRef } from 'react';
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";


type OrderKey = "Last Modified" | "Name A → Z" | "Name Z → A";
type OrderValue = { field: string, direction: string };

const SortActionButton: React.FC<any> = (props) => {
    const { 
        handleGetUserProfile
    } = props;

    const [listDropdown, setListDropdown] = useState(false);
    const [orderDropdown, setOrderDropdown] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<OrderKey>("Last Modified");
    const orderDropdownRef = useRef<HTMLDivElement | null>(null);
    const listDropdownRef = useRef<HTMLDivElement | null>(null);




    const orderMapping: Record<OrderKey, OrderValue> = {
        "Last Modified": { field: "modifiedDate", direction: "desc" },
        "Name A → Z": { field: "name", direction: "asc" },
        "Name Z → A": { field: "name", direction: "desc" },
    };
      
    const handleApplyOrder = () => {
        const { field, direction } = orderMapping[selectedOrder];
        handleGetUserProfile(field, direction as "asc" | "desc");
        setOrderDropdown(false); 
    };



 

    const handleClickOutside = (event: MouseEvent) => {
        const targetNode = event.target as Node;
    
        if (orderDropdownRef.current && !orderDropdownRef.current.contains(targetNode)) {
            setOrderDropdown(false);
        }
    
        if (listDropdownRef.current && !listDropdownRef.current.contains(targetNode)) {
            setListDropdown(false);
        }
    };

    useEffect(() => {
        if (orderDropdown || listDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [orderDropdown, listDropdown]);

    return (
        <>
            <div className='flex justify-between'>
                <div className='flex '>
                    <div className='relative ml-2'>
                        <button className='flex items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg'
                            onClick={() => setOrderDropdown(!orderDropdown)}
                            style={{ height: "40px"}}
                        >
                            <span>Order By</span>
                            <img src={orderDropdown ? UpArrow : DownArrow } className='ml-1' />
                        </button>
                        {orderDropdown && (
                            <div ref={orderDropdownRef} style={{maxWidth: "200px", minWidth: "200px"}} className='absolute left-0 mt-1 z-10'>
                                <div className='text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px]' >
                                    {Object.keys(orderMapping).map(order => (
                                        <span key={order} className='flex mt-1 cursor-pointer' onClick={() => {
                                            if (Object.keys(orderMapping).includes(order)) {
                                                setSelectedOrder(order as OrderKey);
                                            }
                                        }}>
                                            <img src={selectedOrder === order ? checkImage : unCheckImage} />
                                            <span className='ml-2'>{order}</span>
                                        </span>
                                ))}
                                </div>
                                <div className='flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]' >
                                    <button className='primary_button_custom' onClick={handleApplyOrder}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                 
                </div>
               
            </div>
        </>
    );
}
export default SortActionButton;