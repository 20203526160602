import React from "react";
import cancelImg from "../../../assets/Images/Cancel.svg";
import bgBanner from "../../../assets/Images/banner2.svg"
import "../../../index.css";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";

interface IProps {
  setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
  headline?: string;
  description?: string;
  btnText?: string;
}

const UpgradeBanner = ({ setShowBanner, headline, description, btnText }: IProps) => {
  const navigate = useNavigate();
  return (
    <section>

      <div style={{backgroundImage:`url(${bgBanner})`}} className="py-6 px-8 rounded-[16px] xsm:bg-[85%] bg-cover bg-center mt-8 relative ">
        <img
          className="absolute right-6 top-6 cursor-pointer"
          onClick={() => setShowBanner(false)}
          src={cancelImg}
          alt="Cancel"
        />
        <h3 className="font-poppins text-[20px] font-bold leading-normal text-[#FFD572]">
            {headline || "Become a Premium User!"}
        </h3>
        <p className="font-poppins text-[16px] mt-2 leading-normal text-white">
           {description || `Upgrade now for exclusive access to evyAI's Learning Hub! 
            Dive into curated content designed to elevate your LinkedIn game.`} 
        </p>
        <button
          onClick={() => navigate(`/${PROTECTED_ROUTES.subscriptionPricing}`)}
          className="white_p_buttons "
        >
          {btnText || "Upgrade"}
        </button>
      </div>
    </section>
  );
};

export default UpgradeBanner;
