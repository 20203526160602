import { Link } from "react-router-dom";
import BgImage from "../assets/Images/bg.png"
const ReadyToUpgrade = () => {

  // const handleUpgradeButton = () => {
  //   window.open('http://app.evyai.com/upgrade')
  // }
  return (
    <section className="rounded-[50px] mx-auto w-full mt-52">
      <div className="flex flex-col">
        <div
          className="bg-[#522F9A] mx-auto bg-center sm:bg-cover bg-size text-white bg sm:py-20 py-10 text-center container  sm:px-20 rounded-[50px] z-10 relative mb-[150px] px-[20px] sm:w-[66%] !w-[90%]"
          style={{ backgroundImage: `url(${BgImage})` }}
          >
          <h2 className="sm:text-[48px] text-[28px] font-extrabold font-poppins py-1">
            Ready to Upgrade?
          </h2>
          <p className="sm:px-2 sm:text-[22px] text-[16px] leading-8 font-poppins py-4">
            "I upgraded and everything changed. With GPT 4, the quality of the comments exploded and I get responses much more than before. evyAI saves me so much time."
          </p>
          <p className="sm:px-2 sm:text-[22px] text-[16px] sm:leading-8 leading-0 font-poppins sm:pt-3  pb-8">- James Pereira, 5-stars</p>
          <Link to={"https://evyai.com/upgrade/"} className="btn !sm:text-[22px] !text-[16px]">
              Upgrade to Premium
          </Link>
          {/* <button className="btn !sm:text-[22px] !text-[16px]" onClick={handleUpgradeButton}>Upgrade to Premium</button> */}
        </div>
        <div className="relative w-[100%]">
          <div className="absolute w-full bottom-0 h-[370px] bg-[#333333] rounded-t-[50px]">
            <div className="pt-[400px]"></div>
            {/* <div className="container text-white mx-auto flex justify-between">
                <div>
                  <img
                    src={websiteIcon}
                    alt="PNG"
                    />
                </div>
                <div className="">
                  <h1 className="font-bold text-[40px]">Stay updated on evyAI</h1>
                  <p className="text mt-4 !text-white">Join our inner circle to be the first to know about new updates and tips.</p>
                  <div className="mt-7">
                    <div className="flex gap-4">
                      <input type="text" className="w-full p-[10px] pl-0 bg-[#333333] outline-none border-b-[2px] border-[#FFFFFF]" placeholder="First Name" />
                      <input type="text" className="w-full p-[10px] pl-0 bg-[#333333] outline-none border-b-[2px]" placeholder="Last Name" />
                    </div>
                    <input type="text" className="w-full p-[10px] pl-0 bg-[#333333] outline-none border-b-[2px] mt-3" placeholder="Email Address" />
                    <button className="btn bg-button_color mt-5">Join the inner circle</button>
                  </div>
                </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadyToUpgrade;
