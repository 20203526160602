// import FreeTrailEnd from "../../components/FreeTrailEnd";
// import { Timestamp } from "firebase/firestore";
// import { useState, useContext, useEffect } from "react";
// import { AuthContext, AuthContext_type } from "../../context/Auth/context";
// import { SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
// import NoDataFound from "../../components/NoDataFound";
// import ConfirmationDialog from "../../components/ConfirmationDialog";
// import { toastAlertSuccess } from "../../utils";
// import { useNavigate } from "react-router";
// import { PROTECTED_ROUTES } from "../../router/routes.constants";
// import PlusSvg from "../../assets/Images/Plus.svg"
// import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import EmptyTableCommunity from "./components/EmptyTableCommunity";

// export interface IPersona {
//   id: string;
//   tone: string;
//   Tag: string;
//   personaName: string;
//   userId: string;
//   industry: string;
//   profilePictureUrl: string;
//   backgroundHistory: string;
//   timestamp: Timestamp;
//   keywordsPhrases: string;
// }

const Community = () => {
  // const { getPersonas, getSubscriptionStatus, deleteMultiplePersonas, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating, subscriptionStatus } = useContext(
  //   AuthContext
  // ) as AuthContext_type;
  // const [loading, setLoading] = useState<boolean>(true);
  // const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false);
  // const [isAddButtonDisable, setIsAddButtonDisable] = useState<boolean>(true);
  // const [personas, setPersonas] = useState<IPersona[]>([]);
  // const [limitConfig] = useState<any>({
  //   [SubscriptionStatus.FREE]: 1,
  //   [SubscriptionStatus.PRO]: 50
  // })
  // const [currentSubscription, setCurrentSubscription] = useState<any>()
  // const [deletePersonaIds, setDeletePersonaIds] = useState<string[]>([]);
  // const [showConfirmation, setShowConfirmation] = useState(false);
  // const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);

  // const navigate = useNavigate();

  // const handleMultipleDelete = () => {
  //   deleteMultiplePersonas(deletePersonaIds);
  //   toastAlertSuccess("Deleted Succefully")
  //   setPersonas((prevLists: IPersona[]) => prevLists.filter(persona => !deletePersonaIds.includes(persona.id)));          
  // };

  // const confirmMultipleDelete = () => {
  //   if (deletePersonaIds.length > 0) {
  //       handleMultipleDelete();
  //       setDeletePersonaIds([]);
  //   }
  //   setShowConfirmation(false); 
  // };

  // async function getAllPersonas(sortBy?: string, order?:any) {
  //   try {
  //     let allPersonas;
  //     if (sortBy && order) {
  //       allPersonas = await getPersonas(sortBy, order);
  //     } else {
  //       allPersonas = await getPersonas();
  //     }
  //     setPersonas(allPersonas);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Failed to fetch personas:", error);
  //   }
  // }

  // const handleClickAddPersona = () => {
  //   if (subscriptionStatus?.statusDb === SubscriptionStatusDB.PAST_DUE) {
  //     setShowUpdatePaymentDialog(true);
  //     return;
  //   }
  //   navigate(`/${PROTECTED_ROUTES.addPersona}`)
  // };

  // useEffect(() => {
  //   const fetchSubscriptionStatus = async () => {
  //     const subscriptionStatus = await getSubscriptionStatus();
  //     setCurrentSubscription(subscriptionStatus)

  //     const maxLimit = limitConfig[subscriptionStatus.status]
  //     if (
  //       personas.length >= maxLimit &&
  //       subscriptionStatus.status === SubscriptionStatus.FREE
  //     ) {
  //       setShowLimitMessage(true);
  //       setIsAddButtonDisable(true);
  //     } else if (
  //       personas.length >= maxLimit &&
  //       subscriptionStatus.status === SubscriptionStatus.PRO
  //     ){
  //       setShowLimitMessage(true);
  //       setIsAddButtonDisable(true)
  //     } else {
  //       setShowLimitMessage(false);
  //       setIsAddButtonDisable(false);
  //     }
  //   };
  //   fetchSubscriptionStatus();
  // }, [personas]);

  // useEffect(() => {
  //   getAllPersonas();
  // }, []);

  return (
    <section>
      <div className="flex justify-between items-center">
        <h3 className="custom-h3-style">
          Community
        </h3>
      </div>

      <p className="custom-primary-p-style">
        If you want tips on how to grow on LinkedIn,
        tips on how to do business development with AI and tips on growing your presence online,
        this is the perfect community for you.
      </p>
      <div className="mt-6">
        {/* {!loading && showLimitMessage && (
          <FreeTrailEnd
            title="You’ve reached the max limit of Personas."
            content={`You have already saved 1 Persona. ${currentSubscription.status !== SubscriptionStatus.PRO ? "Upgrade in order to continue adding Personas, and using evyAI to speak in your voice." : ""}`}
            buttonName="Upgrade Subscription"
            feature="Personas"
            subStatus={currentSubscription.status}
          />
        )} */}
      </div>
      {/* {loading && <NoDataFound isLoading={true} text="No data found" />} */}
      <EmptyTableCommunity />
      {/* <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmMultipleDelete}
        header={`Delete Persona${deletePersonaIds.length > 1 ? "s" : ""}`}
        content={`Are you sure you want to delete these Persona${deletePersonaIds.length > 1 ? "(s)" : ""}?`}
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      /> */}
    </section>
  );
};

export default Community;
