
import EvyAIDemonstration from "./components/EvyAIDemonstration";


const Demo = () => {


  return (
    <section>
      <div className="flex justify-between items-center">
        <h3 className="custom-h3-style-evyDemo">
        evyAI Demonstration
        </h3>
      </div>

      <p className="custom-primary-p-style">
      Learn how to use evyAI to grow your business on LinkedIn in just 15 minutes per day.
      </p>
      <div className="mt-6">
      {}
      </div>
      {}
      {}
      <EvyAIDemonstration />
      {}
    </section>
  );
};

export default Demo;
