import PinExtensionImg from "../assets/Images/landing_step1.png";
import LoginExtensionImage from "../assets/Images/landing_step2.png";
import StartExtensionImage from "../assets/Images/landing_step3.png";
import BgImage from "../assets/Images/hexagon.png"
import { MdOutlineExtension } from "react-icons/md";

const NewWelcomeSection = () => {
  return (
    <section className="md:pt-24 mt-6 mx-6 pt-10  sm:px-14 px-3 md:px-0 bg-[#6D40C4] rounded-[50px] sm:bg-no-repeat sm:bg-right-top sm:bg-blend-multiply sm:bg-[length:unset] bg-[length:0%_0%]" style={{ backgroundImage: `url(${BgImage})` }}>
      <div className="container mx-auto">
        <div className="mx-auto lg:w-[800px] text-center">
          <h1 className="sm:text-5xl text-[28px] font-poppins font-extrabold text-button_color">
            Welcome Aboard!
          </h1>
          <h2 className="sm:text-[40px] text-[28px] font-poppins font-extrabold secondry py-1 !text-white">
            What’s next?
          </h2>
          <p className="text !sm:text-[22px] !text-[16px] pt-4 secondry !text-white">
          Follow the following steps after installing evyAI
          </p>
        </div>
        <div className="flex md:flex-nowrap flex-wrap gap-6 mt-12">
          <div className="flex bg-[#F4F0FA] w-full rounded-[30px] py-8 px-4">
            <div className="w-full justify-center text-center">
              <h3 className="font-poppins sm:text-[48px] text-[28px] font-black text-light_purple sm:leading-[90px] sm:py-[10px] py-[0px] ">
                01
              </h3>
              <h2 className="font-poppins text-[#34317D] sm:text-[22px] text-[16px] leading-10 font-bold">
                Pin the extension
              </h2>
              <p className="font-poppins sm:text-[18px] text-[14px] text-text_color px-6">
              To quickly access evyAI, click
  the  <span className="inline-block"><MdOutlineExtension /></span> icon above to pin evyAI
              </p>
              <img className="mt-[20px]" src={PinExtensionImg} alt="" />
            </div>
          </div>
          <div className="flex bg-white w-full rounded-[30px] py-8 px-4" >
            <div className="justify-center text-center w-full">
              <h3 className="font-poppins sm:text-[48px] text-[28px] font-black text-light_purple sm:leading-[90px] text-center sm:py-[10px] py-[0px] ">
                02
              </h3>
              <h2 className="font-poppins text-[#34317D] mx-auto sm:text-[22px] text-[16px] leading-10 font-bold text-center ">
                Log In to your account
              </h2>
              <p className="font-poppins sm:text-[18px] text-[14px] text-text_color px-6">
                Make sure you are logged in to evyAI from the Chrome extension.
              </p>
              <img  className="mt-[35px]"  src={LoginExtensionImage} alt="" />
            </div>
          </div>
          <div className="bg-[#F4F0FA] w-full rounded-[30px] py-8 px-4">
            <div className="w-full justify-center text-center">

            <h3 className="font-poppins sm:text-[48px] text-[28px] font-black text-light_purple sm:leading-[90px] text-center sm:py-[10px] py-[0px] ">
                03
              </h3>
              <h2 className="font-poppins text-[#34317D] mx-auto sm:text-[22px] text-[16px] leading-10 font-bold text-center">
              Start using evyAI
              </h2>
              <p className="font-poppins sm:text-[18px] text-[14px] text-text_color px-6">
                Go to LinkedIn.com/Feed and try to leave a comment on a post using
                evyAI.
              </p>
              <img  className="mt-[15px]"  src={StartExtensionImage} alt="" />
            </div>
          </div>
        </div>
        <div className="mx-auto sm:mt-[-40px] mt-[0] text-center sm:py-[100px] py-[20px] text-white top-14">
          <h2 className="sm:text-[32px] text-[22px] font-poppins font-bold  sm:pt-5 pt-0 sm:leading-10 leading-8 text-center sm:w-[60%] w-[80%] mx-auto">
            Congrats, now you are ready to work with your evyAI LinkedIn assistant
            to comment, post and reply to direct messages!
          </h2>
        </div>
      </div>
    </section>
  );
};

export default NewWelcomeSection;
