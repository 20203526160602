import { useContext, useEffect, useState,useRef } from "react";
import { toastAlertError, toastAlertSuccess } from "../../utils";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
// import './../../CustomStyling/CustomReusablestyling.css';
import {
  Timestamp,
  collection,
  orderBy,
  query,
  getDocs,
  startAfter,
  endBefore,
  limit,
  DocumentSnapshot,
  limitToLast,
  where,
  getCountFromServer,
  startAt
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { COLLECTIONS, SubscriptionStatusDB } from "../../config/firebase";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import ActionButtons from "./components/ActionButtons";
import SaveProfileTable from "./components/SaveProfileTable";
import { UserLists } from "../ManageLists";
import AddToList from "./components/AddToList";
import { SubscriptionStatus } from "../../config/firebase";
import NoDataFound from "../../components/NoDataFound";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Link } from "react-router-dom";
import { Navigate_to } from "../../router/routes.constants";
import arrowLeft from "../../assets/Images/LeftArrow.svg";
import AddProfileDialog from "./components/AddProfileDialog";
import EmptyTable from "./components/EmptyTable";
import add_icon from "../../assets/Images/add_icon.svg"
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import { SAVEDPROFILES_LIMIT } from "../../constant";
import { sentryClient } from "@/Sentry/sentry";

export interface SavedProfiles {
  profileUrl: string;
  timestamp?: Timestamp;
  linkedInProfileName: string;
  profileHeadline: string;
  userId: string;
  summaryId?: string;
  profilePictureUrl: string;
  lists?: UserLists[];
  id: string;
  profileUrlSN?: string;
}

export interface Pagination {
  firstDoc: DocumentSnapshot | null;
  lastDoc: DocumentSnapshot | null;
}

export interface FetchData {
  init?: boolean;
  isNext?: boolean;
  itemsPerPage?: number;
  orderByField?: string;
  orderDirection?: "asc" | "desc",
  filters?: UserLists[];
  query?: string;
  ignoreSelectedList? : boolean,
  startAfterFristDoc? : boolean, 
}

const initialUserList: UserLists[] = [
  {
    id: "0",
    name: "",
    date: "",
  },
];

const SavedProfile = () => {
  const {
    getCurrentUser,
    deleteMultipleProfiles,
    handleGetUserLists,
    subscriptionStatus,
    getCustomerSubscriptionPortalLink,
    isMSubcribeLinkGenerating,
    user,
    createList
  } = useContext(AuthContext) as AuthContext_type;
  const currentUser = getCurrentUser();

  const [savedProfiles, setSavedProfiles] = useState<SavedProfiles[]>([]);
  const [showSelectionButton, setShowSelectionButton] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [userAllLists, setUserAllLists] =
    useState<UserLists[]>(initialUserList);
  const [selectedLists, setSelectedLists] = useState<UserLists[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFreeTrail, setShowFreeTrail] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [listUser, setListUser] = useState<UserLists | null>(null);
  const [showAddProfileDialog, setShowAddProfileDialog] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const [subStatus, setSubStatus] = useState<number>();
  const [pagination, setPagination] = useState<Pagination>({
    firstDoc: null,
    lastDoc: null,
  });
  const [limitConfig, setLimitConfig] = useState<any>({
    [SubscriptionStatus.FREE]: SAVEDPROFILES_LIMIT.FREE,
    [SubscriptionStatus.PRO]: SAVEDPROFILES_LIMIT.PRO,
  });
  const [totalSavedProfileRecords, setTotalSavedProfileRecords] = useState<number>(0)
  const queryParams = new URLSearchParams(location.search);
  const listId = queryParams.get("list");
  
  const [prevListParam, setPrevListParam] = useState(new URL(window.location.href).searchParams.get("list"));
  const isInitialLoad = useRef(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isPaginationDone, setIsPaginationDone] = useState<boolean>(false);
  const [refreshListsFlag, setRefreshListFlag] = useState<boolean>(false);


  useEffect(() => {
    const currentLocation = new URL(window.location.href);
    const isQueryParams = currentLocation.searchParams.get("list");
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    } else {
      if (prevListParam && !isQueryParams) {
        setSelectedLists([]);
        handleGetUserProfile({
          orderByField: "timestamp",
          orderDirection: "desc",
          init: true,
          query: "",
          ignoreSelectedList: true,
          itemsPerPage : rowsPerPage || 10,
        });
      }else{
        if (isQueryParams) {
          const listIndexes = isQueryParams.split(',').map(listId => 
            userAllLists.findIndex(listItem => listItem.id === listId)
          ).filter(index => index !== -1); 
          
          if (listIndexes.length > 0) {
              const urlFilteredLists = listIndexes.map(index => userAllLists[index]);
              setSelectedLists(urlFilteredLists);
              handleGetUserProfile({
                orderByField: "timestamp",
                orderDirection: "desc",
                init: true,
                query: "",
                itemsPerPage : rowsPerPage || 10,
              });
          }
        }
      }
    }

    // Update the previous list parameter
    setPrevListParam(isQueryParams);
  }, [window.location.search]);
  useEffect(() => {
    if (
      (user)?.limits
    ) {
      setLimitConfig({
        [SubscriptionStatus.FREE]: user?.limits.saved_profile_free_limit,
        [SubscriptionStatus.PRO]: user?.limits.saved_profile_pro_limit,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (listId && userAllLists.length) {
      const listIndex = userAllLists.findIndex(
        (listItem) => listItem.id === listId
      );

      if (listIndex !== -1) {
        setListUser(userAllLists[listIndex]);
      }
    }
  }, [userAllLists]);

  const handleGetUserProfile = async (fetchData?: FetchData): Promise<any> => {
    try {
      if (!currentUser) return;
      
      const allListsRef = collection(
        db,
        `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`
      );
      const allListsSnapshot = await getDocs(allListsRef);
      const allListsMap: { [key: string]: UserLists } = {};
      allListsSnapshot.forEach((listDoc) => {
        allListsMap[listDoc.id] = listDoc.data() as UserLists;
      });

      let profileRef;
      const queryRef = collection(db, `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.PROFILES}`);
      let _selectedIds: UserLists[] = [];
      if (!fetchData?.ignoreSelectedList) {
        _selectedIds = (fetchData?.filters || selectedLists);
      }
      // const _whereByLists = _selectedIds.length ? [where('lists', 'array-contains-any', _selectedIds.map(_ => _.id))] : [];
      let _whereByLists;
      if (listId && !fetchData?.filters && !fetchData?.ignoreSelectedList) {
        const listIdsArray = listId.split(',');
        // If listId is present, add it directly to the where condition
        _whereByLists = [where('lists', 'array-contains-any', listIdsArray)];
      } else {
        // If listId is not present, proceed with the existing logic
        _whereByLists = _selectedIds.length ? [where('lists', 'array-contains-any', _selectedIds.map(_ => _.id))] : [];
      }

      let orderByField = "timestamp";
      let _whereByName:any = [];
      let capitalizedQuery = '';
      if (fetchData?.query) {
        orderByField = "linkedInProfileName";
        capitalizedQuery = fetchData.query
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      
        _whereByName = fetchData?.query ? [
          where('linkedInProfileName', '>=', capitalizedQuery),
          where('linkedInProfileName', '<=', capitalizedQuery + '\uf8ff'),
        ] : [];

      } else if (fetchData?.orderByField) {
        orderByField = fetchData.orderByField;
      }
      const _orderBy = orderBy(orderByField, fetchData?.orderDirection || "desc");
      const _limit = limit(fetchData?.itemsPerPage || 10);

      const countQuery = query(queryRef, ..._whereByLists);
      const countSnapshot = await getCountFromServer(countQuery);
      const totalRecords = countSnapshot.data().count;
      setTotalSavedProfileRecords(totalRecords)

      if (fetchData?.init) {
        profileRef = query(
          queryRef,
          ..._whereByLists,
          ..._whereByName,
          _orderBy,
          _limit,
        );
      } else if (fetchData?.isNext && pagination.lastDoc && !fetchData?.startAfterFristDoc) {
        profileRef = query(
          queryRef,
          ..._whereByLists,
          ..._whereByName,
          _orderBy,
          startAfter(pagination.lastDoc),
          _limit,
        );
      } else if (fetchData?.isNext && pagination.firstDoc && fetchData?.startAfterFristDoc) {
        profileRef = query(
          queryRef,
          ..._whereByLists,
          ..._whereByName,
          _orderBy,
          startAt(pagination.firstDoc),
          _limit,
        );
      } else if (!fetchData?.isNext && pagination.firstDoc) {

        profileRef = query(
          queryRef,
          ..._whereByLists,
          ..._whereByName,
          _orderBy,
          endBefore(pagination.firstDoc),
          limitToLast(fetchData?.itemsPerPage || 10),
        );
      } else {

        profileRef = query(
          queryRef,
          ..._whereByLists,
          ..._whereByName,
          _orderBy,
          _limit,
        );
      }

      const profileSnapshot = await getDocs(profileRef);
      if (profileSnapshot.docs.length) {
        setPagination({
          firstDoc: profileSnapshot.docs[0],
          lastDoc: profileSnapshot.docs[profileSnapshot.docs.length - 1],
        })

        let profileArr: SavedProfiles[] = profileSnapshot.docs.map(
          (documentSnapshot) => {
            const profileData = documentSnapshot.data();
            const listIdsForProfile: string[] = profileData?.lists || [];

            const listsData: UserLists[] = listIdsForProfile
              .map((listId: string) => allListsMap[listId])
              .filter(Boolean);

            return {
              ...profileData,
              lists: listsData,
              id: documentSnapshot.id,
            } as SavedProfiles;
          }
        );

        if (fetchData?.query) {
          const queryLower = fetchData.query.toLowerCase();
          profileArr = profileArr.filter((profile) => profile.linkedInProfileName.toLowerCase().includes(queryLower));
        }
        setSavedProfiles(profileArr);
      } else {
        setSavedProfiles([]);
        setPagination({
          ...pagination,
          ...(!fetchData?.isNext && !fetchData?.init ? { firstDoc: null } : {}),
          ...(fetchData?.isNext ? { lastDoc: null } : {}),
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err, "err");
      setPagination({
        ...pagination,
        ...(!fetchData?.isNext && !fetchData?.init ? { firstDoc: null } : {}),
        ...(fetchData?.isNext ? { lastDoc: null } : {}),
      });
      setLoading(false);
      sentryClient.captureException(err)
    }
  };

  const confirmDelete = () => {
    if (selectedProfiles) {
      handleMultipleProfilesDeletion();
    }
    setShowConfirmation(false);
  };

  const initiateDelete = () => {
    setShowConfirmation(true);
  };


  const handleShowProfileDialog = () => {
    if (subscriptionStatus) {
      console.log(subscriptionStatus);

      if (subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
        const canAdd = canFreeUserAddMoreProfiles();
        if (!canAdd) {
          setShowUpdatePaymentDialog(true);
          return;
        } else {
          setShowUpdatePaymentDialog(false);
          setShowAddProfileDialog(true)
        }
      } else {
        setShowAddProfileDialog(true)
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const userLists = await handleGetUserLists();
        setUserAllLists(userLists);
      } catch (error) {
        toastAlertError("Failed to fetch lists");
        sentryClient.captureException(error)
      }
    }

    fetchData();
  }, [refreshListsFlag]);

  useEffect(() => {
    if (selectedProfiles.length > 0) {
      setShowSelectionButton(true);
    } else {
      setShowSelectionButton(false);
    }
  }, [selectedProfiles]);

  useEffect(() => {
    // handleGetUserProfile();
    handleGetUserProfile({
      orderByField: "timestamp",
      orderDirection: "desc",
      init: true,
      query: "",
      itemsPerPage : rowsPerPage || 10,
    });
  }, [dataChanged]);

  const canFreeUserAddMoreProfiles = () => {
    if (subscriptionStatus) {
      setSubStatus(subscriptionStatus.status)
      if (savedProfiles.length >= limitConfig[subscriptionStatus.status]) {
      return false;
    } else {
      return true;
    }
  }
  }

  useEffect(() => {
    if (subscriptionStatus) {
      setSubStatus(subscriptionStatus.status)
      if (
        savedProfiles.length >= limitConfig[subscriptionStatus.status] &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        setShowFreeTrail(true);
      } else {
        setShowFreeTrail(false);
      }
    }
  }, [savedProfiles, subscriptionStatus]);

  const toggleDataChanged = () => {
    setDataChanged((prev) => !prev);
  };

  function handleMultipleProfilesDeletion() {
    deleteMultipleProfiles(selectedProfiles);
    setSavedProfiles((prevLists) =>
      prevLists.filter((profile) => !selectedProfiles.includes(profile.id))
    );
    setSelectedProfiles([]);
    toastAlertSuccess("List deleted successfully");
  }

  function handleMultipleProfilesAddToLists() {
    setShowAlert(true);
  }

  const accessRowsPerPage = (value:any) => {
    setRowsPerPage(value);
  }

  const accessPagination = (value:boolean) => {
    setIsPaginationDone(value);
  }
  
  return (
    <section>
      {listUser !== null && listId && (
        <div className="flex">
          <Link
            to={Navigate_to.savedProfiles}
            className="flex justify-start items-end cursor-pointer"
          >
            <h2 className="font-poppins md:text-[18px] text-[12px]  font-normal leading-normal text-[#444553]">
              Saved Profiles
            </h2>
            <img
              className="ml-2"
              src={arrowLeft}
              alt=""
              width="16px"
              height="16px"
            />
          </Link>
          <Link
            to={Navigate_to.manageLists}
            className="flex justify-start items-center cursor-pointer"
          >
            <h2 className="font-poppins md:text-[18px] text-[12px]  font-normal leading-normal text-[#444553] ml-2">
              Manage Lists
            </h2>
            <img
              className="ml-2"
              src={arrowLeft}
              alt=""
              width="16px"
              height="16px"
            />
          </Link>
          <h2 className="font-poppins md:text-[18px] text-[12px] leading-normal text-[#444553] ml-2 font-bold">
            List Detail
          </h2>
        </div>
      )}
      <div className="flex justify-between md:items-end items-center w-full">
        <h3 className="custom-h3-style">
          Saved Profiles
        </h3>
        <button disabled={showFreeTrail} onClick={handleShowProfileDialog} className="md:hidden primary_button_custom">
          <img src={add_icon} width={15} height={15} className="" />
          Add Profile
        </button>
      </div>

      <p className="custom-primary-p-style">
        Easily create list(s) of profiles that you want to stay actively engaged with.
      </p>
      <div className="mt-5 md:mt-8">
        {!loading && showFreeTrail && (
          <FreeTrailEnd
            title="You’ve reached the max limit of Profiles."
            content="You have already saved 3 Profiles. Upgrade in order to continue saving, summarizing, and managing Profiles in your account."
            buttonName="Upgrade Subscription"
            feature="Profiles"
            subStatus={subStatus}
          />
        )}
      </div>

      <ActionButtons
        showDeleteButton={showSelectionButton}
        initiateDelete={initiateDelete}
        handleMultipleProfilesAddToLists={handleMultipleProfilesAddToLists}
        allLists={userAllLists}
        selectedLists={selectedLists}
        setSelectedLists={setSelectedLists}
        handleGetUserProfile={handleGetUserProfile}
        handleShowProfileDialog={handleShowProfileDialog}
        showFreeTrail={showFreeTrail}
        rowsPerPage = {rowsPerPage}
        handleGetUserLists = {handleGetUserLists}
        refershListsFlag = {refreshListsFlag}
      />

      {!loading && showAlert && (
        <AddToList
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          onDataChanged={toggleDataChanged}
          selectedProfiles={selectedProfiles}
          allLists={userAllLists}
          setSavedProfiles={setSavedProfiles}
          savedProfiles={savedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          rowsPerPage = {rowsPerPage}
          handleGetUserProfile={handleGetUserProfile}
          accessPagination = {isPaginationDone}
          createList = {createList}
          handleGetUserLists = {handleGetUserLists}
          setRefreshListFlag = {setRefreshListFlag}
          refershListsFlag = {refreshListsFlag}
        />
      )}

      {loading && <NoDataFound isLoading={true} text="No data found" />}

      {!loading && savedProfiles.length <= 0 && !listId && (
        <EmptyTable />
      )}

      {!loading && savedProfiles.length <= 0 && listId && (
        <div className=" bg-white rounded-[10px] xsm:border-none h-[65vh] xsm:bg-white md:my-6 my-3 flex flex-col justify-center items-center">
          <div className="flex flex-col gap-2 justify-center items-center">
            <h2 className="custom-h3-style md:text-[24px] text-[18px] leading-[36px] mt-8">
              List Empty
            </h2>
            <h3 className="custom-primary-p-style md:text-[16px] text-[14px] leading-[24px]">
              No profiles in this list.
            </h3>
          </div>
        </div>
      )}

      {!loading && savedProfiles.length > 0 && (
        <SaveProfileTable
          savedProfiles={savedProfiles}
          onDataChanged={toggleDataChanged}
          setSavedProfiles={setSavedProfiles}
          selectedProfiles={selectedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          handleGetUserProfile={handleGetUserProfile}
          allLists={userAllLists}
          setSelectedLists={setSelectedLists}
          totalRecords = {totalSavedProfileRecords}
          accessRowsPerPage = {accessRowsPerPage}
          accessPagination = {accessPagination}
          isPaginationDone = {isPaginationDone}
          handleGetUserLists = {handleGetUserLists}
          refershListsFlag = {refreshListsFlag}
        />
      )}
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={confirmDelete}
        header="Delete Porfile"
        content="Are you sure you want to delete these profile(s)?"
      />
      <AddProfileDialog
        showAlert={showAddProfileDialog}
        setShowAlert={setShowAddProfileDialog}
        toggleDataChanged={toggleDataChanged}
      />
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
      {showSelectionButton &&
        <div className="md:hidden w-[100%] mx-auto flex flex-col gap-2 backdrop-blur-[100px] sticky bottom-[0px] rounded-md ">
          <button
            onClick={() => handleMultipleProfilesAddToLists()}
            className="bg-secondry_color text-white font-bold border border-secondry_color px-3 py-2 rounded-lg mt-4"
          >
            Add To List
          </button>
          <button
            className="bg-transparent text-secondry_color font-bold border border-secondry_color px-3 py-2 rounded-lg mb-2"
            onClick={() => initiateDelete()}
          >
            Delete Profiles
          </button>
        </div>
      }
    </section>
  );
};
export default SavedProfile;
