import Loader from "./Loader";

const NoDataFound = ({text,isLoading}:{text?:string, isLoading?:boolean}) => {
  return (
    <div className="md:h-[340px] h-[220px] border rounded-[10px] border-border_color md:my-6 my-3 flex justify-center items-center">
      <h3 className="font-poppins text-[20px] leading-normal text-[#444553]">
        {isLoading? <Loader size={10}/>: text}
      </h3>
    </div>
  );
};

export default NoDataFound;
