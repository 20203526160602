import { FieldValue, serverTimestamp } from "firebase/firestore";
import { IUser } from "../../../context/Auth/auth.types";

type TeamMemberStatus = "invite_sent" | "joined";

interface TeamMember {
    id: string;
    email: string;
    inviteSentDate: FieldValue;
    joinedDate?: FieldValue;
    status: TeamMemberStatus;
    memberData: IUser | undefined;
    name?: string;
    photoUrl?: string;
    userId: string;
}

export const TEAM_MEMBER_MODEL_FIELDS = {
    EMAIL: 'email',
    INVITE_SENT_DATE: 'inviteSentDate',
    JOINED_DATE: 'joinedDate',
    STATUS: 'status',
};

export class TeamMemberModel implements TeamMember {
    id: string;
    email: string;
    inviteSentDate: FieldValue;
    joinedDate?: FieldValue;
    status: TeamMemberStatus;
    memberData: IUser | undefined;
    name?: string | undefined;
    photoUrl?: string | undefined;
    userId: string;

    constructor(data: Partial<TeamMemberModel>) {
        this.id = data.id || '';
        this.email = data.email || '';
        this.inviteSentDate = data.inviteSentDate || serverTimestamp();
        this.joinedDate = data.joinedDate || serverTimestamp();
        this.status = data.status || 'invite_sent';
        this.memberData = data.memberData || undefined;
        this.name = data.name || undefined;
        this.photoUrl = data.photoUrl || undefined;
        this.userId = data.userId || '';
    }

    static toJSON(data: TeamMemberModel): TeamMemberModel {
        return {
            id: data.id,
            email: data.email,
            inviteSentDate: data.inviteSentDate,
            joinedDate: data.joinedDate,
            status: data.status,
            memberData: data.memberData,
            name: data.name,
            photoUrl: data.photoUrl,
            userId: data.userId,
        };
    }

    static fromJSON(data: any): TeamMemberModel {
        return new TeamMemberModel({
            id: data.id,
            email: data.email,
            inviteSentDate: data.inviteSentDate,
            joinedDate: data.joinedDate,
            status: data.status,
            memberData: data.memberData,
            name: data.name,
            photoUrl: data.photoUrl,
            userId: data.userId,
        });
    }
}
