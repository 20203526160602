import DailogBox from "./DailogBox";
import WarningIcon from "../assets/Images/active.svg"
import { Link } from "react-router-dom";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
//   id?: string | null;
//   content?: any;
//   onClick: Function;
  url: string | undefined;
}
const GoToStripeLinkDialogue = ({
  setShow,
  show,
  url,
}: IProps) => {

  return (
    <DailogBox
        open={show}
        onClose={() => setShow(false)}
        dialogClass="w- !z-[999999999999999999999] flex justify-center items-center"
        dialogBoxClass="flex justify-center items-center"
        >
        <div className="w-[500px] xsm:w-[300px] text-center flex justify-center items-center flex-col sm:text-left gap-4">
            <img
                width={100}
                src={WarningIcon}
            />
            <p className="text-[24px]  text-[#34317D] font-poppins leading-relaxed text-center text-lg font-[900]">
                If you aren’t automatically redirected
                {/* {content} */}
            </p>
            {url &&
            <Link to={url} className=" underline cursor-pointer text-[18px] font-poppins leading-6 text-center text-lg hover:text-[#34317D]" >
                Click Here
            </Link>
            }
            {/* <div className=" w-full items-center flex md:flex-row gap-2 xsm:flex xsm:flex-col-reverse">
                <button
                    className="w-[35%] xsm:w-full mt-2 px-6 py-2.5 flex-1 text-center text-blue-800  font-bold rounded-[10px] outline-none border ring-offset-2"
                    onClick={() => setShow(false)}
                    >
                    Cancel
                </button>
                <button
                    disabled={isLoading}
                    className="w-[65%] h-full xsm:w-full mt-2 px-6 py-2.5 text-center text-white bg-blue-900  outline-none ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
                    onClick={onConfirm}
                    >
                    {isLoading ?
                        <ThreeDots
                            height={25}
                            width={30}
                            color='#ababab'
                            wrapperStyle={{}}
                            wrapperClass=''
                            visible={true}
                            ariaLabel='three-dots-loading'
                            />
                        :
                        content.btnText
                    }
                </button>
            </div> */}
        </div>
    </DailogBox>
  );
};
export default GoToStripeLinkDialogue;