function Category({ height = 20, width = 20, color = "#000000" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 20 20'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    d='M4.49532 4.58698C4.03931 4.58698 3.66964 4.95665 3.66964 5.41266C3.66964 5.86868 4.03931 6.23835 4.49532 6.23835H15.5045C15.9605 6.23835 16.3302 5.86868 16.3302 5.41266C16.3302 4.95665 15.9605 4.58698 15.5045 4.58698H4.49532Z'
    fill={color}
   />
   <path
    d='M4.49532 9.17422C4.03931 9.17422 3.66964 9.5439 3.66964 9.99991C3.66964 10.4559 4.03931 10.8256 4.49532 10.8256H15.5045C15.9605 10.8256 16.3302 10.4559 16.3302 9.99991C16.3302 9.5439 15.9605 9.17422 15.5045 9.17422H4.49532Z'
    fill={color}
   />
   <path
    d='M3.66964 14.5872C3.66964 14.1311 4.03931 13.7615 4.49532 13.7615H11.8348C12.2908 13.7615 12.6605 14.1311 12.6605 14.5872C12.6605 15.0432 12.2908 15.4128 11.8348 15.4128H4.49532C4.03931 15.4128 3.66964 15.0432 3.66964 14.5872Z'
    fill={color}
   />
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M7.24771 0C4.87269 0 2.99538 0.469946 1.73266 1.73266C0.469946 2.99538 0 4.87269 0 7.24771V12.7523C0 15.1273 0.469946 17.0046 1.73266 18.2673C2.99538 19.5301 4.87269 20 7.24771 20H12.7523C15.1273 20 17.0046 19.5301 18.2673 18.2673C19.5301 17.0046 20 15.1273 20 12.7523V7.24771C20 4.87269 19.5301 2.99538 18.2673 1.73266C17.0046 0.469946 15.1273 0 12.7523 0H7.24771ZM1.65138 7.24771C1.65138 5.03557 2.09886 3.70187 2.90036 2.90036C3.70187 2.09886 5.03557 1.65138 7.24771 1.65138H12.7523C14.9644 1.65138 16.2981 2.09886 17.0996 2.90036C17.9011 3.70187 18.3486 5.03557 18.3486 7.24771V12.7523C18.3486 14.9644 17.9011 16.2981 17.0996 17.0996C16.2981 17.9011 14.9644 18.3486 12.7523 18.3486H7.24771C5.03557 18.3486 3.70187 17.9011 2.90036 17.0996C2.09886 16.2981 1.65138 14.9644 1.65138 12.7523V7.24771Z'
    fill={color}
   />
  </svg>
 );
}

export default Category;
