import { useLocation, useNavigate } from "react-router-dom";
import { SideBarProps } from "./types";
import useScreenSize from "../../hooks/useScreenSize";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";

interface IProps extends SideBarProps {
 welcomeSteps: number | null;
 introDialog: boolean;
 setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Sidebar(SideBarProps: IProps) {
 const navigate = useNavigate();
 const { pathname } = useLocation();
 const screenSize = useScreenSize();
 console.log(screenSize, "scree size");

 const handleShowErrorComingSoon = () => {
  toast.error("Coming soon to mobile. Switch to desktop view.");
 };

 const checkIsActive = (path: string) => {
  return pathname.includes(path);
 };
 return (
  <nav className='flex flex-col items-start w-full'>
   {SideBarProps.navigations.map((El, index) => (
    <Fragment key={El.title}>
     <a
      onClick={() => {
       if ((screenSize === "medium" || screenSize === "small") && !El.isComingSoon) {
        SideBarProps?.setIsOpen?.(false);
       }
       if ((screenSize === "medium" || screenSize === "small") && El.isComingSoon) {
        handleShowErrorComingSoon();
       } else {
        navigate(El.link);
       }
      }}
      className={`flex items-center text-base md:text-lg justify-start w-full py-2 px-2 rounded-[8px] cursor-pointer ${
       (index === (SideBarProps.welcomeSteps !== null && SideBarProps.welcomeSteps - 1) && SideBarProps.introDialog) || checkIsActive(El.link) ? "bg-[#F5F1FB]" : ""
      } ${index !== 1 && index !== 3 && index !== 7 && " mb-2"}`}
     >
      <El.icon
       color={(index === (SideBarProps.welcomeSteps !== null && SideBarProps.welcomeSteps - 1) && SideBarProps.introDialog) || checkIsActive(El.link) ? "#6D40C4" : "#696A6F"}
      />
      <span
       className={`font-poppins flex flex-col  md:text-[18px] leading-normal ml-2 ${
        (index === (SideBarProps.welcomeSteps !== null && SideBarProps.welcomeSteps - 1) && SideBarProps.introDialog) || checkIsActive(El.link)
         ? "text-primary_color font-bold"
         : "text-[#696A6F] font-medium"
       }`}
      >
       {El.title}
       {(screenSize === "medium" || screenSize === "small") && El.isComingSoon && <span className='text-[10px]'>(Coming soon to mobile. Switch to desktop view.)</span>}
      </span>
     </a>
     {(index === 1 && <span className='font-poppins font-bold text-[14px] mb-3 mt-6'>Create</span>) ||
      (index === 3 && <span className='font-poppins font-bold text-[14px] mb-3 mt-6'>Engage</span>) ||
      (index === 7 && <span className='font-poppins font-bold text-[14px] mb-3 mt-6'>Learn</span>)}
    </Fragment>
   ))}
  </nav>
 );
}

export default Sidebar;
