import { forwardRef } from 'react';

type PropsType = {
  error?: string;
  label?: string;
  labelClassName?: string;
  [rest: string]: any;
  focus?: boolean;
};

const TextInput = forwardRef((props: PropsType, ref) => {
  const { error, label, className, labelClassName, focus, ...rest } = props;

  return (
    <div>
      {label && (
        <label
          className={`text-sm font-semibold ${
            labelClassName ? labelClassName : ''
          }`}
        >
          {label}
        </label>
      )}
      <input
        ref={ref as any}
        autoFocus={focus}
        className={`px-2 py-3 max-sm:py-2 outline-none text-sm border-gray-200 border w-full rounded-md mt-1 disabled:opacity-50 text-[#292929] ${
          className || ''
        }`}
        {...rest}
      />
      {error && (
        <p className='text-sm font-medium text-start text-rose-600 mt-1 px-1'>{error}</p>
      )}
    </div>
  );
});

export default TextInput;
