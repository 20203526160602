import * as yup from "yup";

export const templateSchema = yup.object({
  templateName: yup
    .string()
    .required("Template Name is required")
    .max(200, "Must be within 200 characters")
    .label("Template Name"),
  templateDescription: yup.string().label("Description"),
  templateTags: yup.string().label("Tags"),
  templateContent: yup
    .string()
    .required("Template content is required")
    .label("Template Content"),
});

// import * as yup from "yup";

// export const templateSchema = yup.object({
//   templateName: yup
//     .string()
//     .required("Template Name is required")
//     .max(200, "Must be within 200 characters")
//     .label("Template Name"),
//   templateDescription: yup.string().label("Description"),
//   templateTags: yup
//     .array()
//     .of(yup.string().label("Tag"))
//     .label("Tags"),
//   templateContent: yup
//     .string()
//     .required("Template content is required")
//     .label("Template Content"),
// });
