import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { STORAGE_KEYS } from "../../config/appConfig";
import { toastAlertSuccess } from "../../utils";
import {
  collection,
  query,
  getDoc,
  doc,
  enableIndexedDbPersistence,
  getFirestore,
  setDoc,
  updateDoc,
  getDocs,
  where,
  addDoc,
  serverTimestamp,
  onSnapshot,
  DocumentSnapshot,
  DocumentData,
  deleteDoc,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
  DocumentReference,
  CollectionReference,
  initializeFirestore,
  persistentLocalCache,
  CACHE_SIZE_UNLIMITED,
  persistentMultipleTabManager,
  Timestamp,
  Unsubscribe,
  QuerySnapshot,
} from "firebase/firestore";

import { AuthContextProvider } from "./context";
import { ReactNode, useEffect, useState } from "react";
import {
  COLLECTIONS,
  FIELDS,
  FIREBASECONFIG,
  FREE_USER_MAXIMUM_TOKENS,
  PRO_USER_MAXIMUM_MONTHLY_USAGE,
  SHARE_EVY_AI_URL,
  SUBSCRIPTIONS_FIELDS,
  SUBSCRIPTIONS_INTERVALS,
  SUBSCRIPTIONS_NAMES,
  SubscriptionStatus,
  SubscriptionStatusDB,
} from "../../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "./auth.types";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { Navigate_to } from "../../router/routes.constants";
import LodingPageLoader from "../../components/LodingPageLoader";
import { SubScriptionStaus_Type, SubscriptionType } from "./types";
import { API_URL, MESSAGE_ACTION } from "../../config/appConfig";
import { sendMessageToExtensionPages } from "../../utils/sendMessageToExtention";
import {
  getDownloadURL,
  getStorage,
  uploadBytes,
  ref,
  deleteObject,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { IGeneratedIdea } from "../../pages/IdeaGenerator";
import {TEMPLATE_LIMIT} from "../../constant";
import { sentryClient } from "@/Sentry/sentry";

const firebaseApp = initializeApp(FIREBASECONFIG);

export const firebaseAuth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firebaseDb = getFirestore(firebaseApp);

export const functionInstance = getFunctions(firebaseApp);

try {
  if(!getFirestore(firebaseApp)) {
    initializeFirestore(firebaseApp,
      {
        localCache:
        persistentLocalCache(/*settings*/{ tabManager: persistentMultipleTabManager() }),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED
      });
    } 
} catch (error) {
  console.error(error);
  sentryClient.captureException(error)
}

enableIndexedDbPersistence(firebaseDb, {
  forceOwnership: false,
}).catch((error) => {
  switch (error.code) {
    case "failed-precondition":
      break;
    case "unimplemented":
      break;
    default:
      console.error(error);
  }
  sentryClient.captureException(error)
});

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");

function AuthContext({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailToChange, setEmailToChange] = useState("");
  const [isNavigateToSub, setIsNavigateToSub] = useState(false);
  const [isTeamInviteUrl, setIsTeamInviteUrl] = useState(false);
  const [teamInviteAcceptParams, setTeamInviteAcceptParams] = useState<{ team_id: string, member_id: string }>()
  const [isStripeLinkGenerating, setIsStripeLinkGenerating] = useState<
    string | null
  >(null);
  const [isMSubcribeLinkGenerating, setIsMSubcribeLinkGenerating] =
    useState<boolean>(false);

  const [subscriptionData, setSubscriptionData] = useState<SubscriptionType>();
  const [lastRecentActivitySnapshot, setLastRecentActivitySnapshot] =
    useState<DocumentSnapshot | null>(null);
  const [lastSavedCommandSnapshot, setLastSavedCommandSnapshot] =
    useState<DocumentSnapshot | null>(null);
  const [teamsInviteSnapshot, setTeamsInviteSnapshot] =
    useState<CollectionReference | null>(null);
  const [userSnapShot, setUserSnapShot] = useState<DocumentData | null>(null);
  const [userDataSnapShot, setUserDataSnapShot] = useState<CollectionReference | null>(null);
  const [userDocListener, setUserDocListener] = useState<Unsubscribe | null>(null);
  // const [userDataSnapShot, setUserDataSnapShot] = useState<DocumentReference<DocumentData> | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [teamInvites, setTeamInvites] = useState<any[]>([])
  const [teamInvitesFetched, setTeamInvitesFetched] = useState(false);
  // const [newTeamInvite, setNewTeamInvite] = useState<any>()
  const [verificationTime, setVerificationTime] = useState<Timestamp | null>(null);
  const [userFetched, setUserFetched] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubScriptionStaus_Type>();
  const [showModalType,setShowModalType] = useState<Record<string,boolean>>({})
    
  const navigateTo = useNavigate();
  const location = useLocation();
  const showExtAlert = true;
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (teamsInviteSnapshot && teamInvitesFetched) {
      const previousInvites = teamInvites;
      const unsubscribe = onSnapshot(teamsInviteSnapshot, (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          const docId = change.doc.id;
          const docData = change.doc.data();
          // const newInvite = {
          //   ...docData,
          //   id: docId,
          // }
          const isAlreadyThere = previousInvites.some((invite) => invite.teamId === docData.teamId);
          if (currentUser && change.type === "added") {
            // console.log("added");
            await fetchTeamInvites(currentUser.uid)
          } else if (isAlreadyThere && change.type === "removed") {
            // console.log("removed");
            const newInvites = teamInvites.filter((invite) => invite.id !== docId)
            setTeamInvites(newInvites);
          }
          // if (!isAlreadyThere) {
          //   // const newInvite = {
          //   //   ...docData,
          //   //   id: docId,
          //   // }
          //   setTeamInvites([...teamInvites, newInvite]);
          // } else {
          //   const newInvites = teamInvites.map((invite) => {
          //     if (invite.id === docId) {
          //       return docData;
          //     }
          //     return invite;
          //   });
          //   setTeamInvites(newInvites);
          // }
          // if (currentUser && change.type === 'added') {
          //   console.log("added");
          //   // console.log("TEAM INVITE DATA CONTEXT", teamInviteData);
          //   // const newInvite = {
          //   //   ...docData,
          //   //   id: docId,
          //   // }
          //   if (!isAlreadyThere) {
          //     // const newInvite = {
          //     //   ...docData,
          //     //   id: docId,
          //     // }
          //     setTeamInvites([...teamInvites, newInvite]);
          //   }
          //   // setNewTeamInvite(newInvite)
          //   // await fetchTeamInvites(currentUser.uid);
          // } else if (currentUser && change.type === "removed") {
          //   console.log("removed");
          //   if (isAlreadyThere) {
          //     const newInvites = teamInvites.filter((invite) => invite.id !== docId)
          //     setTeamInvites(newInvites);
          //   }
          //   // console.log("TEAM INVITE DATA CONTEXT REMOVED", teamInviteData);
          //   // setNewTeamInvite(null)
          //   // await fetchTeamInvites(currentUser.uid);
          // }
        });
      });

      return () => {
        unsubscribe();
      };

    }
  }, [teamsInviteSnapshot, teamInvitesFetched]);

  useEffect(()=>{
    const userId = firebaseAuth.currentUser?.uid
    if(!userId) return;
    const c = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.SUBSCRIPTIONS}`);
    const unSub = onSnapshot(c,async (querySnapshot) => {
      await processSubscriptionStatusSnapShot(querySnapshot, userId);
    });

    return() => {
      unSub()
    }
  },[firebaseAuth.currentUser])

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    const getChangeInDoc = async (user: IUser | null) => {
      if (!user) {
        if (userDocListener) {
          userDocListener();
          setUserDocListener(null);
        }
        return;
      }
      if (user && !userDocListener) {
        let userr = user;
        const currentUserId = userr.userId || userr.uid;
        if (!currentUserId) return;
        try {
          const userDocRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUserId}`);
          unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
            setUserDocListener(() => unsubscribe);
            if (docSnapshot.exists()) {
              const newDocData = docSnapshot.data();
              const newTeams: string[] = newDocData.memberInTeams ? newDocData.memberInTeams : [];
              const previousTeams = userr.memberInTeams ? userr.memberInTeams : [];
              // console.log("NEW TEAMS", newTeams);
              // console.log("PREVIOUS TEAMS", previousTeams);
              if (newTeams === undefined && previousTeams === undefined) {
                await getSubscriptionStatus()
              } else if (previousTeams !== undefined && (newTeams.length === previousTeams.length)) {
                if (newTeams.length !== 0 && previousTeams.length !== 0) {
                  const isSame = newTeams.every((id: string) => previousTeams.includes(id))
                  if (!isSame) {
                    await getSubscriptionStatus()
                  } else {
                  }
                }
              }
              if (previousTeams !== undefined) {
                if (newTeams.length === 0) {
                  await getSubscriptionStatus();
                } else if (newTeams.length > 0 && (previousTeams.length < newTeams.length)) {
                  const newAddedTeams = newTeams.filter((teamId: string) => !previousTeams.includes(teamId));
                  await getSubscriptionStatusWithTeamId(newAddedTeams)
                } else if (newTeams.length > 0 && (previousTeams.length > newTeams.length)) {
                  await getSubscriptionStatusWithTeamId(previousTeams)
                }
              } else if (previousTeams === undefined) {
                if (newTeams === undefined) {
                  await getSubscriptionStatus()
                } else if (newTeams.length > 0) {
                  await getSubscriptionStatusWithTeamId(newTeams)
                } else if (newTeams.length <= 0) {
                  await getSubscriptionStatus();
                }
              }
              let willUpdateUser = false;
              if (previousTeams.length !== newTeams.length) {
                willUpdateUser = true;
              } else if ((previousTeams.every((teamId: string) => newTeams.includes(teamId))) && newTeams.every((teamId: string) => previousTeams.includes(teamId))) {
                willUpdateUser = false;
              } else {
                willUpdateUser = true;
              }
              if (willUpdateUser) {
                userr = newDocData as IUser;
                setUser(newDocData as IUser);
              }
              
            }
          });
        } catch (error) {
          console.log("ERROR", error);
          sentryClient.captureException(error)
        }
      }
    }
    getChangeInDoc(user);

    return () => {
      if (!user || !userFetched) {
        if (userDocListener) {
          userDocListener();
          setUserDocListener(null);
        }
        if (unsubscribe) {
          unsubscribe()
          unsubscribe = null;
        }
      }
    };
  }, [userFetched]);

  useEffect(() => {
    if (!userFetched) {
      if (user && subscriptionStatus) {
        if (!userDocListener) {
          if (teamInvites.length > 0) {
            setUserFetched(true);
          } else if (user.memberInTeams !== undefined && user.memberInTeams.length > 0) {
            // console.log("member in teams", user.memberInTeams);
            setUserFetched(true);
          }
        }
      } else if (!user) {
        setUserFetched(false);
      }
    } else {
      if (user && user.memberInTeams !== undefined && user.memberInTeams.length > 0) {
        !userDocListener && setUserFetched(true);
      } else if (!user) {
        setUserFetched(false);
      }
    }
  }, [user, subscriptionStatus, teamInvites])

  const getCurrentUser = () => {
    return firebaseAuth.currentUser;
  };

  const InitializeUser = () => {
    firebaseAuth.onAuthStateChanged(async (user: User | null) => {
      if (user?.emailVerified) {
        setEmailVerified(true);
      }
      if (user?.uid) {
        getSubscriptionStatus();
        const userData = await fetchCurrentUserData(user?.uid);
        if (userData && userData.lastEmailSentVerification) {
          setVerificationTime(userData.lastEmailSentVerification);
        }
      }

      setIsLoading(false);
      firebaseAuth.updateCurrentUser(user);
      setEmailToChange("");
    });
  };
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] =
    useState<boolean>(false);

  const updateTimeOfLastEmailSent = async (time: Timestamp, userId: string) => {
    try {
      const userRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`);
      await updateDoc(userRef, {
        lastEmailSentVerification: time
      });
    } catch (error) {
      console.log("error setting verification email last sent time", error);
      sentryClient.captureException(error)
    }
  };

  const sendVerificationEmail = async () => {
    try {
      const currentUser = getCurrentUser();
      setIsSendingVerificationEmail(true);
      await sendEmailVerification(firebaseAuth.currentUser as User);
      setIsSendingVerificationEmail(false);
      const date = new Date();
      const serverTimestampValue = Timestamp.fromDate(date);
      setVerificationTime(serverTimestampValue);
      if (currentUser) {
        await updateTimeOfLastEmailSent(serverTimestampValue, currentUser.uid);
      }
    } catch (error) {
      console.log("error sending verification email", error);
      setIsSendingVerificationEmail(false);
      sentryClient.captureException(error)
      throw error;
    }
  };

  // function dateFormatterContext(date: Date): string {
  //   const options: Intl.DateTimeFormatOptions = {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit'
  //   };
  //   return date.toLocaleDateString(undefined, options);
  // }

  useEffect(() => {

    let unsubscribe: any = null;

    if (user && userDataSnapShot) {

      const currentUserId = user.userId || user.uid
      const previousSessionId = user.currentSessionId

      unsubscribe = onSnapshot(userDataSnapShot, async (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const userDocId = change.doc.id;
          if (userDocId === currentUserId) {
            const newDocData: IUser = change.doc.data() as IUser;
            if (change.type === 'modified') {
              // console.log("PREVIOUS DOCUMENT", previousSessionId);
              // console.log("NEW DOCUMENT", newDocData?.currentSessionId);

              if (newDocData && newDocData.currentSessionId !== previousSessionId) {
                await signOut();
              }
            }
          }
          // const existingSessionId = localStorage.getItem("C_S_ID");
          // const existingUserId = localStorage.getItem("C_U_");
          // const { currentSessionId } = user;
          // if (!existingSessionId && !existingUserId) {
          //   signOut();
          //   // if (existingUserId === user.userId) {
          //   // }
          // } 
          // if (existingSessionId && existingUserId) {
          //   if (existingUserId === user.userId) {
          //     (existingSessionId !== currentSessionId) && signOut();
          //   }
          // } 

        })
        // if (snapshot.exists) {
        //   const user: IUser = snapshot.data() as IUser;
        //   const existingSessionId = localStorage.getItem("C_S_ID");
        //   const existingUserId = localStorage.getItem("C_U_");
        //   const { currentSessionId } = user;
        //   if (!existingSessionId && !existingUserId) {
        //     signOut();
        //     // if (existingUserId === user.userId) {
        //     // }
        //   } 
        //   if (existingSessionId && existingUserId) {
        //     if (existingUserId === user.userId) {
        //       (existingSessionId !== currentSessionId) && signOut();
        //     }
        //   } 
        // } else {
        //   console.log('Document does not exist');
        // }
      });

      return () => {
        if (unsubscribe) {
          unsubscribe()
        }
      };
    }

  }, [userDataSnapShot, user]);

  // useEffect(() => {

  //   const handleStorageChange = async (event: StorageEvent) => {

  //     const user = getCurrentUser();
  //     let currentUser: IUser | null | undefined;
  //     if (user) {
  //       currentUser = await fetchUserDataById(user.uid)
  //     }
  //     if (event.key === 'C_S_ID') {
  //       if (event.oldValue) {
  //         if (currentUser) {
  //           if (event.oldValue === currentUser.currentSessionId) {
  //             saveCurrentSessionId(currentUser.userId)
  //           } else {
  //             localStorage.setItem(event.key, event.oldValue)
  //           }
  //         }
  //       }
  //     } else if (event.key === 'C_U_') {
  //       if (event.oldValue) {
  //         if (currentUser) {
  //           if (event.oldValue === currentUser.userId) {
  //             saveCurrentUserId(currentUser.userId)
  //           } else {
  //             localStorage.setItem(event.key, event.oldValue)
  //           }
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  const signInWithGoogle = async () => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);

      if (result.user.uid) {
        setIsLoading(true);
        await fetchCurrentUserData(result.user.uid);
        // if (userData) {
        //   saveCurrentSessionId(userData.userId)
        // }
      }

      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;

      setIsLoading(false);

      return {
        user,
        token,
      };
    } catch (error: any) {
      let errorCode, errorMessage, email, credential;

      if (error.code === 'auth/internal-error') {
        const message = error.message;
        const errorReason = message.match(/{.*}/g);
        const errorObj = JSON.parse(errorReason?.[0] || "{}");
        errorMessage = errorObj.error.message;
      } else {
        errorMessage = error.message;
      }

      if (error.customData) {
        email = error.customData.email;
      }

      if (error.code) {
        errorCode = error.code;
      }

      if (error.credential) {
        credential = GoogleAuthProvider.credentialFromError(error);
      }

      setIsLoading(false);

      console.log(`Error occurred during Google SignIn: ${errorMessage}`);
      sentryClient.captureException(error)
      return {
        errorCode,
        errorMessage,
        email,
        credential,
      };
    }
  };

  // const saveCurrentSessionId = async (userId: string) => {

  //   const currentUser = await fetchUserDataById(userId)
  //   if (currentUser) {
  //     const { currentSessionId } = currentUser;
  //     // localStorage.setItem("C_S_ID", currentSessionId + "_" + currentUser.userId)
  //     localStorage.setItem("C_S_ID", currentSessionId)
  //     localStorage.setItem("C_U_", currentUser.userId)

  //   }
  // };

  // const saveCurrentUserId = async (userId: string) => {
  //   const currentUser = await fetchUserDataById(userId)
  //   if (currentUser) {
  //   localStorage.setItem("C_U_", currentUser.userId)
  //   }
  // };

  const sendEmailToResetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(firebaseAuth, email)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          let errorMessage = error.message;
          if (error.code === "auth/user-not-found") {
            errorMessage =
              "Email not found. Please register or use a different email.";
          } else if (error.code === "auth/invalid-email") {
            errorMessage = "That email address is invalid!";
          }
          sentryClient.captureException(error)
          reject(new Error(errorMessage));
        });
    });
  };

  const getCurrentUserIdToken = () => {
    return firebaseAuth.currentUser?.getIdToken();
  };

  const reloadCurrentUser = async () => {
    return firebaseAuth.currentUser?.reload();
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password).then(
        async (data) => {
          if (data.user.uid) {
            setIsLoading(true);
            await fetchCurrentUserData(data.user.uid);
            // if (userData) {
            //   saveCurrentSessionId(userData.userId)
            // }
          }
        }
      );
      return { success: true };
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.code === "auth/invalid-login-credentials") {
        errorMessage =
          "Invalid credentials. Please check your email and password";
      } else if (error.code === "auth/invalid-email") {
        errorMessage =
          "That email address is invalid! Please use different email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Wrong Password!";
      } else if (error.code === "auth/internal-error") {
        const message = error.message;
        // get the error JSON object from string: FirebaseError: Firebase: HTTP cloud function return an error: { "error": { "message": "error message" } }
        const errorReason = message.match(/{.*}/g);

        const errorObj = JSON.parse(errorReason?.[0] || "{}");

        errorMessage = errorObj.error.message;
      }
      // console.error(error);
      sentryClient.captureException(error)
      return {
        success: false,
        message: errorMessage,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const signUpWithEmail = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    linkedInAccount: string
  ) => {
    try {
      const data = await createUserWithEmailAndPassword(firebaseAuth, email, password);

      const date = new Date();
      const serverTimestampValue = Timestamp.fromDate(date);
      const referralId = (window as any).Rewardful?.referral;
      const dataToSet: any = {
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        linkedin_url: linkedInAccount,
        welcome_tour: false,
        lastEmailSentVerification: serverTimestampValue,
        rewardful_referral_code: referralId === undefined || referralId === null ? null : referralId
      };

      const userId = data.user.uid;
      await setDoc(
        doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`),
        dataToSet,
        {
          merge: true,
        }
      );
      await firebaseAuth.currentUser?.reload();
      await sendVerificationEmail();
      navigateTo(Navigate_to.emailVerificationPending);

      return true;
    } catch (error: any) {
      let errorMessage = error.message;
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "That email address is already in use!";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "That email address is invalid!";
      } else if (error.code === "auth/internal-error") {
        const message = error.message;
        // get the error JSON object from string: FirebaseError: Firebase: HTTP cloud function return an error: { "error": { "message": "error message" } }
        const errorReason = message.match(/{.*}/g);

        const errorObj = JSON.parse(errorReason?.[0] || "{}");

        errorMessage = errorObj.error.message;
      }
      sentryClient.captureException(error)
      throw errorMessage;
    }
  };


  // const signUpWithEmail = (
  //   email: string,
  //   password: string,
  //   firstName: string,
  //   lastName: string,
  //   phoneNumber: string,
  //   linkedInAccount: string
  // ) => {
  //   return new Promise((resolve, reject) => {
  //     createUserWithEmailAndPassword(firebaseAuth, email, password)
  //       .then(async (data) => {
  //         const dataToSet = {
  //           email,
  //           first_name: firstName,
  //           last_name: lastName,
  //           phone_number: phoneNumber,
  //           linkedin_url: linkedInAccount,
  //           welcome_tour: false,
  //         };
  //         try {
  //           //add this code
  //           const userId = data.user.uid;
  //           await setDoc(
  //             doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`),
  //             dataToSet,
  //             {
  //               merge: true,
  //             }
  //           );
  //           await firebaseAuth.currentUser?.reload();
  //           await sendVerificationEmail();
  //           navigateTo(Navigate_to.emailVerificationPending);
  //         } catch (error) {
  //         } finally {
  //           resolve(true);
  //         }
  //       })
  //       .catch((error) => {
  //         let errorMessage = error.message;
  //         if (error.code === "auth/email-already-in-use") {
  //           errorMessage = "That email address is already in use!";
  //         } else if (error.code === "auth/invalid-email") {
  //           errorMessage = "That email address is invalid!";
  //         }
  //         reject(errorMessage);
  //       });
  //   });
  // };

  const updateUserProfile = async (
    userId?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
    linkedInAccount?: string,
    twitterAccount?: string,
    profilePictureUrl?: string,
  ) => {
    try {
      const firestore = getFirestore();
      const userRef = doc(firestore, `${COLLECTIONS.CUSTOMERS}/${userId}`);

      const functionRef = httpsCallable(
        functionInstance,
        "updateMemberNameInPendingInvitesAndTeamMembers"
      );
      
      const newData = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        linkedin_url: linkedInAccount,
        twitter_url: twitterAccount,
        profile_picture_url: profilePictureUrl
      };
      
      await updateDoc(userRef, newData);

      const response: any = await functionRef({firstName: newData.first_name, lastName: newData.last_name, photoUrl: newData.profile_picture_url});
      
      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }
      
      return true;
    } catch (error) {
      sentryClient.captureException(error)
      return false;
    }
  };

  const fetchUserDataById = async (id: string) => {
    try {
      const docRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${id}`);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data() as IUser;
      }

      return null;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const fetchUserDataByEmail = async (email: string) => {
    try {
      const currentUser = getCurrentUser();
      const firebaseIdToken = await getCurrentUserIdToken();
      if (currentUser) {
        const functionRef = httpsCallable(
          functionInstance,
          "getUserByEmail_onCall"
        );
        const response: any = await functionRef({ data: { email }, auth: firebaseIdToken });
        if (response && response.data.success) {
          const userData = await fetchUserDataById(response.data.userId);
          return userData as IUser;
        } else if (response.data.success === false) {
          return null;
        }
      } else {
        console.log("user not found");
        return null;
      }

      // const userRef = query(
      //   collection(
      //     firebaseDb,
      //     `${COLLECTIONS.CUSTOMERS}/`
      //   ),
      //   where("email", "==", email),
      //   limit(1)
      // );
      // const docSnapshot = await getDocs(userRef);

      // if (!docSnapshot.empty) {
      //   return docSnapshot.docs[0].data() as IUser;
      // }
      // return null;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const getCustomToken = async () => {
    const functionRef = httpsCallable(
      functionInstance,
      "getFirebaseCustomToken"
    );
    const response: any = await functionRef();
    const customToken = response?.data?.customToken;
    if (customToken) {
      await sendMessageToExtensionPages(
        MESSAGE_ACTION.GET_WEB_APP_AUTH_DETAILS,
        {
          customToken: customToken,
        }
      );
    }
  };

  const updateWelcomeTourStatus = async (id: string) => {
    try {
      const docRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${id}`);
      await updateDoc(docRef, {
        welcome_tour: true,
      });

      return null;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const updateTeamAcceptedNotificationStatus = async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        const docRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUser.uid}`);
        await updateDoc(docRef, {
          invitesAccepted: [],
        });
      }
      return null;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const updateTeamSize = async (id: string) => {
    try {
      if (subscriptionStatus && subscriptionStatus.hasTeamsPlan) {
        const newTeamSize = subscriptionStatus.allowedTeamMembers && subscriptionStatus.allowedTeamMembers + 1;
        const docRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${id}`);
        await updateDoc(docRef, {
          team_size: newTeamSize,
        });
        await fetchCurrentUserData(id)
        return null;
      }
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const handleExtensionMessages = async () => {
    console.log(
      "################ queryParams.get",
      queryParams.get("is_ext") == "login"
    );
    if (queryParams.get("is_ext") == "login") {
      await localStorage.setItem(STORAGE_KEYS.IS_EXTENSION_LOGIN, "true");
      getCustomToken();
    }
    const is_extension_login = localStorage.getItem(
      STORAGE_KEYS.IS_EXTENSION_LOGIN
    );
    if (is_extension_login === "true") {
      getCustomToken();
      if (showExtAlert) {
        toastAlertSuccess("Extension Sign-in successful");
        localStorage.setItem(STORAGE_KEYS.IS_EXTENSION_LOGIN, "false");
      }
    }
  };

  const fetchTeamInvites = async (userId: string) => {
    try {
      const teamInvitesCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.TEAM_INVITES}`);
      const teamInvitesSnapshot = await getDocs(teamInvitesCollectionRef);
      const teamInvitesDB = teamInvitesSnapshot.docs.map((invite) => ({ id: invite.id, ...invite.data() }))
      if (user?.memberInTeams && user.memberInTeams.length > 0) {
        const memberInTeams = user.memberInTeams;
        const filteredTeamInvites = teamInvitesDB.filter((invite: any) => {
          const check =  teamInvites.some((teamInvite: any) => teamInvite.id !== invite.id) && !memberInTeams.includes(invite.teamId)
          return check;
        });
        setTeamInvites(filteredTeamInvites);
      } else {
        setTeamInvites(teamInvitesDB);
      }
      console.log("team invites", teamInvitesDB);
      setTeamInvitesFetched(true);
      return teamInvitesDB;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const acceptTeamInvite = async (teamId: string, inviteId: string) => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const teamInvitesCollectionRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUser.uid}/${COLLECTIONS.TEAM_INVITES}/${inviteId}`);
        const teamInvitesSnapshot = await getDoc(teamInvitesCollectionRef);
        if (!teamInvitesSnapshot.exists()) {
          throw { inviteDeleted: true, message: "Invite deleted by Team Administrator!"}
        };
      }
        
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(
        functionInstance,
        "acceptTeamInvite"
      );
      const response: any = await functionRef({
        teamId,
        uid: currentUser?.uid,
      });
      if (response.data.success) {
        const newTeamInvites = teamInvites.filter((invite: any) => invite.teamId !== teamId);
        setTeamInvites(newTeamInvites);
        getSubscriptionStatus();
        return response;
      } else {
        throw Error(response.data.code);
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      if (error.inviteDeleted) {
        const newTeamInvites = teamInvites.filter((invite: any) => invite.teamId !== teamId);
        setTeamInvites(newTeamInvites);
        throw error;
      } else {
        throw new Error(error as string);
      }
    }
  };

  const declineTeamInvite = async (teamId: string, inviteId: string) => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const teamInvitesCollectionRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUser.uid}/${COLLECTIONS.TEAM_INVITES}/${inviteId}`);
        const invitedMemberDocRef = query(
          collection(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`),
          where("email", "==", currentUser.email),
          limit(1)
        );
        
        const teamInvitesSnapshot = await getDoc(teamInvitesCollectionRef);
        const invitedMemberSnapshot = await getDocs(invitedMemberDocRef);

        if (!teamInvitesSnapshot.exists()) {
          throw { inviteDeleted: true, message: "Invite already deleted by Team Administrator!"}
        };
        
        const teamInviteData = teamInvitesSnapshot.data();
        const invitedMemberData = !invitedMemberSnapshot.empty ? invitedMemberSnapshot.docs[0].data() : null;
        const invitedMemberRef = !invitedMemberSnapshot.empty ? invitedMemberSnapshot.docs[0].ref : null;
        
        
        const teamInviteId = teamInvitesSnapshot.id;
        const invitedMemberEmail = invitedMemberData && (invitedMemberData.email as string);
        
        if ((teamInviteId === inviteId) && (teamInviteData?.teamId === teamId)) {
          await deleteDoc(teamInvitesSnapshot.ref)
        }
        if (invitedMemberRef && currentUser.email === invitedMemberEmail) {
          await deleteDoc(invitedMemberRef);
        }
        const newTeamInvites = teamInvites.filter((invite: any) => invite.teamId !== teamId);
        setTeamInvites(newTeamInvites);
        await fetchTeamInvites(currentUser.uid)
        return
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      if (error.inviteDeleted) {
        const newTeamInvites = teamInvites.filter((invite: any) => invite.teamId !== teamId);
        setTeamInvites(newTeamInvites);
        throw error;
      } else {
        throw new Error(error as string);
      }
    }
  };

  const deleteUserFromTeam = async (userIdOrg: string, teamId: string) => {
    try {
      let userId = userIdOrg;
      // let userId = "XxgEyrbFB8ZP1uMXgFIl0lEQlVm1";
      // console.log("user ID", userId);

      const currentUser = getCurrentUser();
      let userData: IUser | undefined | null = null;
      const userRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userDocRef = userSnapshot.ref;
        userData = (userSnapshot.data() as IUser);
        if (userData.memberInTeams) {
          // console.log("existing teams ids", userData.memberInTeams);
          const newTeams = userData.memberInTeams.filter((id: string) => id !== teamId)
          await updateDoc(userDocRef, {
            ...userData,
            memberInTeams: newTeams,
          })
        }
      }
      // if (userData?.email === "muhammadahmd1234@gmail.com") {
      //   userId = "XxgEyrbFB8ZP1uMXgFIl0lEQlVm1";
      // }
      if (currentUser && userData) {
        const memberRef = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`
          ),
          where("email", "==", userData.email),
          // limit(1)
        );
        const teamInvitesCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.TEAM_INVITES}`);
        const teamInvitesSnapshot = await getDocs(teamInvitesCollectionRef)
        // for (const teamInviteDoc of teamInvitesSnapshot.docs) {
        //     await deleteDoc(teamInviteDoc.ref)
        // }

        const querySnapshot = await getDocs(memberRef);
        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (doc) => {
            const docData = doc.data();
            const docRef = doc.ref;
            if (docData.status === "invite_sent") {
              for (const teamInviteDoc of teamInvitesSnapshot.docs) {
                if (teamInviteDoc.data().teamId === teamId) {
                  await deleteDoc(teamInviteDoc.ref)
                }
              }
            }
            await deleteDoc(docRef)
            // await updateDoc(docRef, {
            //   ...doc.data(),
            //   joinedDate: serverTimestamp(),
            //   status: "joined",
            // });
          });
        }
        const teamDocRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}`);
        const docSnapshot = await getDoc(teamDocRef);
        if (docSnapshot.exists()) {
          const teamMemberData = docSnapshot.data();
          // const newMembersIds = teamMemberData.membersIds.filter((memberId: string) => memberId !== userId)
          const newMembersEmails = teamMemberData.membersEmails.filter((memberEmail: string) => userData && (memberEmail !== userData.email))
          const newActiveMembersIds = teamMemberData.activeMembersIds.filter((memberId: string) => memberId !== userId)
          await updateDoc(teamDocRef, {
            ...teamMemberData,
            membersEmails: newMembersEmails,
            // membersIds: newMembersIds,
            activeMembersIds: newActiveMembersIds,
          });
        }
        // const teamInvitesCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUser.uid}/${COLLECTIONS.TEAM_INVITES}`);
        // const teamInvitesSnapshot = await getDocs(teamInvitesCollectionRef);
        // teamInvitesSnapshot.docs.map((invite) => {
        //   const data = invite.data()
        //   if (data.teamId === teamId) {
        //     deleteDoc(invite.ref)
        //   } else {
        //     return
        //   }
        // })
        // await fetchTeamInvites(currentUser.uid)
        return
      }
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const fetchCurrentUserData = async (id: string) => {
    try {
      setIsLoadingUser(true);
      const docRef: DocumentReference<DocumentData> = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${id}`);
      const customersCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}`);

      // setUserDataSnapShot(docRef);
      setUserDataSnapShot(customersCollectionRef);

      const docSnapshot = await getDoc(docRef);

      const teamInvitesCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${id}/${COLLECTIONS.TEAM_INVITES}`);
      // const teamInvitesSnapshot = await getDocs(teamInvitesCollectionRef);
      // teamInvitesSnapshot.docs.map((doc) => deleteDoc(doc.ref))

      setTeamsInviteSnapshot(teamInvitesCollectionRef)

      const invites = await fetchTeamInvites(id)
      console.log("invites from user function", invites);


      if (docSnapshot.exists()) {

        const referralId = (window as any).Rewardful?.referral;
        const db_stored_referralID = docSnapshot.data()?.rewardful_referral_code;
        const updatedReferralId = db_stored_referralID === referralId ? db_stored_referralID : referralId;

        await updateDoc(docRef, {
          ...docSnapshot.data(),
          last_active_time: serverTimestamp(),
          rewardful_referral_code: updatedReferralId || null
        })

        const currentUser = docSnapshot.data() as IUser;

        if (currentUser) {
          setUser(currentUser);
          if (window.location.pathname !== "/logout") {
            getCustomToken();
          }
        } else {
          setUser(null);
        }
        return docSnapshot.data() as IUser;
      }
      return null;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    } finally {
      setIsLoadingUser(false);
    }
  };

  async function getPersonas(
    sortBy: string = FIELDS.TIMESTAMP,
    order: any = "desc"
  ) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const listsRef = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.PERSONAS}/${currentUser.uid}/${COLLECTIONS.PERSONAS}`
          ),
          orderBy(sortBy, order)
        );

        const querySnapshot = await getDocs(listsRef);
        const personasArr: any[] = [];
        querySnapshot.forEach((doc) => {
          personasArr.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        return personasArr;
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  const getRecentActivities = async (
    limitOfRecords: number = 3,
    willFetchFromBeginning: boolean = true
  ) => {
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId) return [];
    try {
      let queryToGetDocs = null;
      if (willFetchFromBeginning || !lastRecentActivitySnapshot) {
        setLastRecentActivitySnapshot(null);
        queryToGetDocs = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.ACTIVITIES}`
          ),
          orderBy(FIELDS.TIMESTAMP, "desc"),
          limit(limitOfRecords)
        );
      } else {
        queryToGetDocs = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.ACTIVITIES}`
          ),
          orderBy(FIELDS.TIMESTAMP, "desc"),
          startAfter(lastRecentActivitySnapshot),
          limit(limitOfRecords)
        );
      }
      const recentActivitiesSnapshot = await getDocs(queryToGetDocs);
      if (recentActivitiesSnapshot.docs.length > 0) {
        setLastRecentActivitySnapshot(
          recentActivitiesSnapshot.docs[
          recentActivitiesSnapshot.docs.length - 1
          ]
        );
      }
      const data = await Promise.all(
        recentActivitiesSnapshot.docs.map(async (document) => {
          const savedCommandRef = doc(
            firebaseDb,
            `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}/${document.id}`
          );
          const docSnap = await getDoc(savedCommandRef);
          const isSaved = docSnap.exists();

          return {
            id: document.id,
            isSaved,
            ...document.data(),
          };
        })
      );

      return data;
    } catch (error: any) {
      return [];
    }
  };

  async function deleteImageFromStorage(imageUrl: string) {
    try {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    } catch (error) {
      console.error("Error deleting image:", error);
      sentryClient.captureException(error)
      throw error;
    }
  }

  const getSavedCommands = async (
    limitOfRecords: number = 3,
    willFetchFromBeginning: boolean = true
  ) => {
    const userId = firebaseAuth.currentUser?.uid;

    let totalCount = 0;
    if (!userId) return [];
    try {
      let queryToGetDocs = null;

      const q = query(
        collection(
          firebaseDb,
          `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}`
        ),
        orderBy(FIELDS.TIMESTAMP, "desc"),
        limit(limitOfRecords)
      );

      const snapshot = await getCountFromServer(q);

      totalCount = snapshot.data().count;
      if (willFetchFromBeginning || !lastSavedCommandSnapshot) {
        setLastSavedCommandSnapshot(null);
        queryToGetDocs = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}`
          ),
          orderBy(FIELDS.TIMESTAMP, "desc"),
          limit(limitOfRecords)
        );
      } else {
        queryToGetDocs = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}`
          ),
          orderBy(FIELDS.TIMESTAMP, "desc"),
          startAfter(lastSavedCommandSnapshot),
          limit(limitOfRecords)
        );
      }
      const savedCommandsSnapshot = await getDocs(queryToGetDocs);
      if (savedCommandsSnapshot.docs.length > 0) {
        setLastSavedCommandSnapshot(
          savedCommandsSnapshot.docs[savedCommandsSnapshot.docs.length - 1]
        );
      }
      const data: any = [];
      savedCommandsSnapshot.docs.map((doc) =>
        data.push({
          id: doc.id,
          ...doc.data(),
        })
      );

      return {
        data,
        totalCount,
      };
    } catch (error: any) {
      return [];
    }
  };

  async function deleteLinkedinProfile(profileId: string) {
    try {
      const currentUser = getCurrentUser();
      if (user !== null && currentUser?.uid && profileId) {
        await deleteDoc(
          doc(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.PROFILES}`,
            profileId
          )
        ).then((data) => {
          console.log(data, "data");
        });
      }
      console.log(`Profile with ID ${profileId} deleted successfully`);
    } catch (error) {
      console.log(error, "error");
      sentryClient.captureException(error)
      throw error;
    }
  }

  async function createTeam(teamName: string) {
    try {
      // const lowercaseTeamName = teamName.toLowerCase();

      const currentUser = getCurrentUser();

      if (currentUser) {
        const teamsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEAMS}/`
        );
        // const teamsRef = collection(
        //   firebaseDb,
        //   `/${COLLECTIONS.TEAMS}/${currentUser.uid}/${COLLECTIONS.TEAMS}`
        // );

        const querySnapshot1 = await getDocs(query(teamsRef, where("adminId", "==", currentUser.uid)));

        // for (const doc of querySnapshot1.docs) {
        //   const existingTeamName = doc.data().name.toLowerCase();

        //   if (existingTeamName === lowercaseTeamName) {
        //     throw new Error("Team name already exists!");
        //   }
        // }

        if (querySnapshot1.docs.length === 1) {
          // deleteDoc(querySnapshot1.docs[0].ref)
          throw new Error("Already Created Team");
        }

        const newTeam = {
          name: teamName,
          adminId: currentUser.uid,
          // membersIds: [],
          membersEmails: [],
          activeMembersIds: [],
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp(),
        };
        await addDoc(teamsRef, newTeam);
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      throw new Error(error.message);
    }
  }

  async function editTeamName(teamId: string, newTeamName: string) {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const teamRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}`);
        const teamData = await getDoc(teamRef)
        await updateDoc(teamRef, {
          ...teamData.data(),
          name: newTeamName,
          modifiedDate: serverTimestamp(),
        })
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      throw new Error(error.message);
    }
  }

  const fetchMemberById = async (teamId: string, memberId: string): Promise<any> => {

    try {
      const memberDocRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`, memberId);
      const memberDoc = await getDoc(memberDocRef)
      let memberData: any = {};
      const docId = memberDoc.id
      const member = memberDoc.data()
      if (member) {
        const memberUserData: IUser | null | undefined = await fetchUserDataByEmail(member.email)
        memberData = {
          ...member,
          id: docId,
          memberData: memberUserData,
        }
        return memberData;
      }
    } catch (err) {
      sentryClient.captureException(err)
      console.log(err, "err");
    }
  };

  const fetchTeamById = async (teamId: string) => {
    try {
      const docRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}`);
      const data = await getDoc(docRef)
      return { id: data.id, ...data.data() }
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const updateTeamSeats = async (seats: number) => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const docRef = doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${currentUser.uid}`);
        await updateDoc(docRef, {
          total_team_seats: seats,
        });

        await fetchCurrentUserData(currentUser.uid)

        return true;
      }
      return false;
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        console.log("ERROR:", error);
      }
      return false;
    }
  };

  async function addMembersToTeam(teamId: string, memberEmails: string[]) {
    try {

      const url = "https://invitememberstoteamviaemails-ivkhmniq2a-uc.a.run.app";
      const firebaseIdToken = await getCurrentUserIdToken();
      const requestBody = { teamId: teamId, emails: memberEmails }
      console.log("request body: ", requestBody);

      const response = await fetch(url, {
        method: "POST",
        // mode: 'no-cors',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
          pragma: "no-cache",
          "cache-control": "no-cache",
          authorization: `Bearer ${firebaseIdToken}`,
        },
        cache: "no-cache",
        redirect: "follow",
        body: JSON.stringify({ teamId, emails: memberEmails }),
      });
      console.log(response);

      // const currentUser = getCurrentUser();

      // let membersArr: any[] = [];
      // let membersIdsArr: any[] = [];

      // for (const email of memberEmails) {
      //   // if (email === "muhammadahmd1234@gmail.com") {
      //   //   const memberData: any = await fetchUserDataById("XxgEyrbFB8ZP1uMXgFIl0lEQlVm1");
      //   //   if (memberData) {
      //   //     const memberDataUserId = (memberData.uid && memberData.uid) || (memberData.userId && memberData.userId);
      //   //     const newData = {id: memberDataUserId, memberData}
      //   //     membersArr.push(memberData);
      //   //     membersIdsArr.push(newData);
      //   //     // membersIdsArr.push(memberData.userId || memberData.uid);
      //   //   }
      //   // } else {
      //   const memberData: any = await fetchUserDataByEmail(email);
      //   if (memberData) {
      //     const memberDataUserId = (memberData.uid && memberData.uid) || (memberData.userId && memberData.userId);
      //     const newData = { id: memberDataUserId, memberData }
      //     membersArr.push(memberData);
      //     membersIdsArr.push(newData);
      //     // membersIdsArr.push(memberData.userId || memberData.uid);
      //   }
      //   // }
      // }

      // if (currentUser) {

      //   const membersRef = collection(
      //     firebaseDb,
      //     `/${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`
      //   );

      //   const memebersSnapshot = await getDocs(membersRef);

      //   const docRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}`);
      //   const querySnapshot1 = await getDoc(docRef)

      //   const teamData = querySnapshot1.data();
      //   const teamRef = doc(firebaseDb, `${COLLECTIONS.TEAMS}/`, teamId);


      //   // if (teamData) {
      //   //   const newMembersIds = [...teamData.membersIds, ...membersIdsArr]
      //   //   await updateDoc(teamRef, {
      //   //     ...teamData,
      //   //     membersIds: newMembersIds,
      //   //     modifiedDate: serverTimestamp(),
      //   //   });
      //   // }

      //   // for (const memberId of membersIdsArr) {
      //   //   const membId = memberId.id;
      //   //   const membData = memberId.memberData;

      //   //   if (teamData) {
      //   //     if (!teamData.membersEmails.includes(membData.email)) {
      //   //       const newMembersEmails = [...teamData.membersEmails, membData.email]
      //   //       await updateDoc(teamRef, {
      //   //         ...teamData,
      //   //         // membersIds: newMembersIds,
      //   //         membersEmails: newMembersEmails,
      //   //         modifiedDate: serverTimestamp(),
      //   //       });
      //   //     }
      //   //     // if (!teamData.membersIds.includes(membId)) {
      //   //     //   const newMembersIds = [...teamData.membersIds, membId]
      //   //     //     else {
      //   //     //       await updateDoc(teamRef, {
      //   //     //         ...teamData,
      //   //     //         membersIds: newMembersIds,
      //   //     //         modifiedDate: serverTimestamp(),
      //   //     //       });
      //   //     //       }
      //   //     //   }
      //   //   }
      //   //   if (!memebersSnapshot.empty) {
      //   //     const isAlreadyInvited = memebersSnapshot.docs.some((doc) => {
      //   //       if (doc.data().email === membData.email) {
      //   //         return true;
      //   //       }
      //   //       return false;
      //   //     })
      //   //     if (!isAlreadyInvited) {
      //   //       await addDoc(membersRef, {
      //   //         email: membData.email,
      //   //         // userId: memberId.id,
      //   //         status: 'invite_sent',
      //   //         inviteSentDate: serverTimestamp(),
      //   //         joinedDate: null
      //   //       });
      //   //     } else {
      //   //       for (const doc of memebersSnapshot.docs) {
      //   //         if (doc.data().email === membData.email) {
      //   //           await deleteDoc(doc.ref)
      //   //         }
      //   //       }
      //   //       await addDoc(membersRef, {
      //   //         email: membData.email,
      //   //         // userId: memberId.id,
      //   //         status: 'invite_sent',
      //   //         inviteSentDate: serverTimestamp(),
      //   //         joinedDate: null
      //   //       });
      //   //     }
      //   //   } else {
      //   //     await addDoc(membersRef, {
      //   //       email: membData.email,
      //   //       // userId: memberId.id,
      //   //       status: 'invite_sent',
      //   //       inviteSentDate: serverTimestamp(),
      //   //       joinedDate: null
      //   //     });
      //   //   }

      //   //   const teamInvitesCollectionRef = collection(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${membId}/${COLLECTIONS.TEAM_INVITES}`);
      //   //   const teamInvitesSnapshot = await getDocs(teamInvitesCollectionRef)
      //   //   if (!teamInvitesSnapshot.empty) {
      //   //     for (const doc of teamInvitesSnapshot.docs) {
      //   //       if (doc.data().teamId === teamId) {
      //   //         await deleteDoc(doc.ref)
      //   //       }
      //   //     }
      //   //     await addDoc(teamInvitesCollectionRef, {
      //   //       teamId: teamId,
      //   //       inviteAccepted: false
      //   //     });
      //   //   } else {
      //   //     await addDoc(teamInvitesCollectionRef, {
      //   //       teamId: teamId,
      //   //       inviteAccepted: false
      //   //     });
      //   //   }
      //   // }
      //   return null;
      // }
    } catch (error) {
      if (error) {
        sentryClient.captureException(error)
        throw new Error(error as string);
      }
    }
  };

  const fetchInviteStatus = async (teamId: string) => {
    try {
      const membersRef = collection(firebaseDb, `${COLLECTIONS.TEAMS}/${teamId}/${COLLECTIONS.MEMBERS}`);
      const teamDocRef = doc(firebaseDb, COLLECTIONS.TEAMS, teamId);
      const teamDocSnapshot = await getDoc(teamDocRef);

      if (teamDocSnapshot.exists()) {
        // const teamData = teamDocSnapshot.data();
        // Do something with teamData if needed

        // Query members collection
        const membersQuerySnapshot = await getDocs(membersRef);

        // Extract member data
        const membersData = membersQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return membersData;
      } else {
        console.log("Team document does not exist");
        return [];
      }
    } catch (error) {
      console.error('Error fetching team members:', error);
      sentryClient.captureException(error)
      throw error;
    }
  }

  async function createTag(tagName: string) {
    try {
      const lowercaseTagName = tagName.toLowerCase().trim();

      const currentUser = getCurrentUser();

      if (currentUser) {
        const tagsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );

        const querySnapshot1 = await getDocs(query(tagsRef));

        for (const doc of querySnapshot1.docs) {
          const existingTagName = doc.data().name.toLowerCase().trim();

          if (existingTagName === lowercaseTagName) {
            throw new Error("Tag name already exists!");
          }
        }

        const newTag = {
          name: tagName,
          modifiedDate: serverTimestamp(),
        };
        const createdTagRef = await addDoc(tagsRef, newTag);
        return createdTagRef.id;
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      throw new Error(error.message);
    }
  }

  async function getTagById(id: string) {
    try {

      const currentUser = getCurrentUser();

      if (currentUser) {

        const TagsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );

        const tagRef = doc(TagsRef, id);

        const tagSnapshot = await getDoc(tagRef);

        if (tagSnapshot.exists()) {
          return { id: tagSnapshot.id, ...tagSnapshot.data() };
        } else {
          throw new Error("Document does not exist.");
        }
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async function createList(listName: string) {
    try {
      const lowercaseListName = listName.toLowerCase();

      const currentUser = getCurrentUser();

      if (currentUser) {
        const listsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`
        );

        const querySnapshot1 = await getDocs(query(listsRef));

        for (const doc of querySnapshot1.docs) {
          const existingListName = doc.data().name.toLowerCase();

          if (existingListName === lowercaseListName) {
            throw new Error("List name already exists!");
          }
        }

        const newList = {
          name: listName,
          modifiedDate: serverTimestamp(),
        };
        await addDoc(listsRef, newList);
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      throw new Error(error.message);
    }
  }

  async function deleteTag(tagId: string) {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const tagRef = doc(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`,
          tagId
        );
        deleteDoc(tagRef);
        const templatesQuerySnapshot = await getDocs(
          query(
            collection(firebaseDb, `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`),
            where("templateTags", "array-contains", tagId)
          )
        );

        templatesQuerySnapshot.forEach(async (templateDoc) => {
          const templateRef = doc(
            firebaseDb,
            `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
            templateDoc.id
          );

          const updatedTags = templateDoc.data().templateTags.filter((t: string) => t !== tagId);
          await updateDoc(templateRef, { templateTags: updatedTags });
        });
        return true;
      }
      return false;
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
      return false;
    }
  }

  async function updateTag(tagId: string, tagName: string) {
    try {
      const lowercaseTagName = tagName.toLowerCase().trim();
      const currentUser = getCurrentUser();
      if (currentUser) {
        const tagsRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
        );

        const querySnapshot1 = await getDocs(query(tagsRef));

        for (const doc of querySnapshot1.docs) {
          const existingTagName = doc.data().name.toLowerCase().trim();

          if (existingTagName === lowercaseTagName) {
            throw new Error("Tag name already exists!");
          }
        }
        const tagRef = doc(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`,
          tagId
        );

        const tagSnapshot = await getDoc(tagRef);
        const existingName = tagSnapshot.data()?.name;

        const conflictingRef = query(
          collection(
            firebaseDb,
            `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}`
          ),
          where("nameLowerCase", "==", tagName.toLowerCase()),
          where("nameLowerCase", "!=", existingName.toLowerCase()), // Exclude current list
          limit(1)
        );

        const conflictSnapshot = await getDocs(conflictingRef);
        if (!conflictSnapshot.empty) {
          throw "Tag name already exists!";
        } else {
          await updateDoc(tagRef, {
            name: tagName,
            modifiedDate: serverTimestamp(),
          });
        }
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  }

  async function updateList(listId: string, listName: string) {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const listRef = doc(
          firebaseDb,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`,
          listId
        );

        const listSnapshot = await getDoc(listRef);
        const existingName = listSnapshot.data()?.name;

        const conflictingRef = query(
          collection(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`
          ),
          where("nameLowerCase", "==", listName.toLowerCase()),
          where("nameLowerCase", "!=", existingName.toLowerCase()), // Exclude current list
          limit(1)
        );

        const conflictSnapshot = await getDocs(conflictingRef);
        if (!conflictSnapshot.empty) {
          throw "List name already exists!";
        } else {
          await updateDoc(listRef, {
            name: listName,
            nameLowerCase: listName.toLocaleLowerCase(),
            modifiedDate: serverTimestamp(),
          });
        }
      }
    } catch (err: any) {
      console.error(err);
      sentryClient.captureException(err)
      throw new Error(err);
    }
  }

  async function deleteList(listId: string) {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        const profilesRef = collection(
          firebaseDb,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}/${listId}/${COLLECTIONS.PROFILES}`
        );
        const profilesSnapshot = await getDocs(profilesRef);
        for (const doc of profilesSnapshot.docs) {
          deleteDoc(doc.ref);
        }
        const listRef = doc(
          firebaseDb,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`,
          listId
        );
        deleteDoc(listRef);
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  async function addProfilesToLists(profileIds: string[], listIds: string[]) {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        for (const profileId of profileIds) {
          const profileRef = doc(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.PROFILES}`,
            profileId
          );

          const profileSnapshot = await getDoc(profileRef);
          const profileData = profileSnapshot.data();
          const currentLists = profileData?.lists || [];
          const combinedLists = Array.from(
            new Set([...currentLists, ...listIds])
          );
          await updateDoc(profileRef, {
            lists: combinedLists,
          });
        }
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  async function deleteMultipleProfiles(profileIds: string[]) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const promises = profileIds.map(async (profileId) => {
          const profileRef = doc(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.PROFILES}`,
            profileId
          );
          await deleteDoc(profileRef);
        });
        await Promise.all(promises);
        console.log("All profiles deleted successfully");
      }
    } catch (err) {
      console.log("Error deleting profiles:", err);
      sentryClient.captureException(err)
    }
  }

  async function updateProfileToLists(profileId: string, listIds: string[]) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const profileRef = doc(
          firebaseDb,
          `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.PROFILES}`,
          profileId
        );
        await updateDoc(profileRef, {
          lists: listIds,
        });
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  const deleteMultipleTags = async (tagsIds: string[]) => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        for (const tagId of tagsIds) {
          await deleteTag(tagId);
          // const templatesRef = collection(
          //   firebaseDb,
          //   `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TAGS}/${tagId}/${COLLECTIONS.TEMPLATES}`
          // );
          // const templatesSnapshot = await getDocs(templatesRef);
          // for (const doc of templatesSnapshot.docs) {
          //   await deleteDoc(doc.ref);
          // }
          // const tagRef = doc(
          //   firebaseDb,
          //   `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
          //   tagId
          // );
          // await deleteDoc(tagRef);
        }
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  };

  const deleteMultipleLists = async (listIds: string[]) => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser) {
        for (const listId of listIds) {
          const profilesRef = collection(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}/${listId}/${COLLECTIONS.PROFILES}`
          );
          const profilesSnapshot = await getDocs(profilesRef);
          for (const doc of profilesSnapshot.docs) {
            await deleteDoc(doc.ref);
          }
          const listRef = doc(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`,
            listId
          );
          await deleteDoc(listRef);
        }
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  };

  async function handleGetUserLists() {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const listsRef = query(
          collection(
            firebaseDb,
            `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${currentUser.uid}/${COLLECTIONS.LISTS}`
          ),
          orderBy("modifiedDate", "desc")
        );

        const querySnapshot = await getDocs(listsRef);
        const listsArr: any[] = [];
        querySnapshot.forEach((doc) => {
          listsArr.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        return listsArr;
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  const addCommandData = async (
    activityData: any,
    activityType: string,
    generatedText: string,
    commandId: string
  ) => {
    try {
      const data = {
        [FIELDS.TIMESTAMP]: serverTimestamp(),
        [FIELDS.ACTIVITY_DATA]: activityData,
        [FIELDS.GENERATED_TEXT]: generatedText,
        [FIELDS.ACTIVITY_TYPE]: activityType,
      };
      const userId = firebaseAuth.currentUser?.uid;
      if (!userId) return { docId: "", error: "UsreId not found" };
      await setDoc(
        doc(
          firebaseDb,
          `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}/${commandId}`
        ),
        data
      );
      return { docId: commandId, error: null };
    } catch (error: any) {
      sentryClient.captureException(error)
      return { docId: "", error };
    }
  };

  const deleteCommandData = async (documentId: string) => {
    try {
      const userId = firebaseAuth.currentUser?.uid;
      if (!userId) return { docId: "", error: "UsreId not found" };
      await deleteDoc(
        doc(
          firebaseDb,
          `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.COMMANDS}`,
          documentId
        )
      );
      return { docId: documentId, error: null };
    } catch (error: any) {
      sentryClient.captureException(error)
      return { docId: "", error };
    }
  };

  const handleUpdateTeamSize = async (subscriptionId: string, planId: string, quantity: number, subscriptionItemId: string) => {
    try {
      if (user) {
        const client_reference_id: any = (window as any).Rewardful?.referral;
        // console.log("client_reference_id: ", client_reference_id);
        const stripeId = user.stripeId;
        const functionInstance = getFunctions(firebaseApp);
        const functionRef = httpsCallable(
          functionInstance,
          "updateSubscriptionPlan"
        );
        const response = await functionRef({
          subscriptionId,
          planId,
          quantity,
          subscriptionItemId,
          stripeId,
          client_reference_id: client_reference_id ? client_reference_id : "",
        });
        await getSubscriptionStatus();
        return { success: true, error: null, response };
      }
      return { success: false, error: "User is not logged in!", response: null };
    } catch (error: any) {
      sentryClient.captureException(error)
      return { success: false, error, response: null };
    }
  }

  const updateSubscriptionToTeamsDirectly = async (period: "monthly" | "yearly", quantity: number) => {
   try {
    const userId = firebaseAuth.currentUser?.uid;
    const email = firebaseAuth.currentUser?.email;
    if (!userId || !email) return { error: "Missing user email or UserId", success: false };

    const functionRef = httpsCallable(functionInstance, "updateSubscriptionToTeams_onCall");

    const response = (await functionRef({
      userId,
      period,
      quantity,
     })) as HttpsCallableResult<{ error: string; success: boolean }>;
 

    return {
     ...response.data,
    };
   } catch (e: any) {
    console.error(e);
    return { error: e.message as string, success: false };
   }
  };

  const deleteActivity = async (documentId: string) => {
    try {
      const userId = firebaseAuth.currentUser?.uid;
      if (!userId) return { docId: "", error: "UsreId not found" };
      await deleteDoc(
        doc(
          firebaseDb,
          `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.ACTIVITIES}`,
          documentId
        )
      );
      return { docId: documentId, error: null };
    } catch (error: any) {
      sentryClient.captureException(error)
      return { docId: "", error };
    }
  };

  const deleteAccount = async (credentialsRequired: boolean, isGoogle: boolean, credentials: {password: string} | {user: User, credential: OAuthCredential} | any) => {
    try {
      const user = firebaseAuth.currentUser;
      if (credentialsRequired) {
        if (!isGoogle && user) {
          const userEmail = user.email;
          const userPassword = credentials.password;
          if (userEmail && userPassword) {
            const credential = EmailAuthProvider.credential(userEmail, userPassword);
            await reauthenticateWithCredential(user, credential);
          }
        } else {
          await reauthenticateWithCredential(credentials.user, credentials.credential)
        }
      }
      await user?.delete();
      setUser(null);
      setUserSnapShot(null);
      setEmailVerified(false);
    } catch (error) {
      console.log(error, "errr!");
      sentryClient.captureException(error)
      throw error;
    }
  };

  const getSubscriptionPlans = async () => {
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId) return [];
    try {
      const subscriptionPlanSnapshot = await getDocs(
        query(
          collection(firebaseDb, `${COLLECTIONS.PRODUCTS}`),
          // where("active", "==", true)
        )
      );

      // let enableTrial = await getEnableTrail();
      const allProductsPrices = [];
      const filteredProducts = [];
      for (const productSnapshot of subscriptionPlanSnapshot.docs) {
        const productDocumentId = productSnapshot.id;
        const productPricesSnapshot = await getDocs(
          collection(
            firebaseDb,
            `${COLLECTIONS.PRODUCTS}/${productDocumentId}/prices`
          )
        );

        const productPrices = productPricesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        allProductsPrices.push(...productPrices);
      }
      // console.log("ALL Product prices", allProductsPrices);
      allProductsPrices.forEach((price: any) => {
        const jsonString = JSON.stringify(price);
        console.log("JSONs", jsonString);
      })
      filteredProducts.push(allProductsPrices[10]);
      filteredProducts.push(allProductsPrices[11]);
      // filteredProducts.push(allProductsPrices[enableTrial ? 2 : 0]);
      // filteredProducts.push(allProductsPrices[1]);
      console.log("Filtered Products", filteredProducts);

      return filteredProducts;
    } catch (error: any) {
      sentryClient.captureException(error)
      return [];
    }
  };

  const fetchSingleUserActivity = async (id: string) => {
    try {
      const userId = firebaseAuth.currentUser?.uid;
      if (!userId) return null;
      const docRef = doc(
        firebaseDb,
        `${COLLECTIONS.USER_ACTIVITIES}/${userId}/${COLLECTIONS.ACTIVITIES}/${id}`
      );
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setUserSnapShot(docSnapshot.data());
        return docSnapshot.data();
      } else {
        return {};
      }
    } catch (error) {
      sentryClient.captureException(error)
      return {};
    }
  };

  const getEnableTrail = async () => {
    const userId = firebaseAuth.currentUser?.uid;
    let isAlreadyAvailedTrial: any = false;
    let userData = null;

    if (userId) {
      userData = await fetchUserDataById(userId);
      isAlreadyAvailedTrial = userData?.is_trial_availed_in_the_past;
    }
    let enabledTrial = false;
    let isLimitReached =
      subscriptionStatus?.usagePercentage &&
      subscriptionStatus?.usagePercentage >= 100;

    if (isLimitReached) {
      if (isAlreadyAvailedTrial) {
        enabledTrial = false;
      } else {
        enabledTrial = true;
      }
    }
    return enabledTrial;
  };

  const getTrialWarning = async () => {
    const userId = firebaseAuth.currentUser?.uid;
    let showTrialWarning: boolean = false;
    let userData = null;

    if (userId) {
      userData = await fetchUserDataById(userId);
      if (userData?.show_trial_warning) {
        showTrialWarning = userData?.show_trial_warning;
      }
    }
    return showTrialWarning;
  };

  const disableTrialWarning = async () => {

    const userId = firebaseAuth.currentUser?.uid;

    if (userId) {
      try {
        const userRef = doc(firebaseDb, COLLECTIONS.CUSTOMERS, userId);
        const userData = await getDoc(userRef)
        if ((userData.data() as IUser).show_trial_warning) {
          await updateDoc(userRef, {
            show_trial_warning: false
          });
          console.log("Found and set to false");
        }
        console.log("Not Found");
        return true;

      } catch (error) {
        console.error('Error updating document: ', error);
        sentryClient.captureException(error)
        return false;
      }
    } else {
      console.error('User not logged in');
      return false;
    }
  };

  // const disableTrialWarning = async () => {
  //   const userId = firebaseAuth.currentUser?.uid;
  //   let showTrialWarning: boolean = false;
  //   let userData = null;

  //   if (userId) {
  //     userData = await fetchUserDataById(userId);
  //     if (userData?.show_trial_warning) {
  //       showTrialWarning = userData?.show_trial_warning;
  //     }
  //   }
  //   return showTrialWarning;
  // };

  const goToSubscriptionPlanPaymentURL = async (priceId: string, quantity: number = 2, trial_from_plan: boolean = false) => {
    setIsStripeLinkGenerating(priceId);
    // console.log("priceId - ", priceId);
    // console.log("annual teams price id", SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID);

    const client_reference_id: any = (window as any).Rewardful?.referral;
    const userId = firebaseAuth.currentUser?.uid;
    const email = firebaseAuth.currentUser?.email;
    let urlData: any = {};
    if (!userId) {
      navigateTo(Navigate_to.login);
    }
    let stripeSessionObj;

    if (priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_MONTHLY_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID_2) {
      stripeSessionObj = {
        payment_method_types: ['card'],
        line_items: [{
          price: priceId,
          quantity: quantity,
          adjustable_quantity: {
            enabled: true,
            minimum: 2,
            maximum: 1000,
          },
        }],
        mode: 'subscription',
        allow_promotion_codes: true,
        success_url: "https://evyai.com/payment_successful",
        cancel_url: "https://app.evyai.com/subscription-pricing",
        client_reference_id: client_reference_id ? client_reference_id : "",
        metadata: {
          email,
        },
        consent_collection: {
          terms_of_service: "required",
        },
        trial_from_plan,
      };
    } else {
      stripeSessionObj = {
        payment_method_types: ['card'],
        line_items: [{
          price: priceId,
          quantity: 1,
        }],
        mode: 'subscription',
        allow_promotion_codes: true,
        success_url: "https://evyai.com/payment_successful",
        cancel_url: "https://app.evyai.com/subscription-pricing",
        client_reference_id: client_reference_id ? client_reference_id : "",
        metadata: {
          email,
        },
        consent_collection: {
          terms_of_service: "required",
        },
        trial_from_plan,
      };

    }

    const docRef = await addDoc(
      collection(
        firebaseDb,
        `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.CHECKOUT_SESSIONS}`
      ),
      stripeSessionObj
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, async (snap) => {
        const res = await onSnapshotRecieved(snap);
        urlData = res;
        if (urlData.error) {
          unsubscribe();
          setIsStripeLinkGenerating(null);
          reject(urlData.error);
        } else if (urlData.url) {
          // Auto redirect to the url
          // const a = document.createElement("a");
          // a.href = urlData.url;
          // a.rel = "noopener noreferrer"; // for security
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          unsubscribe();
          setIsStripeLinkGenerating(null);
          resolve(urlData.url);
        }
      });
    });
  };

  const onSnapshotRecieved = async (snap: DocumentSnapshot<DocumentData>) => {
    const data = snap.data();
    return data;
  };

  const fetchSubscriptionStatusByUserId = async (userId: string) => {
    if (!userId)
      return {
        error: "Not signed in. Please sign in again.",
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
    try {
      let willGetTrial = false;
      let statusDB = null;
      let userPersonalStatusDB = null;
      let subscriptionData = null;
      let subscription = SUBSCRIPTIONS_NAMES.FREE;
      let interval = SUBSCRIPTIONS_INTERVALS.MONTH;
      let isAdmin = false;
      let hasTeamsPlan = false;
      let allowedTeamMembers = 0;
      let userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      let subscriptionEndDate = null;
      const subscriptionStatusSnapshot = await getDocs(
        query(
          collection(
            firebaseDb,
            `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.SUBSCRIPTIONS}`
          ),
          // where("status", "in", ["trialing", "active"])
        )
      );
      let subDocs = subscriptionStatusSnapshot.docs
      subDocs.reverse();
      let subscriptionItem: any = null;
      let subscriptionItemId: string = "";
      let status = SubscriptionStatus.FREE;
      let usagePercentage = 0;
      if (subDocs.length > 0) {
        let hasActiveSubscription = false;
        for (const doc of subDocs) {
          const docData = doc.data();
          const docId = doc.id;
          if (docData.status === SubscriptionStatusDB.ACTIVE || 
              docData.status === SubscriptionStatusDB.PAST_DUE || 
              docData.status === SubscriptionStatusDB.TRIALING) {
            hasActiveSubscription = true;
            subscriptionItem = doc;
            subscriptionItemId = docId;
            break; 
          } else if (docData.status === SubscriptionStatusDB.CANCELLED) {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          } else {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          }
        }
        if (hasActiveSubscription) {
          status = SubscriptionStatus.PRO;
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.PREMIUM;
        } else {
          subscriptionData = {...(subscriptionItem.data() as SubscriptionType), id: subscriptionItemId};
          status = SubscriptionStatus.FREE;
          subscription = SUBSCRIPTIONS_NAMES.FREE;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          if (subscriptionData.status === SubscriptionStatusDB.CANCELLED) {
            statusDB = SubscriptionStatusDB.CANCELLED;
            userPersonalStatusDB = SubscriptionStatusDB.CANCELLED;
          } 
          else {
            statusDB = subscriptionData.status;
            userPersonalStatusDB = subscriptionData.status;
          }
        }
      }

      if (status === SubscriptionStatus.PRO && subscriptionItem) {
        const subData = subscriptionItem.data();
        // const subData = subscriptionStatusSnapshot.docs[subscriptionStatusSnapshot.docs.length - 1].data();
        subscriptionData = subData ? {...(subData as SubscriptionType), id: subscriptionItemId} : null;
        statusDB = subData.status;
        userPersonalStatusDB = subData.status;
        subscriptionEndDate = subData.current_period_end.toDate();
        const newDate = new Date();
        if (newDate > subscriptionEndDate) {
          statusDB = SubscriptionStatusDB.CANCELLED;
          // console.log("SUBSCRIPTION END DATE is less", subscriptionEndDate);
        }
        interval = (subData.items[0].plan.interval) === "month" ? SUBSCRIPTIONS_INTERVALS.MONTH : SUBSCRIPTIONS_INTERVALS.YEAR;
        const productId = subData.items[0].plan.product;
        const priceId = subData.items[0].plan.id;
        const totalUsersAllowed = subData.items[0].quantity;
        isAdmin = true;

        if (productId === SUBSCRIPTIONS_FIELDS.TEAMS_SUBSCRIPTION_PRODUCT_ID && (priceId === SUBSCRIPTIONS_FIELDS.TEAMS_MONTHLY_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID_2)) {
          hasTeamsPlan = true;
          subscription = SUBSCRIPTIONS_NAMES.TEAMS;
        } else {
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          hasTeamsPlan = false;
        }
        if (totalUsersAllowed > 1) {
          allowedTeamMembers = totalUsersAllowed - 1;
        }
      }

      const customerDetailsSnapshot = await getDoc(
        doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`)
      );
      if (customerDetailsSnapshot.exists()) {
        const customerUsageDetailsSnapshot = await getDoc(
          doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.STATS}/${COLLECTIONS.ALL_STATS}`)
        );
        let {current_minute_stats, current_day_stats, current_hour_stats, current_month_stats, total_usage, ...customerData} = customerDetailsSnapshot.data();
        if (customerUsageDetailsSnapshot.exists()) {
          customerData = {...customerData, ...customerUsageDetailsSnapshot.data()}
        }
        const isTrialAvailedInThePast = (customerData as IUser).is_trial_availed_in_the_past;
        willGetTrial = !isTrialAvailedInThePast;
        if (status === SubscriptionStatus.FREE) {
          subscription = SUBSCRIPTIONS_NAMES.FREE;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          interval = SUBSCRIPTIONS_INTERVALS.MONTH;
          isAdmin = false;
          if (
            customerData[FIELDS.TOTAL_USAGE] &&
            customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
          ) {
            usagePercentage = Math.min(
              100,
              Math.ceil(
                (100 *
                  customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                FREE_USER_MAXIMUM_TOKENS
              )
            );
          }
        } else {
          subscription = hasTeamsPlan ? SUBSCRIPTIONS_NAMES.TEAMS : SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = hasTeamsPlan ? SUBSCRIPTIONS_NAMES.TEAMS : SUBSCRIPTIONS_NAMES.PREMIUM;
          isAdmin = hasTeamsPlan ? true : false;
          if (statusDB === SubscriptionStatusDB.PAST_DUE) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
          } else if (statusDB === SubscriptionStatusDB.ACTIVE || statusDB === SubscriptionStatusDB.TRIALING) {
            if (
              customerData[FIELDS.CURRENT_MONTH_STATS] &&
              customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.CURRENT_MONTH_STATS][
                    FIELDS.TOTAL_TOKENS_COUNT
                    ]) /
                  PRO_USER_MAXIMUM_MONTHLY_USAGE
                )
              );
            }
          } else if (statusDB === SubscriptionStatusDB.CANCELLED) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
          } else {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
          }
          // if (
          //   customerData[FIELDS.CURRENT_MONTH_STATS] &&
          //   customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
          //   ) {
          //   usagePercentage = Math.min(
          //     100,
          //     Math.ceil(
          //       (100 *
          //         customerData[FIELDS.CURRENT_MONTH_STATS][
          //           FIELDS.TOTAL_TOKENS_COUNT
          //         ]) /
          //         PRO_USER_MAXIMUM_MONTHLY_USAGE
          //     )
          //   );
          // }
        }
      }
      // console.log(statusDB);
      if (statusDB !== SubscriptionStatusDB.ACTIVE && statusDB !== SubscriptionStatusDB.PAST_DUE && statusDB !== SubscriptionStatusDB.TRIALING) {
        status = SubscriptionStatus.FREE;
        subscription = SUBSCRIPTIONS_NAMES.FREE;
        interval = SUBSCRIPTIONS_INTERVALS.MONTH;
        isAdmin = false;
      }
      if (userPersonalStatusDB !== SubscriptionStatusDB.ACTIVE && userPersonalStatusDB !== SubscriptionStatusDB.PAST_DUE && userPersonalStatusDB !== SubscriptionStatusDB.TRIALING) {
        hasTeamsPlan = false;
        allowedTeamMembers = 0;
        userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      }
      if ((status === SubscriptionStatus.FREE && usagePercentage >= 100) && willGetTrial) {
        willGetTrial = true;
      } else {
        willGetTrial = false;
      }
      // if (status === SubscriptionStatus.FREE && usagePercentage >= 100) {
      //   willGetTrial = true;
      // } else {
      //   willGetTrial = false;
      // }
      return {
        error: null,
        status,
        usagePercentage,
        hasTeamsPlan,
        allowedTeamMembers,
        subscription,
        interval,
        isAdmin,
        userPersonalSubscription,
        subscriptionData,
        statusDB,
        userPersonalStatusDB,
        willGetTrial,
      };
    } catch (error: any) {
      sentryClient.captureException(error)
      return {
        error,
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
    }
  };

  const getSubscriptionStatusWithTeamId = async (teamIds: string[] | undefined) => {
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId)
      return {
        error: "Not signed in. Please sign in again.",
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
    try {
      let willGetTrial = false;
      let statusDB = null;
      let userPersonalStatusDB = null;
      let subscriptionData = null;
      let subscription = SUBSCRIPTIONS_NAMES.FREE;
      let interval = SUBSCRIPTIONS_INTERVALS.MONTH;
      let isAdmin = false;
      let hasTeamsPlan = false;
      let allowedTeamMembers = 0;
      let teamsData: any[] = [];
      let subStatusesOfTeamAdmins: SubScriptionStaus_Type[] = [];
      let isProUser: boolean = false;
      let userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      let subscriptionEndDate = null;

      const subscriptionStatusSnapshot = await getDocs(
        query(
          collection(
            firebaseDb,
            `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.SUBSCRIPTIONS}`
          ),
          // where("status", "in", ["trialing", "active"])
        )
      );
      let subDocs = subscriptionStatusSnapshot.docs
      subDocs.reverse();
      let subscriptionItem: any = null;
      let subscriptionItemId: string = "";
      let status = SubscriptionStatus.FREE;
      let usagePercentage = 0;
      if (subDocs.length > 0) {
        let hasActiveSubscription = false;
        for (const doc of subDocs) {
          const docData = doc.data();
          const docId = doc.id;
          if (docData.status === SubscriptionStatusDB.ACTIVE || 
              docData.status === SubscriptionStatusDB.PAST_DUE || 
              docData.status === SubscriptionStatusDB.TRIALING) {
            hasActiveSubscription = true;
            subscriptionItem = doc;
            subscriptionItemId = docId;
            break; 
          } else if (docData.status === SubscriptionStatusDB.CANCELLED) {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          } else {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          }
        }
        if (hasActiveSubscription) {
          status = SubscriptionStatus.PRO;
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.PREMIUM;
        } else {
          subscriptionData = {...(subscriptionItem.data() as SubscriptionType), id: subscriptionItemId};
          status = SubscriptionStatus.FREE;
          subscription = SUBSCRIPTIONS_NAMES.FREE;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          if (subscriptionData.status === SubscriptionStatusDB.CANCELLED) {
            statusDB = SubscriptionStatusDB.CANCELLED;
            userPersonalStatusDB = SubscriptionStatusDB.CANCELLED;
          } else {
            statusDB = subscriptionData.status;
            userPersonalStatusDB = subscriptionData.status;
          }
        }
      }

      if (status === SubscriptionStatus.PRO && subscriptionItem) {
        const subData = subscriptionItem.data();
        // const subData = subscriptionStatusSnapshot.docs[subscriptionStatusSnapshot.docs.length - 1].data();
        subscriptionData = subData ? {...(subData as SubscriptionType), id: subscriptionItemId} : null;
        statusDB = subData.status;
        userPersonalStatusDB = subData.status;
        subscriptionEndDate = subData.current_period_end.toDate();
        const newDate = new Date();
        if (newDate > subscriptionEndDate) {
          statusDB = SubscriptionStatusDB.CANCELLED;
          // console.log("SUBSCRIPTION END DATE is less", subscriptionEndDate);
        }
        interval = (subData.items[0].plan.interval) === "month" ? SUBSCRIPTIONS_INTERVALS.MONTH : SUBSCRIPTIONS_INTERVALS.YEAR;
        const productId = subData.items[0].plan.product;
        const priceId = subData.items[0].plan.id;
        const totalUsersAllowed = subData.items[0].quantity;
        isAdmin = true;

        if (productId === SUBSCRIPTIONS_FIELDS.TEAMS_SUBSCRIPTION_PRODUCT_ID && (priceId === SUBSCRIPTIONS_FIELDS.TEAMS_MONTHLY_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID_2)) {
          hasTeamsPlan = true;
          subscription = SUBSCRIPTIONS_NAMES.TEAMS;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.TEAMS;
        } else {
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          hasTeamsPlan = false;
        }
        if (totalUsersAllowed > 1) {
          allowedTeamMembers = totalUsersAllowed - 1;
        }
      }

      const customerDetailsSnapshot = await getDoc(
        doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`)
      );
      if (customerDetailsSnapshot.exists()) {
        const customerUsageDetailsSnapshot = await getDoc(
          doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.STATS}/${COLLECTIONS.ALL_STATS}`)
        );
        let {current_minute_stats, current_day_stats, current_hour_stats, current_month_stats, total_usage, ...customerData} = customerDetailsSnapshot.data();
        if (customerUsageDetailsSnapshot.exists()) {
          customerData = {...customerData, ...customerUsageDetailsSnapshot.data()}
        }
        const isTrialAvailedInThePast = (customerData as IUser).is_trial_availed_in_the_past;
        willGetTrial = !isTrialAvailedInThePast;
        if (status === SubscriptionStatus.PRO) {
          // hasTeamsPlan ? console.log("IS ALREADY PRO USER AND ALSO HAVE TEAM SUBSCRIPTION") : console.log("IS ALREADY PRO USER WITHOUT TEAMS")
          if (statusDB === SubscriptionStatusDB.PAST_DUE) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          } else if (statusDB === SubscriptionStatusDB.ACTIVE || statusDB === SubscriptionStatusDB.TRIALING) {
            if (
              customerData[FIELDS.CURRENT_MONTH_STATS] &&
              customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.CURRENT_MONTH_STATS][
                    FIELDS.TOTAL_TOKENS_COUNT
                    ]) /
                  PRO_USER_MAXIMUM_MONTHLY_USAGE
                )
              );
            }
          } else if (statusDB === SubscriptionStatusDB.CANCELLED) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          } else {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          }
        } else {
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          if (teamIds !== undefined && teamIds.length > 0) {
            const teamIdsMapPromises = teamIds.map(async (teamId: string) => {
              const teamData = await fetchTeamById(teamId);
              const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
              interval = adminSubStatus.interval;
              teamsData.push(teamData);
              subStatusesOfTeamAdmins.push(adminSubStatus);
            })
            await Promise.all(teamIdsMapPromises);
          }
          isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => {
            if ((subStatus.hasTeamsPlan && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE) && subStatus.status === SubscriptionStatus.PRO) {
              if (subStatus.interval) {
                interval = subStatus.interval;
              }
              return true;
            } else {
              return false;
            }
          })
          const hasPastDue = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => {
            if ((subStatus.hasTeamsPlan && subStatus.userPersonalStatusDB === SubscriptionStatusDB.PAST_DUE) && subStatus.status === SubscriptionStatus.PRO) {
              if (subStatus.interval) {
                interval = subStatus.interval;
              }
              return true;
            } else {
              return false;
            }
          })
          // isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.hasTeamsPlan && subStatus.status === SubscriptionStatus.PRO)
          if (isProUser) {
            status = SubscriptionStatus.PRO;
            subscription = SUBSCRIPTIONS_NAMES.TEAMS_MEMBER;
            statusDB = SubscriptionStatusDB.ACTIVE;
            userPersonalStatusDB = SubscriptionStatusDB.ACTIVE;
            isAdmin = false;
            if (
              customerData[FIELDS.CURRENT_MONTH_STATS] &&
              customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.CURRENT_MONTH_STATS][
                    FIELDS.TOTAL_TOKENS_COUNT
                    ]) /
                  PRO_USER_MAXIMUM_MONTHLY_USAGE
                )
              );
            }
          } else if (!isProUser && hasPastDue) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
              // usagePercentage = 100;
            }
            if (usagePercentage >= 100) {
              status = SubscriptionStatus.PRO;
              subscription = SUBSCRIPTIONS_NAMES.TEAMS_MEMBER;
              // interval = SUBSCRIPTIONS_INTERVALS.MONTH;
              isAdmin = false;
              statusDB = SubscriptionStatusDB.PAST_DUE;
            } else {
              status = SubscriptionStatus.FREE;
              subscription = SUBSCRIPTIONS_NAMES.FREE;
              interval = SUBSCRIPTIONS_INTERVALS.MONTH;
              isAdmin = false;
            }
          } else {
            status = SubscriptionStatus.FREE;
            subscription = SUBSCRIPTIONS_NAMES.FREE;
            interval = SUBSCRIPTIONS_INTERVALS.MONTH;
            isAdmin = false;
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
          }
        }
      }
      // console.log(statusDB);
      if (statusDB !== SubscriptionStatusDB.ACTIVE && statusDB !== SubscriptionStatusDB.PAST_DUE && statusDB !== SubscriptionStatusDB.TRIALING) {
        status = SubscriptionStatus.FREE;
        subscription = SUBSCRIPTIONS_NAMES.FREE;
        interval = SUBSCRIPTIONS_INTERVALS.MONTH;
        isAdmin = false;
      }
      if (userPersonalStatusDB !== SubscriptionStatusDB.ACTIVE && userPersonalStatusDB !== SubscriptionStatusDB.PAST_DUE && userPersonalStatusDB !== SubscriptionStatusDB.TRIALING) {
        hasTeamsPlan = false;
        allowedTeamMembers = 0;
        userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      }
      if ((status === SubscriptionStatus.FREE && usagePercentage >= 100) && willGetTrial) {
        willGetTrial = true;
      } else {
        willGetTrial = false;
      }
      setSubscriptionStatus({
        error: null,
        status,
        usagePercentage,
        hasTeamsPlan,
        allowedTeamMembers,
        subscription,
        interval,
        isAdmin,
        userPersonalSubscription,
        subscriptionData,
        statusDB,
        userPersonalStatusDB,
        willGetTrial,
      });
      return {
        error: null,
        status,
        usagePercentage,
        hasTeamsPlan,
        allowedTeamMembers,
        subscription,
        interval,
        isAdmin,
        userPersonalSubscription,
        subscriptionData,
        statusDB,
        userPersonalStatusDB,
        willGetTrial,
      };
    } catch (error: any) {
      console.log("ERROR", error);
      sentryClient.captureException(error)
      return {
        error,
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
    }
  };

  const processSubscriptionStatusSnapShot = async (subscriptionStatusSnapshot:QuerySnapshot<DocumentData,DocumentData>,userId:string) => {
    try {
      let willGetTrial = false;
      let statusDB = null;
      let userPersonalStatusDB = null;
      let subscriptionData = null;
      let subscription = SUBSCRIPTIONS_NAMES.FREE;
      let interval = SUBSCRIPTIONS_INTERVALS.MONTH;
      let isAdmin = false;
      let hasTeamsPlan = false;
      let allowedTeamMembers = 0;
      let teamsData: any[] = [];
      let subStatusesOfTeamAdmins: SubScriptionStaus_Type[] = [];
      let isProUser: boolean = false;
      let userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      let subscriptionEndDate = null;

      let subDocs = subscriptionStatusSnapshot.docs
      subDocs.reverse();
      let subscriptionItem: any = null;
      let subscriptionItemId: string = "";
      let status = SubscriptionStatus.FREE;
      let usagePercentage = 0;
      if (subDocs.length > 0) {
        let hasActiveSubscription = false;
        for (const doc of subDocs) {
          const docData = doc.data();
          const docId = doc.id;
          if (docData.status === SubscriptionStatusDB.ACTIVE || 
              docData.status === SubscriptionStatusDB.PAST_DUE || 
              docData.status === SubscriptionStatusDB.TRIALING) {
            hasActiveSubscription = true;
            subscriptionItem = doc;
            subscriptionItemId = docId;
            break; 
          } else if (docData.status === SubscriptionStatusDB.CANCELLED) {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          } else {
            hasActiveSubscription = false;
            subscriptionItem = doc;
            subscriptionItemId = docId;
          }
        }
        if (hasActiveSubscription) {
          status = SubscriptionStatus.PRO;
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.PREMIUM;
        } else {
          subscriptionData = {...(subscriptionItem.data() as SubscriptionType), id: subscriptionItemId};
          status = SubscriptionStatus.FREE;
          subscription = SUBSCRIPTIONS_NAMES.FREE;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          if (subscriptionData.status === SubscriptionStatusDB.CANCELLED) {
            statusDB = SubscriptionStatusDB.CANCELLED;
            userPersonalStatusDB = SubscriptionStatusDB.CANCELLED;
          } else {
            statusDB = subscriptionData.status;
            userPersonalStatusDB = subscriptionData.status;
          }
        }
      }

      if (status === SubscriptionStatus.PRO && subscriptionItem) {
        // subscriptionStatusSnapshot.docs.
        const subData = subscriptionItem.data();
        // const subData = subscriptionStatusSnapshot.docs[subscriptionStatusSnapshot.docs.length - 1].data();
        // const subDataId = subscriptionStatusSnapshot.docs[subscriptionStatusSnapshot.docs.length - 1].id;
        subscriptionData = subData ? {...(subData as SubscriptionType), id: subscriptionItemId} : null;
        statusDB = subData.status;
        userPersonalStatusDB = subData.status;
        subscriptionEndDate = subData.current_period_end.toDate();
        const newDate = new Date();
        if (newDate > subscriptionEndDate) {
          statusDB = SubscriptionStatusDB.CANCELLED;
          // console.log("SUBSCRIPTION END DATE is less", subscriptionEndDate);
        }
        interval = (subData.items[0].plan.interval) === "month" ? SUBSCRIPTIONS_INTERVALS.MONTH : SUBSCRIPTIONS_INTERVALS.YEAR;
        const productId = subData.items[0].plan.product;
        const priceId = subData.items[0].plan.id;
        const totalUsersAllowed = subData.items[0].quantity;
        isAdmin = true;

        if (productId === SUBSCRIPTIONS_FIELDS.TEAMS_SUBSCRIPTION_PRODUCT_ID && (priceId === SUBSCRIPTIONS_FIELDS.TEAMS_MONTHLY_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID || priceId === SUBSCRIPTIONS_FIELDS.TEAMS_ANNUAL_PRICE_ID_2)) {
          hasTeamsPlan = true;
          subscription = SUBSCRIPTIONS_NAMES.TEAMS;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.TEAMS;
        } else {
          subscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.PREMIUM;
          hasTeamsPlan = false;
        }
        if (totalUsersAllowed > 1) {
          allowedTeamMembers = totalUsersAllowed - 1;
        }
      }

      const customerDetailsSnapshot = await getDoc(
        doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}`)
      );
      if (customerDetailsSnapshot.exists()) {
        const customerUsageDetailsSnapshot = await getDoc(
          doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.STATS}/${COLLECTIONS.ALL_STATS}`)
        );
        let {current_minute_stats, current_day_stats, current_hour_stats, current_month_stats, total_usage, ...customerData} = customerDetailsSnapshot.data();
        if (customerUsageDetailsSnapshot.exists()) {
          customerData = {...customerData, ...customerUsageDetailsSnapshot.data()}
        }
        const isTrialAvailedInThePast = (customerData as IUser).is_trial_availed_in_the_past;
        willGetTrial = !isTrialAvailedInThePast;
        if (status === SubscriptionStatus.PRO) {
          // hasTeamsPlan ? console.log("IS ALREADY PRO USER AND ALSO HAVE TEAM SUBSCRIPTION") : console.log("IS ALREADY PRO USER WITHOUT TEAMS")
          if (statusDB === SubscriptionStatusDB.PAST_DUE) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          } else if (statusDB === SubscriptionStatusDB.ACTIVE || statusDB === SubscriptionStatusDB.TRIALING) {
            if (
              customerData[FIELDS.CURRENT_MONTH_STATS] &&
              customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.CURRENT_MONTH_STATS][
                    FIELDS.TOTAL_TOKENS_COUNT
                    ]) /
                  PRO_USER_MAXIMUM_MONTHLY_USAGE
                )
              );
            }
          } else if (statusDB === SubscriptionStatusDB.CANCELLED) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          } else {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
            }
            if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
              const teamPromises = customerData.memberInTeams.map(async (id: string) => {
                const teamData = await fetchTeamById(id);
                const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
                interval = adminSubStatus.interval;
                teamsData.push(teamData);
                subStatusesOfTeamAdmins.push(adminSubStatus);
              })
              await Promise.all(teamPromises);
            }
            isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => subStatus.userPersonalStatusDB && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE)
            if (isProUser) {
              statusDB = SubscriptionStatusDB.ACTIVE;
            }
          }
        } else {
          userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
          if (customerData && customerData.memberInTeams && customerData.memberInTeams.length > 0) {
            const teamPromises = customerData.memberInTeams.map(async (id: string) => {
              const teamData = await fetchTeamById(id);
              const adminSubStatus = await fetchSubscriptionStatusByUserId((teamData as any).adminId);
              interval = adminSubStatus.interval;
              teamsData.push(teamData);
              subStatusesOfTeamAdmins.push(adminSubStatus);
            })
            await Promise.all(teamPromises);
          }
          isProUser = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => {
            if ((subStatus.hasTeamsPlan && subStatus.userPersonalStatusDB === SubscriptionStatusDB.ACTIVE) && subStatus.status === SubscriptionStatus.PRO) {
              if (subStatus.interval) {
                interval = subStatus.interval;
              }
              return true;
            } else {
              return false;
            }
          })
          const hasPastDue = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => {
            if ((subStatus.hasTeamsPlan && subStatus.userPersonalStatusDB === SubscriptionStatusDB.PAST_DUE) && subStatus.status === SubscriptionStatus.PRO) {
              if (subStatus.interval) {
                interval = subStatus.interval;
              }
              return true;
            } else {
              return false;
            }
          })
          // const hasPastDue = subStatusesOfTeamAdmins.some((subStatus: SubScriptionStaus_Type) => (subStatus.hasTeamsPlan && subStatus.userPersonalStatusDB === SubscriptionStatusDB.PAST_DUE) && subStatus.status === SubscriptionStatus.PRO)
          if (isProUser) {
            status = SubscriptionStatus.PRO;
            subscription = SUBSCRIPTIONS_NAMES.TEAMS_MEMBER;
            statusDB = SubscriptionStatusDB.ACTIVE;
            userPersonalStatusDB = SubscriptionStatusDB.ACTIVE;
            isAdmin = false;
            if (
              customerData[FIELDS.CURRENT_MONTH_STATS] &&
              customerData[FIELDS.CURRENT_MONTH_STATS][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.CURRENT_MONTH_STATS][
                    FIELDS.TOTAL_TOKENS_COUNT
                    ]) /
                  PRO_USER_MAXIMUM_MONTHLY_USAGE
                )
              );
            }
          } else if (!isProUser && hasPastDue) {
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
              // usagePercentage = 100;
            }
            if (usagePercentage >= 100) {
              status = SubscriptionStatus.PRO;
              subscription = SUBSCRIPTIONS_NAMES.TEAMS_MEMBER;
              isAdmin = false;
              statusDB = SubscriptionStatusDB.PAST_DUE;
            } else {
              status = SubscriptionStatus.FREE;
              subscription = SUBSCRIPTIONS_NAMES.FREE;
              interval = SUBSCRIPTIONS_INTERVALS.MONTH;
              isAdmin = false;
            }
          } else {
            status = SubscriptionStatus.FREE;
            subscription = SUBSCRIPTIONS_NAMES.FREE;
            interval = SUBSCRIPTIONS_INTERVALS.MONTH;
            isAdmin = false;
            if (
              customerData[FIELDS.TOTAL_USAGE] &&
              customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]
            ) {
              usagePercentage = Math.min(
                100,
                Math.ceil(
                  (100 *
                    customerData[FIELDS.TOTAL_USAGE][FIELDS.TOTAL_TOKENS_COUNT]) /
                  FREE_USER_MAXIMUM_TOKENS
                )
              );
              // usagePercentage = 100;
            }
          }
        }
      }
      // statusDB = SubscriptionStatusDB.CANCELLED;
      // allowedTeamMembers = 10;
      // console.log(statusDB);
      // allowedTeamMembers = 5;
      // hasTeamsPlan = true;
      if (statusDB !== SubscriptionStatusDB.ACTIVE && statusDB !== SubscriptionStatusDB.PAST_DUE && statusDB !== SubscriptionStatusDB.TRIALING) {
        status = SubscriptionStatus.FREE;
        subscription = SUBSCRIPTIONS_NAMES.FREE;
        interval = SUBSCRIPTIONS_INTERVALS.MONTH;
        isAdmin = false;
      }
      if (userPersonalStatusDB !== SubscriptionStatusDB.ACTIVE && userPersonalStatusDB !== SubscriptionStatusDB.PAST_DUE && userPersonalStatusDB !== SubscriptionStatusDB.TRIALING) {
        hasTeamsPlan = false;
        allowedTeamMembers = 0;
        userPersonalSubscription = SUBSCRIPTIONS_NAMES.FREE;
      }
      // usagePercentage = 100;
      if ((status === SubscriptionStatus.FREE && usagePercentage >= 100) && willGetTrial) {
        willGetTrial = true;
      } else {
        willGetTrial = false;
      }
      setSubscriptionStatus({
        error: null,
        status,
        usagePercentage,
        hasTeamsPlan,
        allowedTeamMembers,
        subscription,
        interval,
        isAdmin,
        userPersonalSubscription,
        subscriptionData,
        statusDB,
        userPersonalStatusDB,
        willGetTrial,
      });
      return {
        error: null,
        status,
        usagePercentage,
        hasTeamsPlan,
        allowedTeamMembers,
        subscription,
        interval,
        isAdmin,
        userPersonalSubscription,
        subscriptionData,
        statusDB,
        userPersonalStatusDB,
        willGetTrial,
      };
    } catch (error: any) {
      console.log("ERROR", error);
      sentryClient.captureException(error)
      return {
        error,
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
    }
  };

  const getSubscriptionStatus = async () => {
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId)
      return {
        error: "Not signed in. Please sign in again.",
        status: SubscriptionStatus.FREE,
        usagePercentage: 0,
        hasTeamsPlan: false,
        allowedTeamMembers: 0,
        subscription: SUBSCRIPTIONS_NAMES.FREE,
        interval: SUBSCRIPTIONS_INTERVALS.MONTH,
        isAdmin: false,
        userPersonalSubscription: SUBSCRIPTIONS_NAMES.FREE,
        subscriptionData: null,
        statusDB: null,
        userPersonalStatusDB: null,
        willGetTrial: false,
      };
      const subscriptionStatusSnapshot = await getDocs(
        query(
          collection(
            firebaseDb,
            `${COLLECTIONS.CUSTOMERS}/${userId}/${COLLECTIONS.SUBSCRIPTIONS}`
          ),
          // where("status", "in", ["trialing", "active", "past_due"])
        )
      );
     return processSubscriptionStatusSnapShot(subscriptionStatusSnapshot,userId)
  };

  const getCustomerSubscriptionPortalLink = async (willEnableLoading: boolean = true) => {
    willEnableLoading && setIsMSubcribeLinkGenerating(true);
    try {
      const functionInstance = getFunctions(firebaseApp);

      const customerId = user?.stripeId;
      let hasTeamsPremiumSubscription = true;
      let hasPremiumSubscription = false;
      let productId: string | null = null;
      let response: any = { error: null, url: null };

      if (subscriptionStatus && subscriptionStatus.subscriptionData) {
        hasTeamsPremiumSubscription = subscriptionStatus.hasTeamsPlan ? true : false;
        hasPremiumSubscription = subscriptionStatus.hasTeamsPlan ? false : true;
        productId = subscriptionStatus.subscriptionData.items[0].plan.product;
      }

      if (hasPremiumSubscription && (productId === SUBSCRIPTIONS_FIELDS.OLD_PREMIUM_SUBSCRIPTION_PRODUCT_ID || productId === SUBSCRIPTIONS_FIELDS.NEW_PREMIUM_SUBSCRIPTION_PRODUCT_ID || productId === SUBSCRIPTIONS_FIELDS.PREMIUM_SUBSCRIPTION_FREE_TRIAL_PRODUCT_ID)) {
        const functionRef = httpsCallable(
          functionInstance,
          "ext-firestore-stripe-payments-createPortalLink"
        );
        response = await functionRef({
          returnUrl: SHARE_EVY_AI_URL,
          locale: "auto", // Optional, defaults to "auto"
        });
      } else {
        const functionRef = httpsCallable(
          functionInstance,
          "createStripePortalUrl"
        );
        response = await functionRef({
          customerId,
          // locale: "auto", // Optional, defaults to "auto"
          hasTeamsPremiumSubscription,
          hasPremiumSubscription,
        });
      }
      setIsMSubcribeLinkGenerating(false);
      // Auto Redirect to the url
      // openInSameTab((response.data as any).url as string);
      return { error: null, url: response.data };
      // if (customerId) {
      // }
    } catch (error: any) {
      setIsMSubcribeLinkGenerating(false);
      sentryClient.captureException(error)
      return { error, url: null };
    }
  };

  const fetchSingleData = async (id: string, collection: string) => {
    try {
      const docRef = doc(firebaseDb, `${collection}/${id}`);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      }
      return null;
    } catch (error) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const getUserSubscriptionData = async () => {
    try {
      const userId = getCurrentUser();
      if (!userId) {
        return;
      }
      const customerRef = collection(firebaseDb, COLLECTIONS.CUSTOMERS);
      // const subscriptionRef = collection(
      //   customerRef,
      //   userId.uid,
      //   COLLECTIONS.SUBSCRIPTIONS
      // );

      const subscriptionRef = query(
        collection(
          customerRef,
          userId.uid,
          COLLECTIONS.SUBSCRIPTIONS
        ),
        orderBy("created", "asc")
      )

      const q = query(subscriptionRef);
      const querySnapshot = await getDocs(q);

      const _subscriptionData: any = [];
      querySnapshot.forEach((doc) => {
        _subscriptionData.push({ ...doc.data(), id: doc.id });
      });
      setSubscriptionData(
        _subscriptionData[_subscriptionData?.length - 1 || 0]
      );
      return _subscriptionData[_subscriptionData?.length - 1 || 0];
    } catch (error) {
      console.error("Error fetching data:", error);
      sentryClient.captureException(error)
    }
  };

  const fetchSuggestedComments = async (data: any) => {
    console.log({ data });

    try {
      const firebaseIdToken = await getCurrentUserIdToken();
      const response = await fetch(API_URL.GET_COMMENTS, {
        method: "POST",
        // mode: 'no-cors',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
          pragma: "no-cache",
          "cache-control": "no-cache",
          authorization: `Bearer ${firebaseIdToken}`,
        },
        cache: "no-cache",
        redirect: "follow",
        body: JSON.stringify({ data, userId: firebaseAuth.currentUser?.uid }),
      });
      console.log({ response });
      if (response.status >= 200 && response.status <= 204) {
        const jsonResponse = await response.json();
        console.log({ jsonResponse });
        return jsonResponse;
      } else {
        console.log(`something wrong, the server code: ${response.status}`);
        return {
          success: false,
          message: "Some error occurred. Please try again later.",
        };
      }
    } catch (error) {
      console.log({ error });
      sentryClient.captureException(error)
      throw {
        success: false,
        error,
        message: "Some error occurred. Please try again later.",
      };
    }
  };

  const fetchProfileDetails = async (profileUrl: string) => {
    try {
      const response = await fetch(
        "https://us-central1-ai-finance-assistant.cloudfunctions.net/getLinkedInProfileData",
        {
          method: "POST",
          // mode: 'no-cors',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
            "cache-control": "no-cache",
          },
          cache: "no-cache",

          body: JSON.stringify({ profileUrl }),
        }
      );

      if (response) {
        const jsonResponse = await response.json();

        return jsonResponse;
      }
    } catch (error) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const addUserReview = async (reviewText: string, rating: number) => {
    try {
      const user = await getCurrentUser();
      if (!user) {
        throw new Error("User not found!");
      }

      const customerDetailsSnapshot = await getDoc(
        doc(firebaseDb, `${COLLECTIONS.CUSTOMERS}/${user.uid}`)
      );
      if (customerDetailsSnapshot.exists()) {
        const customerData = customerDetailsSnapshot.data();

        const userReviewsRef = collection(
          firebaseDb,
          COLLECTIONS.MANUAL_REVIEWS
        );
        await addDoc(userReviewsRef, {
          userId: user.uid,
          email: user.email,
          timeStamp: serverTimestamp(),
          reviewText: reviewText,
          rating: rating,
          userName:
            customerData?.first_name + " " + customerData?.last_name || "",
        });
      }

      return {
        isSuccess: true,
      };
    } catch (err) {
      console.error("Error while adding data:", err);
      sentryClient.captureException(err)
    }
  };

  const generateProfileOptimizationResponse = async ({
    goal,
    tone,
    personaId,
    user_input,
    gpt_version,
    user_name,
    last_generated_optimizer,
    command,
  }: {
    goal: string;
    tone: string;
    personaId: string;
    user_input: string;
    gpt_version: string;
    user_name: string;
    last_generated_optimizer: string;
    command: string;
  }) => {
    try {
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(
        functionInstance,
        "generateProfileOptimizationResponse"
      );
      const response = await functionRef({
        goal,
        tone,
        personaId,
        user_input,
        gpt_version,
        user_name,
        command,
        last_generated_optimizer,
      });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const upvoteGeneratedIdea = async ({
    userPrompt,
    idea,
    persona,
  }: {
    userPrompt: string;
    idea: string;
    persona: string;
  }) => {
    try {
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(
        functionInstance,
        "upvoteGeneratedIdea"
      );
      const response = await functionRef({
        userPrompt,
        idea,
        persona,
      });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const downvoteGeneratedIdea = async ({
    userPrompt,
    idea,
    persona,
  }: {
    userPrompt: string;
    idea: string;
    persona: string;
  }) => {
    try {
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(
        functionInstance,
        "downvoteGeneratedIdea"
      );
      const response = await functionRef({
        userPrompt,
        idea,
        persona,
      });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const generatePostIdeas = async (data : {
    prompt: string;
    personaId: string;
    existing_ideas: IGeneratedIdea[];
  } & Record<string,string>) => {    
    try {
      let status = null;
      let isProUser = false;
      if (!subscriptionStatus) {
        const subStatus = await getSubscriptionStatus();
        if (subStatus.statusDB === null && subStatus.status === SubscriptionStatus.PRO) {
          status = SubscriptionStatusDB.ACTIVE;
        } else {
          status = subStatus.statusDB;
        }
        if (status === SubscriptionStatusDB.PAST_DUE) {
          isProUser = false;
        } else {
          isProUser = subStatus.status === SubscriptionStatus.PRO;
        }
        // isProUser = subStatus.status === SubscriptionStatus.PRO;
      } else if (subscriptionStatus) {
        if (subscriptionStatus.statusDB === null && subscriptionStatus.status === SubscriptionStatus.PRO) {
          status = SubscriptionStatusDB.ACTIVE;
        } else {
          status = subscriptionStatus.statusDB;
        }
        if (status === SubscriptionStatusDB.PAST_DUE) {
          isProUser = false;
        } else {
          isProUser = subscriptionStatus.status === SubscriptionStatus.PRO;
        }
        // isProUser = subscriptionStatus.status === SubscriptionStatus.PRO;
      }
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(functionInstance, "generatePostIdeas");
      const response = await functionRef({
        ...data,
        status,
        isProUser,
      });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  async function addTemplate(data: any) {
    try {
      const lowercaseTemplateName = data.templateName.toLowerCase().trim();

      const currentUser = getCurrentUser();

      if (currentUser) {
        const templatesRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
        );

        const querySnapshot1 = await getDocs(query(templatesRef));
        
        const currentUserId =  currentUser.uid;
        const userData = await fetchCurrentUserData(currentUserId)
        const proLimit = userData?.limits?.templates_pro_limit ?? TEMPLATE_LIMIT.PRO;
        const freeLimit = userData?.limits?.templates_free_limit ?? TEMPLATE_LIMIT.FREE;
        if (
          (subscriptionStatus?.status === 0 && querySnapshot1.docs.length >= freeLimit) ||
          (subscriptionStatus?.status === 1 && querySnapshot1.docs.length >= proLimit)
        ) {
          throw new Error("Templates limit reached!");
        }

        for (const doc of querySnapshot1.docs) {
          const existingTemplateName = doc.data().templateName.toLowerCase().trim();

          if (existingTemplateName === lowercaseTemplateName) {
            throw new Error("Template name already exists!");
          }
        }
        if (data.originalTemplateId) {
          const newTemplate = {
            templateName: data.templateName.trim(),
            templateTags: data.templateTags,
            templateContent: data.templateContent,
            originalTemplateId: data.originalTemplateId,
            timestamp: serverTimestamp(),
            modifiedDate: serverTimestamp(),
          };
          await addDoc(templatesRef, newTemplate);
        } else {
          const newTemplate = {
            templateName: data.templateName.trim(),
            templateTags: data.templateTags,
            templateContent: data.templateContent,
            originalTemplateId: null,
            timestamp: serverTimestamp(),
            modifiedDate: serverTimestamp(),
          };
          await addDoc(templatesRef, newTemplate);

        }
      }
    } catch (error: any) {
      sentryClient.captureException(error)
      throw new Error(error.message);
    }
  }

  async function getTemplates(
    sortBy: string = FIELDS.TIMESTAMP,
    order: any = "desc"
  ) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const templatesRef = query(
          collection(
            firebaseDb,
            `${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
          ),
          orderBy(sortBy, order)
        );

        const querySnapshot = await getDocs(templatesRef);
        let templatesArr: any[] = [];
        querySnapshot.forEach((doc) => {
          templatesArr.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        return templatesArr;
      }
    } catch (err) {
      console.log("Error:", err);
      sentryClient.captureException(err)
    }
  }

  async function editTemplate(templateId: string, data: any) {
    try {
      const lowercaseTemplateName = data.templateName.toLowerCase().trim();
      const currentUser = getCurrentUser();
      if (currentUser) {

        const templatesRef = doc(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
          templateId
        );

        const allTemplatesRef = collection(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
        );

        // const templateSnapshot = await getDoc(templatesRef);
        const allTemplatesSnapshot = await getDocs(query(allTemplatesRef));

        for (const doc of allTemplatesSnapshot.docs) {

          if (doc.id !== templateId) {
            const existingTemplateName = doc.data().templateName.toLowerCase().trim();
            if (existingTemplateName === lowercaseTemplateName) {
              throw new Error("Template name already exists!");
            }
          }
        }
        await updateDoc(templatesRef, {
          templateName: data.templateName.trim(),
          templateTags: data.templateTags,
          templateContent: data.templateContent,
          modifiedDate: serverTimestamp(),
        });
      }
    } catch (err: any) {
      console.error(err);
      sentryClient.captureException(err)
      throw new Error(err.message);
    }
  }

  const duplicateTemplate = async (templateId: string) => {
    const textToAdd = " - Copy"
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const templateRef = doc(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
          templateId
        );
        const duplicatedTemplatesRef = query(
          collection(
            firebaseDb,
            `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
          ),
          where("originalTemplateId", "==", templateId))

        const querySnapshot = await getDoc(templateRef);
        let data = querySnapshot.data();
        const duplicatedQuerySnapshot = await getDocs(duplicatedTemplatesRef);
        if (duplicatedQuerySnapshot.docs.length > 0) {
          const totalNumOfDuplicatedTemplates = duplicatedQuerySnapshot.docs.length + 1;
          if (data) {
            data = { ...data, templateName: data.templateName + textToAdd + " " + `(${totalNumOfDuplicatedTemplates})`, originalTemplateId: templateId }
            await addTemplate(data);
            console.log("Template duplicated successfully");
            return
          }
        } else {
          if (data) {
            data = { ...data, templateName: data.templateName + textToAdd, originalTemplateId: templateId }
            await addTemplate(data);
            console.log("Template duplicated successfully");
            return
          }
        }
      }
    } catch (err) {
      console.log("Error duplicating template:", err);
      sentryClient.captureException(err)
    }
  };

  // const duplicateTemplate = async (templateId: string) => {
  //   const textToAdd = " - Copy"
  //   const regex = /- Copy(?:\(\d+\))?$/;
  //   const regex2 = / - Copy$/;
  //   try {
  //     // const currentUser = getCurrentUser();

  //     // if (currentUser) {
  //     //   const templateRef = doc(
  //     //     firebaseDb,
  //     //     `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
  //     //     templateId
  //     //   );
  //     //   const querySnapshot = await getDoc(templateRef);
  //     //   const templateDataWithId = querySnapshot.data();
  //     //   if (templateDataWithId) {
  //     //     // console.log(data);

  //     //     // const { templateName } = data;
  //     //     // if (!templateName.includes("- Copy")) {
  //     //     //   data.templateName = data.templateName + textToAdd
  //     //     //   await addTemplate(data);
  //     //     //   console.log("Template duplicated successfully");
  //     //     //   // const lastIndexOfDash = templateName.lastIndexOf('-');
  //     //     // } else {


  //     //     // }
  //     //     // const allTemplatesRef = collection(
  //     //     //   firebaseDb,
  //     //     //   `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
  //     //     // );
  //     //     const allTemplatesRef = query(
  //     //       collection(
  //     //         firebaseDb,
  //     //         `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`
  //     //       ),
  //     //       orderBy("templateName", "asc"),
  //     //     );
  //     //     const allTemplatesSnapshot = await getDocs(allTemplatesRef);
  //     //     let data: any = []
  //     //     let bool: boolean = false;

  //     //     if (!(regex2.test(templateDataWithId.templateName) || regex.test(templateDataWithId.templateName))) {
  //     //       if (!allTemplatesSnapshot.empty) {
  //     //         allTemplatesSnapshot.forEach(async (template) => {
  //     //           const { templateName } = template.data();
  //     //           if (templateName === templateDataWithId.templateName && regex2.test(templateName) && regex2.test(templateName)) {
  //     //             data.push({...template.data(), templateName: templateName + textToAdd})
  //     //             bool = true;
  //     //           }
  //     //         })
  //     //       }
  //     //     } else if (regex2.test(templateDataWithId.templateName)) {

  //     //     }

  //     //     if (!allTemplatesSnapshot.empty) {
  //     //       allTemplatesSnapshot.forEach(async (template) => {
  //     //         let templateData = template.data();

  //     //         if (!(regex2.test(templateDataWithId.templateName) || regex.test(templateDataWithId.templateName))) {

  //     //         }

  //     //         if (regex2.test(templateDataWithId.templateName)) {
  //     //           const tempName = templateDataWithId.templateName.replace(regex2, '');
  //     //           console.log("TEMP NAME", tempName);

  //     //           if (templateData.templateName.includes(tempName) && regex.test(templateData.templateName)) {
  //     //           }

  //     //         }
  //     //         if (templateData.templateName.includes(templateDataWithId.templateName) && regex.test(templateData.templateName)) {
  //     //           data.push(templateData)
  //     //           console.log("MATCHED");


  //     //         // // if (!templateData.templateName.includes(templateDataWithId.templateName + textToAdd)) {
  //     //         //   templateData = {...templateData, templateName: templateDataWithId.templateName + textToAdd.slice(-1)}
  //     //         //   // templateData.templateName = templateData.templateName + textToAdd.slice(-1);
  //     //         //   bool = false;

  //     //         //   console.log(templateData);

  //     //         //   // await addTemplate(data);
  //     //         }
  //     //         // else if (templateData.templateName.includes(textToAdd)) {
  //     //         //   data.push({...templateData, templateName: templateData.templateName + `(${allTemplatesSnapshot.docs.length})`})
  //     //         // }
  //     //         // return {
  //     //         //   ...template.data(),
  //     //         // }
  //     //       })
  //     //     }
  //     //     console.log("DATAA", data);

  //     //     // data.templateName = data.templateName + textToAdd
  //     //   }
  //     //   // await addTemplate(data);
  //     //   // console.log("Template duplicated successfully");
  //     // }
  //   } catch (err) {
  //     console.log("Error duplicating template:", err);
  //   }
  // };

  const deleteTemplate = async (templateId: string) => {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const templateRef = doc(
          firebaseDb,
          `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
          templateId
        );
        await deleteDoc(templateRef);
        console.log("Template deleted successfully");
      }
    } catch (err) {
      console.log("Error deleting template:", err);
      sentryClient.captureException(err)
    }
  };

  async function deleteMultipleTemplates(templateIds: string[]) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const promises = templateIds.map(async (templateId) => {
          const templateRef = doc(
            firebaseDb,
            `/${COLLECTIONS.TEMPLATES}/${currentUser.uid}/${COLLECTIONS.TEMPLATES}`,
            templateId
          );
          await deleteDoc(templateRef);
        });
        await Promise.all(promises);
        console.log("All templates deleted successfully");
      }
    } catch (err) {
      console.log("Error deleting templates:", err);
      sentryClient.captureException(err)
    }
  }

  const addPersona = async (data: any) => {
    try {
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(functionInstance, "savePersona");
      const response = await functionRef({ ...data });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const editPersona = async (data: any) => {
    try {
      const functionInstance = getFunctions(firebaseApp);
      const functionRef = httpsCallable(functionInstance, "editPersona");
      const response = await functionRef({ ...data });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  const deletePersona = async (personaId: string) => {
    try {
      const functionInstance = getFunctions(firebaseApp);

      const functionRef = httpsCallable(functionInstance, "deletePersona");
      const response = await functionRef({ personaId });
      return response;
    } catch (error: any) {
      sentryClient.captureException(error)
      throw error;
    }
  };

  async function deleteMultiplePersonas(personaIds: string[]) {
    try {
      const currentUser = getCurrentUser();

      if (currentUser) {
        const promises = personaIds.map(async (personaId) => {
          const profileRef = doc(
            firebaseDb,
            `/${COLLECTIONS.PERSONAS}/${currentUser.uid}/${COLLECTIONS.PERSONAS}`,
            personaId
          );
          await deleteDoc(profileRef);
        });
        await Promise.all(promises);
        console.log("All profiles deleted successfully");
      }
    } catch (err) {
      console.log("Error deleting profiles:", err);
      sentryClient.captureException(err)
    }
  }

  const handleUploadPersonaImage = async (image: any) => {
    const ImageResponse = await fetch(image);
    const blob = await ImageResponse.blob();
    const id = nanoid();

    const storageRef = ref(storage, `personas/${id}`);

    return await uploadBytes(storageRef, blob).then(async (_snapshot) => {
      return await getDownloadURL(ref(storageRef))
        .then((url) => {
          console.log(url, "url");
          return url;
        })
        .catch((error) => {
          sentryClient.captureException(error)
          throw error;
        });
    });
  };

  const handleUploadProfileImage = async (image: any) => {
    const ImageResponse = await fetch(image);
    const blob = await ImageResponse.blob();
    const id = nanoid();

    const storageRef = ref(storage, `profile_pictures/${firebaseAuth.currentUser?.uid}/${id}`);

    return await uploadBytes(storageRef, blob).then(async (_snapshot) => {
      return await getDownloadURL(ref(storageRef))
        .then((url) => {
          return url;
        })
        .catch((error) => {
          sentryClient.captureException(error)
          throw error;
        });
    });
  };

  const getPersonasList = async () => {
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId) return [];
    try {
      const queryToGetDocs = query(
        collection(
          firebaseDb,
          `${COLLECTIONS.PERSONAS}/${userId}/${COLLECTIONS.PERSONAS}`
        ),
        orderBy(FIELDS.TIMESTAMP, "desc")
      );
      const personasSnapshot = await getDocs(queryToGetDocs);

      const data = personasSnapshot.docs.map((document) => {
        return {
          id: document.id,
          ...document.data(),
        };
      });

      return data;
    } catch (error: any) {
      sentryClient.captureException(error)
      return [];
    }
  };

  const addLinkedInProfile = async (profileUrl: string) => {
    try {
      const userId = firebaseAuth.currentUser?.uid;
      if (!userId) return;
      const profileDetail = await fetchProfileDetails(profileUrl);
      console.log(profileDetail, "profile details");

      const functionInstance = getFunctions(firebaseApp);

      const functionRef = httpsCallable(
        functionInstance,
        "saveLinkedInProfileInDatabase"
      );
      const response = await functionRef({
        userId,
        profileUrl,
        linkedInProfileName: `${profileDetail?.firstName + profileDetail?.lastName
          }`,
        profileHeadline: profileDetail?.title,
        profilePictureUrl: profileDetail?.profilePictureUrl,
      });
      console.log({ response });
      return response;
    } catch (error: any) {
      console.log("### error while getCustomerSubscriptionPortalLink...");
      console.log({ error });
      sentryClient.captureException(error)
      throw new error();
    }
  };

  const checkProfileExistence = async (userId: String, profileUrl: String) => {
    const collectionRef = collection(
      firebaseDb,
      `/${COLLECTIONS.SAVED_LINKEDIN_PROFILES}/${userId}/${COLLECTIONS.PROFILES}`
    );

    const querySnapshot = await getDocs(
      query(collectionRef, where("profileUrl", "==", profileUrl))
    );
    return !querySnapshot.empty;
  };

  const addLinkedInProfileWithURL = async (profileData: {
    profileUrl: String;
    linkedInProfileName: String;
    profilePictureUrl: String;
  }) => {
    try {
      const currentUser = getCurrentUser();

      if (currentUser !== null && currentUser.uid && profileData) {
        const functionInstance = getFunctions(firebaseApp);

        let status = null;
        let isProUser = false;
        if (!subscriptionStatus) {
          const subStatus = await getSubscriptionStatus();
          if (subStatus.statusDB === null && subStatus.status === SubscriptionStatus.PRO) {
            status = SubscriptionStatusDB.ACTIVE;
          } else {
            status = subStatus.statusDB;
          }
          if (status === SubscriptionStatusDB.PAST_DUE) {
            isProUser = false;
          } else {
            isProUser = subStatus.status === SubscriptionStatus.PRO;
          }
          // isProUser = subStatus.status === SubscriptionStatus.PRO;
        } else if (subscriptionStatus) {
          if (subscriptionStatus.statusDB === null && subscriptionStatus.status === SubscriptionStatus.PRO) {
            status = SubscriptionStatusDB.ACTIVE;
          } else {
            status = subscriptionStatus.statusDB;
          }
          if (status === SubscriptionStatusDB.PAST_DUE) {
            isProUser = false;
          } else {
            isProUser = subscriptionStatus.status === SubscriptionStatus.PRO;
          }
          // isProUser = subscriptionStatus.status === SubscriptionStatus.PRO;
        }

        const existingProfile = await checkProfileExistence(
          currentUser.uid,
          profileData.profileUrl
        );

        if (existingProfile) {
          console.error("Profile with the same URL already exists");
          throw new Error("Profile with the same URL already exists");
        }

        const functionRef = httpsCallable(
          functionInstance,
          "saveLinkedInProfileInDatabase"
        );

        console.log("data to send", {
          userId: currentUser.uid,
          profileUrl: profileData.profileUrl,
          linkedInProfileName: profileData.linkedInProfileName,
          profileHeadline: " ",
          profilePictureUrl: profileData.profilePictureUrl,
          status,
          isProUser,
        });

        const response = await functionRef({
          userId: currentUser.uid,
          profileUrl: profileData.profileUrl,
          linkedInProfileName: profileData.linkedInProfileName,
          profileHeadline: " ",
          profilePictureUrl: profileData.profilePictureUrl,
          status,
          isProUser,
        });
        console.log("LinkedIn profile added successfully");
        console.log({ response });
        return response;
      }
    } catch (error) {
      console.error(error, "error");
      sentryClient.captureException(error)
      throw error;
    }
  };

  const signOut = (isNavigate = true) => {
    return new Promise((resolve) => {
      firebaseAuth.signOut().then(() => {
        setUser(null);
        setIsNavigateToSub(false);
        setEmailVerified(false);
        localStorage.clear();
        // localStorage.removeItem("C_S_ID");
        // localStorage.removeItem("C_U_");
        sendMessageToExtensionPages(MESSAGE_ACTION.GET_WEB_APP_AUTH_SIGN_OUT, {});
        if (isNavigate) {
          navigateTo(Navigate_to.login);
        }
        resolve(true);

      });
      resolve(true);
      // localStorage.removeItem("C_S_ID");
      // localStorage.removeItem("C_U_");
      sendMessageToExtensionPages(MESSAGE_ACTION.GET_WEB_APP_AUTH_SIGN_OUT, {});
      if (isNavigate) {
        navigateTo(Navigate_to.login);
      }
      setEmailToChange("");
    });
  };

  const addQuestionOrIssues = async (question: string) => {
    try {
      if (firebaseAuth.currentUser !== null && user !== null) {
        const questionRef = collection(
          firebaseDb,
          `${COLLECTIONS.QUESTIONS}/${firebaseAuth?.currentUser?.uid}/${COLLECTIONS.USER_QUERIES}`
        );
        await addDoc(questionRef, {
          question,
          timestamp: serverTimestamp(),
          email: user?.email,
          userName: user?.first_name + " " + user?.last_name || "",
        });
      }
    } catch (error) {
      sentryClient.captureException(error)
      throw new Error(error as string);
    }
  };

  useEffect(() => {
    InitializeUser();
    reloadCurrentUser();
    const isNavigateToSub = queryParams.get("isNavigateToSubscription-pricing");
    const teamId = queryParams.get('team_id');
    const memberId = queryParams.get('member_id');

    // console.log("teamId: " + teamId + " memberId: " + memberId);
    if (isNavigateToSub) {
      setIsNavigateToSub(true);
    } else if (teamId && memberId) {
      setIsTeamInviteUrl(true);
      setTeamInviteAcceptParams({ team_id: teamId, member_id: memberId });
    }
  }, []);

  return (
    <AuthContextProvider
      value={{
        sendEmailToResetPassword,
        sendVerificationEmail,
        getCurrentUser,
        getCurrentUserIdToken,
        reloadCurrentUser,
        signOut,
        signInWithGoogle,
        signInWithEmail,
        signUpWithEmail,
        updateUserProfile,
        fetchUserDataById,
        fetchUserDataByEmail,
        updateWelcomeTourStatus,
        updateTeamAcceptedNotificationStatus,
        updateTeamSeats,
        fetchCurrentUserData,
        fetchSingleUserActivity,
        getRecentActivities,
        getSavedCommands,
        addCommandData,
        deleteCommandData,
        deleteActivity,
        handleExtensionMessages,
        deleteAccount,
        getSubscriptionPlans,
        goToSubscriptionPlanPaymentURL,
        getSubscriptionStatus,
        getCustomerSubscriptionPortalLink,
        fetchSingleData,
        getUserSubscriptionData,
        addUserReview,
        isSendingVerificationEmail,
        isMSubcribeLinkGenerating,
        isStripeLinkGenerating,
        user,
        teamInvites,
        // newTeamInvite,
        setUser,
        subscriptionData,
        subscriptionStatus,
        userSnapShot,
        setIsNavigateToSub,
        isNavigateToSub,
        setIsTeamInviteUrl,
        isTeamInviteUrl,
        teamInviteAcceptParams,
        emailVerified,
        setEmailVerified,
        isLoadingUser,
        User: firebaseAuth.currentUser,
        deleteLinkedinProfile,
        fetchSuggestedComments,
        deletePersona,
        deleteTemplate,
        duplicateTemplate,
        deleteMultipleTemplates,
        getTemplates,
        addTemplate,
        editTemplate,
        addPersona,
        getEnableTrail,
        getTrialWarning,
        disableTrialWarning,
        editPersona,
        getPersonas,
        handleUploadPersonaImage,
        deleteImageFromStorage,
        getPersonasList,
        createTeam,
        createList,
        createTag,
        getTagById,
        deleteTag,
        handleGetUserLists,
        updateList,
        updateTag,
        deleteList,
        deleteMultipleTags,
        deleteMultipleLists,
        deleteMultipleProfiles,
        updateProfileToLists,
        addProfilesToLists,
        generateProfileOptimizationResponse,
        generatePostIdeas,
        upvoteGeneratedIdea,
        downvoteGeneratedIdea,
        addLinkedInProfile,
        addLinkedInProfileWithURL,
        deleteMultiplePersonas,
        addQuestionOrIssues,
        handleUploadProfileImage,
        addMembersToTeam,
        fetchInviteStatus,
        fetchTeamInvites,
        fetchMemberById,
        fetchTeamById,
        acceptTeamInvite,
        declineTeamInvite,
        deleteUserFromTeam,
        editTeamName,
        fetchSubscriptionStatusByUserId,
        updateTeamSize,
        handleUpdateTeamSize,
        updateSubscriptionToTeamsDirectly,
        emailToChange,
        setEmailToChange,
        verificationTime,
        setVerificationTime,
        showModalType,
        setShowModalType
      }}
    >
      {isLoading ? <LodingPageLoader /> : children}
    </AuthContextProvider>
  );
}

export default AuthContext;
