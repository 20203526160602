export const learningHubItems = [
    {
        title: "Quick Links",
        description: "Find quick links to parts of LinkedIn and related resources. This list is designed to help you quickly find the most important LinkedIn information so you can be as productive as possible.",
    },
    {
        title: "Courses",
        description: "View our LinkedIn 101 Tutorial and other courses to help you get started the right way on LinkedIn.",
    },
    {
        title: "evyAI Resources",
        description: "View additional resources and tutorials to help you get the most out of evyAI.",
    }
]