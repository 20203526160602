import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { toastAlertSuccess } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import { IPersona } from "..";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Check from "../../../assets/Images/primaryCheck.svg";
import Uncheck from "../../../assets/Images/primaryUncheck.svg";
import SortArrow from "../../../assets/Images/Sort.svg"
import Dots from "../../../assets/Images/Dots.svg";
import useScreenSize from "../../../hooks/useScreenSize";
interface PersonasTableProps {
    personas: IPersona[];
    setPersonas: React.Dispatch<React.SetStateAction<IPersona[]>>;
    headers: any[];
    setDeletePersonaIds: (listIds: string[]) => void;
    deletePersonaIds: string[];
}
const PersonasTable: React.FC<PersonasTableProps> = (props) => {
    const navigate = useNavigate()
    const { deletePersona } = useContext(AuthContext) as AuthContext_type;
    const { personas, setPersonas, setDeletePersonaIds, deletePersonaIds } = props;
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [toDeleteId, setToDeleteId] = useState<string | null>(null);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [personasPerPage, setPersonasPerPage] = useState(10);
    const [dropDown, setDropDown] = useState<any>(null)

    const dropDownRef = useRef<HTMLDivElement | null>(null);


    const screen = useScreenSize();

    const indexOfLastPersona = currentPage * personasPerPage;
    const indexOfFirstPersona = indexOfLastPersona - personasPerPage;
    const currentPersonas = personas.slice(indexOfFirstPersona, indexOfLastPersona);

    useEffect(() => {
        setIsAllChecked(currentPersonas.every(persona => deletePersonaIds.includes(persona.id)));
    }, [deletePersonaIds, currentPersonas]);

    const handleSetItemsPerPage = (count: number) => {
        setPersonasPerPage(count);
        setCurrentPage(1);
    };

    const handleDelete = (personaId: string) => {
        deletePersona(personaId);
        toastAlertSuccess("Deleted Succefully")
        setPersonas((prevLists: IPersona[]) => prevLists.filter(persona => persona.id !== personaId));
    };

    const confirmDelete = () => {
        if (toDeleteId) {
            handleDelete(toDeleteId);
            setToDeleteId(null);
        }
        setShowConfirmation(false);
    };

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newDeleteListsIds = deletePersonaIds.filter(id => !currentPersonas.map(persona => persona.id).includes(id));
            setDeletePersonaIds(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...deletePersonaIds, ...currentPersonas.map(persona => persona.id)];
            setDeletePersonaIds(newDeleteListsIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    const handlePersonaCheckboxClick = (listId: string) => {
        if (deletePersonaIds.includes(listId)) {
            const newDeleteListsIds = deletePersonaIds.filter(id => id !== listId);
            setDeletePersonaIds(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...deletePersonaIds, listId];
            setDeletePersonaIds(newDeleteListsIds);
        }
    };

    const initiateDelete = (personaId: string) => {
        setToDeleteId(personaId);
        setShowConfirmation(true);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleClickOutside = (event: MouseEvent) => {
        const targetNode = event.target as Node;

        if (dropDownRef.current && !dropDownRef.current.contains(targetNode)) {
            setDropDown(false);
        }

    };

    useEffect(() => {
        if (dropDown !== null) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDown]);

    return (
        <div className="mt-4">
            <table className="w-full text-sm text-left bg-white rounded-xl">
                <thead className="font-poppins text-base text-gray_dark w-8 border-b border-gray-400 flex-row">
                    <tr>
                        <th scope="col" className="xsm:w-[15%] md:w-[10%] lg:w-[4%]">
                            <img
                                className="mx-auto"
                                src={isAllChecked ? Check : Uncheck}
                                width={25}
                                height={25}
                                onClick={handleHeaderCheckboxClick}
                                alt="Checkbox"
                            />
                        </th>

                        <th scope="col" className="py-3 px-3" style={{ width: "45%" }}>
                            <div className="flex justify-start items-center gap-2 xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">
                                Name
                                <img className="md:hidden" src={SortArrow} alt="" />
                            </div>
                        </th>
                        {(screen !== "small" && screen !== "medium") &&
                            <th scope="col" className="py-3 px-3" style={{ width: "40%" }}>
                                Persona Type
                            </th>
                        }
                        <th scope="col" className="flex py-3 px-3" >
                            <div className="xsm:ml-[6.5rem] flex justify-end w-full xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">

                                Actions
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentPersonas.map((persona, index) => {
                        const isChecked = deletePersonaIds.includes(persona.id);
                        return (
                            <tr
                                key={persona.id}
                                className={`xsm:h-[100px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === personas.length - 1 ? "" : "border-b border-gray-400"}`}
                            >
                                <td valign="top" className="px-3 xsm:px-0 py-3">
                                    <img
                                        className="mx-auto"
                                        src={isChecked ? Check : Uncheck}
                                        width={25}
                                        height={25}
                                        onClick={() => handlePersonaCheckboxClick(persona.id)}
                                    />
                                </td>
                                <td colSpan={screen === "small" ? 2 : 1} className="py-3 px-3">
                                    <div className="flex profiles-center items-center gap-2 xsm:items-start">
                                        <div className="max-w-[32px] w-[44px] h-[32px] rounded-lg overflow-hidden">
                                            <img
                                                src={
                                                    persona.profilePictureUrl
                                                        ? persona.profilePictureUrl
                                                        : "https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
                                                }
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-3 justify-center ml-4 items-start w-full">
                                            <p
                                                className=" color-[#000] xsm:text-[#444553] font-poppins text-[20px] xsm:text-[14px] xsm:font-bold font-semibold"
                                                // className="w-full" 
                                                style={{
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "1",
                                                    WebkitBoxOrient: 'vertical',
                                                    textOverflow: 'ellipsis',
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {persona?.personaName}
                                            </p>
                                            {/* <p 
                                                >
                                                {persona?.personaName}
                                            </p> */}
                                            <button className="md:hidden -ml-[0.2rem] cursor-pointer rounded-xl text-primary_color font-semibold text-[14px] bg-chip_bg px-3 py-[3px] mr-0">
                                                {persona?.Tag}
                                            </button>
                                        </div>
                                        <div className="md:hidden relative flex justify-end items-center">
                                            <img className="md:hidden w-[25px]" src={Dots} alt="" onClick={() => setDropDown((prev: any) => (prev === index ? null : index))} />
                                            {(dropDown === index) &&
                                                <div ref={dropDownRef} className="absolute mt-[8.5rem] bg-white z-[1] w-[120px] dropDown-shadow p-1 flex flex-col ">
                                                    <span className="flex justify-start items-center p-4 font-poppins text-[16px] xsm:text-[14px] font-medium"
                                                        onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}
                                                    >
                                                        Edit
                                                    </span>
                                                    <span className="flex justify-start items-center p-4 font-poppins text-[16px] xsm:text-[14px] text-[#EB5757] font-medium" onClick={() => {
                                                        setDropDown(null);
                                                        initiateDelete(persona.id)
                                                    }
                                                    }>Delete</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </td>
                                {(screen !== "small" && screen !== "medium") &&
                                    <td className="py-3 px-3">
                                        <button className="cursor-pointer rounded-xl text-primary_color font-semibold text-[16px] bg-chip_bg px-3 py-[3px] mr-0">
                                            {persona?.Tag}
                                        </button>
                                    </td>
                                }
                                <td className="xsm:hidden justify-end align-middle py-3 px-3">
                                    <img className="md:hidden relative w-[25px]" src={Dots} alt="" onClick={() => setDropDown((prev: any) => (prev === index ? null : index))} />
                                    {(dropDown === index) &&
                                        <div className="absolute mt-6 bg-white z-[1] w-[120px] dropDown-shadow p-1 flex flex-col ">
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}
                                            >
                                                Edit
                                            </span>
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => {
                                                setDropDown(null);
                                                initiateDelete(persona.id)
                                            }
                                            }>Delete</span>
                                        </div>
                                    }
                                    <div className="xsm:hidden sm:hidden md:flex flex justify-between items-center">
                                        <button className='underline bg-transparent text-secondry_color font-bold text-underline px-3 py-3 rounded-lg'
                                            onClick={() => initiateDelete(persona.id)}
                                        >
                                            Delete
                                        </button>
                                        <button className='all_sType_buttons'
                                            onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}>
                                            Edit
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    <Pagination
                        colSpan={4}
                        itemsPerPage={personasPerPage}
                        totalItems={personas.length}
                        currentPage={currentPage}
                        paginate={paginate}
                        setItemsPerPage={handleSetItemsPerPage}
                    />
                </tbody>
            </table>
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={confirmDelete}
                header="Delete Persona"
                content="Are you sure you want to delete this persona?"
            />
        </div>
    );
}

export default PersonasTable;