import { useContext, useEffect, useRef, useState } from "react";
import TextInput from "../../../components/TextInput";
import { Controller } from "react-hook-form";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ActionCard from "./ActionCard";
import deleteIcon from "../../../assets/Images/Trash.png";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import CopyIcon from "../../../assets/svgs/CopyIcon";
import { SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE, SubscriptionStatus, SubscriptionStatusDB } from "../../../config/firebase";
import DownArrow from "../../../assets/svgs/DownArrow";
import UpArrow from "../../../assets/svgs/UpArrow";
import { Tags } from "../AddTemplate";
import CloseIcon from "../../../assets/svgs/CloseIcon";
import PlusIcon from "../../../assets/Images/Plus.svg"
import CreateTag from "./CreateTag";
import { sentryClient } from "@/Sentry/sentry";

const AddTemplateForm = (props: any) => {
  const {
    formControl,
    formErrors,
    isSubmitting,
    isLoading,
    userTags,
    allTags,
    setAllTags,
    toggleDataChanged,
    getValues,
    reset,
    selectedTagsDoc,
    // selectedTags,
    // selectedTagsNames,
    addTags,
    setShowUpdatePaymentDialog,
  } = props;
  
  const { templateId } = useParams();

  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();
  const { createTag, deleteTag, deleteTemplate, subscriptionStatus } = useContext(AuthContext) as AuthContext_type;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [previousWidth, setPreviousWidth] = useState<number | null>(null);
  const [showDropDown, setShowDropDown] = useState(false)
  const [tagsToAdd, setTagsToAdd] = useState<Tags[]>([])
  const [remainingTagsToShow, setRemainingTagsToShow] = useState<Tags[]>([])
  const [currentTag, setCurrentTag] = useState("")
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [toDeleteTag, setToDeleteTag] = useState<string | null>(null);
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const [showCreateTagDialogue, setShowCreateTagDialogue] = useState(false);
  const [showDeleteTagDialogue, setShowDeleteTagDialogue] = useState(false);

  // const [showFreeTrail, setShowFreeTrail] = useState(false)

  const previousRef = useRef<HTMLDivElement>(null);
  const absoluteRef = useRef<HTMLDivElement | any>(null);

  async function canAddNewTag(tags_count: any) {
    // const subscriptionStatus = await getSubscriptionStatus();
    
    
    if (subscriptionStatus) {
      if (
        tags_count >= 10 &&
        subscriptionStatus.status === SubscriptionStatus.PRO &&
        subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE
      ) {
        setShowUpdatePaymentDialog(true);
        return {result: true, showToast: false};
      } else if (
        tags_count >= 10 &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        // setShowFreeTrail(true);
        return {result: true, showToast: true};
      } else if (
        tags_count >= 1000 &&
        subscriptionStatus.status !== SubscriptionStatus.FREE
      ) {
        // setShowFreeTrail(true);
        return {result: true, showToast: true};
      } else {
        // setShowFreeTrail(false);
        return {result: false, showToast: true};
      }
    } else {
      return {result: false, showToast: false};
    }
    
  }

  const handleKeyPress = async (event?:any, tag?: Tags) => {

    if (!event && tag) {
      const values = getValues()
      const newValue = {
        ...values,
        templateTags: "",
      };
      setTagsToAdd([...tagsToAdd, {id: tag.id, name: tag.name}])
      reset(newValue)
      setCurrentTag("")
      setShowDropDown(false);
      setIsAddingTag(false);
      return;
    }

    if (event.type === "keydown" && event.key === "Enter") {

      !tag && setIsAddingTag(true);
      const values = getValues()
      !tag && event.preventDefault();
      const tagToAdd: string = (!tag && event.target.value).trim() || currentTag.trim();
      if (tagToAdd.length === 0) {
        return
      }
      const newValue = {
        ...values,
        templateTags: "",
      };

      try {
        
        const canAddTag = await canAddNewTag(userTags.length)
        if (!canAddTag.result) {
          if (!tag) {
            const isAlreadyThere: Tags = userTags.find((existingTag: Tags) => {
              if (existingTag.name === tagToAdd) {
                return existingTag;
              } else {
                return false;
              }
            })
            const isAlreadyThereInTagsToAdd = tagsToAdd.some((existingTag: Tags) => {
              if (existingTag.name === tagToAdd) {
                return true;
              } else {
                return false;
              }
            })
            
            if (isAlreadyThere && !isAlreadyThereInTagsToAdd) {
              toggleDataChanged();
              setTagsToAdd([...tagsToAdd, {id: isAlreadyThere.id, name: isAlreadyThere.name}])
              reset(newValue)
              event.target.value = '';
              setCurrentTag("")
              setShowDropDown(false);
              setIsAddingTag(false);
              return;
            } else if (isAlreadyThere && isAlreadyThereInTagsToAdd) {
              reset(newValue)
              event.target.value = '';
              setCurrentTag("")
              toastAlertError("Tag already added!");
              return
              // // toggleDataChanged();
              // // setTagsToAdd([...tagsToAdd, {id: isAlreadyThere.id, name: isAlreadyThere.name}])
              // setShowDropDown(false);
              // setIsAddingTag(false);
              // return;
            } else if (!isAlreadyThere) {
              const createdTagId = await createTag(tagToAdd)
              if (createdTagId !== undefined) {
                toggleDataChanged();
                setTagsToAdd([...tagsToAdd, {id: createdTagId, name: tagToAdd}])
                reset(newValue)
                event.target.value = '';
                setCurrentTag("")
                canAddTag.showToast && toastAlertSuccess("Tag created successfully");
                setShowDropDown(false);
                setIsAddingTag(false);
              } else {
                setIsAddingTag(false);
                throw new Error(createdTagId)
              }
            }
          } else if (tag) {
            setTagsToAdd([...tagsToAdd, {id: tag.id, name: tag.name}])
            reset(newValue)
            setCurrentTag("")
            canAddTag.showToast && toastAlertSuccess("Tag created successfully");
            setShowDropDown(false);
            setIsAddingTag(false);
          }
        } else {
          canAddTag.showToast && toastAlertError(`You have reached the limit of ${subscriptionStatus?.status === SubscriptionStatus.FREE ? "10" : "1000"} tags`)
          setIsAddingTag(false);
        }
      } catch (error: any) {
        console.log("error", error);
        toastAlertError(error.message);
        setIsAddingTag(false);
        sentryClient.captureException(error)
      }
    }
  };
  
  const handleDeleteTag = async (tagId: string) => {
    if (toDeleteTag) {
      setToDeleteTag(null);
    }
    if (tagId) {
      setShowDeleteTagDialogue(true);
      setToDeleteTag(tagId);
    } else {
      return;
    }
  }
  
  const handleCreateTagUsingButton = async () => {
    const canAddTag = await canAddNewTag(userTags.length)
    if (!canAddTag.result) {
      setShowCreateTagDialogue(true);
    } else {
      setShowCreateTagDialogue(false);
    }
  }
  
  const confirmDelete = async () => {
    setIsDeletingTag(true);
    if (toDeleteTag) {
      const isDeleted = await deleteTag(toDeleteTag);
      if (isDeleted) {
        toastAlertSuccess("Tag Deleted Successfully!")
        toggleDataChanged()
      }
      // setShowDropDown(false);
      setToDeleteTag(null);
      setShowDeleteTagDialogue(false);
    }
    setIsDeletingTag(false);
  }

  useEffect(() => {

    toggleDataChanged()
    const remainingTags = userTags.filter((tag:Tags) => {
      return !tagsToAdd.some((alreadyAddedTag: Tags) => alreadyAddedTag.id === tag.id);
    })
    setAllTags(remainingTags)
    setRemainingTagsToShow(remainingTags)
    addTags(tagsToAdd)
    
  }, [tagsToAdd])
  
  useEffect(() => {

    const remainingTags = allTags.filter((tag:Tags) => !tagsToAdd.some((alreadyAddedTag: Tags) => alreadyAddedTag.id === tag.id))
    setAllTags(remainingTags)
    setRemainingTagsToShow(remainingTags)

  }, [userTags])
  
  useEffect(() => {

    if (templateId) {
      setTagsToAdd(selectedTagsDoc)
    }

  }, [])
  

  const addTagToAdd = (tag: Tags) => {

    setShowDropDown(false);
    setTagsToAdd([...tagsToAdd, tag])
    setCurrentTag("")

  }

  const removeTagToAdd = (tagToRemove: Tags) => {

    const newArr = tagsToAdd.filter(tag => tag.id !== tagToRemove.id)
    setTagsToAdd(newArr)

  }

  const handleClickOutside = (event: MouseEvent) => {
    const targetNode = event.target as Node;

    if (absoluteRef.current && !absoluteRef.current.contains(targetNode)) {
        setShowDropDown(false);
    }
  };

  useEffect(() => {
      if (showDropDown) {
          document.addEventListener("mousedown", handleClickOutside);
      } else {
          document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [showDropDown]);

  const searchTags = (e: any) => {
    const value = e.target ? e.target.value : e;
    setCurrentTag(value)
    e.target && setShowDropDown(true);

    if (value === "") {
      setRemainingTagsToShow(allTags);
    } else {
      const filteredTemplates = allTags.filter((tag:Tags) => 
        tag.name.toLowerCase().includes(value.toLowerCase())
        );
      // setAllTags(filteredTemplates);
      setRemainingTagsToShow(filteredTemplates);
    }

  }

  useEffect(() => {
    if (currentTag === "") {
      searchTags(currentTag)
    }
  }, [currentTag])
  

  useEffect(() => {
    userTags.map((tag: Tags) => {
      if (tag.name === currentTag) {
        handleKeyPress(false, tag);
      } else {
        return
      }
    })
    
  }, [userTags])
  

  const handleDeleteTemplate = async () => {
    try {
      setIsDeleting(true)
      if (templateId) {
        await deleteTemplate(templateId);
        setShowConfirmation(false);
        toastAlertSuccess("Deleted Successfully!");
        navigate(`/${PROTECTED_ROUTES.templates}`);
      }
    } catch (error) {
      sentryClient.captureException(error)
    } finally {
      setIsDeleting(false)
    }
  };

  const copyTextToClipBoard = (text:string) => {
    console.log("### text will be copied to clipboard");
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
        setTimeout(() => {}, SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE * 1000);
      })
      .catch((error) => {
        console.log("### Error while copying content to clipboard");
        console.log({ error });
        sentryClient.captureException(error)
      });
  };


  useEffect(() => {
  }, [isSubmitting]);

  useEffect(() => {

    if (previousRef.current) {
      const newWidth = previousRef.current.offsetWidth;
      setPreviousWidth(newWidth);
    }

    const handleResize = () => {
      if (previousRef.current && absoluteRef.current) {
        const newWidth = previousRef.current.offsetWidth;
        setPreviousWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showDropDown]);

  useEffect(() => {
    if (previousWidth !== null || absoluteRef.current) {
      absoluteRef.current.style.width = previousWidth + 'px';
    }
  }, [previousWidth]);

  return (
    <div className="flex justify-between my-6 gap-6">
      <div className="w-full max-w-[1080px] xsm:p-4 p-8 bg-white rounded-lg flex flex-col gap-y-6">
        <div>
        <h2 className="font-poppins font-bold text-[22px]">
            {templateId ? "Edit Template" : "New Template"}
          </h2>

          {<p className="font-poppins text-[18px] text-[#333333] mt-4">
            <a style={
              {
                color: "#6D40C4",
                fontWeight: "600",
              }
            } className="" href="https://www.skool.com/evyai/classroom/a1594949?md=9698e249437b4dc4b429856d987f9dcf" target="_blank" >Click here</a> to learn how to create templates
          </p> }
            {/* } className="" href="https://evyai.com/personalize-to-shine-linkedin-evyai/" target="_blank" >Click here</a> to learn how to create templates */}
        </div>
        <div>
          <div className="flex justify-between items-center xsm:flex-col xsm:items-start xsm:gap-8 gap-x-4 mt-2">
            <div className="flex flex-col justify-between w-full gap-3">
              <div className="w-full">
                <label htmlFor="templateName" className="form-label ">
                  <p className="text-[18px] font-semibold">
                    Template Name <span className="text-[#EB5757]">*</span>
                  </p>
                </label>
                <Controller
                  name="templateName"
                  render={({ field }) => (
                    <TextInput
                      error={formErrors.templateName?.message}
                      {...field}
                      id="templateName"
                      name="templateName"
                      type="text"
                      placeholder="Ex: Ajax Union"
                      className="input xsm:text-[16px] xsm:px-4"
                    />
                  )}
                  control={formControl}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <label className="form-label">
            <p className="text-[18px] font-semibold">
              Description
            </p>
          </label>

          <Controller
            name="templateDescription"
            render={({ field }) => (
              <textarea
                  {...field}
                  id="templateDescription"
                  name="templateDescription"
                  placeholder="Ex: Ajax Union"
                  className="input mt-1 xsm:text-[16px] xsm:px-4 md:px-2 xsm:leading-[21px]"
                  rows={3}
                />
            )}
            control={formControl}
          />
        </div> */}

        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="templateTags" className="form-label flex flex-col justify-between items-center">
              <p className="text-[18px] font-semibold w-full">
              Tags
              </p>
              <p className="text-[14px] font-normal w-full my-1">
              Hit "enter" to save tag
              </p>
            </label>
          </div>
          <div ref={previousRef} className={`input !p-0 mt-[5px] flex justify-between  ${tagsToAdd.length === 0 ? "items-center" : "items-center"} items-start relative`}>
            <div id="tagsScrollBar" className=" w-[99%] xsm:w-[95%] flex justify-start p-1 items-center overflow-x-auto gap-y-1" >
              {tagsToAdd.map((tag: Tags) => {
                  return (
                    <span 
                      key={tag.id}
                      className={` mx-[2px] text-nowrap text-nowrap-md flex justify-center items-center gap-2 py-[2px] px-3 font-poppins font-semibold text-[16px] text-[#6E40C5] bg-[#F5F1FB] rounded-[30px]`}>
                      {tag.name}
                      <span className="cursor-pointer" onClick={() => removeTagToAdd(tag)}>
                        <CloseIcon width={10} color={"#6E40C5"}/>
                      </span>
                    </span>
                  )
                })}

              <Controller
                name="templateTags"
                render={({ field }) => (
                  <input
                    {...field}
                    id="templateTags"
                    name="templateTags"
                    type="text"
                    value={currentTag}
                    placeholder={"Search or type to create a tag"}
                    // placeholder={tagsToAdd.length < 1 ? "Search or type to create a tag" : ""}
                    onFocus={()=>setShowDropDown(true)}
                    // onFocus={handleKeyPress}
                    // onBlur={handleKeyPress}
                    // onClick={handleKeyPress}
                    onKeyDown={handleKeyPress}
                    onChange={searchTags}
                    className={`px-2 py-3 ${tagsToAdd.length > 0 ? "min-w-[35%] xsm:min-w-[100%]" : "min-w-[60%] xsm:min-[100%]"} text-sm flex-1 rounded-md disabled:opacity-50 text-[#292929] input ml-1 !border-none w-full xsm:text-[16px] xsm:px-2 `}
                    // className={`${tagsToAdd.length === 0 ? "xsm:!w-[30vh] " : "!w-full"} input !-mt-[0.2px] ml-1 !border-none xsm:text-[16px] xsm:px-2 `}
                  />
                  // <TextInput
                  //   {...field}
                  //   id="templateTags"
                  //   name="templateTags"
                  //   type="text"
                  //   value={currentTag}
                  //   placeholder={"Search or type to create a tag"}
                  //   // placeholder={tagsToAdd.length < 1 ? "Search or type to create a tag" : ""}
                  //   onFocus={()=>setShowDropDown(true)}
                  //   // onFocus={handleKeyPress}
                  //   // onBlur={handleKeyPress}
                  //   // onClick={handleKeyPress}
                  //   onKeyDown={handleKeyPress}
                  //   onChange={searchTags}
                  //   className={` input !-mt-[0.2px] ml-1 !border-none xsm:text-[16px] xsm:px-2 `}
                  //   // className={`${tagsToAdd.length === 0 ? "xsm:!w-[30vh] " : "!w-full"} input !-mt-[0.2px] ml-1 !border-none xsm:text-[16px] xsm:px-2 `}
                  // />
                )}
                control={formControl}
              />
            </div>
            <div className="flex justify-end items-center gap-2 px-2 backdrop:blur-[4px] bg-gradient-to-l from-white to-transparent absolute right-0">
              <span 
                // type="button"
                className={`bg-[#34317D] p-2 rounded-lg md:hidden cursor-pointer my-1 w-[28px] disabled:bg-[#C0C0C0] `}
                onClick={handleCreateTagUsingButton} 
                // onClick={() => setShowCreateTagDialogue(true)}
                // disabled={currentTag.trim().length === 0}
                >
                  {isAddingTag ? 
                    <Loader size={3} />
                      :
                    <img src={PlusIcon}  />
                  }
              </span>
              <span onClick={()=>setShowDropDown(!showDropDown)}>
                {!showDropDown ? <DownArrow/> : <UpArrow/>}
              </span>

            </div>
          </div>
          <div ref={absoluteRef} className={`${!showDropDown && "hidden"} mt-2 max-h-[35vh] overflow-y-auto absolute bg-white rounded-md input !p-0 `}>
                {remainingTagsToShow.length > 0 ? 
                remainingTagsToShow.map((tag: Tags, index: number) => {
                  return (
                    <div className={`${index === 0  && " rounded-t-xl"} ${index === remainingTagsToShow.length - 1  && " rounded-b-xl"} md:hover:bg-[#F5F7F9] xsm:active:!bg-[#F5F7F9] flex justify-between items-center`}>
                      <span
                        key={tag.id}
                        onClick={()=>{addTagToAdd(tag)}}
                        className={`cursor-pointer text-[16px] w-full flex justify-start p-3 `}
                      >
                        {tag.name}
                      </span>
                      <span
                        onClick={() => handleDeleteTag(tag.id)} 
                        className="mx-2 cursor-pointer"
                      >
                        <CloseIcon width={10} color={"black"}/>
                      </span>
                    </div>
                  )
                }) 
                :
                (
                  <div className={`flex justify-between items-center `}>
                    <span
                      className={`text-[16px] w-full p-3 font-bold text-center`}
                    >
                      No Tags Found!
                    </span>
                  </div>
                )
                }
          </div>
          </div>
        <div className='my-3 md:block xsm:block hidden'>
          <hr className='border-t-1 xsm:border-[#6E40C5] md:border-[#6E40C5] border-border_color' />
        </div>
        <div>
          <label className="form-label">
            <p className="text-[18px] font-semibold">
              Template Content <span className="text-[#EB5757]">*</span>
            </p>
          </label>
          <Controller
            name="templateContent"
            render={({ field }) => (
              <div className="border-[0.5px] border-[#cbccd0] rounded-[10px] overflow-hidden">
                <textarea
                  {...field}
                  id="templateContent"
                  name="templateContent"
                  placeholder="Create a template"
                  className="input mt-1 border-none xsm:text-[16px] xsm:px-4 xsm:leading-[21px]"
                  rows={3}
                  // maxLength={2000}
                />
                <div className="w-full p-3 bg-[#F5F7F9] md:flex justify-end">
                  <button
                  type="button"
                  disabled={field.value.length === 0}
                  onClick={() => copyTextToClipBoard(field.value)}
                  className="w-full md:w-[15%] border-[1.5px] border-[#34317D] rounded-[10px] flex items-center justify-center gap-2 py-3 font-poppins text-[14px] font-bold text-[#34317D] disabled:border-[#C0C0C0] disabled:text-[#C0C0C0]"
                  >
                    <CopyIcon width={18} color={field.value.length === 0 ? "#C0C0C0" : "#34317D"} />
                    Copy
                  </button>
                </div>
              </div>
            )}
            control={formControl}
          />
          <div className=" flex justify-between items-center">
            <p className="text-sm font-medium text-rose-600 mt-1 px-1">
              {formErrors.templateContent?.message}
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center xsm:flex-col xsm:items-start">
          <h4 className="form-label !text-red-600 xsm:hidden">*Required</h4>
          <div className="md:hidden flex flex-col items-center justify-center w-full gap-4">
            <button
              disabled={isLoading}
              type="submit"
              className="btn-primary mt-4 disabled:opacity-50 w-full"
            >
              {isLoading && <Loader color="fill-white" size={5} />}
              {templateId ? "Update Template" : "Save Template"}
            </button>
            {!templateId ? 
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="btn-secondary disabled:opacity-50 w-full"
            >
              Cancel
            </button>
            :
            <button
              onClick={() => setShowConfirmation(true)}
              disabled={!templateId}
              type="button"
              className="btn-secondary disabled:opacity-50 w-full"
            >
              <img src={deleteIcon} className="mr-[10px]" />
              Delete Template
            </button>
            }
          </div>
        </div>
      </div>
      <ActionCard isLoading={isLoading}/>
      <ConfirmationDialog
        setShow={setShowConfirmation}
        show={showConfirmation}
        onConfirm={handleDeleteTemplate}
        isLoading={isDeleting}
        header="Delete Template"
        content="Are you sure you want to delete these Template?"
      />
      {showCreateTagDialogue && (
        <CreateTag
          showAlert={showCreateTagDialogue}
          setShowAlert={setShowCreateTagDialogue}
          onDataChanged={toggleDataChanged}
          tagToAdd={currentTag}
          functionToExecute={handleKeyPress}
        />
      )}
      <ConfirmationDialog
        setShow={setShowDeleteTagDialogue}
        show={showDeleteTagDialogue}
        onConfirm={confirmDelete}
        header="Delete Tag"
        content="Are you sure you want to delete this Tag?"
        isLoading={isDeletingTag}
      />
    </div>
  );
};

export default AddTemplateForm;
