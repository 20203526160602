import DailogBox from "./DailogBox";
import WarningIcon from "../assets/Images/warningsvg.svg"
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../router/routes.constants";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => Promise<boolean>;
  show: boolean;
}
const ShowTrialWarningDialogue = ({
  setShow,
  show,
  onConfirm,
}: IProps) => {
    
    const navigate = useNavigate();

    const handleClose = async () => {
        setShow(false);
        await onConfirm();
    }
    
    const handleConfirm = async () => {
        setShow(false);
        await onConfirm();
        navigate(`/${PROTECTED_ROUTES.subscriptionPricing}`);
    }

  return (
    <DailogBox
        open={show}
        onClose={handleClose}
        dialogClass="w- !z-[99999999999999] flex justify-center items-center"
        dialogBoxClass="flex justify-center items-center"
        >
        <div className="w-[420px] xsm:w-full text-center flex justify-center items-center flex-col sm:text-left gap-4 xsm:gap-6 ">
            <img
                src={WarningIcon}
            />
            <div className="flex justify-between items-center flex-col gap-4 mb-2 border-black w-full xsm:w-[95%]">
                <p className="text-[32px] w-[80%] xsm:font-black xsm:w-full xsm:text-[28px] leading-[48px] text-[#333333] font-poppins xsm:leading-8 text-center text-lg font-extrabold">
                    Free Trial Warning
                </p>
                <p className="text-[16px] xsm:text-[14px] xsm:leading-5 font-poppins leading-6 text-center text-lg">
                    You have reached the maximum number of free trials allowed. Please upgrade below.
                </p>
            </div>
            <div className=" w-[90%] xsm:w-full items-center flex md:flex-row gap-2 xsm:gap-1 xsm:flex xsm:flex-col-reverse">
                {/* <button
                    className="w-full xsm:py-3 mt-2 px-6 py-2.5 text-center xsm:text-[14px] font-poppins text-[#34317D]  font-bold rounded-[10px] border-[1.5px] border-[#34317D]"
                    onClick={handleClose}
                    >
                    Skip
                </button> */}
                <button
                    className="h-full w-full xsm:py-3 mt-2 px-6 py-2.5 text-center xsm:text-[14px] font-poppins text-white bg-[#34317D] border-[1.5px] border-[#34317D] outline-none ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
                    onClick={handleConfirm}
                    >
                    Upgrade Now
                </button>
            </div>
        </div>
    </DailogBox>
  );
};
export default ShowTrialWarningDialogue;
