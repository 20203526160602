import DailogBox from "./DailogBox";
import SignInDialogForm from "../pages/Auth/SignInDialogForm";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";

const LoginDialog = () => {
  const navigate = useNavigate();
  return (
    <DailogBox
      open={true}
      dialogClass="!z-[100] "
      dialogBoxClass="!p-0 md:!p-10"
      onClose={()=>navigate(Navigate_to.login)}
    >
      <SignInDialogForm />
    </DailogBox>
  );
};

export default LoginDialog;
