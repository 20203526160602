import "../Register/style.css";
import Arrow from "../../../assets/Images/arrow.svg";
import { Navigate_to } from "../../../router/routes.constants";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import TextInput from "../../../components/TextInput";
import { ThreeDots } from "react-loader-spinner";
import { TERMS_AND_CONDITIONS_PAGE } from '../../../constant';
import useSettings from "../hooks/useSettings";

function CreateProfile() {
  const {
    setTND,
    handleSubmit,
    onSubmit,
    isLoading,
    formControl,
    signUpError,
    formIsValid,
    TND,
    formErrors,
  } = useSettings();

  return (
    <div className=" pb-[78px] bg-bg_white_color">
        <h2 className="md:text-[42px] md:font-extrabold text-[32px] md:mt-6 mt-8 font-poppins font-black text-text_color">
          {" "}
          Create Profile
        </h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col lg:mt-14 mt-4"
        >
          <div>
            <label htmlFor="firstName" className="labal">
              First Name<span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="firstName"
              render={({ field }) => (
                <TextInput
                  error={formErrors.firstName?.message}
                  {...field}
                  id="first-name"
                  name="first-name"
                  type="text"
                  placeholder="Helen"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-4 ">
            <label htmlFor="lastName" className="labal">
              Last Name<span className="md:text-[#333] text-[#EB5757]"> *</span>
            </label>
            <Controller
              name="lastName"
              render={({ field }) => (
                <TextInput
                  error={formErrors.lastName?.message}
                  {...field}
                  id="last-name"
                  name="last-name"
                  type="text"
                  placeholder="Anderson"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-4">
            <label htmlFor="phoneNumber" className="labal">
              Phone Number
            </label>
            <Controller
              name="phoneNumber"
              render={({ field }) => (
                <TextInput
                  error={formErrors.phoneNumber?.message}
                  {...field}
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="(111) 1111 -1111"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <div className="md:mt-8 mt-4">
            <label htmlFor="linkedInAccount" className="labal">
              LinkedIn Account
            </label>
            <Controller
              name="linkedInAccount"
              render={({ field }) => (
                <TextInput
                  error={formErrors.linkedInAccount?.message}
                  {...field}
                  id="linkedIn-account"
                  name="linkedIn-account"
                  type="text"
                  placeholder="linkedin.com/in/Helen_Anderson"
                  className="input"
                />
              )}
              control={formControl}
            />
          </div>
          <label className="inline-flex items-start md:my-10 my-8">
            <div className="round min-w-[14px] h-[14px] md:w-6 md:h-6 mt-1">
              <input
                checked={TND}
                onChange={() => setTND(!TND)}
                type="checkbox"
                id="checkbox"
                className="checkbox text-secondry_color"
                // className="form-checkbox h-5 w-5 rounded-full text-secondry_color appearance-none"
              />
              <label htmlFor="checkbox" ></label>
            </div>

            <p className="md:text-[18px] text-[14px] font-normal md:leading-8 leading-normal font-poppins text-text_color ml-2">
              By signing up you agree to our &nbsp;
              <b>
                <a href={TERMS_AND_CONDITIONS_PAGE}>Terms & Conditions *</a>
              </b>
            </p>
          </label>
          <button
            disabled={!formIsValid || !TND}
            type="submit"
            className={`${formIsValid && TND
              ? "bg-secondry_color cursor-pointer text-white"
              : "!bg-border_color !cursor-not-allowed text-black_color"
              }  rounded-[10px] w-full px-6 font-poppins md:text-[18px] text-[10px] font-bold h-10 flex items-center justify-center`}
          >
            {!isLoading ? (
              " Create profile"
            ) : (
              <ThreeDots
                height={16}
                width={30}
                color="#fff"
                wrapperStyle={{}}
                wrapperClass="justify-center"
                visible={true}
                ariaLabel="three-dots-loading"
              />
            )}
          </button>
          <Link
            to={Navigate_to.login}
            type="button"
            className="w-full text-[#34317D] text-center border border-[#34317D] rounded-[10px] px-1 py-[10px] items-center justify-center mt-4 md:hidden flex"
          >
            <img src={Arrow} alt="Back_Arrow" />
            <span className="pl-1 font-poppins text-[10px] font-bold">
              Back to Login
            </span>
          </Link>

          <p className="text-[16px] font-poppins text-text_color mt-4">
            * Required
          </p>

          {signUpError.length > 0 && (
            <p className="text-sm font-medium text-rose-600 mt-1 px-1 text-center">
              {signUpError}
            </p>
          )}
        </form>
    
    </div>
  );
}

export default CreateProfile;
