import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { nanoid } from "nanoid";
// import './../../CustomStyling/CustomReusablestyling.css';

import IdeaGeneratorBanner from "../../components/IdeaGeneratorBanner";
import IdeaGeneratorCard from "../../components/IdeaGeneratorCard";
import EditIdeaGeneratorCard from "../../components/EditIdeaGeneratorCard";

import { COLLECTIONS, SubscriptionStatus, SubscriptionStatusDB } from "../../config/firebase";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { toastAlertError } from "../../utils";

import useScreenSize from "../../hooks/useScreenSize";
import EditIdeaDialogue from "../../components/EditIdeaDialogue";
import IdeaGeneratorMobile from "./components/RegenerateCard";

import ArrowRight from "../../assets/Images/arrowRight.svg";
import UpgradeBanner from "../LearningHub/components/UpgradeBanner";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import { LanguageKey } from "@/components/FlagSelector";
import { PARAMETER_KEY } from "@/config/appConfig";
import { sentryClient } from "@/Sentry/sentry";

export interface IGeneratedIdea {
  idea: string;
  id: string;
  isSaved: boolean;
}

const IdeaGenerator: React.FC = () => {
  const { generatePostIdeas, fetchSingleData, User, subscriptionStatus, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating,getSubscriptionStatus } = useContext(
    AuthContext
  ) as AuthContext_type;
  const screenSize = useScreenSize();

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [selectedPersona, setSelectedPersona] = useState("");
  const [generateIdeaCommand, setGenerateIdeaCommand] = useState("");
  const [generatedIdeas, setGeneratedIdeas] = useState<IGeneratedIdea[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activityData, setActivityData] = useState<any>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [showBanner, setShowBanner] = useState(false)
  const [isTrailFinished, setIsTrailFinished] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageKey>("ENG");

  const fetchGenerateIdea = useCallback(async () => {
    try {
      setIsLoading(true);
      if (subscriptionStatus) {
        if (subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
          if (subscriptionStatus.usagePercentage >= 100) {
            setShowUpdatePaymentDialog(true);
            return;
          }
        }
      }
      const result: any = await generatePostIdeas({
        prompt: generateIdeaCommand,
        personaId: selectedPersona,
        existing_ideas: generatedIdeas,
        [PARAMETER_KEY.LANGUAGE] : selectedLanguage
      });
      getSubscriptionStatus();
      
      setGeneratedIdeas(
        JSON.parse(result.data.data.reply).map((item: { idea: string }) => ({
          id: nanoid(),
          idea: item.idea,
          isSaved: false,
        }))
      );

      if (result.data.data.activity_id) {
        const activityData = await fetchSingleData(
          result.data.data.activity_id,
          `${COLLECTIONS.USER_ACTIVITIES}/${User?.uid}/${COLLECTIONS.ACTIVITIES}`
        );
        setActivityData(activityData);
      }
    } catch (error) {
      toastAlertError("Error while generating idea please try again");
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  }, [generateIdeaCommand, selectedPersona, generatedIdeas, subscriptionStatus,selectedLanguage]);

  const handleCardClick = (index: number) => {
    setSelectedCardIndex(index);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const isSmallScreen = useMemo(() => {
    if (screenSize === "small" || screenSize === "medium") {
      return true;
    }
    return false;
  }, [screenSize]);

  const handleGoBack = () => {
    setGenerateIdeaCommand("");
    setGeneratedIdeas([]);
    setSelectedCardIndex(0);
    setSelectedPersona("")
  }

  useEffect(() => {
    if (subscriptionStatus) {
      if (
        (
          subscriptionStatus.usagePercentage &&
          subscriptionStatus.usagePercentage >= 100
        ) &&
        subscriptionStatus.status === SubscriptionStatus.FREE
      ) {
        setShowBanner(true);
        setIsTrailFinished(true);
      } else if (subscriptionStatus.status === SubscriptionStatus.PRO) {
        setShowBanner(false);
        setIsTrailFinished(false);
      }
    }
  }, [subscriptionStatus?.usagePercentage]);

  return (
    <section className="max-h-fit">
      {!isSmallScreen && (
        <div>
          <h3 className="custom-h3-style">
            Idea Generator
          </h3>

          <p className="custom-primary-p-style">
            Generate ideas for your LinkedIn posts
          </p>
          {showBanner &&
            <UpgradeBanner
              setShowBanner={setShowBanner}
              headline="Become a Premium Member"
              description="Upgrade now for unlimited access to evyAI’s Idea Generator."
            />}
        </div>
      )}

      {!generatedIdeas.length && isSmallScreen && (
        <div>
          <h3 className="font-poppins text-2xl md:text-[32px] font-black md:font-extrabold text-[#292929] leading-normal capitalize">
            Idea Generator
          </h3>

          <p className="font-poppins text-sm md:text-lg font-normal text-text_color mt-1">
            Generate ideas for your LinkedIn posts
          </p>
          {showBanner &&
            <UpgradeBanner
              setShowBanner={setShowBanner}
              headline="Become a Premium Member"
              description="Upgrade now for unlimited access to evyAI’s Idea Generator."
            />}
        </div>
      )}

      {!isSmallScreen && (
        <div className="md:mt-8 mt-5 ">
          <IdeaGeneratorBanner
            setSelectedPersona={setSelectedPersona}
            fetchGenerateIdea={fetchGenerateIdea}
            selectedPersona={selectedPersona}
            setGenerateIdeaCommand={setGenerateIdeaCommand}
            generateIdeaCommand={generateIdeaCommand}
            generatedIdeas={generatedIdeas}
            isGenerating={isLoading}
            isTrailFinished={isTrailFinished}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
      )}
      {!generatedIdeas.length && isSmallScreen && (
        <div className="mt-6 md:mt-8 h-[70vh]">
          <IdeaGeneratorBanner
            setSelectedPersona={setSelectedPersona}
            fetchGenerateIdea={fetchGenerateIdea}
            selectedPersona={selectedPersona}
            setGenerateIdeaCommand={setGenerateIdeaCommand}
            generateIdeaCommand={generateIdeaCommand}
            generatedIdeas={generatedIdeas}
            isGenerating={isLoading}
            isTrailFinished={isTrailFinished}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
      )}

      {!!generatedIdeas.length && (
        <div className="md:bg-white pb-4 md:p-4 md:mt-6 rounded-[16px] font-poppins">
          <div className="flex items-center mb-6 md:hidden">
            <h2 onClick={handleGoBack} className="font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] ml-2 opacity-60">
              Idea Generator
            </h2>
            <img
              className="ml-2 md:w-[14px] w-3 opacity-60"
              src={ArrowRight}
              alt=""
            />
            <h2 className="font-poppins md:text-[16px] text-[14px] font-bold leading-[24px] text-[#444553] ml-2">
              Result
            </h2>
          </div>
          <h3 className=" hidden md:block text-gray_light text-xl font-semibold mb-4">Results</h3>
          <div className="md:bg-white flex gap-6 justify-between flex-wrap md:flex-nowrap">
            <div className=" w-full pb-[145px] md:pb-0 md:w-[52%] grid grid-cols-1 md:grid-cols-2 gap-4">
              {generatedIdeas.map((idea, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setIsOpenDialog(true);
                    handleCardClick(index);
                  }}
                  className="cursor-pointer h-full"
                >
                  <IdeaGeneratorCard
                    generateIdeaCommand={generateIdeaCommand}
                    selectedPersona={selectedPersona}
                    activityData={activityData}
                    generatedIdeaText={idea.idea}
                    id={idea.id}
                    key={index}
                    isActive={index === selectedCardIndex}
                    generatedIdeas={generatedIdeas}
                    setGeneratedIdeas={setGeneratedIdeas}
                    isIdeaSaved={idea.isSaved}
                  />
                </div>
              ))}
              {isSmallScreen && !!generatedIdeas.length && (
                <IdeaGeneratorMobile
                  setSelectedPersona={setSelectedPersona}
                  fetchGenerateIdea={fetchGenerateIdea}
                  selectedPersona={selectedPersona}
                  setGenerateIdeaCommand={setGenerateIdeaCommand}
                  generateIdeaCommand={generateIdeaCommand}
                  generatedIdeas={generatedIdeas}
                  isGenerating={isLoading}
                />
              )}
            </div>
            <div className="hidden md:inline-block w-full md:w-[48%]">
              {!!generatedIdeas.length && (
                <EditIdeaGeneratorCard
                  generateIdeaCommand={generateIdeaCommand}
                  activityData={activityData}
                  selectedPersona={selectedPersona}
                  selectedIdea={generatedIdeas[selectedCardIndex].idea}
                  id={generatedIdeas[selectedCardIndex].id}
                  isIdeaSaved={generatedIdeas[selectedCardIndex].isSaved}
                  generatedIdeas={generatedIdeas}
                  setGeneratedIdeas={setGeneratedIdeas}
                />
              )}
            </div>
          </div>
          {!!generatedIdeas.length && (
            <EditIdeaDialogue
              open={isOpenDialog && isSmallScreen}
              handleClose={handleClose}
              generateIdeaCommand={generateIdeaCommand}
              activityData={activityData}
              selectedPersona={selectedPersona}
              selectedIdea={generatedIdeas[selectedCardIndex].idea}
              id={generatedIdeas[selectedCardIndex].id}
              isIdeaSaved={generatedIdeas[selectedCardIndex].isSaved}
              generatedIdeas={generatedIdeas}
              setGeneratedIdeas={setGeneratedIdeas}
            />
          )}
        </div>

      )}
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </section>
  );
};

export default IdeaGenerator;
