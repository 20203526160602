import { ReactNode, useContext, useEffect } from "react";

import { Navigate_to } from '../../../router/routes.constants';
import {  useNavigate } from 'react-router-dom';
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import LodingPageLoader from "../../../components/LodingPageLoader";

function Logout(): ReactNode {
  const { getCurrentUser, signOut } = useContext(AuthContext) as AuthContext_type;
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
   useEffect(()=>{
    const singnOutTheUser = async () => {
        if (currentUser){
           signOut()
           navigate(Navigate_to.login);
        }
    }
    setTimeout(()=>{
        singnOutTheUser()
    }, 1000)
   },[currentUser])

  return (<>
  <LodingPageLoader  />
  </>);
}

export default Logout;
