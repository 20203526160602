
export const EXTENSION_URL = "https://chromewebstore.google.com/detail/evyai-ai-assistant-for-so/imemcjjdfiaphipleieldpoiaoejdofp"
export enum AuthenticationState {
  Authenticated,
  UnverifiedAuthenticated,
  Login,
  Register,
  ForgotPassword,
  SendVerificationCodeInPhone,
  VerifyPhoneNumber,
  MyAccount,
  setting,
  manageSubscripion,
  UpgradePlan,
  ShareWithFriend,
}

export enum POST_COMMENT_SOURCE_PAGE {
  HOME = "home",
  MESSAGE = "message",
  CREATE_POST = "create_post",
  INVITATION_NOTE = "invitation_note",
  PROFILE_HEADLINE = "profile_headline",
  PROFILE_ABOUT = "profile_about",
  RECOMMENDATION_TEXT = "recommendation_text",
  SALES_MESSAGE_PAGE = "sales_message_page",
  ADVICE = "advice"
}

export enum MESSAGE_ACTION {
  GET_WEB_APP_AUTH_DETAILS = "GET_WEB_APP_AUTH_DETAILS",
  GET_WEB_APP_AUTH_SIGN_OUT = "GET_WEB_APP_AUTH_SIGN_OUT",
}

export const PARAMETER_KEY = {
  GOAL: "goal",
  TONE: "tone",
  SUMMARY_TEXT: "summaryText",
  COMMAND: "command",
  POST_TEXT: "postText",
  COMMENT_TEXT: "commentText",
  AUTHOR_NAME: "authorName",
  COMMENT_AUTHOR_NAME: "commentAuthorName",
  TYPE: "type",
  LAST_MESSAGES: "lastMessages",
  POST_CREATION_TEXT: "postCreationText",
  USER_ENTERED_INVITATION_NOTE: "userEnteredInvitationNote",
  CONNECTING_USER_NAME: "connectingUserName",
  CONNECTING_USER_TAGLINE: "connectingUserTagline",
  CONNECTING_USER_EXPERIENCES: "connectingUserExperiences",
  PROFILE: "profile",
  ABOUT_TEXT: "aboutText",
  ARTICLE_INFO: "articleInfo",
  RECOMMENDATION_INFO: "recommendationInfo",
  USER_ENTERED_RECOMMENDATION_TEXT: "userEnteredRecommendationText",
  CURRENT_USER_NAME: "currentUserName",
  MODEL: "model",
  PERSONA: "persona",
  LANGUAGE: "language",
  FULL_NAME: "fullName",
  STATUS: "status",
  IS_PRO_USER: "isProUser",
};

export enum API_REQUEST_TYPE {
  POST_COMMENT = "post_comment",
  MESSAGE_REPLY = "message_reply",
  COMMENT_REPLY = "comment_reply",
  CREATE_POST = "create_post",
  PROFILE_OPTIMIZER = "profile_optimizer",
  INVITATION_NOTE = "invitation_note",
  PROFILE_HEADLINE = "profile_headline",
  PROFILE_ABOUT = "profile_about",
  ARTICLE_COMMENT = "article_comment",
  ARTICLE_COMMENT_REPLY = "article_comment_reply",
  RECOMMENDATION_TEXT = "recommendation_text",
  PROFILE_SUMMARY = "profile_summary",
  PERSPECTIVE_COMMENT = "contributed_article_comment",
  POST_IDEAS = "post_ideas",
  POST_IDEA = "post_idea"
}

export const RECENT_ACTIVITY_TYPE_TITLE = {
  [API_REQUEST_TYPE.POST_COMMENT]: "Comment",
  [API_REQUEST_TYPE.MESSAGE_REPLY]: "Message",
  [API_REQUEST_TYPE.COMMENT_REPLY]: "Reply",
  [API_REQUEST_TYPE.CREATE_POST]: "Post",
  [API_REQUEST_TYPE.INVITATION_NOTE]: "Invitation",
  [API_REQUEST_TYPE.PROFILE_HEADLINE]: "Profile",
  [API_REQUEST_TYPE.PROFILE_ABOUT]: "Profile",
  [API_REQUEST_TYPE.ARTICLE_COMMENT]: "Comment",
  [API_REQUEST_TYPE.ARTICLE_COMMENT_REPLY]: "Reply",
  [API_REQUEST_TYPE.RECOMMENDATION_TEXT]: "Recommendation",
  [API_REQUEST_TYPE.PROFILE_SUMMARY]: "Profile Summary",
  [API_REQUEST_TYPE.PROFILE_OPTIMIZER]: "Profile Optimizer",
  [API_REQUEST_TYPE.PERSPECTIVE_COMMENT]: "Perspective",
  [API_REQUEST_TYPE.POST_IDEAS]: "Post Ideas",
  [API_REQUEST_TYPE.POST_IDEA]: "Post Idea"
};

export interface ProfileExperience {
  position: string;
  company: string;
  timeline: string;
  location: string;
  description?: string;
  skills?: string;
}

export interface ProfileEducation {
  institution: string;
  degreeAndDiscipline: string;
  timeline: string;
}

export interface ProfileLicensesAndCertificatioins {
  title: string;
  organization: string;
  issuedOn: string;
  expiredOn?: string;
  credentialID: string;
}

export interface InvitationNoteRequestParams {
  userEnteredInvitationNote: string;
  name: string;
  tagline: string;
  experiences: ProfileExperience[];
}
export interface Profile {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  headline?: string;
  currentPosition?: string;
  experiences?: ProfileExperience[];
  educations?: ProfileEducation[];
  licensesAndCertifications?: ProfileLicensesAndCertificatioins[];
}

export interface ArticleInfo {
  title: string;
  author: string;
  postDate: string;
  contentHTML: string;
}

export interface RecommendationInfo {
  relationship: string;
  positionAtTheTime: string;
  profile?: Profile;
}

export const isLinkedInFeedPage = (url: string) => {
  if (!url) return false;
  const urlObj = new URL(url);
  if (
    urlObj.protocol === "https:" &&
    urlObj.origin.includes(".linkedin.com/feed")
  ) {
    return true;
  }
  return false;
};

export const isLinkedInMessagingPage = (url: string) => {
  if (url.includes("linkedin.com/messaging/thread/")) {
    return true;
  }
  return false;
};



export const isLinkedInArticlePage = (url: string) => {
  if (url.toLowerCase().includes("linkedin.com/pulse/")) {
    return true;
  }
  return false;
};




export const RECAPTCHA_CONTAINER_ID = "recaptcha-container";

export const API_URL = {
  GET_COMMENTS:
    "https://us-central1-gptchromeextension.cloudfunctions.net/generatePostReplyForWeb",
  GET_USER_WITH_EMAIL:
    "https://us-central1-gptchromeextension.cloudfunctions.net/getUserByEmail_onCall",
  INVITE_USERS_WITH_EMAILS:
    "https://invitememberstoteamviaemails-ivkhmniq2a-uc.a.run.app",
};

export const PAGE_TO_OPEN_IN_TAB = "https://www.linkedin.com/";




export const NUMBER_OF_RECORDS_TO_SHOW_PER_PAGE = 3;

export const GOALS = [
  "Encourage Engagement",
  "Highlight Author",
  "Provide Value",
  "Encourage Debate",
  "Share Stories",
  "Offer Support",
  "Provide Resources",
  "Ask Questions",
  "Showcase Values",
  "Respond to Criticism",
  "Show Empathy",
  "Share Opinion",
  "Encourage Action",
  "Share Anecdotes",
  "Use Inclusive Language",
  "Build Relationships",
  "Reinforce Branding",
  "Provide Feedback",
  "Promote Product",
  "Establish Expertise",
  "Share News",
  "Build Rapport",
  "Offer Tips",
  "Showcase Voice",
  "Address Misconceptions",
  "Encourage Sharing",
  "Address Concerns",
  "Highlight Partnerships",
  "Spark Curiosity",
];


export const POSTING_GOALS = [
  "Ask a Question",
  "Celebrating Others",
  "Challenges and Solutions",
  "Common Myths",
  "Company Updates",
  "Educational",
  "Encourage Engagement",
  "Informational",
  "Introduction Post",
  "Networking",
  "Personal Story",
  "Poll",
  "Promotional",
  "Provide Value",
  "Seeking Advice",
  "Success Stories",
  "Thought Leadership"
];

export const PROFILE_HEADLINE_GOALS = [
  "Thought Leadership",
  "Lead Generation",
  "Brand Building",
  "Networking Engagement",
  "Marketing Services",
]

export const TONES = [
  "Analytical",
  "Assertive",
  "Authoritative",
  "Challenging",
  "Conversational",
  "Curious",
  "Educational",
  "Empathetic",
  "Friendly",
  "Funny",
  "Happy",
  "Informative",
  "Inspirational",
  "Metaphorical",
  "Motivational",
  "Narrative",
  "Normal",
  "Persuasive",
  "Philosophical",
  "Professional",
  "Reflective",
  "Sarcastic",
  "Sincere",
  "Visionary",
  "Whimsical"
];

export interface TextGenerationInfo {
  // command: string;
  generatedText: string;
  activityData: any;
  activityType: API_REQUEST_TYPE;
  // selectedGoal: string;
  // selectedTone: string;
}

export interface LinkedInMessage {
  messageSpeaker: string;
  messageText: string;
}

export const SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE = 10;

export const STORAGE_KEYS = {
  LAST_GOAL_USED: "last_goal_used",
  LAST_TONE_USED: "last_tone_used",

  LAST_PERSONA_USED: "last_persona_used",
  LAST_LANGUAGE_USED: "last_language_used",
  LAST_GPT_VERSION_USED:"last_gpt_version_used",

  WILL_SHOW_REGISTER_SCREEN: "will_show_register_screen",
  REGISTER_DATA: "register_data",
  IS_EXTENSION_LOGIN: "is_extension_login",
  SHOW_EXTENSION_BUTTON: "show_extension_button",
  SUBSCRIPTION_BANNER_SHOWN : "subscription_banner_shown"
};

export enum SubscriptionStatus {
  FREE,
  PRO,
}

export const UsageDescriptionText = {
  [SubscriptionStatus.FREE]:
    "Your monthly usage plan is about to finish. Upgrade your plan to get access to our Premium Features",
  [SubscriptionStatus.PRO]:
    "You are a premium user, manage your subscription below.",
};

export const UsageButtonTitle = {
  [SubscriptionStatus.FREE]: "Upgrade to Premium",
  [SubscriptionStatus.PRO]: "Manage Subscriptions",
};

export const FREE_USER_MAXIMUM_TOKENS = 20000;
export const PRO_USER_MAXIMUM_MONTHLY_USAGE = 1000000;
export const TERMS_AND_CONDITIONS_URL = "https://evyai.com/terms-of-use/";
