import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
// import './../../CustomStyling/CustomReusablestyling.css';

import copyIcon from "../../assets/Images/Copy.svg";
import EmptyStarIcon from "../../assets/Images/blue-empty-star.svg";
import FillStarIcon from "../../assets/Images/blue-star-fill.svg";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import { toastAlertSuccess, toastAlertError } from "../../utils";
import { AiFillLock } from "react-icons/ai";

import resetimg from "../../assets/Images/Reset.png";
import {
  API_REQUEST_TYPE,
  PROFILE_HEADLINE_GOALS,
  PARAMETER_KEY,
  SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE,
  STORAGE_KEYS,
  SubscriptionStatus,
  TONES,
  TextGenerationInfo,
} from "../../config/appConfig";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import arrowRight from "../../assets/Images/arrowleft.png";
import arrowLeft from "../../assets/Images/arrowright.png";

import { nanoid } from "nanoid";
import UpgradeSubscriptionDia from "../../components/UpgradeSubscriptionDia";
import { IPersona } from "../Persona";
import UpgradeBanner from "../LearningHub/components/UpgradeBanner";
import { UsageCompleteDisabledWrapper } from "../../utils/UsageCompleteDisabledWrapper";
import LeftArrow from "../../assets/svgs/LeftArrow";
import useScreenSize from "../../hooks/useScreenSize";
import DownArrow from "../../assets/svgs/DownArrow";
import checkImage from "../../assets/Images/listCheck.svg";
import CopyIcon from "../../assets/svgs/CopyIcon";
import downArrow from "../../assets/Images/downArrow.svg"
import upArrow from "../../assets/Images/upArrow.svg"
import { SelectDialogue } from "./components/selectDialogue";
import { SubscriptionStatusDB } from "../../config/firebase";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { sentryClient } from "@/Sentry/sentry";

const ProfileOptimizer = () => {
  const navigate = useNavigate();

  const {
    fetchSuggestedComments,
    subscriptionStatus,
    addCommandData,
    deleteCommandData,
    user,
    getPersonasList,
    getCustomerSubscriptionPortalLink,
    isMSubcribeLinkGenerating,
  } = useContext(AuthContext) as AuthContext_type;

  const [textareaVal, setTextareaVal] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [personas, setPersona] = useState<IPersona[]>([]);
  const [selectedPersona, setSelectedPersona] = useState("");


  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedTone, setSelectedTone] = useState("");
  const [commandText, setCommandText] = useState("");
  const [generatedTextsHistory, setGeneratedTextsHistory] = useState<
    TextGenerationInfo[]
  >([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isUpdatingSaveCommandStatus, setIsUpdatingSaveCommandStatus] =
    useState(false);
  const [
    savedDocumentIdsOfGeneratedTextHistory,
    setSavedDocumentIdsOfGeneratedTextHistory,
  ] = useState<string[]>([]);
  const [profileOptimizerText, setProfileOptimizerText] = useState("");
  const [showPreDialog, setShowPreDialog] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  const [openGoalDialog, setOpenGoalDialog] = useState(false);
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [openPersonaDialog, setOpenPersonaDialog] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)

  const handleClose = () => {
    setOpenGoalDialog(false);
    setOpenToneDialog(false);
    setOpenPersonaDialog(false);
  };

  const screen = useScreenSize()

  const [showBanner, setShowBanner] = useState(false)
  const [isTrailFinished, setIsTrailFinished] = useState(false);
  const [showUsageCompleteBanner, setShowUsageCompleteBanner] = useState(false)

  const gptLengths = [
    {
      label: <span>GPT4o-mini</span>,
      value: "gpt_3.5",
    },
    {
      label: (
        <span className="flex gap-x-2">
          GPT4o{" "}
          {(subscriptionStatus?.status === SubscriptionStatus.FREE || subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE) && (
            <AiFillLock />
          )}{" "}
        </span>
      ),
      value: "gpt_4",
    },
  ];

  const handleFetchPersonas = async () => {
    try {
      const res = await getPersonasList();
      setPersona(res);
    } catch (error) {
      console.log(error);
      sentryClient.captureException(error)
    }
  };

  useEffect(() => {
    const setInitialGoalAndTone = async () => {
      try {
        const lastUsedGoal = await localStorage.getItem(
          STORAGE_KEYS.LAST_GOAL_USED
        );
        if (lastUsedGoal !== null && lastUsedGoal?.length > 0) {
          setSelectedGoal(lastUsedGoal);
        } else {
          setSelectedGoal(PROFILE_HEADLINE_GOALS[0]);
        }

        const lastUsedTone = await localStorage.getItem(
          STORAGE_KEYS.LAST_TONE_USED
        );
        if (lastUsedTone !== null && lastUsedTone?.length > 0) {
          setSelectedTone(lastUsedTone);
        } else {
          setSelectedTone(TONES[0]);
        }
      } catch (error) {
        console.log("### Error while getting data from local storage");
        console.log({ error });
        sentryClient.captureException(error)
      }
    };
    setInitialGoalAndTone();
    handleFetchPersonas();
  }, []);

  useEffect(() => {
    setCurrentHistoryIndex(Math.max(0, generatedTextsHistory.length - 1));
  }, [generatedTextsHistory]);

  useEffect(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      setCommandText(
        generatedTextsHistory[currentHistoryIndex].activityData.command
      );
      setSelectedGoal(
        generatedTextsHistory[currentHistoryIndex].activityData.goal
      );
      setSelectedTone(
        generatedTextsHistory[currentHistoryIndex].activityData.tone
      );
    }
  }, [generatedTextsHistory, currentHistoryIndex]);

  const [gptType, setGptType] = useState(gptLengths[0].value);

  const fetchProfileOptimizerText = useCallback(async () => {
    try {
      setIsLoading(true);
      const data: any = {};

      if (!profileOptimizerText) {
        toastAlertError("User input is required.");
        return;
      }
      data[PARAMETER_KEY.GOAL] = selectedGoal;
      data[PARAMETER_KEY.TONE] = selectedTone;
      data[PARAMETER_KEY.COMMAND] = commandText;
      data[PARAMETER_KEY.TYPE] = API_REQUEST_TYPE.PROFILE_ABOUT;
      data[PARAMETER_KEY.CURRENT_USER_NAME] =
        user?.first_name + " " + user?.last_name;
      data[PARAMETER_KEY.FULL_NAME] =
        user?.first_name + " " + user?.last_name;
      data[PARAMETER_KEY.MODEL] = gptType;
      data[PARAMETER_KEY.PERSONA] = selectedPersona;
      data[PARAMETER_KEY.ABOUT_TEXT] = profileOptimizerText;
      data[PARAMETER_KEY.PROFILE] = "";

      if (subscriptionStatus) {
        if (subscriptionStatus.status === null && subscriptionStatus.status === SubscriptionStatus.PRO) {
          data[PARAMETER_KEY.STATUS] = SubscriptionStatusDB.ACTIVE;
        } else {
          data[PARAMETER_KEY.STATUS] = subscriptionStatus.statusDB;
        }
        if (data[PARAMETER_KEY.STATUS] === SubscriptionStatusDB.PAST_DUE) {
          data[PARAMETER_KEY.IS_PRO_USER] = false;
        } else {
          data[PARAMETER_KEY.IS_PRO_USER] = subscriptionStatus.status === SubscriptionStatus.PRO;
        }
      }

      console.log(data, "data to send");
      const response = await fetchSuggestedComments(data);

      if (response?.success) {
        const generatedText = response?.data?.[0]?.["reply"] ?? "";
        const generatedTextInfo: TextGenerationInfo = {
          activityData: data,
          generatedText,
          activityType: API_REQUEST_TYPE.PROFILE_ABOUT,
        };
        setGeneratedTextsHistory((previousHistory) => [
          ...previousHistory,
          generatedTextInfo,
        ]);
        setSavedDocumentIdsOfGeneratedTextHistory((previousItems) => [
          ...previousItems,
          "",
        ]);
        if (generatedTextInfo) {
          setTextareaVal(generatedTextInfo.generatedText);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);

        if (response?.responseCode === 429) {
          setShowUsageCompleteBanner(true);
          setIsTrailFinished(true);
          return;
        } else if ((response?.responseCode === 402) && subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE) {
          setShowUpdatePaymentDialog(true);
          return;
        }

        toastAlertError(
          response?.error?.reason ??
          "Some error occurred. Please try again later."
        );
      }

    } catch (error: any) {
      toastAlertError(
        error?.message ?? "Some error occurred. Please try again later."
      );
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  }, [
    commandText,
    selectedTone,
    selectedGoal,
    gptType,
    profileOptimizerText,
    user,
    selectedPersona,
    subscriptionStatus,
  ]);

  useEffect(() => {
    setCurrentHistoryIndex(Math.max(0, generatedTextsHistory.length - 1));
  }, [generatedTextsHistory]);

  useEffect(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      setCommandText(
        generatedTextsHistory[currentHistoryIndex].activityData.command
      );
      setSelectedGoal(
        generatedTextsHistory[currentHistoryIndex].activityData.goal
      );
      setSelectedTone(
        generatedTextsHistory[currentHistoryIndex].activityData.tone
      );
    }
  }, [generatedTextsHistory, currentHistoryIndex]);

  const textInfo = useMemo(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      const words = textareaVal?.split(" ");
      return `${words.length} words/${textareaVal?.length} characters`;
    }
    return "";
  }, [generatedTextsHistory, currentHistoryIndex, textareaVal]);

  const goToPreviousHistoryPage = useCallback(() => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex((previousValue) => previousValue - 1);
    }
  }, [currentHistoryIndex]);

  const goToNextHistoryPage = useCallback(() => {
    if (currentHistoryIndex < generatedTextsHistory.length - 1) {
      setCurrentHistoryIndex((previousValue) => previousValue + 1);
    }
  }, [currentHistoryIndex, generatedTextsHistory]);

  const copyTextToClipBoard = () => {
    console.log("### text will be copied to clipboard");
    navigator.clipboard
      .writeText(textareaVal)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
        setTimeout(() => { }, SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE * 1000);
      })
      .catch((error) => {
        console.log("### Error while copying content to clipboard");
        console.log({ error });
        sentryClient.captureException(error)
      });
  };

  const saveCommand = async () => {
    try {
      setIsUpdatingSaveCommandStatus(true);
      const docId = nanoid();
      const { error } = await addCommandData(
        generatedTextsHistory[currentHistoryIndex].activityData || "",
        generatedTextsHistory[currentHistoryIndex].activityType,
        generatedTextsHistory[currentHistoryIndex].generatedText,
        docId
      );

      if (error === null && docId?.length > 0) {
        const _savedDocIds = [...savedDocumentIdsOfGeneratedTextHistory];
        _savedDocIds[currentHistoryIndex] = docId;
        setSavedDocumentIdsOfGeneratedTextHistory(_savedDocIds);
        setIsFavorite(true);
        toastAlertSuccess("Saved Successfully");
      }
      setIsFavorite(true);
    } catch (error) {
      console.log("### Error while saving command");
      console.log({ error });
      sentryClient.captureException(error)
    } finally {
      setIsUpdatingSaveCommandStatus(false);
    }
  };

  const unsaveCommand = async () => {
    try {
      setIsUpdatingSaveCommandStatus(true);
      const { docId, error } = await deleteCommandData(
        savedDocumentIdsOfGeneratedTextHistory[currentHistoryIndex]
      );
      if (error === null && docId?.length > 0) {
        const _savedDocIds = [...savedDocumentIdsOfGeneratedTextHistory];
        _savedDocIds[currentHistoryIndex] = "";
        setSavedDocumentIdsOfGeneratedTextHistory(_savedDocIds);
        toastAlertSuccess("Unsaved Successfully");
      }
    } catch (error) {
      console.log("### Error while unsaving command");
      console.log({ error });
      sentryClient.captureException(error)
    } finally {
      setIsUpdatingSaveCommandStatus(false);
    }
  };

  const saveOrUnsaveCommand = () => {
    if (
      savedDocumentIdsOfGeneratedTextHistory[currentHistoryIndex].length > 0
    ) {
      unsaveCommand();
    } else {
      saveCommand();
    }
  };

  useEffect(() => {
    if (generatedTextsHistory[currentHistoryIndex]?.generatedText) {
      setTextareaVal(generatedTextsHistory[currentHistoryIndex]?.generatedText);
    }
  }, [generatedTextsHistory[currentHistoryIndex]?.generatedText]);

  const handleNavigateToSubPag = () => {
    navigate(`/${PROTECTED_ROUTES.subscriptionPricing}`);
  };

  useEffect(() => {
    if (isFavorite) {
      setTimeout(() => {
        setIsFavorite(false);
      }, 4000);
    }
  }, [isFavorite]);



  useEffect(() => {
    if (
      gptType === "gpt_4" &&
      subscriptionStatus?.status === SubscriptionStatus.FREE
    ) {
      setShowPreDialog(true);
    } else if (
      gptType === "gpt_4" &&
      subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE
    ) {
      setShowUpdatePaymentDialog(true);
    }
  }, [gptType]);

  useEffect(() => {
    if (
      !showPreDialog &&
      subscriptionStatus?.status === SubscriptionStatus.FREE
    ) {
      setGptType("gpt_3.5");
    }
    if (
      !showUpdatePaymentDialog &&
      subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE
    ) {
      setGptType("gpt_3.5");
    }
  }, [showPreDialog, showUpdatePaymentDialog, subscriptionStatus?.status]);

  const handleGoBack = () => {
    setGeneratedTextsHistory([])
    setSelectedPersona("")
  }

  useEffect(() => {
    if (
      !!(
        subscriptionStatus?.usagePercentage &&
        subscriptionStatus.usagePercentage >= 100
      ) &&
      subscriptionStatus.status === SubscriptionStatus.FREE
    ) {
      setShowBanner(true);
      setIsTrailFinished(true);
    }
  }, [subscriptionStatus?.usagePercentage]);

  return (
    <section className={`xsm:rounded-[16px] xsm:py-2 md:px-0 xsm:mb-2 xsm:px-0 ${generatedTextsHistory.length === 0 ? "h-full" : "xsm:"}`}>

      {generatedTextsHistory.length == 0 ?
        <>
          <h3 className="custom-h3-style">
            Profile Optimizer
          </h3>
          <p className="custom-primary-p-style">
            Optimize your LinkedIn Profile by entering in your current profile
            information, any additional information, and hit generate.
          </p>
        </>
        :
        <div className="flex items-center mb-6">
          <h2 onClick={handleGoBack} className="ursor-pointer font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] mr-2 opacity-60">
            Profile Optimizer
          </h2>
          <LeftArrow width={15} height={15} color={"#44455390"} />
          <h2 className="cursor-pointer font-poppins md:text-[16px] text-[14px] font-bold leading-[24px] text-[#444553] ml-2">
            Results
          </h2>
        </div>

      }

      {
        showBanner &&
        <UpgradeBanner
          setShowBanner={setShowBanner}
          headline="Become a Premium Member"
          description="Upgrade now for unlimited access to evyAI’s Profile Optimizer."
        />
      }

        {showUsageCompleteBanner &&
            <FreeTrailEnd
            title="Your free trial has finished!"
            content="Upgrade your plan to get access to our Premium Features"
          />}


      <UsageCompleteDisabledWrapper isDisabled={isTrailFinished}>
        <div className={`bg-white rounded-[16px] p-10 xsm:p-0 xsm:h- xsm:border ${generatedTextsHistory.length < 1 ? "border-[#A1A2A9]" : "!border-none"} w-full md:mt-8 mt-5 `}>
          <div className="xsm:h-full">
            {generatedTextsHistory.length > 0 && (
              <div className="xsm:hidden mb-4">
                <label
                  htmlFor="name"
                  className="label text-[#444553] font-poppins text-[22px] font-semibold"
                >
                  Command to Evy
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={commandText}
                  onChange={(e) => setCommandText(e.target.value)}
                  placeholder="Enter a prompt with instructions for the type of profile optimization you would like evyAI to create."
                  className="input text-[#444553] font-poppins text-base font-normal mt-2"
                />
              </div>
            )}
            <div className="xsm:hidden flex flex-col lg:flex-row items-center gap-8">
              <div className="flex flex-row items-center w-full gap-x-0">
                <span className="min-w-[85px] text-[#444553] font-poppins text-[22px] font-semibold ">
                  Goal
                </span>
                <div className="custom-select">
                  <select
                    className="block w-full text-base font-poppins outline-none text-gray_light border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3"
                    value={selectedGoal}
                    onChange={(e) => {
                      setSelectedGoal(e.target.value);
                      localStorage.setItem(
                        STORAGE_KEYS.LAST_GOAL_USED,
                        e.target.value!
                      );
                    }}
                  >
                    {PROFILE_HEADLINE_GOALS.map((ton, index) => {
                      return (
                        <option key={index} value={ton}>
                          {ton}
                        </option>
                      );
                    })}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z"
                        fill="#484848"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="flex items-center w-full gap-x-0">
                <span className="min-w-[85px] text-[#444553] font-poppins text-[22px] font-semibold ">
                  Tone
                </span>
                <div className="custom-select">
                  <select
                    value={selectedTone}
                    onChange={(e) => setSelectedTone(e.target.value)}
                    className="block  w-full  text-base outline-none text-gray_light font-poppins border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3"
                  >
                    {TONES.map((ton, index) => {
                      return (
                        <option key={index} value={ton}>
                          {ton}
                        </option>
                      );
                    })}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z"
                        fill="#484848"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full gap-x-8">
                <span className="min-w-[85px] text-[#444553] font-poppins text-[22px] font-semibold ">
                  Persona
                </span>
                <div className="custom-select">
                  <select
                    value={selectedPersona}
                    onChange={(e) => setSelectedPersona(e.target.value)}
                    className="block w-full text-base outline-none text-gray_light font-poppins border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3"
                  >
                    <option value="">Select Persona</option>
                    {personas?.map((persona, index) => {
                      return (
                        <option key={index} value={persona.id}>
                          {persona?.personaName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="custom-arrow">
                    <svg
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z"
                        fill="#484848"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {generatedTextsHistory.length === 0 ? (
              <div className="md:relative xsm:h-[70vh] xsm:flex flex-col justify-between items-center mt-5 xsm:mt-0 xsm:px-0">
                <textarea
                  id="message"
                  onChange={(e) => setProfileOptimizerText(e.target.value)}
                  value={profileOptimizerText}
                  rows={screen !== "small" ? 16 : 32}
                  maxLength={4000}
                  className="block p-6 xsm:px-4 xsm:text-[14px] xsm:w-[99%] w-full font-poppins font-normal text-base text-[#121212] md:border border-[#A1A2A9] xsm:rounded-xl rounded-lg outline-none relative"
                  placeholder="Enter a prompt with instructions for the type of profile optimization you would like evyAI to create."
                />
                {/* <div className="flex justify-between items-center absolute bottom-4 w-full"> */}
                <div className="flex px-4 xsm:py-4 xsm:flex-col xsm:justify-between items-center md:absolute md:bottom-4 w-full xsm:gap-4 md:justify-between xsm:rounded-t-none xsm:rounded-b-[16px] xsm:bg-[#F5F7F9]">
                  <div className="md:hidden  flex justify-between items-center gap-2">
                    <div onClick={() => setOpenGoalDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedGoal ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                      <span className="font-poppins font-medium text-[14px]">Goal</span>
                      {selectedGoal ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                    <div onClick={() => setOpenToneDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedTone ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                      <span className="font-poppins font-medium text-[14px]">Tone</span>
                      {selectedTone ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                    <div onClick={() => setOpenPersonaDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedPersona ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                      <span className="font-poppins font-medium text-[14px]">Persona</span>
                      {selectedPersona ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                  </div>
                  <div className="xsm:hidden w-full">
                    {!generatedTextsHistory?.length && (
                      <span className="font-semibold ml-6">
                        {profileOptimizerText.length} / 4000
                      </span>
                    )}
                  </div>
                  <div className="flex items-center">
                    <div className="flex  text-[#3b4046] bg-[#e0e3e6] whitespace-nowrap p-1 rounded-lg">
                      {gptLengths.map((length, index) => (
                        <button
                          key={index}
                          onClick={() => setGptType(length.value)}
                          className={`py-2 px-4 font-poppins xsm:font-medium xsm:text-[12px] w-full justify-center flex items-center ${gptType === length.value
                            ? "all_pType_buttons"
                            : ""
                            }`}
                        >
                          {length.label}
                        </button>
                      ))}
                    </div>
                    <button
                      // className="xsm:hidden xsm:font-poppins xsm:font-bold text-[14px] px-6 py-[10px] flex items-center xsm:ml-0 ml-6 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50 xsm:mr-0 mr-4  "
                      className="xsm:hidden all_pType_buttons bg-secondry_color md:text-[16px] text-[14px] ml-4"
                      onClick={fetchProfileOptimizerText}
                      disabled={isLoading}
                    >
                      {isLoading && <Loader size={4} />}
                    <span>Generate</span>
                    </button>
                  </div>
                  <button
                    // className="md:hidden w-[90%] xsm:font-poppins xsm:font-bold text-[14px] px-6 py-[10px] flex items-center xsm:ml-0 ml-6 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50 xsm:mr-0 mr-4  "
                    className="md:hidden w-[90%]  all_pType_buttons bg-secondry_color "
                    onClick={fetchProfileOptimizerText}
                    disabled={isLoading}
                  >
                    {isLoading && <Loader size={4} />}
                    <span>Generate</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="md:relative xsm:h-full xsm:flex flex-col justify-between items-center mt-5 xsm:mt-0 xsm:px-0 xsm:rounded-[16px] md:p-4 md:rounded-md md:border border-gray-300 ">
                <div className=" xsm:flex flex-col justify-between   block p-2.5 w-full text-sm xsm:bg-[#FAF8FD] bg-[#F5F1FB] rounded-lg !border-none focus:!border-none relative">
                  <textarea
                    id="message"
                    onChange={(e) => setTextareaVal(e.target.value)}
                    value={textareaVal}
                    // rows={16}
                    className=" min-h-[50vh] block p-2.5 w-full font-poppins font-normal text-base text-[#121212] xsm:text-[14px] xsm:bg-[#FAF8FD] bg-[#F5F1FB] rounded-lg !border-none focus:!border-none outline-none "
                    placeholder="Enter a prompt with instructions for the type of optimization you would like evyAI to create."
                  />

                  <div className="flex flex-col w-full justify-between mt-3 gap-8">
                    <div className="flex justify-between mt-3">
                      <span className="font-poppins text-base xsm:font-bold xsm:text-[12px] font-semibold text-gray-700 opacity-50">
                        {textInfo}
                      </span>
                      <div className="flex">
                        <img
                          className="xsm:hidden ml-4 cursor-pointer"
                          src={copyIcon}
                          onClick={copyTextToClipBoard}
                          alt="Copy"
                        />

                        {isUpdatingSaveCommandStatus ? (
                          <span className="ml-4">
                            <Loader size={5} />
                          </span>
                        ) : (
                          <img
                            className="ml-4 mr-4 cursor-pointer"
                            src={
                              savedDocumentIdsOfGeneratedTextHistory[
                                currentHistoryIndex
                              ].length > 0
                                ? FillStarIcon
                                : EmptyStarIcon
                            }
                            onClick={saveOrUnsaveCommand}
                            alt="Copy"
                          />
                        )}
                      </div>
                    </div>
                    <Button
                      onClick={copyTextToClipBoard}
                      className="md:hidden gap-2 w-full flex mx-auto primary_button_custom ml-6  bg-secondry_color md:text-[16px] text-[14px]"
                    >
                      <CopyIcon width={15} height={15} color={"white"} />
                      Copy Post
                    </Button>
                  </div>
                </div>
                <div className=" relative md:hidden flex xsm:pt-2 xsm:flex-col xsm:justify-between xsm:items-center xsm:gap-4 justify-end xsm:w-full mt-4 xsm:rounded-[16px] xsm:border border-[#A1A2A9] xsm:">
                  <div className="md:hidden w-full flex justify-between items-center gap-2 px-2">
                    <textarea
                      id="name"
                      name="name"
                      value={commandText}
                      onChange={(e) => setCommandText(e.target.value)}
                      placeholder="Enter a prompt with instructions for the type of post you would like evyAI to create."
                      className=" min-h-[7vh] w-full my-2 font-poppins text-[14px] font-normal mt-2"
                    />
                  </div>
                  <div className="overflow-hidden flex-col gap-3 items-center w-full bg-[#F5F7F9] rounded-b-[20px] py-4 flex md:absolute md:bottom-4">
                    <div className=" md:hidden  flex justify-between items-center gap-2">
                      <img src={isExpand ? downArrow : upArrow} className={`border-2 bg-[#f1f3f5] mx-auto inset-x-0 border-white absolute rounded-full  z-30 ${isExpand ? (generatedTextsHistory.length > 1 ? "bottom-[213px]" : "bottom-[183px]") : (generatedTextsHistory.length <= 1 ? "bottom-[145px]" : "bottom-[175px]")} `} onClick={() => setIsExpand(!isExpand)} />
                      {isExpand &&
                        <div className="relative flex justify-between items-center gap-2 mt-2">
                          <div onClick={() => setOpenGoalDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedGoal ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                            <span className="font-poppins font-medium text-[14px]">Goal</span>
                            {selectedGoal ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                          <div onClick={() => setOpenToneDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedTone ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                            <span className="font-poppins font-medium text-[14px]">Tone</span>
                            {selectedTone ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                          <div onClick={() => setOpenPersonaDialog(true)} className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${selectedPersona ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"} rounded-md`}>
                            <span className="font-poppins font-medium text-[14px]">Persona</span>
                            {selectedPersona ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                        </div>}
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex text-[#495057] bg-[#f1f3f5] whitespace-nowrap p-1 rounded-lg">
                        {gptLengths.map((length, index) => (
                          <button
                            key={index}
                            onClick={() => setGptType(length.value)}
                            className={`py-2 px-4 w-full font-poppins font-medium text-[12px] justify-center flex items-center ${gptType === length.value
                              ? "all_pType_buttons"
                              : ""
                              }`}
                          >
                            {length.label}
                          </button>
                        ))}
                      </div>
                      <Button
                        className="xsm:hidden px-6 py-[10px] bg-white rounded-[12px] border border-secondry_color md:text-[18px] font-bold leading-normal text-secondry_color disabled:opacity-50"
                        onClick={fetchProfileOptimizerText}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader size={4} />
                        ) : (
                          <img src={resetimg} alt="reset" className="mr-2" />
                        )}
                        <span>Regenerate</span>
                      </Button>
                      <Button
                        onClick={copyTextToClipBoard}
                        className="xsm:hidden min-w-[180px]  py-[10px] flex items-center ml-6 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
                      >
                        Copy
                      </Button>
                    </div>
                    <Button
                      className="md:hidden w-[90%] bg-transparent all_sType_buttons"
                      onClick={fetchProfileOptimizerText}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader size={4} />
                      ) : (
                        <img src={resetimg} alt="reset" className="mr-2" />
                      )}
                      <span>Regenerate</span>
                    </Button>
                    <div className="flex justify-between gap-2 mt-2">
                      {generatedTextsHistory.length > 1 && (
                        <>
                          <div
                            onClick={goToPreviousHistoryPage}
                            className={`w-[30px] h-[30px] rounded-full shadow-lg flex items-center justify-center ${currentHistoryIndex > 0
                              ? "cursor-pointer bg-white"
                              : "opacity-60"
                              }`}
                          >
                            <img
                              src={arrowLeft}
                              alt="img"
                              width="15px"
                              height="15px"
                            />
                          </div>
                          <div
                            onClick={goToNextHistoryPage}
                            className={`w-[30px] h-[30px] rounded-full ml-2 shadow-lg flex items-center justify-center ${currentHistoryIndex < generatedTextsHistory.length - 1
                              ? "cursor-pointer bg-white"
                              : "opacity-60"
                              }`}
                          >
                            <img
                              src={arrowRight}
                              alt="img"
                              width={15}
                              height={15}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="xsm:hidden flex justify-between items-center w-full mt-4">
                  <div className="flex justify-between ">
                    {generatedTextsHistory?.length > 1 && (
                      <>
                        <div
                          onClick={goToPreviousHistoryPage}
                          className={`w-[44px] h-[44px] rounded-full shadow-lg flex items-center justify-center ${currentHistoryIndex > 0
                            ? "cursor-pointer bg-white"
                            : "opacity-60"
                            }`}
                        >
                          <img
                            src={arrowLeft}
                            alt="img"
                            width="15px"
                            height="15px"
                          />
                        </div>
                        <div
                          onClick={goToNextHistoryPage}
                          className={`w-[44px] h-[44px] rounded-full ml-2 shadow-lg flex items-center justify-center ${currentHistoryIndex < generatedTextsHistory.length - 1
                            ? "cursor-pointer bg-white"
                            : "opacity-60"
                            }`}
                        >
                          <img
                            src={arrowRight}
                            alt="img"
                            width={15}
                            height={15}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center text-[#495057] bg-[#f1f3f5] whitespace-nowrap p-1 mr-6 rounded-lg">
                      {gptLengths.map((length, index) => (
                        <button
                          key={index}
                          onClick={() => setGptType(length.value)}
                          className={`py-2 px-4 w-full justify-center flex items-center ${gptType === length.value
                            ? "all_pType_buttons"
                            : ""
                            }`}
                        >
                          {length.label}
                        </button>
                      ))}
                    </div>

                    <Button
                      className="xsm:hidden all_sType_buttons bg-transparent md:text-[16px] text-[14px]"
                      onClick={fetchProfileOptimizerText}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader size={4} />
                      ) : (
                        <img src={resetimg} alt="reset" className="mr-2" />
                      )}
                      <span>Regenerate</span>
                    </Button>

                    <Button
                      onClick={copyTextToClipBoard}
                      className="xsm:hidden min-w-[180px] primary_button_custom ml-6  bg-secondry_color md:text-[16px] text-[14px]"
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <SelectDialogue
            openDialog={openGoalDialog}
            title="Goal"
            items={PROFILE_HEADLINE_GOALS}
            handleClose={handleClose}
            setSelectedItem={setSelectedGoal}
            selectedItem={selectedGoal}
          />
          <SelectDialogue
            openDialog={openToneDialog}
            title="Tone"
            items={TONES}
            handleClose={handleClose}
            setSelectedItem={setSelectedTone}
            selectedItem={selectedTone}
          />
          <SelectDialogue
            openDialog={openPersonaDialog}
            title="Persona"
            items={personas}
            handleClose={handleClose}
            setSelectedItem={setSelectedPersona}
            selectedItem={selectedPersona}
          />
          <UpgradeSubscriptionDia
            show={showPreDialog}
            onConfirm={handleNavigateToSubPag}
            content={"Upgrade to evyAI Premium to access GPT-4"}
            setShow={setShowPreDialog}
          />
        </div>
      </UsageCompleteDisabledWrapper>
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </section >
  );
};

export default ProfileOptimizer;
