import CheckIcon from '@/assets/svgs/CheckIcon'
import DailogBox from '@/components/DailogBox'

interface Props {
  onClose: () => void;
  onSubmit:() => void
  title:string;
  description:string
}
export default function WaitForUpdateModal({onClose,onSubmit,title,description}:Props) {
  return (
    <DailogBox
      dialogClass="!z-[100]"
      className={`flex flex-col items-center justify-center !gap-0`}
      open={true}
      onClose={onClose}
      >
      <div className="flex flex-col items-center gap-2">
           <CheckIcon />
      </div>
      <div className="mt-6 flex flex-col justify-between items-center">
            <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
              {title}
            </h2>
            <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
              {description}
            </p>
        </div>
        <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
  
          <button 
            type="button"
            className=" w-full md:max-w-[400px] xsm:w-full xsm:text-center px-6 py-4 text-center disabled:!bg-[#C0C0C0] !text-white !bg-[#34317D] outline-none border ring-offset-2 flex justify-center rounded-[10px] font-bold "
            onClick={onSubmit}
            >
              I understand
          </button>
        </div>

      </DailogBox>
  )
}
