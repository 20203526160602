import _ from 'lodash';

type PropsType = {
  title: string;
  link: string;
  className?: string;
  [rest: string]: any;
};

export default function LinkBtn(props: PropsType) {
  const { title, link, rest, className } = props;

  return (
    <a
      href={link}
      className={`py-2 px-4 rounded-lg bg-[#ffd572] text-xs font-bold ${
        className ? className : ''
      }`}
      {...rest}
    >
      {title}
    </a>
  );
}
