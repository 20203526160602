import React, { useContext, useEffect, useState } from 'react'
import DownArrow from '../../../assets/svgs/DownArrow'
import useScreenSize from '../../../hooks/useScreenSize';
import Pagination from '../../../components/Pagination';
import UpArrow from '../../../assets/svgs/UpArrow';
import DeleteIcon from '../../../assets/svgs/DeleteIcon';
import UserDummyPicture from "../../../assets/Images/ProfilePicUpload.svg";
import { IUser } from '../../../context/Auth/auth.types';
import { collection, onSnapshot } from 'firebase/firestore';
import { firebaseDb } from '../../../context/Auth';
import { COLLECTIONS } from '../../../config/firebase';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import Loader from '../../../components/Loader';
// import SortArrow from "../../../assets/Images/Sort.svg"

interface MembersTeamsTableProps {
    team: any;
    memberTeams: any[];
    setMemberTeams: React.Dispatch<React.SetStateAction<any[]>>;
    activeMembersIdsArr: any[];
    isLoadingMembers: boolean;
  }

const MembersTeamsTable:React.FC<MembersTeamsTableProps> = (props) => {
// export default function MembersTeamsTable( props: MembersTeamsTableProps ) {

    const { team, memberTeams, setMemberTeams, isLoadingMembers } =  props;
    const { getCurrentUser, fetchUserDataByEmail, getSubscriptionStatus } = useContext(AuthContext) as AuthContext_type;

    const [currentPage, setCurrentPage] = useState(1);
    const [membersPerPage, setMembersPerPage] = useState(10);
    const [teamExp, setTeamExp] = useState(true)
    const [membersToShow, setMembersToShow] = useState<any[]>([])

    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    let currentMembers = membersToShow.slice(indexOfFirstMember, indexOfLastMember);

    const screen = useScreenSize()
    const currentUser = getCurrentUser();
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const handleSetItemsPerPage = (count: number) => {
        setMembersPerPage(count);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (team.members !== undefined) {
            // console.log("members", team.members);
            const activeMembers = (team.members as any[]).filter((member) => member.status === "joined")
            setMembersToShow(activeMembers);
        }
    }, [team])
    
    useEffect(() => {
        currentMembers = membersToShow.slice(indexOfFirstMember, indexOfLastMember);
    }, [membersToShow])
    

    useEffect(() => {
        if (team && !isLoadingMembers && team.members !== undefined) {
            const membersCollectionRef = collection(firebaseDb, `${COLLECTIONS.TEAMS}/${team.id}/${COLLECTIONS.MEMBERS}`);
            const unsubscribe = onSnapshot(membersCollectionRef, async (querySnapshot) => {
            for (const change of querySnapshot.docChanges()) {
                let memberDocData = change.doc.data();
                const memberDataId = change.doc.id;
                // const changedDoc = {...memberDocData, id: memberDataId}
                let memberDataUser: any = null;
                // const memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                // let memberDataUserId = memberDataUser.uid || memberDataUser.userId;
                let memberDataUserId: string | null = memberDocData.userId;
                // toggleDataChanged();
                if (change.type === 'added') {
                    // console.log('New member:', memberDocData);
                    let newMemberDocData = {...memberDocData, id: memberDataId}
                    // console.log('New member Doc Data:', newMemberDocData);
                    const isAlreadyThereInMembers = (team.members as any[]).some((member) => member.id === memberDataId);
                    const isAlreadyThereInActiveMembersIds = (team.activeMembersIds as any[]).some((member) => member.email === memberDocData.email);
                    // const isAlreadyThereInActiveMembersIds = (team.activeMembersIds as any[]).some((member) => member.uid || member.userId === memberDataUserId);
                    
                    if (isAlreadyThereInMembers || isAlreadyThereInActiveMembersIds) {
                        return;
                    }

                    let newTeam: any[] = [];
                    let newTeams: any[] = memberTeams;
                    let newMembers: any[] = [...team.members];
                    let newActiveMembers: any[] = [...team.activeMembersIds];

                    // console.log("IS ALREADY THERE IN MEMBERS", isAlreadyThereInMembers);
                    // console.log("IS ALREADY THERE IN ACTIVE MEMBERS", isAlreadyThereInActiveMembersIds);

                    if (!isAlreadyThereInMembers) {
                        if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                            memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                            if (memberDataUser) {
                                const memberUserId = memberDataUser.userId && memberDataUser.userId || memberDataUser.uid && memberDataUser.uid;
                                const memberName = memberDataUser.first_name + (memberDataUser.last_name ? " " + memberDataUser.last_name : "")
                                const photoUrl = memberDataUser.profile_picture_url && memberDocData.profile_picture_url || memberDataUser.photoURL && memberDataUser.photoURL;
                                memberDocData = {...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId}
                            }
                        }
                        // memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                        newMemberDocData = {...memberDocData, id: memberDataId}
                        newMembers.push({memberData: newMemberDocData, id: memberDataId})
                    }
                    if (!isAlreadyThereInActiveMembersIds) {
                        memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                        newActiveMembers.push(memberDataUser)
                    }
                    newTeam = {
                        ...team,
                        members: newMembers,
                        activeMembersIds: newActiveMembers,
                    }
                    newTeams = memberTeams.map((memberTeam) => {
                        if (memberTeam.id === team.id) {
                            return newTeam;
                        } else {
                            return memberTeam;
                        }
                    })
                    // console.log("NEW TEAMS", newTeams);
                    
                    setMemberTeams(newTeams)

                    // if (isAlreadyThereInMembers && isAlreadyThereInActiveMembersIds) {
                    //     const newMemberData = {...memberDocData, id: memberDataId}
                    //     newTeam = {
                    //         ...team,
                    //         activeMembersIds: [...team.activeMembersIds, memberDataUser],
                    //         members: [...team.members, newMemberData]
                    //     }
                    //     const newTeams = memberTeams.map((memberTeam) => {
                    //         if (memberTeam.id === team.id) {
                    //             return newTeam;
                    //         } else {
                    //             return memberTeam;
                    //         }
                    //     })
                    //     console.log("NEW TEAMS", newTeams);
                        
                    // }
                } else if (change.type === 'modified') {
                    // console.log('Modified member:', memberDocData);
                    if (memberDocData.status === 'joined') {
                        let newTeams: any[] = [];
                        // let newMembers: any[] = [];
                        let newActiveMembers: any[] = [];
                        if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                            memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                            if (memberDataUser) {
                                const memberUserId = memberDataUser.userId && memberDataUser.userId || memberDataUser.uid && memberDataUser.uid;
                                const memberName = memberDataUser.first_name + (memberDataUser.last_name ? " " + memberDataUser.last_name : "")
                                const photoUrl = memberDataUser.profile_picture_url && memberDocData.profile_picture_url || memberDataUser.photoURL && memberDataUser.photoURL;
                                memberDocData = {...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId}
                            }
                        }
                        // memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                        const newJoinedMember = {...memberDocData, id: memberDataId}
                        newTeams = memberTeams.map(async (item) => {
                            if (item.id === team.id) {
                                // if (!team.membersIds.includes(memberDocData.userId)) {
                                //     newMembers = [...team.membersIds, memberDocData.userId];
                                // } else {
                                //     newMembers = team.membersIds;
                                // }
                                const isMemberAlreadyThere = team.activeMembersIds.some((member: IUser) => {
                                    if (member.userId === memberDocData.userId || member.uid === memberDocData.userId) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                })
                                if (!isMemberAlreadyThere) {
                                    newActiveMembers = [...team.activeMembersIds, memberDataUser];
                                } else {
                                    newActiveMembers = team.activeMembersIds;
                                }
                                const newTeam = {
                                    ...item,
                                    // membersIds: newMembers,
                                    activeMembersIds: newActiveMembers,
                                    members: [...team.members, newJoinedMember]
                                }
                                return newTeam;
                            }
                            return team;
                        })
                        setMemberTeams(newTeams);
                    }
                } else if (change.type === 'removed') {
                    // console.log('Removed member:', memberDocData);
                    let newTeams: any[] = [];
                    if (currentUser && (memberDocData.email === currentUser.email)) {
                        newTeams = memberTeams.filter((item) => {
                            return item.id!== team.id;
                        })
                        await getSubscriptionStatus();
                    } else {
                        newTeams = memberTeams.map((item) => {
                            if (item.id === team.id) {
                                // const newMembersIds = team.membersIds.filter((member: string) => {
                                //     return member !== memberDataUserId
                                // })
                                const newActiveMembers = team.activeMembersIds.filter((activeMember: IUser) => {
                                    if (activeMember.uid) {
                                        return activeMember.uid !== memberDataUserId
                                    } else if (activeMember.userId) {
                                        return activeMember.userId !== memberDataUserId
                                    }
                                })
                                const newMembers = team.members.filter((member: any) => member.id !== memberDataId)
                                const newMembersEmails = team.membersEmails.filter((email: any) => email !== memberDocData.email)
                                const newTeam = {
                                    ...item,
                                    // membersIds: newMembersIds,
                                    activeMembersIds: newActiveMembers,
                                    members: newMembers,
                                    membersEmails: newMembersEmails,
                                }
                                
                                return newTeam;
                            } else {
                                return item;
                            }
                        })
                    }
                    setMemberTeams(newTeams)
                }
              };
            });
            return () => {
              unsubscribe();
            };
        }
      }, [team]);

  return (
    <div className='flex flex-col justify-start items-center gap-8'>
        <div className='w-full bg-[#F5F1FB] p-6 xsm:px-4 xsm:py-3 xsm:gap-4 rounded-2xl font-poppins flex flex-col gap-6 '>
            <div className='w-full flex justify-between items-center'>
                <p className=' font-semibold xsm:text-[14px] text-[20px] text-[#444553] flex justify-start items-center gap-2'>
                    {team.name} <span className='font-normal'>({membersToShow ? membersToShow.length + 1 : "1"})</span>
                </p>
                <span
                    onClick={() => setTeamExp(!teamExp)} 
                    className='cursor-pointer'
                    >
                    {teamExp ? <UpArrow width={screen === "small" ? 17 : 20} /> : <DownArrow width={screen === "small" ? 17 : 20} />}
                    {/* <DownArrow width={20} /> */}
                </span>
            </div>
            {teamExp && 
            <div className='flex flex-col justify-between items-center gap-6'>
                {/* <div className='flex justify-between items-center w-full'>
                    search
                    <button className='font-bold text-[16px] text-[#34317D] py-3 px-6 border-[1.5px] border-[#34317D] rounded-[10px] '>
                        Invite Members

                    </button>

                </div> */}

                <table className="w-full text-sm text-left bg-white rounded-xl">
                    <thead className="font-poppins text-base text-gray_dark w-8 border-b border-[#696A6F] flex-row">
                        <tr>
                            {/* <th scope="col" className="xsm:w-[15%] md:w-[10%] lg:w-[4%] px-3 py-3">
                                <img
                                    src={Uncheck}
                                    // src={isAllChecked ? Check : Uncheck}
                                    width={25}
                                    height={25}
                                    // onClick={handleHeaderCheckboxClick}
                                    alt="Checkbox"
                                />
                            </th> */}

                            <th scope="col" className="py-3 px-4" style={{ width: "85%" }}>
                                <div className="flex justify-start items-center gap-2 xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">
                                    Name 
                                    <img className="md:hidden" 
                                    // src={SortArrow} 
                                    alt="" />
                                </div>
                            </th>
                            {(screen !== "small" && screen !== "medium") && 
                                <th scope="col" className="py-3" style={{ width: "33%" }}>
                                    Member
                                </th>
                            } 
                            {/* <th scope="col" className="flex py-3" style={{ width: "13%" }}>
                                <span className="xsm:ml-[4.5rem] xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">

                                Actions
                                </span>
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPage === 1 && 
                            <tr 
                                // key={persona.id} 
                                // className={`xsm:h-[100px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === personas.length-1 ? "" : "border-b border-border_color"}`}
                                className={`xsm:-[100px] border-b border-border_color}`}
                                >     
                                {/* <td className="px-3 xsm:flex xsm:justify-start xsm:items-center ">
                                    <img 
                                        src={Uncheck} 
                                        width={25} 
                                        height={25} 
                                        // onClick={() => handlePersonaCheckboxClick(persona.id)}
                                    />
                                </td> */}
                                <td className="py-2 xsm:py-3">                 
                                    <div className="flex xsm:ml-2 ml-4 xsm:gap-2 profiles-center items-center xsm:items-start">
                                        <img
                                            className="rounded-lg"
                                            width={screen === "small" ? 38 : 50}
                                            height={screen === "small" ? 38 : 50}
                                            // src={
                                            //     persona.profilePictureUrl
                                            //     ? persona.profilePictureUrl
                                            //     : "https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
                                            // }
                                            src={
                                                (team.adminId.profile_picture_url && team.adminId.profile_picture_url || team.adminId.photoURL && team.adminId.photoURL) ||
                                                UserDummyPicture
                                            }
                                        />
                                        <div className="xsm:mt-0 flex flex-col justify-center md:ml-4 items-start w-full">
                                            <span
                                                style={{
                                                    overflow: "hidden",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "1",
                                                    WebkitBoxOrient: 'vertical',
                                                    textOverflow: 'ellipsis'
                                                }} 
                                                className="color-[#000] xsm:text-[#444553] font-poppins xsm:text-[16px] text-[20px] font-semibold md:mb-2"
                                                >
                                                {team.adminId.first_name + (team.adminId.last_name ? " " + team.adminId.last_name : "")}
                                            </span>
                                            <span className="color-[#000] xsm:text-[#444553] italic font-normal font-poppins xsm:text-[13px] text-[14px]">
                                                {team.adminId.email}
                                            </span>
                                            <button className="md:hidden mt-1 px-1 items-center rounded-[30px] font-poppins font-semibold xsm:text-[12px] text-[16px] text-white bg-light_purple">
                                                Admin
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                {(screen !== "small" && screen !== "medium") &&
                                <td className="py-1">
                                    <button className=" py-1 px-2 items-center gap-8 rounded-[30px] text-white bg-light_purple">
                                    {/* {persona?.Tag} */}
                                    Admin
                                    </button>
                                </td>
                                }
                                <td className="hidden justify-end align-middle py-3">
                                    <img 
                                        className="md:hidden relative mr-4 w-[25px]" 
                                        // src={Dots} 
                                        alt="" 
                                        // onClick={() => setDropDown((prev:any) => (prev === index ? null : index))}
                                    />
                                    {/* {(dropDown === index) &&
                                        <div ref={dropDownRef} className="absolute mt-6 mr-4 bg-white z-[1] w-[120px] dropDown-shadow p-1 flex flex-col ">
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}
                                                >
                                                Edit
                                            </span>
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => 
                                                {setDropDown(null);
                                                initiateDelete(persona.id)}
                                                }>Delete</span>
                                        </div>
                                    } */}
                                    <div className="xsm:hidden sm:hidden md:flex flex justify-between items-center mr-3">
                                        <button className='underline bg-transparent text-[#EB5757] text-[16px] font-bold text-underline px-4 py-3 rounded-lg flex items-center gap-2'
                                        // onClick={() => initiateDelete(persona.id)}
                                        >
                                            Delete
                                            <DeleteIcon width={15} color={"#EB5757"} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        }
                        {isLoadingMembers &&
                                    <tr
                                    className={`border-b border-border_color bg-white`}
                                        >
                                        <td colSpan={4} className='py-4'>
                                            <div className=' w-full flex justify-center items-center'>
                                                <Loader size={10} />
                                            </div>
                                        </td>
                                    </tr> 
                                }
                        {!isLoadingMembers && currentMembers.map((memberDoc: any) => {
                            // const member: IUser = memberDoc.memberData;
                            const member: {userId: string, photoUrl: string, name: string, email: string} = { userId: memberDoc.userId, photoUrl: memberDoc.photoUrl, name: memberDoc.name, email: memberDoc.email };
                            return (
                                <tr 
                                    key={member.userId} 
                                    // className={`xsm:h-[100px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === personas.length-1 ? "" : "border-b border-border_color"}`}
                                    className={`border-b border-border_color}`}
                                    >     
                                    {/* <td className="px-3 xsm:flex xsm:justify-start xsm:items-center ">
                                        <img 
                                            src={Uncheck} 
                                            width={25} 
                                            height={25} 
                                            // onClick={() => handlePersonaCheckboxClick(persona.id)}
                                        />
                                    </td> */}
                                    <td className="py-2 xsm:py-3">                 
                                        <div className="flex xsm:ml-2 ml-4 xsm:gap-2 profiles-center items-center xsm:items-start">
                                            <img
                                                className="rounded-lg"
                                                width={screen === "small" ? 38 : 50}
                                                height={screen === "small" ? 38 : 50}
                                                // src={
                                                //     persona.profilePictureUrl
                                                //     ? persona.profilePictureUrl
                                                //     : "https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
                                                // }
                                                src={
                                                    member.photoUrl ||
                                                    UserDummyPicture
                                                  }
                                            />
                                            <div className="flex flex-col justify-center md:ml-4 items-start w-full">
                                                <span
                                                    style={{
                                                        overflow: "hidden",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "1",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'ellipsis'
                                                      }}  
                                                    className="color-[#000] xsm:text-[#444553] font-poppins xsm:text-[16px] text-[20px] font-semibold md:mb-2"
                                                    >
                                                    {member.name}
                                                </span>
                                                <span className="color-[#000] xsm:text-[#444553] italic font-normal font-poppins xsm:text-[13px] text-[14px]">
                                                    {member.email}
                                                </span>
                                                <button className="md:hidden mt-1 px-1 items-center rounded-[30px] font-poppins font-semibold xsm:text-[12px] text-[16px] text-white bg-light_purple">
                                                    Team Member
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    {(screen !== "small" && screen !== "medium") &&
                                    <td className="py-1">
                                        <button className=" py-1 px-2 items-center gap-8 rounded-[30px] text-white bg-light_purple">
                                        {(member.userId !== team.adminId.userId || team.adminId.uid) || (member.userId !== team.adminId.userId || team.adminId.uid) ? "Team Member" : ""}
                                        </button>
                                    </td>
                                    }
                                    <td className="hidden justify-end align-middle py-3">
                                        <img 
                                            className="md:hidden relative mr-4 w-[25px]" 
                                            // src={Dots} 
                                            alt="" 
                                            // onClick={() => setDropDown((prev:any) => (prev === index ? null : index))}
                                        />
                                        {/* {(dropDown === index) &&
                                            <div ref={dropDownRef} className="absolute mt-6 mr-4 bg-white z-[1] w-[120px] dropDown-shadow p-1 flex flex-col ">
                                                <span className="flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                    onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}
                                                    >
                                                    Edit
                                                </span>
                                                <span className="flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => 
                                                    {setDropDown(null);
                                                    initiateDelete(persona.id)}
                                                    }>Delete</span>
                                            </div>
                                        } */}
                                        <div className="xsm:hidden sm:hidden md:flex flex justify-between items-center mr-3">
                                            {/* <button className='underline bg-transparent text-[#EB5757] text-[16px] font-bold text-underline px-4 py-3 rounded-lg flex items-center gap-2'
                                            // onClick={() => initiateDelete(persona.id)}
                                            >
                                                Delete
                                                <DeleteIcon width={15} color={"#EB5757"} />
                                            </button> */}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        <Pagination
                            colSpan={2}
                            itemsPerPage={membersPerPage}
                            totalItems={membersToShow.length ? membersToShow.length : 1}
                            currentPage={currentPage}
                            paginate={paginate}
                            setItemsPerPage={handleSetItemsPerPage}
                        />
                    </tbody>
                </table>
            </div>}
        </div>
    </div>
  )
}

export default MembersTeamsTable;
