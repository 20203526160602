import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { Navigate_to } from "../router/routes.constants";
import UserDummyPicture from "../assets/Images/ProfilePicUpload.svg";
import HamburgerIcon from "../assets/Icons/hamburger-icon.svg";

import CrossIcon from "../assets/Icons/cross-icon.svg";
import LogoutIcon from "../assets/Images/Log-out.svg"
import UserIcon from "../assets/Images/UserBlack.svg"
import DownArrow from "../assets/Images/downArrow.svg"
import QuestionAndIssuesMenu from "./QuestionAndIssuesMenu";
// import DownArrow from "../assets/svgs/DownArrow";
import TopBarUsageComponent from "./TopBarUsageComponent";
// import Notification_Dissmiss from '../assets/Icons/notification_dissmiss.svg'
import CreditCard from "../assets/Images/CreditCard.svg";

import { STORAGE_KEYS } from "@/config/appConfig";
import GlobalTeamInviteBanner from "./GlobalTeamInviteBanner";


interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMyAccountWelcomeStep?: boolean;
  setRightMarginToSet?: React.Dispatch<React.SetStateAction<number | null>>;
}

function DashboardHeader({ isOpen, setIsOpen, isMyAccountWelcomeStep, setRightMarginToSet }: IProps) {
  const navigate = useNavigate();
  const { user, signOut, subscriptionStatus } = useContext(AuthContext) as AuthContext_type;

  const [showDropDown, setShowDropDown] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const profileDropdownRef = useRef<HTMLDivElement | null>(null);
  const profileDropdownInnerDivRef = useRef<HTMLDivElement | null>(null);

  const handleSignout = () => {
    localStorage.removeItem(STORAGE_KEYS.LAST_LANGUAGE_USED);
    signOut();
    setShowDropDown(false);
    setIsClicked(false);
  }

  const handleClickOutside = (event: MouseEvent) => {
    const targetNode = event.target as Node | HTMLElement;


    if (profileDropdownRef.current && !profileDropdownRef.current.contains(targetNode)) {
      const targetNodeId = (targetNode as HTMLElement).id;
      // console.log(targetNode);
      // console.log(targetNodeId);

      if (targetNodeId === "profile-dropdown-desk" || targetNodeId === "profile-dropdown-mob") {
        setShowDropDown(false);
      }

      if (targetNodeId !== "my-account-desk" && 
        targetNodeId !== "my-account-mob" && 
        targetNodeId !== "my-subscription" &&
        targetNodeId !== "log-out-desk" && 
        targetNodeId !== "log-out-mob") {
            
        if (targetNodeId !== "profile-dropdown-desk" && targetNodeId !== "profile-dropdown-mob") {
          setIsClicked(false);
        }
        
        setShowDropDown(false);
      }
      // if ((targetNode.nodeName !== "A" && targetNode.textContent !== "My Account") && (targetNode.nodeName !== "SPAN" && targetNode.textContent !== "Log Out")) {
      //   // console.log(targetNode.nodeName, targetNode.textContent);
      //   console.log("CONIDTIION");
      //   if (targetNodeId !== "profile-dropdown") {
      //     setIsClicked(false);
      //   }
      //   setShowDropDown(false);
      // }
    }

  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);

  useEffect(() => {
    if (isClicked) {
      setShowDropDown(true);
    } else if (!isClicked && showDropDown) {
      setShowDropDown(false);
    }
  }, [isClicked])

  // useEffect(() => {
  //   // console.log("width", profileDropdownRef.current && profileDropdownRef.current.offsetWidth);

  //   if (profileDropdownRef.current && profileDropdownRef.current.className === "hidden") {
  //     // console.log("HIDDEN");
  //   } else if (profileDropdownRef.current && profileDropdownRef.current.className !== "hidden") {
  //     // console.log("SHOWN");

  //   }
  // }, [profileDropdownRef.current])

  useEffect(() => {
    if ((showDropDown && isMyAccountWelcomeStep) && profileDropdownInnerDivRef.current) {
      setRightMarginToSet && setRightMarginToSet(profileDropdownInnerDivRef.current.offsetWidth + 112)
    }
  }, [showDropDown, profileDropdownInnerDivRef.current])


  useEffect(() => {
    if (isMyAccountWelcomeStep === true) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  }, [isMyAccountWelcomeStep])

  // const [showNotifications, setShowNotifications] = useState(false)
  // const [newNotifications, setNewNotifications] = useState(true)
  // const [ setNotificationsRead] = useState(false);

  // const handleNotificationClick = () => {
  //   setShowNotifications(!showNotifications);

  //   // Mark notifications as read when opened
  //   if (newNotifications) {
  //     setNewNotifications(false);
  //     setNotificationsRead(true);
  //   }
  // };

  return (
    <div>
      <div className={`${isMyAccountWelcomeStep ? "z-[11]" : "z-10"} relative`}>
        <div className="absolute top-2 right-0 hidden h-24 md:flex items-center justify-between w-full z-0 pl-2 pr-4">
          <div className="flex items-center space-x-2">
            {/* <img src={Search} /> */}
            {/* <a href="">
            <span className="text-light_dark text-[18px] leading-normal font-poppins">
              Search
            </span>
          </a> */}
          </div>

          <div className="flex items-center w-full justify-end gap-x-4">
            <div className="flex-[2] flex">
              <GlobalTeamInviteBanner />
            </div>
            {/* <div className="relative">
              </div> */}
              {/* <div className='relative '>
              <img className='mr-6 cursor-pointer' src={Notification} alt='Notification' onClick={handleNotificationClick} />
              {
                newNotifications && !notificationsRead &&  <div className='absolute bg-[red] w-[5px] h-[5px] top-1 right-[30px] rounded-[100px]'></div>
              }
             {showNotifications && <div className={`absolute w-[360px] h-[500px] bottom-[-510px] left-[-330px] bg-white shadow-2xl rounded-[16px] overflow-y-auto`}>
                <div className='flex flex-col gap-[24px] m-4 mb-2 mx-6'>
                  <div className='flex justify-between text-[22px]'>
                    <p className='flex justify-between items-center text font-poppins text-weight-600 text-[22px]'>Notifications</p>
                    <span className='cursor-pointer' onClick={() => setShowNotifications(!showNotifications)}><img src={Notification_Dissmiss}/></span>
                  </div>
                  <div className='flex flex-col gap-[16px]'>
                    <span className='text-weight-400 font-poppins text-[16px] border-b-[1px] border-[#6E40C5] py-[15px]'>Lorem ipsum dolor sit amet. Et dolores quisquam aut recusandae quas 33 facere dolorem.</span>
                    <span className='text-weight-400 font-poppins text-[16px] border-b-[1px] border-[#6E40C5] py-[15px]'>Lorem ipsum dolor sit amet. Et dolores quisquam aut recusandae quas 33 facere dolorem.</span>
                    <span className='text-weight-400 font-poppins text-[16px] border-b-[1px] border-[#6E40C5] py-[15px]'>Lorem ipsum dolor sit amet. Et dolores quisquam aut recusandae quas 33 facere dolorem.</span>
                    <span className='text-weight-400 font-poppins text-[16px] py-[15px]'>Lorem ipsum dolor sit amet. Et dolores quisquam aut recusandae quas 33 facere dolorem.</span>
                  </div>
                </div>
              </div>}
            </div> */}
            
            {((subscriptionStatus && subscriptionStatus.status === 0) && !isMyAccountWelcomeStep) && <TopBarUsageComponent />}
            <QuestionAndIssuesMenu />
            <Button
              id="profile-dropdown-desk"
              onClick={() => setIsClicked(!isClicked)}
              // onClick={(e) => {
              //   e.stopPropagation(); // Stop click propagation
              //   // if (!showDropDown) {
              //   //   setShowDropDown(true);
              //   // }
              //   setIsClicked(!isClicked);
              // }}
              className="bg-white relative gap-2 !p-[1px] cursor-pointer !rounded-[32px] flex justify-between max-w-fit w-full"
            >
              <img
                // onClick={() => navigate(Navigate_to.myAccount)}
                id="profile-dropdown-desk"
                className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] rounded-full object-cover"
                src={
                  user?.profile_picture_url ||
                  UserDummyPicture
                }
              />
              <img id="profile-dropdown-desk" width={15} src={DownArrow} className="mr-1" />
              {/* <span id="profile-dropdown-desk" className=" mr-1">
                <DownArrow width={15} />
              </span> */}
              {/* <img src={UserIcon} alt="User" /> */}

              {/* {user?.first_name && user?.first_name ? (
                <h3 className="font-poppins text-[16px] leading-normal font-bold text-primary_color  pl-4">
                  {`${user?.first_name ? user?.first_name : ""}` +
                    " " +
                    `${user?.last_name ? user?.last_name : ""}`}
                </h3>
              ) : null} */}
            </Button>
            <div
              ref={profileDropdownRef}
              className={`${!showDropDown ? "hidden" : "font-poppins flex flex-col gap-5 justify-between items-center absolute mt-56 bg-white rounded-xl border border-[#F6F6F6] p-4 shadow-[0px_4px_15px_0px_rgba(0,0,0,0.1)]"}`}
            >
              <div ref={profileDropdownInnerDivRef} className="flex justify-center items-center gap-2 cursor-pointer">
                <img
                  // onClick={() => navigate(Navigate_to.myAccount)}
                  className="w-[45px] h-[45px] rounded-full"
                  src={
                    user?.profile_picture_url ||
                    UserDummyPicture
                  }
                />
                <div>
                  <h3 className="font-poppins text-[14px] leading-normal font-bold text-[#444553] ">
                    Hi {`${user?.first_name ? user?.first_name : ""}`}!
                  </h3>
                  <p className="font-poppins text-[12px] leading-normal font-normal italic text-[#444553] ">
                    {`${user?.email ? user?.email : ""}`}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-3 w-full ml-1">
                <Link
                  id="my-account-desk"
                  to={Navigate_to.myAccount}
                  onClick={() => setIsClicked(false)}
                  className="w-full cursor-pointer flex justify-start items-center gap-2"
                >
                  <img src={UserIcon} alt="User" width={20} />
                  My Account
                </Link>
                <Link
                  id="my-account-desk"
                  to={Navigate_to.subscriptionPricing}
                  onClick={() => setIsClicked(false)}
                  className="w-full cursor-pointer flex justify-start items-center gap-2"
                >
                  <img src={CreditCard} alt="Subscription" width={20} />
                  Subscription
                </Link>
                <hr color="#DFDFDF" className="w-full" />
                <span id="log-out-desk" onClick={handleSignout} className="w-full cursor-pointer flex justify-start items-center gap-2">
                  <img src={LogoutIcon} alt="Logout" width={20} />
                  Log Out
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile header */}

      <div className=" flex justify-between items-center p-4 md:hidden border-b border-solid fixed top-0 left-0 z-[99] w-screen border-gray-300 bg-white cursor-pointer">
        <div className="flex items-center gap-x-4">
          <img
            onClick={() => setIsOpen((pre) => !pre)}
            src={isOpen ? CrossIcon : HamburgerIcon}
            alt="ham"
          />
          <img
            onClick={() => navigate(Navigate_to.afterLogin)}
            className="w-24 h-11"
            src="https://evyai.com/wp-content/uploads/2023/05/Evy_Logo.png"
          />
        </div>
        <div className="flex justify-end w-full items-center gap-x-4">
          {subscriptionStatus && subscriptionStatus.status === 0 && <TopBarUsageComponent setIsOpen={setIsOpen} />}
          {/* <QuestionAndIssuesMenu /> */}
          <div
            id="profile-dropdown-mob"
            onClick={() => {
              setIsClicked(!isClicked)
              setIsOpen(false);
            }}
            className="w-full h-8 bg-white relative gap-2 !p-[1px] cursor-pointer !rounded-[32px] flex justify-between items-center max-w-fit"
          >
            <img
              // onClick={() => navigate(Navigate_to.myAccount)}
              id="profile-dropdown-mob"
              className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] rounded-full object-cover"
              src={
                user?.profile_picture_url ||
                UserDummyPicture
              }
            />
            <img id="profile-dropdown-mob" width={15} src={DownArrow} className="mr-1" />
          </div>
          <div
            ref={profileDropdownRef}
            className={`${!showDropDown ? "hidden" : "font-poppins flex flex-col gap-5 justify-between items-center absolute mt-48 bg-white rounded-xl border border-[#F6F6F6] p-4 shadow-[0px_4px_15px_0px_rgba(0,0,0,0.1)]"}`}
          >
            <div className="flex justify-center items-center gap-2 cursor-pointer">
              <img
                // onClick={() => navigate(Navigate_to.myAccount)}
                className="w-[45px] h-[45px] rounded-full"
                src={
                  user?.profile_picture_url ||
                  UserDummyPicture
                }
              />
              <div>
                <h3 className="font-poppins text-[14px] leading-normal font-bold text-[#444553] ">
                  Hi {`${user?.first_name ? user?.first_name : ""}`}!
                </h3>
                <p className="font-poppins text-[12px] leading-normal font-normal italic text-[#444553] ">
                  {`${user?.email ? user?.email : ""}`}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center gap-3 w-full ml-1">
              <Link
                id="my-account-mob"
                to={Navigate_to.myAccount}
                onClick={() => setIsClicked(false)}
                className="w-full cursor-pointer flex justify-start items-center gap-2"
              >
                <img src={UserIcon} alt="User" width={20} />
                My Account
              </Link>
              <Link
                id="my-account-mob"
                to={Navigate_to.subscriptionPricing}
                onClick={() => setIsClicked(false)}
                className="w-full cursor-pointer flex justify-start items-center gap-2"
              >
                <img src={CreditCard} alt="Subscription" width={20} />
                Subscription
              </Link>

              <hr color="#DFDFDF" className="w-full" />
              <span id="log-out-mob" onClick={handleSignout} className="w-full cursor-pointer flex justify-start items-center gap-2">
                <img src={LogoutIcon} alt="Logout" width={20} />
                Log Out
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;