import { useState, useContext, useEffect } from "react";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import DailogBox from "../../../components/DailogBox";
import { UserLists } from "../../ManageLists";
import { GrFormClose } from 'react-icons/gr';
import { SavedProfiles } from "..";
import Loader from "../../../components/Loader";
import type { FetchData } from "../index";
import { sentryClient } from "@/Sentry/sentry";

export interface ManageProfileProps {
    setShowAlert: () => void;
    showAlert: boolean
    userProfile: SavedProfiles
    savedProfiles: Array<SavedProfiles>
    allLists: Array<UserLists>;
    setSavedProfiles: React.Dispatch<React.SetStateAction<SavedProfiles[]>>;
    handleGetUserProfile: (fetchData?: FetchData) => Promise<any>
    profilesPerPage : number;
    isPaginationDone : boolean;
}

export default function ManageProfile(props: ManageProfileProps) {
    const { handleGetUserProfile, setShowAlert, showAlert, userProfile, allLists, savedProfiles, setSavedProfiles, profilesPerPage, isPaginationDone } = props;
    const { handleGetUserLists, updateProfileToLists } = useContext(AuthContext) as AuthContext_type;

    const [profile, setProfile] = useState<SavedProfiles>()
    const [userLists, setUserLists] = useState<string[]>([])
    const [orginalUserLists, setoriginalUserLists] = useState<UserLists[]>([])
    const [isUpdating, setIsUpdating] = useState(false)


    useEffect(() => {
        async function fetchData() {
            try {
                const allLists = await handleGetUserLists();
                setoriginalUserLists(allLists)
                let namesArray = allLists.map((list: UserLists) => list.name);

                // If userProfile has lists, filter out names from namesArray
                if (userProfile?.lists?.length) {
                    namesArray = namesArray.filter((name: string) => !userProfile.lists?.some(list => list.name === name));
                }

                setProfile(userProfile);
                setUserLists(namesArray);

            } catch (error) {
                toastAlertError("Failed to fetch lists");
                sentryClient.captureException(error)
            }
        }

        fetchData();
    }, [userProfile, allLists]);


    function handleCloseDialog() {
        setShowAlert()
    }
    const currentTimeInNanoseconds = BigInt(Date.now()) * BigInt(1e6);

    const handleOnChange = (value: string) => {
        const newAddedList = value;
        const updatedUserLists = userLists.filter(list => list !== newAddedList);
        setUserLists(updatedUserLists);
        if (profile) {
            setProfile((prevProfile: any) => {
                if (!prevProfile) return prevProfile;

                const newList = { id: currentTimeInNanoseconds.toString(), name: newAddedList };
                const updatedProfileLists = prevProfile.lists ? [...prevProfile.lists, newList] : [newList];
                return {
                    ...prevProfile,
                    lists: updatedProfileLists
                };
            });
        }
    };

    function handleRemoveOnChange(value: string) {
        const updatedUserLists = [...userLists, value];
        setUserLists(updatedUserLists);
        if (profile) {
            setProfile(prevProfile => {
                if (!prevProfile) return prevProfile;

                // Remove the list from profile.lists where list.name is equal to value
                const updatedProfileLists = prevProfile.lists
                    ? prevProfile.lists.filter(list => list.name !== value)
                    : [];

                return {
                    ...prevProfile,
                    lists: updatedProfileLists
                };
            });
        }
    }

    async function updateProfileWithLists() {
        if (profile) {
            const filteredLists = orginalUserLists.filter(list => !userLists.includes(list.name));
            const filteredListIds = filteredLists.map(list => list.id);
            await updateProfileToLists(profile.id, filteredListIds)
            const updatedProfiles = savedProfiles.map(savedProfile =>
                savedProfile.id === profile.id ? profile : savedProfile
            );
            const profileWithEmptyLists = updatedProfiles.some(savedProfile => (savedProfile.lists ?? []).length === 0);
            // console.log(profileWithEmptyLists,'profileWithEmptyLists')
            // if (profileWithEmptyLists) {
                handleGetUserProfile({
                    orderByField: "timestamp",
                    orderDirection: "desc",
                    init: (isPaginationDone) ? false : true,
                    query: "",
                    isNext: isPaginationDone,
                    startAfterFristDoc: (isPaginationDone) ? true : false,
                    itemsPerPage: profilesPerPage,
                });
            // } else {
            //     setSavedProfiles(updatedProfiles);
            // }

            if(profileWithEmptyLists === false){
                setSavedProfiles(updatedProfiles);
            }
            toastAlertSuccess("Update successful");
        }
    }

    async function handleSubmit() {
        try {
            setIsUpdating(true);
            await updateProfileWithLists();
            handleCloseDialog();
        } catch (error) {
            console.log(error);
            sentryClient.captureException(error)
        } finally {
            setIsUpdating(false);
        }
    }
    return (
        <>
            <DailogBox
                className='flex flex-col items-center justify-center !max-w-[644px]'
                open={showAlert}
                onClose={handleCloseDialog}
            >
                <div className='flex flex-col items-center gap-2'>
                    <h2 className='font-bold xsm:font-black xsm:text-[28px] text-[#333333] text-[32px] font-poppins'>
                        List Manager
                    </h2>
                    <p className='text-base xsm:text-[14px] font-poppins text-[#333333]'>
                        Add or remove <span className="font-bold"> {profile?.linkedInProfileName} </span> from a list
                    </p>
                </div>
                <div className="items start w-full">
                    <div className="custom-select w-full">
                        <select
                            value=""
                            onChange={(e) => handleOnChange(e.target.value)}
                            className="block w-full xsm:text-[14px] text-base outline-none text-gray_light font-poppins border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3">
                            <option className="xsm:text-[14px]" value="">Add Profile to List</option>
                            {userLists?.map((list, index) => {
                                return (
                                    <option key={index} value={list}>
                                        {list}
                                    </option>
                                );
                            })}
                        </select>
                        <div className="custom-arrow">
                            <svg
                                width="21"
                                height="12"
                                viewBox="0 0 21 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z"
                                    fill="#484848"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="flex mt-3 justify-center mb-2 w-[600px] xsm:w-full xsm:flex-wrap xsm:gap-y-2" >
                    {profile?.lists?.map((list: UserLists) => (
                        <span key={list.id} className="rounded-xl xsm:justify-center xsm:items-center text-primary_color font-semibold xsm:font-bold xsm:text-[14px] bg-chip_bg pr-1 pl-3 py-0.5 ml-2 flex">
                            <span>{list.name}</span>
                            <span className="">
                                <GrFormClose
                                    size={7}
                                    onClick={() => handleRemoveOnChange(list.name)}
                                    className='w-5 h-5 font-bold text-secondry_color rounded-full cursor-pointer'
                                />
                            </span>
                        </span>
                    )) || null}
                </div>
                <div className="flex xsm:flex-col-reverse gap-4 xsm:w-full">
                    <button
                        type='button'
                        onClick={() => handleCloseDialog()}
                        className='all_dialogue_sType_buttons text-secondry_color disabled:opacity-50'
                    >

                        Cancel
                    </button>
                    <button
                        type='button'
                        disabled={isUpdating}
                        onClick={handleSubmit}
                        className='all_dialogue_pType_buttons  bg-secondry_color text-white
'
                    >
                        Save Changes {isUpdating && <Loader size={4} />}
                    </button>
                </div>
            </DailogBox>
        </>
    );
}