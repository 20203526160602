import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";


import ArrowRight from "../../assets/Images/arrowRight.svg";

import HistoryIcon from "../../assets/Images/History.svg";

import { Navigate_to } from "../../router/routes.constants";
import ActivityCard from "../../components/ActivityCard";

import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import { SubscriptionStatus } from "../../config/appConfig";
import { UsageCompleteDisabledWrapper } from "../../utils/UsageCompleteDisabledWrapper";
import ActionButtons from "../Dashboard/components/ActionButtonsHome";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../services/firebase";
import { COLLECTIONS, FIELDS } from "../../config/firebase";
import { toastAlertSuccess } from "../../utils";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { sentryClient } from "@/Sentry/sentry";

// const NUMBER_OF_RECORDS_TO_FETCH = 20;

const RecentActivities = () => {
  const { getCurrentUser, subscriptionStatus, deleteActivity } = useContext(
    AuthContext
  ) as AuthContext_type;

  const [recentActivities, setRecentActivities] = useState<any[]>([]);
  const [isLoadinRecentActivities, setIsLoadinRecentActivities] =
    useState(true);
  const [showDialog, setShowDeleteDialog] = useState(false);
  const [id, setDocumentId] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  // const [
  //   doesHaveMoreRecentActivitiesToLoad,
  //   setDoesHaveMoreRecentActivitiesToLoad,
  // ] = useState(true);


  const fetchRecentActivities = async () => {
    try {
      setIsLoadinRecentActivities(true);
      const user = await getCurrentUser();
      // let q : Query
      if (user?.uid) {
        let q = query(
          collection(
            db,
            `${COLLECTIONS.USER_ACTIVITIES}/${user?.uid}/${COLLECTIONS.ACTIVITIES}`
          ),
          orderBy(FIELDS.TIMESTAMP, "desc")

        );

        onSnapshot(q, (querySnapshot) => {
          setIsLoadinRecentActivities(false);
          const cmds: any[] = [];
          querySnapshot.forEach((doc) => {
            cmds.push({ id: doc.id, ...doc.data() });
          });

          setRecentActivities(cmds);
          // setIsLoadinRecentActivities(false);
        });
      }
    } catch (error) {
      setIsLoadinRecentActivities(false);
      sentryClient.captureException(error)
      return error;
    }
  };


  const handleDelete = async () => {
    try {
      if (id) {
        setIsDeleting(true);
        await deleteActivity(id);
        toastAlertSuccess("Deleted successfully");
      }
      setShowDeleteDialog(false);
    } catch (err) {
      console.error(err);
      sentryClient.captureException(err)
    } finally {
      setIsDeleting(false);
    }
  };


  useEffect(() => {
    fetchRecentActivities();
  }, []);

  return (
    <section>
      <div className="flex">
        <Link
          to={Navigate_to.home}
          className="flex justify-start items-center cursor-pointer"
        >
          <h2 className="font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] ml-2 opacity-60">
            Home
          </h2>
          <img
            className="ml-2 md:w-[14px] w-3 opacity-60"
            src={ArrowRight}
            alt=""
          />
        </Link>

        <h2 className="font-poppins md:text-[16px] text-[14px] font-bold leading-[24px] text-[#444553] ml-2">
          Recent Activities
        </h2>
      </div>

      <div className="md:mt-7 mt-5 flex justify-between items-center md:pr-0 pr-3  flex-wrap">
        <div className="flex items-center">
          <img className="mr-2" src={HistoryIcon} alt="History" />
          <span className="text-black md:text-[20px] text-lg leading-normal font-poppins font-semibold">
            Recent Activities
          </span>
        </div>
        <ActionButtons
          setActivities={setRecentActivities}
          activities={"Recent Activities"}
        />
      </div>
      {!isLoadinRecentActivities && recentActivities.length <= 0 && (
        <NoDataFound text={"No recent activities found"} isLoading={false} />
      )}
      <UsageCompleteDisabledWrapper
        isDisabled={
          !!(
            subscriptionStatus?.usagePercentage &&
            subscriptionStatus.usagePercentage >= 100
          ) && subscriptionStatus.status === SubscriptionStatus.FREE
        }
      >
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-6 mb-10">
          {recentActivities?.map((item, index) => {
            if (recentActivities.length === index + 1) {
              return (
                <div key={index}>
                  <ActivityCard
                    key={index}
                    activity={item}
                    generatedText={
                      item.activityType === "post_ideas"
                        ? item.activityData.summaryText
                        : item?.activityData?.generatedText
                    }
                    isRecent={true}
                    currentIndex={index}
                    setSavedCommands={setRecentActivities}
                    savedCommands={recentActivities}
                    onDeleteClicked={() => {
                      setDocumentId(item.id);
                      setShowDeleteDialog(true);
                    }}
                  />
                </div>
              );
            } else {
              return (
                <ActivityCard
                  key={index}
                  activity={item}
                  generatedText={
                    item.activityType === "post_ideas"
                      ? item.activityData.summaryText
                      : item?.activityData?.generatedText
                  }
                  isRecent={true}
                  currentIndex={index}
                  setSavedCommands={setRecentActivities}
                  savedCommands={recentActivities}
                  onDeleteClicked={() => {
                    setDocumentId(item.id);
                    setShowDeleteDialog(true);
                  }}
                />
              );
            }
          })}
        </div>
      </UsageCompleteDisabledWrapper>
      {isLoadinRecentActivities && (
        <div className="flex justify-center padding-[50px]">
          {" "}
          <Loader size={10} />{" "}
        </div>
      )}
      <ConfirmationDialog
        setShow={setShowDeleteDialog}
        show={showDialog}
        onConfirm={() => {
          handleDelete();
        }}
        isLoading={isDeleting}
        header="Delete Activity"
        content="Are you sure you want to delete this activity?"
      />
    </section>
  );
};

export default RecentActivities;
