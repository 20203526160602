import { HOME_PAGE } from "../constant";

type PropsType = {};

export default function Header(props: PropsType) {
  const {} = props;

  return (
    // todo a link to web
    <header className="sm:py-4 p-4">
      <a href={HOME_PAGE} className="max-w-[123px] max-sm:max-w-[100px] block">
        <img
          className="w-full"
          src="https://evyai.com/wp-content/uploads/2023/05/Evy_Logo.png"
        />
      </a>
    </header>
  );
}
