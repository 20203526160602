import CheckIcon from "../assets/Images/Check.svg";
// import WhiteCheckIcon from "../assets/Icons/CheckIconWhite.svg";

import Button from "./Button";
import { Navigate_to } from "../router/routes.constants";
import { ThreeDots } from "react-loader-spinner";
import { useContext, useEffect, useState } from "react";
import UpArrow from "../assets/svgs/UpArrow";
import DownArrow from "../assets/svgs/DownArrow";
import RightArrow from "../assets/svgs/RightArrow";
import LeftArrow from "../assets/svgs/LeftArrow2";
import { openInSameTab } from "../utils";
import { AuthContext, AuthContext_type } from "../context/Auth/context";

function SubscriptionPricingCards({
  type,
  price,
  id,
  idTeams,
  isLoading,
  onClick,
  isEnableTrail,
  interval,
  setSubPlanPaymentURL,
  setShowStripeLinkDialogue,
}: {
  id?: string;
  idTeams?: string;
  type?: "month" | "year";
  price?: number;
  isLoading: string | null;
  onClick?: (id: string, quantity?: number, trial_from_plan?: boolean) => Promise<unknown>;
  isEnableTrail?: boolean;
  interval?: string;
  setSubPlanPaymentURL?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowStripeLinkDialogue?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const getPrice = () => parseInt((price || 0) / 100 + "", 10);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [basicCard, setBasicCard] = useState(false)
  const [monthlyCard, setMonthlyCard] = useState(false)
  const [monthlyTeamCard, setMonthlyTeamCard] = useState(false)
  const [annuallyCard, setAnnuallyCard] = useState(false)
  const [annuallyTeamCard, setAnnuallyTeamCard] = useState(false)
  const [monthlyTeamsUsers, setMonthlyTeamsUsers] = useState(2)
  const [yearlyTeamsUsers, setYearlyTeamsUsers] = useState(2)

  const { 
    goToSubscriptionPlanPaymentURL,
} = useContext(AuthContext) as AuthContext_type;

  const handleGetSubscriptionLink = async (id: string, quantity?: number | undefined, trial_from_plan?: boolean | undefined) => {
    
    const res = await goToSubscriptionPlanPaymentURL(id, quantity, trial_from_plan)
    if (res && setSubPlanPaymentURL && setShowStripeLinkDialogue) {
      setSubPlanPaymentURL(res as string);
      setShowStripeLinkDialogue(true)
      openInSameTab(res as string);
    }
  }

  const handleIncreaseMonthlyTeamsUsers = (increase: boolean) => {
    if (increase) {
      setMonthlyTeamsUsers(monthlyTeamsUsers + 1);
    } else {
      if (monthlyTeamsUsers === 2) {
        return
      } 
      setMonthlyTeamsUsers(monthlyTeamsUsers - 1);
    }
  }
  const handleIncreaseYearlyTeamsUsers = (increase: boolean) => {
    if (increase) {
      setYearlyTeamsUsers(yearlyTeamsUsers + 1);
    } else {
      if (yearlyTeamsUsers === 2) {
        return
      } 
      setYearlyTeamsUsers(yearlyTeamsUsers - 1);
    }
  }

  useEffect(() => {
    if (screenWidth > 800) {
      setBasicCard(true);
      setMonthlyCard(true);
      setMonthlyTeamCard(true);
      setAnnuallyCard(true);
      setAnnuallyTeamCard(true);
    } else {
      setBasicCard(false);
      setMonthlyCard(false);
      setMonthlyTeamCard(false);
      setAnnuallyCard(false);
      setAnnuallyTeamCard(false);
    }
  }, [screenWidth])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const handleClick = async (enableTrial: boolean = false) => {
    if (onClick && id && setSubPlanPaymentURL && setShowStripeLinkDialogue) {
      if (enableTrial) {
        await handleGetSubscriptionLink(id, undefined, true);
      } else {
        await handleGetSubscriptionLink(id);
      }
    }
  };
  
  const handleClickTeam = async (intervalType: string) => {
    if (onClick && idTeams) {
      if (intervalType === "month") {
        await handleGetSubscriptionLink(idTeams, monthlyTeamsUsers);
        } else if (intervalType === "year") {
        await handleGetSubscriptionLink(idTeams, yearlyTeamsUsers);
      }
    }
  };

  return (
    <>
      {type ? (
        <>
          {type === "month" ? (
            <>
              <div className="hidden w-[50%]">
                <label
                  className="xsm:hidden flex flex-col px-4 py-2 bg-white rounded-[10px] cursor-pointer"
                  htmlFor="radio_2"
                  // onClick={handleClick}
                >
                  {isLoading == id ? (
                    <ThreeDots height={66} color="#6E40C5" />
                  ) : (
                    <>
                      <span className="font-poppins text-[10px] font-bold leading-normal text-text_color">
                        Monthly
                      </span>
                      <span className="font-poppins text-[20px] font-extrabold leading-normal text-text_color mt-1 mb-4">
                        ${getPrice()}
                      </span>
                    </>
                  )}
                </label>
              </div>

              <div
                className={`xsm:w-full bg-[#34317D] relative text-[#444553] border-[#6D40C4] rounded-3xl border-2   py-8 px-6 flex flex-col justify-between md:max-w-[335px] lg:min-h-[500px] font-poppins`}
              >
                <Button
                  className={`!bg-[#6D40C4] text-white px-4 py-1 tracking-wider rounded-full font-poppins font-bold text-[16px] absolute inset-x-0 mx-auto w-max -top-3`}
                >
                  MOST POPULAR
                </Button>
                <div>
                  <h3
                    className={`flex justify-between text-[#FFD572] items-center text-[22px] font-bold leading-8  text-start `}
                  >
                    Premium
                    <div className="md:hidden" onClick={() => {setMonthlyCard(!monthlyCard)}}>
                      {!monthlyCard ? <DownArrow color={"white"} /> : <UpArrow color={"white"} />}
                    </div>
                  </h3>
                  <div className="flex items-center gap-2 text-white ">
                    <span className="text-[40px] font-black leading-[60px]">
                      ${getPrice()}
                    </span>
                    <div className="flex flex-col items-start">
                      <span className="text-lg font-bold mt-1">USD</span>
                      <p className="text-base font-normal">
                         / {type}{isEnableTrail ? ", after 3-day trail" : ""}
                      </p>
                    </div>
                  </div>
                  <Button
                    isLoading={isLoading === id}
                    disabled={isLoading === id}
                    onClick={() => {
                      if (isEnableTrail) {
                        handleClick(true)
                      } else {
                        handleClick()
                      }
                    }}
                    className={`bg-white h-11 text-[#34317D] leading-[24px] w-full text-center font-poppins  text-[18px] font-bold !py-[10px] px-5 rounded-[10px] ${isEnableTrail ? "mt-5" : "mt-10"}`}
                  >
                    <span className="h-full">
                      {isEnableTrail
                        ? "3-Day FREE Premium Trial"
                        : "Upgrade To Premium"}
                    </span>
                  </Button>
                  {monthlyCard &&
                    <div className="text-white">
                      <p className="text-base font-bold text-start mt-6">
                        Includes
                      </p>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited activities </b>(comment, post, message, and
                          more)
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited access</b> to your history and saved items
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          Full access to <b> GPT 4 </b>
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <a
                          href="https://evyai.com/upgrade/"
                          target="_blank"
                          className={`text-[#FFD572] text-[16px] font-bold leading-7 pl-3 text-start underline`}
                        >
                          <b> And more </b>
                        </a>
                      </div>
                    </div>
                    }
                </div>
              </div>


              <div className="hidden w-[50%]">
                <label
                  className="xsm:hidden flex flex-col px-4 py-2 bg-white rounded-[10px] cursor-pointer"
                  htmlFor="radio_2"
                  // onClick={handleClickTeam}
                >
                  {isLoading == id ? (
                    <ThreeDots height={66} color="#6E40C5" />
                  ) : (
                    <>
                      <span className="font-poppins text-[10px] font-bold leading-normal text-text_color">
                        Teams
                      </span>
                      <span className="font-poppins text-[20px] font-extrabold leading-normal text-text_color mt-1 mb-4">
                        ${29 * monthlyTeamsUsers}
                      </span>
                    </>
                  )}
                </label>
              </div>

              <div
                className={`xsm:w-full bg-white bg-transparent text-[#444553] rounded-3xl border-2   py-8 px-6 flex flex-col justify-between md:max-w-[335px] lg:min-h-[500px] font-poppins`}
              >
                <div>
                  <div className="md:hidden  w-full flex justify-end mb-3" onClick={() => {setMonthlyTeamCard(!monthlyTeamCard)}}>
                    {!monthlyTeamCard ? <DownArrow/> : <UpArrow/>}
                    {/* {(!monthlyCard && isEnableTrail) ? <DownArrow color={"white"} /> : <UpArrow color={"white"} />} */}
                  </div>
                  <div className="flex justify-between items-center">
                    <h3
                      className={`flex justify-between items-center text-[22px] font-bold leading-8  text-start text-secondry_color`}
                    >
                      Teams
                    </h3>
                    <div className="xsm:hidden border border-[#A1A2A980] rounded-[10px]  px-4 flex justify-between gap-4 items-center font-bold text-[20px]">
                      {monthlyTeamsUsers}
                      <div className="flex flex-col justify-between items-center">
                        <span className="cursor-pointer" onClick={() => handleIncreaseMonthlyTeamsUsers(true)}>
                          <UpArrow width={17} />
                        </span>
                        <span className={`${monthlyTeamsUsers !== 2 && "cursor-pointer"}`} onClick={() => handleIncreaseMonthlyTeamsUsers(false)}>
                          {monthlyTeamsUsers === 2 ?
                            <DownArrow width={17} color={"#DFDFDF"} />
                              :
                            <DownArrow width={17} />
                          }
                        </span>
                      </div>
                    </div>
                    <div className="md:hidden border border-[#A1A2A980] rounded-[10px] px-3 py-1 flex justify-between gap-4 items-center font-bold text-[20px]">
                      <span className={`${monthlyTeamsUsers !== 2 && "cursor-pointer"}`} onClick={() => handleIncreaseMonthlyTeamsUsers(false)}>
                          {monthlyTeamsUsers === 2 ?
                            <LeftArrow width={14} color={"#DFDFDF"} />
                              :
                            <LeftArrow width={14} />
                          }
                      </span>
                      {monthlyTeamsUsers}
                      <span className="cursor-pointer" onClick={() => handleIncreaseMonthlyTeamsUsers(true)}>
                        <RightArrow width={14} />
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 ">
                    <span className="text-[40px] font-black leading-[60px]">
                      ${29}
                      {/* ${29 * monthlyTeamsUsers} */}
                    </span>
                    <div className="flex flex-col items-start">
                      <span className="text-lg font-bold mt-1">USD</span>
                      <p className="text-base font-normal">
                        per user / {type}
                      </p>
                    </div>
                  </div>
                  <Button
                    isLoading={isLoading === idTeams}
                    // disabled={isLoading === id}
                    onClick={() => handleClickTeam("month")}
                    className={`bg-secondry_color h-11 text-white leading-[24px] w-full text-center font-poppins  text-[18px] font-bold !py-[10px] px-5 rounded-[10px] mt-8`}
                  >
                    <span className="h-full">Upgrade To Teams</span>
                  </Button>
                  {monthlyTeamCard &&
                    <>
                      <p className="text-base font-bold text-start mt-6">
                        Includes
                      </p>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited activities </b>(comment, post, message, and
                          more)
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited access</b> to your history and saved items
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          Full access to <b> GPT 4 </b>
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <a
                          href="https://evyai.com/upgrade/"
                          target="_blank"
                          className={`text-[#6D40C4] text-[16px] font-bold leading-7 pl-3 text-start underline`}
                        >
                          <b> And more </b>
                        </a>
                      </div>
                    </>
                    }
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="hidden w-[50%]">
                <label
                  className="xsm:hidden flex flex-col px-4 py-2 bg-white rounded-[10px] cursor-pointer border-secondry_color border-2"
                  htmlFor="radio_1"
                  // onClick={handleClick}
                >
                  {isLoading == id ? (
                    <ThreeDots height={66} color="#6E40C5" />
                  ) : (
                    <>
                      <span className="font-poppins  text-[10px] font-bold leading-normal text-text_color">
                        Annual
                      </span>
                      <span className="font-poppins  text-[20px] font-extrabold leading-normal text-text_color text-start">
                        ${getPrice()}
                      </span>
                      <span className="font-poppins  text-[8px] font-bold leading-normal text-[#35D1A4] -mt-1 mb-2">
                        (Beta Price $100)
                      </span>
                    </>
                  )}
                </label>
              </div>
              <div
                className={`xsm:w-full bg-[#34317D] border-[#6D40C4] text-white rounded-3xl border-2 py-8 px-6 flex flex-col justify-between md:max-w-[335px] lg:min-h-[500px] font-poppins relative`}
                >
                <div>
                  <Button
                    className={`!bg-[#6D40C4] text-white px-4 py-1 tracking-wider rounded-full font-poppins font-bold text-[16px] absolute inset-x-0 mx-auto w-max -top-3`}
                  >
                    MOST POPULAR
                  </Button>
                  <h3
                    className={`flex justify-between items-center text-[22px] font-bold leading-8  text-start text-[#FFD572]`}
                  >
                    Premium
                    <div className="md:hidden" onClick={() => {setAnnuallyCard(!annuallyCard)}}>
                      {!annuallyCard ? <DownArrow color={"white"} /> : <UpArrow color={"white"} />}
                    </div>
                  </h3>
                  <div className="flex items-center gap-2 font-poppins ">
                    <span className="text-[40px] font-black leading-[60px]">
                      ${getPrice() / 12}
                    </span>
                    <div className="flex flex-col items-start">
                      <span className="text-lg font-bold mt-1">USD</span>
                      <p className="text-base font-normal">
                         / month
                      </p>
                    </div>
                  </div>
                  <div className=" w-full flex justify-start mt-1 items-center gap-2 font-semibold text-[14px]">
                    Billed Annually
                    <span className="border-[2px] border-[#FFD572] rounded-lg py-[2px] px-2">
                      Save 50%
                    </span>
                  </div>

                  <Button
                    disabled={isLoading === id}
                    isLoading={isLoading === id}
                    onClick={() => handleClick()}
                    className={`bg-white h-11 text-secondry_color leading-[24px] w-full text-center font-poppins  text-[18px] font-bold !py-[10px] px-5 rounded-[10px] mt-10`}
                  >
                    <span className="mx-2 h-full">Upgrade To Premium</span>
                  </Button>

                  {annuallyCard &&
                  <>
                    <p className="text-base font-bold text-start mt-6">
                      Includes
                    </p>
                    <div className="flex items-start pt-2">
                      <img
                        src={CheckIcon}
                        alt="Check"
                      />
                      <p className="text-[18px] leading-7 pl-3 text-start">
                        <b> Unlimited activities</b> (comment, post, message, and
                        more)
                      </p>
                    </div>
                    <div className="flex items-start pt-2">
                      <img
                        src={CheckIcon}
                        alt="Check"
                      />
                      <p className="text-[18px] leading-7 pl-3 text-start">
                        <b>Unlimited access</b> to your history and saved items
                      </p>
                    </div>
                    <div className="flex items-start pt-2">
                      <img
                        src={CheckIcon}
                        alt="Check"
                      />
                      <p className="text-[18px] leading-7 pl-3 text-start">
                        Full access to <b> GPT 4 </b>
                      </p>
                    </div>
                    <div className="flex items-start pt-2">
                      <img
                        src={CheckIcon}
                        alt="Check"
                      />
                      <a
                        href="https://evyai.com/upgrade/"
                        target="_blank"
                        className={`text-[#FFD572] text-[16px] font-bold leading-7 pl-3 text-start underline`}
                      >
                        <b> And more </b>
                      </a>
                    </div>
                  </>
                  }
                </div>
              </div>
              <div className="hidden w-[50%]">
                <label
                  className="xsm:hidden flex flex-col px-4 py-2 bg-white rounded-[10px] cursor-pointer"
                  htmlFor="radio_2"
                  // onClick={handleClickTeam}
                >
                  {isLoading == id ? (
                    <ThreeDots height={66} color="#6E40C5" />
                  ) : (
                    <>
                      <span className="font-poppins text-[10px] font-bold leading-normal text-text_color">
                        Teams
                      </span>
                      <span className="font-poppins text-[20px] font-extrabold leading-normal text-text_color mt-1 mb-4">
                        ${14 * yearlyTeamsUsers}
                      </span>
                    </>
                  )}
                </label>
              </div>

              <div
                className={`xsm:w-full  bg-transparent text-[#444553] rounded-3xl border-2   py-8 px-6 flex flex-col justify-between md:max-w-[335px] lg:min-h-[500px] font-poppins`}
              >
                <div>
                  <div className="md:hidden w-full flex justify-end mb-3" onClick={() => {setAnnuallyTeamCard(!annuallyTeamCard)}}>
                    {!annuallyTeamCard ? <DownArrow/> : <UpArrow/>}
                    {/* {(!monthlyCard && isEnableTrail) ? <DownArrow color={"white"} /> : <UpArrow color={"white"} />} */}
                  </div>
                  <div className="flex justify-between items-center">
                    <h3
                      className={`flex justify-between items-center text-[22px] font-bold leading-8  text-start text-secondry_color`}
                    >
                      Teams
                    </h3>
                    <div className="xsm:hidden border border-[#A1A2A980] rounded-[10px]  px-4 flex justify-between gap-4 items-center font-bold text-[20px]">
                      {yearlyTeamsUsers}
                      <div className="flex flex-col justify-between items-center">
                        <span className="cursor-pointer" onClick={() => handleIncreaseYearlyTeamsUsers(true)}>
                          <UpArrow width={17} />
                        </span>
                        <span className={`${yearlyTeamsUsers !== 2 && "cursor-pointer"}`} onClick={() => handleIncreaseYearlyTeamsUsers(false)}>
                          {yearlyTeamsUsers === 2 ?
                            <DownArrow width={17} color={"#DFDFDF"} />
                              :
                            <DownArrow width={17} />
                          }
                        </span>
                      </div>
                    </div>
                    <div className="md:hidden border border-[#A1A2A980] rounded-[10px] px-3 py-1 flex justify-between gap-4 items-center font-bold text-[20px]">
                      <span className={`${yearlyTeamsUsers !== 2 && "cursor-pointer"}`} onClick={() => handleIncreaseYearlyTeamsUsers(false)}>
                          {yearlyTeamsUsers === 2 ?
                            <LeftArrow width={14} color={"#DFDFDF"} />
                              :
                            <LeftArrow width={14} />
                          }
                      </span>
                      {yearlyTeamsUsers}
                      <span className="cursor-pointer" onClick={() => handleIncreaseYearlyTeamsUsers(true)}>
                        <RightArrow width={14} />
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 ">
                    <span className="text-[40px] font-black leading-[60px]">
                      {/* ${14 * yearlyTeamsUsers} */}
                      ${15}
                    </span>
                    <div className="flex flex-col items-start">
                      <span className="text-lg font-bold mt-1">USD</span>
                      <p className="text-base font-normal">
                        per user / month
                      </p>
                    </div>
                  </div>
                  <div className=" w-full flex justify-start mt-1 items-center gap-2 font-semibold text-[14px]">
                    Billed Annually
                    <span className="border-[2px] border-[#FFD572] rounded-lg py-[2px] px-2">
                      Save 50%
                    </span>
                  </div>
                  <Button
                    isLoading={isLoading === idTeams}
                    // disabled={isLoading === id}
                    onClick={() => handleClickTeam("year")}
                    className={`bg-secondry_color h-11 text-white leading-[24px] w-full text-center font-poppins  text-[18px] font-bold !py-[10px] px-5 rounded-[10px] mt-8`}
                  >
                    <span className="h-full">Upgrade To Teams</span>
                  </Button>
                  {annuallyTeamCard &&
                    <>
                      <p className="text-base font-bold text-start mt-6">
                        Includes
                      </p>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited activities </b>(comment, post, message, and
                          more)
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          <b>Unlimited access</b> to your history and saved items
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <p className="text-[18px] leading-7 pl-3 text-start">
                          Full access to <b> GPT 4 </b>
                        </p>
                      </div>
                      <div className="flex items-start pt-2">
                        <img
                          src={CheckIcon}
                          alt="Check"
                        />
                        <a
                          href="https://evyai.com/upgrade/"
                          target="_blank"
                          className={`text-[#6D40C4] text-[16px] font-bold leading-7 pl-3 text-start underline`}
                        >
                          <b> And more </b>
                        </a>
                      </div>
                    </>
                    }
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="xsm:w-full bg-white rounded-3xl border-2 border-[#DFDFDF] font-poppins text-gray_light py-8 px-6 md:flex flex-col justify-between md:max-w-[335px] lg:min-h-[500px]">
          <div>
            <h3 className="flex justify-between items-center text-[22px] font-bold font-poppins text-secondry_color leading-8 text-start">
              Basic
              <div className="md:hidden" onClick={() => {setBasicCard(!basicCard)}}>
                {!basicCard ? <DownArrow/> : <UpArrow/>}
              </div>
            </h3>
            <h1 className="text-[40px] flex font-black leading-[60px]">Free</h1>
            <a href={Navigate_to.extensionPage} target="_blank">
              <Button
                disabled={isLoading === id}
                isLoading={isLoading === id}
                className={`leading-[24px] h-11 !bg-[#C0C0C0] w-full text-white font-poppins text-[18px] font-bold px-6 rounded-[10px] py-[10px] flex !justify-center items-center ${interval === "year" && "mt-[72px]"} ${interval === "month" && "mt-10"}`}
              >
                <span className="mx-3 h-full">Current Plan</span>
              </Button>
            </a>
            {basicCard && 
              <>
                <p className="text-base font-bold text-start mt-6">Includes</p>
                <div className="flex items-start mt-2">
                  <img src={CheckIcon} alt="Check" />
                  <p className="ext-[18px] leading-7 pl-3 text-start">
                    <b> 20 activities </b> (comment, post, message, and more)
                  </p>
                </div>
                <div className="flex items-start pt-2">
                  <img src={CheckIcon} alt="Check" />
                  <p className="text-[18px] leading-7 pl-3 text-start">
                    Access your history and saved items <b> for a limited-time </b>
                  </p>
                </div>
                <div className="flex items-start pt-2">
                  <img src={CheckIcon} alt="Check" />
                  <p className="text-[18px] leading-7 pl-3 text-start">
                    Full access to <b> GPT 4o </b>
                  </p>
                </div>
                <div className="flex items-start pt-2">
                  <img src={CheckIcon} alt="Check" />
                  <a
                    href="https://evyai.com/upgrade/"
                    target="_blank"
                    className="text-[18px] leading-7 pl-3 text-[#6D40C4] text-start underline"
                  >
                    <b> And more </b>
                  </a>
                </div>
              </>
              }
          </div>
        </div>
      )}
    </>
  );
}

export default SubscriptionPricingCards;
