import React, { useContext, useEffect, useRef, useState } from 'react'
import DownArrow from '../../../assets/svgs/DownArrow'
import EditIcon from '../../../assets/Images/Edit.svg'
import Check from "../../../assets/Images/primaryCheck.svg";
import Uncheck from "../../../assets/Images/primaryUncheck.svg";
import UserDummyPicture from "../../../assets/Images/ProfilePicUpload.svg";
import useScreenSize from '../../../hooks/useScreenSize';
import Pagination from '../../../components/Pagination';
import UpArrow from '../../../assets/svgs/UpArrow';
import DeleteIcon from '../../../assets/svgs/DeleteIcon';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import InviteUserDialogue from './InviteUserDialogue';
import SearchImg from "../../../assets/Images/listSearch.svg"
import SeatLimitReachedDialogue from './SeatLimitReachedDialogue';
import { IUser } from '../../../context/Auth/auth.types';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import { COLLECTIONS, SUBSCRIPTIONS_NAMES, SubscriptionStatusDB } from '../../../config/firebase';
import { dateFormater, openInSameTab } from '../../../utils';
import { firebaseDb } from '../../../context/Auth';
import { collection, onSnapshot } from 'firebase/firestore';
// import TeamSizeUpdatedDialogue from './TeamSizeUpdatedDialogue';
import Dots from "../../../assets/Images/Dots.svg";
import GoToStripeLinkDialogue from '../../../components/GoToStripeLinkDialogue';
import TeamSizeUpdatedDialogue from './TeamSizeUpdatedDialogue';
import SomethingWentWrongDialogue from '../../../components/SomethingWentWrongDialogue';
import Loader from '../../../components/Loader';
import { TeamsDbService } from '../services/TeamsDbService';
// import { SubScriptionStaus_Type } from '../../../context/Auth/types';
// import SortArrow from "../../../assets/Images/Sort.svg"

// const monthlyTeamsPriceId = "price_1PMntuKLRkrcwJ5STo8EpaH0";
const yearlyTeamsPriceId = "price_1PQpT5KLRkrcwJ5SIVkFtfTQ";
interface AdminTeamsTableProps {
    setTeamToEdit: React.Dispatch<React.SetStateAction<any | null>>;
    setShowEditAlert: React.Dispatch<React.SetStateAction<boolean>>;
    team: any[];
    setAdminTeam: React.Dispatch<React.SetStateAction<any[]>>;
    navigateTo: Function;
    isLoadingMembers: boolean;
}

export default function AdminTeamsTable(props: AdminTeamsTableProps) {

    const { setShowEditAlert, setTeamToEdit, team, setAdminTeam, navigateTo, isLoadingMembers } = props;

    const { subscriptionStatus, handleUpdateTeamSize, fetchUserDataByEmail, deleteUserFromTeam, goToSubscriptionPlanPaymentURL, getCustomerSubscriptionPortalLink } = useContext(AuthContext) as AuthContext_type;

    const [currentPage, setCurrentPage] = useState(1);
    const [membersPerPage, setMembersPerPage] = useState(10);
    const [teamExp, setTeamExp] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showInviteDialogue, setShowInviteDialogue] = useState(false)
    const [showLimitReachedDialogue, setShowLimitReachedDialogue] = useState(false)
    // const [user, setUser] = useState<IUser | null>(null)
    const [totalSeats, setTotalSeats] = useState<number>(1)
    const [membersToShow, setMembersToShow] = useState<any[]>([])
    const [memberStatuses, setMemberStatuses] = useState<any[]>([])
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [selectedMembersIds, setSelectedMembersIds] = useState<string[]>([])
    const [userToDel, setUserToDel] = useState<string>("")
    const [loading, setLoading] = useState(false)
    const [dropDown, setDropDown] = useState<any>(null)
    const [showStripeSubscribedLinkDialogue, setShowSubscribedStripeLinkDialogue] = useState(false)
    const [customerSubPortalURL, setCustomerSubPortalURL] = useState<string>()
    const [showSomethingWWD, setshowSomethingWWD] = useState(false);
    const [showDeleteUsersToUpdateDialogue, setShowDeleteUsersToUpdateDialogue] = useState(false);
    const [usersToDeleteToUpdate, setusersToDeleteToUpdate] = useState(0);
    const [willShowDialogue, setWillShowDialogue] = useState(false);
    const [totalMembers, setTotalMembers] = useState(0);
    const [isInviteUserError, setIsInviteUserError] = useState(false);
    const [inviteUserError, setInviteUserError] = useState("");
    const [willClearField, setWillClearField] = useState(false);

    const dropDownRef = useRef<HTMLDivElement | null>(null);

    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    let currentMembers = membersToShow.slice(indexOfFirstMember, indexOfLastMember);

    const screen = useScreenSize()

    // const currentUser = getCurrentUser();

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleSetItemsPerPage = (count: number) => {
        setMembersPerPage(count);
        setCurrentPage(1);
    };

    const searchMembers = (value: string) => {
        const regex = new RegExp(value, 'i');
        const filteredMembers = team[0].members.filter((memberDoc: any) => {
            // const member: IUser = memberDoc.memberData;
            // const filteredMembers = team[0].membersIds.filter((member: IUser) => {
            let userName: string = memberDoc.name;
            // if (member.last_name) {
            //     userName = member.first_name + " " + member.last_name;
            // } else {
            //     userName = member.first_name;
            // }
            return regex.test(userName);
        });
        // console.log("filtered members:", filteredMembers);

        setMembersToShow(filteredMembers)
    }

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newSelectedMembersIds = selectedMembersIds.filter(id => !currentMembers.map((member: IUser) => member.uid || member.userId).includes(id));
            setSelectedMembersIds(newSelectedMembersIds);
        } else {
            const newSelectedMembersIds = [...selectedMembersIds, ...currentMembers.map((member: IUser) => member.uid || member.userId)];
            setSelectedMembersIds(newSelectedMembersIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    const handleMemberCheckboxClick = (memberId: string) => {
        if (selectedMembersIds.includes(memberId)) {
            const newSelectedMembersIds = selectedMembersIds.filter(id => id !== memberId);
            setSelectedMembersIds(newSelectedMembersIds);
        } else {
            const newSelectedMembersIds = [...selectedMembersIds, memberId];
            setSelectedMembersIds(newSelectedMembersIds);
        }
    };

    const handleDeleteUser = async () => {
        setLoading(true)
        if (userToDel) {
            const user = team[0].members.find((member: any) => member.id === userToDel)
            const userIdObtained = user.userId;
            await deleteUserFromTeam(userIdObtained, team[0].id)
            // const newMembersIds = team[0].membersIds.filter((item: IUser) => {
            //     if (item.userId) {
            //         return item.userId!== userIdObtained
            //     } else if (item.uid) {
            //         return item.uid!== userIdObtained
            //     }
            // })
            const newActiveMembersIds = team[0].activeMembersIds.filter((item: any) => {
                return item.uid || item.userId !== userIdObtained;
            })
            const newMembers = team[0].members.filter((member: any) => member.id !== userToDel)
            const newMembersEmails = team[0].membersEmails.filter((email: string) => email !== user.email)
            const newTeams = team.map((item, index) => {
                if (index === 0) {
                    const newTeam = {
                        ...item,
                        // membersIds: newMembersIds,
                        activeMembersIds: newActiveMembersIds,
                        members: newMembers,
                        membersEmails: newMembersEmails,
                    }
                    return newTeam
                }
            })
            setAdminTeam(newTeams)
            // toggleDataChanged();
            setLoading(false)
            setShowConfirmation(false);
            return
        }
    };

    // const handleFetchCurrentUser = async () => {
    //     if (currentUser) {
    //         const user = await fetchUserDataById(currentUser.uid);
    //         setUser(user);
    //     }
    // };

    const handleShowInviteOrSeatLimit = async () => {
        // if (subscriptionStatus && (!subscriptionStatus.allowedTeamMembers || subscriptionStatus.allowedTeamMembers === 0)) {
        //     setShowLimitReachedDialogue(true);
        //     setShowInviteDialogue(false);
        if (subscriptionStatus && subscriptionStatus.allowedTeamMembers !== undefined) {
            if (subscriptionStatus.userPersonalStatusDB === SubscriptionStatusDB.CANCELLED) {
                setTotalSeats(0);
                if (team[0].members.length >= 0) {
                    setShowLimitReachedDialogue(true);
                    setShowInviteDialogue(false);
                } else {
                    setShowInviteDialogue(true);
                    setShowLimitReachedDialogue(false);
                }
            } else {
                setTotalSeats(subscriptionStatus.allowedTeamMembers);
                if (team[0].members.length >= subscriptionStatus.allowedTeamMembers) {
                    setShowLimitReachedDialogue(true);
                    setShowInviteDialogue(false);
                } else {
                    setShowInviteDialogue(true);
                    setShowLimitReachedDialogue(false);
                }
            }
        }
        // if (userToRecieve && userToRecieve.total_team_seats) {
        //     setTotalSeats(userToRecieve.total_team_seats);
        //     if (team[0].members.length + 1 >= userToRecieve.total_team_seats) {
        //         setShowLimitReachedDialogue(true);
        //         setShowInviteDialogue(false);
        //     } else {
        //         setShowInviteDialogue(true);
        //         setShowLimitReachedDialogue(false);
        //     }
        // } else if (!userToRecieve?.total_team_seats) {
        //     await updateTeamSeats(1)
        //     setTotalSeats(1);
        //     if (team[0].members.length + 1 >= 1) {
        //         setShowLimitReachedDialogue(true);
        //     } else {
        //         setShowInviteDialogue(true);
        //     }
        // }
    }

    const handleInviteUsers = async (emails: string[]) => {
        setLoading(true);
        const adminId: string = team[0].adminId.uid || team[0].adminId.userId;
        const response = await TeamsDbService.getInstance().addMembersToTeam(team[0].id, emails, adminId)
        if (response.success && response.status === 200) {
            setIsInviteUserError(false);
            setInviteUserError("");
            setLoading(false);
            setWillClearField(true);
            setShowInviteDialogue(false);
        } else {
            setWillClearField(false);
            setIsInviteUserError(true);
            setInviteUserError(response.message);
            setLoading(false);
            setShowInviteDialogue(false);
            setIsInviteUserError(true);
            setInviteUserError(response.message);
        }
        // await addMembersToTeam(team[0].id, emails);
    }

    // const handleModifySubscription = async () => {
    //     const res = await getCustomerSubscriptionPortalLink()
    //     if (!(res.error) && setCustomerSubPortalURL && setShowSubscribedStripeLinkDialogue) {
    //         setCustomerSubPortalURL(res.url.url as string);
    //         setShowSubscribedStripeLinkDialogue(true)
    //         openInSameTab(res.url as string);
    //     }
    // }

    const handleSeatLimitReached = async (seats: number) => {
        if (subscriptionStatus) {
            setLoading(true);
            if (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.FREE || subscriptionStatus.userPersonalStatusDB === SubscriptionStatusDB.CANCELLED) {
                const checkOutLink = await goToSubscriptionPlanPaymentURL(yearlyTeamsPriceId, seats)
                setCustomerSubPortalURL(checkOutLink as string);
                setShowSubscribedStripeLinkDialogue(true)
                openInSameTab(checkOutLink as string);
                // console.log("SEATS", seats);
                // console.log("CHECKOUT LINK", checkOutLink);
            } else if (subscriptionStatus && (subscriptionStatus.userPersonalSubscription === SUBSCRIPTIONS_NAMES.PREMIUM && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED)) {
                const res = await getCustomerSubscriptionPortalLink()
                const checkOutLink: string = (res.url as any).url;
                setCustomerSubPortalURL(checkOutLink as string);
                setShowSubscribedStripeLinkDialogue(true)
                openInSameTab(checkOutLink as string);
            } else if (subscriptionStatus && (subscriptionStatus.hasTeamsPlan === true && subscriptionStatus.userPersonalStatusDB !== SubscriptionStatusDB.CANCELLED)) {
                setLoading(true);
                if (subscriptionStatus && (subscriptionStatus.allowedTeamMembers && subscriptionStatus.subscriptionData)) {
                    const existingMembersQuantity = team[0].members.length + 1;
                    const subscriptionId = subscriptionStatus.subscriptionData.items[0].subscription;
                    const planId = subscriptionStatus.subscriptionData.items[0].plan.id;
                    const quantity = seats;
                    const subItemId = subscriptionStatus.subscriptionData.items[0].id;
                    if (quantity < existingMembersQuantity) {
                        const usersExceeding = existingMembersQuantity - quantity;
                        setusersToDeleteToUpdate(usersExceeding);
                        setShowDeleteUsersToUpdateDialogue(true);
                        setLoading(false);
                        setShowLimitReachedDialogue(false);
                        return;
                    } else {
                        const res = await handleUpdateTeamSize(subscriptionId, planId, quantity, subItemId);
                        if (!res.success && res.error) {
                            setshowSomethingWWD(true);
                            setLoading(false);
                            setShowLimitReachedDialogue(false)
                        } else if (res.success && !res.error) {
                            // await getSubscriptionStatus();
                            setshowSomethingWWD(false);
                            setWillShowDialogue(true);
                            setLoading(false);
                            setShowLimitReachedDialogue(false)
                        }
                    }
                }
            }
            setLoading(false);
            setShowLimitReachedDialogue(false);
        }
        // else {
        // const res = await getCustomerSubscriptionPortalLink()
        // console.log("RES", res);
        // if (!(res.error)) {
        //     setCustomerSubPortalURL(res.url.url as string);
        //     setShowSubscribedStripeLinkDialogue(true)
        //     openInSameTab(res.url.url as string);
        //     // console.log(res.url.url);
        //     }
        // }
        // console.log(res.url.url);
        // const res = await updateTeamSeats(seats);
        // if (user && res) {
        //     await handleShowInviteOrSeatLimit();
        // }
        // setLoading(false);
        // setShowLimitReachedDialogue(false);
    }

    const handleEditTeam = (team: any) => {
        setTeamToEdit(team);
        setShowEditAlert(true);
    }

    useEffect(() => {
        if (subscriptionStatus && subscriptionStatus.allowedTeamMembers !== undefined) {
            if (subscriptionStatus.userPersonalStatusDB === SubscriptionStatusDB.CANCELLED) {
                setTotalSeats(0)
            } else {
                // console.log(subscriptionStatus.allowedTeamMembers);
                setTotalSeats(subscriptionStatus.allowedTeamMembers)
            }
        }
    }, [subscriptionStatus, showLimitReachedDialogue])

    // useEffect(() => {
    //     console.log("Total seats available", totalSeats);
    // }, [totalSeats])


    // const fetchStatuses = async () => {
    //     const statuses = await fetchInviteStatus(team[0].id)
    //     // console.log("statuses", statuses);
    //     // console.log("team", team[0].members);
    //     setMemberStatuses(statuses)
    // }

    useEffect(() => {
        currentMembers = membersToShow.slice(indexOfFirstMember, indexOfLastMember);
    }, [membersToShow])


    useEffect(() => {
        // console.log("teammmmmmmm", team[0]);

        // let members: IUser[] = [];
        // team[0].membersIds.map(async (memberId: string) => {
        //     const memberData = await fetchUserDataById(memberId);
        //     members.push(memberData)
        //     return
        // })
        // setMembersData(members)
        // // const fetchStatuses = async () => {
        // //     const statuses = await fetchInviteStatus(team[0].id)
        // // }
        // fetchStatuses()
        if (team[0].members !== undefined) {
            setTotalMembers(team[0].members.length)
            setMemberStatuses(team[0].members)
            // setMembersToShow(team[0].membersIds);
            // console.log("TEAM MEMBERSSSS", team[0].members);
            setMembersToShow(team[0].members)
        }

    }, [team])

    useEffect(() => {
        if (team.length > 0 && !isLoadingMembers) {
            const membersCollectionRef = collection(firebaseDb, `${COLLECTIONS.TEAMS}/${team[0].id}/${COLLECTIONS.MEMBERS}`);
            const unsubscribe = onSnapshot(membersCollectionRef, async (querySnapshot) => {
                for (const change of querySnapshot.docChanges()) {
                    let memberDocData = change.doc.data();
                    const memberDataId = change.doc.id;
                    const changedDoc = { ...memberDocData, id: memberDataId }
                    let memberDataUser: any = null;
                    // const memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                    // const memberDataUserId = memberDataUser.uid || memberDataUser.userId;
                    if (change.type === 'added') {
                        // console.log('New member:', memberDocData);
                        if (memberDocData.name === "undefined undefined") {
                            memberDocData = {...memberDocData, name: ""}
                        }
                        let newMemberDocData = { ...memberDocData, id: memberDataId }
                        
                        const isAlreadyThereInMembers = (team[0].members as any[]).some((member) => member.email === memberDocData.email);

                        // console.log("IS ALREADY THERE IN MEMBERS:", isAlreadyThereInMembers);


                        if (!isAlreadyThereInMembers) {
                            if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                                memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                                if (memberDataUser) {
                                    const memberUserId = memberDataUser.userId && memberDataUser.userId || memberDataUser.uid && memberDataUser.uid;
                                    const memberName = memberDataUser.first_name + (memberDataUser.last_name ? " " + memberDataUser.last_name : "")
                                    const photoUrl = memberDataUser.profile_picture_url && memberDocData.profile_picture_url || memberDataUser.photoURL && memberDataUser.photoURL;
                                    memberDocData = { ...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId }
                                }
                            }
                            let newTeam: any = [];
                            let newTeams: any[] = team[0];
                            let newMembers: any[] = [...team[0].members];
                            // let newActiveMembers: any[] = [...team[0].activeMembersIds];

                            if (!isAlreadyThereInMembers) {
                                newMemberDocData = { ...memberDocData, id: memberDataId }
                                newMembers = [...newMembers, newMemberDocData]
                            }
                            newTeam = {
                                ...team[0],
                                members: newMembers,
                                // activeMembersIds: newActiveMembers,
                            }
                            newTeams = team.map((adminTeam) => {
                                if (adminTeam.id === team[0].id) {
                                    return newTeam;
                                } else {
                                    return adminTeam;
                                }
                            })

                            // console.log("NEW TEAM", newTeams);


                            setAdminTeam(newTeams);
                        }


                    } else if (change.type === 'modified') {
                        // console.log('Modified member:', memberDocData);
                        const isAlreadyThere = memberStatuses.some((status) => status.email === memberDocData.email)
                        // const isAlreadyThereInTeam = team[0].membersIds.some((member: IUser) => member.email === memberDocData.email)
                        const isAlreadyThereInTeam = team[0].members.some((member: any) => member.email === memberDocData.email)
                        // const isAlreadyThereInTeam = team[0].membersIds.some((member: IUser) => (member.email === memberData.email) || member.uid === memberData.userId)
                        // console.log("IS ALREADY THERE IN TEAM MODIFIED", isAlreadyThereInTeam);

                        // const newStatuses = memberStatuses.map((status) => {
                        //     if (status.id === memberDataId) {
                        //     }
                        // })
                        const memberPreviousData = team[0].members.find((member: any) => member.userId === memberDocData.userId)
                        if ((memberPreviousData.name !== memberDocData.name) || (memberPreviousData.photoUrl !== memberDocData.photoUrl)) {
                            const newUpdatedMembers = team[0].members.map((member: any) => {
                                if (member.userId === memberDocData.userId) {
                                    return {...memberDocData, id: memberDataId}
                                } else {
                                    return member;
                                }
                            })
                            const newTeamsToSet = team.map((teamItem: any) => {
                                if (teamItem.id === team[0].id) {
                                    return {...teamItem, members: newUpdatedMembers}
                                } else {
                                    return teamItem;
                                }
                            });
                            setAdminTeam(newTeamsToSet);
                        }

                        if (memberDocData.status === "invite_sent") {
                            if (!isAlreadyThere) {
                                setMemberStatuses([...memberStatuses, changedDoc])
                            }
                            if (!isAlreadyThereInTeam) {
                                if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                                    memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                                    if (memberDataUser) {
                                        const memberUserId = memberDataUser.userId && memberDataUser.userId || memberDataUser.uid && memberDataUser.uid;
                                        const memberName = memberDataUser.first_name + (memberDataUser.last_name ? " " + memberDataUser.last_name : "")
                                        const photoUrl = memberDataUser.profile_picture_url && memberDocData.profile_picture_url || memberDataUser.photoURL && memberDataUser.photoURL;
                                        memberDocData = { ...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId }
                                    }
                                }
                                let newTeams: any[] = [];
                                // memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                                const newMember = { ...memberDocData, id: memberDataId };
                                // const newMemberId = await fetchUserDataByEmail(memberDocData.email);
                                // const newMember = await fetchUserDataById(memberData.email);
                                newTeams = team.map((item, index) => {
                                    if (index === 0) {
                                        // console.log("New Memberrrrrrr", newMember);
                                        const newTeam = {
                                            ...item,
                                            // membersIds: [...item.membersIds, newMemberId],
                                            members: [...item.members, newMember]
                                        }
                                        return newTeam;
                                    }
                                })
                                setAdminTeam(newTeams);
                            }
                        }
                        if (memberDocData.status === 'joined') {
                            memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                            if (memberDocData.name === undefined && memberDocData.photoUrl === undefined) {
                                if (memberDataUser) {
                                    const memberUserId = memberDataUser.userId && memberDataUser.userId || memberDataUser.uid && memberDataUser.uid;
                                    const memberName = memberDataUser.first_name + (memberDataUser.last_name ? " " + memberDataUser.last_name : "")
                                    const photoUrl = memberDataUser.profile_picture_url && memberDocData.profile_picture_url || memberDataUser.photoURL && memberDataUser.photoURL;
                                    memberDocData = { ...memberDocData, name: memberName, photoUrl: photoUrl, userId: memberUserId }
                                }
                            }
                            let newTeams: any[] = [];
                            let newStatuses: any[] = [];
                            // memberDataUser = await fetchUserDataByEmail(memberDocData.email);
                            const newMember = { ...memberDocData, id: memberDataId };
                            const newMembers = team[0].members.map((member: any) => {
                                if (member.id === memberDataId) {
                                    return newMember;
                                } else {
                                    return member;
                                }
                            })
                            newStatuses = memberStatuses.map((item) => {
                                if (item.id === memberDataId) {
                                    const newStatus = {
                                        ...item,
                                        status: memberDocData.status,
                                        joinedDate: memberDocData.joinedDate,
                                    }
                                    return newStatus;
                                }
                                return item
                            })
                            newTeams = team.map((item, index) => {
                                if (index === 0) {
                                    const newTeam = {
                                        ...item,
                                        // membersIds: [...item.membersIds, newMember],
                                        activeMembersIds: [...item.activeMembersIds, memberDataUser.userId],
                                        members: newMembers
                                    }
                                    return newTeam;
                                }
                                return item
                            })
                            // console.log("NEW STATUSES", newStatuses);
                            // console.log("NEW TEAMS", newTeams);

                            setMemberStatuses(newStatuses)
                            setAdminTeam(newTeams)
                            //   toggleDataChanged();
                        }
                    } else if (change.type === 'removed') {
                        // console.log('Removed member:', memberDocData);
                        let newMembers = team[0].members.filter((member: any) => member.id !== memberDataId)
                        // let newMembersIds = team[0].membersIds.filter((member: any) => member.email !== memberDocData.email)
                        let newActiveMembersIds = team[0].activeMembersIds.filter((member: any) => member.email !== memberDocData.email)
                        let newMembersEmails = team[0].membersEmails.filter((member: string) => member !== memberDocData.email)
                        let newTeams = team.map((item, index) => {
                            if (index === 0) {
                                const newTeam = {
                                    ...item,
                                    members: newMembers,
                                    membersEmails: newMembersEmails,
                                    // membersIds: newMembersIds,
                                    activeMembersIds: newActiveMembersIds,
                                }
                                return newTeam;
                            }
                            return item;
                        })
                        // console.log("NEW TEAM AFTER REMOVING", newTeams);
                        // console.log("NEW MEMBERS AFTER REMOVING", newMembers);

                        setMembersToShow(newMembers)
                        setAdminTeam(newTeams)
                    }
                };
            });
            return () => {
                unsubscribe();
            };
        }
    }, [team]);

    const handleClickOutside = (event: MouseEvent) => {
        const targetNode = event.target as Node;

        if (dropDownRef.current && !dropDownRef.current.contains(targetNode)) {
            setDropDown(false);
        }

    };

    useEffect(() => {
        if (dropDown !== null) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDown]);


    return (
        <div className='flex flex-col justify-start items-center gap-8'>
            <div className='w-full bg-[#F5F1FB] xsm:py-3 xsm:px-4 p-6 rounded-2xl font-poppins flex flex-col xsm:gap-4 gap-6 '>
                <div className='w-full flex justify-between items-center'>
                    <p className=' font-semibold xsm:text-[14px] text-[20px] text-[#444553] flex justify-start items-center gap-2'>
                        {team[0].name}
                        <img
                            onClick={() => handleEditTeam(team[0])}
                            className='cursor-pointer xsm:w-[14px]'
                            src={EditIcon}
                        />
                    </p>
                    <span
                        onClick={() => setTeamExp(!teamExp)}
                        className='cursor-pointer'
                    >
                        {teamExp ? <UpArrow width={screen === "small" ? 17 : 20} /> : <DownArrow width={screen === "small" ? 17 : 20} />}
                        {/* <DownArrow width={20} /> */}
                    </span>
                </div>
                {teamExp &&
                    <div className='flex flex-col justify-between items-center gap-6'>
                        <div className='flex justify-between items-center w-full xsm:gap-2 '>
                            <div className=" relative flex xsm:w-[60%] w-[40%] items-center bg-white border border-border_color xsm:px-2 xsm:py-[6px] py-3 px-2 rounded-[8px]">
                                <img
                                    src={SearchImg}
                                    alt="Search"
                                    className="absolute left-3 w-4 xsm:w-[14px]"
                                // style={{ height: "16px", width: "16px" }}
                                />
                                <input
                                    className="text-black_color font-poppins leading-6 xsm:-ml-1 ml-1 pl-[calc(16px+12px)] outline-none bg-transparent"
                                    onChange={(e) => searchMembers(e.target.value)}
                                    style={{ fontSize: "14px", width: "100%" }}
                                    placeholder="Search Members"
                                />
                            </div>
                            {!(selectedMembersIds.length > 0) ?
                                <button
                                    onClick={() => handleShowInviteOrSeatLimit()}
                                    className='font-bold text-[16px] xsm:text-[14px] text-[#34317D] xsm:w-[40%] py-3 xsm:py-[6px] xsm:px-0 px-6 border-[1.5px] border-[#34317D] rounded-[10px] '>
                                    Invite Member
                                </button>
                                :
                                <button
                                    // onClick={handleShowInviteOrSeatLimit} 
                                    className='font-bold text-[16px] xsm:text-[14px] text-[#34317D] xsm:w-[40%] py-3 xsm:py-[6px] xsm:px-0 px-6 border-[1.5px] border-[#34317D] rounded-[10px] '>
                                    Delete Members
                                </button>
                            }

                        </div>

                        <table className="w-full text-sm text-left bg-white rounded-xl">
                            <thead className="font-poppins text-base text-gray_dark w-8 border-b border-[#696A6F] flex-row">
                                <tr>
                                    <th scope="col" className="xsm:w-[12%] md:w-[10%] lg:w-[4%] px-3 py-3 xsm:p-0">
                                        <img
                                            className='xsm:ml-2'
                                            src={isAllChecked ? Check : Uncheck}
                                            width={25}
                                            height={25}
                                            onClick={handleHeaderCheckboxClick}
                                            alt="Checkbox"
                                        />
                                    </th>

                                    <th scope="col" className="py-3 w-1/2 xsm:w-[70%]">
                                        {/* <th scope="col" className="py-3" style={{ width: "50%" }}> */}
                                        <div className="flex justify-start items-center gap-2 xsm:font-medium xsm:text-[14px] xsm:text-[#696A6F]">
                                            Name
                                            {/* <img className="md:hidden" 
                                    // src={SortArrow} 
                                    alt="" /> */}
                                        </div>
                                    </th>
                                    {(screen !== "small" && screen !== "medium") &&
                                        <th scope="col" className="py-3" style={{ width: "50%" }}>
                                            Status
                                        </th>
                                    }
                                    <th scope="col" className="flex py-3 md:w-1/2">
                                        {/* <th scope="col" className="flex py-3" style={{ width: "5%" }}> */}
                                        <span className="xsm:font-medium xsm:text-[14px] md:mr-2 xsm:text-[#696A6F]">
                                            Actions
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPage === 1 &&
                                    <tr
                                        // key={persona.id} 
                                        // className={`xsm:h-[100px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === personas.length-1 ? "" : "border-b border-border_color"}`}
                                        className={`xsm:h-[92px] border-b border-border_color`}
                                    >
                                        <td className="px-3 xsm:p-0 xsm:flex xsm:justify-start xsm:items-center ">
                                            <img
                                                className='xsm:ml-2 xsm:mt-2 cursor-not-allowed'
                                                src={Uncheck}
                                                width={25}
                                                height={25}
                                            // onClick={() => handlePersonaCheckboxClick(persona.id)}
                                            />
                                        </td>
                                        <td className="py-2 xsm:py-0">
                                            <div className="flex profiles-center items-center xsm:items-start">
                                                <div className="max-w-[32px] w-[44px] h-[32px] rounded-lg overflow-hidden">
                                                    <img
                                                        className="w-full h-full object-cover"
                                                        // width={screen === "small" ? 38 : 50}
                                                        // height={screen === "small" ? 38 : 50}
                                                        src={
                                                            team[0].adminId.profile_picture_url ? team[0].adminId.profile_picture_url : team[0].adminId.photoURL ||
                                                                UserDummyPicture
                                                        }
                                                    />
                                                </div>
                                                <div className="xsm:ml-3 flex flex-col justify-center ml-4 items-start w-full">
                                                    <p
                                                        style={{
                                                            overflow: "hidden",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "1",
                                                            WebkitBoxOrient: 'vertical',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                        className="color-[#000] xsm:text-[#444553] xsm:text-[16px] font-poppins text-[14px] font-semibold md:mb-1"
                                                    >
                                                        {team[0].adminId.first_name + (team[0].adminId.last_name ? " " + team[0].adminId.last_name : "")}
                                                    </p>
                                                    <span className="color-[#000] xsm:text-[#444553] italic font-normal font-poppins text-[14px] xsm:text-[13px]">
                                                        {team[0].adminId.email}
                                                    </span>
                                                    <button className="md:hidden mt-1 px-1 items-center rounded-[30px] font-poppins font-semibold xsm:text-[12px] text-[16px] text-white bg-light_purple">
                                                        Admin
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        {(screen !== "small" && screen !== "medium") &&
                                            <td className="py-3">
                                                <button className=" py-1 px-2 items-center gap-8 rounded-[30px] text-white bg-light_purple">
                                                    {/* {persona?.Tag} */}
                                                    Admin
                                                </button>
                                            </td>
                                        }
                                        <td className="flex justify-end align-middle py-3">
                                            <img
                                                className="md:hidden relative mr-4 w-[25px]"
                                                // src={Dots} 
                                                alt=""
                                            // onClick={() => setDropDown((prev:any) => (prev === index ? null : index))}
                                            />
                                            {/* {(dropDown === index) &&
                                        <div ref={dropDownRef} className="absolute mt-6 mr-4 bg-white z-[1] w-[120px] dropDown-shadow p-1 flex flex-col ">
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                onClick={() => navigate(`/${PROTECTED_ROUTES.addPersona}/${persona.id}`)}
                                                >
                                                Edit
                                            </span>
                                            <span className="flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => 
                                                {setDropDown(null);
                                                initiateDelete(persona.id)}
                                                }>Delete</span>
                                        </div>
                                    } */}
                                            <div className="xsm:hidden sm:hidden md:flex flex justify-between items-center mr-3">
                                                {/* <button className='underline bg-transparent text-[#EB5757] text-[16px] font-bold text-underline px-4 py-3 rounded-lg flex items-center gap-2'
                                        // onClick={() => initiateDelete(persona.id)}
                                        onClick={() => setShowConfirmation(true)}
                                        >
                                            Delete
                                            <DeleteIcon width={15} color={"#EB5757"} />
                                        </button> */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {isLoadingMembers &&
                                    <tr
                                        className={`border-b border-border_color bg-white`}
                                    >
                                        <td colSpan={4} className='py-4'>
                                            <div className=' w-full flex justify-center items-center'>
                                                <Loader size={10} />
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {!isLoadingMembers && currentMembers.map((memberDoc: any, index) => {

                                    const member: { userId: string, photoUrl: string, name: string, email: string } = { userId: memberDoc.userId, photoUrl: memberDoc.photoUrl, name: memberDoc.name, email: memberDoc.email };
                                    // const memberId = member.uid || member.userId;
                                    const isChecked = selectedMembersIds.includes(member.userId);
                                    let memberStatus = memberDoc.status;
                                    const currentDate = new Date();

                                    return (
                                        <tr
                                            key={memberDoc.id}
                                            // className={`xsm:h-[100px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === personas.length-1 ? "" : "border-b border-border_color"}`}
                                            // className={`xsm:h-[100px] border-b border-border_color}`}
                                            className={`${index === 0 ? "" : "border-b border-border_color"} ${index === currentMembers.length - 1 ? "" : "border-b border-border_color"} ${isChecked ? "bg-chip_bg" : "bg-white"}`}
                                        >
                                            <td className="px-3 xsm:p-0 xsm:flex xsm:justify-start xsm:items-center ">
                                                <img
                                                    className='xsm:ml-2 xsm:mt-2'
                                                    src={isChecked ? Check : Uncheck}
                                                    width={25}
                                                    height={25}
                                                    onClick={() => handleMemberCheckboxClick(member.userId)}
                                                />
                                            </td>
                                            <td className="py-2 xsm:py-3">
                                                <div className="flex xsm:gap-3 profiles-center items-center xsm:items-start">
                                                    <div className="max-w-[32px] w-[44px] h-[32px] rounded-lg overflow-hidden">
                                                        <img
                                                            className="w-full h-full object-cover"
                                                            // width={screen === "small" ? 38 : 50}
                                                            // height={screen === "small" ? 38 : 50}
                                                            src={
                                                                member.photoUrl ||
                                                                UserDummyPicture
                                                            }
                                                        />
                                                    </div>
                                                    <div className={`${memberStatus === "invite_sent" && "opacity-50"} xsm:mt-0 -t-2 flex flex-col justify-center md:ml-4 items-start w-full`}>
                                                        <p
                                                            className="color-[#000] xsm:text-[#444553] xsm:text-[16px] font-poppins text-[14px] font-semibold md:mb-1"
                                                            // className="w-full" 
                                                            style={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "1",
                                                                WebkitBoxOrient: 'vertical',
                                                                textOverflow: 'ellipsis',
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            {member.name}
                                                        </p>
                                                        {/* <span
                                                            style={{
                                                                overflow: "hidden",
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "1",
                                                                WebkitBoxOrient: 'vertical',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                            className={`color-[#000] xsm:text-[#444553] font-poppins xsm:text-[16px] text-[20px] font-semibold md:mb-2`}
                                                        >
                                                            {member.name}
                                                        </span> */}
                                                        <span className="color-[#000] xsm:text-[#444553] xsm:text-[13px] italic font-normal font-poppins text-[14px]">
                                                            {member.email}
                                                        </span>
                                                        <span className="md:hidden text-[13px] mt-1 font-semibold text-[#696A6F]">
                                                            {/* {(memberStatus === "invite_sent" && `Invite sent ${dateFormater(memberDoc.inviteSentDate.seconds)}`) || 
                                                    (memberStatus === "joined" && `Joined ${dateFormater(memberDoc.joinedDate.seconds)}`)} */}
                                                            {(memberStatus === "invite_sent" && `Invite sent ${memberDoc.inviteSentDate !== null ? dateFormater(memberDoc.inviteSentDate.seconds) : dateFormater(currentDate.getTime() / 1000)}`) ||
                                                                (memberStatus === "joined" && `Joined ${dateFormater(memberDoc.joinedDate.seconds)}`)}
                                                        </span>
                                                        {/* <button className="md:hidden -ml-[0.2rem] py-2 px-3 items-center gap-8 rounded-[30px] font-poppins font-semibold text-[16px] text-white bg-light_purple">
                                                </button> */}
                                                    </div>
                                                </div>
                                            </td>
                                            {(screen !== "small" && screen !== "medium") &&
                                                <td className="py-3">
                                                    <span className="text-[14px] xsm:text-[10px] font-semibold text-[#696A6F]">
                                                        {/* {(memberStatus === "invite_sent" && `Invite sent ${dateFormater(memberDoc.inviteSentDate.seconds)}`) || 
                                            (memberStatus === "joined" && `Joined ${dateFormater(memberDoc.joinedDate.seconds)}`)} */}
                                                        {(memberStatus === "invite_sent" && `Invite sent ${memberDoc.inviteSentDate !== null ? dateFormater(memberDoc.inviteSentDate.seconds) : dateFormater(currentDate.getTime() / 1000)}`) ||
                                                            (memberStatus === "joined" && `Joined ${dateFormater(memberDoc.joinedDate.seconds)}`)}
                                                    </span>
                                                    {/* {memberStatuses.map(status => (member.uid ? status.userId === member.uid : status.userId === member.userId) &&
                                        <span className="text-[16px] font-semibold text-[#696A6F]">
                                            {(status.status === "invite_sent" && `Invite sent ${dateFormater(status.inviteSentDate.seconds)}`) || 
                                            (status.status === "joined" && `Joined ${dateFormater(status.joinedDate.seconds)}`)}
                                        </span>
                                        )} */}
                                                </td>
                                            }
                                            <td className="flex justify-end align-middle py-3 xsm:py-0">
                                                {memberStatus === "invite_sent" ?
                                                    <>
                                                        <img
                                                            className="relative mr-7 xsm:mr-3 w-[25px] cursor-pointer"
                                                            src={Dots}
                                                            alt=""
                                                            onClick={() =>
                                                                setDropDown((prev: any) => (prev === index ? null : index))}
                                                        />
                                                        {(dropDown === index) &&
                                                            <div ref={dropDownRef} className="absolute mt-6 mr-4 bg-white z-[1] w-[170px] dropDown-shadow p-1 flex flex-col ">
                                                                <button
                                                                    disabled={loading}
                                                                    className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] font-medium disabled:opacity-30"
                                                                    onClick={() => {
                                                                        setDropDown(null);
                                                                        handleInviteUsers([member.email])
                                                                    }}
                                                                >
                                                                    Resend Invite
                                                                </button>
                                                                <button
                                                                    className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium"
                                                                    // onClick={() => {
                                                                    //     // setDropDown(null);
                                                                    //     // initiateDelete(persona.id)
                                                                    // }}
                                                                    onClick={() => {
                                                                        setDropDown(null);
                                                                        setUserToDel(memberDoc.id)
                                                                        // setUserToDel(member.uid ? member.uid : member.userId)
                                                                        setShowConfirmation(true)
                                                                    }}
                                                                >
                                                                    Delete Invite
                                                                </button>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <div className="md:flex flex justify-between items-center xsm:mr-0 mr-3">
                                                        <button className='underline bg-transparent text-[#EB5757] text-[16px] font-bold text-underline px-4 py-3 rounded-lg flex items-center gap-2'
                                                            // onClick={() => initiateDelete(persona.id)}
                                                            onClick={() => {
                                                                setUserToDel(memberDoc.id)
                                                                // setUserToDel(member.uid ? member.uid : member.userId)
                                                                setShowConfirmation(true)
                                                            }}
                                                        >
                                                            <span className='xsm:hidden'>
                                                                Delete
                                                            </span>
                                                            <DeleteIcon width={screen === "small" ? 17 : 15} color={"#EB5757"} />
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                <Pagination
                                    colSpan={4}
                                    itemsPerPage={membersPerPage}
                                    // totalItems={team[0].members.length ? membersToShow.length : 1}
                                    totalItems={membersToShow.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                    setItemsPerPage={handleSetItemsPerPage}
                                />
                            </tbody>
                        </table>
                    </div>}
            </div>
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={handleDeleteUser}
                header={`Delete User?`}
                content={`Are you sure you want to delete this user? Deleting users cannot be undone. All saved history will be lost for this user.`}
                isLoading={loading}
            />
            <InviteUserDialogue
                setShow={setShowInviteDialogue}
                show={showInviteDialogue}
                onConfirm={handleInviteUsers}
                totalUsers={totalSeats && totalSeats - totalMembers}
                seatLimitReached={setShowLimitReachedDialogue}
                isLoading={loading}
                existingMembers={team[0].members}
                willClearField={willClearField}
            />
            <SeatLimitReachedDialogue
                setShow={setShowLimitReachedDialogue}
                show={showLimitReachedDialogue}
                onConfirm={handleSeatLimitReached}
                totalUsers={totalSeats + 1}
                isLoading={loading}
                navigateTo={navigateTo}
            />
            <GoToStripeLinkDialogue
                show={showStripeSubscribedLinkDialogue}
                setShow={setShowSubscribedStripeLinkDialogue}
                url={customerSubPortalURL}
            />
            <TeamSizeUpdatedDialogue
                setShow={setWillShowDialogue}
                show={willShowDialogue}
                onConfirm={() => navigateTo && navigateTo("3")}
            />
            <SomethingWentWrongDialogue
                show={showSomethingWWD}
                setShow={setshowSomethingWWD}
                content="Couldn't update users, please try again!"
            />
            <SomethingWentWrongDialogue
                show={showDeleteUsersToUpdateDialogue}
                setShow={setShowDeleteUsersToUpdateDialogue}
                header="Delete Users"
                content={`Please delete ${usersToDeleteToUpdate} users from your team in order to change your team size.`}
            />
            <SomethingWentWrongDialogue
                show={isInviteUserError}
                setShow={setIsInviteUserError}
                header="Invite User Failed"
                content={inviteUserError}
            />
            {/* <TeamSizeUpdatedDialogue
            setShow={setShowLimitReachedDialogue}
            show={showLimitReachedDialogue}
            onConfirm={handleSeatLimitReached}
        /> */}
        </div >
    )
}
