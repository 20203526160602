import React, { useEffect, useState, useRef, useContext } from 'react';
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";
import {
    collection,
    orderBy,
    query,
    onSnapshot,
    Query,
} from "firebase/firestore";
import { db } from '../../../services/firebase';
import { COLLECTIONS, FIELDS } from '../../../config/firebase';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import { sentryClient } from '@/Sentry/sentry';

export interface ActionButtonsProps {
    setActivities: any;
    activities?: String;
}

type OrderKey = "Last Modified" | "Oldest";
type OrderValue = { field: string, direction: string };

type ActivityKey =
    | "all"
    | "article_comment"
    | "article_comment_reply"
    | "comment_reply"
    | "contributed_article_comment"
    | "create_post"
    | "invitation_note"
    | "message_reply"
    | "post_comment"
    | "post_idea"
    | "post_ideas"
    | "profile_about"
    | "profile_headline"
    | "profile_optimizer"
    | "profile_summary"
    | "recommendation_text";

type ActivityValue = { display: string };

const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
    const {
        setActivities,
        activities
    } = props;

    const {
        getCurrentUser,
    } = useContext(AuthContext) as AuthContext_type;

    const [orderDropdown, setOrderDropdown] = useState(false);
    const [activityDropdown, setActivityDropdown] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState<OrderKey>("Last Modified");
    const [selectedActivity, setSelectedActivity] = useState<ActivityKey>("all")
    const orderDropdownRef = useRef<HTMLDivElement | null>(null);
    const activityDropdownRef = useRef<HTMLDivElement | null>(null);


    const orderMapping: Record<OrderKey, OrderValue> = {
        "Last Modified": { field: "timestamp", direction: "desc" },
        "Oldest": { field: "timestamp", direction: "asc" },
    };

    const activityMapping: Record<ActivityKey, ActivityValue> = {
        all: { display: "All" },
        article_comment: { display: "Article Comment" },
        article_comment_reply: { display: "Article Comment Reply" },
        comment_reply: { display: "Comment Reply" },
        contributed_article_comment: { display: "Contributed Article Comment" },
        create_post: { display: "Create Post" },
        invitation_note: { display: "Invitation Note" },
        message_reply: { display: "Message Reply" },
        post_comment: { display: "Post Comment" },
        post_idea: { display: "Post Idea" },
        post_ideas: { display: "Post Ideas" },
        profile_about: { display: "Profile About" },
        profile_headline: { display: "Profile Headline" },
        profile_optimizer: { display: "Profile Optimizer" },
        profile_summary: { display: "Profile Summary" },
        recommendation_text: { display: "Recommendation Text" },
    };

    const hanldleGetSaveCommands = async (order: any = "desc", activity: any = "") => {
        try {
            const user = await getCurrentUser();
            let q: Query
            if (user?.uid) {
                if (activities) {
                    q = query(
                        collection(
                            db,
                            `${COLLECTIONS.USER_ACTIVITIES}/${user?.uid}/${COLLECTIONS.ACTIVITIES}`
                        ),
                        orderBy(FIELDS.TIMESTAMP, order)
                    );
                } else {
                    q = query(
                        collection(
                            db,
                            `${COLLECTIONS.USER_ACTIVITIES}/${user?.uid}/${COLLECTIONS.COMMANDS}`
                        ),
                        orderBy(FIELDS.TIMESTAMP, order)
                    );
                }

                onSnapshot(q, (querySnapshot) => {

                    const cmds: any[] = [];
                    querySnapshot.forEach((doc) => {
                        cmds.push({ id: doc.id, ...doc.data() });
                    });

                    let filteredCmds = cmds;

                    if (activity) {
                        filteredCmds = cmds.filter((cmd) => cmd.activityType === activity);
                    }

                    setActivities(filteredCmds);

                });
            }
        } catch (error) {
            sentryClient.captureException(error)
            return error;
        }
    };

    useEffect(() => {
        hanldleGetSaveCommands()
    }, []);

    const handleApplyOrder = async () => {
        console.log(selectedOrder);

        const { direction } = orderMapping[selectedOrder];
        let activity: any = selectedActivity;
        if (selectedActivity === "all") {
            activity = ""
        }

        if (direction === "asc") {
            await hanldleGetSaveCommands("asc", activity);
        } else if (direction === "desc") {
            await hanldleGetSaveCommands("desc", activity);
        }

        setOrderDropdown(false);
    };

    const handleApplyActivity = async () => {
        console.log(selectedActivity);

        const { direction } = orderMapping[selectedOrder];
        let activity: any = selectedActivity;
        if (selectedActivity === "all") {
            activity = ""
        }

        if (direction === "asc") {
            await hanldleGetSaveCommands("asc", activity);
        } else if (direction === "desc") {
            await hanldleGetSaveCommands("desc", activity);
        }

        setActivityDropdown(false);
    };

    // const handleClickOutside = (event: MouseEvent) => {
    //     const targetNode = event.target as Node;

    //     if (orderDropdownRef.current && !orderDropdownRef.current.contains(targetNode)) {
    //         setOrderDropdown(false);
    //     }

    //     if (activityDropdownRef.current && !activityDropdownRef.current.contains(targetNode)) {
    //         setActivityDropdown(false);
    //     }
    // };

    // useEffect(() => {
    //     if (orderDropdown || activityDropdown) {
    //         document.addEventListener("mousedown", handleClickOutside);
    //     } else {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     }
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [orderDropdown, activityDropdown]);

    return (
        <>
            <div className='flex justify-between mt-5 mb-3'>
                <div className='flex '>
                    <div className='relative ml-2'>
                        <button className='flex items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg'
                            onClick={() => {
                                setOrderDropdown(false);
                                setActivityDropdown(!activityDropdown)
                            }}
                            style={{ height: "40px" }}
                        >
                            <span className='md:text-base text-sm'>Activity</span>
                            <img src={activityDropdown ? UpArrow : DownArrow} className='md:ml-14 ml-4 md:w-4 w-[14px]' />
                        </button>
                        {(!orderDropdown && activityDropdown) && (
                            <div ref={activityDropdownRef} className='absolute min-w-[250px] md:min-w-[350px] left-0 mt-1 z-10'>
                                <div className='text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px] overflow-y-auto h-[200px]' >
                                    {Object.keys(activityMapping).map(activity => (
                                        <span key={activity} className='flex mt-1 cursor-pointer' onClick={() => {
                                            if (Object.keys(activityMapping).includes(activity)) {
                                                setSelectedActivity(activity as ActivityKey);
                                            }
                                        }}>
                                            <img src={selectedActivity === activity ? checkImage : unCheckImage} />
                                            <span className='ml-2'>{activityMapping[activity as ActivityKey].display}</span>
                                        </span>
                                    ))}
                                </div>
                                <div className='flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]' >
                                    <button className='primary_button_custom rounded-lg' onClick={handleApplyActivity}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='relative ml-2'>
                        <button className='flex items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg'
                            onClick={() => {
                                setActivityDropdown(false);
                                setOrderDropdown(!orderDropdown)
                            }}
                            style={{ height: "40px" }}
                        >
                            <span className='md:text-base text-sm'>Order By</span>
                            <img src={orderDropdown ? UpArrow : DownArrow} className='md:ml-14 ml-4 md:w-4 w-[14px]' />
                        </button>
                        {(!activityDropdown && orderDropdown) && (
                            <div ref={orderDropdownRef} style={{ maxWidth: "200px", minWidth: "200px", marginLeft: "-62px" }} className='absolute left-0 mt-1 z-10'>
                                <div className='text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px]' >
                                    {Object.keys(orderMapping).map(order => (
                                        <span key={order} className='flex mt-1 cursor-pointer' onClick={() => {
                                            if (Object.keys(orderMapping).includes(order)) {
                                                setSelectedOrder(order as OrderKey);
                                            }
                                        }}>
                                            <img src={selectedOrder === order ? checkImage : unCheckImage} />
                                            <span className='ml-2'>{order}</span>
                                        </span>
                                    ))}
                                </div>
                                <div className='flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]' >
                                    <button className='primary_button_custom rounded-lg' onClick={handleApplyOrder}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default ActionButtons;