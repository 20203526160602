import { Link } from "react-router-dom";
import MagicIcon from "../../../assets/Images/MagicIcon.svg";
import evyaidemo_videoimage from "../../../assets/Images/evyaidemo_videoimage.png"

 export default function EmptyTableCommunity() {
  return (
    <>
      <div className="xsm:h-[60vh] sm:h-[70vh] bg-white rounded-[10px] font-poppins md:my-6 my-3 flex flex-col justify-center items-center">
        <Link
          to={
            "https://www.skool.com/evyai/classroom/a1594949?md=b57881a6bbdc42028320cfc6c01c0f69"
          }
          target="_blank"
        >
          <img
            className="cursor-pointer w-[500px] xsm:w-[90%] mx-auto mt-6"
            src={evyaidemo_videoimage}
          />
        </Link>
        <div className="flex flex-col gap-2 justify-center items-center">
          <h2 className="custom-h3-style-evyDemo text-center md:text-[24px] text-[18px] leading-[36px] mt-8">
            Watch this demonstration of evyAI
          </h2>
          <p className="custom-seconday-p-style md:text-[16px] text-[14px] text-center">
            Whether you're optimizing your LinkedIn profile, summarizing your
            connections profiles, creating custom connection notes, or leaving
            recommendations, evyAI has your back.
          </p>
        </div>
        <Link
          to={
            "https://www.skool.com/evyai/classroom/a1594949?md=b57881a6bbdc42028320cfc6c01c0f69"
          }
          target="_blank"
          style={{
            background:
              "linear-gradient(110.88deg, #2F3178 4.78%, #542E9C 83.75%)",
          }}
          className=" xsm:w-[90%] px-6 py-[10px] flex justify-center items-center gap-2 font-bold text-[16px] text-white rounded-[12px]"
        >
          <img src={MagicIcon} />
          Watch Demo
        </Link>
      </div>
    </>
  );
}
