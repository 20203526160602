import Logo from '../assets/Images/evyAI Logo_Purple.svg';
import '../index.css';
import { HOME_PAGE } from '../constant';
import { Link } from 'react-router-dom';
import { Navigate_to } from '../router/routes.constants';
import Button from './Button';
import { useState } from 'react';
import UserIcon from '../assets/Images/User.png'

const Header = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const HandleGetStarted = ()=>{
    window.open('https://linkedin.com/feed')
  }

  return (
    <header className='py-3 shadow-md'>
      <div className='sm:flex-row flex-col mx-auto sm:px-10 px-2 flex sm:items-center justify-between gap-[10px] sm:gap-0'>
        <div className='sm:block flex justify-between '>
          <a href={HOME_PAGE}>
            <img src={Logo} alt='Logo' className='sm:w-36 w-24' />
          </a>
          <div className='sm:hidden  bg-[#F5F1FB] flex flex-col justify-center items-center gap-2 px-3 cursor-pointer rounded-[10px]' onClick={()=> setIsMenuOpen(!isMenuOpen)}>

              <div className='w-[30px] h-[3px] bg-[#34317D] rounded-[100px]'>&nbsp;</div>
              <div className='w-[30px] h-[3px] bg-[#34317D] rounded-[100px]'>&nbsp;</div>
          </div>
        </div>
        {/* <div className={`sm:flex-row flex-col justify-between ${isMenuOpen ? 'flex' : 'sm:flex hidden'} `}>
          <Link to={"#"} className='btn ghost'>How it works</Link>
          <Link to={"#"} className='btn ghost'>Pricing</Link>
          <Link to={"#"} className='btn ghost'>Help Center</Link>
          <Link to={"#"} className='btn ghost'>Blog</Link>
          <Link to={"#"} className='btn ghost'>Become an affiliate</Link>
        </div> */}
        <div className={`${isMenuOpen ? 'flex' : 'sm:flex hidden'} gap-[10px]`}>
          <Link to={Navigate_to.login} target='_blank' className='btn ghost flex items-center gap-2'>
            <img src={UserIcon} alt='User' className='w-[20px] h-[20px]' />
            Login
          </Link>
          <Button className='btn primary' onClick={HandleGetStarted}>Get Started</Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
