import { useContext, useState } from "react";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { toastAlertError } from "../utils";

import { IoClose } from "react-icons/io5";
import CheckSuccess from "../assets/Icons/CheckSucess.svg";
import HelpIcon from "../assets/Images/help.svg";
import Loader from "./Loader";
import { sentryClient } from "@/Sentry/sentry";

export default function QuestionAndIssuesMenu() {
  const { addQuestionOrIssues } = useContext(AuthContext) as AuthContext_type;

  const [isOpen, toggleOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenMenu = () => {
    toggleOpen(!isOpen);
    setIsSuccess(false);
    setQuestion("");
  };

  const handleClose = () => {
    toggleOpen(false);
    setIsSuccess(false);
    setQuestion("");
  };

  const handleAddQuestion = async () => {
    setIsLoading(true);
    try {
      if (question) {
        await addQuestionOrIssues(question);
        setIsSuccess(true);
      }
    } catch (error) {
      toastAlertError("Something went wrong!");
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  };

  const QuestionForm = () => {
    return (
      <div className="flex flex-col h-full w-full">
        <h3 className="text-base md:text-lg font-bold text-text_primary">
          Questions and Issues
        </h3>
        <textarea
          value={question}
          autoFocus
          maxLength={5000}
          onChange={(e) => setQuestion(e.target.value)}
          className="h-full min-h-[250px] mt-2 font-poppins text-[#444553] border border-[#A1A2A9] rounded-[10px] text-sm md:text-base outline-none px-4 py-3 w-full"
          placeholder="Have a question or having an issue? Let us know here"
        />
        <button
          onClick={handleAddQuestion}

          disabled={question.length === 0 || isLoading}
          className="btn-primary flex items-center gap-x-2 max-w-[110px] mt-4 disabled:opacity-50 "
        >
          Submit
          {isLoading && <Loader size={4} />}
        </button>
      </div>
    );
  };

  const SuccessCard = () => {
    return (
      <div className="flex items-center flex-col text-white text-center font-poppins w-full">
        <img src={CheckSuccess} alt="" />
        <h3 className="text-[28px] md:text-[32px] font-black leading-9 mt-4">Thank you!</h3>
        <p className="text-sm md:text-lg font-normal mt-1">
          Your message has been received. We will respond via email from
        </p>
        <span className="text-sm md:text-lg font-bold">hello@evyai.com.</span>
      </div>
    );
  };

  return (
    <div className="relative">
      <div
        className="p-[6px] rounded-lg  cursor-pointer "
        onClick={handleOpenMenu}
      >
        <img src={HelpIcon} alt="" />
      </div>

      <div
        style={{
          background: isSuccess
            ? "linear-gradient(110.88deg, #2F3178 4.78%, #542E9C 83.75%)"
            : "white",
        }}
        className={
          isOpen
            ? "absolute md:-left-[50px] md:transform md:-translate-x-1/2 -right-12 sm:-right-4 md:right-0 mt-8 md:mt-4 w-[340px] md:w-[360px] min-h-[390px] h-fit rounded-2xl py-8 px-6 shadow-xl font-poppins flex items-center justify-center "
            : "hidden"
        }
      >
        <button
          type="button"
          className="absolute flex items-center justify-center rounded-full top-2 right-2"
          data-te-modal-dismiss
          aria-label="Close"
        >
          <IoClose
            onClick={handleClose}
            size={24}
            className={`font-black cursor-pointer ${isSuccess ? "!text-white" : "!text-secondry_color"
              }`}
          />
        </button>
        {isSuccess ? SuccessCard() : QuestionForm()}
      </div>
    </div>
  );
}
