import React, { useState, useRef } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
// import { FcGlobe } from "react-icons/fc";
import GlobeIcon from "../assets/Images/GlobeIcon.svg"

import SearchImg from "../assets/Images/listSearch.svg";

import {
  US,
  DE,
  IT,
  IL,
  FR,
  ES,
  PT,
  SE,
  RU,
  IN,
  PK,
  FlagComponent,
  NO,
  PL,
  RO,
  CZ,
  SK,
  GB,
  FI,
} from "country-flag-icons/react/3x2";
import { STORAGE_KEYS } from "@/config/appConfig";
import { toastAlertError } from "@/utils";
import { sentryClient } from "@/Sentry/sentry";

export type LanguageKey = "Spa" | "Ger" | "Fr" | "Ita" | "Heb" | "Eng-US" | "Por" | "Dut" | "Nor" | "Swe" | "Rus" | "Hin" | "Urd" | "POL" | "RO" | "CZ" | "SK" | "Eng-UK" | "Fin" | string;

type LanguageValue = { display: string; icon: FlagComponent };

interface IProps {
  selectedLanguage: LanguageKey;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<LanguageKey>>
}

const languageMapping: Record<LanguageKey, LanguageValue> = {
  'Eng-US': { display: "English (US)", icon: US },
  'Eng-UK': { display: "English (UK)", icon: GB },
  Spa: { display: "Spanish", icon: ES },
  Ger: { display: "German", icon: DE },
  Fr: { display: "French", icon: FR },
  Ita: { display: "Italian", icon: IT },
  Heb: { display: "Hebrew", icon: IL },
  Por: { display: "Portuguese", icon: PT },
  Dut: { display: "Dutch", icon: DE },
  Nor: { display: "Norwegian", icon: NO },
  Swe: { display: "Swedish", icon: SE },
  Pol: { display: "Polish", icon: PL },
  Rus: { display: "Russian", icon: RU },
  RO: { display: "Romanian", icon: RO },
  Hin: { display: "Hindi", icon: IN },
  Urd: { display: "Urdu", icon: PK },
  CZ: { display: "Czech", icon: CZ },
  SK: { display: "Slovak", icon: SK },
  FIN: { display: "Finnish", icon: FI },
};

const LanguageSelector: React.FC<IProps> = ({ selectedLanguage, setSelectedLanguage }) => {
  const [activityDropdown, setActivityDropdown] = useState(false);
  const [searchLists, setSearchedLists] = useState("");
  const [filteredLanguages, setFilteredLanguages] =
    useState<Record<LanguageKey, LanguageValue>>(languageMapping);


  const activityDropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();

    const filtered: Record<LanguageKey, LanguageValue> = Object.keys(
      languageMapping
    )
      .filter((lang: LanguageKey | string) => {
        return lang.toLowerCase().includes(searchValue) || languageMapping[lang].display.toLowerCase().includes(searchValue);
      })
      .reduce((obj, key) => {
        //@ts-ignore
        obj[key] = languageMapping[key];
        return obj;
      }, {} as Record<LanguageKey, LanguageValue>);
    setFilteredLanguages(filtered);
    setSearchedLists(searchValue);
  };



  const handleLanguageChange = (language: LanguageKey) => {
    try {
      setSelectedLanguage(language);
      localStorage.setItem(STORAGE_KEYS.LAST_LANGUAGE_USED, language)
      setActivityDropdown(false);
    } catch (error) {
      toastAlertError("Error while remebering last used language!");
      sentryClient.captureException(error)
    }
  };



  return (
    <>
      <div className="flex align-middle justify-between">
        <div className="relative md:ml-2">
          <button
            className="flex items-center md:bg-white text-black_color font-poppins  md:px-4 md:py-1 rounded-lg border-none outline-none"
            onClick={() => {
              setActivityDropdown(!activityDropdown);
            }}
            style={{ height: "40px" }}
          >
            <span className="flex items-center gap-x-1 md:gap-x-3 text-sm md:text-base font-semibold uppercase">
              {/* <FlagIcon className="h-4 w-6 md:w-[30px] md:h-5" />{" "} */}
              <img src={GlobeIcon} alt="Globe" width={25} />
              {/* <FcGlobe size={25}/> */}
              {selectedLanguage}
            </span>

            <span className="ml-1 md:ml-2 ">
              {activityDropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
          </button>
          {activityDropdown && (
            <div
              ref={activityDropdownRef}
              // className="absolute min-w-[140px] md:min-w-[240px] left-0 mt-1 z-10"
              className="absolute min-w-[140px] md:min-w-[240px] left-0 top-[-13rem] z-10"
            >
              <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-3 md:p-4  rounded-t-[10px] overflow-y-auto h-[200px]">
                <div className="relative  flex items-center border border-border_color p-1.5 rounded-[10px] mb-3">
                  <img
                    src={SearchImg}
                    alt="Search"
                    className="absolute left-3"
                    style={{ height: "16px", width: "16px" }}
                  />
                  <input
                    className="text-black_color font-poppins text-xs md:text-sm w-full leading-6 pl-[calc(16px+12px)] outline-none "
                    onChange={handleSearch}
                    placeholder="Search"
                    value={searchLists}
                  />
                </div>

                {Object.keys(filteredLanguages).map((activity) => {
                  // const FlagIcon =
                  //   languageMapping[activity as LanguageKey].icon;

                  return (
                    <span
                      key={activity}
                      className="flex items-center py-1.5 md:py-3 cursor-pointer border-b"
                      onClick={() => handleLanguageChange(activity as LanguageKey)}
                    >
                      {/* <FlagIcon className="h-4 w-6 md:w-[30px] md:h-5" /> */}

                      <span className="ml-2 text-sm md:text-base">
                        {languageMapping[activity as LanguageKey].display}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default LanguageSelector;