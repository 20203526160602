import { useContext, useState } from 'react';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import { useNavigate } from 'react-router-dom';
import { Navigate_to } from '../../../router/routes.constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginFormSchema } from '../schema';
import { signIngoogleFailure } from '../../../context/Auth/types';
import { sentryClient } from '@/Sentry/sentry';

function useLogin() {
  const { signInWithEmail, signInWithGoogle, isNavigateToSub } = useContext(AuthContext) as AuthContext_type;
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const {
    handleSubmit,
    getValues,
    control: formControl,
    formState: { errors: formErrors, isValid: formIsValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginFormSchema),
  });

  const onSignInWithCredentials = async () => {
    setIsLoading(true);
    signInWithEmail(getValues().email, getValues().password)
      .then((response) => {
        if (response.success) {
          // if (isNavigateToSub) {
          //   navigateTo(Navigate_to.subscriptionPricing, { replace: true })
          // } else if (isTeamInviteUrl && teamInviteAcceptParams) {
          //   navigateTo(`${Navigate_to.acceptInvite}/:${teamInviteAcceptParams.team_id}?/:${teamInviteAcceptParams.member_id}?`, { replace: true })
          // } else {
          //   navigateTo(Navigate_to.afterLogin, { replace: true })
          // }
          if (isNavigateToSub) {
            navigateTo(Navigate_to.subscriptionPricing , { replace: true });
          }
          // navigateTo(isNavigateToSub ? Navigate_to.subscriptionPricing : Navigate_to.afterLogin , { replace: true });
          setErrorText('');
        } else {
        
          setErrorText(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('ERROR - ', err.data)
        sentryClient.captureException(err)
      });
  };

  const onLoginWithGoogle = async () => {
    const response = await signInWithGoogle();
    if (response && (response as signIngoogleFailure).errorMessage) {
      setErrorText((response as signIngoogleFailure).errorMessage);
    }
  };

  return {
    onSignInWithCredentials,
    handleSubmit,
    onLoginWithGoogle,
    formIsValid,
    formErrors,
    formControl,
    isLoading,
    errorText,
    getValues,
  };
}

export default useLogin;
