import { ReactNode, useEffect } from 'react';
import AuthContext from '../context/Auth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UNPROTECTED_ROUTES } from '../router/routes.constants';

type LayoutMain_props = { children: ReactNode };

function LayoutMain(props: LayoutMain_props | {}) {

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    switch (queryParams.get("mode")) {
      case "resetPassword":
        navigate(`${UNPROTECTED_ROUTES.resetPassword}${location.search}`);
        break;
      case "verifyEmail":
        navigate(`${UNPROTECTED_ROUTES.emailVerification}${location.search}`);
        break;
      default: ;
        break;
    }
  }, []);

  return (
    <AuthContext>
      {(props as LayoutMain_props)?.children ? <div>hello</div> : <Outlet />}
    </AuthContext>
  );
}

export default LayoutMain;
