import { useContext } from "react";
import nextarrow from "../assets/Images/nextarrow.png";
import WelcomeTourDialogBox from "./WelcomeTourDialogBox";
import { AuthContext, AuthContext_type } from "../context/Auth/context";

interface IProps {
  openDialog: boolean;
  welcomeSteps: number | null;
  onClose: () => void;
  setWelcomeSteps: React.Dispatch<React.SetStateAction<number | null>>;
  rightMarginToSet: number | null;
}

const WelcomeTourMainDialog = ({
  welcomeSteps,
  setWelcomeSteps,
  openDialog,
  onClose,
  rightMarginToSet,
}: IProps) => {
  const { updateWelcomeTourStatus, User } = useContext(AuthContext) as AuthContext_type;

  const stepsContent = [
    {
      title: "Pin the extension",
      description: "Click the puzzle icon on Google Chrome and pin the evyAI icon so it's always visible.",
    },
    {
      title: "Home",
      description: "Click Home to browse your recent activities as well as any items you have saved to view or use later.",
    },
    {
      title: "Subscription",
      description: "Subscribe to evyAI premium here. Return to manage your subscription at any time.",
    },
    {
      title: "Saved Profiles",
      description: "View the LinkedIn profiles you have saved and quickly access their recent activity to stay top of mind with your strategic connections.",
    },
    {
      title: "Post Writer",
      description: "Create personalized LinkedIn posts with a click of a button to gain exposure on LinkedIn.",
    },
    {
      title: "Idea Generator",
      description: "Generate creative ideas for posts and then create those posts with the click of a button.",
    },
    {
      title: "Templates",
      description: "Generate creative ideas for posts and then create those posts with the click of a button.",
    },
    {
      title: "Profile Optimizer",
      description: "Optimize your LinkedIn Profile by entering your current profile information, any additional information, and hit generate.",
    },
    {
      title: "Persona",
      description: "Create personas to help evyAI learn and personalize content for you and your company.",
    },
    {
      title: "Learning Hub",
      description: "Discover evyAI's LinkedIn Resources - your shortcut to expert tips and guides for mastering LinkedIn.",
    },
    {
      title: "Community",
      description: "Join this group on Skool to learn how to leverage AI and LinkedIn together.",
    },
    {
      title: "Demonstration",
      description: "Watch this demonstration video of evyAI.",
    },
    {
      title: "My account",
      description: "Update your personal information, password, and manage your subscription.",
    },
    {
      title: "Get Started",
      description: "Click the evyAI pinned extension to open LinkedIn and get started using evyAI.",
    },
  ];

  const handleShowContent = () => {
    if (welcomeSteps === null) {
      return null;
    }
  
    const stepContent = stepsContent[welcomeSteps];
    if (!stepContent) return null;

    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-[#6E40C5] text-[16px] font-poppins font-semibold text-start">
          {stepContent.title}
        </h2>
        <p className="text-base font-poppins color-[#292929] text-start">
          {stepContent.description}
        </p>
      </div>
    );
  };

  const buttonText = welcomeSteps !== 13 ? "Next" : "Finish";

  return (
    <WelcomeTourDialogBox
      className="flex flex-col !max-w-[315px] !p-0"
      open={openDialog}
      onClose={onClose}
      welcomeSteps={welcomeSteps}
      rightMarginToSet={rightMarginToSet}
    >
      {handleShowContent()}
      <div className="flex justify-end gap-3">
        <button
          onClick={() => {
            onClose();
            setWelcomeSteps(null)
            if (User?.uid) {
              updateWelcomeTourStatus(User.uid);
            }
          }}
          type="button"
          className="text-[#34317D] px-6 py-[10px] flex items-center justify-center disabled:opacity-50"
        >
          <span className="pl-1 font-poppins text-[12px] font-bold text-center underline">
            Skip All
          </span>
        </button>
        <button
          onClick={() => {
            if (welcomeSteps !== 13 && welcomeSteps !== null) {
              setWelcomeSteps(welcomeSteps + 1);
            } else {
              onClose();
              setWelcomeSteps(null);
              if (User?.uid) {
                updateWelcomeTourStatus(User.uid);
              }
            }
          }}
          className="bg-secondry_color text-white rounded-[10px] px-6 py-[10px] font-poppins md:text-[18px] text-[10px] font-bold flex items-center justify-center disabled:opacity-50"
        >
          <span className="pr-1 font-poppins text-[12px] font-bold text-center">{buttonText}</span>
          <img
            className="ml-50 color-white"
            src={nextarrow}
            alt="img"
            width="9.6px"
            height="9.6px"
          />
        </button>
      </div>
    </WelcomeTourDialogBox>
  );
};

export default WelcomeTourMainDialog;