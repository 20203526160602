import { useState, useContext, useEffect, useRef } from "react"
import Check from "../../../assets/Images/primaryCheck.svg"
import Uncheck from "../../../assets/Images/primaryUncheck.svg"
import ProfileAvatar from "../../../assets/Images/profileAvatar.svg"
import Dots from "../../../assets/Images/Dots.svg"
import sortArrow from "../../../assets/Images/Sort.svg"
import { useNavigate, useLocation } from "react-router-dom";
import NextPrevPagination from "../../../components/NextPrevPagination"
import { toastAlertError, toastAlertSuccess } from "../../../utils"
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { SavedProfiles } from ".."
import { copyTextToClipBoard } from "../../../utils"
import ManageProfile from "./ManageProfile"
import { UserLists } from "../../ManageLists"
import { PROTECTED_ROUTES } from "../../../router/routes.constants"
import { formatLinkedInUrl } from "../../../utils"
import useScreenSize from "../../../hooks/useScreenSize"
import type { FetchData } from "../index";
import { updateQueryParams } from "../../../utils"
import { sentryClient } from "@/Sentry/sentry"


const initialProfile: SavedProfiles = {
    profileUrl: '',
    linkedInProfileName: '',
    profileHeadline: '',
    userId: '',
    profilePictureUrl: '',
    lists: [],
    id: '',
};

export interface SaveProfileTableProps {
    savedProfiles: Array<SavedProfiles>
    onDataChanged: () => void;
    setSavedProfiles: React.Dispatch<React.SetStateAction<SavedProfiles[]>>;
    selectedProfiles: string[];
    setSelectedProfiles: (profileIds: string[]) => void;
    allLists: UserLists[]
    setSelectedLists: React.Dispatch<React.SetStateAction<UserLists[]>>
    handleGetUserProfile: (fetchData?: FetchData) => Promise<any>
    totalRecords : number
    accessRowsPerPage : (value : number ) => void;
    accessPagination : (value : boolean) => void;
    isPaginationDone : boolean;
    handleGetUserLists: () => Promise<any>;
    refershListsFlag: boolean;
}

export default function SaveProfileTable(props: SaveProfileTableProps) {
    const { savedProfiles, setSavedProfiles, selectedProfiles, handleGetUserProfile, setSelectedProfiles, setSelectedLists, allLists, totalRecords, accessRowsPerPage, accessPagination, isPaginationDone, handleGetUserLists, refershListsFlag } = props;
    const { deleteLinkedinProfile } = useContext(
        AuthContext
    ) as AuthContext_type;

    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState<SavedProfiles>(initialProfile);
    const [showListForm, setShowListForm] = useState(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [dotsDropdown, setDotsDropdown] = useState(-1);
    const [profiles, setProfiles] = useState<any[]>(savedProfiles);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [profilesPerPage, setProfilesPerPage] = useState<number>(10);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [userAllLists, setUserAllLists] = useState<UserLists[]>(allLists);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [toDeleteId, setToDeleteId] = useState<string | null>(null);
    const dotsDropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    // const [dropdownDirection, setDropdownDirection] = useState<'up' | 'down'>('down');
    const currentLocation = useLocation();


    const screen = useScreenSize();

    // Get current profiles
    const indexOfLastPorfile = currentPage * profilesPerPage;
    const indexOfFirstPorfile = indexOfLastPorfile - profilesPerPage;
    const currentPorfiles = profiles.slice(indexOfFirstPorfile, indexOfLastPorfile);
    const paginate = (isNext: boolean) => {
        handleGetUserProfile({
            isNext,
            itemsPerPage: profilesPerPage,
        })
    };

    useEffect(() => {
        setProfiles(savedProfiles);
    }, [savedProfiles]);

    useEffect(() => {
        if(refershListsFlag){
          async function fetchData() {
              try {
                  const userLists = await handleGetUserLists();
                  setUserAllLists(userLists);
            } catch (error) {
                toastAlertError("Failed to fetch lists");
                sentryClient.captureException(error)
            }
          }
      
          fetchData();
        }
      }, [refershListsFlag]);

    const handleSetItemsPerPage = (count: number) => {
        setProfilesPerPage(count);
        accessRowsPerPage(count);
        setCurrentPage(1);
        handleGetUserProfile({
            itemsPerPage: count,
            init: true,
        })
    }

    const handleFilterList = async (itemName: string) => {
        // await handleGetUserProfile()
        let selectedList : UserLists[] = [];
        if (allLists.length > 0) {
            selectedList = [allLists.find((listItem) => listItem.name === itemName)] as UserLists[]
            setSelectedLists(selectedList)
        }
        await handleGetUserProfile({
            orderByField: "linkedInProfileName",
            orderDirection:"asc",
            init: true,
            filters: selectedList,
            query: "",
        });

        updateQueryParams(selectedList.map(list => list.id),currentLocation)
    }

    const handleDelete = async (id: string) => {
        try {
            if (id) {
                deleteLinkedinProfile(id);
                const updatedProfiles = savedProfiles.filter(profile => profile.id !== id);
                setSavedProfiles(updatedProfiles);
                console.log(id, "id")
                toastAlertSuccess("Deleted successfully");
            }
        } catch (error) {
            console.log(error, "error!")
            sentryClient.captureException(error)
        }
    }

    function handleManager(saveProfile: SavedProfiles) {
        setUserProfile(saveProfile)
        setShowAlert(true)
        setShowListForm(true);
    }

    function handleCloseManager() {
        // setUserProfile(undefined)
        setShowAlert(false)
        setShowListForm(false);
    }

    useEffect(() => {
        setProfiles(savedProfiles);
        setIsAllChecked(currentPorfiles.every(profile => selectedProfiles.includes(profile.id)));
    }, [selectedProfiles, setSelectedProfiles]);

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newDeleteListsIds = selectedProfiles.filter(id => !currentPorfiles.map(profile => profile.id).includes(id));
            setSelectedProfiles(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...selectedProfiles, ...currentPorfiles.map(profile => profile.id)];
            setSelectedProfiles(newDeleteListsIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    // const handleDotsClick = (index: number) => {

    //     const buttonRect = dotsDropdownRefs.current[index]?.getBoundingClientRect();
    //     const viewportHeight = window.innerHeight;

    //     if (buttonRect) {
    //         const spaceBelow = viewportHeight - buttonRect.bottom;
    //         const dropdownHeight = 200; // estimated height of your dropdown

    //         if (spaceBelow < dropdownHeight) {
    //             setDropdownDirection('up');
    //         } else {
    //             setDropdownDirection('down');
    //         }
    //     }

    //     setDotsDropdown(dotsDropdown === index ? -1 : index);
    // };


    const handleListCheckboxClick = (profileId: string) => {
        if (selectedProfiles.includes(profileId)) {
            const newDeleteListsIds = selectedProfiles.filter(id => id !== profileId);
            setSelectedProfiles(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...selectedProfiles, profileId];
            setSelectedProfiles(newDeleteListsIds);
        }
    };

    const confirmDelete = () => {
        if (toDeleteId) {
            handleDelete(toDeleteId);
            setToDeleteId(null);
        }
        setShowConfirmation(false);
    };

    const initiateDelete = (profileId: string) => {
        setToDeleteId(profileId);
        setShowConfirmation(true);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dotsDropdown !== -1 && !dotsDropdownRefs.current[dotsDropdown]?.contains(event.target as Node)) {
            setDotsDropdown(-1);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dotsDropdown, dotsDropdownRefs.current]);

    return (
        <div className="mt-4">
            <div className="bg-white rounded-xl">
                <table className="w-full text-sm text-left">
                    <thead className="font-poppins text-base text-gray_dark w-8 border-b border-gray-300">
                        <tr>
                            {screen === "small" ?

                                <th scope="col" className="py-3 px-0" style={{ width: "20%" }}>
                                    <img
                                        src={isAllChecked ? Check : Uncheck}
                                        width={25}
                                        height={25}
                                        onClick={handleHeaderCheckboxClick}
                                        alt="Checkbox"
                                        className="mx-auto"
                                    />
                                </th> :
                                <th scope="col" className="px-3 py-3" style={{ width: "4%" }}>
                                    <img
                                        src={isAllChecked ? Check : Uncheck}
                                        width={25}
                                        height={25}
                                        onClick={handleHeaderCheckboxClick}
                                        alt="Checkbox"
                                        className="mx-auto"
                                    />
                                </th>
                            }
                            {screen === "small" ?
                                <th scope="col" className="py-3 px-3 font-medium text-[16px]" style={{ width: "95.6667%" }}>
                                    <div className="flex gap-1">
                                        Name
                                        <img src={sortArrow} />
                                    </div>
                                </th> :
                                <th scope="col" className="py-3 px-3" style={{ width: "43.6667%" }}>
                                    Name
                                </th>
                            }
                            <th scope="col" className="xsm:hidden py-3 px-3" style={{ width: "22.6667%" }}>
                                Lists
                            </th>
                            {screen === "small" ?
                                <th scope="col" className="py-3 px-3 font-medium text-[16px]" style={{ width: "5.6667%" }}>
                                    Actions
                                </th> :
                                <th scope="col" className="py-3 px-3" >
                                    <div className="w-full flex justify-end">
                                        Actions
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>

                        {currentPorfiles.map((saveProfile, index) => {
                            const isChecked = selectedProfiles.includes(saveProfile.id);
                            return (
                                <>
                                    <tr
                                        key={saveProfile.id}
                                        className={`${isChecked ? "bg-chip_bg" : "bg-white"}`}
                                    >
                                        <td valign="top" className="px-3 xsm:px-0 py-3">
                                            <img
                                                className="mx-auto"
                                                src={isChecked ? Check : Uncheck}
                                                width={25}
                                                height={25}
                                                onClick={() => handleListCheckboxClick(saveProfile.id)}
                                            />
                                        </td>
                                        <td className="py-3 px-3 w-[35%]">
                                            <div className="flex align-bottom profiles-center">
                                                <img
                                                    src={saveProfile.profilePictureUrl ? saveProfile.profilePictureUrl : ProfileAvatar}
                                                    // style={{height: "52px", width:"52px"}} 
                                                    className="rounded-full h-[52px] w-[52px] xsm:h-[36px] xsm:w-[36px]"
                                                    onError={(e) => {
                                                        const imgElement = e.target as HTMLImageElement;
                                                        imgElement.onerror = null;
                                                        imgElement.src = ProfileAvatar;
                                                    }}
                                                />
                                                <div className="flex flex-col justify-center ml-3">
                                                    <h2 className="font-bold xsm:text-[14px] font-poppins">{saveProfile.linkedInProfileName}</h2>
                                                    {/* <p className="mt-1 font-poppins xsm:text-[12px] xsm:overflow-hidden xsm:min-w-[150px] xsm:max-w-[200px]">{saveProfile.profileHeadline}</p> */}
                                                    <p className="w-full" style={{
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "1",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'ellipsis',
                                                        overflow: "hidden"
                                                    }}
                                                    >
                                                        {saveProfile.profileHeadline}
                                                    </p>
                                                    {/* <p className="w-[200px] sm:w-[300px] whitespace-nowrap overflow-hidden text-ellipsis mt-1 font-poppins xsm:text-[12px]">
                                                        {saveProfile.profileHeadline}
                                                    </p> */}

                                                    <div className="md:hidden flex flex-wrap profiles-center w-full gap-1 mt-2">
                                                        {
                                                            saveProfile.lists.slice(0, 2).map((list: UserLists) => (
                                                                <span key={list.id} onClick={() => {
                                                                    handleFilterList(list.name)
                                                                }} className="rounded-xl text-primary_color font-bold text-[12px] bg-chip_bg px-2 py-0.5 ml-0">{list.name}</span>
                                                            ))
                                                        }
                                                        {
                                                            saveProfile.lists.length > 2 && (<span className="rounded-xl text-primary_color font-bold text-[12px] bg-chip_bg px-2 py-0.5 ml-0">+{saveProfile.lists.length - 3}</span>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="xsm:hidden py-3 px-3 cursor-pointer col-span-2 w-[30%]">
                                            <div className="flex flex-wrap profiles-center">
                                                {
                                                    saveProfile.lists.slice(0, 3).map((list: UserLists) => (
                                                        <span key={list.id} onClick={() => {
                                                            handleFilterList(list.name)
                                                        }} className="rounded-lg text-primary_color font-semibold bg-chip_bg px-3 py-1 ">{list.name}</span>
                                                    ))
                                                }
                                                {
                                                    saveProfile.lists.length > 3 && (<span className="rounded-xl text-primary_color font-semibold bg-chip_bg px-2 py-0.5">+{saveProfile.lists.length - 3}</span>)
                                                }
                                            </div>
                                        </td>
                                        <td className="py-3 px-3 xsm:flex xsm:justify-end w-[100%]">
                                            <div className="flex justify-end">
                                                <button className='xsm:hidden all_sType_buttons font-normal'
                                                    onClick={() => {
                                                        window.open(`${formatLinkedInUrl(saveProfile.profileUrl)}recent-activity/all/`, "_blank");
                                                    }}
                                                >
                                                    See Recent Activity
                                                </button>
                                                <div className='relative ml-6'>
                                                    <img src={Dots} onClick={() => setDotsDropdown(dotsDropdown === index ? -1 : index)} className="cursor-pointer xsm:w-[20px] xsm:h-[20px]" />
                                                    {dotsDropdown === index && (
                                                        <div ref={(el) => dotsDropdownRefs.current[index] = el} style={{ maxWidth: "180px", minWidth: "180px", top: "22px", right: "0px", overflow: "visible", zIndex: "10" }} className='absolute mt-1'>
                                                            <div className='text-black_color font-poppins xsm:font-medium xsm:text-[14px] bg-white leading-6 border border-border_color p-1 pl-3 rounded-[10px]'>
                                                                <span className='mt-1'>
                                                                    <button className='mt-2'
                                                                        onClick={() => {
                                                                            handleManager(saveProfile)
                                                                            setDotsDropdown(-1)
                                                                        }}>
                                                                        Manage Profile's List
                                                                    </button>
                                                                    <button className='mt-2'
                                                                        onClick={() => {
                                                                            copyTextToClipBoard(saveProfile.profileUrl)
                                                                            setDotsDropdown(-1)
                                                                        }}>
                                                                        Copy Profile Url
                                                                    </button>
                                                                    {
                                                                        saveProfile.profileUrlSN && (
                                                                            <button className='mt-2'
                                                                            onClick={() => {
                                                                                copyTextToClipBoard(saveProfile.profileUrlSN)
                                                                                setDotsDropdown(-1)
                                                                            }}>
                                                                            Copy SN Profile Url
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {
                                                                        saveProfile.summaryId && (
                                                                            <button className='mt-2'
                                                                                onClick={() => navigate(`/${PROTECTED_ROUTES.commandDetails}/${saveProfile.summaryId}`)}>
                                                                                Show Profile Summary
                                                                            </button>
                                                                        )
                                                                    }
                                                                    <button className='mt-2 xsm:text-[#EB5757]'
                                                                        onClick={() => {
                                                                            initiateDelete(saveProfile.id)
                                                                            setDotsDropdown(-1)
                                                                        }}>
                                                                        Delete Profile
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr >
                                    <tr key={index} className={`${index === currentPorfiles.length - 1 ? "" : "border-b border-border_color"} ${isChecked ? "bg-chip_bg" : "bg-white"}`}>
                                        <td colSpan={3} className="">
                                            <div className="w-[92%] mx-auto">
                                                <button className='md:hidden mb-4 all_sType_buttons font-medium w-full'
                                                    onClick={() => {
                                                        window.open(`${formatLinkedInUrl(saveProfile.profileUrl)}recent-activity/all/`, "_blank");
                                                    }}
                                                >
                                                    See Recent Activity
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
                <NextPrevPagination
                    itemsPerPage={profilesPerPage}
                    paginate={paginate}
                    setItemsPerPage={handleSetItemsPerPage}
                    totalRecords = {totalRecords}
                    accessPagination = {accessPagination}
                />
            </div >
            {
                showListForm && (
                    <ManageProfile
                        handleGetUserProfile={handleGetUserProfile}
                        setShowAlert={handleCloseManager}
                        showAlert={showAlert}
                        userProfile={userProfile}
                        savedProfiles={savedProfiles}
                        setSavedProfiles={setSavedProfiles}
                        allLists={userAllLists}
                        profilesPerPage = {profilesPerPage}
                        isPaginationDone = {isPaginationDone}
                    />
                )
            }
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={confirmDelete}
                header="Delete Profile"
                content="Are you sure you want to delete this profile?"
            />
        </div >
    );
}