import { Link } from "react-router-dom";
import { Navigate_to } from "../../../router/routes.constants";
import PlusSvg from "../../../assets/Images/Plus.svg"
import { useEffect, useRef, useState } from "react";
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";
import SearchImg from "../../../assets/Images/listSearch.svg"
import Setting from "../../../assets/Images/SettingIcon.svg"
import { ITemplate } from "..";
import useScreenSize from "../../../hooks/useScreenSize";
import { Tags } from "../../AddTemplate/AddTemplate";

export interface ActionButtonsProps {
    isAddButtonDisable: boolean;
    getAllTemplates(sortBy?: string, order?: any): Promise<ITemplate[] | undefined>;
    deleteTemplateIds: string[];
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    setTemplates: React.Dispatch<React.SetStateAction<ITemplate[]>>;
    templates: ITemplate[];
    templatesForSearch: ITemplate[];
    userAllTags: Tags[];
    selectedTags: Tags[];
    setSelectedTags: React.Dispatch<React.SetStateAction<Tags[]>>;
    checkPaymentPastDue: () => void;
}


type OrderKey = "Last Modified" | "Date Created" | "Name A → Z" | "Name Z → A";
type OrderValue = { field: string, direction: string };

export default function ActionButtons(props: ActionButtonsProps) {

    const { isAddButtonDisable, getAllTemplates, deleteTemplateIds, setShowConfirmation, setTemplates, templatesForSearch, selectedTags, setSelectedTags, userAllTags, checkPaymentPastDue } = props;

    const allTemplates: ITemplate[] = templatesForSearch;

    const [orderDropdown, setOrderDropdown] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<OrderKey>("Last Modified");
    const [tagListDropdown, setTagListDropdown] = useState(false);
    const [searchedTags, setSearchedTags] = useState<Tags[]>([]);
    const [userSelectedTags, setUserSelectedTags] = useState<Tags[]>([]);
    const tagListDropdownRef = useRef<HTMLDivElement | null>(null);
    const orderDropdownRef = useRef<HTMLDivElement | null>(null);

    const screen = useScreenSize();

    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const tagId = queryParams.get("tag");
        console.log(tagId);


        if (tagId) {
            const tagIndex = userAllTags.findIndex(
                (tagItem) => tagItem.id === tagId
            );

            if (tagIndex !== -1) {
                setSelectedTags([userAllTags[tagIndex]]);
            }
        }
    }, [userAllTags]);

    const orderMapping: Record<OrderKey, OrderValue> = {
        "Last Modified": { field: "modifiedDate", direction: "desc" },
        "Date Created": { field: "timestamp", direction: "desc" },
        "Name A → Z": { field: "templateName", direction: "asc" },
        "Name Z → A": { field: "templateName", direction: "desc" },
    };

    const handleApplyOrder = () => {
        const { field, direction } = orderMapping[selectedOrder];
        getAllTemplates(field, direction);
        setOrderDropdown(false);
    };

    useEffect(() => {
        setSearchedTags(userAllTags);
    }, [userAllTags]);

    useEffect(() => {
        setUserSelectedTags(selectedTags);
    }, [selectedTags]);

    const searchLists = (value: string) => {

        if (value === '') {
            setTemplates(allTemplates)
        } else {
            const filteredTemplates = allTemplates.filter(template =>
                template.templateName.toLowerCase().includes(value.toLowerCase()) ||
                template.templateContent.toLowerCase().includes(value.toLowerCase())
            );
            setTemplates(filteredTemplates);
        }
    }

    const searchTags = (value: string) => {
        const regex = new RegExp(value, "i");
        const filteredTags = userAllTags.filter((tag) => regex.test(tag.name));
        setSearchedTags(filteredTags);
    };
    const addOrRemoveTag = (tag: Tags) => {
        const isSelected = userSelectedTags.some(
            (userSelectedTag) => userSelectedTag.id === tag.id
        );
        if (isSelected) {
            const updatedTags = userSelectedTags.filter(
                (userSelectedTag) => userSelectedTag.id !== tag.id
            );
            setUserSelectedTags(updatedTags);
        } else {
            setUserSelectedTags([...userSelectedTags, tag]);
        }
    };

    const handleApply = async () => {
        setSelectedTags(userSelectedTags);
        // const { field, direction } = orderMapping[selectedOrder];
        await getAllTemplates();
        setTagListDropdown(false);
        setSearchedTags(userAllTags);

    };

    const handleClear = () => {
        setUserSelectedTags([]);
        setSelectedTags([]);
        setSearchedTags(userAllTags);
        setOrderDropdown(false);
        setTagListDropdown(false);
    };

    useEffect(() => {
        console.log(userSelectedTags);

    }, [userSelectedTags])

    const handleClickOutside = (event: MouseEvent) => {
        const targetNode = event.target as Node;

        if (orderDropdownRef.current && !orderDropdownRef.current.contains(targetNode)) {
            setOrderDropdown(false);
        }

        if (tagListDropdownRef.current && !tagListDropdownRef.current.contains(targetNode)) {
            setTagListDropdown(false);
        }
    };

    useEffect(() => {
        if (orderDropdown || tagListDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [orderDropdown, tagListDropdown]);

    return (
        <>
            <div className='flex justify-start items-center xsm:mb-0 md:mt-8 mt-5  mb-3'>
                <div className="w-full flex justify-between xsm:flex-wrap xsm:gap-y-2">
                    <div className=" flex justify-start items-center xsm:w-full xsm:flex-col xsm:gap-2 ">
                        <div className=" w-[300px] h-[40px] md:mr-2 xsm:w-full bg-white relative flex items-center  border border-border_color p-1.5 rounded-[10px]">
                            <img
                                src={SearchImg}
                                alt="Search"
                                className="absolute left-3"
                                style={{ height: "16px", width: "16px" }}
                            />
                            <input
                                className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)] outline-none bg-transparent"
                                onChange={(e) => searchLists(e.target.value)}
                                style={{ fontSize: "16px", width: "100%", lineHeight: "24px" }}
                                placeholder="Search Templates"
                            />
                        </div>
                        <div className="flex justify-start w-full gap-y-2 gap-x-2 items-center xsm:flex-wrap">
                            <div className="relative">
                                <button
                                    className="flex gap-2 xsm:gap-1 items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 text-nowrap py-1 rounded-lg"
                                    onClick={() => setOrderDropdown(!orderDropdown)}
                                    style={{ height: "40px" }}
                                >
                                    <span className="font-medium xsm:text-[14px]">Order By</span>
                                    <img src={orderDropdown ? UpArrow : DownArrow} className="ml-1 mr-1" />
                                </button>
                                {orderDropdown && (
                                    <div
                                        ref={orderDropdownRef}
                                        style={{ maxWidth: "200px", minWidth: "200px" }}
                                        className="absolute left-0 mt-1 z-10"
                                    >
                                        <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px]">
                                            {Object.keys(orderMapping).map((order) => (
                                                <span
                                                    key={order}
                                                    className="flex mt-1 cursor-pointer"
                                                    onClick={() => {
                                                        if (Object.keys(orderMapping).includes(order)) {
                                                            setSelectedOrder(order as OrderKey);
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            selectedOrder === order ? checkImage : unCheckImage
                                                        }
                                                    />
                                                    <span className="ml-2">{order}</span>
                                                </span>
                                            ))}
                                        </div>
                                        <div className="flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px] hover:opacity-90 transition ">
                                            <button
                                                className="primary_button_custom"
                                                onClick={handleApplyOrder}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="relative">
                                <button
                                    onClick={() => setTagListDropdown(!tagListDropdown)}
                                    style={{ height: "40px" }}
                                    className={
                                        `${selectedTags.length > 0 ? "bg-[#F5F1FB] border-[#6E40C5]" : "border-border_color bg-white"} flex items-center  text-black_color gap-2 font-poppins leading-6 border px-4 py-1 rounded-lg`}
                                >
                                    {selectedTags.length > 0 ? (
                                        // const isLongName = selectedTags[0].name.length > 5;
                                        <>
                                            <span className="font-medium text-nowrap xsm:text-[14px]">
                                                {
                                                    screen === "small" &&
                                                    (selectedTags.length > 1 ?
                                                        (selectedTags[0].name.length > 3 ? selectedTags[0].name.slice(0, 3).trim() + "..." : selectedTags[0].name) + ", " +
                                                        (selectedTags[1].name.length > 3 ? selectedTags[1].name.slice(0, 3).trim() + "..." : selectedTags[1].name) +
                                                        (selectedTags.length > 2 ? ", ..." : "")
                                                        :
                                                        ((selectedTags[0].name.length > 3 ? selectedTags[0].name.slice(0, 3) + "..." : selectedTags[0].name)))
                                                    ||
                                                    screen !== "small" &&
                                                    (selectedTags.length > 1 ?
                                                        (selectedTags[0].name.length > 5 ? selectedTags[0].name.slice(0, 5).trim() + "..." : selectedTags[0].name) + ", " +
                                                        (selectedTags[1].name.length > 5 ? selectedTags[1].name.slice(0, 5).trim() + "..." : selectedTags[1].name) +
                                                        (selectedTags.length > 2 ? ", ..." : "")
                                                        :
                                                        ((selectedTags[0].name.length > 5 ? selectedTags[0].name.slice(0, 5) + "..." : selectedTags[0].name)))
                                                }
                                            </span>
                                            {/* <span
                                            className="bg-light_purple text-white font-bold w-[25px] xsm:flex justify-center xsm:p-2 items-center xsm:text-[12px] h-[25px] xsm:w-[18px] xsm:h-[18px]  rounded-full xsm:ml-1 ml-2"
                                            // style={{ minWidth: "25px", minHeight: "18px" }}
                                            >
                                            {selectedTags.length}
                                            </span> */}
                                        </>
                                    ) :
                                        (
                                            <span className="font-medium xsm:text-[14px]">Tags</span>
                                        )
                                    }
                                    <img src={tagListDropdown ? UpArrow : DownArrow} className="ml-1 mr-1" />
                                </button>

                                {tagListDropdown && (
                                    <div
                                        ref={tagListDropdownRef}
                                        // style={{ maxWidth: "300px", minWidth: "300px" }}
                                        // className="absolute w-[50vh] mx-auto mt-1 z-10"
                                        className="absolute xsm:max-w-[250px] xsm:min-w-[200px] max-w-[300px] min-w-[300px] left-0 mt-1 z-10"
                                    >
                                        <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-3 rounded-t-[10px]">
                                            <div className="relative flex items-center border border-border_color p-1.5 rounded-[10px]">
                                                <img
                                                    src={SearchImg}
                                                    alt="Search"
                                                    className="absolute left-3"
                                                    style={{ height: "16px", width: "16px" }}
                                                />
                                                <input
                                                    className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)] outline-none"
                                                    onChange={(e) => searchTags(e.target.value)}
                                                    style={{ fontSize: "14px", width: "100%" }}
                                                    placeholder="Search a list"
                                                />
                                            </div>
                                            <div
                                                className="mt-2"
                                                style={{
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {searchedTags.map((tag) => {
                                                    const isSelected = userSelectedTags.some(
                                                        (selectedList) => selectedList.id === tag.id
                                                    );

                                                    return (
                                                        <span
                                                            key={tag.id}
                                                            className="flex mt-1 cursor-pointer"
                                                            onClick={() => addOrRemoveTag(tag)}
                                                        >
                                                            <img src={isSelected ? checkImage : unCheckImage} />
                                                            <span className="ml-2 xsm:text-[14px]">{tag.name}</span>
                                                        </span>
                                                    );
                                                })}
                                                <span className="md:hidden flex mt-1 ml-1 gap-1 cursor-pointer">
                                                    <img src={Setting} className="w-[15px]" />
                                                    <Link
                                                        to={Navigate_to.manageLists}
                                                        className="text-center ml-2 font-poppins text-secondry_color text-[14px] font-bold underline cursor-pointer"
                                                    >
                                                        Manage Tags
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]">
                                            <button
                                                className={screen === "small" ? "bg-white text-secondry_color font poppins font-bold xsm:text-[14px] border border-secondry_color px-3 py-2 rounded-lg flex ml-3" :
                                                    "font-poppins font-bold text-secondry_color xsm:text-[14px] lg:text-[14px] md:text-[12px] underline leading-normal"}
                                                onClick={handleClear}
                                            >
                                                Clear
                                            </button>
                                            <button
                                                className="bg-secondry_color text-white font-poppins font-bold xsm:text-[14px] border border-secondry_color px-3 py-2 rounded-lg flex ml-3 hover:opacity-90 transition"
                                                onClick={handleApply}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Link
                                to={Navigate_to.manageTags}
                                className=" xsm:ml-1 text-left text-nowrap md:ml-2 xsm:m font-poppins text-secondry_color text-[16px] xsm:text-[14px] font-bold underline cursor-pointer"
                            >
                                Manage Tags
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 xsm:w-full">

                        {deleteTemplateIds.length > 0 ? (
                            <div className="xsm:w-full flex justify-end items-center">
                                <button
                                    className="all_sType_buttons ml-3"
                                    onClick={() => setShowConfirmation(true)}
                                >
                                    Delete Template{deleteTemplateIds.length > 1 && "s"}
                                </button>
                            </div>
                        )
                            :
                            (<button
                                // className={`xsm:hidden sm:hidden md:flex font-bold border text-white px-3 py-2 rounded-lg flex items-center ${isAddButtonDisable ? 'bg-gray-400 border-gray-400' : 'bg-secondry_color border-secondry_color'}`}
                                className={`xsm:hidden sm:hidden md:flex primary_button_custom `}
                                onClick={checkPaymentPastDue}
                                disabled={isAddButtonDisable}
                            >
                                <img src={PlusSvg} style={{ marginRight: "5px" }} /> New Template
                            </button>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}
