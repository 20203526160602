import { useState, useContext, useEffect } from "react";
import { toastAlertError, toastAlertSuccess } from "../../../utils";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import TextInput from "../../../components/TextInput";
import DailogBox from "../../../components/DailogBox";
import { Tags } from "../AddTemplate";
import Loader from "../../../components/Loader";
import { sentryClient } from "@/Sentry/sentry";


export interface CreateTagProps {
    setShowAlert: (status: boolean) => void;
    showAlert: boolean;
    tag?: Tags | null;
    tagToAdd?: string;
    onDataChanged: () => void;
    functionToExecute?: any;
}

export default function CreateTag(props: CreateTagProps) {
    const { setShowAlert, showAlert, tag, onDataChanged, tagToAdd, functionToExecute } = props;
    const { createTag, updateTag } = useContext(AuthContext) as AuthContext_type;
    const [tagName, setTagName] = useState<string>(tag?.name || "");
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (tag) {
            setTagName(tag.name);
        } else if (tagToAdd) {
            setTagName(tagToAdd);
        }
    }, [tag]);

    function handleCloseDialog() {
        setShowAlert(false);
    }

    function handleTagNameChange(value: string) {
        if (value) {
            setError("");
            setTagName(value);
        } else {
            setError("Tag Name is required*");
            setTagName("");
        }
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        try {
            if (tagName.trim() === "") {
                toastAlertError("Tag Name is required");
                setError("Tag Name is required*");
            } else {
                if (tag) {
                    setIsLoading(true);
                    await updateTag(tag.id, tagName.trim());
                    toastAlertSuccess("Tag updated successfully");
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                    const createdTagId = await createTag(tagName.trim());
                    if (createdTagId !== undefined && functionToExecute) {
                        const tagToSend = { id: createdTagId, name: tagName.trim() };
                        functionToExecute(undefined, tagToSend)
                    }
                    if (!tagToAdd) {
                        toastAlertSuccess("Tag created successfully");
                    }
                    setIsLoading(false);
                }
                handleCloseDialog();
                onDataChanged()
            }
        } catch (error: any) {
            toastAlertError(error.message)
            console.log(error, "error");
            setIsLoading(false);
            sentryClient.captureException(error)
        }
    }

    return (
        <DailogBox
            className='flex flex-col items-center justify-center md:max-w-[644px] xsm:w-full'
            open={showAlert}
            onClose={handleCloseDialog}
            dialogBoxClass="xsm:w-full md:w-[30%]"
        >
            <form className="w-[100%]">
                <div className='flex flex-col items-center gap-2 font-poppins'>
                    <h2 className='font-bold xsm:font-black xsm:text-[28px] text-[15px] text-[#333333] md:text-[32px] font-poppins'>
                        {tag ? "Edit Tag" : "Add New Tag"}
                    </h2>
                    <p className="text-[18px] xsm:text-[14px] leading-[27px] w-[85%]">e.g. Potential prospects, Referral partners, Clients, Employees, Team Members</p>
                </div>
                <div className="items start mt-3">
                    <TextInput
                        sx={{ width: "600px" }}
                        className="w-full xsm:text-[14px]"
                        labelClassName="!font-medium text-base xsm:text-[14px] xsm:font-semibold flex max-w-[500px] md:w-[450px] sm:w-[350px] xsm:w-[300px]"
                        placeholder="Ex: Fast-growing"
                        error={error}
                        value={tagName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleTagNameChange(e.target.value);
                        }}
                        focus={true}
                        type="text"
                        label="Tag Name"
                    />
                </div>
                <div className="flex gap-4 mt-4 justify-center xsm:w-full xsm:flex-col-reverse">
                    <button
                        type='button'
                        onClick={() => handleCloseDialog()}
                        // className='font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-transparent text-secondry_color disabled:opacity-50'
                        className="all_dialogue_sType_buttons"

                    >
                        Close
                    </button>
                    <button
                        type='submit'
                        onClick={handleSubmit}
                        // className='font-poppins xsm:text-[14px] xsm:py-3 px-8 py-[7px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50'
                        className="all_dialogue_pType_buttons bg-secondry_color"
                    >
                        {isLoading ? <Loader color="fill-white" size={5} /> : tag ? "Update" : "Save"}
                        {/* {tag ? "Update" : "Save"} */}
                    </button>
                </div>
            </form>
        </DailogBox>
    );
}
