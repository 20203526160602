import React from 'react';

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
  setItemsPerPage: (count: number) => void;
  colSpan: number
}

const Pagination: React.FC<PaginationProps> = ({ itemsPerPage, totalItems, currentPage, paginate, setItemsPerPage, colSpan }) => {
  
  let pageNumbers = [];
  const totalNumberOfPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalNumberOfPages; i++) {
    pageNumbers.push(i);
  }

  if (totalNumberOfPages >= 5) {
    if ((currentPage === totalNumberOfPages) || (currentPage > totalNumberOfPages - 5)) {
      pageNumbers = pageNumbers.slice(totalNumberOfPages - 5, totalNumberOfPages - 1);
    } else if (totalNumberOfPages > 5) {
      pageNumbers = pageNumbers.slice(currentPage - 1, currentPage + 3);
    }
  }

  return (
    <tr style={{ padding: "0px"}}>
    <td colSpan={colSpan} style={{ padding: "0px"}}> 
    <div className="flex items-center justify-between p-2 pl-3 border-t border-gray_dark">
      
      <ul className='flex'>
        <li className="mx-0.5">
          <button 
            onClick={() => currentPage > 1 && paginate(currentPage - 1)} 
            className="px-1 py-1 rounded text-black"
            disabled={currentPage === 1}
          >
            &lt;
          </button>
        </li>
        <div className='flex'>
          {(totalNumberOfPages > 5 && currentPage > 5) && (
            <>
              <li className='mx-0.5'>
                <button 
                  onClick={() => paginate(1)} 
                  className={`px-1 py-1 rounded ${currentPage === 1 ? 'text-black font-bold' : 'text-gray_dark'}`}
                  >
                  1
                </button>
              </li>
              <li className='mx-0.5'>
                <span className='mx-0.5 flex justify-center items-center'>...</span>
              </li>
            </>
          )}
          {pageNumbers.map((number) => (
            <li key={number} className='mx-0.5'>
              <button 
                onClick={() => paginate(number)} 
                className={`px-1 py-1 rounded ${currentPage === number ? 'text-black font-bold' : 'text-gray_dark'}`}
              >
                {number}
              </button>
            </li>
          ))}
          {(totalNumberOfPages >= 5) && (
            <>
              {currentPage <= totalNumberOfPages - 5 &&
                <li className='mx-0.5'>
                  <span className='mx-0.5 flex justify-center items-center'>...</span>
                </li>
              }
              <li className='mx-0.5'>
                <button 
                  onClick={() => paginate(totalNumberOfPages)} 
                  className={`px-1 py-1 rounded ${currentPage === totalNumberOfPages ? 'text-black font-bold' : 'text-gray_dark'}`}
                  >
                  {totalNumberOfPages}
                </button>
              </li>
            </>
          )}
        </div>
        <li className="mx-0.5">
          <button 
            onClick={() => currentPage < totalNumberOfPages && paginate(currentPage + 1)} 
            className="px-1 py-1 rounded text-black"
            disabled={currentPage === totalNumberOfPages}
          >
            &gt;
          </button>
        </li>
      </ul>
      <div className="flex items-center pr-3">
            <label htmlFor="rowsPerPage" style={{width: "100px"}} className="mr-2 text-gray_dark">Rows per page:</label>
            <select 
            id="rowsPerPage" 
            value={itemsPerPage} 
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className="border rounded p-1"
            >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            </select>
        </div>
    </div>
    </td>
    </tr>
  );
};

export default Pagination;