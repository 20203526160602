import CheckIcon from "@/assets/svgs/CheckIcon";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import SeatLimitReachedDialogue from "@/pages/Teams/components/SeatLimitReachedDialogue";
import { toastAlertError } from "@/utils";
import { useCallback, useContext, useState } from "react";
import WaitForUpdateModal from "./WaitForUpdateModal";

export default function DirectUpgradeToTeamsModel() {
 const { updateSubscriptionToTeamsDirectly, setShowModalType, showModalType } = useContext(AuthContext) as AuthContext_type;
 const [isLoading, setIsLoading] = useState(false);
 const [showWaitModal, setShowWaitModal] = useState(false);

 const setShow = useCallback((v: boolean) => {
  setShowModalType((prev) => ({
   ...prev,
   directUpgradeToTeams: v,
  }));
 }, []) as any;

 const upgrade = useCallback(async (seats: number, intervalType: string) => {
  try {
   let period: "yearly" | "monthly" = "yearly";
   if (intervalType.toLowerCase() === "month") period = "monthly";
   setIsLoading(true);
   const data = await updateSubscriptionToTeamsDirectly(period, seats);
   if ("error" in data) {
    toastAlertError(data.error);
   } else {
    setShowWaitModal(true);
   }
   setShow(false);
  } catch (e) {
   console.error(e);
   toastAlertError(e as string);
  } finally {
   setIsLoading(false);
  }
 }, []);

 return (
  <>
   {showWaitModal ? (
    <WaitForUpdateModal
     description='Upgrade successful. Please wait a few minutes for this change to be reflected.'
     title='Congratulations!'
     onClose={() => setShowWaitModal(false)}
     onSubmit={() => setShowWaitModal(false)}
    />
   ) : (
    <SeatLimitReachedDialogue
     show={showModalType.directUpgradeToTeams}
     setShow={setShow}
     onConfirm={upgrade}
     title='How many users do you want to add to your team?'
     description='Please set the number of users below'
     totalUsers={1}
     allowUserIntervalChange={true}
     icon={<CheckIcon />}
     isLoading={isLoading}
    />
   )}
  </>
 );
}
