import { MdAlternateEmail } from "react-icons/md";
import Header from "../components/Header";
import vector from "../assets/vector.svg";
import Button from "../components/Button";
import LodingPageLoader from "../components/LodingPageLoader";

import { useContext, useEffect, useState } from "react";
import { applyActionCode } from "firebase/auth";
import { auth } from "../services/firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { sentryClient } from "@/Sentry/sentry";

const EmailVerification = () => {
  const { emailVerified, setEmailVerified } = useContext(
    AuthContext
  ) as AuthContext_type;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigateTo = useNavigate();

  const handleEmailVerification = async (oobCodeVal: string) => {
    try {
      await applyActionCode(auth, oobCodeVal);
      setEmailVerified(true);
    } catch (error) {
      navigateTo(Navigate_to.emailVerificationFail);
      console.error("Error verifying email", error);
      sentryClient.captureException(error)
    } finally {
      setIsLoading(true);
    }
  };

  // oob code extract from url
  const handleGetQueryParams = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      let code: string | null = queryParams.get("oobCode");
      if (code) {
        await handleEmailVerification(code);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error verifying email", error);
      navigateTo(Navigate_to.emailVerificationFail);
      sentryClient.captureException(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    navigateTo(Navigate_to.afterLogin);
  };

  useEffect(() => {
    handleGetQueryParams();
  }, []);

  if (isLoading) {
    return <LodingPageLoader />;
  }

  if (emailVerified) {
    return <Navigate to={Navigate_to.afterLogin} />;
  }

  return (
    <div className="h-screen container mx-auto">
      <Header />
      <div className="h-[calc(100vh-93px)] py-12 md:items-center justify-center flex mx-auto">
        <div className="w-full max-sm:px-4 max-md:pt-20 md:pb-40">
          <div className="relative flex justify-center py-8">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <MdAlternateEmail className="text-4xl text-white" />
            </div>
            <img src={vector} className="logo text-slate-500" alt="Vite logo" />
          </div>
          <h2 className="text-text_color text-5xl max-sm:text-2xl text-center font-semibold font-lora">
            Please Verify Your email
          </h2>
          <h4 className="text-xl text-center text-text_color mt-5">
            You can verify your email by click on verify email button below
          </h4>
          <div className="mt-12 justify-center flex">
            <Button
              className="btn-custom"
              onClick={handleClick}
              isLoading={isLoading}
              type="button"
              title="Verify Email"
              disabled={isLoading}
            >
              Verify Email
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
