import { useEffect, useState } from "react";

import DailogBox from "../../../components/DailogBox";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";

interface IProps {
  openDialog: boolean;
  title: string;
  items: any[];
  handleClose: () => void;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  selectedItem: string;
}

export const SelectDialogue = ({
  openDialog,
  title,
  items,
  selectedItem,
  setSelectedItem,
  handleClose,
}: IProps) => {
  const [selItem, setSelItem] = useState("");

  useEffect(() => {
    setSelItem(selectedItem);
  }, [selectedItem]);

  return (
    <DailogBox
      className="!gap-6"
      dialogClass="!z-[100]"
      dialogBoxClass="!max-w-[464px] w-full !p-6"
      open={openDialog}
      onClose={handleClose}
    >
      <h2 className="text-[28px] font-black leading-8 text-text_secondary mt-4">
        {title}
      </h2>
      <div className="text-black_color text-sm font-normal font-poppins max-h-[65vh] overflow-y-auto">
        <span
          className="flex  cursor-pointer"
          onClick={() => {
            setSelItem("");
          }}
        >
          <img src={selItem === "" ? checkImage : unCheckImage} />
          <span className="ml-2">None</span>
        </span>
        {items.map((item, index) => (
          <span
            key={index}
            className="flex mt-3 cursor-pointer"
            onClick={() => {
              if (title === "Persona") {
                  setSelItem(item.id);
              } else {
                  setSelItem(item);
              }
            }}
          >
            {title === "Persona" ? 
                <>
                    <img src={selItem === item.id ? checkImage : unCheckImage} />
                    <span className="ml-2">{item?.personaName}</span>
                </> : 
                <>
                    <img src={selItem === item ? checkImage : unCheckImage} />
                    <span className="ml-2">{item}</span>
                </>}
          </span>
        ))}
      </div>
      <div className="flex justify-end mt-2">
        <button
          className="font-poppins font-bold px-6 py-[10px] text-sm text-secondry_color rounded-[10px] border border-purple-900 leading-normal"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-secondry_color text-white text-sm font-bold border px-6 py-[10px] rounded-[10px] leading-normal ml-2"
          onClick={() => {
            setSelectedItem(selItem);
            handleClose();
          }}
        >
          Apply
        </button>
      </div>
    </DailogBox>
  );
};
