import * as Sentry from '@sentry/react'
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
export default function ErrorView() {
  const error = useRouteError()
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div>This is the default Error View</div>
  )
}
