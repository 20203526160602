
const TutorialSection = () => {
  return (
    <section className="pb-24 sm:px-14 px-3 flex items-center flex-wrap sm:flex-nowrap sm:text-left container mx-auto tablet-container">
      <div className=" sm:pt-24 pt-12 mx-auto lg:w-full sm:px-14 px-3 ">
        <h2 className="sm:text-[40px] text-[24px] font-poppins font-extrabold text-text_color">
          Kickstart Your Journey <br/><span className="text-primary_color">With Our Tutorial</span> 
        </h2>
        <p className="text leading-[30px] text-weight-400 pt-4 w-full">
          To get you started, we highly recommend watching our tutorial video below. This will guide you on how to best
          leverage evyAI.com and navigate our exciting features.
        </p>
      </div>
      <div className="flex w-full flex-col items-center justify-center sm:px-14 px-3 md:px-0 mx-auto pt-16 gap-4 iframes-container">
        <div className="iframe-container-mobile sm:w-1/2 w-full">
          <iframe
            className="rounded-[30px] xl:h-[304px] md:h-[250px] w-full h-[160px] bg-white sm:w-[450px] iframe-container"
            src="https://www.youtube.com/embed/pYOmiLdjy_A"
            title="How to Install evyAI on Chrome and start Posting, Engaging, and Messaging"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="iframe-container-mobile sm:w-1/2 w-full">
          <iframe
            className="rounded-[30px] w-full xl:h-[304px] md:h-[250px] h-[160px] sm:mt-0 bg-white sm:w-[450px] iframe-container"
            src="https://www.youtube.com/embed/-Jo8hFfuqfo"
            title="How the evyAI Chrome Extension works"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default TutorialSection;
