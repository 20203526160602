import "../index.css";
import {TERMS_AND_CONDITIONS_PAGE,PRIVACY_POLICY_PAGE, CONTACT_US} from "../constant";
import { GrFacebook, GrInstagram, GrLinkedin, GrTwitter } from "react-icons/gr";

const FooterSection = () => {
  const footerLinks = [
  
    { 
      path: PRIVACY_POLICY_PAGE, 
      link: "Privacy Policy" 
    },
    {
      link: "Terms & Conditions",
      path: TERMS_AND_CONDITIONS_PAGE,
    },
    {
      link: "Contact Us",
      path: CONTACT_US,
    },
  ];

  return (
    <section className="bg-white py-4">
      <div className="sm:px-14 px-3 mx-auto flex md:!justify-between justify-center items-center flex-wrap py-3 gap-6">
        <div className="md:w-1.5/4 flex gap-5 justify-center items-center space-x-4">
         <a href="https://www.linkedin.com/company/evyai/about/" target="_blank" className="text-[20px]"> <GrLinkedin /> </a>
         <a href="https://www.facebook.com/tryevyAI/" target="_blank" className="text-[20px]"> <GrFacebook /> </a>
         <a href="https://www.instagram.com/tryevyai/" target="_blank" className="text-[20px]"> <GrInstagram /> </a>
         <a href="https://twitter.com/tryevyai" target="_blank" className="text-[20px]"> <GrTwitter /> </a>
        </div>
        <div className="md:w-7/4">
          <nav>
            <ul className="flex gap-0 space-x-4 md:justify-between justify-center flex-wrap">
              {footerLinks.map((item,index) => {
                return (
                  <li key={index} className="sm:text !sm:text-[16px] !text-[13px] text-weight-600 text nav-item leading-7 font-poppins text-white">
                    <a
                      href={item.path}
                      className="nav-link"
                    >
                      {item.link}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        <div className="md:w-1.5/10">
          <p className="sm:text !text-[16px] text leading-7 font-poppins text-white sm:text-left text-center">
            © 2024 evyAI LLC
          </p>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
