import { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import SubscriptionPricingCards from '../../../components/SubscriptionDialogPricingCards';
import { SubscriptionType } from '../../../context/Auth/types';
import _ from 'lodash';
import { SubscribedUser, SubscriptionData } from '../Components';
import GoToStripeLinkDialogue from '../../../components/GoToStripeLinkDialogue';
import PaymentMethodPastDueDialogue from '../../../components/PaymentMethodPastDueDialogue';

import priceMonthlyData from "../../../constant/priceMonthly.json"
import priceYearlyData from "../../../constant/priceYearly.json"
// import priceMonthlyTrialData from "../../../constant/priceMonthlyTrial.json"
import teamsMonthlyData from "../../../constant/teamsMonthly.json"
import teamsYearlyData from "../../../constant/teamsYearly.json"
import { SubscriptionStatus } from '../../../config/firebase';
import Loader from '../../../components/Loader';
import DirectUpgradeToTeamsModel from './DirectUpgradeToTeamsModal';
interface SubscriptionPricingProps {
 navigateTo: Function;
}
function SubscriptionPricing(props: SubscriptionPricingProps) {
 const { navigateTo } = props;
 const {
  // getSubscriptionPlans,
  // getEnableTrail,
  goToSubscriptionPlanPaymentURL,
  getUserSubscriptionData,
  getCustomerSubscriptionPortalLink,
  isMSubcribeLinkGenerating,
  subscriptionStatus,
  isStripeLinkGenerating,
  subscriptionData,
  updateTeamSeats,
 } = useContext(AuthContext) as AuthContext_type;

 const [_loading, setLoading] = useState<boolean>(true);
 const [subPlansLoading, setSubPlansLoading] = useState(false);
 const [subscriptonPlans, setSubscriptonPlans] = useState<any[]>([]);
 const [subscribeData, setSubscribeData] = useState<SubscriptionType>();
 const [isEnableTrail, setIsEnableTrail] = useState(false);
 const [showStripeLinkDialogue, setShowStripeLinkDialogue] = useState(false);
 const [showStripeSubscribedLinkDialogue, setShowSubscribedStripeLinkDialogue] = useState(false);
 const [customerSubPortalURL, setCustomerSubPortalURL] = useState<string>();
 const [subPlanPaymentURL, setSubPlanPaymentURL] = useState<string>();
 const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);
 const [totalUsersAllowed, setTotalUsersAllowed] = useState(1);
 const [currentSubPlanToShow, setCurrentSubPlanToShow] = useState(1);
 // const [currentSubPlanToShow, setCurrentSubPlanToShow] = useState<"month" | "year">("year")
 const [currentSubPlanPrices, setCurrentSubPlanPrices] = useState<any[]>([priceYearlyData, teamsYearlyData]);

 const initializeSubscriptionPlan = async () => {
  setSubPlansLoading(true);
  let enableTrail = false;
  if (subscriptionStatus && subscriptionStatus.willGetTrial) {
   enableTrail = subscriptionStatus.willGetTrial;
  }
  setIsEnableTrail(enableTrail);
  const _subscriptionplans2: any[] = [];
  const _monthlyPlans: any[] = [];
  const _yearlyPlans: any[] = [];
  _monthlyPlans.push(priceMonthlyData);
  _monthlyPlans.push(teamsMonthlyData);
  _yearlyPlans.push(priceYearlyData);
  _yearlyPlans.push(teamsYearlyData);
  _subscriptionplans2.push(_monthlyPlans);
  _subscriptionplans2.push(_yearlyPlans);
  setCurrentSubPlanPrices(_monthlyPlans);

  if (!subscriptionData) {
   const _subcribeData = await getUserSubscriptionData();
   console.log(_subcribeData);

   setSubscribeData(_subcribeData);
  }
  setSubscriptonPlans(_subscriptionplans2);
  setSubPlansLoading(false);
 };

 useEffect(() => {
  let enableTrail = false;
  if (subscriptionStatus) {
   setLoading(false);
  }
  if (subscriptionStatus && subscriptionStatus.allowedTeamMembers) {
      setTotalUsersAllowed(subscriptionStatus.allowedTeamMembers + 1)
  }
  if (subscriptionStatus && subscriptionStatus.willGetTrial) {
   enableTrail = subscriptionStatus.willGetTrial;
  }
    setIsEnableTrail(enableTrail)
  }, [subscriptionStatus])


 useEffect(() => {
  initializeSubscriptionPlan();
 }, []);

 useEffect(() => {
  if (subscriptonPlans.length > 0) {
   if (currentSubPlanToShow === 1) {
    setCurrentSubPlanPrices(subscriptonPlans[1]);
   } else if (currentSubPlanToShow === 0) {
    setCurrentSubPlanPrices(subscriptonPlans[0]);
   }
  }
 }, [currentSubPlanToShow, subscriptonPlans, subscriptionStatus]);

 useEffect(() => {
  if (subscriptonPlans && subscriptonPlans.length > 0 && currentSubPlanToShow === 1) {
   setCurrentSubPlanPrices(subscriptonPlans[1]);
  }
 }, [subscriptonPlans]);

 return (
  <div>
   <section>
    <DirectUpgradeToTeamsModel />
    <div>
     {subscriptionStatus ? (
      subscriptionStatus.status === SubscriptionStatus.PRO ? (
       <SubscribedUser
        getCustomerSubscriptionPortalLink={getCustomerSubscriptionPortalLink}
        isLoading={isMSubcribeLinkGenerating}
        subscriptionData={subscriptionData || subscribeData}
        setCustomerSubPortalURL={setCustomerSubPortalURL}
        setShowSubscribedStripeLinkDialogue={setShowSubscribedStripeLinkDialogue}
        subscriptionStatus={subscriptionStatus}
        updateTeamSeats={updateTeamSeats}
        totalUsersAllowed={totalUsersAllowed}
        setTotalUsersAllowed={setTotalUsersAllowed}
        navigateTo={navigateTo}
       />
      ) : subPlansLoading ? (
       <div className=' w-full flex justify-center items-center py-48'>
        <Loader size={16} />
       </div>
      ) : (
       <SubscriptionData isEnableTrail={isEnableTrail}>
        <div className='w-[40%] xsm:w-full font-poppins font-bold mx-auto'>
         <div className='bg-[#F5F1FB] p-1 xsm:py-1 rounded-[35px] flex justify-between items-center'>
          <button
           onClick={() => setCurrentSubPlanToShow(0)}
           className={`${
            currentSubPlanToShow === 0 ? "bg-[#6D40C4] text-white" : "bg-transparent text-black font-semibold"
           } xsm:px-0 xsm:text-[14px] p-3 py-4 rounded-[35px] xsm:w-[90%] w-full `}
          >
           Monthly
          </button>
          <button
           onClick={() => setCurrentSubPlanToShow(1)}
           className={`${
            currentSubPlanToShow === 1 ? "bg-[#6D40C4] text-white" : "bg-transparent text-black font-semibold"
           } xsm:px-0 xsm:text-[14px] p-3 rounded-[35px] w-full flex gap-2 justify-center items-center`}
          >
           Yearly
           <span className='bg-[#FFD572] py-1 xsm:py-1 px-2 xsm:text-[12px] rounded-lg text-black'>Save 50%</span>
          </button>
         </div>
        </div>
        {currentSubPlanPrices && currentSubPlanPrices.length < 0 ? (
         <div className=' w-full flex justify-center items-center py-48'>
          <Loader size={16} />
         </div>
        ) : (
         <section className='xsm:flex xsm:flex-col-reverse flex lg:flex-row justify-center lg:items-start xsm:items-center gap-6 p-0'>
          {currentSubPlanPrices && currentSubPlanPrices.length > 0 && (
           <>
            <SubscriptionPricingCards
             isLoading={isStripeLinkGenerating}
             interval={currentSubPlanToShow === 1 ? "year" : "month"}
            />
            <SubscriptionPricingCards
             id={currentSubPlanPrices[0].id}
             idTeams={currentSubPlanPrices[1].id}
             type={currentSubPlanPrices[0].recurring.interval}
             isLoading={isStripeLinkGenerating}
             onClick={goToSubscriptionPlanPaymentURL}
             price={currentSubPlanPrices[0].unit_amount}
             isEnableTrail={isEnableTrail}
             interval={currentSubPlanToShow === 1 ? "year" : "month"}
             setSubPlanPaymentURL={setSubPlanPaymentURL}
             setShowStripeLinkDialogue={setShowStripeLinkDialogue}
            />
           </>
          )}
         </section>
        )}
       </SubscriptionData>
      )
     ) : (
      <div className=' w w-full flex justify-center items-center py-48'>
       <Loader size={16} />
      </div>
     )}
    </div>
   </section>
   <GoToStripeLinkDialogue
    show={showStripeLinkDialogue}
    setShow={setShowStripeLinkDialogue}
    url={subPlanPaymentURL}
   />
   <GoToStripeLinkDialogue
    show={showStripeSubscribedLinkDialogue}
    setShow={setShowSubscribedStripeLinkDialogue}
    url={customerSubPortalURL}
   />
   <PaymentMethodPastDueDialogue
    show={showUpdatePaymentDialog}
    setShow={setShowUpdatePaymentDialog}
    onConfirm={() => getCustomerSubscriptionPortalLink()}
    isLoading={isMSubcribeLinkGenerating}
   />
  </div>
 );
}

export default SubscriptionPricing;
