import DailogBox from "./DailogBox";
import { ThreeDots } from "react-loader-spinner";
import WarningIcon from "../assets/Images/warningsvg.svg"
import { openInSameTab } from "../utils";
import GoToStripeLinkDialogue from "./GoToStripeLinkDialogue";
import { useEffect, useState } from "react";
import { Navigate_to } from "../router/routes.constants";
import { useNavigate } from "react-router-dom";
import { sentryClient } from "@/Sentry/sentry";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => Promise<
  | {
      error: null;
      url: unknown;
    }
  | {
      error: any;
      url: null;
    }
>;
  isLoading?: boolean;
  show: boolean;
  skipToNavigate?: boolean;
}
const PaymentMethodPastDueDialogue = ({
  setShow,
  show,
  isLoading,
  onConfirm,
  skipToNavigate,
}: IProps) => {

    const navigate = useNavigate();

    const [showStripeSubscribedLinkThisDialogue, setshowStripeSubscribedLinkThisDialogue] = useState(false);
    const [customerSubPortalURLThis, setcustomerSubPortalURLThis] = useState("")
    const [confirmed, setConfirmed] = useState(false);

    const handleClose = () => {
        if (skipToNavigate) {
            navigate(Navigate_to.subscriptionPricing);
            return;
        }
        setConfirmed(false);
        setShow(false);
    }
    
    const handleConfirm = async () => {
        onConfirm().then((res) => {
            const url: string = (res.url as any).url;
                setcustomerSubPortalURLThis(url);
                setshowStripeSubscribedLinkThisDialogue(true);
            openInSameTab(url);
            setConfirmed(false);
          })
          .catch((err) => {
            console.log(err);
            sentryClient.captureException(err)
          })
    }

    useEffect(() => {
        if (confirmed) {
            setshowStripeSubscribedLinkThisDialogue(false);
            setcustomerSubPortalURLThis("");
        }
    }, [confirmed])
    

  return (
    <DailogBox
        open={show}
        onClose={handleClose}
        dialogClass="w- !z-[1000000] flex justify-center items-center"
        dialogBoxClass="flex justify-center items-center"
        >
        <div className="w-[420px] xsm:w-full text-center flex justify-center items-center flex-col sm:text-left gap-4 xsm:gap-6 ">
            <img
                src={WarningIcon}
            />
            <div className="flex justify-between items-center flex-col gap-4 mb-2 border-black w-full xsm:w-[84%]">
                <p className="text-[32px] w-[70%] xsm:font-black xsm:w-full xsm:text-[28px] leading-[48px] text-[#333333] font-poppins xsm:leading-8 text-center text-lg font-extrabold">
                    Payment Method Past Due
                </p>
                <p className="text-[16px] xsm:text-[14px] xsm:leading-5 font-poppins leading-6 text-center text-lg">
                    Please update your payment method to avoid losing your Premium Features
                </p>
            </div>
            <div className=" w-[90%] xsm:w-full items-center flex md:flex-row gap-2 xsm:gap-1 xsm:flex xsm:flex-col-reverse">
                <button
                    className="w-[25%] xsm:w-full xsm:py-3 mt-2 px-6 py-2.5 flex-1 text-center xsm:text-[14px] font-poppins text-[#34317D]  font-bold rounded-[10px] border-[1.5px] border-[#34317D]"
                    onClick={handleClose}
                    >
                    Skip
                </button>
                <button
                    disabled={isLoading}
                    className="w-[75%] h-full xsm:w-full xsm:py-3 mt-2 px-6 py-2.5 text-center xsm:text-[14px] font-poppins text-white bg-[#34317D] border-[1.5px] border-[#34317D] outline-none ring-offset-2 disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
                    onClick={handleConfirm}
                    >
                    {isLoading ?
                        <ThreeDots
                            height={25}
                            width={30}
                            color='#ababab'
                            wrapperStyle={{}}
                            wrapperClass=''
                            visible={true}
                            ariaLabel='three-dots-loading'
                            />
                        :
                        "Update Payment Method"
                    }
                </button>
            </div>
        </div>
        <GoToStripeLinkDialogue
          show={showStripeSubscribedLinkThisDialogue}
          setShow={setshowStripeSubscribedLinkThisDialogue}
          url={customerSubPortalURLThis}
        />
    </DailogBox>
  );
};
export default PaymentMethodPastDueDialogue;
