import Category from '../../assets/svgs/Category';
// import UserIcon from '../../assets/svgs/UserIcon';
import Profile from '../../assets/svgs/SavedProfile'
import PostIcon from "../../assets/svgs/postCreation"
import ProfileOptimizer from "../../assets/svgs/ProfileOptimizer"
import PersonasIcon from "../../assets/svgs/Personas"
import IdeaGenerator from '../../assets/svgs/IdeaGenerator';
import Learning from '../../assets/svgs/Learning';
import Demo from "../../assets/svgs/Demo";

import { PROTECTED_ROUTES } from '../../router/routes.constants';
import { SideBarProps } from './types';
import Templates from '../../assets/svgs/Templates';
import Community from '../../assets/svgs/Community';
import Card from '../../assets/svgs/Card';

export const sidebarnavProps: SideBarProps = {
  navigations: [
    {
      icon: Category,
      link: PROTECTED_ROUTES.home,
      title: 'Home',
    },
    {
      icon: Card,
      link: PROTECTED_ROUTES.subscriptionPricing,
      title: 'Subscription',
    },
    {
      icon: Profile,
      link: PROTECTED_ROUTES.savedProfile,
      title: 'Saved Profiles',
    },
    {
      icon: PostIcon,
      link: PROTECTED_ROUTES.createPost,
      title: 'Post Writer',
    },
    {
      icon: IdeaGenerator,
      link: PROTECTED_ROUTES.ideaGenerator,
      title: 'Idea Generator',
    },
    {
      icon: Templates,
      link: PROTECTED_ROUTES.templates,
      title: 'Templates',
    },
    {
      icon: ProfileOptimizer,
      link: PROTECTED_ROUTES.profileOptimizer,
      title: 'Profile Optimizer',
    },
    {
      icon: PersonasIcon,
      link: PROTECTED_ROUTES.Personas,
      title: 'Personas',
    },
    {
      icon: Learning,
      link: PROTECTED_ROUTES.learningHub,
      title: 'Learning Hub',
    },
    {
      icon: Community,
      link: PROTECTED_ROUTES.community,
      title: 'Community',
    },
    {
      icon: Demo,
      link: PROTECTED_ROUTES.demo,
      title: 'Demo',
    },
    // {
    //   icon: UserIcon,
    //   link: PROTECTED_ROUTES.myAccount,
    //   title: 'My Account',
    // },
  ],
};
