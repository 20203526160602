import { useState, useContext, useEffect, useRef } from "react";
import Check from "../../../assets/Images/primaryCheck.svg";
import Uncheck from "../../../assets/Images/primaryUncheck.svg";
import sortArrow from "../../../assets/Images/Sort.svg"
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { toastAlertSuccess } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { dateFormater } from "../../../utils";
import CreateList from "./CreateList";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { UserLists } from "..";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../../../router/routes.constants";
import Dots from "../../../assets/Images/Dots.svg";
import useScreenSize from "../../../hooks/useScreenSize";

interface ListsTableProps {
    userLists: UserLists[];
    setUserLists: React.Dispatch<React.SetStateAction<UserLists[]>>;
    onDataChanged: () => void;
    setDeleteListsIds: (listIds: string[]) => void;
    deleteListsIds: string[];
    canAddNewList: (lists_count: any) => void;
}

const ListsTable: React.FC<ListsTableProps> = (props) => {
    const { deleteList } = useContext(AuthContext) as AuthContext_type;
    const navigate = useNavigate();
    const { userLists, setUserLists, onDataChanged, setDeleteListsIds, deleteListsIds, canAddNewList } = props;

    const [showListForm, setShowListForm] = useState(false);
    const [editingList, setEditingList] = useState<UserLists | null>(null);
    const [lists, setLists] = useState(userLists);
    const [currentPage, setCurrentPage] = useState(1);
    const [listsPerPage, setListsPerPage] = useState(10);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [toDeleteId, setToDeleteId] = useState<string | null>(null);
    const [dotsDropdown, setDotsDropdown] = useState(-1);

    const dotsDropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

    const screen = useScreenSize();

    const indexOfLastList = currentPage * listsPerPage;
    const indexOfFirstList = indexOfLastList - listsPerPage;
    const currentLists = lists.slice(indexOfFirstList, indexOfLastList);

    const handleClickOutside = (event: MouseEvent) => {
        if (dotsDropdown !== -1 && !dotsDropdownRefs.current[dotsDropdown]?.contains(event.target as Node)) {
            setDotsDropdown(-1);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dotsDropdown, dotsDropdownRefs.current]);

    useEffect(() => {
        setIsAllChecked(currentLists.every(list => deleteListsIds.includes(list.id)));
    }, [deleteListsIds, currentLists]);

    useEffect(() => {
        setLists(userLists);
    }, [userLists]);

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newDeleteListsIds = deleteListsIds.filter(id => !currentLists.map(list => list.id).includes(id));
            setDeleteListsIds(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...deleteListsIds, ...currentLists.map(list => list.id)];
            setDeleteListsIds(newDeleteListsIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    const handleListCheckboxClick = (listId: string) => {
        if (deleteListsIds.includes(listId)) {
            const newDeleteListsIds = deleteListsIds.filter(id => id !== listId);
            setDeleteListsIds(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...deleteListsIds, listId];
            setDeleteListsIds(newDeleteListsIds);
        }
    };


    const handleSetItemsPerPage = (count: number) => {
        setListsPerPage(count);
        setCurrentPage(1);
    };

    const handleEdit = (list: UserLists) => {
        setEditingList(list);
        setShowListForm(true);
    };

    const handleCloseListForm = () => {
        setShowListForm(false);
        setEditingList(null);
    };

    const handleDelete = async (listId: string) => {
        deleteList(listId);
        toastAlertSuccess("List deleted successfully");
        await setUserLists((prevLists: UserLists[]) => prevLists.filter(list => list.id !== listId));
        canAddNewList(userLists.length - 1)
    };

    const confirmDelete = () => {
        if (toDeleteId) {
            handleDelete(toDeleteId);
            setToDeleteId(null);
        }
        setShowConfirmation(false);
    };

    const initiateDelete = (listId: string) => {
        setToDeleteId(listId);
        setShowConfirmation(true);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <div className="mt-4">
            <table className="w-full text-sm text-left bg-white rounded-xl">
                <thead className="font-poppins text-base text-gray_dark w-8 border-b border-[#696A6F] flex-row">
                    <tr>
                        {screen === "small" ?
                            <th scope="col" style={{ width: "12%" }}>
                                <img
                                    src={isAllChecked ? Check : Uncheck}
                                    width={25}
                                    height={25}
                                    onClick={handleHeaderCheckboxClick}
                                    alt="Checkbox"
                                />
                            </th> :
                            <th scope="col" className="px-3 py-3" style={{ width: "4%" }}>
                                <img
                                    src={isAllChecked ? Check : Uncheck}
                                    width={25}
                                    height={25}
                                    onClick={handleHeaderCheckboxClick}
                                    alt="Checkbox"
                                />
                            </th>
                        }

                        {screen === "small" ?
                            <th scope="col" className="py-3 px-3 font-medium text-[16px]" style={{ width: "90%" }}>
                                <div className="flex gap-1">
                                    Name
                                    <img src={sortArrow} />
                                </div>
                            </th> :
                            <th scope="col" className="py-3 px-3" style={{ width: "60%" }}>
                                Name
                            </th>
                        }
                        <th scope="col" className="xsm:hidden py-3 px-3" style={{ width: "40%" }}>
                            Last Modified
                        </th>
                        {screen === "small" ?
                            <th scope="col" className="flex py-3 px-3 font-medium text-[16px]" >
                                <div className="w-full flex justify-end">
                                    Actions
                                </div>
                            </th> :
                            <th scope="col" className="flex py-3 px-3" >
                                <div className="w-full flex justify-end">
                                    Actions
                                </div>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>

                    {currentLists.map((list, index) => {
                        const isChecked = deleteListsIds.includes(list.id);
                        return (
                            <tr
                                key={list.id}
                                className={`${index === lists.length - 1 ? "" : "border-b border-border_color"} ${isChecked ? "bg-chip_bg" : "bg-white"}`}
                            >
                                <td className="px-3 xsm:flex xsm:px-0 xsm:items-start justify-start">
                                    <img
                                        src={isChecked ? Check : Uncheck}
                                        width={25}
                                        height={25}
                                        onClick={() => handleListCheckboxClick(list.id)}
                                    />
                                </td>
                                <td onClick={() => navigate(`${Navigate_to.savedProfiles}?list=${list.id}`)} className="py-3 px-3 cursor-pointer">
                                    <p className="font-poppins xsm:font-bold xsm:text-[14px]">{list.name}</p>
                                    <p className="md:hidden font-poppins text-[12px]">{list.modifiedDate ? dateFormater(list.modifiedDate.seconds) : "Just now"}</p>
                                </td>
                                <td className="xsm:hidden py-3 px-3">
                                    <p>{list.modifiedDate ? dateFormater(list.modifiedDate.seconds) : "Just now"}</p>
                                </td>
                                <td className="flex align-middle py-3 px-3 xsm:justify-end">
                                    <div className="md:hidden relative ml-2">
                                        <img src={Dots} onClick={() => setDotsDropdown(dotsDropdown === index ? -1 : index)} className="cursor-pointer xsm:w-[20px] xsm:h-[20px] " />
                                        {dotsDropdown === index && (
                                            <div ref={(el) => dotsDropdownRefs.current[index] = el} style={{ maxWidth: "100px", minWidth: "100px", top: "22px", right: "0px", overflow: "visible", zIndex: "10" }} className='absolute -mt-1'>
                                                <div className='text-black_color flex flex-col items-start font-poppins xsm:font-medium xsm:text-[14px] bg-white leading-6 border border-border_color p-1 pl-3 rounded-[10px]'>
                                                    <button className='flex justify-start items-center py-2 w-full'
                                                        onClick={() => {
                                                            handleEdit(list)
                                                            setDotsDropdown(-1)
                                                        }}>
                                                        Edit
                                                    </button>
                                                    <button className='flex justify-start items-center py-2 w-full xsm:text-[#EB5757]'
                                                        onClick={() => {
                                                            initiateDelete(list.id)
                                                            setDotsDropdown(-1)
                                                        }}>
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <button className='xsm:hidden underline bg-transparent text-secondry_color font-bold text-underline px-4 py3 rounded-lg'
                                        onClick={() => initiateDelete(list.id)}
                                    >
                                        Delete
                                    </button>
                                    <button className='xsm:hidden bg-transparent text-secondry_color font-bold border border-secondry_color px-4 py-3 rounded-lg ml-3'
                                        onClick={() => handleEdit(list)}>
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    <Pagination
                        colSpan={4}
                        itemsPerPage={listsPerPage}
                        totalItems={lists.length}
                        currentPage={currentPage}
                        paginate={paginate}
                        setItemsPerPage={handleSetItemsPerPage}
                    />
                </tbody>
            </table>
            {showListForm && (
                <CreateList
                    list={editingList}
                    showAlert={showListForm}
                    setShowAlert={handleCloseListForm}
                    onDataChanged={onDataChanged}
                />
            )}
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={confirmDelete}
                header="Delete List"
                content="Are you sure you want to delete this list?"
            />
        </div>

    );
}

export default ListsTable;