import DailogBox from "./DailogBox";
import Check from "../assets/Images/CheckGreen.svg"

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: () => void;
  show: boolean;
}
const TeamInvitationAcceptedDialogue = ({
  setShow,
  show,
  onConfirm
}: IProps) => {
  function handleClose() {
    setShow(false);
  }
  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    }
  }
  return (
    <DailogBox
      dialogClass="!z-[100]"
      className="flex flex-col items-center justify-center !gap-0 !max-w-[450px]"
      open={show}
      onClose={handleClose}
    >
      <div className="flex flex-col items-center gap-2">
        <img src={Check} style={{ width: "54px", height: "60px" }} />
      </div>
      <div className="mt-6 flex flex-col justify-between items-center">
        <h2 className="font-black text-[#333] text-[28px] md:text-[32px] font-poppins">
            Congratulations!
        </h2>
        <p className="text-sm md:text-base font-poppins md:w-[80%] md:leading-[24px] text-[#333] mt-2 fontnormal-">
            You have been added to a team and been granted evyAI Premium
        </p>
      </div>
      <div className=" gap-2 flex items-center justify-center flex-col-reverse md:flex-row w-full mt-8">
        <button
          type="button"
          className="xsm:w-full  px-6 py-[11.5px] text-center text-[16px] text-[#34317D] font-bold rounded-[10px] border border-[#34317D]"
          onClick={() => handleClose()}
        >
          Exit
        </button>
        <button
          type="button"
          className="xsm:w-full md:min-w-[140px] px-6 py-3 text-center  !text-white !bg-[#34317D] disabled:opacity-50 flex justify-center rounded-[10px] font-bold "
        //   onClick={onConfirm}
          onClick={() => onConfirm ? handleConfirm() : handleClose()}
        >
            Go to team
        </button>
      </div>
    </DailogBox>
  );
};
export default TeamInvitationAcceptedDialogue;
