function CopyIcon({ height = 24, width = 24, color = "#34317D" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 24 24'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M5.33083 0.583839C5.71653 0.198141 6.1746 -0.00012207 6.69761 -0.00012207H19.7025C20.2256 -0.00012207 20.6836 0.198141 21.0693 0.583839C21.455 0.969538 21.6533 1.4276 21.6533 1.95062V18.5023C21.6533 19.0254 21.455 19.4834 21.0693 19.8691C20.6836 20.2548 20.2256 20.4531 19.7025 20.4531H6.69761C6.1746 20.4531 5.71653 20.2548 5.33083 19.8691C4.94513 19.4834 4.74687 19.0254 4.74687 18.5023V1.95062C4.74687 1.4276 4.94513 0.969538 5.33083 0.583839ZM6.52027 2.37328C6.52027 2.04191 6.7889 1.77328 7.12027 1.77328H19.2799C19.6112 1.77328 19.8799 2.04191 19.8799 2.37328V18.0797C19.8799 18.4111 19.6112 18.6797 19.2799 18.6797H7.12027C6.7889 18.6797 6.52027 18.4111 6.52027 18.0797V2.37328ZM1.20007 5.11318C1.20007 4.52553 1.67646 4.04914 2.26411 4.04914C2.85177 4.04914 3.32815 4.52553 3.32815 5.11318V21.2718C3.32815 21.6032 3.59678 21.8718 3.92815 21.8718H16.274C16.8616 21.8718 17.338 22.3482 17.338 22.9358C17.338 23.5235 16.8616 23.9999 16.274 23.9999H3.15081C2.6278 23.9999 2.16973 23.8016 1.78403 23.4159C1.39834 23.0302 1.20007 22.5722 1.20007 22.0491V5.11318Z'
    fill={color}
   />
  </svg>
 );
}

export default CopyIcon;
