import { useState } from "react";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../services/firebase";
// import TopAlert from "../components/TopAlert";
import DailogBox from "../components/DailogBox";
import CheckIcon from "../assets/svgs/CheckIcon";

import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // oob code extract from url
  const handleGetQueryParams = (e: React.FormEvent<HTMLFormElement>) => {
    handleValidation();

    e.preventDefault();

    setIsLoading(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowAlert(true);
        setIsLoading(false);
        setEmail("");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
    navigate(Navigate_to.login);
  };

  const handleValidation = () => {
    if (!email) {
      setError("Email is required**");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setError("Invalid email address*");
      return;
    }

    setError("");
  };

  // const handleSignup = async () => {
  //   createUserWithEmailAndPassword(auth, "ahsanabrar58@gmail.com", "pk123456")
  //     .then(async (data) => {
  //       sendEmailVerification(data?.user);
  //     })
  //     .catch((error) => {
  //       if (error.code === "auth/email-already-in-use") {
  //         return toastAlertError("That email address is already in use!");
  //       } else if (error.code === "auth/invalid-email") {
  //         return toastAlertError("That email address is already in use!");
  //       } else {
  //         return toastAlertError("Something went wrong please try again later");
  //       }
  //     });
  // };
  return (
    <>
      <div className="h-screen container mx-auto">
        <Header />
        <hr className="border-t border-[#DFDFDF] md:hidden" />
        <div className="flex items-center justify-center h-[calc(100%-94px)] ">
          <div className="flex flex-col items-center justify-center max-w-[620px] mt-[-7rem] px-4 w-full">
            <h2 className="text-[32px] md:text-5xl text-text_color text-center font-black font-poppins leading-[40px]">
              Forgot your password?
            </h2>
            <p className="font-poppins md:text-xl text-center md:mt-6 text-base mt-4">
              Please enter your email to reset your password
            </p>
            <form className="mt-12 w-full" onSubmit={handleGetQueryParams}>
              <TextInput
                className="input"
                labelClassName="labal"
                placeholder="example@example.com"
                error={error}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                type="text"
                label="Email Address"
              />
              <div className="mt-6 justify-center flex">
                <Button
                  className="bg-secondry_color text-white py-[10px] px-6 rounded-[10px] w-full font-poppins md:text-base  text-sm font-bold items-center justify-center"
                  isLoading={isLoading}
                  type="submit"
                  disabled={isLoading}
                >
                  Send Email
                </Button>
              </div>
            </form>
          </div>
        </div>
        <DailogBox
          className="flex flex-col items-center justify-center !max-w-[464px]"
          open={showAlert}
          onClose={handleCloseDialog}
        >
          <CheckIcon />
          <div className="mt-6 mb-8 flex flex-col items-center gap-2">
            <h2 className="font-bold text-[#333333] text-[32px] font-poppins">
              Check Your Email
            </h2>
            <p className="text-base font-poppins">
              Please check your email for instructions on how to reset your
              password.
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => handleCloseDialog()}
              className="font-poppins  px-6 py-[9px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
            >
              Close
            </button>
          </div>
        </DailogBox>
      </div>
    </>
  );
};

export default ForgetPassword;
