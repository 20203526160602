import { useContext, useEffect, useState } from 'react';
import AddIcon from '../../assets/Images/add_icon.svg'
import CreateTeam from './components/CreateTeam';
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { COLLECTIONS } from '../../config/firebase';
import { db } from '../../services/firebase';
import { CollectionReference, collection } from 'firebase/firestore';
// import { SubscriptionStatus } from '../../config/appConfig';
import Loader from '../../components/Loader';
import AdminTeamsTable from './components/AdminTeamsTable';
import MembersTeamsTable from './components/MembersTeamsTable';
import { IUser } from '../../context/Auth/auth.types';

// import { firebaseDb } from '../../context/Auth';
// import { useSearchParams } from 'react-router-dom';
import TeamInvitationAcceptedDialogue from '../../components/TeamInvitationAcceptedDialogue';
import SomethingWentWrongDialogue from '../../components/SomethingWentWrongDialogue';
import { TeamsDbService } from './services/TeamsDbService';
import { sentryClient } from '@/Sentry/sentry';

interface TeamsProps {
    navigateTo: Function;
}

const Teams: React.FC<TeamsProps> = (props) => {
    // export default function Teams( props:TeamsProps ) {

    const { navigateTo } = props;
    // const [searchParams] = useSearchParams();
    // const teamId = searchParams.get('team_id');
    // const memberId = searchParams.get('member_id');

    const {
        getCurrentUser,
        // getSubscriptionStatus,
        // subscriptionStatus,
        fetchUserDataById,
        // fetchTeamById,
        fetchTeamInvites,
        // acceptTeamInvite,
        // teamInvites,
        // newTeamInvite,
        // fetchUserDataByEmail,
        // fetchMemberById,
        user,
    } = useContext(AuthContext) as AuthContext_type;

    const [showAlert, setShowAlert] = useState(false)
    const [showEditAlert, setShowEditAlert] = useState(false)
    const [dataChanged, setDataChanged] = useState(false);
    // const [showFreeTrail, setShowFreeTrail] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [adminTeam, setAdminTeam] = useState<any[]>([]);
    const [memberTeams, setMemberTeams] = useState<any[]>([]);
    // const [adminData, setAdminData] = useState<IUser>()
    // const [userTeams, setUserTeams] = useState<any[]>([]);
    // const [allUserTeams, setAllUsersTeams] = useState<any[]>([])
    const [teamToEdit, setTeamToEdit] = useState<any | null>(null)
    // const [teamInvitesArr, setTeamInvitesArr] = useState<any[]>([])
    // const [teamInvitesArrTeams, setTeamInvitesArrTeams] = useState<any[]>([])
    // const [setShowInviteNotification] = useState(false)
    // const [acceptLoading, setAcceptLoading] = useState<string[]>([])
    
    const [showInvitationCongrats, setShowInvitationCongrats] = useState(false);
    const [acceptInviteError, setAcceptInviteError] = useState(false);
    // const [inviteDeletedError, setInviteDeletedError] = useState(false)
    // const [inviteDeletedErrorMessage, setInviteDeletedErrorMessage] = useState("")
    const [teamsRef, setTeamsRef] = useState<CollectionReference | null>(null);
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [isLoadingMembersMem, setIsLoadingMembersMem] = useState<string[]>([""]);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
    const [adminTeamFetched, setAdminTeamFetched] = useState(false);
    const [memberTeamsFetched, setMemberTeamsFetched] = useState(false);

    const currentUser: IUser = getCurrentUser();
    // console.log(currentUser);

    const toggleDataChanged = () => {
        setDataChanged((prev) => !prev);
    };

    const handleGetAdminTeam = async () => {
        try {
            setAdminTeamFetched(false);
            if (currentUser) {
                let userTeam = await TeamsDbService.getInstance().getMyTeam(currentUser.uid);
                
                if (userTeam) {
                    setIsAddButtonDisabled(true);
                } else {
                    setIsAddButtonDisabled(false);
                }
                if (userTeam && user) {
                    const adminData: IUser = user;
                    userTeam.adminId = adminData;
                    // console.log("admin Team", userTeam);
                    setAdminTeam([userTeam]);
                    setAdminTeamFetched(true)
                    return [userTeam];
                }                
            }
            setAdminTeamFetched(true)
        } catch (err) {
            setAdminTeamFetched(true);
            console.log(err, "err");
            sentryClient.captureException(err)
        }
    };

    const handleGetMemberTeam = async () => {
        try {
            setMemberTeamsFetched(false);
            if (user) {
                const userId = user.userId || user.uid;
                const latestUserData = await fetchUserDataById(userId)
                let allTeamsMap: any[] = [];
                let allMemberTeams = await TeamsDbService.getInstance().getMemberTeams(latestUserData.memberInTeams !== undefined ? latestUserData.memberInTeams : []);
                if (allMemberTeams) {
                    allTeamsMap = allMemberTeams;
                }
                
                setMemberTeams(allTeamsMap);
                
                setMemberTeamsFetched(true)
                return allTeamsMap;
            }
            setMemberTeamsFetched(true)
        } catch (err) {
            setMemberTeamsFetched(true)
            console.log(err, "err");
            sentryClient.captureException(err)
        }
    };

    // const editTeamName = (name: string) => {
    //     if (name) {
    //         setShowEditAlert(true)
    //     }
    // }

    // const handleAcceptTeamInvite = async (teamId: string) => {
    //     try {
    //         setAcceptLoading([...acceptLoading, teamId]);
    //         const res = await acceptTeamInvite(teamId);
    //         if (res !== undefined && (!res.data.success && res.data.message)) {
    //             setAcceptLoading(prev => prev.filter((id: string) => id !== teamId));
    //             if (teamInvitesArr && teamInvitesArrTeams) {
    //                 setTeamInvitesArr(teamInvitesArr.filter((team: any) => team.teamId !== teamId))
    //                 setTeamInvitesArrTeams(teamInvitesArrTeams.filter((team: any) => team.id !== teamId))
    //             }
    //             setInviteDeletedError(true);
    //             setInviteDeletedErrorMessage(res.message);
    //             return;
    //         }
    //         if (teamInvitesArr && teamInvitesArrTeams) {
    //             setTeamInvitesArr(teamInvitesArr.filter((team: any) => team.teamId !== teamId))
    //             setTeamInvitesArrTeams(teamInvitesArrTeams.filter((team: any) => team.id !== teamId))
    //         }
    //         handleGetMemberTeam();
    //         toggleDataChanged();
    //         // fetchData();
    //         setAcceptLoading(prev => prev.filter((id: string) => id !== teamId));
    //         setShowInvitationCongrats(true);
    //     } catch (error) {
    //         console.log(error);
    //         setAcceptLoading(prev => prev.filter((id: string) => id !== teamId));
    //         setAcceptInviteError(true);
    //     }
    // }

    // const handleFetchTeamById = async (teamId: string) => {
    //     let data = await fetchTeamById(teamId);
    //     const admin = await fetchUserDataById(data.adminId)
    //     data = { ...data, adminId: admin as IUser }
    //     return data;
    // }

    const fetchData = async () => {
        setLoading(true);
        await handleGetAdminTeam();
        await handleGetMemberTeam();
        setLoading(false);
    }

    useEffect(() => {
        if (currentUser) {
            const allTeamsRef = collection(
                db,
                `/${COLLECTIONS.TEAMS}/`
            );
            setTeamsRef(allTeamsRef);
        } else {
            setTeamsRef(null);
        }
    }, [])

    useEffect(() => {
        const fixAdminTeam = async () => {
            if (adminTeam.length > 0) {
                if (adminTeam[0].members === undefined) {
                    let membersData = await TeamsDbService.getInstance().getMembersOfATeam(adminTeam[0].id);
                    membersData = membersData.map((memberData: any) => {
                        if (memberData.name === "undefined undefined") {
                            const newMemberData = {... memberData, name: ""}
                            return newMemberData;
                        } else {
                            return memberData;
                        }
                    })
                    
                    // let membersData = await fetchAllMembers(adminTeam[0].id);
                    const newTeam = {
                        ...adminTeam[0],
                        // membersIds: members,
                        members: membersData,
                    }
                    setAdminTeam([newTeam]);
                }
                setIsLoadingMembers(false);
            }
            // setAdminTeamFetched(true);
        }
        fixAdminTeam();
    }, [adminTeam])

    useEffect(() => {
        console.log("member teams", memberTeams);

        const fixMemberTeam = async () => {
            if (memberTeams.length > 0) {
                memberTeams.map(async (team, index) => {
                    // const loadingIds = isLoadingMembersMem.filter((id) => id !== team.id)
                    if (team.activeMembersIds.length > 0 && team.members === undefined) {
                        setIsLoadingMembersMem([...isLoadingMembersMem, team.id])
                        // let membersData = await fetchAllMembers(team.id);
                        let membersData = await TeamsDbService.getInstance().getMembersOfATeam(team.id);

                        let activeMembers: IUser[] = [];
                        for (const member of membersData) {
                            if (member.status === "joined" && member.memberData) {
                                activeMembers.push(member.memberData);
                            }
                            // const memberData = await fetchUserDataById(id)
                        }
                        // console.log("team from useeffect", team);

                        const newTeam = {
                            ...team,
                            activeMembersIds: activeMembers,
                            members: membersData,
                        }
                        // let membersData = await fetchAllMembers(adminTeam[0].id);
                        // const newTeam = {
                        //     ...adminTeam[0],
                        //     members: membersData,
                        // }
                        memberTeams[index] = newTeam;

                        // setIsLoadingMembersMem(loadingIds);
                        // return newTeam;
                    }
                    // else {
                    //     // setIsLoadingMembersMem(loadingIds);
                    //     return team;
                    // }
                    const loadingIds = isLoadingMembersMem.filter((id) => id !== team.id)
                    setIsLoadingMembersMem(loadingIds);
                })
                // setMemberTeams(newMemberTeams);
                // setIsLoadingMembersMem(false);
            }
            // setMemberTeamsFetched(true)
        }
        fixMemberTeam();
    }, [memberTeams])

    useEffect(() => {
        fetchData();
        // handleGetAdminTeam();
        // handleGetMemberTeam();
    }, [dataChanged]);

    useEffect(() => {
        if (adminTeamFetched && memberTeamsFetched) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [adminTeamFetched, memberTeamsFetched])

    useEffect(() => {
        if (teamsRef) {
            handleGetAdminTeam();
        }
    }, [teamsRef])

    useEffect(() => {
        if (teamsRef) {
            handleGetMemberTeam();
        }
    }, [teamsRef])

    useEffect(() => {
        if (memberTeams.length <= 0) {
            if (user?.memberInTeams && user.memberInTeams.length > 0) {
                handleGetMemberTeam();
            }
        } else {
            const memberInTeams = user?.memberInTeams;
            if (memberInTeams) {
                const willFetchMemberTeamsAgain = memberInTeams.some((teamId: string) => {
                    return memberTeams.some((team: any) => team.id !== teamId);
                })
                if (willFetchMemberTeamsAgain) {
                    handleGetMemberTeam();
                }
            }
        }
    }, [user?.memberInTeams])
    
    // useEffect(() => {

    //     const checkAndHandleInvite = async () => {
    //         if (teamId && memberId) {
    //             const invitedMember = await fetchMemberById(teamId, memberId)
    //             if (invitedMember.email !== currentUser.email) {
    //                 console.log("Not invited User");
    //                 return;
    //             }
    //             if (invitedMember.status === "joined") {
    //                 console.log("Already accepted invite");
    //                 return;
    //             }
    //             if (invitedMember.status === "invite_sent") {
    //                 console.log("Accepting invite");
    //                 handleAcceptTeamInvite(teamId);
    //             }
    //         } else {
    //             return;
    //         }
    //     };

    //     checkAndHandleInvite();
    // }, [searchParams]);

    useEffect(() => {
        if (currentUser) {
            fetchTeamInvites(currentUser.uid);
        }
    }, []);

    // useEffect(() => {
    //     const fetchInvites = async () => {
    //         let teams: any[] = [];
    //         const teamInvitesPromises = teamInvites.map(async (item: any) => {
    //             const data = await handleFetchTeamById(item.teamId)
    //             teams.push(data);
    //         })
    //         await Promise.all(teamInvitesPromises);
    //         setTeamInvitesArrTeams(teams)
    //         setTeamInvitesArr(teamInvites)
    //         if (teams.length > 0 || teamInvites.length > 0) {
    //             setShowInviteNotification(true);
    //         } else {
    //             setShowInviteNotification(false);
    //         }
    //     }
    //     if (teamInvites.length > 0) {
    //         fetchInvites();
    //     }
    // }, [teamInvites]);

    return (
        <>
            <div className={`bg-white xsm:px-4 xsm:min-h-[60vh] ${!(adminTeam.length <= 0 || loading) ? "xsm:justify-between" : "xsm:justify-start"} xsm:py-6 md:p-8 rounded-[16px] flex flex-col gap-6 mb-6 sm:mt-[24px] mt-[16px]`}>
                <div className="w-full xsm:flex xsm:flex-col xsm:gap-2 ">
                    <div className="w-full flex justify-between">
                        <h4 className="font-poppins xsm:text-[16px] xsm:font-bold md:text-[22px] text-[10px] font-bold leading-normal text-text_color">
                            Manage Your Team
                        </h4>
                        {!loading &&
                            adminTeam.length <= 0 &&
                            <button
                                onClick={() => setShowAlert(true)}
                                disabled={isAddButtonDisabled}
                                className="flex xsm:hidden justify-center items-center gap-2 px-6 py-3 bg-[#34317D] text-white rounded-[10px] font-poppins text-[16px] font-bold disabled:bg-[#C0C0C0]"
                            >
                                <img src={AddIcon} />
                                Create Team
                            </button>
                        }
                    </div>
                    <ul className=" font-poppins text-[18px] xsm:text-[14px] xsm:leading-5 leading-7 md:list-inside xsm:ml-6 ml-2 list-disc">
                        <li>Add new team members in the <span onClick={() => navigateTo("2")} className='font-bold underline cursor-pointer'>Manage subscription</span> section</li>
                        <li>Modify your subscription</li>
                        <li>Create a new team and invite members</li>
                    </ul>
                </div>
                
                {!loading ? (
                    <>
                        {(adminTeam.length > 0) &&
                            <AdminTeamsTable
                                setTeamToEdit={setTeamToEdit}
                                setShowEditAlert={setShowEditAlert}
                                team={adminTeam}
                                setAdminTeam={setAdminTeam}
                                navigateTo={navigateTo}
                                isLoadingMembers={isLoadingMembers}
                            />
                        }
                        {memberTeams.length > 0 && memberTeams.map((team) => {
                            return (
                                <MembersTeamsTable
                                    key={team.id}
                                    team={team}
                                    memberTeams={memberTeams}
                                    setMemberTeams={setMemberTeams}
                                    activeMembersIdsArr={team.activeMembersIds}
                                    isLoadingMembers={isLoadingMembersMem.includes(team.id)}
                                />
                            )
                        })}
                    </>
                ) :
                    <div className='flex justify-center items-center w-full'>
                        <Loader size={10} />
                    </div>
                }
                {!(adminTeam.length > 0 || loading) &&
                    <button
                        onClick={() => setShowAlert(true)}
                        disabled={isAddButtonDisabled}
                        className="flex md:hidden justify-center items-center gap-2 px-6 py-3 bg-[#34317D] text-white rounded-[10px] font-poppins xsm:text-[14px] text-[16px] font-bold disabled:bg-[#C0C0C0]"
                    >
                        <img src={AddIcon} className='xsm:w-[14px]' />
                        Create Team
                    </button>
                }
            </div>
            <CreateTeam
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                onDataChanged={toggleDataChanged}
            />
            <CreateTeam
                showAlert={showEditAlert}
                setShowAlert={setShowEditAlert}
                onDataChanged={toggleDataChanged}
                team={teamToEdit}
            />
            <TeamInvitationAcceptedDialogue
                show={showInvitationCongrats}
                setShow={setShowInvitationCongrats}
            />
            <SomethingWentWrongDialogue
                show={acceptInviteError}
                setShow={setAcceptInviteError}
                content="Couldn't accept team invite, please try again!"
            />
            {/* <SomethingWentWrongDialogue
                show={inviteDeletedError}
                setShow={setInviteDeletedError}
                content={inviteDeletedErrorMessage}
            /> */}
        </>
    )
}

export default Teams;
