import Header from '../components/LandingPageHeader';
import WelcomeSection from '../components/WelcomeSection';
import AssistanceSection from '../components/AssistanceSection';
import TutorialSection from '../components/TutorialSectionold';
import ThankYouSection from '../components/ThankYouSection';
import FooterSection from '../components/FooterSection';
import ReadyToUpgrade from '../components/ReadyToUpgrade';
import InstallationPopup from '../components/installationPopup';
import { useEffect, useState } from 'react';


const WelcomePage = () => {
  const [showInstallationPopup, setShowInstallationPopup] = useState(false)

  useEffect(()=>{
    // get query param 'source' and check if it is 'installation'
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    if(source === 'installation'){
      setShowInstallationPopup(true)
    }
  },[])
  return (
    <div className="overflow-x-hidden">
      <Header />
      <WelcomeSection />
      <TutorialSection />
      <AssistanceSection />
      <ThankYouSection />
      <ReadyToUpgrade/>
      <FooterSection />      
      <InstallationPopup showPopup={showInstallationPopup} />
    </div>
  );
};

export default WelcomePage;
