import Header from "../components/Header";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import useResetPassword from "./Auth/hooks/useResetPassword";
import { Controller } from "react-hook-form";
import TickIcon from "../assets/Icons/CheckIcon.svg";
import CheckIconGrey from "../assets/Icons/CheckIconGrey.svg";

const Requirement = ({
  satisfied,
  text,
}: {
  satisfied?: boolean;
  text: string;
}) => (
  <p
     className={`text-base font-normal flex items-center gap-x-2 font-poppins  mt-1 px-1 ${
      !satisfied ? "text-[#696A6F]" : "text-[#333]"
    }`}
  >
    <img
      src={satisfied ? TickIcon : CheckIconGrey}
      className="cursor-pointer"
      alt="Trash"
    />
    {text}
  </p>
);

const ResetPassword = () => {
  const { formControl, formErrors, isLoading, onSubmit, handleSubmit } =
    useResetPassword();

  return (
    <div className="h-screen container mx-auto">
      <Header />
      <hr className="border-t border-[#DFDFDF] md:hidden" />
      <div className="h-[calc(100vh-93px)] py-12 md:items-center justify-center flex max-w-[568px] mx-auto">
        <div className="w-full max-sm:px-4 max-md:pt-16 max-sm:pt-8 md:pb-28">
          <h2 className="text-[32px] md:text-5xl text-text_color text-center font-black font-poppins leading-[40px]">
            Reset your password
          </h2>
          <p className="font-poppins md:text-xl text-center md:mt-6 text-base mt-4">
            Please enter a new password to reset the password on your account
          </p>
          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="md:mt-8 mt-4">
              <Controller
                name="password"
                render={({ field }) => (
                  <div>
                    <TextInput
                      {...field}
                      id="password"
                      labelClassName="labal"
                      className="input"
                      name="password"
                      type="password"
                      placeholder="••••••••••••"
                      label={"Enter new Password"}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2  gap-1 mt-4">
                      <Requirement
                        satisfied={field.value.length >= 8}
                        text="8 characters minimum"
                      />
                      <Requirement
                        satisfied={!!field.value.match(/\d/)}
                        text="One number"
                      />
                      <Requirement
                        satisfied={!!field.value.match(/[A-Z]/)}
                        text="One upper case letter"
                      />
                      <Requirement
                        satisfied={
                          !!field.value.match(/[!@#$%^&*(),.?":{}|<>]/)
                        }
                        text="One special character"
                      />
                      <Requirement
                        satisfied={!!field.value.match(/[a-z]/)}
                        text="One lower case letter"
                      />
                    </div>
                  </div>
                )}
                control={formControl}
              />
            </div>
            <div className="md:mt-8 mt-4">
              <Controller
                name="confirmPassword"
                render={({ field }) => (
                  <TextInput
                    error={formErrors.confirmPassword?.message}
                    {...field}
                    labelClassName="labal"
                    className="input"
                    id="repeat-password"
                    name="repeat-password"
                    type="password"
                    placeholder="••••••••••••"
                    label={"Confirm new Password"}
                  />
                )}
                control={formControl}
              />
            </div>

            <div className="mt-5 justify-center flex">
              <Button
                isLoading={isLoading}
                className="bg-secondry_color text-white py-[10px] px-6 rounded-[10px] w-full font-poppins md:text-base  text-sm font-bold items-center justify-center"
                type="submit"
                disabled={isLoading ? true : false}
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
