import { useState, useContext, useEffect, useRef } from "react";
import Check from "../../../assets/Images/primaryCheck.svg";
import Uncheck from "../../../assets/Images/primaryUncheck.svg";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { toastAlertSuccess } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { dateFormater } from "../../../utils";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { Navigate_to } from "../../../router/routes.constants";
import Dots from "../../../assets/Images/Dots.svg";
import useScreenSize from "../../../hooks/useScreenSize";
import CreateTag from "../../AddTemplate/components/CreateTag";
import { Tags } from "../../AddTemplate/AddTemplate";

interface TagsTableProps {
    userTags: Tags[];
    setUserTags: React.Dispatch<React.SetStateAction<Tags[]>>;
    onDataChanged: () => void;
    setDeleteTagsIds: (listIds: string[]) => void;
    deleteTagsIds: string[];
    canAddNewTag: (tags_count: any) => void;
}

const TagsTable: React.FC<TagsTableProps> = (props) => {
    const { deleteTag } = useContext(AuthContext) as AuthContext_type;
    const navigate = useNavigate();
    const { userTags, setUserTags, onDataChanged, setDeleteTagsIds, deleteTagsIds, canAddNewTag } = props;

    const [showTagForm, setShowTagForm] = useState(false);
    const [editingTag, setEditingTag] = useState<Tags | null>(null);
    const [tags, setTags] = useState(userTags);
    const [currentPage, setCurrentPage] = useState(1);
    const [tagsPerPage, settagsPerPage] = useState(10);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [toDeleteId, setToDeleteId] = useState<string | null>(null);
    const [dotsDropdown, setDotsDropdown] = useState(-1);

    const dotsDropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

    const screen = useScreenSize();

    const indexOfLastTag = currentPage * tagsPerPage;
    const indexOfFirstTag = indexOfLastTag - tagsPerPage;
    const currentTags = tags.slice(indexOfFirstTag, indexOfLastTag);

    const handleClickOutside = (event: MouseEvent) => {
        if (dotsDropdown !== -1 && !dotsDropdownRefs.current[dotsDropdown]?.contains(event.target as Node)) {
            setDotsDropdown(-1);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dotsDropdown, dotsDropdownRefs.current]);

    useEffect(() => {
        setIsAllChecked(currentTags.every(tag => deleteTagsIds.includes(tag.id)));
    }, [deleteTagsIds, currentTags]);

    useEffect(() => {
        setTags(userTags);
    }, [userTags]);

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newDeleteTagsIds = deleteTagsIds.filter(id => !currentTags.map(tag => tag.id).includes(id));
            setDeleteTagsIds(newDeleteTagsIds);
        } else {
            const newDeleteTagsIds = [...deleteTagsIds, ...currentTags.map(tag => tag.id)];
            setDeleteTagsIds(newDeleteTagsIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    const handleListCheckboxClick = (tagId: string) => {
        if (deleteTagsIds.includes(tagId)) {
            const newDeleteTagsIds = deleteTagsIds.filter(id => id !== tagId);
            setDeleteTagsIds(newDeleteTagsIds);
        } else {
            const newDeleteTagsIds = [...deleteTagsIds, tagId];
            setDeleteTagsIds(newDeleteTagsIds);
        }
    };


    const handleSetItemsPerPage = (count: number) => {
        settagsPerPage(count);
        setCurrentPage(1);
    };

    const handleEdit = (tag: Tags) => {
        setEditingTag(tag);
        setShowTagForm(true);
    };

    const handleCloseTagForm = () => {
        setShowTagForm(false);
        setEditingTag(null);
    };

    const handleDelete = async (tagId: string) => {
        if (deleteTagsIds.includes(tagId)) {
            const newDeleteTagsIds = deleteTagsIds.filter(id => id !== tagId);
            setDeleteTagsIds(newDeleteTagsIds);
        }
        deleteTag(tagId);
        toastAlertSuccess("Tag deleted successfully");
        await setUserTags((prevTags: Tags[]) => prevTags.filter(tag => tag.id !== tagId));
        canAddNewTag(userTags.length - 1)
    };

    const confirmDelete = () => {
        if (toDeleteId) {
            handleDelete(toDeleteId);
            setToDeleteId(null);
        }
        setShowConfirmation(false);
    };

    const initiateDelete = (tagId: string) => {
        setToDeleteId(tagId);
        setShowConfirmation(true);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <div className="mt-4">
            <table className="w-full text-sm text-left bg-white rounded-xl">
                <thead className="font-poppins text-base text-gray_dark w-8 border-b border-[#696A6F] flex-row">
                    <tr>
                        {screen === "small" ?
                            <th scope="col" style={{ width: "12%" }}>
                                <img
                                    src={isAllChecked ? Check : Uncheck}
                                    width={25}
                                    height={25}
                                    onClick={handleHeaderCheckboxClick}
                                    alt="Checkbox"
                                />
                            </th> :
                            <th scope="col" className="px-3 py-3" style={{ width: "4%" }}>
                                <img
                                    src={isAllChecked ? Check : Uncheck}
                                    width={25}
                                    height={25}
                                    onClick={handleHeaderCheckboxClick}
                                    alt="Checkbox"
                                />
                            </th>
                        }

                        {screen === "small" ?
                            <th scope="col" className="py-3 px-3 font-medium text-[16px]" style={{ width: "90%" }}>
                                <div className="flex gap-1">
                                    Name
                                </div>
                            </th> :
                            <th scope="col" className="py-3 px-3" style={{ width: "60%" }}>
                                Name
                            </th>
                        }
                        <th scope="col" className="xsm:hidden py-3 px-3" style={{ width: "40%" }}>
                            Last Modified
                        </th>
                        {screen === "small" ?
                            <th scope="col" className="flex py-3 font-medium text-[16px] px-3">
                                <div className="w-full flex justify-end">
                                    Actions
                                </div>
                            </th> :
                            <th scope="col" className="flex py-3 px-3" >
                                <div className=" w-full flex justify-end">
                                    Actions
                                </div>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>

                    {currentTags.map((tag, index) => {
                        const isChecked = deleteTagsIds.includes(tag.id);
                        return (
                            <tr
                                key={tag.id}
                                className={`${index === tags.length - 1 ? "" : "border-b border-border_color"} ${isChecked ? "bg-chip_bg" : "bg-white"}`}
                            >
                                <td className="px-3 xsm:flex xsm:px-0 xsm:items-start justify-start">
                                    <img
                                        src={isChecked ? Check : Uncheck}
                                        width={25}
                                        height={25}
                                        onClick={() => handleListCheckboxClick(tag.id)}
                                    />
                                </td>
                                <td onClick={() => navigate(`${Navigate_to.templates}?tag=${tag.id}`)} className="py-3 px-3 cursor-pointer">
                                    <p className="font-poppins xsm:font-bold xsm:text-[14px]">{tag.name}</p>
                                    <p className="md:hidden font-poppins text-[12px]">{tag.modifiedDate ? dateFormater(tag.modifiedDate.seconds) : "Just now"}</p>
                                </td>
                                <td className="xsm:hidden py-3 px-3">
                                    <p>{tag.modifiedDate ? dateFormater(tag.modifiedDate.seconds) : "Just now"}</p>
                                </td>
                                <td className="flex align-middle py-3 px-3 xsm:justify-end">
                                    <div className="md:hidden relative ml-2">
                                        <img src={Dots} onClick={() => setDotsDropdown(dotsDropdown === index ? -1 : index)} className="cursor-pointer xsm:w-[20px] xsm:h-[20px] " />
                                        {dotsDropdown === index && (
                                            <div ref={(el) => dotsDropdownRefs.current[index] = el} style={{ maxWidth: "100px", minWidth: "100px", top: "22px", right: "0px", overflow: "visible", zIndex: "10" }} className='absolute -mt-1'>
                                                <div className='text-black_color flex flex-col items-start font-poppins xsm:font-medium xsm:text-[14px] bg-white leading-6 border border-border_color p-1 pl-3 rounded-[10px]'>
                                                    <button className='flex justify-start items-center py-2 w-full'
                                                        onClick={() => {
                                                            handleEdit(tag)
                                                            setDotsDropdown(-1)
                                                        }}>
                                                        Edit
                                                    </button>
                                                    <button className='flex justify-start items-center py-2 w-full xsm:text-[#EB5757]'
                                                        onClick={() => {
                                                            initiateDelete(tag.id)
                                                            setDotsDropdown(-1)
                                                        }}>
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <button className='xsm:hidden underline bg-transparent text-secondry_color font-bold text-underline px-4 py3 rounded-lg'
                                        onClick={() => initiateDelete(tag.id)}
                                    >
                                        Delete
                                    </button>
                                    <button className='xsm:hidden all_sType_buttons ml-3'
                                        onClick={() => handleEdit(tag)}>
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    <Pagination
                        colSpan={4}
                        itemsPerPage={tagsPerPage}
                        totalItems={tags.length}
                        currentPage={currentPage}
                        paginate={paginate}
                        setItemsPerPage={handleSetItemsPerPage}
                    />
                </tbody>
            </table>
            {showTagForm && (
                <CreateTag
                    tag={editingTag}
                    showAlert={showTagForm}
                    setShowAlert={handleCloseTagForm}
                    onDataChanged={onDataChanged}
                />
            )}
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={confirmDelete}
                header="Delete Tag"
                content="Are you sure you want to delete this tag?"
            />
        </div>

    );
}

export default TagsTable;