import { META_DATA } from "@/constant";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export interface MetaDataType {
 title: string;
 description?: string;
 keywords?: string;
 robots?: string;
}

const MetaDataComponent: React.FC = () => {
 const location = useLocation();
 const metaData: MetaDataType = META_DATA[location.pathname as keyof typeof META_DATA] || {};

 return (
  <HelmetProvider>
   <Helmet>
    <title>{metaData.title || "evyAI"}</title>
    <meta
     name='description'
     content={metaData.description || "Default description"}
    />
    <meta
     name='keywords'
     content={metaData.keywords || "evyAI, keywords"}
    />
    {metaData.robots && (
     <meta
      name='robots'
      content={metaData.robots}
     />
    )}
   </Helmet>
  </HelmetProvider>
 );
};

export default MetaDataComponent;
