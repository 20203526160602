import DailogBox from "./DailogBox";
import CheckIcon from "../assets/svgs/CheckIcon";

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}
const EmailSentDialogue = ({
  setShow,
  show,
}: IProps) => {
  function handleClose() {
    setShow(false);
  }
  return (
    <DailogBox
        className="flex flex-col items-center justify-center !max-w-[464px]"
        open={show}
        onClose={() => setShow(false)}
      >
        <CheckIcon />
        <div className="mt-6 mb-8 flex flex-col items-center gap-2">
          <h2 className="xsm:font-black font-bold text-[#333333] text-[32px] font-poppins">
            Verify Your Email
          </h2>
          <p className="xsm:font-normal xsm:text-[16px] xsm:w-[90%] text-base font-poppins">
            Please check your email to verify your email address.
          </p>
        </div>
        <div className="xsm:w-full w-full flex justify-center items-center">
          <button
            type="button"
            onClick={() => handleClose()}
            className="xsm:w-full md:w-[30%] font-poppins  px-6 py-[9px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
          >
            Close
          </button>
        </div>
      </DailogBox>
  );
};
export default EmailSentDialogue;
