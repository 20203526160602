import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { AuthContext, AuthContext_type } from "../../context/Auth/context";

import copyIcon from "../../assets/Images/Copy.svg";
import EmptyStarIcon from "../../assets/Images/blue-empty-star.svg";
import FillStarIcon from "../../assets/Images/blue-star-fill.svg";
import DownArrow from "../../assets/svgs/DownArrow";
import DownArrowBlack from "../../assets/Images/DownArrowBlack.svg";
import checkImage from "../../assets/Images/listCheck.svg";
import TemplatesIcon from "../../assets/Images/TemplatesIcon.svg";
import SearchIcon from "../../assets/Images/Search.svg";
import PlusIcon from "../../assets/Images/Plus.svg";
import LinkIcon from "../../assets/Images/LinkIconBlack.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../router/routes.constants";
import { toastAlertSuccess, toastAlertError } from "../../utils";
import { AiFillLock } from "react-icons/ai";

import resetimg from "../../assets/Images/Reset.png";
import {
  API_REQUEST_TYPE,
  POSTING_GOALS,
  PARAMETER_KEY,
  SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE,
  STORAGE_KEYS,
  SubscriptionStatus,
  TONES,
  TextGenerationInfo,
} from "../../config/appConfig";
import Loader from "../../components/Loader";
// import './../../CustomStyling/CustomReusablestyling.css';

import Button from "../../components/Button";
import arrowRight from "../../assets/Images/arrowleft.png";
import arrowLeft from "../../assets/Images/arrowright.png";

import { nanoid } from "nanoid";
import UpgradeSubscriptionDia from "../../components/UpgradeSubscriptionDia";
import { IPersona } from "../Persona";
import useScreenSize from "../../hooks/useScreenSize";
import CopyIcon from "../../assets/svgs/CopyIcon";
import { SelectDialogue } from "./components/selectDialogue";
import LeftArrow from "../../assets/svgs/LeftArrow";
import FlagSelector, { LanguageKey } from "../../components/FlagSelector";
import UpgradeBanner from "../LearningHub/components/UpgradeBanner";
import { UsageCompleteDisabledWrapper } from "../../utils/UsageCompleteDisabledWrapper";
import { SubscriptionStatusDB } from "../../config/firebase";
import PaymentMethodPastDueDialogue from "../../components/PaymentMethodPastDueDialogue";
import { ITemplate } from "../templates";
import FreeTrailEnd from "../../components/FreeTrailEnd";
import { sentryClient } from "@/Sentry/sentry";

const PostComponent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const generatedInfo = state?.generatedTextInfo;
  const selectedPersonaId = state?.selectedPersona;
  const selectedIdea = state?.selectedIdea;

  const {
    fetchSuggestedComments,
    subscriptionStatus,
    addCommandData,
    deleteCommandData,
    user,
    getPersonasList,
    getTemplates,
    getCustomerSubscriptionPortalLink,
    isMSubcribeLinkGenerating,
    getSubscriptionStatus,
  } = useContext(AuthContext) as AuthContext_type;


  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const [textareaVal, setTextareaVal] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [personas, setPersona] = useState<IPersona[]>([]);
  const [selectedPersona, setSelectedPersona] = useState("");

  const [errorText, setErrorText] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedTone, setSelectedTone] = useState("");
  const [commandText, setCommandText] = useState("");
  const [templates, settemplates] = useState<ITemplate[]>([]);
  const [selectedTemplate, setselectedTemplate] = useState("");

  const [generatedTextsHistory, setGeneratedTextsHistory] = useState<TextGenerationInfo[]>([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isUpdatingSaveCommandStatus, setIsUpdatingSaveCommandStatus] = useState(false);
  const [savedDocumentIdsOfGeneratedTextHistory, setSavedDocumentIdsOfGeneratedTextHistory] = useState<string[]>([]);
  const [postCreationText, setPostCreationText] = useState("");
  const [showPreDialog, setShowPreDialog] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  const [openGoalDialog, setOpenGoalDialog] = useState(false);
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [openPersonaDialog, setOpenPersonaDialog] = useState(false);
  const [openTemplateDialog, setopenTemplateDialog] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [isTrailFinished, setIsTrailFinished] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageKey>("Eng");

  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);

  const [showTemplateSelectDropDown, setShowTemplateSelectDropDown] = useState(false);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);
  const [templateSearchField, setTemplateSearchField] = useState("");
  const [searchedTemplates, setSearchedTemplates] = useState<ITemplate[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const templatesDropdownRef = useRef<HTMLDivElement | null>(null);
  const [showUsageCompleteBanner, setShowUsageCompleteBanner] = useState(false)
  const [componentsLoading, setComponentsLoading] = useState({
    personas: false,
  })

  const handleClose = () => {
    setOpenGoalDialog(false);
    setOpenToneDialog(false);
    setOpenPersonaDialog(false);
    setopenTemplateDialog(false);
  };

  const screen = useScreenSize();

  const gptLengths = [
    {
      label: <span>GPT4o-mini</span>,
      value: "gpt_3.5",
    },
    {
      label: (
        <span className="flex gap-x-2">
          GPT4o{" "}
          {(subscriptionStatus?.status === SubscriptionStatus.FREE || subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE) && (
            <AiFillLock />
          )}{" "}
        </span>
      ),
      value: "gpt_4",
    },
  ];
  const initialState = {
    textareaVal: "",
    isLoading: false,
    personas: [],
    errorText: "",
    selectedPersona: localStorage.getItem(STORAGE_KEYS.LAST_PERSONA_USED) || "",
    selectedGoal: localStorage.getItem(STORAGE_KEYS.LAST_GOAL_USED) || POSTING_GOALS[0] || "",
    selectedTone: localStorage.getItem(STORAGE_KEYS.LAST_TONE_USED) || TONES[0] || "",
    gptType: localStorage.getItem(STORAGE_KEYS.LAST_GPT_VERSION_USED) || gptLengths[0].value,
    commandText: "",
    generatedTextsHistory: [],
    currentHistoryIndex: 0,
    isFavorite: false,
    isUpdatingSaveCommandStatus: false,
    savedDocumentIdsOfGeneratedTextHistory: [],
    postCreationText: "",
    showPreDialog: false,
    isExpand: false,
    openGoalDialog: false,
    openToneDialog: false,
    openPersonaDialog: false,
    showBanner: false,
    isTrailFinished: false,
    selectedLanguage: "Eng",
    showUpdatePaymentDialog: false,
    isButtonVisible: true,
  };

  const handleFetchPersonas = async () => {
    try {
      setComponentsLoading(prev => ({ ...prev, personas: true }))
      const res = await getPersonasList();
      setPersona(res);
    } catch (error) {
      console.error(error)
      toastAlertError('Failed fetching personas');
      sentryClient.captureException(error)
    } finally {
      setComponentsLoading(prev => ({ ...prev, personas: false }))
    }
  };

  const resetState = async () => {
    setTextareaVal(initialState.textareaVal);
    setIsLoading(initialState.isLoading);
    setPersona([]);
    setSelectedPersona(initialState.selectedPersona);
    setErrorText(initialState.errorText);
    setSelectedGoal(initialState.selectedGoal);
    setSelectedTone(initialState.selectedTone);
    setCommandText(initialState.commandText);
    setGeneratedTextsHistory(initialState.generatedTextsHistory);
    setCurrentHistoryIndex(initialState.currentHistoryIndex);
    setIsFavorite(initialState.isFavorite);
    setIsUpdatingSaveCommandStatus(initialState.isUpdatingSaveCommandStatus);
    setSavedDocumentIdsOfGeneratedTextHistory(initialState.savedDocumentIdsOfGeneratedTextHistory);
    setPostCreationText(initialState.postCreationText);
    setShowPreDialog(initialState.showPreDialog);
    setIsExpand(initialState.isExpand);
    setOpenGoalDialog(initialState.openGoalDialog);
    setOpenToneDialog(initialState.openToneDialog);
    setOpenPersonaDialog(initialState.openPersonaDialog);
    setShowBanner(initialState.showBanner);
    setIsTrailFinished(initialState.isTrailFinished);
    // setSelectedLanguage(initialState.selectedLanguage);
    setShowUpdatePaymentDialog(initialState.showUpdatePaymentDialog);
    setIsButtonVisible(initialState.isButtonVisible);
    setGptType(initialState.gptType);
    await handleFetchPersonas()
  };


  const handleFetchTemplates = async () => {
    try {
      setIsLoadingTemplates(true);
      const res = await getTemplates();
      settemplates(res);
      setSearchedTemplates(res);
      // console.log("templates response : ", res)
      setIsLoadingTemplates(false);
    } catch (error) {
      console.log(error);
      setIsLoadingTemplates(false);
      sentryClient.captureException(error)
    }
  };

  useEffect(() => {
    // const setInitialGoalAndToneAndGPT = async () => {
    //   try {
    //     const lastUsedGoal =  localStorage.getItem(
    //       STORAGE_KEYS.LAST_GOAL_USED
    //     );
    //     const lastPersonaUsed =  localStorage.getItem(
    //       STORAGE_KEYS.LAST_PERSONA_USED
    //     );

    //     if (lastUsedGoal !== null && lastUsedGoal?.length > 0) {
    //       setSelectedGoal(lastUsedGoal);
    //     } else {
    //       setSelectedGoal(POSTING_GOALS[0]);
    //     }
    //     if (lastPersonaUsed !== null && lastPersonaUsed?.length > 0) {
    //       setSelectedPersona(lastPersonaUsed);
    //     }

    //     const lastUsedTone = localStorage.getItem(
    //       STORAGE_KEYS.LAST_TONE_USED
    //     );
    //     if (lastUsedTone !== null && lastUsedTone?.length > 0) {
    //       setSelectedTone(lastUsedTone);
    //     } else {
    //       setSelectedTone(TONES[0]);
    //     }

    //     const lastUsedGPTVersion = localStorage.getItem(
    //       STORAGE_KEYS.LAST_GPT_VERSION_USED
    //     );
    //     if (lastUsedGPTVersion !== null && lastUsedGPTVersion?.length > 0) {
    //       setGptType(lastUsedGPTVersion)
    //     }

    //   } catch (error) {
    //     console.log("### Error while getting data from local storage");
    //     console.log({ error });
    //   }
    // };
    // setInitialGoalAndToneAndGPT();
    if(!generatedInfo){
      resetState()
    }else {
      handleFetchPersonas()
    }
    // handleFetchTemplates();
  }, []);

  useEffect(() => {
    setCurrentHistoryIndex(Math.max(0, generatedTextsHistory.length - 1));
  }, [generatedTextsHistory]);

  useEffect(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      setCommandText(generatedTextsHistory[currentHistoryIndex].activityData.command);
      setSelectedGoal(generatedTextsHistory[currentHistoryIndex].activityData.goal);
      setSelectedTone(generatedTextsHistory[currentHistoryIndex].activityData.tone);
    }
  }, [generatedTextsHistory, currentHistoryIndex]);

  const [gptType, setGptType] = useState(gptLengths[0].value);

  useEffect(() => {
    if (!!generatedInfo) {
      setGeneratedTextsHistory((previousHistory) => [...previousHistory, generatedInfo]);
      setTextareaVal(generatedInfo.generatedText);
      setSavedDocumentIdsOfGeneratedTextHistory((previousItems) => [...previousItems, ""]);
      setSelectedGoal("");
      setSelectedTone("");
    }
  }, [generatedInfo]);

  useEffect(() => {
    if (selectedPersonaId) {
      setSelectedPersona(selectedPersonaId);
    }
  }, [selectedPersonaId]);

  useEffect(() => {
    console.log(selectedTemplate, "SELECTED");

    if (selectedTemplate.length > 0) {
      const templateToPut = templates.find((template) => template.id === selectedTemplate);
      if (templateToPut) {
        setPostCreationText(`${postCreationText.length > 0 ? postCreationText + `\n${templateToPut.templateContent}` : templateToPut.templateContent}`);
      }
      setselectedTemplate("");
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedIdea) {
      setCommandText(selectedIdea);
    }
  }, [selectedIdea]);

  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    setErrorText("");
    const data: any = {};
    data[PARAMETER_KEY.GOAL] = selectedGoal;
    data[PARAMETER_KEY.TONE] = selectedTone;
    data[PARAMETER_KEY.AUTHOR_NAME] = "";
    data[PARAMETER_KEY.COMMAND] = commandText;
    data[PARAMETER_KEY.TYPE] = API_REQUEST_TYPE.CREATE_POST;
    data[PARAMETER_KEY.CURRENT_USER_NAME] = user?.first_name + " " + user?.last_name;
    data[PARAMETER_KEY.MODEL] = gptType;
    data[PARAMETER_KEY.PERSONA] = selectedPersona;
    data[PARAMETER_KEY.LANGUAGE] = selectedLanguage;

    data[PARAMETER_KEY.POST_CREATION_TEXT] = postCreationText;

    if (subscriptionStatus) {
      if (subscriptionStatus.status === null && subscriptionStatus.status === SubscriptionStatus.PRO) {
        data[PARAMETER_KEY.STATUS] = SubscriptionStatusDB.ACTIVE;
      } else {
        data[PARAMETER_KEY.STATUS] = subscriptionStatus.statusDB;
      }
      if (data[PARAMETER_KEY.STATUS] === SubscriptionStatusDB.PAST_DUE) {
        data[PARAMETER_KEY.IS_PRO_USER] = false;
      } else {
        data[PARAMETER_KEY.IS_PRO_USER] = subscriptionStatus.status === SubscriptionStatus.PRO;
      }
    }
    const response = await fetchSuggestedComments(data);

    if (response?.success) {
      getSubscriptionStatus();
      const generatedText = response?.data?.[0]?.["reply"] ?? "";

      const generatedTextInfo: TextGenerationInfo = {
        activityData: data,
        generatedText,
        activityType: API_REQUEST_TYPE.CREATE_POST,
      };

      setGeneratedTextsHistory((previousHistory) => [...previousHistory, generatedTextInfo]);

      setSavedDocumentIdsOfGeneratedTextHistory((previousItems) => [...previousItems, ""]);

      if (generatedTextInfo) {
        setTextareaVal(generatedTextInfo.generatedText);
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);

      if (response?.responseCode === 429) {
        setShowUsageCompleteBanner(true);
        setIsTrailFinished(true);
        return;
      } else if (response?.responseCode === 402 && subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE) {
        setShowUpdatePaymentDialog(true);
        return;
      }

      setErrorText(response?.error?.reason ?? "Some error occurred. Please try again later.");
    }
  }, [commandText, selectedTone, selectedGoal, gptType, postCreationText, user, selectedPersona, selectedLanguage, subscriptionStatus]);

  useEffect(() => {
    setCurrentHistoryIndex(Math.max(0, generatedTextsHistory.length - 1));
  }, [generatedTextsHistory]);

  useEffect(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      setCommandText(generatedTextsHistory[currentHistoryIndex].activityData.command);
      setSelectedGoal(generatedTextsHistory[currentHistoryIndex].activityData.goal);
      setSelectedTone(generatedTextsHistory[currentHistoryIndex].activityData.tone);
    }
  }, [generatedTextsHistory, currentHistoryIndex]);

  const textInfo = useMemo(() => {
    if (currentHistoryIndex < generatedTextsHistory.length) {
      const words = textareaVal?.split(" ");
      return `${words.length} words/${textareaVal?.length} characters`;
    }
    return "";
  }, [generatedTextsHistory, currentHistoryIndex, textareaVal]);

  const goToPreviousHistoryPage = useCallback(() => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex((previousValue) => previousValue - 1);
    }
  }, [currentHistoryIndex]);

  const goToNextHistoryPage = useCallback(() => {
    if (currentHistoryIndex < generatedTextsHistory.length - 1) {
      setCurrentHistoryIndex((previousValue) => previousValue + 1);
    }
  }, [currentHistoryIndex, generatedTextsHistory]);

  const copyTextToClipBoard = () => {
    console.log("### text will be copied to clipboard");
    navigator.clipboard
      .writeText(textareaVal)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
        setTimeout(() => {}, SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE * 1000);
      })
      .catch((error) => {
        console.log("### Error while copying content to clipboard");
        console.log({ error });
        sentryClient.captureException(error)
      });
  };

  const saveCommand = async () => {
    try {
      setIsUpdatingSaveCommandStatus(true);
      const docId = nanoid();
      const { error } = await addCommandData(
        generatedTextsHistory[currentHistoryIndex].activityData || "",
        generatedTextsHistory[currentHistoryIndex].activityType,
        generatedTextsHistory[currentHistoryIndex].generatedText,
        docId
      );

      if (error === null && docId?.length > 0) {
        const _savedDocIds = [...savedDocumentIdsOfGeneratedTextHistory];
        _savedDocIds[currentHistoryIndex] = docId;
        setSavedDocumentIdsOfGeneratedTextHistory(_savedDocIds);
        setIsFavorite(true);
        toastAlertSuccess("Saved Successfully");
      }
      setIsFavorite(true);
    } catch (error) {
      console.log("### Error while saving command");
      console.log({ error });
      sentryClient.captureException(error)
    } finally {
      setIsUpdatingSaveCommandStatus(false);
    }
  };

  const unsaveCommand = async () => {
    try {
      setIsUpdatingSaveCommandStatus(true);
      const { docId, error } = await deleteCommandData(savedDocumentIdsOfGeneratedTextHistory[currentHistoryIndex]);
      if (error === null && docId?.length > 0) {
        const _savedDocIds = [...savedDocumentIdsOfGeneratedTextHistory];
        _savedDocIds[currentHistoryIndex] = "";
        setSavedDocumentIdsOfGeneratedTextHistory(_savedDocIds);
        toastAlertSuccess("Unsaved Successfully");
      }
    } catch (error) {
      console.log("### Error while unsaving command");
      console.log({ error });
      sentryClient.captureException(error)
    } finally {
      setIsUpdatingSaveCommandStatus(false);
    }
  };

  const saveOrUnsaveCommand = () => {
    if (savedDocumentIdsOfGeneratedTextHistory[currentHistoryIndex].length > 0) {
      unsaveCommand();
    } else {
      saveCommand();
    }
  };

  useEffect(() => {
    if (generatedTextsHistory[currentHistoryIndex]?.generatedText) {
      setTextareaVal(generatedTextsHistory[currentHistoryIndex]?.generatedText);
    }
  }, [generatedTextsHistory[currentHistoryIndex]?.generatedText]);

  const handleNavigateToSubPag = () => {
    navigate(`/${PROTECTED_ROUTES.subscriptionPricing}`);
  };

  useEffect(() => {
    if (isFavorite) {
      setTimeout(() => {
        setIsFavorite(false);
      }, 4000);
    }
  }, [isFavorite]);

  useEffect(() => {
    if (errorText) {
      toastAlertError(errorText);
    }
  }, [errorText]);

  useEffect(() => {
    if (gptType === "gpt_4" && subscriptionStatus?.status === SubscriptionStatus.FREE) {
      setShowPreDialog(true);
    }
  }, [gptType]);

  useEffect(() => {
    if (!showPreDialog && subscriptionStatus?.status === SubscriptionStatus.FREE) {
      setGptType("gpt_3.5");
    }
  }, [showPreDialog, subscriptionStatus?.status]);

  useEffect(() => {
    if (!!(subscriptionStatus?.usagePercentage && subscriptionStatus.usagePercentage >= 100) && subscriptionStatus.status === SubscriptionStatus.FREE) {
      setShowBanner(true);
      setIsTrailFinished(true);
    }
  }, [subscriptionStatus?.usagePercentage]);

  useEffect(() => {
    if (templateSearchField === "") {
      setSearchedTemplates(templates);
    } else {
      const filteredTemplates = templates.filter(
        (template) =>
          template.templateName.toLowerCase().includes(templateSearchField.toLowerCase()) || template.templateContent.toLowerCase().includes(templateSearchField.toLowerCase())
      );
      setSearchedTemplates(filteredTemplates);
    }
  }, [templateSearchField]);

  const handleClickOutside = (event: MouseEvent) => {
    const targetNode = event.target as Node | HTMLElement;

    if (templatesDropdownRef.current && !templatesDropdownRef.current.contains(targetNode)) {
      const targetNodeId = (targetNode as HTMLElement).id;

      if (targetNodeId === "template-dropdown") {
        setShowTemplateSelectDropDown(false);
      } else {
        setIsDropdownClicked(false);
        setShowTemplateSelectDropDown(false);
      }
    }
  };

  useEffect(() => {
    let doc = document;
    if (doc) {
      if (showTemplateSelectDropDown) {
        doc.addEventListener("mousedown", handleClickOutside);
      } else {
        doc.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        doc.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showTemplateSelectDropDown]);

  useEffect(() => {
    if (isDropdownClicked) {
      setShowTemplateSelectDropDown(true);
    } else if (!isDropdownClicked && showTemplateSelectDropDown) {
      setShowTemplateSelectDropDown(false);
    }
  }, [isDropdownClicked]);

  useEffect(() => {
    setTemplateSearchField("");
  }, [showTemplateSelectDropDown]);

  const handleGoBack = () => {
    setGeneratedTextsHistory([])
    setSelectedPersona("")
  }

  useEffect(() => {
    const loadLanguageFromLocalStorage = () => {
      try {
        const savedLanguage = localStorage.getItem(STORAGE_KEYS.LAST_LANGUAGE_USED);
        if (savedLanguage) {
          setSelectedLanguage(savedLanguage as LanguageKey);
        }
      } catch (error) {
        toastAlertError("Error while loading last used language!");
        sentryClient.captureException(error)
      }
    }

    loadLanguageFromLocalStorage();
  }, [setSelectedLanguage])

  return (
    <section className={`xsm:rounded-[16px] xsm:py-2 md:px-0 xsm:mb-2 xsm:px-0 ${generatedTextsHistory.length === 0 ? "h-full" : "xsm:"}`}>
      {generatedTextsHistory.length === 0 ? (
        <>
          <h3 className='custom-h3-style'>Post Writer</h3>
          <p className='custom-primary-p-style'>Create personalized LinkedIn posts with a click of a button to help you gain exposure on LinkedIn.</p>
        </>
      ) : (
        <div className='flex items-center mb-6'>
          <h2 onClick={handleGoBack} className='cursor-pointer font-poppins md:text-[16px] text-[14px] font-normal leading-normal text-[#444553] mr-2 opacity-60'>
            Post Writer
          </h2>
          <LeftArrow width={15} height={15} color={"#44455390"} />
          <h2 className='cursor-pointer font-poppins md:text-[16px] text-[14px] font-bold leading-[24px] text-[#444553] ml-2'>Results</h2>
        </div>
      )}

      {showBanner && <UpgradeBanner setShowBanner={setShowBanner} headline='Become a Premium Member' description='Upgrade now for unlimited access to evyAI’s Post Writer.' />}

      {showUsageCompleteBanner && <FreeTrailEnd title='Your free trial has finished!' content='Upgrade your plan to get access to our Premium Features' />}

      <UsageCompleteDisabledWrapper isDisabled={isTrailFinished}>
        <div className={`bg-white rounded-[16px] p-10 xsm:p-0 xsm:h- xsm:border ${generatedTextsHistory.length < 1 ? "border-[#A1A2A9]" : "!border-none"} w-full md:mt-8 mt-5 `}>
          <div className='xsm:h-full'>
            {generatedTextsHistory.length > 0 && (
              <div className='xsm:hidden mb-4'>
                <label htmlFor='name' className='label md:text-[#444553] font-poppins md:text-[24px] md:font-semibold text-sm font-bold'>
                  Command to Evy
                </label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  value={commandText}
                  onChange={(e) => setCommandText(e.target.value)}
                  placeholder='Enter a prompt with instructions for the type of post you would like evyAI to create.'
                  className='input text-[#444553] font-poppins text-base font-normal mt-2'
                />
              </div>
            )}
            <div className='xsm:hidden flex flex-col lg:flex-row items-center gap-8'>
              <div className='flex flex-row items-center w-full gap-x-0'>
                <span className='min-w-[80px] text-[#444553] font-poppins text-[22px] font-semibold '>Goal</span>
                <div className='custom-select'>
                  <select
                    className='block cursor-pointer w-full text-base font-poppins outline-none text-gray_light border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3'
                    value={selectedGoal}
                    onChange={(e) => {
                      setSelectedGoal(e.target.value);
                      localStorage.setItem(STORAGE_KEYS.LAST_GOAL_USED, e.target.value!);
                    }}
                  >
                    {POSTING_GOALS.map((ton, index) => {
                      return (
                        <option key={index} value={ton}>
                          {ton}
                        </option>
                      );
                    })}
                  </select>
                  <div className='custom-arrow'>
                    <svg width='18' height='10' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z'
                        fill='#484848'
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='flex items-center w-full gap-x-0'>
                <span className='min-w-[80px] text-[#444553] font-poppins text-[22px] font-semibold '>Tone</span>
                <div className='custom-select'>
                  <select
                    value={selectedTone}
                    onChange={(e) => {
                      setSelectedTone(e.target.value);
                      localStorage.setItem(STORAGE_KEYS.LAST_TONE_USED, e.target.value!);
                    }}
                    className='block cursor-pointer w-full  text-base outline-none text-gray_light font-poppins border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3'
                  >
                    {TONES.map((ton, index) => {
                      return (
                        <option key={index} value={ton}>
                          {ton}
                        </option>
                      );
                    })}
                  </select>
                  <div className='custom-arrow'>
                    <svg width='18' height='10' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z'
                        fill='#484848'
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full gap-x-8">
                <span className="min-w-[80px] text-[#444553] font-poppins text-[22px] font-semibold ">
                  Persona
                </span>
                <>
                { componentsLoading.personas ?
                  <div className="w-full flex justify-center">
                    <Loader size={3} />
                  </div>
                  :
                  <div className="custom-select">
                        <select
                          value={selectedPersona}
                          onChange={(e) => {
                            setSelectedPersona(e.target.value)
                            localStorage.setItem(
                              STORAGE_KEYS.LAST_PERSONA_USED,
                              e.target.value!
                            );
                          }}
                          className="block cursor-pointer w-full text-base outline-none text-gray_light font-poppins border border-[#A1A2A9] !rounded-[10px] focus:border-[#A1A2A9] bg-transparent focus:ring-[#A1A2A9] p-3"
                        >
                          <option value="">Select Persona</option>
                          {personas?.map((persona, index) => {
                            return (
                              <option key={index} value={persona.id}>
                                {persona?.personaName}
                              </option>
                            );
                          })}
                        </select>
                    <div className="custom-arrow">
                      <svg
                        width="18"
                        height="10"
                        viewBox="0 0 21 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9882 11.3118C10.6298 11.3118 10.2714 11.176 9.99813 10.905L1.39856 2.3723C0.85152 1.82952 0.85152 0.949488 1.39856 0.406924C1.94539 -0.135641 2.83215 -0.135641 3.37924 0.406924L10.9882 7.95711L18.5973 0.407187C19.1443 -0.135377 20.031 -0.135377 20.5778 0.407187C21.1251 0.949752 21.1251 1.82978 20.5778 2.37257L11.9784 10.9052C11.705 11.1763 11.3466 11.3118 10.9882 11.3118Z"
                          fill="#484848"
                        />
                      </svg>
                    </div>
                  </div>
                }
                </>
              </div>
            </div>

            {generatedTextsHistory.length === 0 ? (
              <div className='md:relative overflow-hidden relative xsm:h-[70vh] xsm:flex flex-col justify-between items-center mt-5 xsm:mt-0 xsm:px-0 md:border md:border-[#A1A2A9] md:rounded-lg md:outline-none md:pb-[72px]'>
                <textarea
                  id='message'
                  onChange={(e) => setPostCreationText(e.target.value)}
                  value={postCreationText}
                  rows={screen !== "small" ? 16 : 32}
                  className='outline-none block p-6 xsm:px-4 xsm:text-[14px] xsm:w-[99%] w-full font-poppins font-normal text-base text-[#121212] md:border-none border border-[#A1A2A9] xsm:rounded-xl rounded-lg relative'
                  placeholder='Enter a prompt with instructions for the type of post you would like evyAI to create.'
                />
                <div ref={templatesDropdownRef} className='xsm:hidden font-poppins'>
                  <button
                    id='template-dropdown'
                    onClick={() => {
                      if (!showTemplateSelectDropDown && templates.length < 1) {
                        handleFetchTemplates();
                      }
                      if (screen === "small") {
                        setopenTemplateDialog(true);
                        return;
                      }
                      setShowTemplateSelectDropDown(!showTemplateSelectDropDown);
                    }}
                    className='bg-[#F5F1FB] border-[0.7px] border-[#9778D21A] py-[2px] px-[3px] rounded-[6px] absolute top-[424px] right-[16px] md:top-[20px] md:right-[20px] flex gap-2'
                  >
                    <div id='template-dropdown' className='p-[5.5px] md:p-[6.4px] rounded-md bg-chip_bg flex justify-center items-center gap-2 '>
                      <img id='template-dropdown' className='h-[16px] w-[16px] md:h-[20px] md:w-[20px]' src={TemplatesIcon} alt='' />
                      <img id='template-dropdown' className='h-[13px] w-[13px] md:h-[15px] md:w-[15px]' src={DownArrowBlack} alt='' />
                    </div>
                  </button>
                  <div
                    style={{ boxShadow: "0px 5.5px 20.62px 0px #0000001A" }}
                    className={`${
                      !showTemplateSelectDropDown
                        ? "hidden"
                        : " xsm:hidden z-20 bg-white rounded-[14px] flex flex-col justify-between items-center border-[1.3px] border-[#F6F6F6] h-[380px] w-[325px] absolute top-[4rem] right-[20px]"
                    }`}
                  >
                    <div className=' w-full'>
                      <div className='p-4 '>
                        <div className='flex justify-start items-center border-[0.6px] border-[#A1A2A980] rounded-[8px] p-2 gap-3 '>
                          <img src={SearchIcon} width={16} />
                          <input className='outline-none' placeholder='Search Templates' value={templateSearchField} onChange={(e) => setTemplateSearchField(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className=' w-full flex-1 overflow-y-auto'>
                      {isLoadingTemplates && (
                        <div className='w-full flex justify-center items-center py-[70px]'>
                          <Loader size={10} />
                        </div>
                      )}
                      {!isLoadingTemplates &&
                        searchedTemplates.length >= 0 &&
                        searchedTemplates.map((template: ITemplate) => {
                          return (
                            <div
                              key={template.id}
                              onClick={(e) => {
                                const id = (e.target as HTMLElement).id;
                                console.log("id", id);

                                if (id === "template-edit-link") {
                                  setShowTemplateSelectDropDown(false);
                                  return;
                                }
                                setselectedTemplate(template.id);
                                setShowTemplateSelectDropDown(false);
                              }}
                              className='px-4 py-3 border-b-[1.3px] border-[#DFDFDF] hover:bg-[#F5F7F9] cursor-pointer'
                            >
                              <div className='flex flex-col justify-between items-center'>
                                <div className='flex justify-between w-full items-center'>
                                  <h2
                                    className='font-bold text-[#444553] w-[85%] '
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      WebkitBoxOrient: "vertical",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {template.templateName}
                                  </h2>
                                  <span id='template-edit-link' onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}/${template.id}`)} className='flex justify-end flex-1'>
                                    <img id='template-edit-link' src={LinkIcon} width={15} />
                                  </span>
                                </div>
                                <p
                                  className='text-[#444553] w-full'
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {template.templateContent}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {!isLoadingTemplates && searchedTemplates.length <= 0 && (
                        <div className='px-4 py-[90px] flex justify-center items-center'>
                          <h2 className='font-bold text-[#444553] '>No Templates Found!</h2>
                        </div>
                      )}
                    </div>
                    <div className=' w-full p-[10px] bg-[#F5F1FB] flex justify-end items-center'>
                      <button onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}`)} className='bg-[#34317D] md:rounded-[12px] rounded-[10px] p-2 '>
                        <img src={PlusIcon} width={17} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='flex xsm:py-4 xsm:flex-col xsm:justify-between xsm:items-center xsm:gap-4 justify-end xsm:w-full xsm:rounded-t-none xsm:rounded-b-[16px] xsm:bg-[#F5F7F9]'>
                  <div className='md:hidden relative font-poppins w-full flex justify-end items-center px-3'>
                    <button
                      // id="template-dropdown"
                      onClick={() => {
                        if (!showTemplateSelectDropDown && templates.length < 1) {
                          handleFetchTemplates();
                        }
                        if (screen === "small") {
                          setopenTemplateDialog(true);
                          return;
                        }
                        setShowTemplateSelectDropDown(!showTemplateSelectDropDown);
                      }}
                      className='bg-[#F5F1FB] border-[0.7px] border-[#9778D21A] py-[2px] px-[3px] rounded-[6px]  to-[44px] right-[16px] md:top-[20px] md:right-[20px] flex gap-2'
                    >
                      <div id='template-dropdown' className='p-[5.5px] md:p-[6.4px] rounded-md bg-chip_bg flex justify-center items-center gap-2 '>
                        <img id='template-dropdown' className='h-[16px] w-[16px] md:h-[20px] md:w-[20px]' src={TemplatesIcon} alt='' />
                        <img id='template-dropdown' className='h-[13px] w-[13px] md:h-[15px] md:w-[15px]' src={DownArrowBlack} alt='' />
                      </div>
                    </button>
                    <div
                      style={{ boxShadow: "0px 5.5px 20.62px 0px #0000001A" }}
                      className={`${
                        !showTemplateSelectDropDown
                          ? "hidden"
                          : " xsm:hidden z-20 bg-white rounded-[14px] flex flex-col justify-between items-center border-[1.3px] border-[#F6F6F6] h-[380px] w-[325px] absolute top-[4rem] right-[20px]"
                      }`}
                    >
                      <div className=' w-full'>
                        <div className='p-4 '>
                          <div className='flex justify-start items-center border-[0.6px] border-[#A1A2A980] rounded-[8px] p-2 gap-3 '>
                            <img src={SearchIcon} width={16} />
                            <input className='outline-none' placeholder='Search Templates' value={templateSearchField} onChange={(e) => setTemplateSearchField(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className=' w-full flex-1 overflow-y-auto'>
                        {isLoadingTemplates && (
                          <div className='w-full flex justify-center items-center py-[70px]'>
                            <Loader size={10} />
                          </div>
                        )}
                        {!isLoadingTemplates &&
                          searchedTemplates.length >= 0 &&
                          searchedTemplates.map((template: ITemplate) => {
                            return (
                              <div
                                key={template.id}
                                onClick={(e) => {
                                  const id = (e.target as HTMLElement).id;
                                  if (id === "template-edit-link") {
                                    setShowTemplateSelectDropDown(false);
                                    return;
                                  }
                                  setselectedTemplate(template.id);
                                  setShowTemplateSelectDropDown(false);
                                }}
                                className='px-4 py-3 border-b-[1.3px] border-[#DFDFDF] hover:bg-[#F5F7F9] cursor-pointer'
                              >
                                <div className='flex flex-col justify-between items-center'>
                                  <div className='flex justify-between w-full items-center'>
                                    <h2
                                      className='font-bold text-[#444553] w-[85%] '
                                      style={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {template.templateName}
                                    </h2>
                                    <span id='template-edit-link' onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}/${template.id}`)} className='flex justify-end flex-1'>
                                      <img id='template-edit-link' src={LinkIcon} width={15} />
                                    </span>
                                  </div>
                                  <p
                                    className='text-[#444553] w-full'
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: "1",
                                      WebkitBoxOrient: "vertical",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {template.templateContent}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isLoadingTemplates && searchedTemplates.length <= 0 && (
                          <div className='px-4 py-[90px] flex justify-center items-center'>
                            <h2 className='font-bold text-[#444553] '>No Templates Found!</h2>
                          </div>
                        )}
                      </div>
                      <div className=' w-full p-[10px] bg-[#F5F1FB] flex justify-end items-center'>
                        <button onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}`)} className='bg-[#34317D] rounded-[8px] p-2 '>
                          <img src={PlusIcon} width={17} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='md:hidden  flex justify-between items-center gap-2'>
                    <div
                      onClick={() => setOpenGoalDialog(true)}
                      className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                        selectedGoal ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                      } rounded-md`}
                    >
                      <span className='font-poppins font-medium text-[14px]'>Goal</span>
                      {selectedGoal ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                    <div
                      onClick={() => setOpenToneDialog(true)}
                      className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                        selectedTone ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                      } rounded-md`}
                    >
                      <span className='font-poppins font-medium text-[14px]'>Tone</span>
                      {selectedTone ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                    <div
                      onClick={() => setOpenPersonaDialog(true)}
                      className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                        selectedPersona ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                      } rounded-md`}
                    >
                      <span className='font-poppins font-medium text-[14px]'>Persona</span>
                      {selectedPersona ? <img src={checkImage} /> : <DownArrow />}
                    </div>
                  </div>
                  <div className="flex align-middle justify-between xsm:justify-center whitespace-nowrap xsm:gap-6 md:absolute md:bottom-4 xsm:h-10 w-full md:pl-2 pr-4">
                    <FlagSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                    <div className="flex items-center gap-2">
                      <div className="flex  text-[#3b4046] bg-[#e0e3e6] p-1 md:rounded-[12px] rounded-[10px]">
                        {gptLengths.map((length, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setGptType(length.value);
                              localStorage.setItem(STORAGE_KEYS.LAST_GPT_VERSION_USED, length.value);
                            }}
                            className={`py-2 px-4 font-poppins xsm:font-medium xsm:text-[12px] w-full justify-center flex items-center ${gptType === length.value
                              ? "all_pType_buttons"
                              : ""
                              }`}
                          >
                            {length.label}
                          </button>
                        ))}
                      </div>
                      <button
                        // className="xsm:font-poppins xsm:font-bold xsm:hidden text-[14px] px-6 py-[10px] flex items-center xsm:ml-0 ml-6 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50 xsm:mr-0 mr-4 "
                        className='all_pType_buttons xsm:hidden'
                        onClick={fetchComments}
                        disabled={isLoading}
                      >
                        {isLoading && <Loader size={4} />}
                        <span>Generate</span>
                      </button>
                    </div>
                  </div>
                  <button className='md:hidden all_pType_buttons w-[90%] bg-secondry_color ' onClick={fetchComments} disabled={isLoading}>
                    {isLoading && <Loader size={4} />}
                    <span>Generate</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className='md:relative xsm:h-full xsm:flex flex-col justify-between items-center mt-5 xsm:mt-0 xsm:px-0 xsm:rounded-[16px] md:p-4 md:rounded-md md:border border-gray-300 '>
                <div className=' xsm:flex flex-col justify-between   block p-2.5 w-full text-sm xsm:bg-[#FAF8FD] bg-[#F5F1FB] rounded-lg !border-none focus:!border-none relative'>
                  <textarea
                    id='message'
                    onChange={(e) => setTextareaVal(e.target.value)}
                    value={textareaVal}
                    // rows={36}
                    className=' min-h-[50vh] block p-2.5 w-full font-poppins font-normal text-base text-[#121212] xsm:text-[14px] xsm:bg-[#FAF8FD] bg-[#F5F1FB] rounded-lg !border-none focus:!border-none outline-none '
                    placeholder='Enter a prompt for what you want evyAI to create about'
                  />

                  <div className='flex flex-col w-full justify-between mt-3 gap-8'>
                    <div className='flex justify-between mt-3'>
                      <span className='font-poppins text-base xsm:font-bold xsm:text-[12px] font-semibold text-gray-700 opacity-50'>{textInfo}</span>
                      <div className='flex'>
                        <img className='xsm:hidden ml-4 cursor-pointer' src={copyIcon} onClick={copyTextToClipBoard} alt='Copy' />

                        {isButtonVisible && (
                          <>
                            {isUpdatingSaveCommandStatus ? (
                              <span className='ml-4'>
                                <Loader size={5} />
                              </span>
                            ) : (
                              <img
                                className='ml-4 mr-4 cursor-pointer'
                                src={savedDocumentIdsOfGeneratedTextHistory[currentHistoryIndex].length > 0 ? FillStarIcon : EmptyStarIcon}
                                onClick={saveOrUnsaveCommand}
                                alt='Copy'
                              />
                            )}
                          </>
                        )}

                        {/* {isUpdatingSaveCommandStatus ? (
                          <span className="ml-4">
                            <Loader size={5} />
                          </span>
                        ) : (
                          <img
                            className="ml-4 cursor-pointer"
                            src={
                              savedDocumentIdsOfGeneratedTextHistory[
                                currentHistoryIndex
                              ].length > 0
                                ? FillStarIcon
                                : EmptyStarIcon
                            }
                            onClick={saveOrUnsaveCommand}
                            alt="Copy"
                          />
                        )} */}
                      </div>
                    </div>
                    <Button onClick={copyTextToClipBoard} className='md:hidden gap-2 w-full flex mx-auto primary_button_custom ml-6  bg-secondry_color md:text-[16px] text-[14px]'>
                      <CopyIcon width={15} height={15} color={"white"} />
                      Copy Post
                    </Button>
                  </div>
                </div>
                <div className=' relative md:hidden flex xsm:pt-2 xsm:flex-col xsm:justify-between xsm:items-center xsm:gap-4 justify-end xsm:w-full mt-4 xsm:rounded-[16px] xsm:border border-[#A1A2A9] xsm:'>
                  <div className='md:hidden w-full flex justify-between items-center gap-2 px-2'>
                    <textarea
                      id='name'
                      name='name'
                      value={commandText}
                      onChange={(e) => setCommandText(e.target.value)}
                      placeholder='Enter a prompt with instructions for the type of post you would like evyAI to create.'
                      className=' min-h-[7vh] w-full my-2 font-poppins text-[14px] font-normal mt-2'
                    />
                  </div>
                  <div className='flex-col gap-3 items-center w-full bg-[#F5F7F9] rounded-b-[20px] py-4 flex md:absolute md:bottom-4'>
                    <div className=' md:hidden  flex justify-between items-center gap-2'>
                      {/* <img src={isExpand ? downArrow : upArrow} className={`border-2 bg-[#f1f3f5] mx-auto inset-x-0 border-white absolute rounded-full  z-30 ${isExpand ? (generatedTextsHistory.length > 1 ? "bottom-[210px]" : "bottom-[180px]") : (generatedTextsHistory.length <= 1 ? "bottom-[140px]" : "bottom-[170px]")} `} onClick={() => setIsExpand(!isExpand)} /> */}
                      {isExpand && (
                        <div className='relative flex justify-between items-center gap-2 mt-2'>
                          <div
                            onClick={() => setOpenGoalDialog(true)}
                            className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                              selectedGoal ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                            } rounded-md`}
                          >
                            <span className='font-poppins font-medium text-[14px]'>Goal</span>
                            {selectedGoal ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                          <div
                            onClick={() => setOpenToneDialog(true)}
                            className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                              selectedTone ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                            } rounded-md`}
                          >
                            <span className='font-poppins font-medium text-[14px]'>Tone</span>
                            {selectedTone ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                          <div
                            onClick={() => setOpenPersonaDialog(true)}
                            className={`cursor-pointer flex justify-between items-center gap-2 px-2 py-1 bg-white border ${
                              selectedPersona ? "border-[#6D40C4] bg-[#F5F1FB]" : "border-[#D0D1D4]"
                            } rounded-md`}
                          >
                            <span className='font-poppins font-medium text-[14px]'>Persona</span>
                            {selectedPersona ? <img src={checkImage} /> : <DownArrow />}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex items-center gap-3 justify-between'>
                      <FlagSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                      <div className="flex text-[#495057] bg-[#f1f3f5]  whitespace-nowrap p-1 rounded-lg">
                        {gptLengths.map((length, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setGptType(length.value);
                              localStorage.setItem(STORAGE_KEYS.LAST_GPT_VERSION_USED, length.value);
                            }}
                            className={`py-2 px-4 w-full font-poppins text-xs justify-center flex items-center ${gptType === length.value
                              ? "all_pType_buttons"
                              : "font-medium"
                              }`}
                          >
                            {length.label}
                          </button>
                        ))}
                      </div>
                      <Button
                        className="xsm:hidden all_sType_buttons bg-transparent md:text-[16px] text-[14px]"
                        onClick={fetchComments}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader size={4} />
                        ) : (
                          <img src={resetimg} alt="reset" className="mr-2" />
                        )}
                        <span>Regenerate</span>
                      </Button>
                      <Button
                        onClick={copyTextToClipBoard}
                        className='xsm:hidden min-w-[180px]  py-[10px] flex items-center ml-6 rounded-[10px] border border-secondry_color md:text-[18px] text-[14px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50'
                      >
                        Copy
                      </Button>
                    </div>
                    <Button
                      className="md:hidden w-[90%] all_sType_buttons bg-transparent md:text-[16px] text-[14px]"
                      onClick={fetchComments}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader size={4} />
                      ) : (
                        <img src={resetimg} alt="reset" className="mr-2" />
                      )}
                      <span>Regenerate</span>
                    </Button>
                    <Button onClick={resetState} className='md:hidden primary_button_custom w-[90%] bg-secondry_color'>
                      + New Post
                    </Button>
                    <div className='flex justify-between gap-2 mt-2'>
                      {generatedTextsHistory.length > 1 && (
                        <>
                          <div
                            onClick={goToPreviousHistoryPage}
                            className={`w-[30px] h-[30px] rounded-full shadow-lg flex items-center justify-center ${
                              currentHistoryIndex > 0 ? "cursor-pointer bg-white" : "opacity-60"
                            }`}
                          >
                            <img src={arrowLeft} alt='img' width='15px' height='15px' />
                          </div>
                          <div
                            onClick={goToNextHistoryPage}
                            className={`w-[30px] h-[30px] rounded-full ml-2 shadow-lg flex items-center justify-center ${
                              currentHistoryIndex < generatedTextsHistory.length - 1 ? "cursor-pointer bg-white" : "opacity-60"
                            }`}
                          >
                            <img src={arrowRight} alt='img' width={15} height={15} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='xsm:hidden flex items-center justify-between w-full mt-4 md:flex-row flex-col'>
                  <div className='flex justify-center items-center mb-2 gap-5'>
                    {generatedTextsHistory.length > 1 && (
                      <>
                        <div
                          onClick={goToPreviousHistoryPage}
                          className={`w-[44px] h-[44px] rounded-full shadow-lg flex items-center justify-center ${
                            currentHistoryIndex > 0 ? "cursor-pointer bg-white" : "opacity-60"
                          }`}
                        >
                          <img src={arrowLeft} alt='img' width='15px' height='15px' />
                        </div>
                        <div
                          onClick={goToNextHistoryPage}
                          className={`w-[44px] h-[44px] rounded-full ml-2 shadow-lg flex items-center justify-center ${
                            currentHistoryIndex < generatedTextsHistory.length - 1 ? "cursor-pointer bg-white" : "opacity-60"
                          }`}
                        >
                          <img src={arrowRight} alt='img' width={15} height={15} />
                        </div>
                      </>
                    )}
                    <FlagSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                  </div>
                  <div className="flex items-center">

                    <div className="flex items-center text-[#495057] bg-[#f1f3f5] p-1 mr-6 whitespace-nowrap rounded-lg">
                      {gptLengths.map((length, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setGptType(length.value);
                            localStorage.setItem(STORAGE_KEYS.LAST_GPT_VERSION_USED, length.value);
                          }}
                          className={`py-2 px-4 w-full flex justify-center items-center font-poppins ${gptType === length.value ? "all_pType_buttons" : ""}`}
                        >
                          {length.label}
                        </button>
                      ))}
                    </div>

                    <Button
                      className="xsm:hidden all_sType_buttons bg-transparent md:text-[16px] text-[14px]"
                      onClick={fetchComments}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader size={4} />
                      ) : (
                        <img src={resetimg} alt="reset" className="mr-2" />
                      )}
                    <span>Regenerate</span>
                    </Button>

                    {isButtonVisible && (
                      <>
                        <Button onClick={copyTextToClipBoard} className='xsm:hidden min-w-[180px] primary_button_custom ml-6  bg-secondry_color md:text-[16px] text-[14px]'>
                          Copy
                        </Button>
                        <Button onClick={resetState} className='xsm:hidden min-w-[180px] primary_button_custom ml-6  bg-secondry_color md:text-[16px] text-[14px]'>
                          + New Post
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <SelectDialogue openDialog={openGoalDialog} title='Goal' items={POSTING_GOALS} handleClose={handleClose} setSelectedItem={setSelectedGoal} selectedItem={selectedGoal} />
          <SelectDialogue openDialog={openToneDialog} title='Tone' items={TONES} handleClose={handleClose} setSelectedItem={setSelectedTone} selectedItem={selectedTone} />
          <SelectDialogue
            openDialog={openPersonaDialog}
            title='Persona'
            items={personas}
            handleClose={handleClose}
            setSelectedItem={setSelectedPersona}
            selectedItem={selectedPersona}
          />
          <SelectDialogue
            openDialog={openTemplateDialog}
            title='Template'
            items={templates}
            handleClose={handleClose}
            setSelectedItem={setselectedTemplate}
            selectedItem={selectedTemplate}
            isLoading={isLoadingTemplates}
          />
          <UpgradeSubscriptionDia show={showPreDialog} onConfirm={handleNavigateToSubPag} content={"Upgrade to evyAI Premium to access GPT-4"} setShow={setShowPreDialog} />
        </div>
      </UsageCompleteDisabledWrapper>
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
      />
    </section>
  );
};

export default PostComponent;
