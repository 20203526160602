import PlusSvg from "../../../assets/Images/Plus.svg"
import { useState } from "react";
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";

export interface ActionButtonsProps {
    isAddButtonDisable: boolean;
    getAllPersonas(sortBy?: string, order?: any): Promise<void>;
    deletePersonaIds: string[];
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    handleClickAddPersona: () => void;
}


type OrderKey = "Last Modified" | "Name A → Z" | "Name Z → A";
type OrderValue = { field: string, direction: string };

export default function ActionButtons(props: ActionButtonsProps) {

    const { isAddButtonDisable, getAllPersonas, deletePersonaIds, setShowConfirmation, handleClickAddPersona } = props;

    const [orderDropdown, setOrderDropdown] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<OrderKey>("Last Modified");

    const orderMapping: Record<OrderKey, OrderValue> = {
        "Last Modified": { field: "timestamp", direction: "desc" },
        "Name A → Z": { field: "personaName", direction: "asc" },
        "Name Z → A": { field: "personaName", direction: "desc" },
    };

    const handleApplyOrder = () => {
        const { field, direction } = orderMapping[selectedOrder];
        getAllPersonas(field, direction);
        setOrderDropdown(false);
    };

    return (
        <>
            <div className='flex justify-start items-center md:mt-8 mt-5 '>
                <div className="w-full flex justify-between">
                    <div className="relative">
                        <button
                            className="flex items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg"
                            onClick={() => setOrderDropdown(!orderDropdown)}
                            style={{ height: "40px" }}
                        >
                            <span>Order By</span>
                            <img src={orderDropdown ? UpArrow : DownArrow} className="ml-1" />
                        </button>
                        {orderDropdown && (
                            <div
                                style={{ maxWidth: "200px", minWidth: "200px" }}
                                className="absolute left-0 mt-1 z-10"
                            >
                                <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px]">
                                    {Object.keys(orderMapping).map((order) => (
                                        <span
                                            key={order}
                                            className="flex mt-1 cursor-pointer"
                                            onClick={() => {
                                                if (Object.keys(orderMapping).includes(order)) {
                                                    setSelectedOrder(order as OrderKey);
                                                }
                                            }}
                                        >
                                            <img
                                                src={
                                                    selectedOrder === order ? checkImage : unCheckImage
                                                }
                                            />
                                            <span className="ml-2">{order}</span>
                                        </span>
                                    ))}
                                </div>
                                <div className="flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]">
                                    <button
                                        className="primary_button_custom  px-3 py-2 rounded-lg flex ml-3"
                                        onClick={handleApplyOrder}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center gap-4">

                        {deletePersonaIds.length > 0 && (
                            <button
                                className="bg-transparent text-secondry_color font-bold border border-secondry_color px-3 py-2 rounded-lg ml-3"
                                onClick={() => setShowConfirmation(true)}
                            >
                                Delete Persona{deletePersonaIds.length > 1 && "s"}
                            </button>
                        )}

                        <button
                            className={`primary_button_custom xsm:hidden sm:hidden md:flex font-bold border text-white px-3 py-2 flex ${isAddButtonDisable ? 'bg-gray-400 border-gray-400' : 'bg-secondry_color border-secondry_color'}`}
                            onClick={handleClickAddPersona}
                            disabled={isAddButtonDisable}
                        >
                            <img src={PlusSvg} style={{ marginRight: "5px" }} /> Add Persona
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
