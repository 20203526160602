import { Link } from "react-router-dom";
import { Navigate_to } from "../router/routes.constants";

import WarningSvg from "../assets/Images/Warning.svg";
import ThunderSvg from "../assets/Images/Thunder.svg"
import PremiumSvg from "../assets/Images/premiumSvg.svg"
import MagicSvg from "../assets/Images/Magic.svg"
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { useContext, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ShareDialog from "./ShareDialog";

interface IProps {
  setOpenSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionBadgeNew: React.FC<IProps> = ({ setOpenSideBar }) => {
  const { subscriptionStatus } = useContext(AuthContext) as AuthContext_type;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  if (!subscriptionStatus) {
    return (
      <div className="flex justify-center items-center w-[85%] bg-[#FFFBF3] border border-[#FEBF2B] rounded-[10px] p-2">
        <ThreeDots color="#6E40C5" />
      </div>
    );
  }

  return subscriptionStatus.usagePercentage &&
    subscriptionStatus.usagePercentage >= 100 &&
    subscriptionStatus.status != 1 ? (
    <div className="w-full md:bg-transparent md:border-none bg-[#FFF8F6] border border-[#FDDDD3] rounded-[10px]">
        <div className="md:hidden p-2 flex flex-col gap-2">
            <div className="flex items-center gap-1 w-full">
                <img className="w-[15px] h-[15px]" src={WarningSvg} alt="Waring" />
                <h4 className="font-poppins font-bold text-[#444553] text-sm md:text-[12px] leading-normal">
                    FREE trial finished!
                </h4>
            </div>
        <div className="w-full bg-[#DFDFDF] rounded-full flex justify-between items-center">
            <div
            className="bg-[#F77049] text-xs font-medium text-blue-100 text-right p-[6px]  leading-none rounded-full"
            style={{ width: "97.5%" }}
            >
            </div>
        </div>
        </div>
      <div className="w-full md:border-none md:px-4 md:p-0 border-t-[1px] border-t-[#DFDFDF] p-2">
        <Link
            onClick={() => setOpenSideBar(false)}
            to={Navigate_to.subscriptionPricing}
            className="font-poppins font-bold text-[#34317D] text-[14px] py-2 px-3 bg-[#FFD572] rounded-[32px] leading-normal flex justify-center items-center gap-1"
            >
            <img src={ThunderSvg} alt="Thunder" />
            Upgrade Subscription
        </Link>
      </div>
    </div>
  ) : (
    <div
      className={`w-full md:border-none  rounded-[10px] border ${
        subscriptionStatus.status === 0
          ? "bg-[#F6F6F6]  border-[#DFDFDF] md:bg-transparent"
          : "border-[#D5C9ED] bg-[#F5F1FB] md:bg-transparent"
      }`}
        >
        <div className="md:hidden p-2">
            {subscriptionStatus.status === 0 ? (
                <div className="flex items-center gap-x-1">
                <h4 className="font-poppins font-bold text-[#444553] text-sm md:text-xs leading-normal">
                    Your FREE usage <span className="font-normal"> ({subscriptionStatus.usagePercentage}%) </span>
                </h4>
                </div>
            ) : (
                <div className="flex items-center gap-1">
                    <img src={PremiumSvg} alt="Premium" />
                    <span className="font-poppins font-bold text-sm md:text-xs leading-normal text-[#34317D]">
                        Premium
                    </span>
                </div>
            )}
            {subscriptionStatus.status === 0 && (
                <>
                    <div className="w-full bg-[#DFDFDF] rounded-full mt-2 flex justify-between items-center">
                        <div
                        className="bg-[#696A6F] text-xs font-medium text-blue-100 text-right p-[6px] leading-none rounded-full"
                        style={{ width: subscriptionStatus.usagePercentage + "%" }}
                        >
                        </div>
                    </div>
                </>
            )}
        </div>
        {subscriptionStatus.status === 0 ? 
            <div className="w-full md:border-none md:px-4 md:p-0 border-t-[1px] border-t-[#DFDFDF] p-2">
                <Link
                    onClick={() => setOpenSideBar(false)}
                    to={Navigate_to.subscriptionPricing}
                    className="font-poppins font-bold text-[#34317D] text-[14px] py-2 px-3 bg-[#FFD572] rounded-[32px] leading-normal flex justify-center items-center gap-1"
                >
                    <img src={ThunderSvg} alt="Thunder" />
                    Upgrade Subscription
                </Link>
            </div>
            :
            <div className="xsm:px-2 md:px-4 md:pb-0 pb-2">
                <button
                  onClick={openDialog}
                  className="font-poppins w-full font-bold text-white text-[14px] py-2 px-3 bg-[#6D40C4] rounded-[32px] xsm:rounded-[6px] leading-normal flex justify-center items-center gap-1"
              >
                  <img src={MagicSvg} alt="Magic" width={15} />
                  Share with a friend
              </button>
              <ShareDialog open={isDialogOpen} onClose={() => closeDialog()} />
            </div>
        }
      {/* {subscriptionStatus.status === 1 && (
        <div className="mt-2">
          <ShareDialog open={isDialogOpen} onClose={() => closeDialog()} />
          <div
            onClick={openDialog}
            className="font-poppins font-bold text-primary_color lg:text-[14px] md:text-[12px] underline leading-normal cursor-pointer"
          >
            Share with a friend
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SubscriptionBadgeNew;
