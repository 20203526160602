import { useState, useContext, useEffect } from "react";

import { MdOutlineContentCopy } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { BiSolidDislike } from "react-icons/bi";
import { BiDislike, BiLike } from "react-icons/bi";
import { ImStarEmpty } from "react-icons/im";
import { FaStar } from "react-icons/fa";

import { AuthContext, AuthContext_type } from "../context/Auth/context";
import ideaGeneratorIcon from "../assets/Images/IdeaGenerator.png";
import { SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE } from "../config/firebase";
import { toastAlertError, toastAlertSuccess } from "../utils";
import { API_REQUEST_TYPE } from "../config/appConfig";
import { IGeneratedIdea } from "../pages/IdeaGenerator";
import { sentryClient } from "@/Sentry/sentry";

interface IProps {
  isActive: boolean;
  generatedIdeaText: string;
  activityData: any;
  generateIdeaCommand: string;
  selectedPersona: string;
  id: string;
  generatedIdeas: IGeneratedIdea[];
  setGeneratedIdeas: React.Dispatch<React.SetStateAction<IGeneratedIdea[]>>;
  isIdeaSaved: boolean;
}

const IdeaGeneratorCard = ({
  isActive,
  generatedIdeaText,
  activityData,
  selectedPersona,
  generateIdeaCommand,
  id: ideaId,
  generatedIdeas,
  setGeneratedIdeas,
  isIdeaSaved,
}: IProps) => {
  const {
    upvoteGeneratedIdea,
    downvoteGeneratedIdea,
    deleteCommandData,
    addCommandData,
  } = useContext(AuthContext) as AuthContext_type;
  const [isSaved, setIsSaved] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  useEffect(() => {
    setIsSaved(isIdeaSaved || false);
  }, [isIdeaSaved]);

  const handleLike = async (e: any) => {
    try {
      e.stopPropagation();
      upvoteGeneratedIdea({
        userPrompt: generateIdeaCommand,
        idea: generatedIdeaText,
        persona: selectedPersona,
      });
      toastAlertSuccess("liked Successfully");
      setIsLiked(true);
      setIsDisliked(false);
    } catch (error) {
      console.log(error, "error");
      toastAlertError("Something went wrong please try again");
      sentryClient.captureException(error)
    }
  };

  const handleDislike = async (e: any) => {
    try {
      e.stopPropagation();
      downvoteGeneratedIdea({
        userPrompt: generateIdeaCommand,
        idea: generatedIdeaText,
        persona: selectedPersona,
      });
      setIsLiked(false);
      setIsDisliked(true);
    } catch (error) {
      console.log(error, "error");
      toastAlertError("Something went wrong please try again");
      sentryClient.captureException(error)
    }
  };

  const handleSaveUnsave = (id: string) => {
    if (isSaved) {
      unsaveCommand(id);
    } else {
      saveCommand(id);
    }
  };

  const unsaveCommand = async (commandDocId: string) => {
    try {
      const { docId, error } = await deleteCommandData(commandDocId);

      if (error === null && docId?.length > 0) {
        toastAlertSuccess("Unsaved Successfully");
        setIsSaved(false);

        const tempSavedCommands = [...generatedIdeas];
        const indexToRemove = tempSavedCommands.findIndex(
          (command: any) => command.id === docId
        );
        if (indexToRemove > -1) {
          tempSavedCommands[indexToRemove].isSaved = false;
          setGeneratedIdeas([...tempSavedCommands]);
        }
      }
    } catch (error) {
      toastAlertError("Something went wrong");
      sentryClient.captureException(error)
    }
  };

  const saveCommand = async (id: string) => {
    try {
      if (!generatedIdeaText) {
        return;
      }
      await addCommandData(
        activityData.activityData || "",
        API_REQUEST_TYPE.POST_IDEA,
        generatedIdeaText,
        id
      );
      toastAlertSuccess("Saved Successfully");
      setIsSaved(true);
      const tempSavedCommands = [...generatedIdeas];
      const indexToRemove = tempSavedCommands.findIndex(
        (command: any) => command.id === id
      );
      if (indexToRemove > -1) {
        tempSavedCommands[indexToRemove].isSaved = true;
        setGeneratedIdeas([...tempSavedCommands]);
      }
    } catch (error: any) {
      toastAlertError(error?.message);
      sentryClient.captureException(error)
    } finally {
    }
  };

  const copyTextToClipBoard = () => {
    console.log("### text will be copied to clipboard");
    navigator.clipboard
      .writeText(generatedIdeaText)
      .then(() => {
        toastAlertSuccess("Text copied to clipboard");
        setTimeout(() => {}, SECONDS_TO_KEEP_THE_CONTENT_COPIED_MESSAGE * 1000);
      })
      .catch((error) => {
        console.log("### Error while copying content to clipboard");
        console.log({ error });
        sentryClient.captureException(error)
      });
  };

  return (
    <div
      className={`overflow-hidden p-4 rounded-2xl h-full  ${
        isActive
          ? "bg-[#6D40C4] border border-[#34317D] text-white "
          : "bg-[#F5F1FB] border border-[#DFDFDF] text-[#444553]"
      }`}
    >
      <div className=" bg-white md:w-[28px] w-[24px] md:h-[28px] h-[24px] md:p-[7px] p-[6px] rounded-md justify-center ">
        <img
          className="md:w-[15px] w-[13px] md:h-[15px] h-[13px] cursor-pointer"
          src={ideaGeneratorIcon}
          alt=""
        />
      </div>
      <p
        className={`overflow-hidden multiline-ellipsis ${
          isActive ? "white" : "text-[#444553]"
        } font-poppins md:text-base text-sm font-normal md:pt-4 pt-2`}
      >
        {generatedIdeaText}
      </p>
      <hr className={`${isActive ? "white" : "bg-[#6E40C5]"} h-0.5 mt-6`} />
      <div className="flex justify-between mt-4 ">
        <div className="flex gap-2">
          {isLiked ? (
            <AiFillLike
              onClick={handleLike}
              size={20}
              color={isActive ? "white" : "#34317D"}
              className="cursor-pointer md:w-[20px] w-[16px]"
            />
          ) : (
            <BiLike
              onClick={handleLike}
              size={20}
              color={isActive ? "white" : "#34317D"}
              className="cursor-pointer md:w-[20px] w-[16px]"
            />
          )}

          {isDisliked ? (
            <BiSolidDislike
              onClick={handleDislike}
              size={20}
              color={isActive ? "white" : "#34317D"}
              className="cursor-pointer md:w-[20px] w-[16px]"
            />
          ) : (
            <BiDislike
              onClick={handleDislike}
              size={20}
              color={isActive ? "white" : "#34317D"}
              className="cursor-pointer md:w-[20px] w-[16px]"
            />
          )}
        </div>
        <div className="flex gap-2">
          <span
            onClick={(event: any) => {
              event.stopPropagation();
              copyTextToClipBoard();
            }}
          >
            <MdOutlineContentCopy
              size={20}
              color={isActive ? "white" : "#34317D"}
              className="cursor-pointer md:w-[20px] w-[16px]"
            />
          </span>

          {isSaved ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                handleSaveUnsave(ideaId);
              }}
            >
              <FaStar
                size={20}
                color={isActive ? "white" : "#34317D"}
                className="cursor-pointer md:w-[20px] w-[16px]"
              />
            </span>
          ) : (
            <span
              onClick={(event) => {
                event.stopPropagation();
                handleSaveUnsave(ideaId);
              }}
            >
              <ImStarEmpty
                size={20}
                color={isActive ? "white" : "#34317D"}
                className="cursor-pointer md:w-[20px] w-[16px]"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdeaGeneratorCard;
