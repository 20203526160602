import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext, AuthContext_type } from "../../../context/Auth/context";
import { dateFormater, toastAlertError, toastAlertSuccess } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../router/routes.constants";
import { ITemplate } from "..";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Check from "../../../assets/Images/primaryCheck.svg";
import Uncheck from "../../../assets/Images/primaryUncheck.svg";
import Dots from "../../../assets/Images/Dots.svg";
import useScreenSize from "../../../hooks/useScreenSize";
import { Tags } from "../../AddTemplate/AddTemplate";

import { SubscriptionStatusDB } from "../../../config/firebase";
interface TemplateTableProps {
    isAddButtonDisable: boolean;
    getAllTemplates(sortBy?: string, order?: any): Promise<ITemplate[] | undefined>;
    templates: ITemplate[];
    setTemplates: React.Dispatch<React.SetStateAction<ITemplate[]>>;
    allTemplatesPresent: ITemplate[];
    setAllTemplatesPresent: React.Dispatch<React.SetStateAction<ITemplate[]>>;
    setDeleteTemplateIds: (listIds: string[]) => void;
    deleteTemplateIds: string[];
    userAllTags: Tags[];
    setSelectedTags: React.Dispatch<React.SetStateAction<Tags[]>>;
    setShowUpdatePaymentDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const TemplatesTable: React.FC<TemplateTableProps> = (props) => {
    const navigate = useNavigate()
    const { deleteTemplate, duplicateTemplate, subscriptionStatus } = useContext(AuthContext) as AuthContext_type;
    const {
        isAddButtonDisable,
        getAllTemplates,
        templates,
        setTemplates,
        allTemplatesPresent,
        setAllTemplatesPresent,
        setDeleteTemplateIds,
        deleteTemplateIds,
        userAllTags,
        setSelectedTags,
        setShowUpdatePaymentDialog,
    } = props;
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [toDeleteId, setToDeleteId] = useState<string | null>(null);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [templatesPerPage, setTemplatesPerPage] = useState(10);
    const [dropDown, setDropDown] = useState<any>(null)

    const dropDownRef = useRef<HTMLDivElement | null>(null);


    const screen = useScreenSize();

    const indexOfLastTemplate = currentPage * templatesPerPage;
    const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
    const currentTemplates = templates.slice(indexOfFirstTemplate, indexOfLastTemplate);

    useEffect(() => {
        setIsAllChecked(currentTemplates.every(template => deleteTemplateIds.includes(template.id)));
    }, [deleteTemplateIds, currentTemplates]);

    const handleSetItemsPerPage = (count: number) => {
        setTemplatesPerPage(count);
        setCurrentPage(1);
    };

    const handleDelete = (templateId: string) => {
        deleteTemplate(templateId)
        toastAlertSuccess("Deleted Successfully")
        setTemplates((prevLists: ITemplate[]) => prevLists.filter(template => template.id !== templateId));
        setAllTemplatesPresent((prevLists: ITemplate[]) => prevLists.filter(template => template.id !== templateId));
    };

    const checkPaymentDue = () => {
        if (
            allTemplatesPresent.length >= 10 &&
            subscriptionStatus?.statusDB === SubscriptionStatusDB.PAST_DUE
        ) {
            return true;
        }
        return false;
    }

    const handleDuplicate = async (templateId: string) => {
        setDropDown(null);
        const isPaymentDue = checkPaymentDue();
        if (!isAddButtonDisable) {
            if (isPaymentDue) {
                setShowUpdatePaymentDialog(true);
                return;
            } else {
                await duplicateTemplate(templateId)
                toastAlertSuccess("Duplicated Successfully")
                await getAllTemplates();
            }
        } else {
            toastAlertError("Coudn't have duplicate")
        }
    };

    const confirmDelete = () => {
        if (toDeleteId) {
            handleDelete(toDeleteId);
            setToDeleteId(null);
        }
        setShowConfirmation(false);
    };

    const handleHeaderCheckboxClick = () => {
        if (isAllChecked) {
            const newDeleteTemplateIds = deleteTemplateIds.filter(id => !currentTemplates.map(template => template.id).includes(id));
            setDeleteTemplateIds(newDeleteTemplateIds);
        } else {
            const newDeleteTemplateIds = [...deleteTemplateIds, ...currentTemplates.map(template => template.id)];
            setDeleteTemplateIds(newDeleteTemplateIds);
        }
        setIsAllChecked(!isAllChecked);
    };

    const handleTemplateCheckboxClick = (listId: string) => {
        if (deleteTemplateIds.includes(listId)) {
            const newDeleteListsIds = deleteTemplateIds.filter(id => id !== listId);
            setDeleteTemplateIds(newDeleteListsIds);
        } else {
            const newDeleteListsIds = [...deleteTemplateIds, listId];
            setDeleteTemplateIds(newDeleteListsIds);
        }
    };

    const initiateDelete = (templateId: string) => {
        setToDeleteId(templateId);
        setShowConfirmation(true);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleClickOutside = (event: MouseEvent) => {
        const targetNode = event.target as Node;

        if (dropDownRef.current && !dropDownRef.current.contains(targetNode)) {
            setDropDown(false);
        }

    };

    const handleFilterTag = async (tagItem: Tags) => {

        if (userAllTags.length > 0) {
            const selectedTags = [userAllTags.find((tag) => tag.id === tagItem.id)] as Tags[]
            setSelectedTags(selectedTags)
        }
        await getAllTemplates()

    }

    useEffect(() => {
        if (dropDown !== null) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDown]);

    return (
        <div className="mt-4">
            <table className="w-full text-sm text-left bg-white rounded-xl">
                <thead className="font-poppins text-base text-gray_dark w-8 border-b border-[#696A6F] flex-row">
                    <tr>
                        <th scope="col" className="xsm:w-[10%] md:w-[10%] lg:w-[4%] md:px-3 py-2">
                            <img
                                src={isAllChecked ? Check : Uncheck}
                                width={25}
                                height={25}
                                onClick={handleHeaderCheckboxClick}
                                alt="Checkbox"
                                className="xsm:mx-auto"
                            />
                        </th>

                        <th scope="col" className="py-3 px-3" style={screen === "small" ? { width: "40%" } : { width: "50%" }}>
                            <div className="flex justify-start items-center gap-2 xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">
                                Name
                            </div>
                        </th>
                        {(screen !== "small" && screen !== "medium") &&
                            <th scope="col" className="py-3 px-3" style={{ width: "20%" }}>
                                Tags
                            </th>
                        }
                        {(screen !== "small" && screen !== "medium") &&
                            <th scope="col" className="py-3 px-3" style={{ width: "13%" }}>
                                Last Modified
                            </th>
                        }
                        {(screen !== "small" && screen !== "medium") &&
                            <th scope="col" className="py-3 px-3" style={{ width: "13%" }}>
                                Date Created
                            </th>
                        }
                        <th scope="col" className="flex py-3 px-3" >
                            <div className="xsm:ml-[6.5rem] flex justify-end w-full xsm:font-medium xsm:text-[16px] xsm:text-[#696A6F]">
                                Actions
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentTemplates.map((template, index) => {
                        const isChecked = deleteTemplateIds.includes(template.id);
                        return (
                            <>
                                <tr
                                    key={template.id}
                                    className={`xsm:h-[140px] ${isChecked ? "bg-chip_bg" : "bg-white"} ${index === templates.length - 1 ? "" : "md:border-b border-border_color"} `}
                                >
                                    <td valign="top" className="md:px-3 xsm:py-4 xsm:flex xsm:justify-start xsm:items-center ">
                                        <div className="flex md:mt-5 xsm:mt-1 w-full justify-start items-start ">
                                            <img
                                                className="mx-auto"
                                                src={isChecked ? Check : Uncheck}
                                                width={25}
                                                height={25}
                                                onClick={() => handleTemplateCheckboxClick(template.id)}
                                            />
                                        </div>
                                    </td>
                                    <td
                                        colSpan={screen === "small" ? 2 : 1}
                                        className="py-3 px-3 cursor-pointer"
                                    >
                                        <div className="flex profiles-center items-center xsm:items-start gap-3 ">
                                            <div onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}/${template.id}`)} className={`${template.templateContent ? "xsm:-m6" : "xsm:--9"} xsm:mt-[-42px] flex flex-col gap-2 justify-center items-start w-full`}>
                                                <p className=" w-full color-[#000] xsm:text-[#444553] font-poppins text-[20px] xsm:text-[14px] xsm:font-bold font-semibold sm:mt-[10px]">
                                                    <p className="w-full" style={{
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "1",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'ellipsis',
                                                        overflow: "hidden"
                                                    }}
                                                    >
                                                        {template.templateName}
                                                    </p>
                                                    {/* {screen === "small" ?
                                                        // <LinesEllipsis
                                                        //     component="div"
                                                        //     text={template.templateName}
                                                        //     maxLine='1'
                                                        //     ellipsis='...'
                                                        //     trimRight
                                                        //     winWidth={400}
                                                        //     basedOn='letters'
                                                        // />
                                                        // template.templateName.length > 10 ? template.templateName.slice(0, 9) + "..." : template.templateName
                                                        :
                                                        template.templateName
                                                    } */}
                                                </p>
                                                {(screen === "small" || screen === "medium") ?
                                                    <p className=" w-[100%] color-[#000] text-[#444553] font-poppins text-[12px] font-normal  " style={{
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "2",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'unset',
                                                        overflow: "hidden"
                                                    }}
                                                    >
                                                        {template.templateContent.slice(0, 40) + "..."}
                                                    </p>
                                                    :
                                                    <p className=" w-[100%] color-[#000] text-[#444553] font-poppins text-[14px] font-normal  " style={{
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "2",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'ellipsis',
                                                        overflow: "hidden"
                                                    }}
                                                    >
                                                        {template.templateContent}
                                                    </p>

                                                }
                                                {/* {template.templateContent &&
                                                    <span className="color-[#000] text-[#444553] font-poppins text-[14px] font-normal  ">
                                                    </span>
                                                } */}
                                                {/* {screen === "small" ?
                                                    <>
                                                        <LinesEllipsis
                                                            component="div"
                                                            text={template.templateContent}
                                                            maxLine={1}
                                                            ellipsis='...'
                                                            basedOn='letters'
                                                            trimRight
                                                            winWidth={20}
                                                            style={{ marginBottom: "12px" }}
                                                        />
                                                    </>
                                                    // template.templateContent.slice(0, 20) + "..."
                                                    :
                                                    <p className="" style={{ 
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "2",
                                                        WebkitBoxOrient: 'vertical',
                                                        textOverflow: 'ellipsis',
                                                        overflow: "hidden"
                                                        }}
                                                        >
                                                        {template.templateContent}
                                                    </p>
                                                    // <LinesEllipsis
                                                    //     component="div"
                                                    //     text={template.templateContent}
                                                    //     maxLine={2}
                                                    //     ellipsis='...'
                                                    //     basedOn='letters'
                                                    //     trimRight
                                                    //     winWidth={20}
                                                    //     style={{ marginBottom: "12px" }}
                                                    // />
                                                    // template.templateContent
                                                } */}
                                                {/* <button className="md:hidden -ml-[0.2rem] py-2 px-3 items-center gap-8 rounded-[30px] font-poppins font-semibold text-[16px] text-white bg-light_purple">
                                            {template.templateTags}
                                            </button> */}
                                            </div>
                                            <div className="mt-[-54px] md:hidden">
                                                <img className={`cursor-pointer relative ${template.templateContent ? "mt-4" : "mt-1"} w-[25px]`} src={Dots} alt="" onClick={() => setDropDown((prev: any) => (prev === index ? null : index))} />
                                                {(dropDown === index) &&
                                                    <div ref={dropDownRef} className="absolute mt-1 right-[28px] bg-white z-[1] w-[200px] dropDown-shadow p-1 flex flex-col rounded-lg ">
                                                        <span className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                            onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}/${template.id}`)}
                                                        >
                                                            Edit Template
                                                        </span>
                                                        <button className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] font-medium disabled:opacity-[0.4]"
                                                            disabled={isAddButtonDisable}
                                                            onClick={() => handleDuplicate(template.id)}
                                                        >
                                                            Duplicate Template
                                                        </button>
                                                        <span className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => {
                                                            setDropDown(null);
                                                            initiateDelete(template.id)
                                                        }
                                                        }>Delete Template</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    {(screen !== "small" && screen !== "medium") &&
                                        <td className="py-3 px-3">
                                            {template.templateTags ?
                                                // <button className=" py-1 px-2 items-center gap-8 rounded-[30px] text-white bg-light_purple">
                                                <div className=" py-1 px-2 flex justify-start items-center gap-2 rounded-[30px]  ">
                                                    {
                                                        template.templateTags.slice(0, 2).map((tag: any) => (
                                                            <span key={tag.id}
                                                                onClick={() => { handleFilterTag(tag) }}
                                                                className="cursor-pointer rounded-xl text-primary_color font-semibold text-[16px] bg-chip_bg px-3 py-[3px] mr-0">{tag.name}</span>
                                                        ))
                                                    }
                                                    {
                                                        template.templateTags.length > 2 && (<span className="rounded-xl text-primary_color font-bold text-[12px] bg-chip_bg px-2 py-0.5 ml-0">+{template.templateTags.length - 2}</span>)
                                                    }
                                                    {/* {template?.templateTags} */}
                                                </div> :
                                                <span className="font-poppins text-[14px] font-medium">No Tags</span>
                                            }
                                        </td>
                                    }
                                    <td className="xsm:hidden py-3 px-3">
                                        <p>{template.modifiedDate ? dateFormater(template.modifiedDate.seconds) : dateFormater(template.timestamp.seconds)}</p>
                                    </td>
                                    <td className="xsm:hidden py-3 px-3">
                                        <p>{dateFormater(template.timestamp.seconds)}</p>
                                    </td>
                                    {
                                        screen !== "small" &&
                                        <td className="flex justify-end items-center py-3 px-3">
                                            <img className={`cursor-pointer relative ${template.templateContent ? "mt-4" : "mt-1"} w-[25px]`} src={Dots} alt="" onClick={() => setDropDown((prev: any) => (prev === index ? null : index))} />
                                            {(dropDown === index) &&
                                                <div ref={dropDownRef} className="absolute mt-48 bg-white z-[1] w-[200px] dropDown-shadow p-1 flex flex-col rounded-lg ">
                                                    <span className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] font-medium"
                                                        onClick={() => navigate(`/${PROTECTED_ROUTES.addTemplate}/${template.id}`)}
                                                    >
                                                        Edit Template
                                                    </span>
                                                    <button className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] font-medium disabled:opacity-[0.4]"
                                                        disabled={isAddButtonDisable}
                                                        onClick={() => handleDuplicate(template.id)}
                                                    >
                                                        Duplicate Template
                                                    </button>
                                                    <span className="cursor-pointer flex justify-start items-center p-4 font-poppins text-[16px] text-[#EB5757] font-medium" onClick={() => {
                                                        setDropDown(null);
                                                        initiateDelete(template.id)
                                                    }
                                                    }>Delete Template</span>
                                                </div>
                                            }
                                        </td>
                                    }
                                </tr>
                                <tr key={index} className={`md:hidden ${index === templates.length - 1 ? "" : "border-b border-border_color"} ${isChecked ? "bg-chip_bg" : "bg-white"}`}>
                                    <td colSpan={3} className="py-0 px-3">
                                        <div className={`w-[92%] mx-auto mb-2 ${template.templateContent ? "-mt7" : "-mt[2.8rem]"} -mt-[3rem] flex justify-between items-center`}>
                                            <div className="ml-[1.2rem] flex gap-2">
                                                {template.templateTags ?
                                                    <div className=" py-1 flex justify-start items-center gap-2 rounded-[30px]">
                                                        {
                                                            template.templateTags.slice(0, 2).map((tag: any) => (
                                                                <span key={tag.id}
                                                                    onClick={() => { handleFilterTag(tag) }}
                                                                    className="rounded-xl text-primary_color font-semibold text-[16px] xsm:text-[14px] bg-chip_bg px-3 py-[3px] mr-0">{tag.name}</span>
                                                            ))
                                                        }
                                                        {
                                                            template.templateTags.length > 2 && (<span className="rounded-xl text-primary_color font-bold text-[12px] bg-chip_bg px-2 py-0.5 ml-0">+{template.templateTags.length - 2}</span>)
                                                        }
                                                    </div> :
                                                    <span className="font-poppins text-[14px] font-medium">No Tags</span>
                                                }
                                                {/* <button className="md:hidden py-2 px-3 items-center gap-8 rounded-[30px] font-poppins font-semibold text-[16px] text-white bg-light_purple">
                                            {template.templateTags}
                                            </button> */}
                                            </div>
                                            <div className="ml-[2.2rem] flex gap-2">
                                                <p className="font-poppins text-nowrap">{template.modifiedDate ? dateFormater(template.modifiedDate.seconds) : dateFormater(template.timestamp.seconds)}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                    <Pagination
                        colSpan={6}
                        itemsPerPage={templatesPerPage}
                        totalItems={templates.length}
                        currentPage={currentPage}
                        paginate={paginate}
                        setItemsPerPage={handleSetItemsPerPage}
                    />
                </tbody>
            </table>
            <ConfirmationDialog
                setShow={setShowConfirmation}
                show={showConfirmation}
                onConfirm={confirmDelete}
                header="Delete Template"
                content="Are you sure you want to delete this template?"
            />
        </div>
    );
}

export default TemplatesTable;