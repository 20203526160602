function UpArrow({ height = 20, width = 20, color = '#6E40C5' }: any) {
    return (
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 4.95056C10.2868 4.95056 10.5735 5.0592 10.7921 5.27601L17.6718 12.1022C18.1094 12.5364 18.1094 13.2404 17.6718 13.6745C17.2343 14.1085 16.5249 14.1085 16.0872 13.6745L10 7.63431L3.91279 13.6742C3.47515 14.1083 2.76581 14.1083 2.32839 13.6742C1.89054 13.2402 1.89054 12.5362 2.32839 12.1019L9.20793 5.2758C9.42666 5.05895 9.71338 4.95056 10 4.95056Z" fill={color}/>
      </svg>
    );
  }
  
  export default UpArrow;

