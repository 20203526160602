import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navigate_to } from "../../../router/routes.constants";
import UpArrow from "../../../assets/Images/upArrow.svg";
import DownArrow from "../../../assets/Images/downArrow.svg";
import checkImage from "../../../assets/Images/listCheck.svg";
import unCheckImage from "../../../assets/Images/listUnCheck.svg";
import SearchImg from "../../../assets/Images/listSearch.svg";
import add_icon from "../../../assets/Images/add_icon.svg";
import Setting from "../../../assets/Images/SettingIcon.svg"
import { UserLists } from "../../ManageLists";
import useScreenSize from "../../../hooks/useScreenSize";
import type { FetchData } from "../index";
import { toastAlertError, updateQueryParams } from "../../../utils";
import { sentryClient } from "@/Sentry/sentry";

export interface ActionButtonsProps {
  showDeleteButton: boolean;
  initiateDelete: () => void;
  handleMultipleProfilesAddToLists: () => void;
  allLists: UserLists[];
  selectedLists: UserLists[];
  setSelectedLists: React.Dispatch<React.SetStateAction<UserLists[]>>;
  handleShowProfileDialog: () => void;
  handleGetUserProfile: (fetchData?: FetchData) => Promise<any>;
  showFreeTrail: boolean,
  rowsPerPage: number;
  handleGetUserLists: () => Promise<any>;
  refershListsFlag: boolean;
}

type OrderKey = "Last Modified" | "Name A → Z" | "Name Z → A";
type OrderValue = { field: string, direction: string };

const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
  const {
    showDeleteButton,
    initiateDelete,
    handleMultipleProfilesAddToLists,
    selectedLists,
    setSelectedLists,
    allLists,
    handleGetUserProfile,
    handleShowProfileDialog,
    showFreeTrail,
    rowsPerPage,
    handleGetUserLists,
    refershListsFlag
  } = props;

  const [query] = useState("");
  const [listDropdown, setListDropdown] = useState(false);
  const [orderDropdown, setOrderDropdown] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderKey>("Last Modified");
  const [searchedLists, setSearchedLists] = useState<UserLists[]>([]);
  const [userSelectedLists, setUserSelectedLists] = useState<UserLists[]>([]);
  const orderDropdownRef = useRef<HTMLDivElement | null>(null);
  const listDropdownRef = useRef<HTMLDivElement | null>(null);

  const screen = useScreenSize();

  const queryParams = new URLSearchParams(location.search);

  const currentLocation = useLocation();

  useEffect(() => {
    const listIds = queryParams.get("list")?.split(',');

    if (listIds) {
      const listIndexes = listIds.map(listId => 
        allLists.findIndex(listItem => listItem.id === listId)
      ).filter(index => index !== -1); 
      
      if (listIndexes.length > 0) {
          const urlFilteredLists = listIndexes.map(index => allLists[index]);
          setSelectedLists(urlFilteredLists);
      }
    }
  }, [allLists]);

  useEffect(() => {
    if(refershListsFlag){
      async function fetchData() {
        try {
          const userLists = await handleGetUserLists();
          setSearchedLists(userLists);
        } catch (error) {
          toastAlertError("Failed to fetch lists");
          sentryClient.captureException(error)
        }
      }
  
      fetchData();
    }
  }, [refershListsFlag]);

  const orderMapping: Record<OrderKey, OrderValue> = {
    "Last Modified": { field: "timestamp", direction: "desc" },
    "Name A → Z": { field: "linkedInProfileName", direction: "asc" },
    "Name Z → A": { field: "linkedInProfileName", direction: "desc" },
  };

  const handleApplyOrder = () => {
    const { field, direction } = orderMapping[selectedOrder];
    handleGetUserProfile({
      orderByField: field,
      orderDirection: direction as "asc" | "desc",
      init: true,
      query,
    });
    setOrderDropdown(false);
  };
  

  useEffect(() => {
    setSearchedLists(allLists);
  }, [allLists]);

  useEffect(() => {
    setUserSelectedLists(selectedLists);
    // setSelectedLists(selectedLists);
    // fetchProfilesData()
  }, [selectedLists]);

  const searchLists = (value: string) => {
    const regex = new RegExp(value, "i");
    const filteredLists = allLists.filter((list) => regex.test(list.name));
    setSearchedLists(filteredLists);
  };

  const addOrRemoveList = (list: UserLists) => {
    const isSelected = userSelectedLists.some(
      (userSelectedList) => userSelectedList.id === list.id
    );
    if (isSelected) {
      const updatedLists = userSelectedLists.filter(
        (userSelectedList) => userSelectedList.id !== list.id
      );
      setUserSelectedLists(updatedLists);
    } else {
      setUserSelectedLists([...userSelectedLists, list]);

    }
  };

  const searchByName = (value?:any) => {
    const { field, direction } = orderMapping[selectedOrder];
    handleGetUserProfile({
      orderByField: field,
      orderDirection: direction as "asc" | "desc",
      init: true,
      query : (value) ? value : query,
    });
  };

  const handleApply = async () => {
    setSelectedLists(userSelectedLists);
    fetchProfilesData(userSelectedLists);
    setListDropdown(false);
    setSearchedLists(allLists);

  };

  const handleClear = () => {
    setSelectedLists([]);
    setUserSelectedLists([]);
    setSearchedLists(allLists);
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   const targetNode = event.target as Node;

  //   if (
  //     orderDropdownRef.current &&
  //     !orderDropdownRef.current.contains(targetNode)
  //   ) {
  //     setOrderDropdown(false);
  //   }

  //   if (
  //     listDropdownRef.current &&
  //     !listDropdownRef.current.contains(targetNode)
  //   ) {
  //     setListDropdown(false);
  //   }
  // };

  // useEffect(()=>{
  //   console.log(8);
  //   searchByName(query)
  // },[query])


  const fetchProfilesData = async (filterList:UserLists[]) => {
    const { field, direction } = orderMapping[selectedOrder];
    if(filterList.length > 0){
      await handleGetUserProfile({
        orderByField: field,
        orderDirection: direction as "asc" | "desc",
        init: true,
        filters: filterList,
        query: "",
        itemsPerPage: rowsPerPage,
      });
    }else{
      // setSelectedLists([]);
      await handleGetUserProfile({
        orderByField: "timestamp",
        orderDirection: "desc",
        ignoreSelectedList: true,
        init: true,
        query: "",
        itemsPerPage: rowsPerPage,
      });
    }

    const selectedListIds = filterList.map(list => list.id);
    updateQueryParams(selectedListIds, currentLocation);
  };

  

  return (
    <>
      <div className="md:mt-8 mt-5 flex justify-between mb-3">
        <div className=" flex justify-start items-center xsm:w-full xsm:flex-col xsm:gap-2 ">
          <div className="w-[300px] h-[40px] md:mr-2 xsm:w-full bg-white relative flex items-center border border-border_color p-1.5 rounded-[10px]">
            <img
              src={SearchImg}
              alt="Search"
              className="absolute left-3"
              style={{ height: "16px", width: "16px" }}
            />
            <input
              className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)] outline-none bg-transparent"
              onChange={(e) => {
                const value = e.target.value;
                searchByName(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  searchByName();
              }}
              style={{ fontSize: "16px", width: "100%", lineHeight: "24px" }}
              placeholder="Search Profiles"
            />
          </div>
          <div className="flex justify-start w-full gap-y-2 gap-x-2 items-center xsm:flex-wrap">
            <div className="relative sm:ml-2">
              <button
                className="flex items-center bg-white text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg"
                onClick={() => setOrderDropdown(!orderDropdown)}
                style={{ height: "40px" }}
              >
                <span className="xsm:font-medium xsm:text-[14px] shrink-0">Order By</span>
                <img src={orderDropdown ? UpArrow : DownArrow} className="ml-1" />
              </button>
              {orderDropdown && (
                <div
                  ref={orderDropdownRef}
                  style={{ maxWidth: "200px", minWidth: "200px" }}
                  className="absolute left-0 mt-1 z-10"
                >
                  <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-1 pl-3 rounded-t-[10px]">
                    {Object.keys(orderMapping).map((order) => (
                      <span
                        key={order}
                        className="flex mt-1 cursor-pointer"
                        onClick={() => {
                          if (Object.keys(orderMapping).includes(order)) {
                            setSelectedOrder(order as OrderKey);
                          }
                        }}
                      >
                        <img
                          src={
                            selectedOrder === order ? checkImage : unCheckImage
                          }
                        />
                        <span className="ml-2 xsm:text-[14px]">{order}</span>
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]">
                    <button
                      className="bg-secondry_color text-white font-bold border border-secondry_color font-poppins xsm:text-[14px] px-3 py-2 rounded-lg flex ml-3 hover:opacity-90 transition"
                      onClick={handleApplyOrder}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="relative ml-2">
              <button
                onClick={() => setListDropdown(!listDropdown)}
                style={{ height: "40px" }}
                className={`${selectedLists.length > 0 ? "xsm:bg-[#F5F1FB] xsm:border-[#6E40C5] md:bg-white" : "bg-white"} flex items-center  text-black_color font-poppins leading-6 border border-border_color px-4 py-1 rounded-lg`}
              >
                <span className="xsm:font-medium xsm:text-[14px] shrink-0">Select List</span>
                {selectedLists.length > 0 ? (
                  <span
                    className="bg-light_purple text-white font-bold w-[25px] xsm:flex justify-center items-center xsm:text-[12px] h-[25px] xsm:w-[18px] xsm:h-[18px]  rounded-full xsm:mx-2 ml-2"
                  // style={{ minWidth: "25px", minHeight: "18px" }}
                  >
                    {selectedLists.length}
                  </span>
                ) : null}
                <img src={listDropdown ? UpArrow : DownArrow} className="ml-1" />
              </button>

              {listDropdown && (
                <div
                  ref={listDropdownRef}
                  // style={{ maxWidth: "300px", minWidth: "300px" }}
                  // className="absolute w-[50vh] mx-auto mt-1 z-10"
                  className="absolute xsm:max-w-[250px] xsm:min-w-[200px] max-w-[300px] min-w-[300px] left-0 mt-1 z-10"
                >
                  <div className="text-black_color font-poppins bg-white leading-6 border border-border_color p-3 rounded-t-[10px]">
                    <div className="relative flex items-center border border-border_color p-1.5 rounded-[10px]">
                      <img
                        src={SearchImg}
                        alt="Search"
                        className="absolute left-3"
                        style={{ height: "16px", width: "16px" }}
                      />
                      <input
                        className="text-black_color font-poppins leading-6 pl-[calc(16px+12px)]"
                        onChange={(e) => searchLists(e.target.value)}
                        style={{ fontSize: "14px", width: "100%" }}
                        placeholder="Search a list"
                      />
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {searchedLists.map((list) => {
                        const isSelected = userSelectedLists.some(
                          (selectedList) => selectedList.id === list.id
                        );

                        return (
                          <span
                            key={list.id}
                            className="flex mt-1 cursor-pointer"
                            onClick={() => addOrRemoveList(list)}
                          >
                            <img src={isSelected ? checkImage : unCheckImage} />
                            <span className="ml-2 xsm:text-[14px]">{list.name}</span>
                          </span>
                        );
                      })}
                      <span className="md:hidden flex mt-1 ml-1 gap-1 cursor-pointer">
                        <img src={Setting} className="w-[15px]" />
                        <Link
                          to={Navigate_to.manageLists}
                          className="text-center ml-2 font-poppins text-secondry_color text-[14px] font-bold underline cursor-pointer"
                        >
                          Manage Lists
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-end text-black_color font-poppins bg-white leading-6 border-t-0 border-l border-r border-b border-border_color p-3 rounded-b-[10px]">
                    <button
                      className={screen === "small" ? "bg-white text-secondry_color font poppins font-bold xsm:text-[14px] border border-secondry_color px-3 py-2 rounded-lg flex ml-3" :
                        "font-poppins font-bold text-secondry_color xsm:text-[14px] lg:text-[14px] md:text-[12px] underline leading-normal"}
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                    <button
                      className="primary_button_custom  px-3 py-2 rounded-lg flex ml-3"
                      onClick={handleApply}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Link
              to={Navigate_to.manageLists}
              className="xsm:text-[14px] text-center ml-3 font-poppins text-secondry_color text-[16px] text-weight-700 underline cursor-pointer"
            >
              Manage Lists
            </Link>
          </div>
        </div>
        <div className="xsm:hidden">
          {showDeleteButton ? (
            <div className="flex  gap-2  lg:flex-row flex-col">
              <button
                className="secondary_button_custom rounded-lg"
                onClick={() => initiateDelete()}
              >
                Delete Profiles
              </button>
              <button
                onClick={() => handleMultipleProfilesAddToLists()}
                className="primary_button_custom rounded-lg"
              >
                Add To List
              </button>
            </div>
          ) : (
            <button disabled={showFreeTrail} onClick={handleShowProfileDialog} className="primary_button_custom disabled:opacity-50">
              <img src={add_icon} className="mr-3" />
              Add New Profile
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default ActionButtons;
