import { sentryClient } from "@/Sentry/sentry";

export const sendMessageToExtensionPages = async (action: string,  data = {}) => {
    try {
        window.postMessage({ action: action, data: data }, '*');
    } catch (e) {
        console.error("send message to extension from web error : ", e);
        sentryClient.captureException(e)
        return null;
    }
};
  