function Demo({ height = 20, width = 20, color = "#6D40C4" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 20 20'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M8.22911 7.32406C7.98363 7.1604 7.66799 7.14514 7.40787 7.28436C7.14774 7.42357 6.98535 7.69466 6.98535 7.9897V13.9897C6.98535 14.2847 7.14774 14.5558 7.40787 14.695C7.66799 14.8343 7.98363 14.819 8.22911 14.6553L12.7291 11.6553C12.9517 11.507 13.0854 11.2572 13.0854 10.9897C13.0854 10.7222 12.9517 10.4724 12.7291 10.3241L8.22911 7.32406ZM10.8431 10.9897L8.58535 12.4949V9.48451L10.8431 10.9897Z'
    fill={color}
   />
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M15.9514 1.02051H4.04908C3.50572 1.0205 3.0485 1.0205 2.67476 1.05103C2.28393 1.08295 1.91239 1.15227 1.55981 1.33192C1.0222 1.60585 0.585332 2.04271 0.311406 2.58031C0.141052 2.91465 0.0693204 3.26777 0.0353151 3.63942C0.0123491 3.71479 5.96529e-07 3.79478 5.96529e-07 3.87765C5.96529e-07 3.92509 0.00405016 3.97157 0.0118197 4.01679C5.96978e-07 4.32228 5.96529e-07 4.67591 5.96529e-07 5.07875V14.9311C-1.36571e-05 15.4746 -1.36569e-05 15.9312 0.0305195 16.3048C0.0624695 16.6955 0.131835 17.0666 0.311406 17.4191C0.58488 17.9558 1.02131 18.3941 1.55981 18.6685C1.91215 18.848 2.28339 18.9173 2.67375 18.9492C3.04705 18.9797 3.50308 18.9797 4.04542 18.9797L15.9543 18.9797C16.4967 18.9797 16.9527 18.9797 17.3259 18.9492C17.7162 18.9173 18.0871 18.848 18.4394 18.6685C18.977 18.3946 19.4149 17.9567 19.6888 17.4191C19.8683 17.0668 19.9376 16.6959 19.9695 16.3056C20 15.9328 20 15.4774 20 14.9357V5.07553C20 4.67394 20 4.32123 19.9882 4.01648C19.996 3.97136 20 3.92497 20 3.87765C20 3.79498 19.9877 3.71519 19.9648 3.63998C19.9309 3.2682 19.8593 2.91486 19.6888 2.58031C19.4144 2.04183 18.9761 1.6054 18.4394 1.33192C18.0869 1.15234 17.7158 1.08297 17.3251 1.05103C16.9515 1.02049 16.4949 1.02049 15.9514 1.02051ZM1.63266 5.10234C1.63266 4.95447 1.63268 4.81893 1.63314 4.69398H18.3669C18.3673 4.81798 18.3673 4.95242 18.3673 5.09899V14.9012C18.3673 15.485 18.3667 15.8738 18.3423 16.1725C18.3186 16.4617 18.2768 16.594 18.2341 16.6779C18.1167 16.9083 17.9286 17.0964 17.6982 17.2138C17.6143 17.2565 17.482 17.2983 17.1929 17.322C16.8941 17.3464 16.5054 17.347 15.9215 17.347H4.07852C3.49467 17.347 3.10568 17.3464 2.80672 17.322C2.51725 17.2983 2.38481 17.2565 2.30101 17.2138C2.07149 17.0968 1.88396 16.9091 1.76611 16.6779C1.72326 16.5937 1.68141 16.4612 1.65774 16.1718C1.63328 15.8726 1.63266 15.4832 1.63266 14.8983V5.10234Z'
    fill={color}
   />
  </svg>
 );
}
export default Demo;
