import {useState } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FIREBASECONFIG } from "../../config/firebase";
import { toastAlertError, toastAlertSuccess } from "../../utils";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { sentryClient } from "@/Sentry/sentry";

const firebaseApp = initializeApp(FIREBASECONFIG);
export const functionInstance = getFunctions(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);

const ForceLogin = () => {
    const [userEmail, setUserEmail] = useState<string>("");

    const handleButtonClick = async () => {
        try {
            await generateUserCustomToken(userEmail);
        } catch (error : any) {
            toastAlertError("Something went wrong!");
            sentryClient.captureException(error)
        }
    };

    const generateUserCustomToken = async (userEmail: string) => {
        const functionRef = httpsCallable(functionInstance, "generateCustomToken");
        try {
            const response: any = await functionRef({email : userEmail});
            if (response.data.success) {
                const customToken = response.data.token;
                await signInWithCustomToken(firebaseAuth, customToken);
                toastAlertSuccess("User has been successfully logged in!");
            } else {
                toastAlertError(response.message);
            }
        } catch (error: any) {
            toastAlertError(error.message);
            sentryClient.captureException(error)
        }
    };

    return (
        <section>
            <div className="flex items-center">
                <h3 className="custom-h3-style-evyDemo">Please enter the email</h3>
            </div>
            <div className="d-block">
                <div className="mb-4">
                    <TextInput
                        placeholder="User Email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUserEmail(e.target.value);
                        }}
                    />
                </div>
                <Button
                    className="primary_button_custom text-lg p-3 font-semibold bg-button_darkBlue_color disabled:opacity-50"
                    onClick={handleButtonClick}
                >
                    Force Login
                </Button>
            </div>
        </section>
    );
};

export default ForceLogin;
