import { useContext, useEffect, useState } from 'react'
import LearningHubIcon from '../../assets/Icons/learning_hub.svg'
import downArrow from '../../assets/Images/downArrow.svg'
import upArrow from '../../assets/Images/upArrow.svg'
import LinkIcon from '../../assets/svgs/LinkIcon'
import UpgradeBanner from './components/UpgradeBanner'
import { AuthContext, AuthContext_type } from "../../context/Auth/context";
import { SubscriptionStatus, SubscriptionStatusDB } from '../../config/firebase'
import Loader from '../../components/Loader'
import { learningHubItems } from './links/learningHubItems'
import { courses, evAIResources, quickLinks } from './links/links'
import { Link } from 'react-router-dom'
import useScreenSize from '../../hooks/useScreenSize'
import { SubScriptionStaus_Type } from '../../context/Auth/types'
import PaymentMethodPastDueDialogue from '../../components/PaymentMethodPastDueDialogue'
// import './../../CustomStyling/CustomReusablestyling.css';

export default function LearningHub() {

  const { subscriptionStatus, getCustomerSubscriptionPortalLink, isMSubcribeLinkGenerating } = useContext(AuthContext) as AuthContext_type;

  const [isActive, setIsActive] = useState<Number | null>(null)
  const [showBanner, setShowBanner] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)

  const screen = useScreenSize();

  const [subStatus, setSubStatus] = useState<SubScriptionStaus_Type>()

  // const fetchSubscriptionStatus = async () => {
  //   setIsLoading(true)
  //   const _subscriptionStatus = await getSubscriptionStatus();
  //   setSubStatus(_subscriptionStatus);
  // };

  useEffect(() => {
    // fetchSubscriptionStatus();
    if (subscriptionStatus) {
      setSubStatus(subscriptionStatus)
    }
  }, [subscriptionStatus])

  useEffect(() => {
    if (subStatus?.status === SubscriptionStatus.FREE) {

      setShowBanner(true)
      setIsDisabled(true);
      setIsLoading(false)

    } else if (subStatus?.status === SubscriptionStatus.PRO) {
      if (subStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
        setShowUpdatePaymentDialog(true);
        return;
      }
      setShowBanner(false)
      setIsDisabled(false);
      setIsLoading(false)
    }
  }, [subStatus])

  useEffect(() => {
    if (subscriptionStatus && subscriptionStatus.statusDB === SubscriptionStatusDB.PAST_DUE) {
      setShowUpdatePaymentDialog(true);
    } else {
      setShowUpdatePaymentDialog(false);
    }
  }, [subscriptionStatus])

  return (
    <section>
      <h3 className="custom-h3-style flex justify-start items-center">Learning Hub
        <span>
          <img className='xsm:ml-2' src={LearningHubIcon} />
        </span>
      </h3>

      <p className="custom-primary-p-style">
        Discover evyAI's LinkedIn Resources - your shortcut
        to expert tips and guides for mastering LinkedIn.
      </p>

      {showBanner && <UpgradeBanner setShowBanner={setShowBanner} />}

      {!isLoading ?
        (<div className={`md:mt-8 mt-5 flex flex-col gap-2 my-8 ${isDisabled && "opacity-[0.4]"}`}>
          {learningHubItems.map((item, index) => {
            return (
              <div key={index} className={`${isDisabled ? "cursor-default" : "cursor-pointer"} bg-white rounded-[16px] xsm:p-4 py-[16px] px-[32px] flex flex-col justify-between items-start ${isActive === index && "md:border-[1.6px] md:border-[#AC93DB]"}`}>
                <div onClick={() => { !isDisabled && (isActive !== index ? setIsActive(index) : setIsActive(null)) }} className='flex justify-between w-full'>
                  <div className='flex flex-col xsm:w-full w-[95%] gap-[4px]'>
                    <h1 className='font-poppins text-weight-700 xsm:text-[16px] text-[22px] mb-2'>{item.title}</h1>
                    <p className='font-poppins text-weight-400 xsm:text-[14px] text-[18px] xsm:leading-[21px] leading-[27px]'>
                      {item.description}
                    </p>
                  </div>
                  <button
                    className='xsm:h-[20%] xsm:w-[5%] xsm:justify-end flex justify-center items-center w-[3%] cursor-pointer'
                    disabled={isDisabled}
                    onClick={() => { isActive !== index ? setIsActive(index) : setIsActive(null) }}>
                    <img src={isActive !== index ? downArrow : upArrow} />
                  </button>
                </div>
                {isActive === index &&
                  <div className='flex flex-col gap-[16px] w-full mt-4'>
                    {isActive === 0 &&
                      quickLinks.map((link, i) => {
                        return (
                          <Link to={link.url} target='_blank' key={i} className='link-container flex justify-between items-center xsm:items-start rounded-[16px] bg-[#F5F1FB] xsm:py-2 xsm:px-3 py-[24px] px-[16px] w-full xsm:active:bg-[#6D40C4] xsm:active:text-white'>
                            <div className='flex flex-col justify-between items-start gap-2 w-[95%]'>
                              <h2 className='font-poppins text-weight-600 xsm:text-[14px] xsm:font-bold text-[20px]'>{link.title}</h2>
                              <p className='font-poppins text-weight-450 xsm:text-[14px] xsm:leading-[21px] text-[18px]'>{link.description}</p>
                            </div>
                            <div className='xsm:h-[10%] xsm:w-[5%] xsm:justify-end flex justify-center items-center w-[3%] cursor-pointer'>
                              <Link className='link-img-primary' to={link.url} target='_blank'>
                                <LinkIcon color={"#6D40C4"} />
                              </Link>
                              {screen === "small" &&
                                <Link className='hidden md:hidden link-img-white' to={link.url} target='_blank'>
                                  <LinkIcon color={"white"} />
                                </Link>
                              }
                            </div>
                          </Link>
                        )
                      })
                    }
                    {isActive === 1 &&
                      courses.map((link, i) => {
                        return (
                          <Link to={link.url} target='_blank' key={i} className='link-container flex justify-between items-center xsm:items-start rounded-[16px] bg-[#F5F1FB] xsm:py-2 xsm:px-3 py-[24px] px-[16px] w-full xsm:active:bg-[#6D40C4] xsm:active:text-white'>
                            <div className='flex flex-col justify-between items-start gap-2 w-[95%]'>
                              <h2 className='font-poppins text-weight-600 xsm:text-[14px] xsm:font-bold text-[20px]'>{link.title}</h2>
                              <p className='font-poppins text-weight-450 xsm:text-[14px] xsm:leading-[21px] text-[18px]'>{link.description}</p>
                            </div>
                            <div className='xsm:h-[10%] xsm:w-[5%] xsm:justify-end flex justify-center items-center w-[3%] cursor-pointer'>
                              <Link className='link-img-primary' to={link.url} target='_blank'>
                                <LinkIcon color={"#6D40C4"} />
                              </Link>
                              {screen === "small" &&
                                <Link className='hidden md:hidden link-img-white' to={link.url} target='_blank'>
                                  <LinkIcon color={"white"} />
                                </Link>
                              }
                            </div>
                          </Link>
                        )
                      })
                    }
                    {isActive === 2 &&
                      evAIResources.map((link, i) => {
                        return (
                          <Link to={link.url} target='_blank' key={i} className='link-container flex justify-between items-center xsm:items-start rounded-[16px] bg-[#F5F1FB] xsm:py-2 xsm:px-3 py-[24px] px-[16px] xsm:p-2 w-full xsm:active:bg-[#6D40C4] xsm:active:text-white'>
                            <div className='flex flex-col justify-between items-start gap-2 w-[95%]'>
                              <h2 className='font-poppins text-weight-600 xsm:text-[14px] xsm:font-bold text-[20px] xsm:py-4'>{link.title}</h2>
                            </div>
                            <div className='xsm:h-[10%] xsm:w-[5%] xsm:justify-end flex justify-center items-center w-[3%] cursor-pointer'>
                              <Link className='link-img-primary' to={link.url} target='_blank'>
                                <LinkIcon color={"#6D40C4"} />
                              </Link>
                              {screen === "small" &&
                                <Link className='hidden md:hidden link-img-white' to={link.url} target='_blank'>
                                  <LinkIcon color={"white"} />
                                </Link>
                              }
                            </div>
                          </Link>
                        )
                      })
                    }
                  </div>
                }
              </div>
            )
          })}
        </div>) :
        (<div className='flex justify-center items-center mt-48'>
          <Loader size={10} />
        </div>)
      }
      <PaymentMethodPastDueDialogue
        show={showUpdatePaymentDialog}
        setShow={setShowUpdatePaymentDialog}
        onConfirm={() => getCustomerSubscriptionPortalLink()}
        isLoading={isMSubcribeLinkGenerating}
        skipToNavigate={true}
      />
    </section>
  )
}
