import Header from '../components/Header';
import verifyicon from '../assets/Images/verifyicon.png';
import { useContext, useEffect, useState } from 'react';
import { Navigate_to, UNPROTECTED_ROUTES } from '../router/routes.constants';
import Button from '../components/Button';
import { AuthContext, AuthContext_type } from '../context/Auth/context';
import { Navigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import EmailSentDialogue from '../components/EmailSentDialogue';
import { sentryClient } from '@/Sentry/sentry';

const EmailVerificationPending = () => {
  const { isSendingVerificationEmail, emailVerified, signOut, user, sendVerificationEmail, verificationTime,  fetchCurrentUserData } =
    useContext(AuthContext) as AuthContext_type;

    const [isTryDiffEmailButtonClicked, setIsTryDiffEmailButtonClicked] = useState(false);
    const [showEmailSentDialogue, setShowEmailSentDialogue] = useState(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    
    const handleClickChangeEmail = async () => {
      setIsTryDiffEmailButtonClicked(true);
      await signOut(false);
    }
    
    const handleClickSendAgain = async () => {
      try {
        setErrorText(null);
        await sendVerificationEmail()
        setShowEmailSentDialogue(true);
        setTimeLeft(60)
      } catch (error: any) {
        console.log(error);
        if (error.code === "auth/too-many-requests") {
          if (user) {
            await fetchCurrentUserData(user?.userId);
          }
          setErrorText("Too many requests! Try again later")
        } else {
          setErrorText("An error occurred! Please try again later")
        }
        sentryClient.captureException(error)
      }
    }
    useEffect(() => {
      const lastEmailSentVerification = user?.lastEmailSentVerification;
      if (lastEmailSentVerification) {
        const currentTime = Date.now();
        const timeDifference = (currentTime - lastEmailSentVerification.toMillis()) / 1000;
        
        if (timeDifference < 60) {
          setTimeLeft(60 - timeDifference);
        } else {
          setTimeLeft(0);
        }
      } else if (verificationTime) {
        
        const currentTime = Date.now();
        const timeDifference = (currentTime - verificationTime.toMillis()) / 1000;
        
        if (timeDifference < 60) {
          setTimeLeft(60 - timeDifference);
        } else {
          setTimeLeft(0);
        }
      }
    }, [verificationTime, user]);
  
    useEffect(() => {
      let timer: any;
      if (timeLeft !== null && timeLeft > 0) {
        timer = setInterval(() => {
          setTimeLeft(prev => prev !== null ? prev - 1 : null);
        }, 1000);
      } else if (timeLeft && timeLeft < 0) {
        setTimeLeft(null);
      } {}
  
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }, [timeLeft]);
    
    console.log(isTryDiffEmailButtonClicked);
  if (user) {
    if (emailVerified) {
      return <Navigate to={Navigate_to.afterLogin} replace />;
    }
  } 
  if (isTryDiffEmailButtonClicked && !user) {
    return <Navigate to={UNPROTECTED_ROUTES.changeEmail} replace />;
  }
  
  if (!user) {
    return <Navigate to={Navigate_to.login} replace />;
  }

  return (
    <div className='h-screen container mx-auto'>
      <Header />
        <hr className="border-t border-[#DFDFDF] md:hidden" />
      <div className='h-[calc(100vh-93px)] flex items-center justify-center mx-auto'>
        <div className='w-full h-fit max-w-[560px] max-sm:px-4 md:pb-40'>
          <div className='relative flex justify-center '>
            <img
              src={verifyicon}
              className='logo text-slate-500 w-[71px]'
              alt='Vite logo'
            />
          </div>
          <h2 className='md:text-5xl font-black text-[32px]  text-center font-poppins leading-[140%] mt-8'>
            Verify your Email
          </h2>
          <h4 className='font-poppins md:text-xl text-center md:mt-6 text-base mt-4'>
            Just one more step to go! We've sent an email to your address.
            Simply click the link within it to confirm and complete your
            registration.
          </h4>
          <button
              className='text-base md:text-[20px] w-full text-center mt-[24px] font-poppins font-bold disabled:cursor-not-allowed disabled:opacity-50'
              // href={UNPROTECTED_ROUTES.changeEmail}
              onClick={() => handleClickSendAgain()}
              disabled={timeLeft || isSendingVerificationEmail ? true : false}
              type='button'
            >
            <span className='font-normal'>Didn’t get the email?</span> Try again
          </button>

          {timeLeft && timeLeft >= 0 && !isSendingVerificationEmail ? 
            <p className='text-base text-red-500 md:text-[20px] w-full text-center mt-[24px] font-poppins font-bold'>{`${Math.floor(timeLeft)} seconds`}</p>
            :
            ""
          }

          {isSendingVerificationEmail && 
            <div className='flex justify-center items-center w-full'>
              <ThreeDots color='#34317d' />
            </div>
          }

          {errorText && (
            <div className="mt-10 text-red-500 text-center w-full">
              {errorText}
            </div>
          )}

          <div className='md:mt-[62px] mt-14 justify-center items-center flex gap-6 flex-col'>
            <Button
              // isLoading={isSendingVerificationEmail}
              disabled={isSendingVerificationEmail}
              className="bg-secondry_color text-white py-[10px] px-6 rounded-[10px] w-full font-poppins md:text-[16px]  text-[14px] font-bold items-center justify-center"
              onClick={() => handleClickChangeEmail()}
            >
              Try a different email
            </Button>
          </div>
        </div>
  
      </div>
      <EmailSentDialogue
        setShow={setShowEmailSentDialogue}
        show={showEmailSentDialogue}
      />
    </div>
  );
};

export default EmailVerificationPending;
