import { useContext, useState } from 'react';
import { AuthContext, AuthContext_type } from '../../../context/Auth/context';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerFormSchema } from '../schema';
import { useForm } from 'react-hook-form';
import { sentryClient } from '@/Sentry/sentry';

function useRegister(email: string = "") {
  const { signUpWithEmail } = useContext(
    AuthContext
  ) as AuthContext_type;

  // const [defaultEmail, setDefaultEmail] = useState(email);

  const {
    handleSubmit,
    control: formControl,
    getValues,
    formState: { errors: formErrors, isValid: formIsValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      linkedInAccount: '',
      email: email,
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(registerFormSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [TND, setTND] = useState(false);
  const [signUpError, setSignUpError] = useState('');

  const onSubmit = async () => {
    const formValues = getValues();
    setIsLoading(true);
    signUpWithEmail(
      formValues.email,
      formValues.password,
      formValues.firstName,
      formValues.lastName,
      formValues.phoneNumber || '',
      formValues.linkedInAccount || ''
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSignUpError(err);
        sentryClient.captureException(err)
      });
  };

  return {
    setTND,
    onSubmit,
    handleSubmit,
    signUpError,
    formControl,
    TND,
    formErrors,
    isLoading,
    formIsValid,
    getValues,
  };
}

export default useRegister;
