function RightArrow({ height = 20, width = 20, color = '#6E40C5' }: any) {
    return (
      <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5348 5.99963C9.5348 6.20036 9.45876 6.40106 9.30699 6.55409L4.52869 11.3699C4.22473 11.6762 3.73191 11.6762 3.42807 11.3699C3.12424 11.0636 3.12424 10.567 3.42807 10.2607L7.65618 5.99963L3.42822 1.73856C3.12439 1.43221 3.12439 0.935677 3.42822 0.62948C3.73206 0.322987 4.22488 0.322987 4.52883 0.629481L9.30713 5.44516C9.45893 5.59827 9.5348 5.79898 9.5348 5.99963Z" fill={color}/>
      </svg>
    );
  }
  
  export default RightArrow;

