function IdeaGenerator({ height = 20, width = 20, color = "#6D40C4" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 20 20'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
   >
    <path
     fillRule='evenodd'
     clipRule='evenodd'
     d='M10 0C9.08075 0 8.1705 0.18106 7.32122 0.532843C6.47194 0.884626 5.70026 1.40024 5.05025 2.05025C4.40024 2.70026 3.88463 3.47194 3.53284 4.32122C3.18106 5.1705 3 6.08075 3 7C3 9.68845 4.20919 11.2315 5.08175 12.3449L5.09513 12.362C5.75496 13.204 6 13.5808 6 14V15.0007C6 16.1061 6.89628 17 8 17H12C13.1046 17 14 16.1046 14 15V14C14 13.5808 14.245 13.204 14.9049 12.362L14.9183 12.3449C15.7908 11.2315 17 9.68845 17 7C17 6.08075 16.8189 5.17049 16.4672 4.32122C16.1154 3.47194 15.5998 2.70026 14.9497 2.05025C14.2997 1.40024 13.5281 0.884626 12.6788 0.532843C11.8295 0.18106 10.9193 0 10 0ZM8.08658 2.3806C8.69321 2.12933 9.34339 2 10 2C10.6566 2 11.3068 2.12933 11.9134 2.3806C12.52 2.63188 13.0712 3.00017 13.5355 3.46447C13.9998 3.92876 14.3681 4.47995 14.6194 5.08658C14.8707 5.69321 15 6.34339 15 7C15 8.98407 14.1627 10.0665 13.3306 11.1284L13.2535 11.2265C12.7136 11.9126 12 12.8195 12 14V15H8V14C8 12.8195 7.28638 11.9126 6.74648 11.2265L6.66937 11.1284C5.83726 10.0665 5 8.98407 5 7C5 6.34339 5.12933 5.69321 5.3806 5.08658C5.63188 4.47996 6.00017 3.92876 6.46447 3.46447C6.92876 3.00017 7.47995 2.63188 8.08658 2.3806ZM7 18C6.44772 18 6 18.4477 6 19C6 19.5523 6.44772 20 7 20H13C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18H7Z'
     fill={color}
    />
   </svg>
  </svg>
 );
}

export default IdeaGenerator;
