import { useContext, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
// import { toastAlertError, toastAlertSuccess } from "../../../utils";
// import { firebaseAuth } from "../../../context/Auth";
import { personaSchema } from "../utils/schema/persona";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import { useNavigate, useParams } from "react-router-dom";
import { COLLECTIONS } from "../config/firebase";
import { IPersona } from "../pages/Persona";
import { toastAlertError, toastAlertSuccess } from "../utils";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import { TONES } from "../config/appConfig";
import { sentryClient } from "@/Sentry/sentry";

function useValidatePersona() {
  const { addPersona, User, fetchSingleData, editPersona } = useContext(
    AuthContext
  ) as AuthContext_type;

  const {
    handleSubmit,
    control: formControl,
    getValues,
    reset,
    formState: { errors: formErrors, isValid: formIsValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      profilePictureUrl: "",
      personaName: "",
      Tag: "",
      industry: "",
      keywordsPhrases: "",
      backgroundHistory: "",
      tone: TONES[0],
    },
    resolver: yupResolver(personaSchema),
  });
  const { personaId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPersona, setIsloadingPersona] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [selectedTags, setSelectedTags] = useState("");

  const onSubmit = async () => {
    if (selectedTags) {
      const formValues = getValues();
      setIsLoading(true);
      try {
        if (User?.uid && personaId) {
          await editPersona({
            profilePictureUrl: profilePictureUrl,
            personaName: formValues.personaName,
            Tag: selectedTags,
            industry: formValues.industry,
            keywordsPhrases: formValues.keywordsPhrases,
            backgroundHistory: formValues.backgroundHistory,
            tone: formValues.tone,
            personaId: personaId,
            userId: User?.uid,
          });
          toastAlertSuccess("Updated Successfully!");
          navigate(`/${PROTECTED_ROUTES.Personas}`);
        } else if (User?.uid) {
          await addPersona({
            profilePictureUrl: profilePictureUrl,
            personaName: formValues.personaName,
            Tag: selectedTags,
            industry: formValues.industry,
            keywordsPhrases: formValues.keywordsPhrases,
            backgroundHistory: formValues.backgroundHistory,
            tone: formValues.tone,
          });
          toastAlertSuccess("Added Successfully!");
          navigate(`/${PROTECTED_ROUTES.Personas}`);
        }
      } catch (error) {
        toastAlertError("Some this went wrong while saving!")
        console.log(error, "error!");
        sentryClient.captureException(error)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFetchData = async () => {
    try {
      if (personaId) {
        setIsloadingPersona(true);
        const data: IPersona = await fetchSingleData(
          personaId,
          `/${COLLECTIONS.PERSONAS}/${User?.uid}/${COLLECTIONS.PERSONAS}`
        );
        const formValues = getValues();
        const newValue = {
          ...formValues,

          personaName: data.personaName,
          industry: data.industry,
          keywordsPhrases: data.keywordsPhrases,
          backgroundHistory: data.backgroundHistory,
          tone: data.tone,
        };
        setSelectedTags(data.Tag);
        setProfilePictureUrl(data.profilePictureUrl);
        reset(newValue);
      }
    } catch (error) {
      console.log(error, "err!");
      sentryClient.captureException(error)
    } finally {
      setIsloadingPersona(false);
    }
  };

  useEffect(() => {
    if (personaId) {
      handleFetchData();
    }
  }, [User, personaId]);

  return {
    onSubmit,
    handleSubmit,
    formControl,
    formErrors,
    isLoading,
    formIsValid,
    setProfilePictureUrl,
    profilePictureUrl,
    setSelectedTags,
    selectedTags,
    isLoadingPersona,
    isSubmitting,
  };
}

export default useValidatePersona;
