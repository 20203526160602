function Category({ height = 20, width = 20, color = "#000000" }: any) {
 return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 18 18'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M6.2449 2.84683C4.62186 2.84683 3.30612 4.16256 3.30612 5.7856C3.30612 7.40865 4.62186 8.72438 6.2449 8.72438C7.86794 8.72438 9.18367 7.40865 9.18367 5.7856C9.18367 4.16256 7.86794 2.84683 6.2449 2.84683ZM1.83673 5.7856C1.83673 3.35104 3.81034 1.37744 6.2449 1.37744C8.67946 1.37744 10.6531 3.35104 10.6531 5.7856C10.6531 8.22017 8.67946 10.1938 6.2449 10.1938C3.81034 10.1938 1.83673 8.22017 1.83673 5.7856ZM17.7848 7.10283C18.0717 7.38975 18.0717 7.85493 17.7848 8.14185L14.1113 11.8153C13.8244 12.1022 13.3592 12.1022 13.0723 11.8153L11.2356 9.97858C10.9487 9.69167 10.9487 9.22648 11.2356 8.93957C11.5225 8.65265 11.9877 8.65265 12.2746 8.93957L13.5918 10.2568L16.7458 7.10283C17.0327 6.81592 17.4979 6.81592 17.7848 7.10283ZM1.94106 12.6788C3.0747 11.9231 4.59848 11.4795 6.2449 11.4795C7.89132 11.4795 9.4151 11.9231 10.5487 12.6788C11.6772 13.4311 12.4898 14.5512 12.4898 15.8876C12.4898 16.2934 12.1609 16.6223 11.7551 16.6223C11.3493 16.6223 11.0204 16.2934 11.0204 15.8876C11.0204 15.1953 10.5995 14.4786 9.73367 13.9014C8.873 13.3276 7.64168 12.9489 6.2449 12.9489C4.84811 12.9489 3.61679 13.3276 2.75613 13.9014C1.89033 14.4786 1.46939 15.1953 1.46939 15.8876C1.46939 16.2934 1.14045 16.6223 0.734694 16.6223C0.328934 16.6223 0 16.2934 0 15.8876C0 14.5512 0.812558 13.4311 1.94106 12.6788Z'
    fill={color}
   />
  </svg>
 );
}

export default Category;
