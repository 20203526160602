import React from "react";
export const UsageCompleteDisabledWrapper = ({
  children,
  isDisabled,
}: {
  children: any;
  isDisabled: boolean;
}) => {
  if (isDisabled) {
    return (
      <div>
        {React.Children.map(children, (child) => {
          const childStyle = { pointerEvents: "none" };
          return React.cloneElement(child, {
            disabled: true,
            style: { ...child.props.style, ...childStyle, opacity: 0.3 },
          });
        })}
      </div>
    );
  } else {
    return <div>{children}</div>;
  }
};
