// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyC0kOy4juGg4iMqSkDmh4vF9HKRVsPXvOA",
  authDomain: "gptchromeextension.firebaseapp.com",
  projectId: "gptchromeextension",
  storageBucket: "gptchromeextension.appspot.com",
  messagingSenderId: "776551117048",
  appId: "1:776551117048:web:b07c9ec9bf7fe7faa6cb0d",
  measurementId: "G-HPJLZFL679",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);


export { auth,db };
