import { useContext, useState } from "react";
import FileUploadInput from "../components/FileUploadInput";

import { Controller } from "react-hook-form";
import TextInput from "../components/TextInput";
import useSettings from "./Auth/hooks/useSettings";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../router/routes.constants";
import Loader from "../components/Loader";
import DailogBox from "../components/DailogBox";
import CheckIcon from "../assets/svgs/CheckIcon";
// import LeftArrow from "../assets/svgs/LeftArrow";
import arrowRight from "../assets/Images/arrow-right.svg";
import { toastAlertSuccess } from "../utils";
import ProfilePicUpload from "../assets/Images/ProfilePicUpload.svg";
import Camera from "../assets/Images/Camera.svg";
import { AuthContext, AuthContext_type } from "../context/Auth/context";
import WarningDialogue from "../components/WarningDialogue";
import ReauthenticateUserDialogue from "../components/ReauthenticateUserDialogue";
import { OAuthCredential, User } from "firebase/auth";
import { sentryClient } from "@/Sentry/sentry";
// import LeftArrow from "../assets/svgs/LeftArrow2";

const Setting = () => {
  const navigate = useNavigate();
  const { handleUploadProfileImage, deleteAccount } = useContext(
    AuthContext
  ) as AuthContext_type;
  const {
    handleSubmit,
    onSubmit,
    sendPasswordResetEmail,
    onDailogBoxClose,
    showDailogBox,
    isSendingEmail,
    signUpError,
    formControl,
    formIsValid,
    isLoading,
    formErrors,
    setValue,
  } = useSettings();

  const [isUploading, setIsUploading] = useState(false);
  const [showDialog, setShowDeleteDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [willRequireCredentials, setWillRequireCredentials] = useState(false);
  const [invalidPassError, setInvalidPassError] = useState(false);

  const handleUploadImage = async (event: any) => {
    try {
      setIsUploading(true);
      let file = URL.createObjectURL(event.target.files[0]);
      const response = await handleUploadProfileImage(file);
      setValue("profilePictureUrl", response, { shouldValidate: true });
    } catch (error) {
      console.log(error, "error");
      sentryClient.captureException(error)
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteAccount = async (credentialsRequired: boolean, isGoogle: boolean, credentials: {password: string} | {user: User, credential: OAuthCredential}) => {
    try {
      setIsDeleting(true);
      await deleteAccount(credentialsRequired, isGoogle, credentials);
      toastAlertSuccess("Deleted successfully");
    } catch (err: any) {
      console.error(err);
      if (err.code === "auth/invalid-credential") {
        setInvalidPassError(true);
        console.log("invalid pass");
      } else if (err.code === 'auth/requires-recent-login') {
        setWillRequireCredentials(true);
        setShowDeleteDialog(false);
        setShowLoginDialog(true);
      }
      sentryClient.captureException(err)
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <section>
      <div className="!w-full ">
        {/* Content for the right part */}
        <form className="!w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between items-start">
            {/* <div className="flex justify-start items-center gap-3">
              <p
                className="cursor-pointer font-poppins text-[#8B8C95] text-[16px] font-normal"
                onClick={() => navigate(`/${PROTECTED_ROUTES.myAccount}`)}
              >
                My Account
              </p>
              <LeftArrow width={18} height={18} color={"#8B8C95"} />
              <p className="-ml-1 font-poppins text-[16px] font-bold">
                Settings
              </p>
            </div> */}

            {/* <div className="md:flex justify-end items-center xsm:hidden">
              <button
                onClick={() => navigate(`/${PROTECTED_ROUTES.home}`)}

                className=" all_sType_buttons  leading-normal text-secondry_color"

              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!formIsValid || isLoading}

                className="all_sType_buttons  bg-secondry_color text-white ml-6 "
              >
                
                Save Changes
                {isLoading && <Loader size={4} />}
              </button>
            </div> */}
          </div>
          <div className="xsm:p-4 bg-white p-8 pb-4 xsm:rounded-[16px] md:rounded-[16px] sm:mt-[24px] mt-[16px]">
            <div>
              <div className="w-full flex justify-between items-center">
                <h4 className="font-poppins xsm:mb-6 xsm:text-[18px] font-bold md:text-[22px] leading-normal text-black md:block">
                  Profile
                </h4>
                <div className="md:flex justify-end items-center gap-4 xsm:hidden">
                  <button
                    onClick={() => navigate(`/${PROTECTED_ROUTES.home}`)}
                    // className="px-6 py-[10px] rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal text-secondry_color hover:bg-button_darkBlue_color hover:text-white transition"
                    className="all_sType_buttons px-6"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!formIsValid || isLoading}
                    // className="px-6 py-[10px] flex items-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white ml-6 disabled:opacity-50 hover:opacity-90 transition"
                    className="all_pType_buttons px-6  w-full md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
                  >
                    Save Changes
                    {isLoading && <Loader size={4} />}
                  </button>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center gap-6 md:mt-6">
                <Controller
                  name="profilePictureUrl"
                  render={({ field }) => (
                    <div className="img-wrap w-[165px] min-w-[165px] h-[165px] flex justify-center items-center rounded-[10px] overflow-hidden">
                      {isUploading ? (
                        <Loader size={10} />
                      ) : (
                        <img
                          className="rounded-lg w-full h-full"
                          src={field.value ? field.value : ProfilePicUpload}
                          alt=""
                        />
                      )}
                      <div className="img-description flex justify-center items-center">
                        <FileUploadInput
                          {...field}
                          value=""
                          onChange={(event) => handleUploadImage(event)}
                          className="flex justify-center items-center"
                        >
                          <button
                            type="button"
                            disabled={isUploading}
                            className="disabled:opacity-50 flex flex-col justify-center items-center gap-2"
                          >
                            <img src={Camera} alt="" />
                          </button>
                        </FileUploadInput>
                      </div>
                    </div>
                  )}
                  control={formControl}
                />
                <div className="xsm:gap-6 grid md:grid-cols-2 grid-cols-1  md:gap-6 gap-3  w-full">
                  <div>
                    <label
                      htmlFor="name"
                      className="font-poppins xsm:text-[14px] xsm:font-semibold font-semibold text-[18px] label"
                    >
                      Name
                    </label>
                    <Controller
                      name="firstName"
                      render={({ field }) => (
                        <TextInput
                          error={formErrors.firstName?.message}
                          {...field}
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Helen"
                          className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                        />
                      )}
                      control={formControl}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="font-poppins xsm:text-[14px] xsm:font-semibold font-semibold text-[18px] label"
                    >
                      Last Name
                    </label>
                    <Controller
                      name="lastName"
                      render={({ field }) => (
                        <TextInput
                          error={formErrors.lastName?.message}
                          {...field}
                          id="last-name"
                          name="last-name"
                          type="text"
                          placeholder="Anderson"
                          className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                        />
                      )}
                      control={formControl}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="font-poppins xsm:text-[14px] xsm:font-semibold font-semibold text-[18px] label"
                    >
                      Email Address
                    </label>
                    <Controller
                      name="email"
                      render={({ field }) => (
                        <TextInput
                          disabled
                          error={formErrors.email?.message}
                          {...field}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="example@example.com"
                          className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                        />
                      )}
                      control={formControl}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="font-poppins xsm:text-[14px] xsm:font-semibold font-semibold text-[18px] label"
                    >
                      Phone Number
                    </label>
                    <Controller
                      name="phoneNumber"
                      render={({ field }) => (
                        <TextInput
                          error={formErrors.phoneNumber?.message}
                          {...field}
                          id="phone"
                          name="phone"
                          type="tel"
                          placeholder="(111) 1111 -1111"
                          className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                        />
                      )}
                      control={formControl}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xsm:mt-7 xsm:mb-7 md:mt-10 md:mb-10 mt-4 mb-4 md:block ">
              <hr className="border-t-1 border-[#6E40C5]" />
            </div>
            <div>
              <h4 className="font-poppins md:text-[22px] xsm:text-[18px] font-bold leading-normal text-black xsm:my-6">
                Linked Accounts
              </h4>
              <div className="grid grid-cols-1">
                <div className="md:mt-6">
                  <label
                    htmlFor="linkedIn-account"
                    className="font-poppins xsm:text-[14px] md:text-[18px] font-semibold label"
                  >
                    LinkedIn Account
                  </label>
                  <Controller
                    name="linkedInAccount"
                    render={({ field }) => (
                      <TextInput
                        error={formErrors.linkedInAccount?.message}
                        {...field}
                        id="linkedIn-account"
                        name="linkedIn-account"
                        type="text"
                        placeholder="linkedin.com/in/Helen_Anderson"
                        className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                      />
                    )}
                    control={formControl}
                  />
                </div>
                <div className="md:mt-6 md:block mt-6">
                  <label
                    htmlFor="linkedIn-account"
                    className="font-poppins xsm:text-[14px] md:text-[18px] font-semibold label"
                  >
                    Twitter Account
                  </label>
                  <Controller
                    name="twitterAccount"
                    render={({ field }) => (
                      <TextInput
                        error={formErrors.twitterAccount?.message}
                        {...field}
                        id="twitter-account"
                        name="twitter-account"
                        type="text"
                        placeholder="twitter.com/Helen_Anderson"
                        className="font-poppin xsm:py-3 xsm:px-4 xsm:font-normal xsm:text-[14px] input"
                      />
                    )}
                    control={formControl}
                  />
                </div>
                <div className="md:hidden flex flex-col justify-between items-center mt-6 gap-4">
                  <button
                    type="submit"
                    disabled={!formIsValid || isLoading}
                    // className="font-poppins w-full px-6 py-4 flex justify-center items-center gap-x-2 rounded-[10px] border border-secondry_color text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
                    className="all_pType_buttons w-full md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
                  >
                    Save Changes
                    {isLoading && <Loader size={4} />}
                  </button>
                  <button
                    onClick={() => navigate(`/${PROTECTED_ROUTES.home}`)}
                    // className="font-poppins w-full px-6 py-4 flex justify-center items-center gap-x-2 rounded-[10px] border border-secondry_color text-[16px] font-bold leading-normal text-secondry_color"
                    className="all_sType_buttons w-full"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            {signUpError ? (
              <p className="text-red-600 text-base w-full text-center">
                {"signUpError"}
              </p>
            ) : null}
          </div>
          <div className="flex flex-col gap-4 bg-white xsm:p-4 p-8 pb-4 rounded-[16px] mt-5 mb-4">
            <div className="flex flex-col gap-1">
              <h4 className="font-poppins xsm:text-[18px] md:text-[22px] font-bold xsm:mb-2 text-[10px] leading-normal text-black md:block">
                Change Password
              </h4>
              <p className="font-poppins xsm:font-normal xsm:text-[14px] xsm:leading-[21px] md:text-[18px] text-[#333333]">
                We’ll send you an email with instructions to change your
                password. Please click the button below.
              </p>
            </div>

            <div className="flex">
              <button
                type="button"
                onClick={() => sendPasswordResetEmail()}
                disabled={isSendingEmail}

                className="all_sType_buttons md:w-48 w-full md:text-[16px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"

              >
                {/* font-poppins xsm:w-full px-6 py-4 md:py-3 flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color */}
                Send Instructions
                {isSendingEmail && <Loader size={4} />}
              </button>
            </div>
          </div>

          {/* <div className="xsm:hidden mt-4 mb-4 block md:hidden pr-3">
            <hr className="border-t border-[#ECECEE]" />
          </div> */}

          <div
            onClick={() => {
              !willRequireCredentials ? setShowDeleteDialog(true) : setShowLoginDialog(true);
            }}
            className="xsm:p-5 md:py-6 mb-4 py-2 md:px-8 rounded-[16px] bg-white mt-4 xsm:flex flex justify-between items-center cursor-pointer"
          >
            <h3 className="font-poppins md:text-20 text-12 font-semibold leading-normal text-[#EB5757]">
              Delete Account
            </h3>
            <a href="#">
              <img
                className="md:h-4 h-3 md:w-4 w-3"
                src={arrowRight}
                alt="Arrows"
              />
            </a>
          </div>

          {/* <div className='xsm:hidden hidden'>
            <a href='#'>
              <button className='w-full py-[10px] rounded-[10px] border border-secondry_color text-[10px] font-bold leading-normal bg-secondry_color text-white mt-6'>
                Save Account Details
              </button>
            </a>
            <a href='#'>
              <button className='w-full py-[10px] rounded-[10px] border border-secondry_color text-[10px] font-bold leading-normal text-secondry_color mt-2'>
                Delete your account
              </button>
            </a>
          </div> */}
        </form>
      </div >
      <DailogBox
        className="flex flex-col items-center justify-center !max-w-[464px]"
        open={showDailogBox}
        onClose={onDailogBoxClose}
      >
        <CheckIcon />
        <div className="mt-6 mb-8 flex flex-col items-center gap-2">
          <h2 className="xsm:font-black font-bold text-[#333333] text-[32px] font-poppins">
            Check Your Email
          </h2>
          <p className="xsm:font-normal xsm:text-[16px] xsm:w-[90%] text-base font-poppins">
            Please check your email for instructions on how to reset your
            password.
          </p>
        </div>
        <div className="xsm:w-full w-full flex justify-center items-center">
          <button
            type="button"
            onClick={() => onDailogBoxClose()}
            className="xsm:w-full md:w-[30%] font-poppins  px-6 py-[9px] flex items-center justify-center gap-x-2 rounded-[10px] border border-secondry_color md:text-[18px] font-bold leading-normal bg-secondry_color text-white disabled:opacity-50"
          >
            Close
          </button>
        </div>
      </DailogBox>
      {!willRequireCredentials ? 
        <WarningDialogue
          setShow={setShowDeleteDialog}
          onConfirm={() => {
            handleDeleteAccount(false, false, {password: ""});
          }}
          isLoading={isDeleting}
          show={showDialog}
          content={"Are you sure you want to delete your account?"}
        />
        :
        <ReauthenticateUserDialogue
          setShow={setShowLoginDialog}
          onConfirm={handleDeleteAccount}
          show={showLoginDialog}
          invalidPassError={invalidPassError}
        />
      }
    </section >
  );
};

export default Setting;
