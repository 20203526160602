const generateRoute = (routes: string[]) => "/" + routes.join("/");

// notFound: "/not-found",

export const UNPROTECTED_ROUTES = {
  emailVerified: "/email-verified",
  resetPassword: "/reset-password",
  changeEmail: "/change-email",
  emailVerification: "/email-verification",
  emailVerificationPending: "/email-verification-pending",
  forgetPassword: "/forgot-password",
  passwordSuccess: "/password-success",
  emailVerificationFail: "/email-verification-failed",
  upgrade: "/upgrade",
  welcome: "welcome",
};

export const AUTH_ROUTES = {
  login: "login",
  logout: "logout",
  createProfile: "create-profile",
  register: "register",
  resetPassword: "/reset-password",
  forgetPassword: "/forgot-password",
  passwordSuccess: "/password-success",
};

export const PROTECTED_ROUTES = {
  home: "dashboard",
  subscriptionPricing: "subscription-pricing",
  chat: "chat",
  settings: "settings",
  commandDetails: "command-details",
  recentActivities: "recent-activities",
  savedProfile: "saved-profile",
  ideaGenerator: "idea-generator",
  manageLists: "lists",
  manageTags: "tags",
  learningHub: "learning-hub",
  myAccount: "my-account",
  createPost: "post-create",
  profileOptimizer: "profile-optimizer",
  Personas: "personas",
  addPersona: "persona-add",
  teams: "teams",
  acceptInvite: "accept_invite",
  templates: "templates",
  addTemplate: "template-add",
  community: "community",
  demo: "demo",
  forceLogin: "force-login",
};

export const Navigate_to = {
  afterLogin: generateRoute([PROTECTED_ROUTES.home]),
  home: generateRoute(["dashboard"]),
  chat: generateRoute(["chat"]),
  templates: generateRoute([PROTECTED_ROUTES.templates]),
  manageLists: generateRoute([PROTECTED_ROUTES.manageLists]),
  manageTags: generateRoute([PROTECTED_ROUTES.manageTags]),
  savedProfiles: generateRoute([PROTECTED_ROUTES.savedProfile]),
  emailVerificationFail: generateRoute([UNPROTECTED_ROUTES.emailVerificationFail]),
  login: generateRoute([AUTH_ROUTES.login]),
  logout: generateRoute([AUTH_ROUTES.logout]),
  register: generateRoute([AUTH_ROUTES.register]),
  createProfile: generateRoute([AUTH_ROUTES.createProfile]),
  subscriptionPricing: generateRoute([PROTECTED_ROUTES.subscriptionPricing]),
  subscriptionAffilate: "https://evyai.getrewardful.com/signup",
  settings: generateRoute([PROTECTED_ROUTES.settings]),
  myAccount: generateRoute([PROTECTED_ROUTES.myAccount]),
  recentActivities: generateRoute([PROTECTED_ROUTES.recentActivities]),
  extensionPage: "https://chromewebstore.google.com/detail/evyai-ai-assistant-for-so/imemcjjdfiaphipleieldpoiaoejdofp",
  emailVerificationPending: UNPROTECTED_ROUTES.emailVerificationPending,
  gmailInbox: "https://mail.google.com/mail/",
  upgrade: generateRoute([UNPROTECTED_ROUTES.upgrade]),
  community: generateRoute([PROTECTED_ROUTES.community]),
  demo: generateRoute([PROTECTED_ROUTES.demo]),
  teams: generateRoute([PROTECTED_ROUTES.teams]),
  acceptInvite: generateRoute([PROTECTED_ROUTES.acceptInvite]),
  forceLogin: generateRoute([PROTECTED_ROUTES.forceLogin]),
};
