import assistance from "../assets/Images/assistance.png"

const AssistanceSection = () => {
  return (
    <section className="primary-gradient rounded-[50px] sm:mt-2 mx-6 sm:py-[100px] py-[50px] assistance-section">
      <div className="flex sm:items-center container sm:flex-row flex-col sm:px-14 px-3 mx-auto sm:mt-[104] mt-[50] justify-between">
        <h2 className="sm:text-[24px] lg:text-[40px] text-[24px] text-left pb-6 font-poppins font-extrabold secondry py-1 sm:pt-0 sm:hidden block">
          Need Assistance?{" "} <br />
          <span className="text-[#C9ACFF]"> We're Here to Help</span>
        </h2>
        <div className="sm:w-1/2 relative sm:m-0 mx-[10px]">
          <img
            src={assistance}
            alt="PNG"
            className="sm:pr-5 lg:pr-0 sm:w-3/4 w-full z-[2] relative"
          />
        </div>
        <div className="sm:w-1/2 sm:py-20 lg:py-0 pl-5">
          <h2 className="sm:text-[24px] lg:text-[40px] text-[24px] font-poppins font-extrabold secondry py-1 sm:pt-0 pt-5 sm:block hidden">
            Need Assistance?{" "} <br />
            <span className="text-[#C9ACFF]"> We're Here to Help</span>
          </h2>
          <p className="text text-weight-400 leading-[24px] assistance-section-text sm:text-[22px] !text-[16px] !text-[white] pt-4 pb-8">
            If you ever have any questions or need a helping hand, feel free to
            drop us an email at{" "}
            <span className="underline  text-button_color">
              <a href="mailto:hello@evyAI.com">hello@evyAI.com</a>
            </span>
            . We're committed to ensuring your experience with evyAI is smooth
            and enjoyable.
          </p>
          <a
            href="https://evyai.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="sm:mb-20 btn"
          >
            CONTACT US
          </a>
        </div>
      </div>
    </section>
  );
};

export default AssistanceSection;
