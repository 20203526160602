export const quickLinks = [
    {
        title: "Download LinkedIn Connections",
        description: "Back up your LinkedIn connections to a CSV",
        url: "https://www.linkedin.com/mypreferences/d/download-my-data"
    },
    {
        title: "Download LinkedIn Videos",
        description: "Use this tool to download and save videos you find on LinkedIn",
        url: "https://evyai.com/linkedin-video-downloader/"
    },
    {
        title: "Job Changes",
        description: "See who has changed jobs recently; congratulate your connections",
        url: "https://www.linkedin.com/celebrations/job_changes/"
    },
    {
        title: "LinkedIn Anniversaries",
        description: "Congratulate connections on their work anniversary",
        url: "https://www.linkedin.com/celebrations/work_anniversaries/"
    },
    {
        title: "LinkedIn Birthdays",
        description: "Check who is celebrating a birthday; send a birthday message",
        url: "https://www.linkedin.com/celebrations/birthdays/"
    },
    {
        title: "LinkedIn Dashboard",
        description: "Review your metrics and performance on LinkedIn",
        url: "https://www.linkedin.com/dashboard/"
    },
    {
        title: "LinkedIn Events",
        description: "See what events are happening on LinkedIn",
        url: "https://www.linkedin.com/events/"
    },
    {
        title: "LinkedIn Feed",
        description: "Find posts to comment on each day",
        url: "https://www.linkedin.com/feed/"
    },
    {
        title: "LinkedIn Help",
        description: "Get help from LinkedIn",
        url: "https://www.linkedin.com/help/linkedin"
    },
    {
        title: "LinkedIn Learning",
        description: "Online Training Courses and Skill Building from LinkedIn™",
        url: "https://www.linkedin.com/learning/"
    },
    {
        title: "LinkedIn Notifications",
        description: "Check your LinkedIn notifications and engage with users",
        url: "https://www.linkedin.com/notifications/"
    },
    {
        title: "LinkedIn Posts of 1st Degree Connections",
        description: "Engage on the posts of your 1st degree LinkedIn connections",
        url: "https://www.linkedin.com/search/results/content/?keywords=&postedBy=%5B%22first%22%5D&sid=-4B&sortBy=%22relevance%22"
    },
    {
        title: "LinkedIn Posts of 1st Degree Connections in the past 24 hours",
        description: "Engage on the posts of your 1st degree LinkedIn connections from the past 24 hours",
        url: "https://www.linkedin.com/search/results/content/?datePosted=%22past-24h%22&postedBy=%5B%22first%22%5D&sid=4L7"
    },
    {
        title: "LinkedIn Sales Navigator",
        description: "Engage in Prospecting on LinkedIn",
        url: "https://www.linkedin.com/sales/index"
    },
    {
        title: "LinkedIn Webinar Replay",
        description: "Watch a replay of a LinkedIn webinar",
        url: "https://www.evyrgreen.com/webinarreplay/"
    },
    {
        title: "Live LinkedIn Webinar",
        description: "Join a live virtual class on how to leverage LinkedIn",
        url: "https://www.evyrgreen.com/webinar/"
    },
    {
        title: "Pending Incoming Connections",
        description: "Accept or reject incoming connections",
        url: "https://www.linkedin.com/mynetwork/invitation-manager/"
    },
    {
        title: "Pending Sent Connections",
        description: "See your pending sent connection requests; comment on their posts to increase awareness",
        url: "https://www.linkedin.com/mynetwork/invitation-manager/sent/"
    },
    {
        title: "Recent Connections",
        description: "Thank people who have recently connected with you or keep the conversation going",
        url: "https://www.linkedin.com/mynetwork/invite-connect/connections/"
    },
    {
        title: "Recent Followers",
        description: "Thank your followers for following you on LinkedIn",
        url: "https://www.linkedin.com/mynetwork/network-manager/people-follow/followers/"
    },
    {
        title: "Social Selling Score",
        description: "Check your LinkedIn SSI Score and improve your activity",
        url: "https://www.linkedin.com/sales/ssi"
    },
    {
        title: "Top 100 LinkedIn Hashtags",
        description: "Use the most popular hashtags on LinkedIn with this sheet",
        url: "https://www.evyrgreen.com/hashtag/"
    },
    {
        title: "Who Viewed Your Profile",
        description: "Review who viewed your profile daily; send them a direct message and comment on their posts",
        url: "https://www.linkedin.com/analytics/profile-views/"
    },
    {
        title: "Create a LinkedIn Profile Photo with AI",
        description: "We are not related to this company, nor do we receive a commission",
        url: "https://pfpmaker.com/"
    },
]

export const courses = [
    {
        title: "LinkedIn Training",
        description: "View this LinkedIn 101 training made specifically for evyAI premium users",
        url: "https://learn.evyrgreen.com/products/linkedin-training"
    },
    {
        title: "AI Business Development Circle",
        description: "Join our monthly mastermind to get clients with AI",
        url: "https://www.evyrgreen.com/aicircle/"
    },
    {
        title: "Artificial Intelligence Course",
        description: "Learn how to use AI for business development and marketing",
        url: "https://www.evyrgreen.com/ai/"
    },
    {
        title: "Sales Navigator Bootcamp",
        description: "Join our 6-week Sales Navigator training program",
        url: "https://www.evyrgreen.com/sales-navigator-bootcamp/"
    },
    {
        title: "LinkedIn Accelerator Training",
        description: "Learn how to get clients on LinkedIn in our one-day class",
        url: "https://www.evyrgreen.com/linkedin-accelerator-training-one-day/"
    },
]

export const evAIResources = [
    {
        title: "evyAI Google Chrome Extension",
        description: "",
        url: "https://chromewebstore.google.com/detail/evyai-ai-assistant-for-so/imemcjjdfiaphipleieldpoiaoejdofp"
    },
    {
        title: "evyAI Affiliate Program",
        description: "",
        url: "https://evyai.com/affiliate/"
    },
    {
        title: "evyAI Tutorials",
        description: "",
        url: "https://www.youtube.com/@evyAI/videos"
    },
    {
        title: "evyAI Help Center",
        description: "",
        url: "https://evyai.com/help-center/"
    },
    {
        title: "evyAI Affiliate Program Assets",
        description: "",
        url: "https://evyai.com/affiliate-assets/"
    },
    {
        title: "How to Train evyAI Personas",
        description: "",
        url: "https://evyai.com/personalize-to-shine-linkedin-evyai/"
    },
    {
        title: "How to train AI to know who you are on LinkedIn",
        description: "",
        url: "https://www.linkedin.com/pulse/how-train-ai-know-who-you-linkedin-joe-apfelbaum-asble/"
    },

]